import { fontFamily, fontWeightMedium } from '@bufferapp/ui/style/fonts'
import {
  gray,
  grayDark,
  grayDarker,
  grayLight,
  grayLighter,
  green,
  startpage,
  white,
} from '@bufferapp/ui/style/colors'
import ChevronDownIcon from '@bufferapp/ui/Icon/Icons/ChevronDown'
import AddMediaIcon from '@bufferapp/ui/Icon/Icons/AddMedia'
import styled, { css } from 'styled-components'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export const BarButton = styled.button<{
  slimPadding?: boolean
  hasNewTags?: boolean
  darkerHover?: boolean
}>`
  background: transparent;
  border: 0 !important;
  border-radius: 4px;
  color: ${grayDark};
  padding: ${(props) => (props.slimPadding ? '10px 0' : '10px')};
  transition: color 0.1s ease-in, background-color 0.1s ease-in;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.withNewTag {
    margin-right: 10px;
  }

  &.upgrade-plan-cta {
    padding: 6px;
    span {
      margin-left: 0;
    }
  }

  &:hover {
    cursor: pointer;
    color: ${grayDarker};
    background-color: ${(props) =>
      props.darkerHover ? grayLight : grayLighter};
  }

  svg {
    vertical-align: middle;
  }

  :disabled {
    cursor: not-allowed;
    color: ${gray};
    background-color: ${white};
  }

  &:after {
    ${(props) =>
      props.hasNewTags &&
      css`
        content: '';
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: #87c221;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      `}
  }
`

export const SplitBarButton = styled(BarButton)`
  padding: 10px;
  &:hover {
    background-color: ${grayLight};
  }
`

export const NewTag = styled.span<{ isDropdownEnabled?: boolean }>`
  color: ${white};
  font-family: ${fontFamily};
  font-weight: ${fontWeightMedium};
  font-size: 10px;
  background: ${green};
  border-radius: 12px;
  padding: 2px 5px;
  margin-left: 4px;

  ${(props) =>
    !props.isDropdownEnabled &&
    css`
    margin-left: 0
    margin-bottom: 14px;
    position: absolute;
    top: -2px;
    right: -14px;
    `}
`

export const IntegrationsBarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: -10px;
  box-sizing: border-box;
`

export const VerticalDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: ${grayLight};
  margin: 0 6px;
`

export const IntegrationsWrapper = styled.div`
  padding: 6px 16px;

  .floatingComposerBar & {
    position: sticky;
    bottom: -20px;
    background: white;
    z-index: 99999;
    border-radius: 0 0 var(--composer-update-zone-border-radius)
      var(--composer-update-zone-border-radius);
  }

  &.boxed {
    border-top: 1px solid ${grayLight};
  }
`

export const Emoji = styled.div`
  line-height: 0;
`

export const LeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ChildrenArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`

export const StyledDropdown = styled(DropdownMenu.Content)`
  width: 136px;
  padding: 4px 0px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 5000;
`

export const ExpandMenuButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  :hover {
    background: red;
  }
`

export const StyledChevronIcon = styled(ChevronDownIcon)<{
  hasNewTags?: boolean
}>`
  border-left: 1px solid #e0e0e0;
  padding-right: 2px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: red;
    width: 10px;
    height: 10px;
  }
`

export const StyledAddMediaIcon = styled(AddMediaIcon)`
  margin-right: 6px;
  margin-left: 10px;
`

export const StyledListItem = styled(DropdownMenu.Item)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3d3d3d;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
  &[data-highlighted] {
    outline: none;
    background: #e0e0e0;
    border-radius: 4px;
  }
`

export const StyledDropDownTrigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: none;
  background: none;
`

export const ButtonContainer = styled.div`
  display: flex;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    background-color: ${grayLighter};
  }
`

export const SplitBarButtonLinkShortener = styled(BarButton)<{
  enabled?: boolean
  showMessage?: boolean
}>`
  // If enabled show background blue
  ${(props) =>
    props.enabled &&
    css`
      background-color: #e9edff;
      color: ${startpage};

      &:hover {
        background-color: #e9edff;
        color: ${startpage};
      }
    `}

  ${(props) =>
    props.showMessage &&
    props.enabled &&
    css`
      background-color: #e9edff;
      color: ${startpage};
    `}

  ${(props) =>
    props.showMessage &&
    !props.enabled &&
    css`
      background-color: ${grayLighter};
      color: ${grayDark};
    `}

  transition: none;

  display: flex;
  justify-content: flex-start;
  width: 200px;
  padding: 0;
  padding: 8px 10px;

  /* Expand and collapse button width */
  ${(props) =>
    props.showMessage
      ? css`
          width: 130px;
          transition: width 0.3s ease;
        `
      : css`
          width: 37px;
          transition: width 0.3s ease;
          // Wait for text to disappear before collapsing
          transition-delay: 0.3s;
        `}
`

export const ActionButtonMessageWrapper = styled.div`
  width: auto;
`

export const ActionButtonMessage = styled.span<{ shouldShow?: boolean }>`
  font-family: ${fontFamily};
  font-weight: ${fontWeightMedium};
  font-size: 14px;
  line-height: 14px;
  height: 12px;
  white-space: nowrap;
  position: absolute;
  left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;

  ${(props) =>
    props.shouldShow &&
    css`
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease;
      // Wait for container to fully expand before showing text
      transition-delay: 0.3s;
    `}
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 12px;
  top: 19px;
  svg {
    fill: #b8b8b8;
  }
`

export const ShortLinkNoticeWrapper = styled.div`
  position: relative;
  line-height: 16px;

  .notice-link-too-short {
    border-radius: 0;
  }
`
