import React, { useCallback } from 'react'
import { Slot } from '@buffer-mono/popcorn'

type OpenChannelStoreFrontTriggerProps = {
  cta: string
  children: React.ReactNode
}

/**
 * Use this component to trigger opening the channel storefront.
 *
 * @example
 * ```tsx
 * <OpenChannelStoreFrontTrigger cta="publish-allChannels-emptyState-connectAChannel-1">
 *  <Button>Connect a channel</Button>
 * </OpenChannelStoreFrontTrigger>
 * ````
 */
const OpenChannelStoreFrontTrigger = ({
  children,
  cta,
}: OpenChannelStoreFrontTriggerProps): JSX.Element | null => {
  const handleOpen = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.preventDefault()
      window.appshell.actions.openChannelStorefront({
        cta,
      })
    },
    [cta],
  )

  return <Slot.Root onClick={handleOpen}>{children}</Slot.Root>
}

export { OpenChannelStoreFrontTrigger }
export type { OpenChannelStoreFrontTriggerProps }
