import { useCallback, useEffect, useRef, useState } from 'react'
import type { RefObject } from 'react'
import type { Suggestion } from '../CommentsList/SuggestionButtons'

type UseReplyFormStateProps = {
  onSubmit: (text: string) => void
  textareaRef: RefObject<HTMLTextAreaElement>
  initialExpanded?: boolean
}

type UseReplyFormStateReturn = {
  replyText: string
  isExpanded: boolean
  discardDialogVisible: boolean
  formRef: RefObject<HTMLFormElement>
  handleDiscard: () => void
  handleKeepWriting: () => void
  handleSuggestionClick: (suggestion: Suggestion) => void
  handleExpand: () => void
  handleFocus: () => void
  showDiscardDialog: (show: boolean) => void
  setReplyText: (text: string) => void
}

const SUBMIT_REPLY_KEYBOARD_SHORTCUT = 'Enter'

export const useReplyFormState = ({
  onSubmit,
  textareaRef,
  initialExpanded = false,
}: UseReplyFormStateProps): UseReplyFormStateReturn => {
  const [replyText, setReplyText] = useState('')
  const [isExpanded, setIsExpanded] = useState(initialExpanded)
  const [discardDialogVisible, showDiscardDialog] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)

  const resetForm = useCallback((): void => {
    setReplyText('')
    setIsExpanded(false)
    showDiscardDialog(false)
    textareaRef.current?.blur()
  }, [textareaRef])

  // Focus the textarea when the component mounts
  useEffect(() => {
    if (initialExpanded) {
      textareaRef.current?.focus()
      setIsExpanded(true)
    }
  }, [textareaRef, initialExpanded])

  // Handle clicks outside the form
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      const clickedOutsideForm =
        formRef.current && !formRef.current.contains(event.target as Node)
      const hasNoText = replyText.length === 0

      if (clickedOutsideForm && isExpanded) {
        if (hasNoText) {
          resetForm()
        } else {
          showDiscardDialog(true)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isExpanded, replyText, resetForm])

  const handleDiscard = useCallback((): void => {
    resetForm()
  }, [resetForm])

  const handleKeepWriting = useCallback((): void => {
    showDiscardDialog(false)
    // Use requestAnimationFrame to ensure focus happens after dialog is closed
    requestAnimationFrame(() => {
      textareaRef.current?.focus()
    })
  }, [textareaRef])

  const submitReply = useCallback((): void => {
    if (formRef.current?.checkValidity()) {
      onSubmit(replyText)
      resetForm()
    } else {
      formRef.current?.reportValidity()
    }
  }, [onSubmit, replyText, resetForm])

  // Add keyboard shortcut handler for form submission
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (
        event.key === SUBMIT_REPLY_KEYBOARD_SHORTCUT &&
        (event.metaKey || event.ctrlKey) &&
        isExpanded
      ) {
        event.preventDefault()
        submitReply()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isExpanded, submitReply])

  const handleSuggestionClick = useCallback(
    (suggestion: Suggestion): void => {
      const currentText = replyText.trim()
      const newText = currentText
        ? `${currentText} ${suggestion.text}`
        : suggestion.text

      setReplyText(newText)
      textareaRef.current?.focus()
    },
    [replyText, textareaRef],
  )

  const handleExpand = useCallback((): void => {
    setIsExpanded(true)
  }, [])

  const handleFocus = useCallback((): void => {
    setIsExpanded(true)
  }, [])

  return {
    replyText,
    isExpanded,
    discardDialogVisible,
    formRef,
    handleDiscard,
    handleKeepWriting,
    handleSuggestionClick,
    handleExpand,
    handleFocus,
    showDiscardDialog,
    setReplyText,
  }
}
