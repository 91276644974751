import { MODALS, type WindowAppShell } from './common/types'
import {
  AppshellEventKeys,
  OrganizationActionKeys,
  AccountType,
} from './common/events/types'
import { ORGANIZATION_ACTIONS } from './common/events/orgEvents'
import { ORCHESTRATOR_ACTIONS } from './common/events/orchestratorEvents'
import { MODAL_ACTIONS } from './common/events/modalEvents'
import { ACCOUNT_ACTIONS } from './common/events/accountEvents'

import render from './exports/Navigator'
import { COMPONENTS } from './exports/Orchestrator/index'

// Exports for use in other buffer-mono projects. Once all projects use the app-shell directly,
// we can remove the above imports and stop setting window.appshell here
export {
  MODALS,
  COMPONENTS,
  AppshellEventKeys,
  AccountType,
  OrganizationActionKeys,
  ORGANIZATION_ACTIONS,
  ORCHESTRATOR_ACTIONS,
  MODAL_ACTIONS,
  ACCOUNT_ACTIONS,
  WindowAppShell,
}

export { AppShell, ReduxWrappedNavigator } from './exports/Navigator'

function injectLayoutStyle(): void {
  const navigator = document.getElementById('navigator')
  if (!navigator) return
  const style = document.createElement('style')
  style.textContent = `
  `
  document.head.append(style)
}

;(async function init(): Promise<void> {
  injectLayoutStyle()
  render()
})()
