import React from 'react'
import { Services } from '../../AppConstants'
import type { ValidationFeedback } from '../../stores/types'
import { ExternalLinkIcon, Link } from '@buffer-mono/popcorn'
import styles from './styles.module.css'

export const getFormattedWhatPreventsSavingMessages = ({
  warningMessages,
}: {
  warningMessages: ValidationFeedback[]
}): React.ReactNode | string => {
  if (warningMessages.length === 0) {
    return null
  }

  const messages = warningMessages.reduce<Array<string | React.ReactNode>>(
    (finalMessages, what) => {
      let message: string | React.ReactNode = what.message
      const metadata = what.metadata

      // First make sure we can determine the composer service
      // before adding it to the error message
      const composerService = Services.get(what.composerId)
      if (composerService) {
        message = message + ` for ${composerService.formattedName}`
      }

      if (
        metadata?.learnMoreLink &&
        typeof metadata.learnMoreLink === 'string'
      ) {
        const learnMoreText =
          typeof metadata.learnMoreText === 'string'
            ? metadata.learnMoreText
            : `Learn more`
        message = (
          <span>
            {message}{' '}
            <Link
              className={styles.learnMoreToolipLink}
              href={metadata.learnMoreLink as string}
              target="_blank"
              rel="noopener noreferrer"
            >
              {learnMoreText} <ExternalLinkIcon />
            </Link>
          </span>
        )
      }

      // Make sure we don't show the same message twice
      if (!finalMessages.includes(message)) finalMessages.push(message)
      return finalMessages
    },
    [],
  )

  return messages.length === 1
    ? // If there is only one message, just return it as a string
      // to avoid list formatting
      messages
    : messages.map((message) => <p key={`${message}`}>{`• ${message}`}</p>)
}
