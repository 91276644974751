import { useMutation } from '@apollo/client'
import { DISMISS_OBJECT } from '../graphql/account'
import { useCallback } from 'react'

type UseDismissObjectReturn = {
  onDismissObject: () => void
}

const useDismissObject = ({
  organizationId,
  dismissedObject,
}: {
  organizationId?: string
  dismissedObject: { id: string; expiresAt?: string }
}): UseDismissObjectReturn => {
  const [dismissObject] = useMutation(DISMISS_OBJECT)

  const onDismissObject = useCallback(() => {
    if (!dismissedObject || !organizationId) {
      return
    }

    dismissObject({ variables: { organizationId, dismissedObject } })
  }, [dismissedObject, organizationId, dismissObject])

  return { onDismissObject }
}

export default useDismissObject
