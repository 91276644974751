import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { actionTypes as gridActionTypes } from './reducer'
import { getChannelProperties } from './util'
import { toast } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import type { Dispatch } from 'redux'
import { actionTypes } from '~publish/legacy/profile-sidebar/reducer'

export default ({
    getState,
    dispatch,
  }: {
    getState: () => any
    dispatch: Dispatch
  }) =>
  (next: (action: any) => void) =>
  (action: any) => {
    next(action)
    switch (action.type) {
      case actionTypes.SELECT_PROFILE:
        if (action.profile.type && action.profile.type === 'instagram') {
          dispatch(
            dataFetchActions.fetch({
              name: 'gridPosts',
              args: {
                profileId: action.profile.id,
              },
            }),
          )
        }
        break

      case gridActionTypes.SAVE_POST_URL:
        dispatch(
          dataFetchActions.fetch({
            name: 'updatePostLink',
            args: {
              updateId: action.updateId,
              link: action.link,
              callback: action.callback,
            },
          }),
        )
        break

      case `updatePostLink_${dataFetchActionTypes.FETCH_SUCCESS}`:
        if (action.result && action.result.success) {
          const { updateId, link, callback } = action.args
          const channel = getState().profileSidebar.selectedProfile
          const metadata = {
            ...getChannelProperties(channel),
            postId: updateId,
            url: link,
          }

          BufferTracker.shopGridPostURLUpdated(metadata)

          toast.success('Nice! Your changes have been saved.')
          callback?.()
        } else {
          toast.error('There was an error saving your changes!')
        }
        break

      case gridActionTypes.SWAP_CUSTOM_LINKS: {
        const profile = getState().grid.byProfileId[action.profileId]
        const linkDetails = profile.customLinksDetails

        dispatch(
          dataFetchActions.fetch({
            name: 'updateCustomLinks',
            args: {
              profileId: action.profileId,
              customLinks: linkDetails.customLinks || [],
              customLinkColor: null,
              customLinkContrastColor: null,
              customLinkButtonType: null,
            },
          }),
        )
        break
      }

      case gridActionTypes.UPDATE_SINGLE_CUSTOM_LINK: {
        dispatch(
          dataFetchActions.fetch({
            name: 'updateSingleCustomLink',
            args: {
              profileId: action.profileId,
              linkId: action.linkId,
              customLink: action.item,
            },
          }),
        )
        break
      }
      case gridActionTypes.UPDATE_CUSTOM_LINKS: {
        const profile = getState().grid.byProfileId[action.profileId]
        const linkDetails = profile.customLinksDetails

        dispatch(
          dataFetchActions.fetch({
            name: 'updateCustomLinks',
            args: {
              profileId: action.profileId,
              customLinks: linkDetails.customLinks || [],
              customLinkColor: action.customLinkColor,
              customLinkContrastColor: action.customLinkContrastColor,
              customLinkButtonType: action.customLinkButtonType,
            },
          }),
        )
        break
      }
      case gridActionTypes.ADD_NEW_CUSTOM_LINK: {
        const profile = getState().grid.byProfileId[action.profileId]
        const linkDetails = profile.customLinksDetails

        dispatch(
          dataFetchActions.fetch({
            name: 'updateCustomLinks',
            args: {
              profileId: action.profileId,
              customLinks: linkDetails.customLinks || [],
              customLinkColor: action.customLinkColor,
              customLinkContrastColor: action.customLinkContrastColor,
              customLinkButtonType: action.customLinkButtonType,
            },
          }),
        )
        break
      }

      case `updateSingleCustomLink_${dataFetchActionTypes.FETCH_SUCCESS}`:
      case `updateCustomLinks_${dataFetchActionTypes.FETCH_SUCCESS}`:
      case `deleteCustomLink_${dataFetchActionTypes.FETCH_SUCCESS}`:
        toast.success('Nice! Your changes have been saved.')
        break

      case `updateCustomLinks_${dataFetchActionTypes.FETCH_FAIL}`:
      case `deleteCustomLink_${dataFetchActionTypes.FETCH_FAIL}`:
      case `updatePostLink_${dataFetchActionTypes.FETCH_FAIL}`:
        toast.error('There was an error saving your changes!')
        break

      case gridActionTypes.DELETE_CUSTOM_LINK:
        if (action.customLinkId) {
          dispatch(
            dataFetchActions.fetch({
              name: 'deleteCustomLink',
              args: {
                profileId: action.profileId,
                customLinkId: action.customLinkId,
              },
            }),
          )
        }
        break

      default:
        break
    }
  }
