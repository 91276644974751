/* eslint-disable react/forbid-prop-types */

/**
 * Component that displays a link attachment
 */

import React from 'react'
import styled from 'styled-components'

import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import LinkAttachmentTextEditor from './LinkAttachmentTextEditor'
import { LinkAttachmentThumbnailEditor } from './link-attachment-thumbnail-editor/LinkAttachmentThumbnailEditor'
import LinkAttachmentThumbnail from './LinkAttachmentThumbnail'
import CloseButton from './shared/CloseButton'
import A from './shared/A'
import { AttachmentTypes, LinkAttachmentTextFieldTypes } from '../AppConstants'
import styles from './css/LinkAttachment.module.css'
import { ensureUrlProtocol } from '../utils/StringUtils'
import AppStore from '../stores/AppStore'
import { Tooltip } from '@buffer-mono/popcorn'
import type Draft from '../entities/Draft/Draft'

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 12px;
  right: 16px;
  width: 20px;
  height: 20px;
  background: transparent;
`

type Props = {
  draft: Draft
  disabled?: boolean
}
class LinkAttachment extends React.Component<Props> {
  onCloseButtonClick = (): void => {
    const { draft } = this.props

    ComposerActionCreators.disableAttachment(draft.id, AttachmentTypes.LINK)
  }

  getDomainOnlyUrl(url: string): string {
    return url
      .replace('http://', '')
      .replace('www.', '')
      .replace('https://', '')
      .split(/[/?#]/)[0]
  }

  // Facebook has specific logic to allow editing of link attachments, not everyone
  // on the network can edit all attached links. For other social networks, we defer
  // to their config with `service.canEditLinkAttachment`
  canEditLinkAttachment = (): {
    canEdit: boolean
    isResultCertain: boolean
    hasNonPages?: boolean
    isLoading: boolean
  } => {
    const { draft } = this.props
    const selectedProfiles = AppStore.getSelectedProfiles()

    if (!draft.service.isFacebook() || !draft.service.canEditLinkAttachment) {
      return {
        canEdit: draft.service.canEditLinkAttachment,
        isResultCertain: true,
        isLoading: false,
      }
    }

    // If only Facebook Profiles/Groups are selected (not Pages), prevent editing
    const hasOnlyNonPagesSelected = selectedProfiles.every(
      (profile) => profile.serviceType !== 'page',
    )
    if (hasOnlyNonPagesSelected && !draft.link?.wasEdited) {
      return {
        canEdit: false,
        isResultCertain: true,
        isLoading: false,
      }
    }

    // If Facebook Profiles or Groups are selected, default to the generic info message
    // that mentions their unability to edit link previews
    const hasNonPagesSelected = selectedProfiles.some(
      (profile) => profile.serviceType !== 'page',
    )
    if (hasNonPagesSelected) {
      return {
        canEdit: true,
        isResultCertain: false,
        hasNonPages: true,
        isLoading: false,
      }
    }

    // Don't check more than 5 profiles to save on Facebook API request quota
    const maxPagesToCheckCount = 5

    // If unsure, allow editing straight away
    if (selectedProfiles.length > maxPagesToCheckCount) {
      return {
        canEdit: true,
        isResultCertain: false,
        isLoading: false,
      }
    }

    const selectedProfilesIds = selectedProfiles.map(({ id }) => id)
    const linkDomainOwnershipData = draft.link?.url
      ? AppStore.getAppState().domainsOwnedByFacebookPages.get(draft.link.url)
      : undefined

    // Default to true while we retrieve more data
    if (linkDomainOwnershipData === undefined) {
      return {
        canEdit: true,
        isResultCertain: false,
        isLoading: true,
      }
    }

    const doAllSelectedProfilesOwnDomain = selectedProfilesIds.every(
      (id) => linkDomainOwnershipData.get(id) === true,
    )

    if (doAllSelectedProfilesOwnDomain) {
      return {
        canEdit: true,
        isResultCertain: true,
        isLoading: false,
      }
    }

    const doSomeSelectedProfilesOwnDomain = selectedProfilesIds.some(
      (id) => linkDomainOwnershipData.get(id) === true,
    )
    const isSomeOwnershipDataBeingLoaded = selectedProfilesIds.some(
      (id) => linkDomainOwnershipData.get(id) === null,
    )

    if (
      doSomeSelectedProfilesOwnDomain ||
      isSomeOwnershipDataBeingLoaded ||
      draft.link?.wasEdited
    ) {
      return {
        canEdit: true,
        isResultCertain: false,
        isLoading: isSomeOwnershipDataBeingLoaded,
      }
    }

    return {
      canEdit: false,
      isResultCertain: true,
      isLoading: false,
    }
  }

  renderThumbnail(canBeEdited = false): JSX.Element | null {
    const { draft } = this.props

    if (!draft?.link) {
      return null
    }

    return (
      <>
        <LinkAttachmentThumbnail thumbnail={draft.link.thumbnail} />

        {canBeEdited && (
          <LinkAttachmentThumbnailEditor
            draftId={draft.id}
            selectedThumbnail={draft.link.thumbnail}
            availableThumbnails={draft.link.availableThumbnails}
          />
        )}
      </>
    )
  }

  renderDetails(canBeEdited = false): JSX.Element | null {
    const { draft } = this.props
    const { link } = draft || {}

    if (!draft || !link) {
      return null
    }

    const absoluteUrl = ensureUrlProtocol(link.url)
    const domainOnlyUrl = this.getDomainOnlyUrl(link.url)

    return (
      <span className={styles.linkDetailsContainer}>
        {link.title !== null ? (
          <span data-testid="link-preview-title">
            <LinkAttachmentTextEditor
              type={LinkAttachmentTextFieldTypes.TITLE}
              value={link.title}
              draftId={draft.id}
              canBeEdited={canBeEdited}
              data-testid="link-preview-title"
            />
          </span>
        ) : (
          <p
            data-testid="link-preview-loading-message"
            className={styles.loadingMessage}
          />
        )}

        <A className={styles.url} href={absoluteUrl} target="_blank">
          {domainOnlyUrl}
        </A>

        {link.description !== null && (
          <LinkAttachmentTextEditor
            type={LinkAttachmentTextFieldTypes.DESCRIPTION}
            value={link.description}
            draftId={draft.id}
            canBeEdited={canBeEdited}
          />
        )}
      </span>
    )
  }

  renderDisabled(): JSX.Element | null {
    const { draft } = this.props
    const { link } = draft || {}

    if (!link) {
      return null
    }

    return (
      <div>
        <div className={styles.linkAttachment}>
          {this.renderThumbnail()}
          {this.renderDetails()}
        </div>
      </div>
    )
  }

  renderEnabled(): JSX.Element | null {
    const { draft } = this.props

    if (
      AppStore.getExpandedComposerId() !== draft.id ||
      !draft.hasLinkAttachment()
    ) {
      return null
    }

    const {
      canEdit: canEditLinkAttachment,
      isResultCertain,
      hasNonPages = false,
      isLoading,
    } = this.canEditLinkAttachment()

    const showFacebookLinkEditingMessage = !isResultCertain && !isLoading

    return (
      <div className={styles.linkAttachmentWrapper}>
        {showFacebookLinkEditingMessage && (
          <p className={styles.facebookLinkEditingMessage}>
            {hasNonPages
              ? `It looks like you've got a few Facebook accounts selected. Please note that Groups are not allowed to make changes to link previews, and some Pages may not either. `
              : `Heads up! We're unsure if all the Facebook Pages you've selected are allowed to make changes to link previews. `}
            Please bear in mind that if you do make changes, the link attachment
            may look different once published on Facebook. You can read more
            about&#32;
            <A
              href={`https://support.buffer.com/article/615-attaching-images-videos-and-other-media-to-your-posts?${HC_UTM_PARAMS}`}
              target="_blank"
            >
              Facebook&apos;s current link editing policies here
            </A>
            .
          </p>
        )}

        <div className={styles.linkAttachment}>
          {this.renderThumbnail(canEditLinkAttachment)}
          {this.renderDetails(canEditLinkAttachment)}

          <Tooltip content="Disable Link Attachment">
            <StyledCloseButton
              label="Disable Link Attachment"
              onClick={this.onCloseButtonClick}
              light
            />
          </Tooltip>
        </div>
      </div>
    )
  }

  render(): JSX.Element | null {
    const { disabled } = this.props

    return disabled ? this.renderDisabled() : this.renderEnabled()
  }
}

export default LinkAttachment
