import { PusherEvent, usePusherEvent } from '~publish/services/pusher'

export const useNewCommentReceived = (): void => {
  /**
   * Subscription to COMMENT_INGESTED event
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  usePusherEvent(PusherEvent.COMMENT_INGESTED, (): void => {
    const { eventKeys } = window.appshell || {}
    if (eventKeys) {
      window.dispatchEvent(new CustomEvent(eventKeys.NEW_COMMENT_EVENT_KEY))
    }
  })
}
