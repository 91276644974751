import React from 'react'
import styled from 'styled-components'

const FieldWrapper = styled.div`
  padding: 3px 0;
  display: flex;
  width: 100%;
`

const InputWrapper = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: var(--space-xs);
`

const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  color: #3d3d3d;
  min-width: 130px;
  padding-right: 10px;
`

const FooterField = ({
  ariaLabel,
  label,
  fieldContent,
}: {
  ariaLabel: string
  label: string
  fieldContent: React.ReactNode
}): JSX.Element => (
  <FieldWrapper>
    <Label id={ariaLabel}>{label}</Label>
    <InputWrapper>{fieldContent}</InputWrapper>
  </FieldWrapper>
)

export default FooterField
