import React, { type ReactNode } from 'react'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import { Tooltip, type TooltipProps } from '@buffer-mono/popcorn'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import { PostTypeWrapper, RadioInput, RadioLabel } from './styles'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import type { Draft } from '../../entities/Draft'

interface PostTypeBarProps {
  draft: Draft
  defaultPostType: string
  postTypes: {
    value: string
    name: string
    disabledCallback?: () => boolean
    disabledMessage?: string
  }[]
  onPostTypeChangeCallback?: (newValue: string, oldValue: string) => void
  tooltip?: ReactNode
}

const TooltipWrapper = ({ ...props }: TooltipProps): JSX.Element => {
  if (!props.content) {
    return <>{props.children}</>
  }
  return <Tooltip {...props} />
}

const PostTypeBar = ({
  draft,
  defaultPostType,
  postTypes,
  onPostTypeChangeCallback = (): void => undefined,
  tooltip,
}: PostTypeBarProps): JSX.Element | null => {
  const [postTypeSelected, setPostTypeSelected] = React.useState(
    draft.updateType ?? defaultPostType,
  )

  React.useEffect(() => {
    if (!draft.updateType) return
    setPostTypeSelected(draft.updateType)
  }, [draft.updateType])

  const onPostTypeChange = (newValue: string): void => {
    const oldValue = draft.updateType ?? 'post'
    ComposerActionCreators.updateDraftUpdateType(draft.id, newValue)

    onPostTypeChangeCallback(newValue, oldValue)

    /**
     * It's done here with the next tick in order for React to properly reflect
     * the checked/unchecked state of the component in the view. If it's not here,
     * there's a bug where the state is changed, but the view remains immutable.
     * That is, without it, if you select other checkbox, then you see the initial
     * one still selected although the state has changed.
     */
    setTimeout(() => {
      setPostTypeSelected(newValue)
    })
  }

  if (AppStore.getExpandedComposerId() !== draft.id) {
    return null
  }

  return (
    <PostTypeWrapper>
      <div>
        {postTypes.map((postType, i) => (
          <TooltipWrapper
            key={`${i}-tooltip-${postType.name}`}
            content={
              postType.disabledCallback?.() ? postType.disabledMessage : ''
            }
          >
            <RadioLabel
              key={`${i}-radio-button-${postType.name}`}
              htmlFor={postType.value}
              onClick={(): void | null =>
                postType.disabledCallback instanceof Function &&
                !postType.disabledCallback()
                  ? onPostTypeChange(postType.value)
                  : null
              }
              disabled={postType.disabledCallback?.()}
            >
              <RadioInput
                type="radio"
                id={postType.value}
                name={`${draft.service.name}postType`}
                value={postType.value}
                checked={postTypeSelected === postType.value}
                onChange={(e): void => onPostTypeChange(e.target.value)}
                disabled={postType.disabledCallback?.()}
              />
              {postType.name}
            </RadioLabel>
          </TooltipWrapper>
        ))}
      </div>
      <div>
        {tooltip && (
          <Tooltip side="left" content={tooltip}>
            <InfoIcon className="info-icon-wrapper" />
          </Tooltip>
        )}
      </div>
    </PostTypeWrapper>
  )
}

export default PostTypeBar
