import React from 'react'

import { red, yellowDark } from '@bufferapp/ui/style/colors'

import { UXMessagingContainer } from './UXMessaging.style'

const messageStatusColor = {
  error: red,
  warning: yellowDark,
}

export type MessageStatus = {
  messageStatus: 'error' | 'warning'
  message: string
}

const UXMessaging = ({
  messageStatus = 'warning',
  message,
}: MessageStatus): JSX.Element => {
  const messageTest = message
  const color = messageStatusColor[messageStatus]

  return (
    <UXMessagingContainer id="UXMessagingContainer" color={color}>
      {messageTest}
    </UXMessagingContainer>
  )
}

export default UXMessaging
