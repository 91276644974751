import React from 'react'
import { Flex, Heading, Text } from '@buffer-mono/popcorn'

import type { Channel } from '~publish/gql/graphql'

export const PostsList = ({ channel }: { channel: Channel }): JSX.Element => {
  return (
    <Flex
      direction="column"
      align="center"
      gap="lg"
      style={{ padding: 'var(--space-3xl) 0' }}
    >
      <Text size="lg" style={{ fontSize: '4rem' }}>
        🏗️
      </Text>
      <Flex direction="column" align="center" gap="sm">
        <Heading as="h2" size="large">
          Coming Soon!
        </Heading>
        <Text size="lg" color="subtle" align="center">
          Posts view coming soon for {channel.name}
        </Text>
      </Flex>
    </Flex>
  )
}
