import React from 'react'
import styled from 'styled-components'
import styles from '../css/progress-indicators/CircularIndicator.module.css'

const Svg = styled.svg`
  :not(:root) {
    overflow: hidden;
  }
`

export interface CircularIndicatorProps {
  size: number
  progress: number
  initialProgress?: number
  strokeWidth?: number
  classNames?: {
    container?: string | null
    circle?: string | null
  }
  styles?: {
    circle?: Record<string, unknown>
  }
  finishingUpText?: string
  children?: React.ReactNode
}

const CircularIndicator = ({
  size,
  progress,
  initialProgress = 0,
  strokeWidth = 2,
  classNames = { container: null, circle: null },
  styles: stylesMap = { circle: {} },
  finishingUpText = 'Finishing up…',
  children,
}: CircularIndicatorProps): JSX.Element => {
  const displayedProgress =
    initialProgress + (progress * (100 - initialProgress)) / 100
  const progressText =
    progress === 100
      ? finishingUpText
      : progress === 0
      ? `${progress}%`
      : `${progress.toFixed(1)}%`

  const circleRadius = size / 2 - strokeWidth / 2
  const circleCircumference = 2 * Math.PI * circleRadius

  const containerClassName = [styles.container, classNames.container].join(' ')

  const containerDynamicStyle = {
    width: size,
    height: size,
  }

  const svgDynamicStyle = {
    width: size,
    height: size,
  }

  const circleClassName = [styles.circle, classNames.circle].join(' ')

  const circleDynamicStyle = {
    strokeDashoffset: ((100 - displayedProgress) * circleCircumference) / 100,
    strokeDasharray: circleCircumference,
    strokeWidth,
    ...stylesMap.circle,
  }

  const circleDynamicAttributes = {
    cx: size / 2,
    cy: size / 2,
    r: circleRadius,
  }

  return (
    <div className={containerClassName} style={containerDynamicStyle}>
      <Svg className={styles.svg} style={svgDynamicStyle}>
        <circle
          className={circleClassName}
          style={circleDynamicStyle}
          {...circleDynamicAttributes}
        />
      </Svg>

      {children ?? <span className={styles.progressText}>{progressText}</span>}
    </div>
  )
}

export default CircularIndicator
