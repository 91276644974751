import type { Temporal } from '@js-temporal/polyfill'
import { useEffect, useState } from 'react'
import { getZonedNow, startOfDay } from '~publish/helpers/temporal'

const ONE_MINUTE_MS = 60000

/**
 * A hook that provides the current time in a specified timezone.
 * Updates every minute to keep the time current.
 *
 * @param {string} timezone - The timezone identifier (e.g., 'America/New_York')
 * @returns {Object} An object containing:
 *   - now: The current time as a Temporal.ZonedDateTime
 *   - nowTimestamp: The current time as milliseconds since epoch
 *   - today: The start of the current day as a Temporal.ZonedDateTime
 *   - todayTimestamp: The start of the current day as milliseconds since epoch
 */
export function useTimezonedNow(timezone: string): {
  now: Temporal.ZonedDateTime
  nowTimestamp: number
  today: Temporal.ZonedDateTime
  todayTimestamp: number
} {
  const [nowInfo, setNowInfo] = useState<{
    now: Temporal.ZonedDateTime
    nowTimestamp: number
    today: Temporal.ZonedDateTime
    todayTimestamp: number
  }>({
    now: getZonedNow(timezone),
    nowTimestamp: getZonedNow(timezone).toInstant().epochMilliseconds,
    today: startOfDay(getZonedNow(timezone)),
    todayTimestamp: startOfDay(getZonedNow(timezone)).toInstant()
      .epochMilliseconds,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      const now = getZonedNow(timezone)
      const today = startOfDay(now)
      setNowInfo({
        today,
        todayTimestamp: today.toInstant().epochMilliseconds,
        now,
        nowTimestamp: now.toInstant().epochMilliseconds,
      })
    }, ONE_MINUTE_MS)
    return () => clearInterval(interval)
  }, [timezone])

  return nowInfo
}
