import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { TagIcon, SettingsIcon, Tooltip, Sidebar } from '@buffer-mono/popcorn'

import {
  useCurrentOrganization,
  useIsAdmin,
} from '~publish/legacy/accountContext'
import { TagManagementDialog } from '~publish/components/TagManagementDialog'
import { getAccountURL } from '~publish/legacy/utils/formatters/getURL'
import { settingsChannelsRoute, tagsPage } from '~publish/legacy/routes'
import { useSplitEnabled } from '@buffer-mono/features'

import styles from './ManagementNav.module.css'

export const ManagementNav: React.FC = () => {
  const isAdmin = useIsAdmin()
  const ownerEmail = useCurrentOrganization()?.ownerEmail
  const { isEnabled: newTagManagerEnabled } = useSplitEnabled(
    'enable_dialog_tag_manager',
  )
  const [tagManagerOpen, setTagManagerOpen] = useState(false)

  const { isEnabled: isChannelSettingsEnabled } = useSplitEnabled(
    'channel-settings-refresh',
  )

  return (
    <>
      <TagManagementDialog
        open={tagManagerOpen}
        onOpenChange={setTagManagerOpen}
      />
      <Sidebar.List>
        <Sidebar.ListItem>
          {newTagManagerEnabled && (
            <Sidebar.Button
              onClick={(): void => setTagManagerOpen(true)}
              prefix={<TagIcon />}
              className={styles.button}
            >
              {isAdmin ? 'Manage Tags' : 'View Tags'}
            </Sidebar.Button>
          )}
          {!newTagManagerEnabled && (
            <Sidebar.Button
              as={NavLink}
              to={tagsPage.route}
              prefix={<TagIcon />}
              className={styles.button}
            >
              {isAdmin ? 'Manage Tags' : 'View Tags'}
            </Sidebar.Button>
          )}
        </Sidebar.ListItem>
        <Sidebar.ListItem>
          {isAdmin ? (
            <>
              {isChannelSettingsEnabled && (
                <Sidebar.Button
                  as={NavLink}
                  to={settingsChannelsRoute.route}
                  prefix={<SettingsIcon />}
                  data-testid="profile-sidebar-channels-action"
                  className={styles.button}
                >
                  Manage Channels
                </Sidebar.Button>
              )}
              {!isChannelSettingsEnabled && (
                <Sidebar.Button
                  as="a"
                  href={`${getAccountURL()}/channels`}
                  prefix={<SettingsIcon />}
                  data-testid="profile-sidebar-channels-action"
                  className={styles.button}
                >
                  Manage Channels
                </Sidebar.Button>
              )}
            </>
          ) : (
            <Tooltip
              content={`It looks like you haven't been given permission to add/manage channels. Request access from ${
                ownerEmail ?? 'the owner'
              } or any other administrator.`}
            >
              <Sidebar.Button
                disabled
                prefix={<SettingsIcon />}
                data-testid="profile-sidebar-channels-action"
              >
                Manage Channels
              </Sidebar.Button>
            </Tooltip>
          )}
        </Sidebar.ListItem>
        {/* {featureOsWidget && (
          <Sidebar.ListItem>
            <Sidebar.Button
              className={styles.button}
              prefix={<QuestionIcon />}
              onSelect={featureOsWidget.open}
            >
              What&apos;s New
              <AlphaBadge size="small" style={{ marginLeft: '4px' }} />
            </Sidebar.Button>
          </Sidebar.ListItem>
        )} */}
      </Sidebar.List>
    </>
  )
}
