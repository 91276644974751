import React, { useState } from 'react'
import {
  Button,
  Flex,
  InfoIcon,
  Label,
  Textarea,
  Tooltip,
} from '@buffer-mono/popcorn'

import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import CharacterCount from './CharacterCount'
import type { Image } from '../entities/factories'

import styles from './css/ImageDescriptionInput.module.css'

const altExplanation = (
  <>
    <p>
      A description, sometimes called alt-text, makes your images and videos
      accessible to people unable to see them.
    </p>
    <p>The text will also appear in place of the image if it fails to load.</p>
    <p>
      Good descriptions are descriptive and specific. Add context to help people
      create a precise picture of your image.
    </p>
  </>
)

const characterCountLimitMap = {
  pinterest: 500,
  linkedin: 300,
  bluesky: 2000,
} as Record<string, number>

function ImageDescriptionInput({
  draftId,
  mediaAttachment,
}: {
  draftId: string
  mediaAttachment: Image
}): JSX.Element {
  const [altText, setAltText] = useState(mediaAttachment.altText || '')
  const [saveButtonText, setSaveButtonText] = useState('Save')
  const count = altText.length

  const characterCountLimit = characterCountLimitMap[draftId] || 1000

  return (
    <div className={styles.container}>
      <Label className={styles.descriptionLabel} htmlFor="image-description">
        Add a Description{' '}
        <Tooltip content={altExplanation}>
          <InfoIcon size="xsmall" />
        </Tooltip>
      </Label>
      <Flex align="center" gap="xs">
        <Textarea
          id="image-description"
          placeholder="Add a description for people with visual impairments"
          className={styles.textInput}
          value={altText}
          // eslint-disable-next-line jsx-a11y/no-autofocus -- In order to keep focus in modal we need to focus on something (limitation of the modal library)
          autoFocus
          onChange={function handleChange(event): void {
            setAltText(event.target.value)
            setSaveButtonText('Save')
          }}
        />
        <CharacterCount count={count} maxCount={characterCountLimit} />
        <div className={styles.button}>
          <Button
            onClick={function onClick(): void {
              ComposerActionCreators.updateImageAltText(
                mediaAttachment,
                altText,
              )
              setSaveButtonText('Saved!')

              setTimeout(() => {
                ModalActionCreators.closeModal()
              }, 800)
            }}
            disabled={count > characterCountLimit}
          >
            {saveButtonText}
          </Button>
        </div>
      </Flex>
    </div>
  )
}

export default ImageDescriptionInput
