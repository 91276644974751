import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import { actionTypes as orgActionTypes } from '~publish/legacy/organizations'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import { ActionTypes as ComposerActionTypes } from '~publish/legacy/composer/composer/state/ActionTypes'
import cloneDeep from 'lodash/cloneDeep'
import { toggleReminders } from '~publish/legacy/general-settings/thunks/toggleReminders'
import { refreshSingleProfile } from '~publish/legacy/profiles/thunks/fetchSingleProfile'
import { filterProfilesByOrg } from './utils'
import type { Profile, ProfileSidebarState } from './types'
import { toggleGoogleAnalyticsTrackingSettings } from '../general-settings/thunks/toggleGoogleAnalyticsTrackingSettings'
import { pauseQueue } from '../general-settings/thunks/pauseQueue'

export const actionTypes = {
  SELECT_PROFILE: 'PROFILE_SIDEBAR__SELECT_PROFILE',
  PROFILE_UNPAUSED: 'PROFILE_SIDEBAR__PROFILE_UNPAUSED',
  PROFILE_PAUSED: 'PROFILE_SIDEBAR__PROFILE_PAUSED',
  PUSHER_PROFILE_PAUSED_STATE: 'PROFILE_SIDEBAR__PUSHER_PROFILE_PAUSED_STATE',
  CONNECT_SOCIAL_ACCOUNT: 'PROFILE_SIDEBAR__CONNECT_SOCIAL_ACCOUNT',
  HANDLE_SEARCH_PROFILE_CHANGE: 'PROFILE_SIDEBAR__HANDLE_SEARCH_PROFILE_CHANGE',
  PROFILE_DROPPED: 'PROFILE_SIDEBAR__PROFILE_DROPPED',
} as const

// Action Interfaces
interface SelectProfileAction {
  type: typeof actionTypes.SELECT_PROFILE
  profileId: string | null
  profile: Profile | null
}

interface ProfileUnpausedAction {
  type: typeof actionTypes.PROFILE_UNPAUSED
  profileId: string
}

interface ProfilePausedAction {
  type: typeof actionTypes.PROFILE_PAUSED
  profileId: string
}

interface PusherProfilePausedStateAction {
  type: typeof actionTypes.PUSHER_PROFILE_PAUSED_STATE
  profileId: string
  paused: boolean
}

interface ConnectSocialAccountAction {
  type: typeof actionTypes.CONNECT_SOCIAL_ACCOUNT
}

interface HandleSearchProfileChangeAction {
  type: typeof actionTypes.HANDLE_SEARCH_PROFILE_CHANGE
  value: string
}

interface ProfileDroppedAction {
  type: typeof actionTypes.PROFILE_DROPPED
  commit: boolean
  dragIndex: number
  hoverIndex: number
  profileLimit: number
}

interface UpdateShortenerDomainAction {
  type: typeof ComposerActionTypes.UPDATE_SHORTENER_FOR_PROFILE
  payload: {
    profileId: string
    domain: string
  }
}

export type ProfileAction =
  | SelectProfileAction
  | ProfileUnpausedAction
  | ProfilePausedAction
  | PusherProfilePausedStateAction
  | ConnectSocialAccountAction
  | HandleSearchProfileChangeAction
  | ProfileDroppedAction
  | UpdateShortenerDomainAction

export const initialState: ProfileSidebarState = {
  profiles: [],
  profileList: [],
  selectedProfileId: '',
  organization: undefined,
  loading: false,
  loaded: false,
  selectedProfile: {},
  searchText: undefined,
  userId: undefined,
}

const moveProfileInArray = (arr: any[], from: number, to: number) => {
  const clone = [...arr]

  // Generate the new array
  Array.prototype.splice.call(
    clone,
    to,
    0,
    Array.prototype.splice.call(clone, from, 1)[0],
  )
  return clone
}

const handleProfileThreadsCanQueueChanged = (
  profiles: Profile[],
  action: {
    profileId?: string | null
    canQueueMoreThreads?: boolean
  },
): Profile[] => {
  const { profileId, canQueueMoreThreads } = action
  const profileIndexInArray = profiles.findIndex((p) => p.id === profileId)
  const clonedProfiles = cloneDeep(profiles)
  clonedProfiles[profileIndexInArray].canQueueMoreThreads =
    canQueueMoreThreads || false
  return clonedProfiles
}

const handleProfileDropped = (
  profiles: Profile[],
  action: {
    dragIndex?: number
    hoverIndex?: number
  },
): Profile[] => {
  const { hoverIndex, dragIndex } = action
  const reorderedProfiles = moveProfileInArray(
    profiles,
    dragIndex ?? 0,
    hoverIndex ?? 0,
  )

  return reorderedProfiles.map((profile) => {
    return {
      ...profile,
      disabled: false,
    }
  })
}

const profilesReducer = (
  state: Profile[] = [],
  action: ProfileAction,
): Profile[] => {
  switch (action.type) {
    case actionTypes.SELECT_PROFILE:
      return state.map((profile) => ({
        ...profile,
        open: profile.id === action.profileId,
      }))
    case actionTypes.PROFILE_PAUSED:
    case actionTypes.PROFILE_UNPAUSED:
      return state.map((profile) => ({
        ...profile,
        paused:
          profile.id === action.profileId
            ? action.type === actionTypes.PROFILE_PAUSED
            : profile.paused,
      }))
    case actionTypes.PUSHER_PROFILE_PAUSED_STATE:
      return state.map((profile) => ({
        ...profile,
        paused:
          profile.id === action.profileId ? action.paused : profile.paused,
      }))
    case actionTypes.HANDLE_SEARCH_PROFILE_CHANGE:
      return state.map((profile) => ({
        ...profile,
        filtered:
          !profile.handle.match(new RegExp(action.value, 'gi')) &&
          !profile.service.match(new RegExp(action.value, 'gi')),
      }))
    case ComposerActionTypes.UPDATE_SHORTENER_FOR_PROFILE: {
      const { domain, profileId } = action.payload

      return state.map((profile) => {
        if (profile.id !== profileId) {
          return profile
        }

        return {
          ...profile,
          shorteningDomain: domain,
        }
      })
    }

    default:
      return state
  }
}

export const getEnabledProfiles = (profiles: Profile[]): Profile[] =>
  profiles.filter((profile) => !profile.disabled)

export default (
  state: ProfileSidebarState = initialState,
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  action,
): ProfileSidebarState => {
  let searchText = null
  switch (action.type) {
    case `profiles_${dataFetchActionTypes.FETCH_START}`:
      // Ignore analyze specific actions so as not to flash loading state
      if (action.args && action.args.forAnalyze) {
        return state
      }
      return {
        ...state,
        loading: true,
      }

    case `profiles_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const profiles = action.result
      const profileList = getEnabledProfiles(profiles)

      return {
        ...state,
        loading: false,
        loaded: true,
        profileList,
        profiles: filterProfilesByOrg(profileList, state.organization),
      }
    }
    case ComposerActionTypes.UPDATE_SHORTENER_FOR_PROFILE: {
      return {
        ...state,
        profiles: profilesReducer(state.profiles, action),
      }
    }
    case orgActionTypes.ORGANIZATION_SELECTED: {
      const selectedOrganization = action.selected
      let { profiles } = state

      if (profiles) {
        const { profileList } = state
        profiles = filterProfilesByOrg(profileList, selectedOrganization)
      }

      return {
        ...state,
        organization: selectedOrganization,
        profiles,
      }
    }
    case actionTypes.SELECT_PROFILE: {
      return {
        ...state,
        selectedProfileId: action.profileId ?? '',
        profiles: profilesReducer(state.profiles, action),
        selectedProfile: action.profile,
      }
    }
    case actionTypes.HANDLE_SEARCH_PROFILE_CHANGE:
      searchText = action.value

      return {
        ...state,
        profiles: profilesReducer(state.profiles, action),
        searchText,
      }
    case `singleProfile_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `${toggleReminders.fulfilled}`:
    case `${refreshSingleProfile.fulfilled}`: {
      let { selectedProfile, profiles } = state
      const profileReceived = action.result || action.payload
      const { organization } = state
      const isInCurrentOrganization =
        organization?.id === profileReceived.organizationId

      if (selectedProfile?.id === profileReceived.id) {
        selectedProfile = profileReceived
      }

      if (profiles.some((p) => p.id === profileReceived.id)) {
        profiles = profiles.map((profile) => {
          if (profile.id === profileReceived.id) {
            return profileReceived
          }
          return profile
        })
      } else if (isInCurrentOrganization) {
        profiles = [...profiles, profileReceived]
      }

      return {
        ...state,
        profiles,
        selectedProfile,
      }
    }
    case `${toggleGoogleAnalyticsTrackingSettings.fulfilled}`: {
      const profileId = action.meta.arg.profileId
      let { selectedProfile, profiles } = state
      if (selectedProfile?.id === profileId) {
        selectedProfile = {
          ...selectedProfile,
          googleAnalyticsEnabled: action.payload.isEnabled,
        }
      }
      if (profiles.some((p) => p.id === profileId)) {
        profiles = profiles.map((profile) => {
          if (profile.id === profileId) {
            return {
              ...profile,
              googleAnalyticsEnabled: action.payload.isEnabled,
            }
          }
          return profile
        })
      }
      return {
        ...state,
        profiles,
        selectedProfile,
      }
    }
    case `${pauseQueue.fulfilled}`: {
      const profileId = action.meta.arg.profileId
      let { selectedProfile, profiles } = state
      if (selectedProfile && selectedProfile?.id === profileId) {
        selectedProfile = {
          ...selectedProfile,
          paused: !selectedProfile.paused,
        }
      }
      if (profiles.some((p) => p.id === profileId)) {
        profiles = profiles.map((profile) => {
          if (profile.id === profileId) {
            return {
              ...profile,
              paused: !profile.paused,
            }
          }
          return profile
        })
      }
      return {
        ...state,
        profiles,
        selectedProfile,
      }
    }
    case actionTypes.PROFILE_PAUSED:
    case actionTypes.PROFILE_UNPAUSED:
    case actionTypes.PUSHER_PROFILE_PAUSED_STATE: {
      return {
        ...state,
        profiles: profilesReducer(state.profiles, action),
      }
    }
    case `user_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      return {
        ...state,
        userId: action.result.id,
      }
    }
    case actionTypes.PROFILE_DROPPED: {
      if (!action.commit) {
        return {
          ...state,
          profiles: handleProfileDropped(state.profiles, action),
        }
      }
      return state
    }
    case queueActionTypes.PROFILE_CAN_QUEUE_THREADS_CHANGED: {
      if (!action.profileId) {
        return state
      }
      return {
        ...state,
        profiles: handleProfileThreadsCanQueueChanged(state.profiles, action),
      }
    }
    default:
      return state
  }
}

export const actions = {
  selectProfile: ({ profile }: { profile: Profile }): SelectProfileAction => ({
    type: actionTypes.SELECT_PROFILE,
    profileId: profile ? profile.id : null,
    profile,
  }),
  onPauseClick: ({
    profileId,
  }: {
    profileId: string
  }): ProfilePausedAction => ({
    type: actionTypes.PROFILE_PAUSED,
    profileId,
  }),
  onDropProfile: ({
    commit,
    dragIndex,
    hoverIndex,
    profileLimit,
  }: {
    commit: boolean
    dragIndex: number
    hoverIndex: number
    profileLimit: number
  }): ProfileDroppedAction => ({
    type: actionTypes.PROFILE_DROPPED,
    commit,
    dragIndex,
    hoverIndex,
    profileLimit,
  }),
}
