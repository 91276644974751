import { toast, useLocalStorage } from '@buffer-mono/popcorn'
import React, { useCallback, useEffect } from 'react'

import { useSplitEnabled } from '@buffer-mono/features'
import { useUser } from '../../common/context/User'
import { MODALS } from '../../common/types'
import type { Service } from '../../exports/Orchestrator/channelConnections/types'
import { getServiceName } from '../../exports/Orchestrator/channelConnections/utils'
import useChannels from '../Modal/hooks/useChannels'
import { ModalContent } from '../Modal/ModalContent'
import {
  cleanupUrlParams,
  shouldPrefillComposerDataAfterConnection,
  shouldShowChannelConnectionPrompt,
  shouldShowDownloadAppModal,
  shouldShowFreeUserStartTrialPrompt,
  shouldShowPaywallModal,
  shouldShowTeamMemberDowngradeModal,
  shouldShowTrialExpiredModal,
  shouldShowTrialSignupWelcomeModal,
  shouldSkipCelebrationModals,
  shouldTriggerChannelOnboardingModalFromUrl,
  shouldTriggerChannelRefreshModalFromUrl,
  shouldTriggerSuccessModalFromUrl,
  userHasCompletedAStreak,
  userHasSignedUpOneWeekAgo,
} from '../Modal/utils'
import { useWeeklyPostingStreak } from '../NavBar/components/StreakWidget/StreakWidget'
import { useModalManager } from './hooks/useModalManager'
import {
  handleChannelConnectionOnboardingViaURL,
  handleChannelRefreshViaURL,
} from './ModalManagerUtils'

const ModalManager = (): JSX.Element => {
  const { openModal } = useModalManager()
  const [promoteDownloadAppModal] = useLocalStorage(
    'promoteDownloadAppModal',
    true,
  )

  const { isEnabled: isResponsiveNavEnabled } = useSplitEnabled(
    'responsive-main-nav',
  )

  const shouldPromoteDownloadAppModal = isResponsiveNavEnabled
    ? promoteDownloadAppModal && shouldShowDownloadAppModal()
    : shouldShowDownloadAppModal()

  const user = useUser()
  const { connectedChannels = [], connectedChannelIds = [] } = useChannels()
  const { data: { weeklyPostingStreak: streak } = {} } = useWeeklyPostingStreak(
    user.currentOrganization?.id,
  )

  /** We are running an experiment to show the Trial Prompt after a user has
   * - Signed up >= 7 days ago
   * - Have a streak count >=1
   */
  const shouldShowDelayedTrialModal = useCallback((): boolean => {
    return (
      userHasSignedUpOneWeekAgo(user) &&
      userHasCompletedAStreak(streak) &&
      shouldShowFreeUserStartTrialPrompt(user)
    )
  }, [user, streak])

  useEffect(() => {
    if (shouldShowDelayedTrialModal()) {
      openModal({
        key: MODALS.startTrialReactive,
        data: {
          ctaButton: 'startTrial',
          ctaView: 'noclick',
          ctaLocation: 'firstStreakStartTrialModal',
          cta: 'noclick-startTrialModal-startTrial-1',
          upgradePathName: 'startTrial',
        },
      })
    }
  }, [streak, openModal, shouldShowDelayedTrialModal])

  useEffect(() => {
    if (!user?.id) return

    /* This modal must have the highest priority to be shown up to mobile users and avoid
     they see another modal which they cannot interact with */
    if (shouldPromoteDownloadAppModal) {
      openModal({
        key: MODALS.downloadApp,
      })
    }

    // Show Team Member Downgrade Modal
    if (shouldShowTeamMemberDowngradeModal(user)) {
      openModal({
        key: MODALS.teamMemberDowngrade,
      })
    }

    // Show Trial Expired Modal
    if (shouldShowTrialExpiredModal(user)) {
      openModal({
        key: MODALS.trialExpired,
        data: {
          ctaButton: 'noclick',
          upgradePathName: 'noclickTrialExpiredModal-upgrade',
          ctaView: 'trialExpired',
          ctaLocation: ' noclick',
        },
      })
    }

    if (shouldTriggerChannelOnboardingModalFromUrl()) {
      handleChannelConnectionOnboardingViaURL({ openModal })
    }

    if (shouldTriggerChannelRefreshModalFromUrl()) {
      handleChannelRefreshViaURL({ openModal })
    }

    if (shouldTriggerSuccessModalFromUrl()) {
      openModal({
        key: MODALS.success,
        data: {
          ctaButton: 'noclick',
          upgradePathName: 'noclickCheckoutSuccess-upgrade',
          ctaView: 'successModal',
          ctaLocation: ' noclickCheckoutSuccess',
        },
      })
      cleanupUrlParams()
    }

    // Show Channel Connection prompt
    if (shouldShowChannelConnectionPrompt(user)) {
      openModal({
        key: MODALS.channelConnectionPrompt,
      })
    }

    // Show Trial Signup Welcome Modal
    if (shouldShowTrialSignupWelcomeModal(user)) {
      openModal({
        key: MODALS.trialSignupWelcomeModal,
      })
    }

    // Show Paywall Modal
    if (shouldShowPaywallModal(user)) {
      openModal({
        key: MODALS.paywall,
      })
    }
  }, [user.currentOrganization?.id])

  useEffect(() => {
    // Open Composer with Prefilled Data
    if (connectedChannels && shouldPrefillComposerDataAfterConnection()) {
      if (window.__openComposer) {
        const cta = 'connectChannelOnboarding-previewMode'
        window.__openComposer(
          {
            channels: connectedChannelIds,
            cta: `publish-modal-${cta}-1`,
          },
          {
            prefillFromLocalStorage: true,
          },
        )
        if (shouldSkipCelebrationModals()) {
          const service = connectedChannels[0].service
          toast.success(
            `Great! your ${getServiceName(service as Service)} channel${
              connectedChannelIds.length > 1 ? 's have' : ' has'
            } been Connected 🎉`,
          )
        }
        cleanupUrlParams()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedChannels, window.__openComposer])

  if (!user?.id) return <></>

  return <ModalContent />
}

const ModalManagerMemo = React.memo(ModalManager)

export { ModalManagerMemo as ModalManager }
export default ModalManagerMemo
