import * as React from 'react'
export const PlantStarted = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    viewBox="0 0 80 81"
    aria-hidden="true"
    {...props}
  >
    <ellipse
      cx={14}
      cy={5.4}
      fill="#213130"
      rx={14}
      ry={5.4}
      transform="matrix(1 0 0 -1 25 66.6)"
    />
    <path stroke="#213130" strokeLinecap="round" d="M52.125 45.45h-26.25" />
    <g>
      <mask id="c" fill="#fff">
        <path d="M25 45h28zm25.2 0s.7 6.3-.7 9.9c-1.4 3.6-2.395 6.37-2.92 7.046C46.055 62.62 45.343 63 44.6 63H33.4c-.743 0-1.455-.38-1.98-1.054-.525-.675-2.22-5.246-2.92-7.046-.7-1.8-.7-9.9-.7-9.9" />
      </mask>
      <path
        fill="#D1BDFF"
        d="M25 45h28zm25.2 0s.7 6.3-.7 9.9c-1.4 3.6-2.395 6.37-2.92 7.046C46.055 62.62 45.343 63 44.6 63H33.4c-.743 0-1.455-.38-1.98-1.054-.525-.675-2.22-5.246-2.92-7.046-.7-1.8-.7-9.9-.7-9.9"
      />
      <path
        fill="#213130"
        d="M25 44a1 1 0 1 0 0 2v-2zm28 2a1 1 0 1 0 0-2v2zm-1.806-1.11a1 1 0 0 0-1.988.22l1.988-.22zM28.8 45a1 1 0 1 0-2 0h2zM25 46h28v-2H25v2zm25.2-1-.994.11v.003l.002.011a22.298 22.298 0 0 1 .024.245c.015.172.037.424.058.74.042.63.085 1.506.085 2.491 0 2.007-.179 4.322-.807 5.938l1.864.724c.772-1.984.943-4.619.943-6.662a39.436 39.436 0 0 0-.173-3.63l-.006-.058-.001-.016v-.004l-.001-.002-.994.11zm-1.632 9.538c-.707 1.817-1.297 3.387-1.785 4.611a33.255 33.255 0 0 1-.63 1.5c-.19.41-.31.615-.362.683l1.578 1.227c.21-.269.412-.665.6-1.075.2-.435.425-.976.672-1.594.49-1.23 1.098-2.845 1.791-4.628l-1.864-.724zm-2.777 6.794c-.369.473-.806.668-1.191.668v2c1.1 0 2.087-.564 2.77-1.44l-1.58-1.228zM44.6 62H33.4v2h11.2v-2zm-11.2 0c-.385 0-.822-.195-1.19-.668l-1.58 1.227C31.314 63.437 32.3 64 33.4 64v-2zm-1.19-.668c.005.007-.007-.008-.038-.061a3.935 3.935 0 0 1-.108-.2c-.086-.171-.19-.395-.309-.662a58.578 58.578 0 0 1-.8-1.934c-.576-1.45-1.165-3.018-1.523-3.937l-1.864.724c.342.881.951 2.499 1.528 3.952.29.73.579 1.434.83 2.003.126.284.246.543.355.758.1.198.22.418.35.584l1.578-1.227zm-2.778-6.794c-.117-.303-.241-1.011-.341-2.041a58.96 58.96 0 0 1-.204-3.26 121.263 121.263 0 0 1-.087-4.148V45h-2v.095l.001.263a123.734 123.734 0 0 0 .087 3.956c.044 1.133.11 2.336.212 3.377.097.995.235 1.974.468 2.571l1.864-.724z"
        mask="url(#c)"
      />
    </g>
    <path
      fill="#B0EC9C"
      stroke="#213130"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M35.756 34.61c1.552 1.287 2.539 3.54 3.244 5.955-2.82.644-4.936.644-6.77-.483-1.691-.966-3.243-3.058-4.23-6.76 3.949-.805 6.205 0 7.756 1.288zm3.539 10.89v-1.346c.012-4.109.21-1.829 3.584-11.357"
    />
    <path
      fill="#B0EC9C"
      stroke="#213130"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M45.206 25.667c-1.524 2.184-2.29 4.74-2.199 7.333 3.798-.183 6.596-1.1 8.595-2.567C53.601 28.6 54.8 26.217 55 22c-5.397.183-7.995 1.833-9.794 3.667z"
    />
  </svg>
)
