import React from 'react'
import { useReward } from 'react-rewards'

import {
  Flex,
  SimpleSpinner,
  Text,
  VisuallyHidden,
  EmptyState,
  CommentRoundIcon,
} from '@buffer-mono/popcorn'

import { range } from '~publish/helpers/range'
import type { Channel, CommentData, CommentStatus } from '~publish/gql/graphql'

import { useCommentsQuery } from '../hooks/useCommentsQuery'
import { CommentWrapper, CommentWrapperSkeleton } from '../CommentItem'
import type { BaseCommentsFilters } from '../CommentsFilters/useFilters'
import styles from './CommentsList.module.css'

export const CommentListSkeleton = (): JSX.Element => (
  <Flex
    as="ol"
    role="feed"
    direction="column"
    aria-busy
    tabIndex={0}
    aria-label="Comments list"
    className={styles.feed}
  >
    <VisuallyHidden>Loading comments</VisuallyHidden>
    {range(20).map((index) => (
      <li key={index}>
        <CommentWrapperSkeleton />
      </li>
    ))}
  </Flex>
)

const CommentsError = (): JSX.Element => (
  <Flex direction="column" gap="md">
    <Text>Error loading comments</Text>
  </Flex>
)

const CommentsEmptyState = (): JSX.Element => (
  <EmptyState size="large" variant="primary">
    <EmptyState.Icon>
      <CommentRoundIcon />
    </EmptyState.Icon>
    <EmptyState.Heading>No comments found</EmptyState.Heading>
    <EmptyState.Description>
      There are no comments to display at this time.
    </EmptyState.Description>
  </EmptyState>
)

const CommentsCelebration = (): JSX.Element => {
  const { reward } = useReward('comments-celebration', 'confetti', {
    spread: 110,
    elementCount: 180,
    startVelocity: 50,
    lifetime: 1000,
    colors: ['#5E68C2', '#FF3363', '#FFBB00', '#2AFF28', '#2AB6FF', '#EF0583'],
  })

  React.useEffect(() => {
    reward()
  }, [reward])

  return (
    <EmptyState size="large" variant="primary">
      <div id="comments-celebration" />
      <EmptyState.Icon>
        <CommentRoundIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>All caught up!</EmptyState.Heading>
      <EmptyState.Description>
        You&apos;ve handled all the comments. Great job! 🎉
      </EmptyState.Description>
    </EmptyState>
  )
}

type CommentsFeedProps = {
  comments: CommentData[]
  isFetchingMore: boolean
  lastElementRef: (node: HTMLElement | null) => void
  channel: Channel
  filters?: BaseCommentsFilters
}

const CommentsFeed = ({
  comments,
  isFetchingMore,
  lastElementRef,
  channel,
  filters,
}: CommentsFeedProps): JSX.Element => (
  <Flex
    as="ol"
    role="feed"
    aria-busy={isFetchingMore}
    direction="column"
    className={styles.feed}
    tabIndex={0}
    aria-label="Comments list"
  >
    {comments.map((comment, index) => (
      <CommentWrapper
        key={`main-${comment.id}-${index}`}
        ref={index === comments.length - 1 ? lastElementRef : undefined}
        comment={comment}
        channel={channel}
        currentStatusFilter={filters?.status}
        isFirstComment={index === 0}
      />
    ))}
    {isFetchingMore && (
      <li className={styles.loading}>
        <SimpleSpinner size="small" />
      </li>
    )}
  </Flex>
)

export const CommentsList = ({
  channel,
  filters,
}: {
  channel: Channel
  filters?: BaseCommentsFilters
}): JSX.Element => {
  const [hadInitialComments, setHadInitialComments] = React.useState(false)
  const { data, loading, error, fetchingMore, lastElementRef } =
    useCommentsQuery({
      channelIds: [channel.id],
      filters,
    })

  // Update hadInitialComments when we first get data
  React.useEffect(() => {
    if (data && data?.comments?.edges?.length > 0 && !hadInitialComments) {
      setHadInitialComments(true)
    }
  }, [data, hadInitialComments])

  if (loading && !fetchingMore) return <CommentListSkeleton />
  if (error) return <CommentsError />
  if (!data) return <CommentsEmptyState />

  const status = filters?.status as CommentStatus | 'all' | undefined
  const hasComments = data.comments.edges.length > 0

  // If we have no comments and we never had comments, show empty state
  if (!hasComments && !hadInitialComments) {
    return <CommentsEmptyState />
  }

  // If we have no comments but we had comments initially, and we're in unreplied filter,
  // it means we handled all comments
  if (!hasComments && hadInitialComments && status === 'unreplied') {
    return <CommentsCelebration />
  }

  const comments = data.comments.edges.map((edge) => edge.node as CommentData)

  return (
    <CommentsFeed
      comments={comments}
      isFetchingMore={fetchingMore}
      lastElementRef={lastElementRef}
      channel={channel}
      filters={filters}
    />
  )
}
