import { useState, useEffect } from 'react'

/**
 * Type representing the online status of a user's browser connection.
 * Can be either 'online' or 'offline'.
 */
export type OnlineStatus = 'online' | 'offline'

/**
 * Custom hook to monitor and return the user's browser connection status.
 *
 * Uses the browser's navigator.onLine property and listens to 'online'/'offline' events
 * to provide real-time connection status updates.
 *
 * @returns {OnlineStatus} The current connection status ('online' or 'offline')
 */
export const useOnlineStatus = (): OnlineStatus => {
  const [status, setStatus] = useState<OnlineStatus>(
    window.navigator.onLine ? 'online' : 'offline',
  )

  useEffect(() => {
    const handleOnline = () => setStatus('online')
    const handleOffline = () => setStatus('offline')

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return status
}

/**
 * Utility hook that returns true when the user's browser is offline.
 *
 * @returns {boolean} True if the browser is offline, false otherwise
 */
export const useIsOffline = (): boolean => useOnlineStatus() === 'offline'

/**
 * Utility hook that returns true when the user's browser is online.
 *
 * @returns {boolean} True if the browser is online, false otherwise
 */
export const useIsOnline = (): boolean => useOnlineStatus() === 'online'
