import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  Sidebar,
  // Icons
  AllChannelsIcon,
  AudienceIcon,
  BellIcon,
  BuildingIcon,
  DollarIcon,
  FlaskConicalIcon,
  GripIcon,
  MessageCircleHeartIcon,
  SlidersVerticalIcon,
  Text,
  UserIcon,
  Flex,
} from '@buffer-mono/popcorn'

import style from './SettingsSidebar.module.css'
import { getAccountUrl } from '@buffer-mono/app-shell/src/common/utils/urls'
import { useSplitEnabled } from '@buffer-mono/features'
import {
  betaFeaturesRoute,
  generalSettingsRoute,
  referAFriendRoute,
  settingsBillingRoute,
  settingsChannelsRoute,
} from '~publish/legacy/routes'
import useConnectedChannelsCount from './hooks/useConnectedChannelsCount'

const SettingsSidebar = ({
  isOpen,
  isMobile,
  onToggleSidebar,
}: {
  isOpen: boolean
  isMobile: boolean
  onToggleSidebar: () => void
}): JSX.Element => {
  const { isEnabled: channelSettingsEnabled } = useSplitEnabled(
    'channel-settings-refresh',
  )

  const { isEnabled: billingSettingsEnabled } = useSplitEnabled(
    'billing-settings-refresh',
  )

  const channelCountBadge = useConnectedChannelsCount()

  return (
    <Sidebar collapsible={isMobile ? 'icon' : 'none'}>
      <Sidebar.Content className={style.sidebar}>
        {/* Account Section */}
        <Sidebar.Header className={style.sidebarHeader}>
          <Flex justify="between" align="center">
            {isOpen && !isMobile && (
              <Text as="h2" size="lg" weight="bold">
                Settings
              </Text>
            )}
            {isMobile && <Sidebar.Trigger onClick={onToggleSidebar} />}
          </Flex>
        </Sidebar.Header>
        <Sidebar.Group>
          <Sidebar.GroupLabel>Account</Sidebar.GroupLabel>
          <Sidebar.GroupContent>
            <Sidebar.List>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<UserIcon />}
                  as={NavLink}
                  exact
                  to="/settings"
                >
                  Profile
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<SlidersVerticalIcon />}
                  as={NavLink}
                  to="/settings/preferences"
                >
                  Preferences
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<BellIcon />}
                  as={NavLink}
                  to="/settings/notifications"
                >
                  Notifications
                </Sidebar.Button>
              </Sidebar.ListItem>
            </Sidebar.List>
          </Sidebar.GroupContent>
        </Sidebar.Group>

        {/* Organization Section */}
        <Sidebar.Group>
          <Sidebar.GroupLabel>Organization</Sidebar.GroupLabel>
          <Sidebar.GroupContent>
            <Sidebar.List>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<BuildingIcon />}
                  as={NavLink}
                  to={generalSettingsRoute.route}
                >
                  General
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                {channelSettingsEnabled ? (
                  <Sidebar.Button
                    prefix={<AllChannelsIcon />}
                    as={NavLink}
                    to={settingsChannelsRoute.route}
                    suffix={
                      <Text style={{ fontSize: '13px' }} weight="medium">
                        {channelCountBadge}
                      </Text>
                    }
                  >
                    Channels
                  </Sidebar.Button>
                ) : (
                  <Sidebar.Button
                    prefix={<AllChannelsIcon />}
                    as={'a'}
                    href={getAccountUrl('channels')}
                    suffix={
                      <Text size="sm" weight="medium">
                        {channelCountBadge}
                      </Text>
                    }
                  >
                    Channels
                  </Sidebar.Button>
                )}
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<AudienceIcon />}
                  as={NavLink}
                  to="/settings/team"
                >
                  Team
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                {billingSettingsEnabled ? (
                  <Sidebar.Button
                    prefix={<DollarIcon />}
                    as={NavLink}
                    to={settingsBillingRoute.route}
                  >
                    Billing
                  </Sidebar.Button>
                ) : (
                  <Sidebar.Button
                    prefix={<DollarIcon />}
                    as={'a'}
                    href={getAccountUrl('billing')}
                  >
                    Billing
                  </Sidebar.Button>
                )}
              </Sidebar.ListItem>
            </Sidebar.List>
          </Sidebar.GroupContent>
        </Sidebar.Group>

        {/* Other Section */}
        <Sidebar.Group>
          <Sidebar.GroupLabel>Other</Sidebar.GroupLabel>
          <Sidebar.GroupContent>
            <Sidebar.List>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<GripIcon />}
                  as={NavLink}
                  to="/settings/apps-extras"
                >
                  Apps & Extras
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<FlaskConicalIcon />}
                  as={NavLink}
                  to={betaFeaturesRoute.route}
                >
                  Beta Features
                </Sidebar.Button>
              </Sidebar.ListItem>
              <Sidebar.ListItem>
                <Sidebar.Button
                  prefix={<MessageCircleHeartIcon />}
                  as={NavLink}
                  to={referAFriendRoute.route}
                >
                  Refer a Friend
                </Sidebar.Button>
              </Sidebar.ListItem>
            </Sidebar.List>
          </Sidebar.GroupContent>
        </Sidebar.Group>
      </Sidebar.Content>
    </Sidebar>
  )
}

export default SettingsSidebar
