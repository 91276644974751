import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import type { Preferences } from '~publish/gql/graphql'
import { useQuery } from '@apollo/client'

export const GetAccountPreferences = graphql(/* GraphQL */ `
  query getAccountPreferences {
    account {
      timezone
      preferences {
        startOfWeek
        timeFormat
      }
    }
  }
`)

type ExtendedPreferences = Preferences & {
  timezone?: string | null
}

type GetAccountPreferences = {
  preferences: ExtendedPreferences | null
  isLoading: boolean
  hasError: boolean
}

/**
 * Custom hook to query the Account preferences
 * @returns {GetAccountPreferences} The function to trigger querying the account preferences.
 */
export const usePreferences = (): GetAccountPreferences => {
  const { data, loading, error } = useQuery(GetAccountPreferences)

  if (!data?.account?.preferences && !loading && !error) {
    throw new Error('No preferences found for Account')
  }

  // Handle GraphQL errors if any
  if (error) {
    const unexpectedError = new Error(
      error?.message || 'Unexpected API response',
    )
    logError(unexpectedError, { metaData: { data, error } })
    throw unexpectedError
  }

  const preferences = {
    ...data?.account?.preferences,
    timezone: data?.account?.timezone,
  }

  return {
    preferences,
    isLoading: loading,
    hasError: !!error,
  }
}
