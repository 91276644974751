import { toast } from '@buffer-mono/popcorn'

import { graphql } from '~publish/gql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import { isOfType } from '~publish/helpers/typeGuards'

import { useFilters } from '../CommentsFilters/useFilters'

const UndismissCommentMutationGQL = graphql(/* GraphQL */ `
  mutation UndismissComment($input: UndismissCommentInput!) {
    undismissComment(input: $input) {
      __typename
      ... on UndismissCommentSuccess {
        comment {
          ... on BaseComment {
            __typename
            id
            status
            text
            updatedAt
            parentId
          }
          ... on CommentFromThirdParty {
            labels
          }
          ... on CommentFromBuffer {
            publishingStatus {
              status
              errorMessage
            }
          }
        }
      }
      ... on NotFoundError {
        message
      }
      ... on UnauthorizedError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

type UseUndismissCommentResult = {
  undismissComment: (commentId: string) => Promise<void>
  loading: boolean
}

export function useUndismissComment(): UseUndismissCommentResult {
  const [{ status }] = useFilters()

  const [mutate, { loading }] = useTypedMutation(
    UndismissCommentMutationGQL,
    (data) => data.undismissComment,
    {
      successTypename: 'UndismissCommentSuccess',
      update: (cache, { data }) => {
        const result = data?.undismissComment
        if (!isOfType(result, 'UndismissCommentSuccess')) {
          return
        }

        const updatedComment = result.comment
        if (!updatedComment) return

        // 1. Update the comment in the cache with the new status
        cache.modify({
          id: cache.identify({
            __typename: updatedComment.__typename,
            id: updatedComment.id,
          }),
          fields: {
            status: () => updatedComment.status,
          },
        })

        // 2. Decide if the comment should be removed from the current view
        // For undismiss, we remove from the list if we're in the "dismissed" view
        const shouldRemoveFromList = status === 'dismissed'

        if (shouldRemoveFromList) {
          // Find all comments queries in the cache and update them
          cache.modify({
            fields: {
              comments: (existingComments, { readField, storeFieldName }) => {
                const match = storeFieldName.match(/"status":\["(.*?)"\]/)
                const statusInStore = match ? match[1] : null

                // Skip if this isn't the current filter's query
                if (statusInStore !== status) {
                  return existingComments
                }

                // Filter out the comment from the edges array
                const newEdges = existingComments.edges.filter((edge: any) => {
                  const commentId = readField('id', readField('node', edge))
                  return commentId !== updatedComment.id
                })

                // Return the new list without the undismissed comment
                return {
                  ...existingComments,
                  edges: newEdges,
                }
              },
            },
          })
        }
      },
    },
  )

  const undismissComment = async (commentId: string): Promise<void> => {
    try {
      await mutate({
        variables: {
          input: {
            commentId,
          },
        },
      })
      toast.success('Comment undismissed successfully')
    } catch (error) {
      console.error('Error undismissing comment:', error)
      toast.error('An error occurred while undismissing the comment')
    }
  }

  /*
  // TODO: add specific error handling
  try {
    if (errors?.length) {
      const errorMessage =
        errors[0]?.message || 'An error occurred while dismissing the comment'
      toast.error(errorMessage)
      return
    }

    if (data?.undismissComment.__typename === 'UndismissCommentSuccess') {
      toast.success('Comment undismissed successfully')
    } else if (data?.undismissComment.__typename === 'NotFoundError') {
      toast.error('Comment not found')
    } else if (data?.undismissComment.__typename === 'UnauthorizedError') {
      toast.error('Unauthorized to undismiss comment')
    } else {
      toast.error('An error occurred while undismissing the comment')
    }
  } catch (error) {
    console.error('Error undismissing comment:', error)
    toast.error('An error occurred while undismissing the comment')
  }
  */

  return {
    undismissComment,
    loading,
  }
}
