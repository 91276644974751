import { Slot } from '@buffer-mono/popcorn'
import React from 'react'

type FileInputProps = React.ComponentPropsWithoutRef<'input'>

export const FileInput = ({
  children,
  ...props
}: FileInputProps): JSX.Element => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const inputId = React.useId()

  const handleClick = (): void => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <Slot.Root onClick={handleClick}>
      <Slot.Slottable>{children}</Slot.Slottable>
      <input
        id={inputId}
        name="file-upload-input"
        type="file"
        hidden
        ref={inputRef}
        {...props}
      />
    </Slot.Root>
  )
}
