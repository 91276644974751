import React from 'react'

import { Flex, Label, Switch } from '@buffer-mono/popcorn'

import type { DayOfWeek } from '~publish/gql/graphql'

import { DAY_OF_WEEK } from '../../../utils'

import styles from './ScheduleTableHeader.module.css'

interface ScheduleTableHeaderProps {
  day: DayOfWeek
  paused: boolean
  onTogglePause: () => void
  disabled?: boolean
  isScheduleEmpty: boolean
}

const ScheduleTableHeader = ({
  day,
  paused,
  onTogglePause,
  disabled = false,
  isScheduleEmpty,
}: ScheduleTableHeaderProps): JSX.Element => {
  const disablePauseToggle = disabled
  return (
    <Flex direction="column" align="center" gap="md" className={styles.header}>
      <Label>{DAY_OF_WEEK[day]}</Label>
      {!isScheduleEmpty && (
        <Flex align="center" gap="2xs">
          <Label className={styles.switchLabel}>{paused ? 'Off' : 'On'}</Label>
          <Switch
            size="small"
            checked={!paused}
            disabled={disablePauseToggle}
            onCheckedChange={onTogglePause}
            id={`${day}-schedule`}
          />
        </Flex>
      )}
    </Flex>
  )
}

export { ScheduleTableHeader }
