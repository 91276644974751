import { Button, Dialog } from '@buffer-mono/popcorn'
import React from 'react'
import { TrackRender } from '~publish/hooks/tracking/useTrackComponentRendered'
import { useIsScrolled } from '~publish/hooks/useIsScrolled'
import { parseOpml } from '../../helpers/parseOPML'
import {
  SelectFeedCards,
  type SelectFeedCardsProps,
} from '../SelectFeedCards/SelectFeedCards'
import { FileInput } from './FileInput'
import styles from './ImportFeedsDialog.module.css'
import type { WrappedCreateFeedResponse } from '../../hooks/useCreateFeed'

type ImportFeedsContentProps = Omit<SelectFeedCardsProps, 'onAddFeed'> & {
  remainingFeedsCount: number
  onAddFeed: (args: {
    feedUrl: string
    cta: 'add-from-url' | 'import-opml' | 'explore-feeds'
  }) => Promise<WrappedCreateFeedResponse>
}

const ImportFeedsContent = ({
  remainingFeedsCount,
  onAddFeed,
  ...props
}: ImportFeedsContentProps): JSX.Element => {
  const { scrollRef, isScrolled } = useIsScrolled()

  const handleAddFeed = React.useCallback(
    async ({
      feedUrl,
    }: {
      feedUrl: string
    }): Promise<WrappedCreateFeedResponse> => {
      return onAddFeed({ feedUrl, cta: 'import-opml' })
    },
    [onAddFeed],
  )
  return (
    <Dialog.Content size="large" portal={false} className={styles.content}>
      <TrackRender componentName="ImportFeedsDialog" />
      <Dialog.Header className={styles.heading}>
        <Dialog.Title size="large">Add your Feeds</Dialog.Title>
        <Dialog.Description
          aria-hidden={remainingFeedsCount > 8}
          className={styles.description}
        >
          You can add {remainingFeedsCount} more feeds
        </Dialog.Description>
      </Dialog.Header>
      <Dialog.Separator data-scroll={isScrolled} className={styles.separator} />
      <Dialog.Body className={styles.feedsBody} ref={scrollRef}>
        <SelectFeedCards {...props} onAddFeed={handleAddFeed} />
      </Dialog.Body>
      <Dialog.Separator />
      <Dialog.Footer className={styles.footer}>
        <Dialog.Close>
          <Button variant="secondary">Done</Button>
        </Dialog.Close>
      </Dialog.Footer>
    </Dialog.Content>
  )
}

export type ImportFeedsDialogProps = {
  enabled?: boolean
  isOpen?: boolean
  onOpenChange?: (open: boolean) => void
  children: JSX.Element
} & Omit<ImportFeedsContentProps, 'newFeeds'>

export const ImportFeedsDialog = ({
  enabled = true,
  onOpenChange,
  children,
  ...props
}: ImportFeedsDialogProps): JSX.Element => {
  const [open, setOpen] = React.useState(false)

  const [importedFeeds, setImportedFeeds] = React.useState<
    { url: string; title: string }[]
  >([])

  if (!enabled) {
    return children
  }

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }
    const reader = new FileReader()
    reader.onload = (readerLoad): void => {
      const raw = readerLoad.target?.result
      if (raw) {
        const opml = parseOpml(raw.toString())
        const uniqueFeeds = Array.from(
          new Map(opml.feeds.map((feed) => [feed.url, feed])).values(),
        )
        setImportedFeeds(uniqueFeeds)
        setOpen(true)
      }
    }
    reader.readAsText(file)
  }

  const handleOpenChange = (open: boolean): void => {
    setOpen(open)
    onOpenChange?.(open)
  }

  return (
    <>
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <Dialog.Portal>
          <ImportFeedsContent {...props} newFeeds={importedFeeds} />
        </Dialog.Portal>
      </Dialog>
      <FileInput
        onChange={handleFileUpload}
        accept=".opml,.xml"
        multiple={false}
      >
        {children}
      </FileInput>
    </>
  )
}
