import React from 'react'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import Notice from '@bufferapp/ui/Notice'
import Text from '@bufferapp/ui/Text'
import {
  ClickableIconContainer,
  IconContainer,
  NoticeContainer,
} from './styles'
import AppStore from '../../stores/AppStore'
import ComposerStore from '../../stores/ComposerStore'
import type Draft from '../../entities/Draft/Draft'

const NoticeWrapper = ({
  text,
  callback,
}: {
  text: string
  callback: () => void
}): JSX.Element => {
  return (
    <NoticeContainer>
      <Notice type="note" disableAnimation>
        <IconContainer>
          <WarningIcon size="medium" />
        </IconContainer>
        <Text>{text}</Text>
        <ClickableIconContainer onClick={callback}>
          <CrossIcon size="medium" />
        </ClickableIconContainer>
      </Notice>
    </NoticeContainer>
  )
}

const MastodonNotice = ({ draft }: { draft: Draft }): JSX.Element => {
  const [isMentionNoticeDismissed, setIsMentionNoticeDismissed] =
    React.useState(!!localStorage.getItem('isMastodonMentionNoticeDismissed'))

  React.useEffect(() => {
    if (isMentionNoticeDismissed) {
      localStorage.setItem('isMastodonMentionNoticeDismissed', 'true')
    }
  }, [isMentionNoticeDismissed])

  if (
    AppStore.getExpandedComposerId() !== draft.id ||
    !draft.service.isMastodon() ||
    !ComposerStore.getMeta().hasMentionsWithoutServer
  ) {
    return <></>
  }

  return (
    <>
      {ComposerStore.getMeta().hasMentionsWithoutServer &&
        !isMentionNoticeDismissed && (
          <NoticeWrapper
            text="We suggest using the full address (@example@server.com) when
          mentioning a user to ensure it links to the right account—otherwise
          the local user (@example) will be mentioned instead."
            callback={(): void => setIsMentionNoticeDismissed(true)}
          />
        )}
    </>
  )
}

export default MastodonNotice
