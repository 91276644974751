import React from 'react'
import {
  Button,
  DropdownMenu,
  FilterIcon,
  StatusDot,
} from '@buffer-mono/popcorn'
import type { DateRange } from './useFilters'

type SortOrder = 'asc' | 'desc'

type FilterAndSortMenuProps = {
  sortOrder?: SortOrder
  publishedAt?: DateRange
  onSortOrderChange: (value: SortOrder) => void
  onPublishedAtChange: (value: DateRange) => void
  onResetFilters: () => void
}

export const FilterAndSortMenu = ({
  sortOrder = 'desc',
  publishedAt = 'alltime',
  onSortOrderChange,
  onPublishedAtChange,
  onResetFilters,
}: FilterAndSortMenuProps): JSX.Element => {
  // Get active filters count to show on the button
  const getActiveFiltersCount = (): number => {
    let count = 0
    if (sortOrder && sortOrder !== 'desc') count += 1
    if (publishedAt && publishedAt !== 'alltime') count += 1
    return count
  }

  const activeFiltersCount = getActiveFiltersCount()

  // Create type-safe handlers for RadioGroup
  const handleSortChange = (value: string): void => {
    onSortOrderChange(value as SortOrder)
  }

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button size="medium" variant="tertiary">
          <FilterIcon />
          {activeFiltersCount > 0 && (
            <StatusDot style={{ background: 'var(--color-bg-brand)' }} />
          )}
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content align="start" data-no-focus-lock>
        <DropdownMenu.RadioGroup
          label="Sort By"
          value={sortOrder}
          onChange={handleSortChange}
        >
          <DropdownMenu.RadioItem value="desc">Newest</DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="asc">Oldest</DropdownMenu.RadioItem>
        </DropdownMenu.RadioGroup>

        <DropdownMenu.Separator />

        <DropdownMenu.RadioGroup
          label="Date Range"
          value={publishedAt}
          onChange={(value: string): void =>
            onPublishedAtChange(value as DateRange)
          }
        >
          <DropdownMenu.RadioItem value="last7days">
            Last 7 days
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="last30days">
            Last 30 days
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="last6months">
            Last 6 months
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="lastyear">
            Last 12 months
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="alltime">
            All time
          </DropdownMenu.RadioItem>
        </DropdownMenu.RadioGroup>
        <DropdownMenu.Separator />
        <DropdownMenu.Item onClick={onResetFilters}>
          Reset filters
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
