import {
  ArrowLeftIcon,
  Heading,
  IconButton,
  InfoIcon,
  InstagramGridIcon,
  ShopgridIcon,
  ToggleGroup,
  Tooltip,
} from '@buffer-mono/popcorn'
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { PageHeader } from '~publish/components/PageHeader'
import { PageLayout } from '~publish/components/PageLayout'
import { selectProfileById } from '~publish/legacy/profile-sidebar/selectors'
import { actions as profileActions } from '~publish/legacy/profiles/reducer'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import Grid from '~publish/legacy/grid/components/GridPreview'
import { BufferTrackerReact } from '@buffer-mono/tracking-plan'

export enum Views {
  InstagramPreview = 'instagram-preview',
  ShopGrid = 'shop-grid',
}

export const ChannelShopGrid = (): JSX.Element => {
  const { id: channelId } = useParams<{
    id: string
  }>()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)

  const profile = useAppSelector((state) => selectProfileById(state, channelId))
  const customLinksDetails = useAppSelector((state) => {
    return state.grid.byProfileId[channelId]?.customLinksDetails
  })

  const [selectedView, setSelectedView] = React.useState(Views.InstagramPreview)

  useEffect(() => {
    if (loading) {
      dispatch(
        profileActions.fetchSingleProfile({
          profileId: channelId,
          message: '',
        }),
      )
      setLoading(false)
    }
  }, [loading, profile, dispatch, channelId])

  useEffect(() => {
    BufferTrackerReact.instagramGridPreviewPageLoaded({
      product: 'publish',
      clientName: 'publishWeb',
      channelId,
    })
  }, [channelId])

  return (
    <PageLayout>
      <PageLayout.Header separator={false}>
        <PageLayout.HeaderRow>
          <PageHeader.Title>
            <IconButton
              as={Link}
              to={`/channels/${channelId}`}
              label="Back to Channel"
              tooltip="Back to Channel"
              variant="tertiary"
            >
              <ArrowLeftIcon />
            </IconButton>
            <Heading>Grid</Heading>
            <Tooltip content="This is an approximation of your post grid. It may differ on other devices, and natively posted images update every 24 hours.">
              <InfoIcon />
            </Tooltip>
          </PageHeader.Title>
          <ToggleGroup
            value={selectedView}
            onChange={(value): void => {
              if (value) {
                setSelectedView(value as Views)

                BufferTrackerReact.instagramGridPreviewToggleView({
                  product: 'publish',
                  clientName: 'publishWeb',
                  channelId,
                  view: value,
                })
              }
            }}
            size="large"
          >
            <ToggleGroup.Item value={Views.InstagramPreview}>
              <InstagramGridIcon />
              Instagram Preview
            </ToggleGroup.Item>
            <ToggleGroup.Item value={Views.ShopGrid}>
              <ShopgridIcon />
              Shop Grid
            </ToggleGroup.Item>
          </ToggleGroup>
        </PageLayout.HeaderRow>
      </PageLayout.Header>
      <PageLayout.Container
        style={
          {
            '--page-layout-max-width': '932px',
            '--page-layout-padding': 0,
          } as React.CSSProperties
        }
      >
        {!loading && profile && customLinksDetails && (
          <Grid
            selectedView={selectedView}
            profile={profile}
            customLinksDetails={customLinksDetails}
          />
        )}
      </PageLayout.Container>
    </PageLayout>
  )
}
