import React, { useState, useCallback, useRef, useEffect } from 'react'
import { FolderPlusIcon, Input, Sidebar } from '@buffer-mono/popcorn'
import styles from './FeedCollectionNameEditor.module.css'
import clsx from 'clsx'

interface FeedCollectionNameEditorProps {
  disabled?: boolean
  icon?: React.ReactNode
  defaultValue?: string
  className?: string
  onUpdate: (name: string) => void
  onClose: () => void
}

export function FeedCollectionNameEditor({
  disabled,
  defaultValue,
  icon,
  onUpdate,
  onClose,
  className,
}: FeedCollectionNameEditorProps): JSX.Element {
  const [name, setName] = useState(defaultValue ?? '')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.select()
    }
  }, [inputRef])

  const handleSubmit = useCallback(async () => {
    if (!name.trim()) return
    onUpdate(name.trim())
    setName('')
  }, [name, onUpdate])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        onUpdate(name)
        setName('')
      } else if (event.key === 'Escape') {
        onClose()
        setName('')
      }
    },
    [onUpdate, onClose, setName, name],
  )

  return (
    <Sidebar.ListItem
      className={clsx(styles.createButton, styles.editing, className)}
    >
      <Input
        ref={inputRef}
        value={name}
        prefix={
          icon ?? <FolderPlusIcon size="small" className={styles.iconWrapper} />
        }
        onChange={(e): void => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={name.trim() ? handleSubmit : onClose}
        disabled={disabled}
      />
    </Sidebar.ListItem>
  )
}
