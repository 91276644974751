import React from 'react'
import { serializeQueryParams } from '~publish/hooks/useQueryParam'
import type { SelectedTag } from '~publish/legacy/campaign/types'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { createPage, ideaNewRoute } from '~publish/legacy/routes'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectUserIdAndEmail } from '~publish/legacy/user/selectors'
import { createPostFromIdea as createPostFromIdeaThunk } from '../../pages/Create/state/thunks/createPostFromIdea' // Still not sure where this thunk should live so keeping in Create page for now
import type { ConstuctIdeaComposerUrl, DraftIdea } from './IdeaComposer'
import { useSaveIdea } from './useSaveIdea'
import type { Uploader, UploadMetadata } from '@buffer-mono/uploader'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'
import type { Service } from '~publish/gql/graphql'

export type IdeaComposerContextValue = {
  draftIdea?: DraftIdea
  error: string | null
  isLoading: boolean
  hasUnsavedChanges: boolean
  editor: BufferEditor
  uploader: Uploader
  isSidepanelOpen: boolean
  toggleSidepanel: () => void
  setIsSidepanelOpen: (open: boolean) => void
  setGroupId: (groupId: string | undefined) => void
  setTitle: (title: string) => void
  setText: (text: string) => void
  setSelectedTags: (tags: SelectedTag[]) => void
  setChannelServices: (channels: Service[]) => void
  setDate: (date: string | undefined) => void
  addFiles: (files: File[], source?: UploadMetadata['source']) => void
  createPostFromIdea: () => void
  constructIdeaComposerUrl: ConstuctIdeaComposerUrl
  saveDraftIdea: () => void
  onOpenChange: (open: boolean) => void
  closeComposer: () => void
}

export const IdeaComposerContext =
  React.createContext<IdeaComposerContextValue>(
    undefined as unknown as IdeaComposerContextValue,
  )

export const IdeaComposerProvider = ({
  draftIdea,
  editor,
  uploader,
  hasUnsavedChanges,
  onOpenChange,
  closeComposer,
  setDraftIdea,
  isSidepanelOpen,
  toggleSidepanel,
  setIsSidepanelOpen,
  addFiles,
  ...props
}: {
  draftIdea: DraftIdea
  editor: BufferEditor
  uploader: Uploader
  hasUnsavedChanges: boolean
  onOpenChange: (open: boolean) => void
  closeComposer: () => void
  setDraftIdea: React.Dispatch<React.SetStateAction<DraftIdea>>
  isSidepanelOpen: boolean
  toggleSidepanel: () => void
  setIsSidepanelOpen: (open: boolean) => void
  addFiles: (files: File[], source?: UploadMetadata['source']) => void
  children: React.ReactNode
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const [mediaError] = React.useState<string | null>(null)

  const { contentGenerationError } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )
  // const { valid: isMediaConvertible, message } = isMediaConvertibleToPost(
  //   draftIdea?.content?.media,
  // )
  /**
   * IDEA COMPOSER ACTIONS AND SETTERS
   */

  const {
    saveIdea,
    isLoading,
    error: saveIdeaError,
  } = useSaveIdea({
    onCompleted: closeComposer,
  })

  const saveDraftIdea = React.useCallback(() => {
    saveIdea(draftIdea)
  }, [draftIdea, saveIdea])

  const setGroupId = React.useCallback(
    (groupId: string | undefined) => {
      setDraftIdea((current) => ({ ...current, groupId }))
    },
    [setDraftIdea],
  )

  const setTitle = React.useCallback(
    (title: string) => {
      setDraftIdea((current) => ({
        ...current,
        content: { ...current.content, title },
      }))
    },
    [setDraftIdea],
  )

  const setText = React.useCallback(
    (text: string) => {
      setDraftIdea((current) => {
        if (current?.content?.text !== text) {
          return {
            ...current,
            content: { ...current.content, text },
          }
        }
        return current
      })
    },
    [setDraftIdea],
  )

  const setSelectedTags = React.useCallback(
    (tags: SelectedTag[]) => {
      setDraftIdea((current) => ({
        ...current,
        content: { ...current.content, tags },
      }))
    },
    [setDraftIdea],
  )

  const setChannelServices = React.useCallback(
    (services: Service[]) => {
      setDraftIdea((current) => ({
        ...current,
        content: { ...current.content, services },
      }))
    },
    [setDraftIdea],
  )

  const setDate = React.useCallback(
    (date: string | undefined) => {
      setDraftIdea((current) => ({
        ...current,
        content: { ...current.content, date },
      }))
    },
    [setDraftIdea],
  )

  /**
   * CREATE POST FROM IDEA
   */
  const user = useAppSelector(selectUserIdAndEmail)
  const createPostFromIdea = React.useCallback(() => {
    dispatch(
      createPostFromIdeaThunk({
        idea: draftIdea,
        user,
      }),
    )
    closeComposer()
  }, [closeComposer, dispatch, draftIdea, user])

  const constructIdeaComposerUrl =
    React.useCallback<ConstuctIdeaComposerUrl>(() => {
      const search = serializeQueryParams({
        source: 'create-ideaComposer-toolbar-copyComposerUrl-1',
        title: draftIdea.content.title,
        text: draftIdea.content.text,
        media: draftIdea.content?.media?.map((media) => media.url),
      })
      let publishUrl = 'https://publish.buffer.com'
      if (window.location.hostname === 'publish.local.buffer.com') {
        publishUrl = 'https://publish.local.buffer.com:8888'
      }

      // TODO: Remove manual encoding
      // Our FE uses query-string for better query param handling, like array format, eg "&media[]=".
      // While our FE understands the array format and doesn't need the brackets encoded,
      // there seems to be some naive query parsing happening in the login service or somewhere
      // that sees the brackets and encodes the URL again, causing double encoding.
      //
      // I've tried to track down where the double encoding happens but with no luck.
      //
      // Encoding/decoding on the FE is already handled by query-string, it just doesn't encode the array brackets.
      // Because of this, we don't want to use something like 'encodeUriComponent' because everything is already encoded.
      // To solve the problem, we leave encoding to query-string, but manually encode only array brackets to prevent double encoding.
      // This regex is a hack workaround to do that.
      const manuallyEncodedSearch = search.replace(
        /([?&]|^)([^=]+)\[]=/g,
        '$1$2%5B%5D=',
      )

      return `${publishUrl}${createPage.route}${ideaNewRoute.route}?${manuallyEncodedSearch}`
    }, [draftIdea])

  const error = saveIdeaError?.message || mediaError || contentGenerationError

  const contextValue = React.useMemo(
    () => ({
      draftIdea,
      editor,
      uploader,
      error,
      isLoading,
      hasUnsavedChanges,
      isSidepanelOpen,
      toggleSidepanel,
      setIsSidepanelOpen,
      setGroupId,
      setTitle,
      setText,
      setSelectedTags,
      setChannelServices,
      setDate,
      addFiles,
      createPostFromIdea,
      constructIdeaComposerUrl,
      saveDraftIdea,
      onOpenChange,
      closeComposer,
    }),
    [
      draftIdea,
      editor,
      uploader,
      error,
      isLoading,
      hasUnsavedChanges,
      toggleSidepanel,
      setIsSidepanelOpen,
      isSidepanelOpen,
      setGroupId,
      setTitle,
      setText,
      setSelectedTags,
      setChannelServices,
      setDate,
      addFiles,
      createPostFromIdea,
      constructIdeaComposerUrl,
      saveDraftIdea,
      onOpenChange,
      closeComposer,
    ],
  )
  return <IdeaComposerContext.Provider {...props} value={contextValue} />
}

export const useIdeaComposerState = (): IdeaComposerContextValue => {
  const context = React.useContext(IdeaComposerContext)
  if (!context) {
    throw new Error(
      'useIdeaComposer must be used within an IdeaComposerProvider',
    )
  }
  return context
}

export const useIdeaComposerEditor = (): BufferEditor => {
  const { editor } = useIdeaComposerState()
  return editor
}
