import React, { useEffect, useState } from 'react'
import { Button, CoachMark } from '@buffer-mono/popcorn'

type StepPropTypes = {
  index: number
  open?: boolean
  step: {
    title?: React.ReactNode
    content?: React.ReactNode
    data?: {
      actions?: {
        text: string
        action: string
      }[]
    }
    target?: string | HTMLElement
    callback?: () => void
    placement?: string
  }
  isLastStep: boolean
  closeProps: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  }
  primaryProps: {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  }
}

const getSpotlightPosition = (index: number): { top: string; left: string } => {
  switch (index) {
    default:
      return { top: '-38px', left: '0px' }
  }
}

/**
 * Cross-browser compatible scroll helper that works in all browsers
 * @param el - The element to scroll into view
 * @param options - The options for the scrollIntoView method
 * @returns A promise that resolves when the element is in view
 */
const scrollIntoView = function (
  el: HTMLElement,
  options: ScrollIntoViewOptions = {},
): Promise<unknown> {
  return new Promise(function (resolve) {
    // If the element is already in view, resolve immediately
    if (!isOutsideViewport(el)) {
      resolve(undefined)
      return
    }

    // Check if browser supports scrollend event
    if ('onscrollend' in window) {
      window.addEventListener('scrollend', resolve, { once: true })
    } else {
      // Fallback for browsers without scrollend support
      let timeoutId: ReturnType<typeof setTimeout> | null = null

      const handleScroll = (): void => {
        if (timeoutId !== null) {
          clearTimeout(timeoutId)
        }

        timeoutId = setTimeout(() => {
          window.removeEventListener('scroll', handleScroll)
          resolve(undefined)
        }, 150)
      }

      ;(window as Window).addEventListener('scroll', handleScroll)

      // Resolve after a maximum wait time to prevent hanging
      setTimeout(() => {
        if (timeoutId !== null) {
          clearTimeout(timeoutId)
        }
        window.removeEventListener('scroll', handleScroll)
        resolve(undefined)
      }, 2000)
    }

    el.scrollIntoView(options)
  })
}

/**
 * Checks if an HTML element is outside the viewport
 * @param element - The HTML element to check
 * @returns True if the element is completely outside the viewport, false otherwise
 */
function isOutsideViewport(element: HTMLElement): boolean {
  // Get the element's bounding rectangle
  const rect = element.getBoundingClientRect()

  // Get viewport dimensions
  const viewportWidth =
    window.innerWidth || document.documentElement.clientWidth
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight

  // Check if the element is completely outside the viewport
  return (
    rect.right < 0 || // Left of viewport
    rect.bottom < 0 || // Above viewport
    rect.left > viewportWidth || // Right of viewport
    rect.top > viewportHeight // Below viewport
  )
}
export function StepCoachmark({
  index,
  open,
  step,
  isLastStep,
  closeProps,
  primaryProps,
}: StepPropTypes): JSX.Element {
  const handleNextStep = (event: React.MouseEvent<HTMLElement>): void => {
    if (isLastStep) {
      closeProps.onClick(event)
      step.callback?.()
    } else {
      primaryProps.onClick(event)
    }
  }

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (step.target) {
      const element =
        typeof step.target === 'string'
          ? document.querySelector(step.target)
          : step.target
      if (element) {
        scrollIntoView(element as HTMLElement, {
          block: 'center',
          inline: 'center',
        }).then(() => {
          setVisible(true)
        })
      }
    }
  }, [step.target])

  const { top, left } = getSpotlightPosition(index)

  return (
    <CoachMark open={open && visible}>
      <CoachMark.Overlay>
        <CoachMark.Spotlight
          style={{
            '--spotlight-top': top,
            '--spotlight-left': left,
          }}
        />
      </CoachMark.Overlay>

      <CoachMark.Content
        showCloseIcon={false}
        side={step.placement as 'top' | 'left' | 'right' | 'bottom'}
        style={
          {
            '--coach-mark-max-width': '350px',
          } as React.CSSProperties
        }
      >
        <CoachMark.Title style={{ whiteSpace: 'normal' }}>
          {step.title}
        </CoachMark.Title>
        <CoachMark.Description>{step.content}</CoachMark.Description>
        <CoachMark.Footer>
          <CoachMark.Dismiss>
            <Button onClick={handleNextStep}>
              {step.data?.actions?.[0]?.text}
            </Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
