import { useLazyQuery } from '@apollo/client'

import { useCurrentOrganization } from '~publish/legacy/accountContext'
import { PusherEvent, usePusherEvent } from '~publish/services/pusher'

import { GetChannelInfo } from './ChannelPage'

const RELEVANT_EVENTS: Array<PusherEvent> = [
  PusherEvent.POST_CREATED,
  PusherEvent.POST_SENT,
  PusherEvent.POST_UPDATED,
  PusherEvent.POST_DELETED,
  PusherEvent.POSTS_REORDERED,
]

export function usePostingGoalAutoRefresh(
  channelId: string | undefined,
  { skip = false }: { skip?: boolean } = {},
): void {
  const organization = useCurrentOrganization()
  const organizationId = organization?.id

  const [fetchChannelInfo] = useLazyQuery(GetChannelInfo, {
    fetchPolicy: 'network-only',
  })

  usePusherEvent(RELEVANT_EVENTS, async () => {
    if (organizationId && channelId && !skip) {
      await fetchChannelInfo({
        variables: {
          organizationId,
          channelId,
        },
      })
    }
  })
}
