import React, { type ReactElement, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  SelectWithSkeleton,
  TextWithSkeleton,
  NavTag,
} from '~publish/legacy/shared-components'
import { Tooltip, AlertDialog, Button, toast } from '@buffer-mono/popcorn'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'
import { Locked } from '@bufferapp/ui/Icon'
import { allChannels } from '~publish/legacy/routes'
import type { Tag } from '~publish/legacy/campaign/types'

import {
  useAccount,
  useOrganizationBilling,
} from '~publish/legacy/accountContext'
import UserEntity from '~publish/legacy/user/UserEntity'
import truncateText from '../../../../utils/truncateText'
import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'
import {
  IconsWrapper,
  ButtonWrapper,
  Color,
  Container,
  Group,
  LeftWrapper,
  NameContainer,
  StyledLink,
} from './style'

import { SEGMENT_NAMES, TAGS_REPORT_PAYWALL_DATA } from '../../../../constants'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { TagEditDialog } from '~publish/components/TagEditDialog'
import { useDeleteTag, useUpdateTag } from '~publish/hooks/useTags'
import type { Tag as TagType } from '~publish/gql/graphql'

type PropType = {
  tag: Tag
  ideasCounts: Array<{ tagId: string; count: number }>
  displaySkeleton?: boolean
  goToAnalyzeReport: (tag: Tag) => void
  hideAnalyzeReport: boolean
  showTagsAction: boolean
  showIdeas: boolean
}

type SelectItem = {
  title: string
  selectedItemClick: () => void
  disabled?: boolean
  icon?: ReactElement
  iconEnd?: boolean
  canViewReport?: boolean
}

export const TagListItem = ({
  tag,
  ideasCounts,
  goToAnalyzeReport,
  displaySkeleton = false,
  hideAnalyzeReport,
  showTagsAction = false,
  showIdeas = true,
}: PropType): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const tagId = tag.id
  const account = useAccount()
  const isAdmin = UserEntity.isAdmin(account)
  const currentOrganization = account?.account?.currentOrganization
  const billing = useOrganizationBilling()
  const isFree = billing?.subscription?.plan?.id === 'free'
  const commonTrackingProps =
    currentOrganization?.commonTrackingProperties || null
  const canStartTrial = billing?.canStartTrial
  const upgradePathName = canStartTrial
    ? 'tagsReport-trial'
    : 'tagsReport-upgrade'
  const ideasCountForTag = ideasCounts.find((el) => el.tagId === tag.id)
  const ideasCount = ideasCountForTag?.count || 0
  const showViewReportSkeleton = tag.canViewReport === undefined

  const cta = canStartTrial
    ? SEGMENT_NAMES.TAGS_LIST_REPORT_PRO_TRIAL
    : SEGMENT_NAMES.TAGS_LIST_REPORT_PRO_UPGRADE

  const [showEditModal, setEditModal] = React.useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false)
  const deleteTag = useDeleteTag()
  const updateTag = useUpdateTag()
  const handleSaveTag = async (tag: TagType): Promise<void> => {
    try {
      await updateTag(tag)
    } catch (error) {
      toast.error((error as Error).message)
    }
  }
  const handleDeleteTag = useCallback(async (): Promise<void> => {
    try {
      await deleteTag(tag.id)
      toast.success('Your tag has been deleted!')
    } catch (error) {
      toast.error((error as Error).message)
    }
  }, [deleteTag, tag.id])

  const selectItems: SelectItem[] = [
    {
      title: t('campaigns.viewCampaign.editTag'),
      selectedItemClick: (): void => {
        setEditModal(true)
      },
      disabled: !isAdmin || !showTagsAction || tag.locked,
    },
    {
      title: t('campaigns.viewCampaign.deleteTag'),
      selectedItemClick: (): void => {
        setShowDeleteAlert(true)
      },
      disabled: !isAdmin || !showTagsAction,
    },
  ]
  if (!hideAnalyzeReport) {
    selectItems.push({
      title: t('campaigns.viewCampaign.viewReport'),
      selectedItemClick: (): void => {
        onClickViewReport(tag)
      },
      disabled: tag.locked || !tag.canViewReport,
      icon: isFree ? <NavTag type="paywall" /> : undefined,
      iconEnd: true,
    })
  }

  const onOpenSelect = (): void => {
    if (showViewReportSkeleton) {
      dispatch(
        dataFetchActions.fetch({
          name: 'canViewReport',
          args: {
            tagId,
          },
        }),
      )
    }
  }

  const trackCTAClicked = (): void => {
    BufferTracker.cTAClicked({
      organizationId: currentOrganization?.id || undefined,
      clientName: 'publishWeb',
      cta,
      upgradePathName,
      product: 'publish',
      ...commonTrackingProps,
    })
  }

  const onClickViewReport = (tag: Tag): void => {
    if (isFree && currentOrganization) {
      const { MODALS, actions } = window?.appshell || {}
      if (actions && MODALS) {
        actions.openModal(MODALS.featurePaywall, TAGS_REPORT_PAYWALL_DATA)
        trackCTAClicked()
      }
    } else {
      goToAnalyzeReport(tag)
    }
  }

  const tagRoute = allChannels.getRoute({ tagIds: [tag.id] })
  const goToSelectedCampaign = (): void => {
    if (!tag.locked) {
      history.push(tagRoute)
    }
  }

  const NameWrapper: React.ElementType = displaySkeleton
    ? NameContainer
    : StyledLink

  return (
    <>
      <AlertDialog open={showDeleteAlert}>
        <AlertDialog.Content>
          <AlertDialog.Title>
            Are you sure you want to delete your tag, &quot;{tag.name}&quot;?
          </AlertDialog.Title>
          <AlertDialog.Description>
            We won&apos;t be able to save or analyze any of your tag data. If
            you also wish to stop the posts from within this tag from
            publishing, please delete each one individually.
          </AlertDialog.Description>
          <AlertDialog.Actions>
            <AlertDialog.Cancel>
              <Button
                variant="tertiary"
                onClick={(): void => setShowDeleteAlert(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button variant="critical" onClick={handleDeleteTag}>
                Yes, delete tag
              </Button>
            </AlertDialog.Action>
          </AlertDialog.Actions>
        </AlertDialog.Content>
      </AlertDialog>
      <TagEditDialog
        tag={tag}
        onSubmit={handleSaveTag}
        open={showEditModal}
        onOpenChange={setEditModal}
      />
      <Container onClick={goToSelectedCampaign} locked={tag.locked}>
        <LeftWrapper>
          <NameWrapper to={tagRoute}>
            <Color color={tag.color} displaySkeleton={displaySkeleton} />
            <Tooltip content={tag.name} side="top">
              <TextWithSkeleton
                type="h3"
                displaySkeleton={displaySkeleton}
                aria-label={displaySkeleton ? t('common.loading') : null}
                color="grayDark"
              >
                {truncateText(tag.name, 25)}
              </TextWithSkeleton>
            </Tooltip>
          </NameWrapper>
        </LeftWrapper>
        {showIdeas && (
          <Group>
            <TextWithSkeleton
              color="grayDark"
              type="p"
              displaySkeleton={displaySkeleton}
            >
              {ideasCount}
            </TextWithSkeleton>
          </Group>
        )}
        <Group>
          <TextWithSkeleton
            type="p"
            displaySkeleton={displaySkeleton}
            color="grayDark"
          >
            {tag.draftsCount}
          </TextWithSkeleton>
        </Group>
        <Group>
          <TextWithSkeleton
            color="grayDark"
            type="p"
            displaySkeleton={displaySkeleton}
          >
            {tag.approvalsCount}
          </TextWithSkeleton>
        </Group>
        <Group>
          <TextWithSkeleton
            color="grayDark"
            type="p"
            displaySkeleton={displaySkeleton}
          >
            {tag.scheduled}
          </TextWithSkeleton>
        </Group>
        <Group>
          <TextWithSkeleton
            color="grayDark"
            type="p"
            displaySkeleton={displaySkeleton}
          >
            {tag.sent}
          </TextWithSkeleton>
        </Group>
        <IconsWrapper>
          {tag.locked && <Locked />}
          {showTagsAction && (
            <ButtonWrapper showViewReportSkeleton={showViewReportSkeleton}>
              <SelectWithSkeleton
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                disabled={displaySkeleton}
                displaySkeleton={displaySkeleton}
                onSelectClick={(selectedItem: SelectItem): void => {
                  selectedItem.selectedItemClick()
                }}
                onOpen={onOpenSelect}
                hasIconOnly
                icon={<MoreIcon />}
                xPosition="right"
                items={selectItems}
              />
            </ButtonWrapper>
          )}
        </IconsWrapper>
      </Container>
    </>
  )
}
