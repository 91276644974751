import React, { useCallback, useMemo, useState } from 'react'

import {
  Button,
  IconButton,
  Dialog,
  Flex,
  Input,
  SearchIcon,
  SwatchGroup,
  Text,
  useControllableState,
  DropdownMenu,
  PlusIcon,
  Card,
  EllipsisIcon,
  PencilIcon,
  AllChannelsIcon,
  TrashIcon,
  BarChartIcon,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'

import { allChannels } from '~publish/legacy/routes'
import { getAnalyzeReportUrlForTags } from '~publish/legacy/utils/formatters/getURL'
import { useAccount } from '~publish/legacy/accountContext'
import UserEntity from '~publish/legacy/user/UserEntity'
import { useTags } from '~publish/hooks/useTags'
import { colors, selectColor } from '../TagEditDialog/colors'
import { TagEditDialog } from '../TagEditDialog/TagEditDialog'

import styles from './TagManagementDialog.module.css'
import type { Tag } from '~publish/gql/graphql'

export interface TagManagementDialogProps {
  children?: React.ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function TagManagementDialog({
  onOpenChange: onOpenChangeProp,
  open: openProp,
  children,
}: TagManagementDialogProps): JSX.Element {
  // State
  const [open, onOpenChange] = useControllableState({
    prop: openProp,
    onChange: onOpenChangeProp,
  })

  const [openDropdown, setOpenDropdown] = useState<string | null>(null)

  const [isCreatingNewTag, setIsCreatingNewTag] = useState(false)
  const [editingTagId, setEditingTagId] = useState<string | null>(null)
  const [filter, setFilter] = useState('')

  const account = useAccount()
  const isAdmin = UserEntity.isAdmin(account)
  const [loading, setLoading] = useState(false)
  const { tags, updateTag, deleteTag, createTag } = useTags()

  const editingTag = useMemo(() => {
    return tags.find((tag) => tag.id === editingTagId)
  }, [editingTagId, tags])

  const usedColors = useMemo(() => {
    return tags.map((tag) => tag.color)
  }, [tags])

  const filteredTags = useMemo(() => {
    return tags.filter((tag) =>
      tag.name.toLowerCase().includes(filter.toLowerCase()),
    )
  }, [filter, tags])

  const reportUrls = useMemo(() => {
    const reports = new Map<string, string>()
    tags.forEach((tag) => {
      reports.set(tag.id, getAnalyzeReportUrlForTags(tag.id))
    })
    return reports
  }, [tags])

  const postViewUrls = useMemo(() => {
    const urls = new Map<string, string>()
    tags.forEach((tag) => {
      urls.set(tag.id, allChannels.getRoute({ tagIds: [tag.id] }))
    })
    return urls
  }, [tags])

  const handleCreateNewTag = useCallback(
    async (tag: Tag) => {
      setLoading(true)
      await createTag({
        name: tag.name,
        color: tag.color,
      })
      setIsCreatingNewTag(false)
      setLoading(false)
    },
    [createTag],
  )

  const onDeleteTag = useCallback(
    async (tagId: string) => {
      setLoading(true)
      await deleteTag(tagId)
      setLoading(false)
    },
    [deleteTag],
  )

  const onSubmitColor = useCallback(
    async (newColor: string, tagId: string) => {
      const tag = tags.find((tag) => tag.id === tagId)
      setLoading(true)
      if (tag && newColor !== tag.color) {
        await updateTag({ id: tag.id, name: tag.name, color: newColor })
      }
      setLoading(false)
    },
    [tags, updateTag],
  )

  const onSubmitName = useCallback(
    async (tag: Tag) => {
      setLoading(true)
      if (tag) {
        await updateTag(tag)
      }
      setEditingTagId(null)
      setLoading(false)
    },
    [updateTag, setEditingTagId],
  )

  return (
    <>
      {isCreatingNewTag && (
        <TagEditDialog
          tag={{ name: 'New Tag', color: selectColor(usedColors) }}
          onSubmit={handleCreateNewTag}
          open={isCreatingNewTag}
          onOpenChange={(): void => setIsCreatingNewTag(false)}
        />
      )}
      {editingTag && (
        <TagEditDialog
          tag={editingTag}
          onSubmit={onSubmitName}
          open={editingTagId !== null}
          onOpenChange={(): void => setEditingTagId(null)}
        />
      )}
      <Dialog onOpenChange={onOpenChange} open={open}>
        {children && <Dialog.Trigger>{children}</Dialog.Trigger>}
        <Dialog.Content size="medium">
          <Dialog.Header>
            <Dialog.Title>{isAdmin ? 'Manage Tags' : 'View Tags'}</Dialog.Title>
          </Dialog.Header>
          <VisuallyHidden>
            <Dialog.Description>
              {isAdmin
                ? 'This dialog allows you to manage your tags. You can create new tags, edit existing ones, and delete tags you no longer need.'
                : 'This dialog allows you to view your tags. Only administrators can create, edit, or delete tags.'}
            </Dialog.Description>
          </VisuallyHidden>
          <Dialog.Body>
            <Dialog.CloseButton />
            <Flex
              gap="sm"
              justify="start"
              direction="row"
              className={styles.createNewTagActionBar}
            >
              <Input
                prefix={<SearchIcon />}
                size="large"
                placeholder="Search tags"
                value={filter}
                className={styles.filterInput}
                onChange={(e): void => setFilter(e.target.value)}
              />
              <Button
                disabled={loading}
                variant="primary"
                size="large"
                type="button"
                onClick={(): void => setIsCreatingNewTag(true)}
                className={styles.newTagButton}
              >
                <PlusIcon />
                New Tag
              </Button>
            </Flex>
            <div className={styles.manageTagsTable}>
              {filteredTags.length === 0 && (
                <Flex
                  justify="center"
                  align="center"
                  direction="column"
                  gap="sm"
                >
                  <Text size="lg" weight="medium">
                    No tags found
                  </Text>
                </Flex>
              )}
              {filteredTags.map((tag) => (
                <Card key={tag.id} className={styles.manageTagsRow}>
                  <div className={styles.manageTagsCell}>
                    <DropdownMenu>
                      <DropdownMenu.Trigger disabled={loading}>
                        <button
                          className={styles.selectColorButton}
                          style={{ backgroundColor: tag.color }}
                          aria-label={`Select color ${tag.color}`}
                        />
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content>
                        <label
                          htmlFor="colorPicker"
                          className={styles.pickerLabel}
                        >
                          Select Color
                        </label>
                        <SwatchGroup
                          id="colorPicker"
                          onChange={(evt): void => {
                            onSubmitColor(evt.target.value, tag.id)
                          }}
                          className={styles.pickerSwatchGroup}
                          value={tag.color}
                        >
                          {colors.map(({ color, name }) => (
                            <SwatchGroup.Swatch
                              key={color}
                              color={color}
                              name={name}
                              aria-label={`color ${color}`}
                            />
                          ))}
                        </SwatchGroup>
                      </DropdownMenu.Content>
                    </DropdownMenu>
                  </div>
                  <div className={clsx(styles.manageTagsCell, styles.grow)}>
                    {editingTagId !== tag.id && (
                      <Text size="lg" weight="medium">
                        {tag.name}
                      </Text>
                    )}
                  </div>
                  <div
                    className={clsx(
                      styles.manageTagsCell,
                      styles.actionBar,
                      openDropdown !== tag.id && styles.visibleOnHover,
                    )}
                  >
                    <IconButton
                      variant="tertiary"
                      label="Edit Tag"
                      onClick={(): void => setEditingTagId(tag.id)}
                    >
                      <PencilIcon />
                    </IconButton>
                    <DropdownMenu
                      open={openDropdown === tag.id}
                      onOpenChange={(open): void =>
                        setOpenDropdown(open ? tag.id : null)
                      }
                    >
                      <DropdownMenu.Trigger asChild>
                        <IconButton variant="tertiary" label="Actions">
                          <EllipsisIcon />
                        </IconButton>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content>
                        <DropdownMenu.Item asChild>
                          <a href={postViewUrls.get(tag.id)}>
                            <AllChannelsIcon />
                            View Posts
                          </a>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item asChild>
                          <a href={reportUrls.get(tag.id)}>
                            <BarChartIcon />
                            Open Reporting
                          </a>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          variant="critical"
                          onClick={(): void => {
                            onDeleteTag(tag.id)
                          }}
                        >
                          <TrashIcon />
                          Delete
                        </DropdownMenu.Item>
                      </DropdownMenu.Content>
                    </DropdownMenu>
                  </div>
                </Card>
              ))}
            </div>
          </Dialog.Body>
        </Dialog.Content>
      </Dialog>
    </>
  )
}
