import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { getActiveProductFromPath } from '../common/utils/getProduct'
import type { Account } from '../common/types'

import {
  getCommonTrackingPropertiesFromAccount,
  shouldPerformTrackingEvent,
} from './utils'

export type TrackStreaksButtonClickedPayload = {
  ctaButton: string
  ctaView: string
  ctaLocation: string
  ctaVersion?: string
  streakStatus: string
  streakCount: number
}

type TrackStreaksButtonClickedArguments = {
  payload: TrackStreaksButtonClickedPayload
  user: Account
}

export default function trackStreaksButtonClicked({
  payload,
  user,
}: TrackStreaksButtonClickedArguments): void {
  const canPerformTrackingEvent = shouldPerformTrackingEvent({
    user,
  })

  if (!canPerformTrackingEvent) {
    return
  }

  const {
    ctaView,
    ctaLocation,
    ctaButton,
    ctaVersion,
    streakStatus,
    streakCount,
  } = payload

  const version = ctaVersion || '1'

  const organization = user.currentOrganization
  const product = getActiveProductFromPath()
  const cta = `${product}-${ctaView}-${ctaLocation}-${ctaButton}-${version}`

  const commonTrackingProperties = getCommonTrackingPropertiesFromAccount(user)

  const eventData = {
    organizationId: organization?.id || '',
    cta,
    clientName: 'publishWeb',
    product,
    streakCount,
    streakStatus,
    ...commonTrackingProperties,
  }

  BufferTracker.streakButtonClicked(eventData)
}
