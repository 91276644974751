import React from 'react'
import Banner from '../../../../components/Banner'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import { orangeDarker } from '@bufferapp/ui/style/colors'

import {
  getManageChannelsURL,
  isOnAccountChannelsRoute,
} from '../../../../common/utils/urls'
import { ButtonWrapper } from './styles'

const XMigrationBanner = (): JSX.Element => {
  return (
    <Banner
      customHTML={
        <>
          <Text type="p" color={orangeDarker}>
            <strong>ACTION REQUIRED:</strong> Refresh your X (Twitter)
            connection to continue publishing and accessing analytics
          </Text>
          {!isOnAccountChannelsRoute() && (
            <ButtonWrapper>
              <Button
                type="orange"
                onClick={(): void => {
                  window.location.href = getManageChannelsURL()
                }}
                label={'Go to Channels'}
                size="small"
              />
            </ButtonWrapper>
          )}
        </>
      }
      themeColor="orange"
      dismissible={false}
    />
  )
}

export default XMigrationBanner
