import { toast } from '@buffer-mono/popcorn'
import { graphql } from '~publish/gql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'

export enum DismissAllCommentsStatus {
  Processed = 'processed',
  Enqueued = 'enqueued',
}

export type DismissAllCommentsByChannelIdInput = {
  organizationId: string
  channelId: string
}

const DismissAllCommentsByChannelIdMutationGQL = graphql(/* GraphQL */ `
  mutation DismissAllCommentsByChannelId(
    $input: DismissAllCommentsByChannelIdInput!
  ) {
    dismissAllCommentsByChannelId(input: $input) {
      __typename
      ... on DismissAllCommentsSuccess {
        status
        affectedCommentCount
      }
      ... on NotFoundError {
        message
      }
      ... on UnauthorizedError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

type UseDismissAllCommentResult = {
  dismissAllCommentsByChannelId: ({
    organizationId,
    channelId,
  }: DismissAllCommentsByChannelIdInput) => Promise<void>
  loading: boolean
}

export const useDismissAllCommentsByChannelId =
  (): UseDismissAllCommentResult => {
    const [mutate, { loading }] = useTypedMutation(
      DismissAllCommentsByChannelIdMutationGQL,
      (data) => data.dismissAllCommentsByChannelId,
      {
        successTypename: 'DismissAllCommentsSuccess',
        refetchQueries: ['Comments'],
      },
    )

    const dismissAllCommentsByChannelId = async ({
      organizationId,
      channelId,
    }: DismissAllCommentsByChannelIdInput): Promise<void> => {
      try {
        const response = await mutate({
          variables: {
            input: {
              organizationId,
              channelId,
            },
          },
        })
        if (response.data?.status === DismissAllCommentsStatus.Processed) {
          const dismissedCount = response.data?.affectedCommentCount || 0
          if (dismissedCount === 0) {
            toast.success("We couldn't find any comment to dismiss")
          } else {
            toast.success(
              `Successfully dismissed ${dismissedCount} comment${
                dismissedCount === 1 ? '' : 's'
              }`,
            )
          }
        } else {
          throw new Error('Failed to dismiss comments. Unknown error')
        }
      } catch (error) {
        toast.error('An error occurred while dismissing the comment')
      }
    }
    return {
      dismissAllCommentsByChannelId,
      loading,
    }
  }
