import { Button, Dialog } from '@buffer-mono/popcorn'
import React from 'react'
import { Limits } from '../../constants'
import styles from './HardLimitReachedDialog.module.css'
import clsx from 'clsx'
import { TrackRender } from '~publish/hooks/tracking'

export const HardLimitReachedDialog = ({
  enabled,
  type,
  className,
  children,
  onOpenChange,
}: {
  enabled?: boolean
  type: 'feeds' | 'collections' | 'feedspercollection'
  className?: string
  children: JSX.Element
  onOpenChange?: (open: boolean) => void
}): JSX.Element => {
  if (!enabled) {
    return children
  }

  const limit = {
    feeds: Limits.feeds.paid.total,
    collections: Limits.collections.paid,
    feedspercollection: Limits.feeds.paid.perCollection,
  }[type]
  const description = {
    feeds: { item: 'feeds', addMore: 'feeds' },
    collections: { item: 'collections', addMore: 'collections' },
    feedspercollection: {
      item: 'feeds per collection',
      addMore: 'feeds to this collection',
    },
  }[type]
  return (
    <Dialog key="hardLimitReachedDialog" onOpenChange={onOpenChange}>
      {/* asChild needs to be false to support multiple conditional dialogs around one trigger */}
      <Dialog.Trigger
        asChild={false}
        className={clsx(styles.trigger, className)}
      >
        {children}
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>Limit Reached!</Dialog.Title>
          <Dialog.Description>
            You have reached the limit of {limit} {description.item}. Adding
            more {description.addMore} is currently not supported.
          </Dialog.Description>
          <TrackRender componentName="HardLimitReachedDialog" type={type} />
        </Dialog.Header>
        <Dialog.Footer>
          <Dialog.Close>
            <Button variant="primary" size="large">
              Got it
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
