import React, { useCallback } from 'react'
import {
  CoachMark,
  Button,
  useLocalStorage,
  useDelay,
} from '@buffer-mono/popcorn'
import { useIsSplitEnabled } from '@buffer-mono/features'

type LinkShorteningCoachmarkProps = {
  isShorteningEnabled: boolean
}

export const LinkShorteningCoachmark = (
  props: LinkShorteningCoachmarkProps,
): JSX.Element => {
  const { isShorteningEnabled } = props
  const isLinkShorteningFlagEnabled = useIsSplitEnabled(
    'link-shortening-notification',
  )
  const [hasSeenCoachmark, setHasSeenCoachmark] = useLocalStorage(
    'composer::hasSeenLinkShorteningCoachmark',
    false,
  )
  // delay to ensure animation has run, otherwise coachmark is misaligned
  const delay = useDelay(500)
  const open =
    delay &&
    !isShorteningEnabled &&
    !hasSeenCoachmark &&
    isLinkShorteningFlagEnabled
  const handleClose = useCallback(() => {
    setHasSeenCoachmark(true)
  }, [setHasSeenCoachmark])
  if (!open) return <></>

  return (
    <CoachMark open={open} onDismiss={handleClose}>
      <CoachMark.Overlay>
        <CoachMark.Spotlight
          style={{
            '--spotlight-top': '0px',
            '--spotlight-left': '0.6em',
          }}
        />
      </CoachMark.Overlay>

      <CoachMark.Content
        showCloseIcon={false}
        side="top"
        onClick={(e): void => {
          e.stopPropagation()
        }}
      >
        <CoachMark.Title>Shortening disabled</CoachMark.Title>
        <CoachMark.Description>
          Links no longer shorten automatically when creating a post. You can
          toggle using this button or enable automatic shortening on all links
          in channel preferences.
        </CoachMark.Description>
        <CoachMark.Footer>
          <Button variant="tertiary" onClick={handleClose}>
            Ok
          </Button>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
