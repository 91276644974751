import React from 'react'

import { Avatar } from '@buffer-mono/popcorn'

import type { LastReply } from '~publish/gql/graphql'

import { CommentHeader } from './CommentHeader'
import { CommentText } from './CommentText'
import styles from './CommentItem.module.css'

type LastAuthorReplyProps = {
  reply: LastReply
}

export const LastAuthorReply = ({
  reply,
}: LastAuthorReplyProps): JSX.Element => {
  return (
    <article
      className={styles.container}
      style={{
        gridTemplateColumns: '1fr',
      }}
    >
      <section className={styles.body}>
        <Avatar
          size="small"
          src={reply.author.avatar}
          alt={reply.author.username ?? 'username'}
        />
        <div className={styles.bodyWrapper}>
          <CommentHeader isOwnReply={reply.isOwnReply} author={reply.author} />
          <CommentText text={reply.text} serviceType={reply.serviceType} />
        </div>
      </section>
    </article>
  )
}
