import React from 'react'
import UserTags from '~publish/legacy/composer-user-tags'

import Modal from '../shared-components/modal/modal'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import style from './css/InstagramUserTags.module.css'
import AppStore from '../stores/AppStore'

interface Media {
  url: string
  width: number
  height: number
}

export interface UserTag {
  username?: string
  x?: string
  y?: string
}

interface InstagramUserTagsProps {
  media: Media
  draftId: string
  userTags?: UserTag[]
}

const InstagramUserTags = ({
  media,
  draftId,
  userTags = [],
}: InstagramUserTagsProps): React.JSX.Element => {
  const modalClassNames = {
    modal: style.modal,
  }
  const selectedIgProfiles = AppStore.getSelectedProfilesForService('instagram')

  const saveGlobalTags = (tags: UserTag[]): void => {
    ComposerActionCreators.updateDraftImageUserTags(draftId, tags, media.url)
    ModalActionCreators.closeModal()
  }

  const onCancel = (): void => ModalActionCreators.closeModal()

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; classNames: { modal: an... Remove this comment to see the full error message
    <Modal
      classNames={modalClassNames}
      hideCloseButton
      preventCloseOnOverlayClick
    >
      <UserTags
        media={media}
        // @ts-expect-error
        userTags={userTags}
        saveGlobalTags={saveGlobalTags}
        onCancel={onCancel}
        selectedChannels={selectedIgProfiles}
      />
    </Modal>
  )
}

export default InstagramUserTags
