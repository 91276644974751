import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  type onRedirectParams,
  type ChannelAccountType,
  Service,
} from '../../../types'

import { SelectAccountType } from './components/SelectAccountType/SelectAccountType'
import { FacebookGroups } from './components/FacebookGroups/FacebookGroups'

import { useUser } from '../../../../../../common/context/User'

import { resetChannelConnectionsState } from '../../../../store/channelConnectionsReducer'

import { ViewType } from './types'
import { trackChannelConnectionAborted } from '../../../tracking'
import { Header } from './components/shared/Header/Header'
import { useModalManager } from '../../../../../../components/ModalManager/hooks/useModalManager'
import { AccountType } from '../../../../../../common/events/types'

export function Facebook({
  onRedirect,
}: {
  onRedirect: (args: onRedirectParams) => void
}): JSX.Element {
  const { modalData, dismissModal } = useModalManager()
  const {
    channelId,
    isRefreshingConnection,
    channelName,
    channelAvatar,
    channelUrl,
    onContinue: modalOnContinue,
    accountType,
  } = modalData ?? {}
  const [selectedAccountType, setSelectedAccountType] = useState<
    ChannelAccountType.page | ChannelAccountType.group | undefined
  >(undefined)

  const [currentView, setCurrentView] = useState<
    ViewType.accountSelection | ViewType.FacebookGroups
  >(
    accountType === AccountType.group
      ? ViewType.FacebookGroups
      : ViewType.accountSelection,
  )

  const account = useUser()
  const dispatch = useDispatch()

  function handleOnClose(): void {
    trackChannelConnectionAborted({
      account,
      service: Service.facebook,
    })

    dispatch(resetChannelConnectionsState({}))
    // onClose()
  }

  return (
    <>
      <Header isRefreshingConnection={isRefreshingConnection} />
      {currentView === ViewType.FacebookGroups ? (
        <FacebookGroups
          channelId={channelId}
          channelName={channelName}
          channelAvatar={channelAvatar}
          channelUrl={channelUrl}
          isRefreshingConnection={isRefreshingConnection}
          dismissModal={dismissModal}
          modalOnContinue={modalOnContinue}
        />
      ) : (
        <SelectAccountType
          setSelectedAccountType={setSelectedAccountType}
          selectedAccountType={selectedAccountType}
          onRedirect={onRedirect}
          setCurrentView={setCurrentView}
          onClose={handleOnClose}
        />
      )}
    </>
  )
}
