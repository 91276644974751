import React from 'react'
import styled from 'styled-components'
import RetweetIcon from '@bufferapp/ui/Icon/Icons/Retweet'

import CloseButton from './shared/CloseButton'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import { AttachmentTypes } from '../AppConstants'

import styles from './css/RetweetAttachment.module.css'
import AppStore from '../stores/AppStore'
import { Tooltip } from '@buffer-mono/popcorn'

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 12px;
  right: 16px;
`

interface RetweetAttachmentProps {
  draft: any
  disabled?: boolean
}

const RetweetAttachment = ({
  draft,
  disabled = false,
}: RetweetAttachmentProps): JSX.Element | null => {
  const onCloseButtonClick = (): void => {
    ComposerActionCreators.toggleAttachment(draft.id, AttachmentTypes.RETWEET)
  }

  const hasRetweetAttachment =
    draft.hasRetweetAttachment && draft.hasRetweetAttachment()

  if (
    !disabled &&
    (AppStore.getExpandedComposerId() !== draft.id || !hasRetweetAttachment)
  ) {
    return null
  }

  const { text, userName, userDisplayName, avatarUrl } = draft.retweet

  const retweetAttachmentClassName = disabled
    ? styles.disabledRetweetAttachment
    : styles.retweetAttachment
  const message = 'Reposted by you'

  return (
    <div className={retweetAttachmentClassName}>
      <div className={styles.retweetLabelContainer}>
        <RetweetIcon className={styles.retweetIcon} size="small" />
        <span className={styles.retweetLabel}>{message}</span>
      </div>
      <div className={styles.retweetAttachmentRow}>
        <img
          src={avatarUrl}
          alt={"Retweeted user's avatar"}
          className={styles.avatar}
        />
        <div className={styles.retweetAttachmentContent}>
          <span className={styles.userDisplayName}>{userDisplayName}</span>
          <span className={styles.userName}>@{userName}</span>
          <p className={styles.text}>{text}</p>
        </div>
      </div>

      {!disabled && (
        <Tooltip content="Disable Native Retweet Mode">
          <StyledCloseButton
            onClick={onCloseButtonClick}
            label="Disable Native Retweet Mode"
            light
          />
        </Tooltip>
      )}
    </div>
  )
}

export default RetweetAttachment
