/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client'
import {
  BoardIcon,
  Button,
  Flex,
  GalleryIcon,
  Heading,
  Label,
  MessageCircleHeartIcon,
  Notice,
  PlusIcon,
  Sidebar,
  Switch,
  ToggleGroup,
  useSidebar,
} from '@buffer-mono/popcorn'
import React from 'react'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { FilterByTag } from '~publish/components/FilterByTag'
import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'
import { graphql } from '~publish/gql'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { usePageTitle } from '~publish/hooks/usePageTitle'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import {
  useOrganizationId,
  useCurrentOrganization,
} from '~publish/legacy/accountContext'
import { GenerateIdeasButton } from '~publish/legacy/ai/components/GenerateIdeasButton'
import { UploadSource } from '@buffer-mono/uploader'
import styles from './IdeasPage.module.css'
import { Board } from './components/Board'
import { IdeasGallery } from './components/IdeasGallery'
import { UpgradePathForCreate } from './components/UpgradePathForCreate'
import useIdeasUploader from '~publish/hooks/useIdeasUploader'
import { IdeaBatchActions } from './components/IdeaBatchActions'
import {
  IdeasMultiSelectProvider,
  useIdeasMultiSelect,
} from './components/IdeasMultiSelect'
import { FilterByIdeaGroup } from './components/FilterByIdeaGroup/FilterByIdeaGroup'
import { useNewIdeaComposerSplit } from '~publish/components/IdeaComposer/hooks'
import { PageLayout } from '~publish/components/PageLayout'

const LOCAL_STORAGE_VIEW_KEY = 'contentPlannerLastViewed'

export const VIEWS = {
  GALLERY: 'gallery',
  BOARD: 'board',
}

export const GetCreateInfo = graphql(/* GraphQL */ `
  query GetCreateInfo(
    $organizationId: OrganizationId!
    # HACK: This is a workaround to fix the query type error, since these are the same but their type is different
    $organizationId_ID: ID!
  ) {
    tags(input: { organizationId: $organizationId }) {
      id
      ...FilterByTag_Tag
    }
    ideaGroups(input: { organizationId: $organizationId_ID }) {
      id
      ...FilterByIdeaGroup_IdeaGroup
    }
  }
`)

type IdeasPageProps = {
  pageTitle?: string
}

function IdeasPage(props: IdeasPageProps): JSX.Element {
  const { pageTitle } = props
  usePageTitle(pageTitle ?? 'Create')
  const organizationId = useOrganizationId()

  const { createIdeaWithComposer } = useIdeaComposer()
  const {
    isEnabled: isNewIdeaComposerEnabled,
    isSplitEnabled: isNewIdeaComposerSplitEnabled,
    toggleOptIn,
  } = useNewIdeaComposerSplit()

  const { isMobile } = useSidebar()

  const DEFAULT_VIEW = VIEWS.BOARD

  const [selectedTagIds = [], setSelectedTagIds] =
    useQueryParam<string[]>('tagIds')
  const [selectedGroupId = [], setSelectedGroupId] =
    useQueryParam<string[]>('groupIds')

  const { data } = useQuery(GetCreateInfo, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error - organizationId is not always defined we skip if it is not defined
    variables: { organizationId, organizationId_ID: organizationId },
    skip: !organizationId,
  })

  const [view, setView] = useQueryParam('view')
  React.useEffect(() => {
    if (!view || Object.values(VIEWS).includes(view) === false) {
      const initialView =
        localStorage.getItem(LOCAL_STORAGE_VIEW_KEY) || DEFAULT_VIEW
      setView(initialView, { replace: true })
    }
  }, [view])

  const currentOrganization = useCurrentOrganization()
  const hasContentFeature =
    !currentOrganization?.entitlements.includes('content')

  const uploader = useIdeasUploader()

  const handleDropIfPossible = React.useCallback(
    (files: File[], groupId?: string) => {
      if (files.length > 0) {
        uploader.addFiles(files, {
          source: UploadSource.dragAndDrop(),
        })
        const viewSource = view === VIEWS.GALLERY ? 'gallery' : 'board'
        createIdeaWithComposer({
          source: `create-${viewSource}-dragAndDrop-newIdea-1`,
          groupId,
        })
      }
    },
    [uploader],
  )

  const handleViewSelect = (selectedValue: string): void => {
    if (!selectedValue) {
      return
    }
    setView(selectedValue)
    localStorage.setItem(LOCAL_STORAGE_VIEW_KEY, selectedValue)
  }

  if (currentOrganization?.id && hasContentFeature) {
    return <UpgradePathForCreate />
  }

  return (
    <IdeasMultiSelectProvider>
      <PageLayout
        className={styles.page}
        data-testid="create-page"
        legacyNotResponsive
      >
        <PageLayout.Header className={styles.header}>
          {isNewIdeaComposerSplitEnabled && (
            <PageLayout.HeaderRow>
              <Notice variant="info">
                <Notice.Heading>
                  New Experimental Feature - Internal Only
                </Notice.Heading>
                <Notice.Text>
                  Introducing a new Notion-like Idea Composer. Flip the switch
                  to try it out! Please share any feedback with us in
                  #prod-content in Slack.
                </Notice.Text>
                <Notice.Actions>
                  <Flex align="center" gap="xs">
                    <Label
                      htmlFor="new-idea-composer-switch"
                      className={styles.switchLabel}
                    >
                      Enable New Idea Composer
                    </Label>
                    <Switch
                      id="new-idea-composer-switch"
                      size="small"
                      checked={isNewIdeaComposerEnabled}
                      onCheckedChange={toggleOptIn}
                    />
                  </Flex>
                </Notice.Actions>
              </Notice>
            </PageLayout.HeaderRow>
          )}
          <PageLayout.HeaderRow>
            <Flex gap="md" align="center">
              {isMobile && <Sidebar.Trigger />}
              <Heading as="h2" size="large">
                {pageTitle ?? 'Create'}
              </Heading>
              <GenerateIdeasButton />
            </Flex>

            <IdeasBatchActionsWrapper>
              <Flex
                gap="xs"
                align="center"
                className={styles.navigationActions}
              >
                <FeedbackWidget id="create-1" source="create">
                  <Button variant="tertiary" size="large">
                    <MessageCircleHeartIcon />
                    Share Feedback
                  </Button>
                </FeedbackWidget>
                {view === VIEWS.GALLERY && (
                  <FilterByIdeaGroup
                    groups={sanitizeNullableArray(data?.ideaGroups)}
                    value={selectedGroupId}
                    onSelect={setSelectedGroupId}
                  />
                )}
                <FilterByTag
                  size="large"
                  variant="secondary"
                  tags={sanitizeNullableArray(data?.tags)}
                  value={selectedTagIds}
                  onSelect={setSelectedTagIds}
                />
                <ToggleGroup
                  size="large"
                  value={view}
                  onChange={handleViewSelect}
                >
                  <ToggleGroup.Item value={VIEWS.BOARD}>
                    <BoardIcon />
                    Board
                  </ToggleGroup.Item>
                  <ToggleGroup.Item value={VIEWS.GALLERY}>
                    <GalleryIcon />
                    Gallery
                  </ToggleGroup.Item>
                </ToggleGroup>
                <Button
                  id="create-idea-button"
                  variant="secondary"
                  size="large"
                  onClick={(): void => {
                    createIdeaWithComposer({
                      source:
                        view === VIEWS.GALLERY
                          ? 'create-gallery-toolbar-newIdea-1'
                          : 'create-board-toolbar-newIdea-1',
                    })
                  }}
                >
                  <PlusIcon />
                  New Idea
                </Button>
              </Flex>
            </IdeasBatchActionsWrapper>
          </PageLayout.HeaderRow>
        </PageLayout.Header>

        <PageLayout.Container as="main" className={styles.main}>
          {view === VIEWS.GALLERY && (
            <IdeasGallery onFileDrop={handleDropIfPossible} />
          )}
          {view === VIEWS.BOARD && <Board onFileDrop={handleDropIfPossible} />}
        </PageLayout.Container>
      </PageLayout>
    </IdeasMultiSelectProvider>
  )
}

const IdeasBatchActionsWrapper = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { selecting } = useIdeasMultiSelect()

  if (selecting) {
    return <IdeaBatchActions />
  }

  return <>{children}</>
}

export { IdeasPage }
