import {
  Badge,
  Tabs,
  UpgradeBadge,
  VisuallyHidden,
  type TabPanelProps,
  type TabProps,
  type TabsProps,
} from '@buffer-mono/popcorn'
import React from 'react'
import clsx from 'clsx'

import { formatCompactNumber } from '~publish/helpers/numberFormatters'

import { ApprovalsUpgradePath } from './ApprovalsUpgradePath'
import { PostEmptyStateByTab } from './PostEmptyStateByTab'

import styles from './PostTabs.module.css'
import {
  useHasEntitlement,
  useCurrentOrganization,
} from '~publish/legacy/accountContext'

type PostTab = 'queue' | 'drafts' | 'approvals' | 'sent'
export const TAB_VALUES: PostTab[] = ['queue', 'drafts', 'approvals', 'sent']

type PostTabsProps = Omit<TabsProps, 'value' | 'onChange'> & {
  value: PostTab
  onChange: (value: PostTab) => void
}

/**
 * Wrapper around Tabs component that provides a set of tabs for post list
 */
const PostTabs = ({
  children,
  value,
  onChange,
  ...props
}: PostTabsProps): JSX.Element => {
  return (
    <Tabs
      value={value}
      onChange={(tab): void => onChange(tab as PostTab)}
      {...props}
    >
      {children}
    </Tabs>
  )
}

type PostTabsTabProps = Omit<TabProps, 'value' | 'children'> & {
  value: PostTab
  count: number | null | undefined
  countLimit: number | null | undefined
  countLoading: boolean
}

const labelByTab: Record<PostTab, string> = {
  queue: 'Queue',
  drafts: 'Drafts',
  approvals: 'Approvals',
  sent: 'Sent',
}

const PostTabsTab = ({
  value,
  count,
  countLimit,
  countLoading,
}: PostTabsTabProps): JSX.Element => {
  const hasApprovalEntitlement = useHasEntitlement('approvalFlow')
  const currentOrganization = useCurrentOrganization()

  if (
    value === 'approvals' &&
    !hasApprovalEntitlement &&
    currentOrganization?.id
  ) {
    return (
      <Tabs.Tab value={value}>
        {labelByTab[value]} <UpgradeBadge size="xsmall" />
      </Tabs.Tab>
    )
  }

  return (
    <Tabs.Tab id={`${value}-tab`} data-testid={`${value}-tab`} value={value}>
      {labelByTab[value]}{' '}
      <Badge size="small" style={{ pointerEvents: 'none' }}>
        {!countLoading && (
          <>
            {formatCompactNumber(count ?? 0, countLimit)}
            <VisuallyHidden> posts</VisuallyHidden>
          </>
        )}
        {countLoading && (
          <>
            &ensp;
            <VisuallyHidden>Post counts loading</VisuallyHidden>
          </>
        )}
      </Badge>
    </Tabs.Tab>
  )
}

type PostTabsPanelProps = Omit<TabPanelProps, 'value'> & {
  value: PostTab
  children: React.ReactNode
}

const PostTabsPanel = ({
  value,
  className,
  ...props
}: PostTabsPanelProps): JSX.Element => {
  const currentOrganization = useCurrentOrganization()
  const hasApprovalEntitlement = useHasEntitlement('approvalFlow')

  if (
    value === 'approvals' &&
    !hasApprovalEntitlement &&
    currentOrganization?.id
  ) {
    return (
      <Tabs.Panel
        value={value}
        className={clsx(styles.panel, className)}
        {...props}
      >
        <ApprovalsUpgradePath />
      </Tabs.Panel>
    )
  }

  return (
    <Tabs.Panel
      className={clsx(styles.panel, className)}
      value={value}
      {...props}
    />
  )
}

const PostTabsObject = Object.assign(PostTabs, {
  Tab: PostTabsTab,
  TabList: Tabs.TabList,
  Panel: PostTabsPanel,
})

export { PostTabsObject as PostTabs, PostEmptyStateByTab }
export type { PostTab }
