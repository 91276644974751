/**
 * Injects a meta viewport tag into the document head if one does not already exist.
 * If one does exist, we update its content.
 * This allows the page to be responsive on mobile devices.
 */
export function injectMetaViewportTag(): void {
  const existingMeta = document.querySelector('meta[name="viewport"]')
  if (existingMeta) {
    existingMeta.setAttribute('name', 'viewport')
    existingMeta.setAttribute('content', 'width=device-width, initial-scale=1')
  } else {
    const meta = document.createElement('meta')
    meta.setAttribute('name', 'viewport')
    meta.setAttribute('content', 'width=device-width, initial-scale=1')
    document.head.appendChild(meta)
  }
}

/**
 * Removes the meta viewport tags content.
 * We need to pass an empty value opposed to removing the tag entirely to allow the page to update accordingly.
 * This removes responsiveness on mobile devices. The default viewport is set to 980px by the browser.
 */
export function removeMetaViewportTag(): void {
  const existingMeta = document.querySelector('meta[name="viewport"]')
  if (existingMeta) {
    existingMeta.setAttribute('content', '')
  }
}
