import React from 'react'
import clsx from 'clsx'
import { Temporal } from '@js-temporal/polyfill'
import {
  DayPicker,
  type ButtonProps,
  type ChevronProps,
  type MonthCaptionProps,
} from 'react-day-picker'

import { Flex } from '../Flex'
import { IconButton } from '../IconButton'
import { Text } from '../Text'
import { CalendarFilledIcon, ChevronLeftIcon, ChevronRightIcon } from '../icons'

import styles from './Calendar.module.css'

/**
 * MonthCaption override component for Daypicker component
 */
const MonthCaption = (props: MonthCaptionProps) => {
  const { children, className } = props
  return (
    <Flex
      className={clsx(className, styles.monthCaption)}
      justify="start"
      align="center"
      gap="xs"
    >
      {children}
    </Flex>
  )
}

/**
 * CaptionLabel override component for Daypicker component
 */
const CaptionLabel = (props: JSX.IntrinsicElements['span']) => {
  // Need to remove ref and color props as we don't want to pass them to the Text component
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, ref, color, ...rest } = props
  return (
    <>
      <CalendarFilledIcon color="subtle" />
      <Text weight="medium" {...rest}>
        {children}
      </Text>
    </>
  )
}

/**
 * Chevron override component for Daypicker component
 */
const Chevron = (props: ChevronProps) => {
  const { className, size, orientation } = props

  return orientation === 'left' ? (
    <ChevronLeftIcon className={className} size={size} color="subtle" />
  ) : (
    <ChevronRightIcon className={className} size={size} color="subtle" />
  )
}

/**
 * NavigationButton (Button) override component for Daypicker component
 */
const NavigationButton = (props: ButtonProps) => {
  const { children, ...buttonProps } = props

  return (
    <IconButton
      label="Calendar Navigation"
      variant="tertiary"
      as="button"
      size="medium"
      {...buttonProps}
    >
      {/* Expected children prop for IconButton needs it to be React.ReactElement */}
      {children as React.ReactElement}
    </IconButton>
  )
}

/**
 * Weekday override component for Daypicker component
 */
const Weekday = ({ children, ...props }: JSX.IntrinsicElements['th']) => {
  return (
    <th {...props}>
      <Text align="center" weight="medium" color="subtle">
        {typeof children === 'string' ? children[0] : children}
      </Text>
    </th>
  )
}

// Convert Temporal.PlainDate to JS Date
function plainDateToDate(plainDate?: Temporal.PlainDate): Date | undefined {
  if (!plainDate) return undefined
  return new Date(plainDate.year, plainDate.month - 1, plainDate.day)
}

// Convert JS Date to Temporal.PlainDate
function dateToPlainDate(date?: Date): Temporal.PlainDate | undefined {
  if (!date) return undefined
  return new Temporal.PlainDate(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
  )
}

/**
 * Returns a JavaScript Date object that represents "today" in the specified time zone,
 * adjusted to work correctly with React DayPicker's reliance on local time.
 *
 * @param {string} timezone - An IANA time zone identifier (e.g., 'America/New_York', 'Asia/Tokyo').
 * @returns {Date} A Date object set to "today" in the specified time zone but in the local time zone context.
 *
 * @description
 * React DayPicker uses JavaScript Date objects based on the local time zone,
 * so its "today" marker may not match a different desired time zone.
 *
 * This function uses Temporal to get the current date components (year, month, day)
 * in the specified time zone and creates a Date object with these components in the local time zone.
 * This way, when DayPicker retrieves the date parts using getFullYear(), getMonth(), and getDate(),
 * it correctly reflects "today" for the specified time zone, even though the Date object remains in the local time zone.
 *
 * By focusing on date components rather than time zones, this approach ensures
 * DayPicker highlights the correct "today" date for any given time zone.
 */
function getTodayDateForTimezone(timezone: Temporal.TimeZoneLike): Date {
  const zonedDateTime = Temporal.Now.zonedDateTimeISO(timezone)
  const year = zonedDateTime.year
  const month = zonedDateTime.month - 1 // JavaScript Date months are 0-based
  const day = zonedDateTime.day
  return new Date(year, month, day)
}

export type CalendarProps = {
  /**
   * The currently selected date
   */
  value?: Temporal.PlainDate
  /**
   * Callback when the selected date changes
   */
  onValueChange?: (value?: Temporal.PlainDate) => void
  /**
   * The first day of the week in the calendar
   */
  weekStartsOn?: 0 | 1
  /**
   * Override the default today date. This is primarily for timezone support.
   */
  timezone?: Temporal.TimeZoneLike
}

/**
 * Calendar component that displays a date picker for date selection.
 * Returns a PlainDate object with no time information.
 */
export const Calendar = ({
  value,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  weekStartsOn = 0,
  onValueChange,
  ...dayPickerProps
}: CalendarProps): JSX.Element => {
  // Get today's date in the specified timezone
  const today = getTodayDateForTimezone(timezone)

  const handleChange = React.useCallback(
    (date?: Date) => {
      const plainDate = dateToPlainDate(date)
      onValueChange?.(plainDate)
    },
    [onValueChange],
  )

  return (
    <DayPicker
      mode="single"
      required={false}
      showOutsideDays
      disabled={{
        before: today,
      }}
      className={styles.root}
      classNames={styles}
      selected={plainDateToDate(value)}
      onSelect={handleChange}
      today={today}
      weekStartsOn={weekStartsOn}
      startMonth={today}
      components={{
        CaptionLabel,
        Chevron,
        Weekday,
        Button: NavigationButton,
        MonthCaption,
      }}
      {...dayPickerProps}
    />
  )
}
