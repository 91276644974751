import React from 'react'
import {
  AudienceIcon,
  ZapFilledIcon,
  UnstyledButton,
  useBreakpoint,
} from '@buffer-mono/popcorn'

import {
  canHaveTeamMembers,
  getUsersCurrentPlan,
  userCanStartFreeTrial,
} from '../../../common/utils/user'

import { MODALS, type Account } from '../../../common/types'
import { isFreePlan } from '../../../common/hooks/utils/segmentTraitGetters'
import { getMigrationHubUrl } from '../../../common/utils/urls'
import { formatCTAString } from '../../../common/hooks/useSegmentTracking'
import UpgradePathsTracking from '../../../tracking/UpgradePaths'
import { useModalManager } from '../../../components/ModalManager/hooks/useModalManager'

import styles from './UpgradeCTA.module.css'
import { useUser } from '../../context/User'

const LegacyCTA = (): JSX.Element | null => {
  return (
    <UnstyledButton
      id="upgradeCTA"
      className={styles.upgradeCTA}
      as="a"
      href={getMigrationHubUrl('topNav-migration-cta')}
    >
      <ZapFilledIcon />
      Unlock the latest features
    </UnstyledButton>
  )
}

const InviteYourTeamCTA = ({ user }: { user: Account }): JSX.Element | null => {
  const breakpoint = useBreakpoint()
  const { openModal } = useModalManager()
  const [, envModifier] = window.location.hostname.match(
    /\w+\.(\w+\.)buffer\.com/,
  ) || [null, null]

  const isMediumScreen = breakpoint === 'md'

  const currentOrganization = user.currentOrganization
  const inviteYourTeamHandler = canHaveTeamMembers(user)
    ? (): void => {
        window.location.assign(
          `https://${
            envModifier || ''
          }buffer.com/manage/${currentOrganization?.id}/team-members/invite`,
        )
      }
    : (): void =>
        openModal({
          key: MODALS.planSelector,
          data: {
            cta: 'navBar-navBar-inviteYourTeam-1',
            upgradePathName: 'team-upgrade',
          },
        })

  const inviteTeamIcon = canHaveTeamMembers(user) ? (
    <AudienceIcon />
  ) : (
    <ZapFilledIcon />
  )
  return (
    <UnstyledButton
      className={styles.upgradeCTA}
      onClick={inviteYourTeamHandler}
    >
      {inviteTeamIcon}
      {isMediumScreen ? 'Team' : 'Invite Your Team'}
    </UnstyledButton>
  )
}

const UpgradeCTA = (): JSX.Element | null => {
  const user = useUser()

  const { currentOrganization } = user
  const { isOneBufferOrganization } = currentOrganization ?? {}
  const shouldShowLegacyCTA = !isOneBufferOrganization

  const { openModal } = useModalManager()
  const { trackCTAClicked } = UpgradePathsTracking(user)

  const canStartTrial = userCanStartFreeTrial(user)

  const breakpoint = useBreakpoint()
  const isMediumScreen = breakpoint === 'md'

  const startTrialText = isMediumScreen
    ? 'Start Trial'
    : 'Start a 14-day free trial'
  const ctaButton = canStartTrial ? startTrialText : 'Upgrade'
  const plan = getUsersCurrentPlan(user) || ''
  const upgradePathName = canStartTrial
    ? 'mainNav-trial'
    : plan && plan.id === 'essentials'
    ? 'team-upgrade'
    : 'mainNav-upgrade'

  const isFree = isFreePlan(user)

  if (currentOrganization?.shouldDisplayInviteCTA) {
    return <InviteYourTeamCTA user={user} />
  }

  if (currentOrganization?.billing && isOneBufferOrganization && isFree) {
    // Early return: if you can start a trial but you are not in the control
    // Skip showing the CTA to start a trial
    if (canStartTrial) {
      return null
    }

    return (
      <UnstyledButton
        id="upgradeCTA"
        className={styles.upgradeCTA}
        data-testid="top-nav-upgrade-cta"
        onClick={(): void => {
          if (canStartTrial) {
            openModal({
              key: MODALS.startTrial,
              data: {
                ctaView: 'navBar',
                ctaLocation: 'mainNav',
                ctaButton: formatCTAString(ctaButton),
                cta: 'navBar-navBar-startTrial-1',
                upgradePathName,
              },
            })
          } else {
            openModal({
              key: MODALS.planSelector,
              data: {
                cta: 'navBar-navBar-upgradePlan-1',
                upgradePathName,
              },
            })
          }

          trackCTAClicked({
            ctaButton: formatCTAString(ctaButton),
            upgradePathName,
            ctaView: 'mainNav',
            ctaLocation: 'mainNav',
          })
        }}
      >
        <ZapFilledIcon />
        {canStartTrial ? 'Start a 14-day free trial' : 'Upgrade'}
      </UnstyledButton>
    )
  }

  if (currentOrganization?.id && shouldShowLegacyCTA) {
    return <LegacyCTA />
  }

  return null
}

export default UpgradeCTA
