import React, { memo, useCallback } from 'react'
import clsx from 'clsx'
import { Temporal } from '@js-temporal/polyfill'

import { UnstyledButton } from '@buffer-mono/popcorn'

import { useTimezone } from '~publish/hooks/useTimezone'
import { format } from '~publish/helpers/temporal'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'

import styles from './SlotItem.module.css'

interface SlotItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  timestamp: number
  channelId: string
  isDraggingOver?: boolean
  is24HourFormat?: boolean
}

function SlotItemRaw({
  timestamp,
  channelId,
  isDraggingOver,
  className,
  is24HourFormat = false,
  ...props
}: SlotItemProps): JSX.Element {
  const timezone = useTimezone()

  const { triggerAttributes, createNewPinnedPostInComposer } = usePostComposer()
  const selectedTags = useSelectedTags()

  const openComposer = useCallback(
    (timestamp: number): void => {
      createNewPinnedPostInComposer({
        cta: 'publish-calendar-slot-addPost-1',
        channels: [channelId],
        prefillPostData: {
          dueAt: Math.round(timestamp / 1000),
          tags: selectedTags,
          pinned: true,
        },
      })
    },
    [createNewPinnedPostInComposer, channelId, selectedTags],
  )

  const clickHandler = useCallback(() => {
    openComposer(timestamp)
  }, [timestamp, openComposer])

  const time =
    Temporal.Instant.fromEpochMilliseconds(timestamp).toZonedDateTimeISO(
      timezone,
    )

  const timeString = is24HourFormat
    ? format(time, 'HH:mm')
    : format(time, 'hh:mm a')

  return (
    <UnstyledButton
      className={clsx(styles.slot, className, {
        [styles.isDraggingOver]: isDraggingOver,
      })}
      onClick={clickHandler}
      {...triggerAttributes}
      {...props}
    >
      <span className={styles.time}>{timeString}</span>
      <span className={styles.hoverText}>Add a Post</span>
    </UnstyledButton>
  )
}

export const SlotItem = memo(SlotItemRaw)
