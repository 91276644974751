import React from 'react'
import { Button, Notice } from '@buffer-mono/popcorn'

import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

import { RegisteredBanner } from '../../components/RegisteredBanner'
import { useSelectedChannel } from './ChannelContext'
import { getAccountChannelsURL } from '~publish/legacy/utils/formatters/getURL'

export const XRefreshBanner = (): JSX.Element | null => {
  const selectedChannel = useSelectedChannel()
  const bannerId = 'XRefreshBanner'

  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })

  const canRenderBanner =
    banner.isActive &&
    selectedChannel.service === 'twitter' &&
    selectedChannel.apiVersion !== '2'

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <Notice variant="error" onDismiss={banner.dismiss}>
        <Notice.Heading>Action required</Notice.Heading>
        <Notice.Text>
          Refresh your X (Twitter) connection to continue publishing and
          accessing analytics.
        </Notice.Text>
        <Notice.Actions>
          <Button as="a" href={getAccountChannelsURL()} variant="critical">
            Go to Channels
          </Button>
        </Notice.Actions>
      </Notice>
    </RegisteredBanner>
  )
}
