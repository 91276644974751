import styled, { keyframes } from 'styled-components'
import { getActiveProductFromPath } from '../../utils/getProduct'

const activeProduct = getActiveProductFromPath()

function getHorizontalPosition(): number {
  switch (activeProduct) {
    case 'engage':
      return 63
    case 'start_page':
      return 80
    default:
      return 40
  }
}

const scaleIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

export const TriggerButton = styled.button<{ className?: string }>`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 16px;
  right: ${getHorizontalPosition()}px;
  background-color: rgb(44, 75, 255);
  color: #fff;
  border-width: 1px;
  border-radius: 25px;
  border-color: rgb(44, 75, 255);
  text-align: center;
  cursor: pointer;
  z-index: 99999;
  animation: ${scaleIn} 0.5s ease-out;
  transition: transform 200ms ease-in-out;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out,
      visibility 0s linear 0.3s;
  }

  &:hover {
    transform: scale(1.05);
  }
`
