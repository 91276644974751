import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import {
  ClockIcon,
  CloseIcon,
  CommentSquare1StIcon,
  Dialog,
  Flex,
  IconButton,
  MapPinIcon,
  Text,
  Tooltip,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { PortalContainerProvider } from '@buffer-mono/popcorn/src/helpers/usePortalContainer'
import clsx from 'clsx'
import {
  getAnalyzeUrl,
  getEngageUrl,
} from '~publish/components/PostCard/helpers'
import { PostCardActions } from '~publish/components/PostCard/PostCardActions'
import {
  useMetadataFirstComment,
  useMetadataGeolocation,
  useMetadataThread,
  useMetadataThreadCount,
  useMetadataTitle,
  usePostData,
} from '~publish/components/PostCard/PostCardContext'
import { PostCardErrorNotice } from '~publish/components/PostCard/PostCardErrorNotice'
import { PostCardProcessingNotice } from '~publish/components/PostCard/PostCardProcessingNotice'
import { PostMediaBentoBoxPreview } from '~publish/components/PostCard/PostMedia'
import type { PostMediaAsset_Asset } from '~publish/components/PostCard/PostMedia/PostMediaAsset'
import { PostCardContent } from '~publish/components/PostCardContent'
import { PostCardContent_Annotation } from '~publish/components/PostCardContent/PostCardContent'
import { PostCardAuthor } from '~publish/components/PostCardFooter/PostCardAuthor'
import { PostedFrom } from '~publish/components/PostCardFooter/PostedFrom'
import { PostCardHeader } from '~publish/components/PostCardHeader'
import { PostCardTags } from '~publish/components/PostCardTags'
import {
  MetricsRefresh,
  MoreAnalyticsButton,
  PostMetricsGrid,
  PostMetricsWrapper,
} from '~publish/components/PostDetails/PostMetrics'
import { PostNotifyBadge } from '~publish/components/PostNotifyBadge'
import { PostStatusBadge } from '~publish/components/PostStatusBadge'
import { getFragmentData, graphql, type FragmentType } from '~publish/gql'
import type {
  PostCardContent_AnnotationFragment,
  PostType,
} from '~publish/gql/graphql'
import { getReadablePostStatus } from '~publish/helpers/posts/getReadablePostStatus'
import { useDateTimeFormatter } from '~publish/hooks/useDateTimeFormatter'
import { PostCard_Post, PostCardProvider } from '../../PostCard'
import { PostErrorDialog } from '../PostErrorDialog'
import styles from './PostDetailsDialog.module.css'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { PostCardAttachments } from '~publish/components/PostCard/PostCardAttachments'
import { useTimezone } from '~publish/hooks/useTimezone'

export const GetPost = graphql(/* GraphQL */ `
  query GetPost($id: PostId!) {
    post(input: { id: $id }) {
      id
      ...PostCard_Post # TODO: Update this once the PostDetailsDialog has his own implementation
    }
  }
`)

// TODO: we have this same component in PostCardHeader, we should move it to a shared component
const TextSeparator = (): JSX.Element => (
  <Text color="subtle" aria-hidden={true}>
    {' • '}
  </Text>
)

const CustomScheduleLabel = (): JSX.Element => {
  return (
    <Text size="sm" color="subtle" className={styles.customLabel}>
      <TextSeparator />
      <Tooltip content="Posting time was set manually and is not determined by the channel’s posting schedule">
        <Flex gap="2xs" align="center">
          <ClockIcon size="xsmall" />
          <span>Custom</span>
        </Flex>
      </Tooltip>
    </Text>
  )
}

const Header = (): JSX.Element => {
  const {
    dueAt,
    channel,
    status,
    isCustomScheduled,
    sentAt,
    schedulingType,
    notificationStatus,
  } = usePostData()
  const dateTimeFormatter = useDateTimeFormatter()
  const timeZone = useTimezone()

  const date = status === 'sent' && sentAt ? sentAt : dueAt

  return (
    <Dialog.Header className={styles.header}>
      <VisuallyHidden>
        <Dialog.Title>View Post details</Dialog.Title>
        <Dialog.Description>
          {`Post ${getReadablePostStatus(status)} for ${channel.name} ${
            channel.service
          } channel ${dueAt ? `on ${dateTimeFormatter(dueAt)}` : ''}`}
        </Dialog.Description>
      </VisuallyHidden>
      <Flex gap="xs" align="center">
        {date && (
          <Text>
            {sentAt ? 'Published on' : 'Scheduled for'}&nbsp;
            <Tooltip
              content={
                <Flex direction="column">
                  {timeZone && <span>Channel Local Time:</span>}
                  {dateTimeFormatter(
                    date,
                    timeZone
                      ? {
                          timeZone,
                        }
                      : {},
                  )}
                </Flex>
              }
            >
              <Text size="md" weight="medium">
                {dateTimeFormatter(date, { includeTimeZone: false })}
              </Text>
            </Tooltip>
            {isCustomScheduled && <CustomScheduleLabel />}
          </Text>
        )}
        <PostStatusBadge />
        <PostNotifyBadge
          schedulingType={schedulingType}
          status={status}
          notificationStatus={notificationStatus}
        />
      </Flex>
      <Flex gap="xs">
        {/* TODO: Re-add notes button when notes is supported in details dialog */}
        {/* <IconButton
          label="Notes"
          onClick={undefined}
          size="small"
          variant="tertiary"
        >
          <CommentRoundIcon />
        </IconButton> */}
        <Dialog.Close asChild>
          <IconButton
            label="Expand"
            onClick={undefined}
            size="medium"
            variant="tertiary"
          >
            <CloseIcon />
          </IconButton>
        </Dialog.Close>
      </Flex>
    </Dialog.Header>
  )
}

const BodySection = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}): JSX.Element => {
  return (
    <Flex
      as="section"
      className={clsx(styles.bodySection, className)}
      display="flex"
      direction="column"
      justify="between"
      gap="md"
      align="stretch"
    >
      {children}
    </Flex>
  )
}

const PostContent = ({
  text,
  annotations,
  assets,
  title,
  type,
  threadIndex,
  expandText = false,
}: {
  text: string
  annotations: readonly PostCardContent_AnnotationFragment[]
  assets: FragmentType<typeof PostMediaAsset_Asset>[]
  title?: string
  type?: PostType
  threadIndex?: number
  expandText?: boolean
}): JSX.Element => {
  const isThreaded = typeof threadIndex === 'number' && threadIndex >= 0
  return (
    <Flex
      justify="between"
      gap="md"
      align="stretch"
      fullWidth
      fullHeight
      className="postContent"
    >
      <Flex
        direction="column"
        align="stretch"
        gap="sm"
        className={styles.leftContent}
      >
        <PostCardHeader type={type} />
        {isThreaded ? (
          <Flex className={styles.threadedContentWrapper} gap="lg">
            <div className={styles.threadedTimelineMarker}></div>
            <Flex direction="column" gap="sm">
              <PostCardContent
                lineClamp={7}
                title={title}
                text={text}
                annotations={annotations ?? []}
                expanded={expandText}
                className={styles.threadText}
              />
              <PostCardAttachments includeRetweet={threadIndex === 0} />
              <PostMediaBentoBoxPreview assets={assets} />
            </Flex>
          </Flex>
        ) : (
          <>
            <PostCardContent
              lineClamp={7}
              title={title}
              text={text}
              annotations={annotations ?? []}
              expanded={expandText}
            />
            <PostCardAttachments />
            <PostMediaBentoBoxPreview assets={assets} />
          </>
        )}
      </Flex>
    </Flex>
  )
}

const Metrics = (): JSX.Element | null => {
  const { metrics, channel } = usePostData()

  const engageUrl = getEngageUrl(channel)
  const analyzeUrl = getAnalyzeUrl(channel)

  return (
    <PostMetricsWrapper className={styles.dialogMetrics}>
      <PostMetricsGrid metrics={metrics ?? []} engageUrl={engageUrl} />
      {analyzeUrl && <MoreAnalyticsButton analyzeUrl={analyzeUrl} />}
      <MetricsRefresh />
    </PostMetricsWrapper>
  )
}

const Body = (): JSX.Element => {
  const { assets, metadata, schedulingType, status, sentAt, text } =
    usePostData()
  const [expandText] = useQueryParam('expandText')
  const showMetrics =
    status === 'sent' && !!sentAt && schedulingType !== 'notification'

  const threadCount = useMetadataThreadCount(metadata)
  const threads = useMetadataThread(metadata)
  const firstComment = useMetadataFirstComment(metadata)
  const geolocation = useMetadataGeolocation(metadata)
  const title = useMetadataTitle(metadata)

  const annotations =
    getFragmentData(PostCardContent_Annotation, metadata?.annotations) ?? []

  return (
    <Flex
      as="article"
      direction="column"
      align="stretch"
      className={styles.body}
    >
      <BodySection
        aria-label="Post Content"
        className={styles.postContentSection}
      >
        {threadCount <= 1 ? (
          <PostContent
            assets={assets}
            text={text}
            title={title}
            type={metadata?.type}
            annotations={annotations}
            expandText={expandText === 'true'}
          />
        ) : (
          <Flex direction="column" gap="md">
            {threads?.map((thread, index) => {
              return (
                <PostContent
                  key={`post-details-thread-${index}`}
                  threadIndex={index}
                  text={thread.text}
                  type={metadata?.type}
                  annotations={[]}
                  expandText={expandText === 'true' && index === 0}
                  assets={
                    (thread.assets as FragmentType<
                      typeof PostMediaAsset_Asset
                    >[]) ?? []
                  }
                />
              )
            })}
          </Flex>
        )}
      </BodySection>
      {/* <PostCardAttachments /> */}
      {(geolocation || firstComment) && (
        <BodySection>
          <Flex direction="column" gap="xs">
            {geolocation && (
              <Flex gap="md" align="center">
                <MapPinIcon
                  data-testid="map-icon"
                  aria-label={`Location: ${geolocation?.text}`}
                />
                <Text>{geolocation?.text}</Text>
              </Flex>
            )}
            {firstComment && (
              <Flex gap="md" align="center">
                <CommentSquare1StIcon
                  data-testid="first-comment-icon"
                  aria-label={`First comment: ${firstComment}`}
                />
                <Text>{firstComment}</Text>
              </Flex>
            )}
          </Flex>
        </BodySection>
      )}
      <BodySection>
        <PostCardTags />
      </BodySection>
      {showMetrics && (
        <BodySection>
          <Metrics />
        </BodySection>
      )}
    </Flex>
  )
}

const Footer = (): JSX.Element => {
  const { via } = usePostData()
  const isApiPost = via === 'api' || via === 'buffer'

  return (
    <Dialog.Footer className={styles.footer}>
      {!isApiPost && <PostedFrom />}
      {isApiPost && <PostCardAuthor />}
      <PostCardActions />
    </Dialog.Footer>
  )
}

export function PostDetailsDialog({
  parent,
}: {
  parent: string
}): JSX.Element | null {
  const match = useRouteMatch()
  const history = useHistory()

  const { postId, id: channelId } = useParams<{ postId: string; id: string }>()
  const [open, setOpen] = useState(true)
  const { data, loading, error } = useQuery(GetPost, {
    variables: {
      id: postId,
    },
  })
  const portalContainerId = `portal-container-${postId}`

  const [container, setContainer] = React.useState<HTMLElement | null>(null)

  useEffect(
    function setInnerPortalContainer() {
      if (portalContainerId) {
        setContainer(document.getElementById(portalContainerId))
      }
      // We don't want to set the container until we have the data because it will not be present yet
    },
    [data, portalContainerId],
  )

  const closeDialogWhenComposerOpens = (
    evt: React.MouseEvent<HTMLElement>,
  ): void => {
    // Look for the closest ancestor that has the aria-controls attribute set to "composer-root"
    const openComposerTrigger = (evt.target as Element)?.closest(
      '[aria-controls="composer-root"]',
    )
    if (openComposerTrigger) {
      setOpen(false)
      history.push(parent)
    }
  }

  const onOpenChange = (open: boolean): void => {
    setOpen(open)
    if (!open) {
      history.push(parent)
    }
  }

  if (loading && !data) {
    return null
  }

  const rawPost = data?.post
  const post = getFragmentData(PostCard_Post, rawPost)
  if (channelId && post?.channel?.id && channelId !== post.channel.id) {
    history.push(match.url.replace(channelId, post.channel.id))
  }

  if (error || !post || !rawPost) {
    return (
      <PostErrorDialog
        error={error ?? { message: 'Bad Request' }}
        parent={parent}
      />
    )
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        size={error ? 'medium' : 'large'}
        // Override internal padding so we can set it manually
        style={{ padding: 0 }}
        onClick={closeDialogWhenComposerOpens}
        className={styles.postDetailsDialog}
      >
        <PortalContainerProvider container={container ?? document.body}>
          <PostCardProvider post={rawPost}>
            <Flex
              className={styles.card}
              data-post-id={post.id}
              data-testid="expanded-post-card"
              direction="column"
              align="stretch"
            >
              <PostCardErrorNotice />
              <PostCardProcessingNotice />
              <Header />
              <Dialog.Separator />
              <Body />
              <Dialog.Separator />
              <Footer />
            </Flex>
            <div id={portalContainerId} style={{ position: 'absolute' }} />
          </PostCardProvider>
        </PortalContainerProvider>
      </Dialog.Content>
    </Dialog>
  )
}
