import React from 'react'
import {
  EmptyState,
  Text,
  CompassIcon,
  useIsOffline,
  CriticalIcon,
} from '@buffer-mono/popcorn'

export const SidebarFeedError = ({
  message,
}: {
  message?: string
}): JSX.Element => {
  const isOffline = useIsOffline()
  if (isOffline) {
    return (
      <EmptyState variant="neutral" size="small">
        <EmptyState.Icon>
          <CompassIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>You&apos;re offline</EmptyState.Heading>
        <EmptyState.Description>
          Feeds aren&apos;t available when you&apos;re offline. Please check
          your connection and try again.{' '}
          {message && <Text color="critical">{message}</Text>}
        </EmptyState.Description>
      </EmptyState>
    )
  }
  return (
    <EmptyState variant="critical" size="small">
      <EmptyState.Icon>
        <CriticalIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>Failed to load Feeds</EmptyState.Heading>
      <EmptyState.Description>
        Please let our team know about it, we&apos;ll fix so you can get back to
        your feeds. {message && <Text color="critical">{message}</Text>}
      </EmptyState.Description>
    </EmptyState>
  )
}
