import React, { useMemo } from 'react'
import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

type TimeZoneContextType = {
  timeZone: string
}

const TimeZoneContext = React.createContext<TimeZoneContextType>({
  timeZone: getCurrentTimeZone(),
})

export const useTimezone = (): string => {
  const { timeZone } = React.useContext(TimeZoneContext)
  return timeZone
}

export const TimezoneProvider: React.FC<{
  children: React.ReactNode
  timeZone?: string
}> = ({ children, timeZone = getCurrentTimeZone() }) => {
  const value = useMemo(
    () => ({
      timeZone,
    }),
    [timeZone],
  )

  return (
    <TimeZoneContext.Provider value={value}>
      {children}
    </TimeZoneContext.Provider>
  )
}
