import {
  BufferTrackerReact as BufferTracker,
  Product,
  Client,
} from '@buffer-mono/tracking-plan'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { store } from '~publish/legacy/store'
import type { CommonTrackingProperties } from '~publish/legacy/accountContext/types'

export const CTA_CLICKED = 'cta-clicked'

type TrackingMetadata = {
  organizationId: string
  product: Product
}

const getTrackingMetadata = (): TrackingMetadata => {
  const organizationId = selectCurrentOrganizationId(store.getState())

  return {
    organizationId,
    product: Product.Publish,
  }
}

export const sendPaywallEvent = (
  event: string,
  cta: string,
  upgradePathName: string,
  commonTrackingProps: CommonTrackingProperties | null,
): void => {
  switch (event) {
    case CTA_CLICKED:
      {
        const eventCtaClickedData = {
          ...getTrackingMetadata(),
          clientName: Client.PublishWeb,
          cta,
          upgradePathName,
          ...commonTrackingProps,
        }
        BufferTracker.cTAClicked(eventCtaClickedData)
      }
      break
    default:
      break
  }
}
