import {
  DropdownMenu,
  Flex,
  FolderPlusIcon,
  IconButton,
  LockIcon,
  Paragraph,
  NewBadge,
  PlusIcon,
  RssAddIcon,
  RssIcon,
  Sidebar,
  Text,
  toast,
  useSidebar,
} from '@buffer-mono/popcorn'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { BufferTrackerReact, Client, Product } from '@buffer-mono/tracking-plan'
import { readFragment } from 'gql.tada'
import { graphql } from '~publish/graphql'
import { isNotNull, isOfType } from '~publish/helpers/typeGuards'
import { isFreeUser } from '~publish/helpers/user'
import { useCommonTrackingProperties } from '~publish/hooks/useCommonTrackingProperties'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import {
  useAccount,
  useHasPaymentDetails,
  useIsOneBufferOrg,
  useOrganizationId,
} from '~publish/legacy/accountContext'
import {
  createPage,
  feedCollectionPage,
  singleFeedPage,
} from '~publish/legacy/routes'
import { navigateToMigrationHub } from '~publish/legacy/upgrade-paths/helpersUpgradePaths'
import { Limits } from '../../constants/constants'
import {
  useCreateNewFeedCollection,
  useDeleteFeedCollection,
  useUpdateFeedCollection,
} from '../../hooks'
import {
  parseErrorMessage,
  useCreateFeed,
  type WrappedCreateFeedResponse,
} from '../../hooks/useCreateFeed'
import { useDeleteFeed } from '../../hooks/useDeleteFeed'
import { useFeedsForSidebar } from '../../hooks/useFeedsForSidebar'
import { ExploreFeedsDialogDropdownMenuItem } from '../ExploreFeedsDialog/ExploreFeedsDialogDropdownMenuItem'
import { HardLimitReachedDialog } from '../HardLimitReachedDialog/HardLimitReachedDialog'
import { ImportFeedsDialogDropdownMenuItem } from '../ImportFeedsDialog/ImportFeedsDialogDropdownMenuItem'
import { SubscribeToFeedDialogDropdownMenuItem } from '../SubscribeToFeedDialog/SubscribeToFeedDialogDropdownMenuItem'
import { FeedCollectionNameEditor } from './FeedCollectionNameEditor'
import {
  SidebarCollectionItem,
  SidebarCollectionItem_Collection,
} from './SidebarCollectionItem'
import { SidebarFeedItem, SidebarFeedItem_Feed } from './SidebarFeedItem'
import { SidebarFeedError } from './SidebarFeedError'
import styles from './SidebarFeedNav.module.css'
import { TrackRender } from '~publish/hooks/tracking/useTrackComponentRendered'
import { AddFeedCoachMark } from './AddFeedCoachMark'
import { SidebarSkeletons } from './SidebarSkeletons'

const MoveFeed = graphql(/* GraphQL */ `
  mutation MoveFeed($input: MoveFeedInput!) {
    moveFeed(input: $input) {
      __typename
      ... on FeedMoveUpdatesResponse {
        updatedFeeds {
          id
          name
          collection {
            id
            name
          }
        }
        updatedFeedCollections {
          id
          name
          feeds {
            id
            name
          }
        }
      }
    }
  }
`)

export function SidebarFeedNav(): JSX.Element {
  const { state } = useSidebar()
  const organizationId = useOrganizationId()
  const history = useHistory()

  const [isAddingNewCollection, setIsAddingNewCollection] = useState(false)

  const {
    collections: collectionsData,
    feeds,
    totalFeeds,
    error: feedsError,
    loading: sidebarFeedsLoading,
  } = useFeedsForSidebar({
    organizationId,
  })

  const collections = readFragment(
    SidebarCollectionItem_Collection,
    collectionsData,
  )

  const existingFeeds = feeds
    .map((feedData) => {
      const feed = readFragment(SidebarFeedItem_Feed, feedData)
      return isOfType(feed.connectionMetadata, 'RSSFeedConnectionMetadata')
        ? { id: feed.id, url: feed.connectionMetadata.url }
        : null
    })
    .filter(isNotNull)

  /**
   * Tier & limits
   */
  const { account } = useAccount()
  const tier = isFreeUser(account) ? 'free' : 'paid'
  const collectionsLimitReached = collections.length >= Limits.collections[tier]
  const totalRemainingFeeds = Limits.feeds[tier].total - totalFeeds
  const limitReached = totalFeeds >= Limits.feeds[tier].total

  const moveToCollectionsList = React.useMemo(() => {
    return collections.map((collection) => ({
      id: collection.id,
      name: collection.name,
      limitReached:
        (collection.feeds?.length ?? 0) >= Limits.feeds[tier].perCollection,
    }))
  }, [collections, tier])
  /**
   * Upgrade Paths & Tracking
   */
  const isOneBufferOrg = useIsOneBufferOrg()
  const hasPaymentDetails = useHasPaymentDetails()
  const commonTrackingProperties = useCommonTrackingProperties()
  const baseTrackingProperties = React.useMemo(
    () => ({
      organizationId,
      clientName: Client.PublishWeb,
      product: Product.Publish,
      currentCollectionsCount: collections.length,
      hasPaymentDetails,
      isFreeUser: tier === 'free',
      ...commonTrackingProperties,
    }),
    [
      commonTrackingProperties,
      collections.length,
      hasPaymentDetails,
      tier,
      organizationId,
    ],
  )

  const handleUpgradeClick = ({
    cta,
    upgradePathName,
  }: {
    cta: CTA
    upgradePathName: string
  }): void => {
    const trackingProperties = {
      ...baseTrackingProperties,
      cta,
      upgradePathName,
    }
    if (isOneBufferOrg) {
      BufferTrackerReact.cTAClicked(trackingProperties)

      const { MODALS, actions } = window?.appshell || {}
      actions.openModal(MODALS.planSelector, trackingProperties)
    } else {
      navigateToMigrationHub(cta)
    }
  }

  /**
   * Feed Actions
   */
  const [createFeed] = useCreateFeed()
  const handleCreateFeed = React.useCallback(
    async ({
      feedUrl,
      cta,
      collectionId,
    }: {
      feedUrl: string
      cta: 'add-from-url' | 'import-opml' | 'explore-feeds'
      collectionId?: string
    }): Promise<WrappedCreateFeedResponse> => {
      if (!organizationId) {
        throw new Error('Organization ID is required')
      }

      if (limitReached) {
        throw new Error('Failed to add feed: Feed limit reached.')
      }
      const result = await createFeed({
        variables: {
          input: {
            organizationId,
            collectionId,
            rssAtom: { url: feedUrl },
          },
        },
      })

      if (result.success) {
        toast.success('Successfully added feed')
        BufferTrackerReact.feedSubscribed({
          product: 'publish',
          organizationId,
          feedId: result.data.feed.id,
          feedCollectionId: collectionId ?? '',
          feedUrl,
          feedQuery: undefined,
          feedType: 'rssAtom',
          clientName: 'publishWeb',
          cta,
        })
        // Only redirect to newly created feed if the parent collection is not selected
        // When the parent collection is selected, we'd rather see the new feed aggregated in the collection
        const parentCollectionIsSelected =
          result.data.feed.collection?.id &&
          location.pathname.includes(result.data.feed.collection?.id ?? '')
        if (!parentCollectionIsSelected) {
          history.push(singleFeedPage.linkTo(result.data.feed.id))
        }
        return { feedId: result.data.feed.id, error: null }
      }
      return { feedId: null, error: parseErrorMessage(result.error) }
    },
    [createFeed, organizationId, limitReached, history],
  )

  const [moveFeed] = useTypedMutation(MoveFeed, (data) => data.moveFeed, {
    successTypename: 'FeedMoveUpdatesResponse',
  })
  const handleMoveFeed = React.useCallback(
    async (feedId: string, collectionId?: string) => {
      const { error } = await moveFeed({
        variables: {
          input: {
            id: feedId,
            collectionId,
            organizationId: organizationId ?? '',
          },
        },
      })
      if (error) {
        toast.error(`Failed to move feed`)
      } else if (collectionId) {
        // open the feed by url
        history.push(singleFeedPage.linkTo(feedId))
      }
    },
    [moveFeed, organizationId, history],
  )

  const [deleteFeed] = useDeleteFeed()
  const handleDeleteFeed = useCallback(
    async (deletingFeedId: string) => {
      if (!organizationId) return

      const { error } = await deleteFeed({
        variables: { input: { organizationId, id: deletingFeedId } },
      })
      if (error) {
        toast.error(`Failed to delete feed`)
      }

      // Only redirect if the deleted feed is the active one
      if (!history.location.pathname.includes(deletingFeedId)) {
        return
      }

      const parentCollection = collections.find((collectionData) => {
        const collection = readFragment(
          SidebarCollectionItem_Collection,
          collectionData,
        )
        return collection?.feeds?.some((feed) => feed?.id === deletingFeedId)
          ? collection
          : null
      })
      if (parentCollection) {
        history.push(feedCollectionPage.linkTo(parentCollection.id))
        return
      }

      const feedFragments = feeds.map((feed) =>
        readFragment(SidebarFeedItem_Feed, feed),
      )
      const deletedIndex = feedFragments.findIndex(
        (feed) => feed?.id === deletingFeedId,
      )
      const feedToNavigateToIndex =
        deletedIndex === feedFragments.length - 1
          ? deletedIndex - 1
          : deletedIndex + 1
      const feedToNavigateTo = feedFragments[feedToNavigateToIndex]
      if (feedToNavigateTo) {
        history.push(singleFeedPage.linkTo(feedToNavigateTo.id))
        return
      }

      history.push(createPage.route)
    },
    [deleteFeed, collections, history, organizationId, feeds],
  )

  /**
   * Collection Actions
   */
  const [createNewFeedCollection] = useCreateNewFeedCollection()
  const handleCreateCollection = useCallback(
    async (name: string) => {
      if (!organizationId) return
      const { data, error } = await createNewFeedCollection({
        variables: {
          input: {
            name,
            organizationId,
            feedIds: [],
          },
        },
      })
      if (error) {
        toast.error(`Failed to create feed collection`)
        return
      }

      if (isOfType(data, 'FeedCollectionActionSuccess')) {
        history.push(feedCollectionPage.linkTo(data.feedCollection.id))
        BufferTrackerReact.feedCollectionCreated({
          product: 'publish',
          organizationId,
          feedCollectionId: data.feedCollection.id,
          feedCollectionName: data.feedCollection.name,
          clientName: 'publishWeb',
        })
      }
    },
    [createNewFeedCollection, history, organizationId],
  )

  const [updateFeedCollection] = useUpdateFeedCollection()
  const handleRenameCollection = useCallback(
    async (collectionId: string, value: string) => {
      const { error } = await updateFeedCollection({
        variables: {
          input: {
            name: value,
            feedCollectionId: collectionId,
          },
        },
      })

      if (error) {
        toast.error(`Failed to rename feed collection`)
      }
    },
    [updateFeedCollection],
  )

  const [deleteFeedCollection] = useDeleteFeedCollection()
  const handleDeleteCollection = useCallback(
    async (deletingCollectionId: string): Promise<void> => {
      const { error } = await deleteFeedCollection({
        variables: { input: { feedCollectionId: deletingCollectionId } },
      })

      if (error) {
        toast.error(`Failed to delete feed collection`)
        return
      }

      // Only redirect if the deleted collection is the active one
      if (!history.location.pathname.includes(deletingCollectionId)) {
        return
      }

      // For now we're only considering collections and not root level feeds.
      // This is an intermediate step as we release root level feeds.
      // TODO: Update this to consider root level and nested feeds.
      const itemIndex = collections.findIndex(
        (collection) => collection?.id === deletingCollectionId,
      )
      const nextFeedItem =
        collections[itemIndex + 1]?.id ?? collections[itemIndex - 1]?.id ?? null
      if (nextFeedItem) {
        history.push(feedCollectionPage.linkTo(nextFeedItem))
      } else {
        history.push(createPage.route)
      }
    },
    [collections, deleteFeedCollection, history],
  )

  const isEmpty =
    collections.length === 0 && feeds.length === 0 && !isAddingNewCollection

  if (feedsError && collections.length === 0 && feeds.length === 0) {
    return <SidebarFeedError message={feedsError.message} />
  }

  return (
    <section>
      {/* modal=false seems to be necessary to allow auto-selecting collection input on Add Collection menu item click */}
      {/* It's possible there's a different solution, just haven't had time to dig deeper and we're already preventing trigger auto-focus on close */}
      <Sidebar.List>
        <Sidebar.ListItem>
          <DropdownMenu modal={false}>
            <DropdownMenu.Trigger>
              <Sidebar.Button
                prefix={state === 'collapsed' ? <RssAddIcon /> : <RssIcon />}
                suffix={
                  state === 'expanded' && (
                    <div className={styles.addFeedIconButton}>
                      <AddFeedCoachMark>
                        <IconButton
                          variant="tertiary"
                          label="Add feed"
                          aria-hidden
                        >
                          <PlusIcon />
                        </IconButton>
                      </AddFeedCoachMark>
                    </div>
                  )
                }
                className={styles.feedSectionLabel}
              >
                <div
                  className={styles.feedSectionLabelContent}
                  id="feeds-section-header"
                >
                  <Flex align="center" gap="2xs">
                    Feeds <NewBadge />
                  </Flex>
                </div>
              </Sidebar.Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
              align="end"
              className={styles.dropdownMenu}
              // Prevent trigger autofocus because collection input needs to auto focus
              onCloseAutoFocus={(e): void => e.preventDefault()}
            >
              <TrackRender componentName="SidebarFeedsDropdownMenu" />
              <SubscribeToFeedDialogDropdownMenuItem
                tier={tier}
                limitReached={limitReached}
                existingFeeds={existingFeeds}
                onAddFeed={handleCreateFeed}
                onUpgrade={handleUpgradeClick}
              />
              <ImportFeedsDialogDropdownMenuItem
                tier={tier}
                limitReached={limitReached}
                existingFeeds={existingFeeds}
                remainingFeedsCount={totalRemainingFeeds}
                onAddFeed={handleCreateFeed}
                onRemoveFeed={handleDeleteFeed}
                onUpgrade={handleUpgradeClick}
              />
              <DropdownMenu.Separator />
              <ExploreFeedsDialogDropdownMenuItem
                existingFeeds={existingFeeds}
                tier={tier}
                limitReached={limitReached}
                onAddFeed={handleCreateFeed}
                onRemoveFeed={handleDeleteFeed}
                onUpgrade={handleUpgradeClick}
              />
              <DropdownMenu.Separator />
              <HardLimitReachedDialog
                enabled={collectionsLimitReached}
                type="collections"
              >
                <DropdownMenu.Item
                  onSelect={(event): void => {
                    if (collectionsLimitReached) event.preventDefault()
                    else setIsAddingNewCollection(true)
                  }}
                >
                  <FolderPlusIcon aria-hidden /> Add Collection
                  {collectionsLimitReached && (
                    <LockIcon className={styles.suffixIcon} />
                  )}
                </DropdownMenu.Item>
              </HardLimitReachedDialog>
            </DropdownMenu.Content>
          </DropdownMenu>
        </Sidebar.ListItem>
      </Sidebar.List>
      {isEmpty && sidebarFeedsLoading && <SidebarSkeletons />}
      {isEmpty && !sidebarFeedsLoading && (
        <Paragraph color="subtle" className={styles.noFeedsPlaceholder}>
          Add feeds from your favorite websites to get content ideas
        </Paragraph>
      )}
      <Sidebar.List
        className={styles.feedSection}
        aria-labelledby="feeds-section-header"
      >
        {feeds.map((feed) => {
          const feedData = readFragment(SidebarFeedItem_Feed, feed)
          return (
            <SidebarFeedItem
              key={feedData.id}
              data={feed}
              collections={moveToCollectionsList}
              indent={1}
              onMoveToCollection={handleMoveFeed}
              onDelete={handleDeleteFeed}
            />
          )
        })}
        {isAddingNewCollection && (
          <FeedCollectionNameEditor
            onUpdate={(name: string): void => {
              handleCreateCollection(name)
              setIsAddingNewCollection(false)
            }}
            onClose={(): void => setIsAddingNewCollection(false)}
            className={styles.sidebarLink}
          />
        )}
        {collections.map((collection) => {
          return (
            <SidebarCollectionItem
              key={collection.id}
              collection={collection}
              tier={tier}
              onAddFeed={handleCreateFeed}
              onRemoveFeed={handleDeleteFeed}
              onUpgrade={handleUpgradeClick}
              onRename={handleRenameCollection}
              onDelete={handleDeleteCollection}
            >
              <Sidebar.List
                className={styles.subList}
                aria-label={`${collection.name} feeds`}
              >
                {collection.feeds?.length ? (
                  collection.feeds.map((feed) => (
                    <SidebarFeedItem
                      key={feed.id}
                      data={feed}
                      collections={moveToCollectionsList}
                      indent={2}
                      onMoveToCollection={handleMoveFeed}
                      onDelete={handleDeleteFeed}
                    />
                  ))
                ) : (
                  <Sidebar.ListItem>
                    <Text
                      size="md"
                      color="subtle"
                      className={styles.noFeeds}
                      aria-live="polite"
                    >
                      No feeds
                    </Text>
                  </Sidebar.ListItem>
                )}
              </Sidebar.List>
            </SidebarCollectionItem>
          )
        })}
      </Sidebar.List>
    </section>
  )
}
