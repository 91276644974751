import React from 'react'
import { Text, Button, Heading } from '@buffer-mono/popcorn'
import { Image } from '@buffer-mono/legacy-bufferapp-components'
import styles from './index.module.css'

interface HeroImgSize {
  width?: string
  height?: string
}

interface ActionProps {
  onClick: () => void
  label: string
  icon?: React.ReactNode
  iconEnd?: boolean
}

interface EmptyStateProps {
  title?: string | null
  subtitle?: string | null
  heroImg?: string | null
  heroImgAlt?: string | null
  heroImgSize?: HeroImgSize | null
  emoji?: string | null
  primaryAction?: ActionProps | null
}

const EmptyState = ({
  title = null,
  subtitle = null,
  emoji = null,
  heroImg = null,
  heroImgSize = null,
  heroImgAlt = null,
  primaryAction = null,
}: EmptyStateProps): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      {emoji && (
        <Text className={styles.emoji} as="h3">
          {emoji}
        </Text>
      )}
      {heroImg && (
        <Image
          marginBottom="24px"
          alt={heroImgAlt ?? ''}
          src={heroImg}
          width={heroImgSize?.width}
          height={heroImgSize?.height}
        />
      )}
      <div className={styles.textContainer}>
        {title && (
          <Heading align="center" size="large" as="h4">
            {title}
          </Heading>
        )}
        {subtitle && <Text as="p">{subtitle}</Text>}
      </div>
      <div className={styles.buttonWrapper}>
        {primaryAction && (
          <Button variant="primary" onClick={primaryAction.onClick}>
            {primaryAction.icon ?? null}
            {primaryAction.label}
            {primaryAction.iconEnd ?? null}
          </Button>
        )}
      </div>
    </div>
  )
}

export default EmptyState
