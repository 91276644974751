import React from 'react'

import Banner from '../../../../components/Banner'

const TwitterIsDownBanner = (): JSX.Element => {
  return (
    <Banner
      themeColor="orange"
      text="Due to ongoing changes at Twitter, some of Buffer’s Twitter features (including scheduled posts) may not work."
      dismissible={false}
      actionButton={{
        label: 'Learn More',
        action: (): void => {
          window.location.href =
            'https://buffer.com/resources/twitter-api-changes/'
        },
      }}
    />
  )
}

export default TwitterIsDownBanner
