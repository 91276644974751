import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from '@buffer-mono/popcorn'

import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { client, gql } from '~publish/legacy/apollo-client'
import type { Service, UploadDetails } from '~publish/gql/graphql'
import type { UserTag } from '~publish/legacy/user/types'
import {
  parseIdeaToComposerPost,
  getFirstVideo,
  getFirstDocument,
} from '~publish/pages/Create/helpers/parsers'
import type {
  Media,
  ExtendedIdea,
  ExtendedMedia,
  Idea,
} from '~publish/pages/Create/types'
import { isExtendedMedia } from '~publish/pages/Create/helpers'
import type { NewIdea } from '~publish/components/IdeaComposer/IdeaComposer'
import { selectProfiles } from '~publish/legacy/profile-sidebar/selectors'
import type { RootState } from '~publish/legacy/store'

export const GET_UPLOAD = gql`
  query upload($input: UploadIdInput!) {
    upload(input: $input) {
      ... on UploadDetails {
        id
        type
        height
        width
        duration
        durationMs
        location
        transcodedLocation
      }
      ... on CoreWebAppCommonError {
        success
        message
      }
    }
  }
`

export const createPostFromIdea = createAsyncThunk<
  void,
  { idea: Idea | NewIdea; user: UserTag },
  { state: RootState }
>(
  'ideas/createPostFromIdea',
  async ({ idea, user }, { getState, dispatch }) => {
    try {
      const mediaToParse = idea?.content?.media || []
      const firstVideo = getFirstVideo(mediaToParse) as ExtendedMedia

      let extendedVideo: ExtendedMedia | undefined

      if (firstVideo) {
        const uploadId = firstVideo.id
        if (!uploadId) return

        const response = await client.query<
          {
            upload: UploadDetails
          },
          { input: { id: string } }
        >({
          query: GET_UPLOAD,
          variables: { input: { id: uploadId } },
        })

        if (response.data.upload) {
          const {
            height,
            width,
            duration,
            durationMs,
            location,
            transcodedLocation,
          } = response.data.upload
          extendedVideo = {
            ...firstVideo,
            height,
            width,
            duration,
            durationMs,
            location: location ?? undefined,
            transcodedLocation: transcodedLocation ?? undefined,
          }
        }
      }

      const firstDocument = getFirstDocument(mediaToParse)
      let updatedMedia = mediaToParse

      if (extendedVideo && isExtendedMedia(extendedVideo)) {
        updatedMedia = mediaToParse.map((media) =>
          media?.id === firstVideo?.id ? (extendedVideo as Media) : media,
        )
      } else if (firstDocument) {
        updatedMedia = mediaToParse.map((media) =>
          media?.id === firstDocument?.id ? (firstDocument as Media) : media,
        )
      }

      const profiles = selectProfiles(getState())

      const profileIds = profiles
        .filter((profile) =>
          idea?.content?.services?.includes(profile.service as Service),
        )
        .map((profile) => profile.id)

      const payload = {
        selectedProfileIds: profileIds,
        shouldResetComposerData: false,
        post: parseIdeaToComposerPost(
          {
            ...(idea as ExtendedIdea),
            content: { ...idea.content, media: updatedMedia },
          },
          user,
        ),
        idea: idea as ExtendedIdea,
      }

      dispatch(composerPopoverActions.handleCreatePostClick(payload))
    } catch (error) {
      toast.error(
        `Sorry! Something went wrong while creating your post. Would you be up for trying again?`,
      )
    }
  },
)
