import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import type { TimeFormat } from '~publish/gql/graphql'
import { GetAccountPreferences } from './usePreferences'
import { GET_ACCOUNT } from '~publish/legacy/accountContext'

export const UpdateTimeFormatPreferenceMutation = graphql(/* GraphQL */ `
  mutation updateTimeFormatPreference(
    $input: UpdateTimeFormatPreferenceInput!
  ) {
    updateTimeFormatPreference(input: $input) {
      __typename
      ... on UpdateTimeFormatPreferenceSuccess {
        _empty
      }
      ... on InvalidInputError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

export type UpdateTimeFormatPreference = ({
  timeFormat,
}: {
  timeFormat: TimeFormat
}) => Promise<void>

type UpdateTimeFormatPreferenceResponse = {
  updateTimeFormatPreference: UpdateTimeFormatPreference
  isLoading: boolean
  hasError: boolean
}

/**
 * Custom hook to update and Accounts time format preference mutation.
 * @returns {UpdateTimeFormatPreferenceResponse} The function to trigger updating the time format preference.
 */
export const useUpdateTimeFormatPreference =
  (): UpdateTimeFormatPreferenceResponse => {
    const [executeUpdateTimeFormatPreference, { loading, error }] = useMutation(
      UpdateTimeFormatPreferenceMutation,
    )

    const updateTimeFormatPreference = useCallback(
      async ({ timeFormat }: { timeFormat: TimeFormat }) => {
        try {
          const { data, errors } = await executeUpdateTimeFormatPreference({
            variables: {
              input: {
                timeFormat,
              },
            },
            refetchQueries: [GetAccountPreferences, GET_ACCOUNT],
          })

          if (
            data?.updateTimeFormatPreference?.__typename ===
              'UnexpectedError' ||
            data?.updateTimeFormatPreference?.__typename ===
              'InvalidInputError' ||
            data?.updateTimeFormatPreference?.__typename === 'NotFoundError'
          ) {
            const error = new Error(data.updateTimeFormatPreference.message)
            logError(error, { metaData: { data, errors } })
            throw error
          }

          // Handle GraphQL errors if any
          if (
            errors?.length ||
            data?.updateTimeFormatPreference?.__typename !==
              'UpdateTimeFormatPreferenceSuccess'
          ) {
            const unexpectedError = new Error(
              errors?.[0]?.message || 'Unexpected API response',
            )
            logError(unexpectedError, { metaData: { data, errors } })
            throw unexpectedError
          }
        } catch (error) {
          logError(error as Error, {
            metaData: { variables: { timeFormat } },
          })
          throw error
        }
      },
      [executeUpdateTimeFormatPreference],
    )

    return {
      updateTimeFormatPreference,
      isLoading: loading,
      hasError: !!error,
    }
  }
