import React, { useState } from 'react'
import { CoachMark, Button, Flex, Slot } from '@buffer-mono/popcorn'

import useDismissBanner, {
  BannerTypes,
} from '../../../../../common/hooks/useDismissBanner'
import { StreakBackgroundPlain } from '../images/StreakBackgroundPlain'

import { StreakHeader } from './StreakHeader'

import styles from './StreakCoachMark.module.css'

const COACH_MARK_CONTENT = [
  {
    title: 'Build your streak!',
    description:
      'Post each week to grow your streak! Miss a week and it resets.',
    image:
      'https://buffer-ui.s3.us-east-1.amazonaws.com/streaks/onboarding1.png',
  },
  {
    title: 'Keep the momentum',
    description:
      "Consistent posting helps grow your audience. Pre-schedule posts to keep your streak when you're busy!",
    image:
      'https://buffer-ui.s3.us-east-1.amazonaws.com/streaks/onboarding2.png',
  },
] as const

export const StreakCoachMark = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0)
  const [open, setOpen] = useState(false)

  const { isActive, onDismissBanner } = useDismissBanner({
    banner: BannerTypes.streakCoachMark,
  })

  if (!isActive) return <>{children}</>

  const isLastStep = currentStep === COACH_MARK_CONTENT.length - 1
  const currentContent = COACH_MARK_CONTENT[currentStep]

  const handleNext = (): void => {
    if (isLastStep) {
      onDismissBanner()
      setOpen(false)
    } else {
      setCurrentStep((prev) => prev + 1)
    }
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    if (isActive) {
      setOpen((prev) => !prev)
    }
  }

  return (
    <CoachMark open={open}>
      <CoachMark.Overlay>
        <Slot.Root onClickCapture={handleClick}>{children}</Slot.Root>
        <CoachMark.Spotlight
          style={
            {
              '--spotlight-color': 'var(--global-color-green-500)',
            } as React.CSSProperties
          }
        />
      </CoachMark.Overlay>

      <CoachMark.Content showCloseIcon={false} className={styles.streakContent}>
        <StreakHeader>
          <StreakHeader.Background>
            <StreakBackgroundPlain />
          </StreakHeader.Background>
          <img src={currentContent.image} alt="" height={81} />
        </StreakHeader>
        <Flex direction="column" align="center" gap="xs">
          <CoachMark.Title className={styles.streakTitle}>
            {currentContent.title}
          </CoachMark.Title>
          <CoachMark.Description
            className={styles.streakDescription}
            size="sm"
            color="subtle"
            lineHeight="regular"
          >
            {currentContent.description}
          </CoachMark.Description>
        </Flex>
        <CoachMark.Footer>
          <Button size="small" onClick={handleNext}>
            {isLastStep ? 'Got it' : 'Next'}
          </Button>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
