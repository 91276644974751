/* eslint-disable @typescript-eslint/naming-convention */
import { z } from 'zod'

export const organizationNameSchema = z
  .string({
    required_error: 'Organization name is required',
    invalid_type_error: 'Organization name must be a string',
  })
  .trim()
  .min(1, 'Organization name cannot be empty')
  .max(80, 'Organization name must be less than 80 characters')

export type OrganizationNameValidation = z.infer<typeof organizationNameSchema>
