import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  gql,
  type TypePolicies,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { RetryLink } from '@apollo/client/link/retry'
import { env } from '~publish/env'
import generatedIntrospection from '~publish/gql/introspection-result'
import Bugsnag from '@bugsnag/browser'

const baseConfig = {
  uri: env.VITE_GRAPHQL_API,
  credentials: 'include',
  headers: {
    'x-buffer-client-id': 'webapp-publishing',
  },
}

const ErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  /*
   * We want to ignore certain errors that are not critical and don't need to be reported to Bugsnag
   * For example, if the organization is not found, we don't want to report that as an error
   * This is a workaround until we have a better way to handle these errors
   */
  if (graphQLErrors) {
    // Filter out specific errors
    const filteredGraphQLErrors = graphQLErrors.filter(
      (error) => error.extensions?.name !== 'EngageOrganizationNotFoundError',
    )

    // If no remaining errors, suppress further error handling
    if (filteredGraphQLErrors.length === 0 && !networkError) {
      return // Prevent error propagation
    }
  }

  const metaData = {
    operationName: operation?.operationName ?? 'undefined',
    variables: operation?.variables,
    graphQLErrors: graphQLErrors?.map((e) => e.message),
  }

  const error = networkError ?? new Error('Unknown GraphQL error')
  Bugsnag.notify(error, (event) => {
    event.addMetadata('GraphQL', metaData)
  })
})

const AddOperationNameLink = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName
  if (operationName) {
    try {
      const urlObj = new URL(operation.getContext().uri || baseConfig.uri)
      urlObj.searchParams.set('_o', operationName)
      operation.setContext({ uri: urlObj.href })
    } catch (e) {
      // Something failed, but we don't want to break as this is only for debugging purposes
    }
  }
  return forward(operation)
})

// It does not currently handle retries for GraphQL errors in the response, only for network errors.
const RetryRequestLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity, // The maximum number of milliseconds that the link should wait for any retry.
    jitter: true,
  },
  attempts: {
    max: 3, // The max number of times to try a single operation before giving up.
  },
})

const NormalLink = new HttpLink(baseConfig)
const MockLink = new HttpLink({
  ...baseConfig,
  uri: env.VITE_GRAPHQL_MOCK_API,
})

/**
 * Look at the query to determine whether to send to the mock or production API.
 * https://www.apollographql.com/docs/react/api/link/introduction/#directional-composition
 */
const MockOrNormalLink = ApolloLink.split(
  (operation) => operation.getContext().mock === true,
  MockLink,
  NormalLink,
)

/**
 * Custom type polcicies for Apollo Client
 */
const typePolicies: TypePolicies = {
  Idea: {
    keyFields: ['id'],
  },
  Query: {
    fields: {
      postsLegacy: relayStylePagination(),
      posts: relayStylePagination(['input']),
      ideasV2: relayStylePagination(),
      post: {
        read(_, { args, toReference }) {
          return toReference({ __typename: 'Post', id: args?.input?.id })
        },
      },
      feedItems: relayStylePagination(['input']),
    },
  },
  Post: {
    fields: {
      isProcessing: {
        read(isProcessing) {
          return isProcessing ?? false
        },
      },
    },
  },
  AccountOrganization: {
    fields: {
      privileges: {
        merge: true,
      },
    },
  },
}

const client = new ApolloClient({
  link: ApolloLink.from([
    ErrorLink,
    AddOperationNameLink,
    RetryRequestLink,
    MockOrNormalLink,
  ]),
  cache: new InMemoryCache({
    typePolicies,
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
  connectToDevTools: true,
})

export { gql, client }
