import {
  isPayingAnalyzeOrganization,
  isPayingPublishOrganization,
} from '../../../common/hooks/utils/segmentTraitGetters'
import type { Account } from '../../../common/types'
import {
  isPayingStripeCustomer,
  canManageBilling,
  hasExpiredOrExpiringCreditCard,
  hasPaymentDetails,
  getUserCreditCardDetails,
} from '../../../common/utils/user'

export const shouldShowExpiredCardBanner = (user: Account): boolean => {
  const userHasCreditCardDetails = getUserCreditCardDetails(user)

  if (!userHasCreditCardDetails) return false

  return Boolean(
    (isPayingStripeCustomer(user) ||
      isPayingPublishOrganization(user) ||
      isPayingAnalyzeOrganization(user)) &&
      canManageBilling(user) &&
      hasPaymentDetails(user) &&
      hasExpiredOrExpiringCreditCard(user),
  )
}
