import type { History } from 'history'
import React, { useEffect } from 'react'
import { replace } from 'redux-first-history'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  type RouteProps,
} from 'react-router-dom'

import { useLocalStorage } from '@buffer-mono/popcorn'

import PagesWithSidebar from '~publish/legacy/app-pages/components/PagesWithSidebar'
import { CALENDAR_PAGE } from '~publish/legacy/constants'
import {
  allChannels,
  blueprintsPage,
  calendarMonth,
  calendarPage,
  calendarWeek,
  campaignsPage,
  channel,
  channelGroups,
  channelSettings,
  channelShopGrid,
  compose,
  createPage,
  ideaNewRoute,
  linkToPost,
  newCalendarAllChannels,
  newCalendarSingleChannel,
  newPost,
  profileChildTabPages,
  profilePages,
  profileTabPages,
  tagsPage,
  settings,
  commentsPage,
  commentsChannel,
  preferencesAppsExtras,
  appsAndExtrasRoute,
  preferencesSecurity,
  preferencesGeneral,
  preferencesNotifications,
  settingsNotificationsRoute,
  preferencesPage,
} from '~publish/legacy/routes'
import { setLastVisitedPage } from '~publish/legacy/utils/page-visits'
import CreatePage from '~publish/pages/Create'
import { useConnectChannelModal } from '~publish/hooks/useConnectChannelModal'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useAppDispatch } from '~publish/legacy/store'
import { NewPostPage } from '~publish/pages/NewPost'
import { useChannelGroupsAccess } from '~publish/pages/ChannelGroups/hooks/useChannelGroupAccess'
import Settings from '~publish/pages/Settings/Settings'
import { useStreaksAutoRefresh } from '~publish/hooks/useStreaksAutoRefresh'

import { useChannelsConnected, usePageLoad } from '../../../accountContext'
import ComposePage from '../../../compose-page'
import { PostDeepLinkResolver } from '../PostDeepLinkResolver/PostDeepLinkResolver'

import { getChannelLocationFromProfile } from './redirectionUtils'
import {
  DEFAULT_POST_LIST_VIEW_MODE,
  LAST_POST_LIST_VIEW_MODE_LOCAL_STORAGE_KEY,
  type ListOrCalendarViewMode,
} from '../../../../components/PostListOrCalendarViewToggle'
import {
  LAST_VIEW_MODE_LOCAL_STORAGE_KEY,
  DEFAULT_VIEW_MODE,
} from '../../../../components/Calendar/hooks/useCalendar'
import type { CalendarDateRangeViewMode } from '../../../../components/Calendar/hooks/useCalendarDateRangeViewMode'
import { CommentsRouteHandler } from '../CommentsRouteHandler'
import { useNewCommentReceived } from '~publish/hooks/useNewCommentReceived'

declare global {
  // window.__history is used to share the history object with the app shell to have smooth navigations
  interface Window {
    __history: History<unknown> | undefined
    __isComposerOpen?: boolean
  }
}

export const AppPages = (): JSX.Element => {
  // Get current selected org from account
  const { createNewPostInComposer, isOpen: isComposerOpen } = usePostComposer()
  const connectedChannelsOnOrganization = useChannelsConnected()
  const dispatch = useAppDispatch()

  const history = useHistory()
  useEffect(() => {
    // Make history object available globally so that it can be used in the app shell
    window.__history = history
  }, [history])

  useEffect(() => {
    // Make post composer and idea composer available globally so that it can be used in the app shell
    window.__openComposer = createNewPostInComposer
    window.__isComposerOpen = isComposerOpen
  }, [createNewPostInComposer, isComposerOpen])

  const { updateInitialPageLoad } = usePageLoad()

  /**
   * We're deprecating the `awaitingApproval` and `pendingApproval` routes
   * in favour of the `approvals` route. This is a temporary redirect to
   * ensure that users are redirected to the correct page.
   */
  const approvalsRoute = channel.getRoute(
    connectedChannelsOnOrganization?.[0]?.id ?? '',
    { tab: 'approvals' },
  )
  const deprecatedAwaitingApprovalRoute = profileTabPages.getRoute({
    profileId: connectedChannelsOnOrganization?.[0]?.id ?? '',
    tabId: 'awaitingApproval',
  })
  const deprecatedPendingApprovalRoute = profileTabPages.getRoute({
    profileId: connectedChannelsOnOrganization?.[0]?.id ?? '',
    tabId: 'pendingApproval',
  })
  const RedirectToApprovals = ({ location }: RouteProps): JSX.Element => (
    <Redirect
      to={{
        ...location,
        pathname: approvalsRoute,
      }}
    />
  )

  useEffect(() => {
    updateInitialPageLoad()
  })
  useConnectChannelModal()
  useStreaksAutoRefresh()
  useNewCommentReceived()

  const { canAccessChannelGroups, isGroupsReady } = useChannelGroupsAccess()
  const [listOrCalendarViewMode] = useLocalStorage<ListOrCalendarViewMode>(
    LAST_POST_LIST_VIEW_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_POST_LIST_VIEW_MODE,
  )
  const [calendarDateRangeViewMode] =
    useLocalStorage<CalendarDateRangeViewMode>(
      LAST_VIEW_MODE_LOCAL_STORAGE_KEY,
      DEFAULT_VIEW_MODE,
    )

  const location = history.location
  const channelSelectedThroughRoute = (location.state as { channel?: string })
    ?.channel

  useEffect(() => {
    // Reset channel to select state included in some location changes
    if (channelSelectedThroughRoute) {
      dispatch(replace(location.pathname, {}))
    }

    /* TODO: we could include here others setLastVisitedPage calls, as the one for queue, for example */
    if (location.pathname.includes('calendar')) {
      setLastVisitedPage(CALENDAR_PAGE)
    }
  }, [location, channelSelectedThroughRoute])

  const defaultPath = (): string => {
    // NEW
    /**
     * Calculate the default path based on the following rules:
     * - If user has only one connected channel -> Redirect to connected channel
     * - If user has zero or multiple connected channels -> Redirect to all channels
     * - In either view, load calendar/list view based on local storage
     * - Otherwise redirect to all channels queue
     */
    const hasOnlyOneConnectedChannel =
      connectedChannelsOnOrganization?.length === 1
    const shouldRedirectToCalendar = listOrCalendarViewMode === 'calendar'

    if (hasOnlyOneConnectedChannel) {
      const channelId = connectedChannelsOnOrganization?.[0]?.id
      return shouldRedirectToCalendar
        ? newCalendarSingleChannel.buildPathname({
            id: channelId,
            granularity: calendarDateRangeViewMode,
          })
        : channel.getRoute(channelId)
    }

    return shouldRedirectToCalendar
      ? newCalendarAllChannels.buildPathname({
          granularity: calendarDateRangeViewMode,
        })
      : allChannels.route
  }

  return (
    <Switch>
      <Route path={compose.route}>
        <ComposePage />
      </Route>
      <Route
        path={[
          campaignsPage.route,
          tagsPage.route,
          ...(canAccessChannelGroups || !isGroupsReady
            ? [channelGroups.route]
            : []),
          newCalendarAllChannels.route,
          newCalendarSingleChannel.route,
          allChannels.route,
          channel.route,
          channelSettings.route,
          channelShopGrid.route,
          blueprintsPage.route,
        ]}
      >
        <PagesWithSidebar />
      </Route>

      {/* Comments routes */}
      <Route path={[commentsChannel.route, commentsPage.route]}>
        <CommentsRouteHandler defaultPath={defaultPath()} />
      </Route>

      {/* Legacy all-posts route */}
      <Route
        path="/all-posts"
        render={({ location }): JSX.Element => (
          <Redirect to={{ ...location, pathname: allChannels.route }} />
        )}
      />
      <Route
        path={[
          profileChildTabPages.route,
          profileTabPages.route,
          profilePages.route,
        ]}
        render={({ location, match }): JSX.Element => (
          <Redirect to={getChannelLocationFromProfile({ location, match })} />
        )}
      />
      <Route
        path={calendarMonth.route}
        render={({ location }): JSX.Element => {
          return (
            <Redirect
              to={{
                ...location,
                pathname: newCalendarAllChannels.getRoute('month'),
                search: location.search,
              }}
            />
          )
        }}
      />
      <Route
        path={[calendarWeek.route, calendarPage.route]}
        render={({ location }): JSX.Element => {
          return (
            <Redirect
              to={{
                ...location,
                pathname: newCalendarAllChannels.getRoute('week'),
                search: location.search,
              }}
            />
          )
        }}
      />
      <Route
        path={[deprecatedAwaitingApprovalRoute, deprecatedPendingApprovalRoute]}
      >
        <RedirectToApprovals />
      </Route>
      <Route path={linkToPost.route}>
        <PostDeepLinkResolver />
      </Route>
      {/* Create Routes */}
      {/* Redirect old /content paths for backwards compatibility */}
      <Route
        path="/content/ideas/new" // Because of some old routing, it's possible to end up here
        key="content-ideas-redirect-new"
        render={({ location }): JSX.Element => (
          <Redirect
            to={{
              ...location,
              // The ideaNewRoute is a generic subroute, so it's combined with the createPage route
              pathname: createPage.route + ideaNewRoute.route,
            }}
          />
        )}
      />
      <Route
        path="/content/new"
        key="content-redirect-new"
        render={({ location }): JSX.Element => (
          <Redirect
            to={{
              ...location,
              // The ideaNewRoute is a generic subroute, so it's combined with the createPage route
              pathname: createPage.route + ideaNewRoute.route,
            }}
          />
        )}
      />
      <Route
        path="/content"
        key="content-redirect-create"
        render={(): JSX.Element => <Redirect to={createPage.route} />}
      />

      <Route path={createPage.route} key="create-route">
        <CreatePage />
      </Route>

      <Route path={newPost.route}>
        <NewPostPage />
      </Route>

      <Route path={settings.route}>
        <Settings />
      </Route>
      {/* Redirect old preferences routes to new settings routes */}
      <Route
        path={[
          preferencesPage.route,
          preferencesSecurity.route,
          preferencesGeneral.route,
        ]}
        exact
        render={(): JSX.Element => <Redirect to={settings.route} />}
      />
      <Route
        path={preferencesAppsExtras.route}
        exact
        render={(): JSX.Element => <Redirect to={appsAndExtrasRoute.route} />}
      />
      <Route
        path={preferencesNotifications.route}
        exact
        render={(): JSX.Element => (
          <Redirect to={settingsNotificationsRoute.route} />
        )}
      />

      {/* Default / catch-all redirect */}
      <Route
        path="*"
        render={(): JSX.Element => <Redirect to={defaultPath()} />}
      />
    </Switch>
  )
}
