import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Button,
  CopyIcon,
  Dialog,
  Flex,
  Form,
  Heading,
  IconButton,
  Input,
  Link,
  Notice,
  Paragraph,
  RadioCard,
  Switch,
  Text,
  toast,
} from '@buffer-mono/popcorn'

import { graphql } from '~publish/gql'
import style from '../ProfileSettings.module.css'
import OtpQrCode from '../components/OtpQrCode'
import { useAccount } from '~publish/legacy/accountContext'
import { useSplitEnabled } from '@buffer-mono/features'

export const TWO_FACTOR_SETUP = graphql(/* GraphQL */ `
  mutation TwoFactorSetup($input: TwoFactorSetupInput!) {
    twoFactorSetup(input: $input) {
      ... on TwoFactorSetupResponse {
        key
      }
      ... on InvalidInputError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

export const TWO_FACTOR_CONFIRM = graphql(/* GraphQL */ `
  mutation TwoFactorConfirm($input: TwoFactorConfirmInput!) {
    twoFactorConfirm(input: $input) {
      ... on TwoFactorConfirmResponse {
        recoveryCode
      }
      ... on InvalidInputError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

export const TWO_FACTOR_DISABLE = graphql(/* GraphQL */ `
  mutation TwoFactorDisable {
    twoFactorDisable {
      ... on EmptySuccess {
        __typename
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

export const TWO_FACTOR_RECOVERY = graphql(/* GraphQL */ `
  query TwoFactorRecoveryCode($input: TwoFactorRecoveryCodeInput!) {
    recoveryCode(input: $input) {
      ... on TwoFactorRecoveryCodeResponse {
        recoveryCode
      }
      ... on InvalidInputError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

export const TWO_FACTOR_REQUEST_ONE_TIME_PASSWORD = graphql(/* GraphQL */ `
  mutation TwoFactorRequestOneTimePassword {
    twoFactorRequestOneTimePassword {
      ... on EmptySuccess {
        __typename
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

// Modal state
enum ModalPage {
  ChooseType = 'chooseType',
  SetupApp = 'setupApp',
  SetupPhone = 'setupPhone',
  SetupEmail = 'setupEmail',
  ConfirmCode = 'confirmCode',
  ShowRecovery = 'showRecovery',
  // When the user clicks on "View Code" in the UI
  ConfirmCodeRecovery = 'confirmCodeForRecovery',
}

const TwoFactor = (): JSX.Element => {
  const { account, refetchAccount } = useAccount()
  const accountEmail = account?.email
  const tfa = account?.tfa

  const { isEnabled: isTFAviaSMSEnabled } = useSplitEnabled('tfa-sms')

  const [modalPage, setModalPage] = useState<ModalPage>(ModalPage.ChooseType)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // State
  const [tfaMethod, setTfaMethod] = useState<'app' | 'sms' | 'email'>(
    tfa?.type ?? 'app',
  )
  const [telephone, setTelephone] = useState('')
  const [secret, setSecret] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')
  const [recoveryCode, setRecoveryCode] = useState('')

  // GraphQL mutations
  const [setupTwoFactor, { data: setupData, loading: setupLoading }] =
    useMutation(TWO_FACTOR_SETUP)
  const [confirmTwoFactor, { data: confirmData, loading: confirmLoading }] =
    useMutation(TWO_FACTOR_CONFIRM)
  const [disableTwoFactor, { data: disableData }] =
    useMutation(TWO_FACTOR_DISABLE)
  const [getRecoveryCode, { data: recoveryData, loading: recoveryLoading }] =
    useLazyQuery(TWO_FACTOR_RECOVERY)
  const [requestOneTimePassword] = useMutation(
    TWO_FACTOR_REQUEST_ONE_TIME_PASSWORD,
  )

  const [setupErrorMessage, setSetupErrorMessage] = useState<string | null>(
    null,
  )
  const [confirmErrorMessage, setConfirmErrorMessage] = useState<string | null>(
    null,
  )

  const loading = setupLoading || confirmLoading || recoveryLoading

  function handleNext(): void {
    // Choose Type
    if (modalPage === ModalPage.ChooseType) {
      if (tfaMethod === 'app') {
        setModalPage(ModalPage.SetupApp)
      } else if (tfaMethod === 'email') {
        setModalPage(ModalPage.ConfirmCode)
        setupTwoFactor({
          variables: {
            input: {
              method: 'email',
            },
          },
        })
      } else {
        setModalPage(ModalPage.SetupPhone)
      }
    }
    // Setup App
    if (
      modalPage === ModalPage.SetupApp ||
      modalPage === ModalPage.SetupEmail
    ) {
      setModalPage(ModalPage.ConfirmCode)
    }

    // Setup Phone
    if (modalPage === ModalPage.SetupPhone) {
      setupTwoFactor({
        variables: {
          input: {
            method: 'sms',
            telephone,
          },
        },
      })
    }

    // Confirm Code
    if (modalPage === ModalPage.ConfirmCode) {
      confirmTwoFactor({
        variables: {
          input: {
            method: tfaMethod,
            key: secret,
            code: confirmationCode,
            telephone: tfaMethod === 'sms' ? telephone : undefined,
          },
        },
      })
    }

    // Get Recovery Code
    if (modalPage === ModalPage.ConfirmCodeRecovery) {
      getRecoveryCode({
        variables: {
          input: {
            code: confirmationCode,
          },
        },
      })
    }

    // Show Recovery
    if (modalPage === ModalPage.ShowRecovery) {
      reset()
      setIsModalOpen(false)
    }
  }

  // Trigger Fetches
  useEffect(() => {
    if (
      modalPage === ModalPage.SetupApp ||
      modalPage === ModalPage.SetupEmail
    ) {
      setupTwoFactor({
        variables: {
          input: {
            method: tfaMethod,
            telephone,
          },
        },
      })
    }
    if (modalPage === ModalPage.ConfirmCodeRecovery) {
      requestOneTimePassword()
    }
  }, [modalPage, setupTwoFactor, telephone, tfaMethod, requestOneTimePassword])

  // Handle twoFactorSetup response
  useEffect(() => {
    setSetupErrorMessage(null)
    if (setupData?.twoFactorSetup?.__typename === 'TwoFactorSetupResponse') {
      setSecret(setupData.twoFactorSetup.key as string)
      if (tfaMethod === 'sms' && telephone) {
        setModalPage(ModalPage.ConfirmCode)
      }
    }
    if (
      ['UnexpectedError', 'InvalidInputError'].includes(
        setupData?.twoFactorSetup?.__typename as string,
      )
    ) {
      setSetupErrorMessage(
        (setupData?.twoFactorSetup as { message: string }).message,
      )
    }
  }, [setupData, tfaMethod, telephone])

  // Handle twoFactorConfirm response
  useEffect(() => {
    if (
      confirmData?.twoFactorConfirm?.__typename === 'TwoFactorConfirmResponse'
    ) {
      setRecoveryCode(confirmData.twoFactorConfirm.recoveryCode)
      setModalPage(ModalPage.ShowRecovery)
      if (refetchAccount) {
        refetchAccount()
      }
    }
    if (
      ['UnexpectedError', 'InvalidInputError'].includes(
        confirmData?.twoFactorConfirm?.__typename as string,
      )
    ) {
      setConfirmErrorMessage(
        (confirmData?.twoFactorConfirm as { message: string }).message,
      )
    }
  }, [confirmData, refetchAccount])

  // Handle twoFactorDisable response
  useEffect(() => {
    if (
      disableData?.twoFactorDisable?.__typename === 'EmptySuccess' &&
      refetchAccount
    ) {
      toast.success('Two factor authentication has been disabled')
      refetchAccount()
    }
    if (
      ['UnexpectedError', 'InvalidInputError'].includes(
        disableData?.twoFactorDisable?.__typename as string,
      )
    ) {
      toast.error(
        'An error occurred while disabling two factor authentication: ' +
          (disableData?.twoFactorDisable as { message: string }).message,
      )
    }
  }, [disableData, refetchAccount])

  // Handle recoveryCode response
  useEffect(() => {
    if (
      recoveryData?.recoveryCode?.__typename === 'TwoFactorRecoveryCodeResponse'
    ) {
      setRecoveryCode(recoveryData.recoveryCode.recoveryCode)
      setModalPage(ModalPage.ShowRecovery)
    }
    if (
      ['UnexpectedError', 'InvalidInputError'].includes(
        recoveryData?.recoveryCode?.__typename as string,
      )
    ) {
      setConfirmErrorMessage(
        (recoveryData?.recoveryCode as { message: string }).message,
      )
    }
  }, [recoveryData])

  function reset(): void {
    setModalPage(ModalPage.ChooseType)
    // reset state
    setTfaMethod('app')
    setTelephone('')
    setSecret('')
    setConfirmationCode('')
    setRecoveryCode('')
    setSetupErrorMessage(null)
    setConfirmErrorMessage(null)
  }

  const methodNameMap = {
    app: 'Authenticator App',
    sms: 'SMS',
    email: 'Email',
  }

  return (
    <>
      <Flex gap="lg" direction="column" className={style.fullWidth}>
        <Flex as="section" gap="xs" align="start" className={style.fullWidth}>
          <div>
            <Heading
              id="two-factor-heading"
              as="h2"
              size="small"
              style={{
                marginBottom: 'var(--space-xs)',
              }}
            >
              Two Factor Authentication
            </Heading>
            <Paragraph color="subtle">
              Two factor authentication adds an extra layer of security for your
              Buffer account. Whenever you log in to your account, after
              entering your username and password, you will be asked for a
              second authentication code that was sent to your mobile phone via
              text, or a free mobile app, or email.{' '}
              <Link
                external
                href="https://support.buffer.com/article/503-enabling-two-factor-authentication?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
              >
                Learn more
              </Link>
            </Paragraph>
          </div>
          <div>
            <Switch
              id="two-factor"
              aria-labelledby="two-factor-heading"
              checked={!!tfa}
              onClick={(e): void => {
                if (tfa) {
                  disableTwoFactor()
                } else {
                  e.preventDefault()
                  setIsModalOpen(true)
                }
              }}
            />
          </div>
        </Flex>
        {!!tfa && (
          <>
            <Flex
              as="section"
              gap="xs"
              justify="between"
              className={style.fullWidth}
            >
              <div>
                <Text
                  as="h4"
                  weight="medium"
                  style={{ marginBottom: 'var(--space-xs)' }}
                >
                  Method: {tfa ? methodNameMap[tfa.type] : 'Disabled'}
                  {tfa?.type === 'sms' &&
                    ' (Phone number ending in ' + tfa?.tel + ')'}
                </Text>
                <Paragraph color="subtle">
                  {tfa.type === 'app' &&
                    'Use an authenticator app to generate a one-time code'}
                  {tfa.type === 'sms' &&
                    'Receive a text message with one-time code'}
                  {tfa.type === 'email' &&
                    'Receive an email with one-time code'}
                </Paragraph>
              </div>
              <Button
                size="medium"
                variant="secondary"
                onClick={(e): void => {
                  e.preventDefault()
                  setIsModalOpen(true)
                }}
              >
                Edit Method
              </Button>
            </Flex>
            <Flex
              as="section"
              gap="xs"
              justify="between"
              className={style.fullWidth}
            >
              <div>
                <Text
                  as="h4"
                  weight="medium"
                  style={{ marginBottom: 'var(--space-xs)' }}
                >
                  Recovery Code
                </Text>
                <Paragraph color="subtle">
                  If you lose access to your {methodNameMap[tfa.type]}, use this
                  log in to your account.
                </Paragraph>
              </div>
              <Button
                size="medium"
                variant="secondary"
                onClick={(e): void => {
                  e.preventDefault()
                  setModalPage(ModalPage.ConfirmCodeRecovery)
                  setIsModalOpen(true)
                }}
              >
                View Code
              </Button>
            </Flex>
          </>
        )}
      </Flex>

      {/* 2FA Setup Modal */}

      <Dialog
        modal
        open={isModalOpen}
        onOpenChange={(isOpen): void => {
          if (!isOpen) {
            setIsModalOpen(false)
            reset()
          }
        }}
      >
        <Dialog.Content className={style.twoFactorDialog}>
          {modalPage === ModalPage.ChooseType && (
            <>
              <Dialog.Header>
                <Dialog.Title>Enable Two Factor Authentication</Dialog.Title>
                <Dialog.Description>
                  How would you like us to send your security codes?
                </Dialog.Description>
              </Dialog.Header>
              <Dialog.Body className={style.twoFactorDialogBody}>
                <RadioCard.Group
                  onValueChange={(value): void => {
                    setTfaMethod(value as 'app' | 'sms' | 'email')
                  }}
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                  defaultValue={tfaMethod}
                >
                  <RadioCard value="app">
                    <Flex gap="2xs" direction="column">
                      <Text weight="medium">Authenticator App</Text>
                      <Text color="subtle">
                        Use an authenticator app to generate a one-time code
                      </Text>
                    </Flex>
                  </RadioCard>
                  {isTFAviaSMSEnabled && (
                    <RadioCard value="sms">
                      <Flex gap="2xs" direction="column">
                        <Text weight="medium">Text Message</Text>
                        <Text color="subtle">
                          Receive a text message with one-time code
                        </Text>
                      </Flex>
                    </RadioCard>
                  )}
                  <RadioCard value="email">
                    <Flex gap="2xs" direction="column">
                      <Text weight="medium">Email</Text>
                      <Text color="subtle">
                        Receive an email with one-time code
                      </Text>
                    </Flex>
                  </RadioCard>
                </RadioCard.Group>
              </Dialog.Body>
              <Dialog.Footer>
                <Button
                  variant="tertiary"
                  size="large"
                  onClick={(): void => {
                    reset()
                    setIsModalOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="large"
                  onClick={handleNext}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Continue'}
                </Button>
              </Dialog.Footer>
            </>
          )}
          {modalPage === ModalPage.SetupApp && (
            <>
              <Dialog.Header>
                <Dialog.Title>Set up your authenticator app</Dialog.Title>
                <Dialog.Description>
                  Scan the QR code below in your authenticator app and
                  you&apos;re all set! (If you have not installed an
                  authenticator app yet,{' '}
                  <Link
                    external
                    href="https://support.buffer.com/article/503-enabling-two-factor-authentication?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
                  >
                    read here for more information.
                  </Link>
                  )
                </Dialog.Description>
              </Dialog.Header>
              <Dialog.Body className={style.twoFactorDialogBody}>
                <OtpQrCode account={accountEmail as string} secret={secret} />
                {setupErrorMessage && (
                  <Notice variant="error">{setupErrorMessage}</Notice>
                )}
              </Dialog.Body>
              <Dialog.Footer>
                <Button
                  variant="tertiary"
                  size="large"
                  onClick={(): void => reset()}
                  disabled={loading}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  size="large"
                  onClick={handleNext}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Continue'}
                </Button>
              </Dialog.Footer>
              <Dialog.CloseButton />
            </>
          )}
          {modalPage === ModalPage.SetupPhone && (
            <>
              <Dialog.Header>
                <Dialog.Title>Set up your phone number</Dialog.Title>
                <Dialog.Description>
                  This will be the device we send verification codes each time
                  you log into Buffer.
                </Dialog.Description>
              </Dialog.Header>
              <Dialog.Body className={style.twoFactorDialogBody}>
                <Form name="phoneForm">
                  <Form.Field name="telephone">
                    <Form.Label>Phone number (incl. country code): </Form.Label>
                    <Form.Control>
                      <Input
                        placeholder="E.g., +1XXXXXXXXXX"
                        onChange={(event): void =>
                          setTelephone(event.target.value)
                        }
                      />
                    </Form.Control>
                    {setupErrorMessage && (
                      <Notice
                        variant="error"
                        style={{ marginTop: 'var(--space-md)' }}
                      >
                        {setupErrorMessage}
                      </Notice>
                    )}
                  </Form.Field>
                </Form>
              </Dialog.Body>
              <Dialog.Footer>
                <Button
                  variant="tertiary"
                  size="large"
                  onClick={(): void => reset()}
                  disabled={loading}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  size="large"
                  onClick={handleNext}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Confirm'}
                </Button>
              </Dialog.Footer>
              <Dialog.CloseButton />
            </>
          )}
          {(modalPage === ModalPage.ConfirmCode ||
            modalPage === ModalPage.ConfirmCodeRecovery) && (
            <>
              <Dialog.Header>
                <Dialog.Title>Enter confirmation code</Dialog.Title>
                <Dialog.Description>
                  {modalPage !== ModalPage.ConfirmCodeRecovery && (
                    <>Awesome! Now we just need to confirm everything. </>
                  )}
                  {tfaMethod === 'app' &&
                    'Open your authenticator app and input the generated code.'}
                  {tfaMethod === 'sms' &&
                    'We just sent a text message to your phone. Please enter the code below.'}
                  {tfaMethod === 'email' &&
                    'We just sent an email to your email address. Please enter the code below.'}
                </Dialog.Description>
              </Dialog.Header>
              <Dialog.Body className={style.twoFactorDialogBody}>
                <Form name="codeForm">
                  <Form.Field name="code">
                    <Form.Label>Code</Form.Label>
                    <Form.Control>
                      <Input
                        placeholder="000000"
                        onChange={(event): void =>
                          setConfirmationCode(event.target.value)
                        }
                      />
                    </Form.Control>
                    {confirmErrorMessage && (
                      <Notice
                        variant="error"
                        style={{ marginTop: 'var(--space-md)' }}
                      >
                        {confirmErrorMessage}
                      </Notice>
                    )}
                  </Form.Field>
                </Form>
              </Dialog.Body>
              <Dialog.Footer>
                <Button
                  variant="tertiary"
                  size="large"
                  onClick={(): void => {
                    if (modalPage === ModalPage.ConfirmCodeRecovery) {
                      setIsModalOpen(false)
                    }
                    if (tfaMethod === 'sms') {
                      setModalPage(ModalPage.SetupPhone)
                    } else if (tfaMethod === 'email') {
                      setModalPage(ModalPage.ChooseType)
                    } else {
                      setModalPage(ModalPage.SetupApp)
                    }
                  }}
                  disabled={loading}
                >
                  {modalPage === ModalPage.ConfirmCodeRecovery
                    ? 'Cancel'
                    : 'Back'}
                </Button>
                <Button
                  variant="primary"
                  size="large"
                  onClick={handleNext}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Confirm'}
                </Button>
              </Dialog.Footer>
              <Dialog.CloseButton />
            </>
          )}
          {modalPage === ModalPage.ShowRecovery && (
            <>
              <Dialog.Header>
                <Dialog.Title>Save this one-time recovery code</Dialog.Title>
                <Dialog.Description>
                  {tfaMethod === 'sms' &&
                    "If you lose your phone there's a possibility you could get locked out of your account. Save this code in a safe place to use if you can&apos;t log in with your phone."}
                  {tfaMethod === 'app' &&
                    "If you lose access to your authenticator app there's a possibility you could get locked out of your account. Save this code in a safe place to use if you can&apos;t log in with your authenticator app."}
                  {tfaMethod === 'email' &&
                    "If you lose access to your email there's a possibility you could get locked out of your account. Save this code in a safe place to use if you can&apos;t log in with your email."}
                </Dialog.Description>
              </Dialog.Header>
              <Dialog.Body className={style.twoFactorDialogBody}>
                <Flex gap="xs" direction="row">
                  <Text className={style.tfaRecoveryCody}>{recoveryCode}</Text>
                  <IconButton
                    label="Copy recovery code"
                    variant="secondary"
                    onClick={(): void => {
                      navigator.clipboard.writeText(recoveryCode)
                      toast.success('Recovery code copied to clipboard')
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                </Flex>
              </Dialog.Body>
              <Dialog.Footer>
                <Button variant="primary" size="large" onClick={handleNext}>
                  Done
                </Button>
              </Dialog.Footer>
              <Dialog.CloseButton />
            </>
          )}
        </Dialog.Content>
      </Dialog>
    </>
  )
}

export default TwoFactor
