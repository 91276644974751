import { connect } from 'react-redux'
import { actions as modalsActions } from '~publish/legacy/modals/reducer'
import { actions } from './reducer'
import { ProfilesDisconnectedModal } from './components/ProfilesDisconnectedModal'

export default connect(
  (state) => {
    const disconnectedProfiles =
      // @ts-expect-error TS(2339) FIXME: Property 'profilesDisconnectedModal' does not exis... Remove this comment to see the full error message
      state.profilesDisconnectedModal?.disconnectedProfiles
    // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
    const currentOrganization = state.organizations?.selected
    const currentOrganizationId = currentOrganization?.id

    const profiles = disconnectedProfiles
      // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' ty... Remove this comment to see the full error message
      .filter((profile) =>
        currentOrganizationId
          ? profile.organizationId === currentOrganizationId
          : true,
      )
      // @ts-expect-error TS(7006) FIXME: Parameter 'accProfiles' implicitly has an 'any' ty... Remove this comment to see the full error message
      .map((profile) => ({
        ...profile,
        isAdmin: currentOrganization?.isAdmin ?? false,
      }))
    const instagramPersonalProfiles = profiles.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
      (profile) =>
        profile?.service === 'instagram' && profile?.service_type === 'profile',
    )

    return {
      // @ts-expect-error TS(2339) FIXME: Property 'profilesDisconnectedModal' does not exis... Remove this comment to see the full error message
      ...state.profilesDisconnectedModal,
      displayExtraMessage: instagramPersonalProfiles?.length > 0,
      disconnectedProfiles: profiles,
    }
  },
  (dispatch) => ({
    hideModal: () => dispatch(modalsActions.hideProfilesDisconnectedModal()),
    // @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
    reconnectProfile: ({ id, service }) => {
      dispatch(actions.reconnectProfile({ id, service }))
    },
  }),
)(ProfilesDisconnectedModal)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
