import React from 'react'
import {
  Flex,
  Skeleton,
  Card,
  Text,
  Button,
  Link,
  InfoIcon,
  Tooltip,
} from '@buffer-mono/popcorn'
import { type FragmentOf, graphql, readFragment } from '~publish/graphql'
import {
  PortalOptions,
  useGetPortalSession,
} from '../hooks/useGetPortalSession'
import { getSettingsUrl } from '../util/getSettingsUrl'

export const AddressAndTax_Billing = graphql(/* GraphQL */ `
  fragment AddressAndTax_Billing on Billing {
    address {
      city
      country
      line1
      line2
      state
      postalCode
    }
    taxExempt
    taxIds {
      country
      created
      type
      value
      verification {
        status
        verifiedAddress
        verifiedName
      }
    }
  }
`)

const isValidTaxIdStatus = (status: string | null | undefined): boolean => {
  return Boolean(
    status && ['verified', 'unverified', 'pending'].includes(status),
  )
}

const DEFAULT_TAX_MESSAGING =
  'Your business status helps us determine if we need to charge you VAT (Value Added Tax) or the applicable sales tax in your region.'

type TaxId = {
  country: string | null
  created: number | null
  type: string | null
  value: string | null
  verification: {
    status: string | null
    verifiedAddress: string | null
    verifiedName: string | null
  } | null
}

const TaxIdStatus = ({ taxIds }: { taxIds: TaxId[] }): JSX.Element => {
  return (
    <Flex direction="column" gap="xs">
      {taxIds.map((taxId) => {
        const taxIdStatus = taxId.verification?.status
        const shouldRenderStatus = isValidTaxIdStatus(taxIdStatus)
        return (
          <Text key={taxId.value}>
            Tax ID: {taxId.value}{' '}
            {shouldRenderStatus && taxIdStatus && (
              <Text color={taxIdStatus === 'verified' ? 'success' : 'warning'}>
                {taxIdStatus}
              </Text>
            )}
          </Text>
        )
      })}
    </Flex>
  )
}

const TaxStatusDetails = ({
  taxIds,
  taxExempt,
}: {
  taxIds?: TaxId[] | null
  taxExempt?: string | null
}): JSX.Element => {
  const hasTaxIds = taxIds && taxIds.length > 0

  if (hasTaxIds && taxExempt !== 'exempt') {
    return (
      <Flex direction="column" gap="md">
        <Text size="lg" weight="bold">
          Business Status
          <Tooltip content={DEFAULT_TAX_MESSAGING}>
            <InfoIcon />
          </Tooltip>
        </Text>
        <Text weight="medium">I am using Buffer for Business</Text>
        <TaxIdStatus taxIds={taxIds} />
        <Text>
          No further action is required if you are a business without a VAT
          number (UK and EU) or a sales tax ID (US). If you have any questions,
          please contact us at{' '}
          <Link href="mailto:support@buffer.com">support@buffer.com</Link>.
        </Text>
      </Flex>
    )
  }

  if (taxExempt === 'exempt') {
    return (
      <Flex direction="column" gap="md">
        <Text size="lg" weight="bold">
          Business Status
          <Tooltip content={DEFAULT_TAX_MESSAGING}>
            <InfoIcon />
          </Tooltip>
        </Text>
        <Text weight="medium">I am tax-exempt</Text>
        {hasTaxIds && <TaxIdStatus taxIds={taxIds} />}
        <Text>
          To update your tax information, please contact us at{' '}
          <Link href="mailto:support@buffer.com">support@buffer.com</Link>.
        </Text>
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="md">
      <Text size="lg" weight="bold">
        Business Status
        <Tooltip content={DEFAULT_TAX_MESSAGING}>
          <InfoIcon />
        </Tooltip>
      </Text>
      <Text weight="medium">Not a business</Text>
      <Text>
        If you are a business without a VAT number, no further action is
        required. If you have any questions please contact us at{' '}
        <Link href="mailto:support@buffer.com">support@buffer.com</Link>.
      </Text>
    </Flex>
  )
}

export const BillingInformation = ({
  loading,
  data,
}: {
  loading: boolean
  data?: FragmentOf<typeof AddressAndTax_Billing> | null
}): JSX.Element => {
  const fragmentData = data ? readFragment(AddressAndTax_Billing, data) : null
  const { address, taxExempt, taxIds } = fragmentData ?? {}

  const { getCustomerPortalSession, loading: portalSessionLoading } =
    useGetPortalSession({
      portalOption: PortalOptions.CUSTOMER_UPDATE,
    })

  return (
    <>
      <Flex gap="xs" direction="row" align="center" justify="between" fullWidth>
        <Text size="lg" weight="bold">
          Billing Information
        </Text>
        <Button
          onClick={(): void => {
            getCustomerPortalSession(getSettingsUrl())
          }}
          disabled={portalSessionLoading}
        >
          Update Information
        </Button>
      </Flex>
      <Card>
        <Skeleton show={loading}>
          <Flex direction="column" gap="lg">
            {address ? (
              <Flex direction="column" gap="xs">
                <Text size="lg" weight="bold">
                  Address
                </Text>
                {address.line1 && <Text>{address.line1}</Text>}
                {address.line2 && <Text>{address.line2}</Text>}
                {address.country && (
                  <Text>
                    {address.city} {address.state} {address.postalCode}{' '}
                    {address.country}
                  </Text>
                )}
              </Flex>
            ) : (
              <Flex direction="column" gap="xs">
                <Text>You haven&apos;t added your address yet.</Text>
                <Button
                  onClick={(): void => {
                    getCustomerPortalSession(getSettingsUrl())
                  }}
                  disabled={portalSessionLoading}
                >
                  Add Your Address
                </Button>
              </Flex>
            )}
            <TaxStatusDetails
              taxIds={taxIds?.filter((tax): tax is TaxId => tax !== null)}
              taxExempt={taxExempt}
            />
          </Flex>
        </Skeleton>
      </Card>
    </>
  )
}

export default BillingInformation
