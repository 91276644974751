import type {
  CampaignDetails,
  SelectedTag,
} from '~publish/legacy/campaign/types'
import type { LinkedInEntityDetails } from '~publish/legacy/editor/plugins/linkedin-annotations/nodes/LinkedInAnnotationElement'
import type { PublishingNote } from '~publish/gql/graphql'
import type { Document } from '../composer/composer/entities/Document'
import type { ChannelData } from '../composer/composer/entities/Draft'

export enum PostEvents {
  POST_ADDED = 'added_update',
  POST_DELETED = 'deleted_update',
  POST_REORDERED = 'reordered_updates',
  POST_SENT = 'sent_update',
  POST_UPDATED = 'updated_update',
  POST_NOTE_ADDED = 'added_post_note',
  POST_NOTE_DELETED = 'deleted_post_note',
  POST_NOTE_UPDATED = 'updated_post_note',
}

export type RpcUpdateStatistics = {
  retweets?: number
  repins?: number
  favorites?: number
  mentions?: number
  clicks?: number
  reach?: number
}

export type RpcVideoDetails = {
  duration: number
  duration_millis: number
  file_size: number
  width: number
  height: number
  transcoded_location: string
  location: string
}

export type RpcMediaVideo = {
  id: string
  details: RpcVideoDetails
  thumbnails: string[]
  title: string
}

export type RpcUpdateImage = {
  progress: string
  uploaded: string
  thumbnail: string
  alt_text?: string
  source?: string
  width: string
  height: string
  picture: string
}

export type RpcUpdateVideo = {
  progress: string
  uploaded: string
  thumbnail: string
  uploading_type: 'video'
  alt_text?: string
  source?: string
  video: RpcMediaVideo
}

export type RpcUpdateDocument = {
  progress: string
  uploaded: string
  thumbnail: string
  uploading_type: 'document'
  document: {
    id: string
    title: Document['title']
    details: Pick<Document, 'pageCount' | 'fileSizeBytes'> & {
      location: Document['url']
    }
  }
}

export type RpcUpdateMedia = RpcUpdateImage | RpcUpdateVideo | RpcUpdateDocument

export type RpcPostContent = {
  imageSrc?: string
  imageUrls?: string[]
  linkAttachment?: { title: string }
  links?: string[]
  retweetCommentLinks?: string[]
  text: string
  type: string
}

export type RpcPostDetails = {
  postAction: string
  isRetweet: boolean
  error?: unknown
  errorLink?: string
  isCustomScheduled: boolean
  isInstagramReminder: boolean
  isPendingTiktokPost: boolean
}

export type RpcUpdateUser = {
  email: string
  name?: string
  gravatar?: string
  avatar: string
  id: string
}

export type LinkedInAnnotation = LinkedInEntityDetails & {
  start: number
  length: number
}

export enum UpdateStatus {
  Buffer = 'buffer',
  Pending = 'pending',
  PendingTiktok = 'pending_tiktok',
  Draft = 'draft',
  Sent = 'sent',
  Notified = 'notified',
  Error = 'error',
  Analytics = 'analytics',
  Processing = 'processing',
  ProcessingAnalytics = 'processing_analytics',
  Service = 'service',
  ServiceReply = 'service_reply',
  Library = 'library',
  PendingApproval = 'pending_approval',
}

export type RpcUpdateCommonContent = {
  text: string
  media?: RpcUpdateMedia
  extra_media?: RpcUpdateMedia[]
  links: string[] | []
}

export type RpcUpdate = RpcUpdateCommonContent & {
  day: string
  id: string
  status: UpdateStatus | ''
  channel_data?: ChannelData
  channelData?: ChannelData
  updateType?: string
  createdAt: number
  profileId: string
  isPastDue: boolean
  imageSrc?: string
  imageUrls: string[] | []
  scheduled_at?: number
  scheduledAt?: number
  profileTimezone: string
  linkAttachment: { title?: string }
  needsApproval: boolean
  postDetails: RpcPostDetails
  profile_service: string
  retweetCommentLinks?: []
  isSent: boolean
  isPastReminder: boolean
  isReminder: boolean
  isDraft: boolean
  title?: string
  source_url?: string
  type: string
  subprofile_id?: string
  due_at: number
  dueTime?: string
  pinned: boolean
  isFixed: boolean
  statistics: RpcUpdateStatistics
  user: RpcUpdateUser
  serviceLink?: string
  serviceUpdateId?: string
  campaignDetails?: CampaignDetails
  postContent: RpcPostContent
  hasPushNotifications?: boolean
  profileService?: string
  profileServiceType?: string
  isManager: boolean
  dueAt?: number
  retweet?: boolean
  annotations?: LinkedInAnnotation[]
  notes?: PublishingNote[]
  headerDetails?: {
    channel?: {
      avatarUrl: string
      handle: string
      type: string
    }
    hideCreatorDetails?: boolean
  }
  ideaId?: string
  fromIdea?: boolean
  thread?: RpcUpdateCommonContent[]
  via?: string
  tags: SelectedTag[]
  view?: string
}

export enum PostActionType {
  RESCHEDULE = 'RESCHEDULE',
  ADD_TO_QUEUE = 'ADD_TO_QUEUE',
  REQUEUE = 'REQUEUE',
  SCHEDULE_POST = 'SCHEDULE_POST',
  REQUEST_APPROVAL = 'REQUEST_APPROVAL',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  REVERT_TO_DRAFT = 'REVERT_TO_DRAFT',
  MOVE_POST_TO_DRAFTS = 'MOVE_POST_TO_DRAFTS',
  SHARE_NOW = 'SHARE_NOW',
  SHARE_LINK = 'SHARE_LINK',
  SHARE_AGAIN = 'SHARE_AGAIN',
  VIEW_POST = 'VIEW_POST',
}

export enum PostActionButtonType {
  // Post action prefers to be rendered separately
  SINGLE = 'SINGLE',
  // Post action prefers to be rendered nested along with other post actions
  STACKED = 'STACKED',
}

export enum PostActionLabel {
  RESCHEDULE = 'Reschedule',
  ADD_TO_QUEUE = 'Add to Queue',
  REQUEUE = 'Re-add to Queue',
  SCHEDULE_POST = 'Schedule Post',
  REQUEST_APPROVAL = 'Request Approval',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  REVERT_TO_DRAFT = 'Revert to Draft',
  MOVE_POST_TO_DRAFTS = 'Move to Drafts',
  SHARE_NOW = 'Share Now',
  RETRY_NOW = 'Retry Now',
  SHARE_LINK = 'Share Link',
  SHARE_AGAIN = 'Share Again',
  VIEW_POST = 'View Post',
}

export enum PostActionTooltipMessage {
  RESCHEDULE = '',
  ADD_TO_QUEUE = 'This draft will be added to the next available time slot in the queue.',
  REQUEUE = 'This post will be re-added to the top of the queue.',
  SCHEDULE_POST = 'This draft will be added to the queue for the selected date and time.',
  REQUEST_APPROVAL = '',
  APPROVE = '',
  REJECT = 'This post will be moved back to drafts.',
  REVERT_TO_DRAFT = '',
  MOVE_POST_TO_DRAFTS = 'This post will be turned into a draft.',
  SHARE_NOW = 'This post will be shared to your channel immediately',
  SHARE_LINK = 'Schedule a post containing a link to this post.',
  SHARE_AGAIN = 'Schedule this post to be shared again.',
  VIEW_POST = 'View this post on the network it was shared to.',
}

export enum PostActionNotificationSuccess {
  RESCHEDULE = 'Great! Your draft has been rescheduled.',
  ADD_TO_QUEUE = 'Done! Your post has been added to the queue.',
  REQUEUE = 'Done! Your post has been re-added to the queue.',
  SCHEDULE_POST = 'Done! Your draft has been scheduled as a post.',
  REJECT = "We've sent that post back to drafts.",
  REQUEST_APPROVAL = 'Done! Your draft has been sent for approval.',
  REVERT_TO_DRAFT = 'Done! Your draft is no longer pending approval',
  MOVE_POST_TO_DRAFTS = "Great! We've moved that post to drafts",
  ASSIGN_CAMPAIGN_TO_POST = 'Done! Your post has been added to the campaign. Your report was updated.',
  REMOVE_CAMPAIGN_FROM_POST = 'Done! Your post has been removed from the campaign. Your report was updated.',
  SHARE_NOW = 'Done! Your post has been shared.',
  SHARE_NOW_ASYNC = 'Done, we are sharing your post.',
  ASSIGN_TAGS_TO_POST = "Done! We've updated tags for that post.",
}

export enum PostActionNotificationError {
  RESCHEDULE = 'Whoops, we had some trouble rescheduling your draft.',
  ADD_TO_QUEUE = 'Whoops, we had some trouble adding your post to the queue.',
  REQUEUE = 'Whoops, we had some trouble re-adding your post to the queue.',
  SCHEDULE_POST = 'Whoops, we had some trouble scheduling your post.',
  REJECT = 'Whoops, we had some trouble rejecting that draft.',
  REQUEST_APPROVAL = 'Whoops, we had some trouble sending your draft for approval.',
  REVERT_TO_DRAFT = 'Whoops, we had some trouble reverting approval on your draft.',
  MOVE_POST_TO_DRAFTS = 'Whoops, we had some trouble moving that post to drafts.',
  ASSIGN_CAMPAIGN_TO_POST = 'Error! we had some trouble assigning the campaign to your post',
  REMOVE_CAMPAIGN_FROM_POST = 'Error! we had some trouble removing the campaign from your post',
  SHARE_NOW = 'Error! we had some trouble sharing your post',
  ASSIGN_TAGS_TO_POST = "Done! We've updated tags for that post.",
}
