import { connect } from 'react-redux'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import ComposePage from './components/ComposePage'
import type { RootState } from '../store'

export default connect(
  (state: RootState) => {
    const orgsLoaded = state.organizations.loaded
    const userLoaded = state.user.loaded
    const profilesLoaded = state.profileSidebar.loaded
    const campaignsLoaded = !state.campaignsList.isLoading
    const hasCurrentOrg = !!state.organizations?.selected
    const isLoading =
      !orgsLoaded ||
      !userLoaded ||
      !profilesLoaded ||
      !campaignsLoaded ||
      !hasCurrentOrg

    return {
      queryParams: state.router?.location?.search,
      isLoading,
    }
  },

  (dispatch) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'shouldResetComposerData' implicit... Remove this comment to see the full error message
    initComposer: ({ shouldResetComposerData, post }) => {
      dispatch(
        composerPopoverActions.handleCreatePostClick({
          shouldResetComposerData,
          post,
          cta: SEGMENT_NAMES.EXTENSION_CREATE_A_POST,
        }),
      )
    },
  }),
)(ComposePage)
