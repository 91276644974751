import React, { useEffect, useRef } from 'react'

import type { CalendarBlockData } from './types'
import { Hour } from './Hour'
import { useCalendarContext } from './context'
import { useTimezonedNow } from './hooks/useTimezonedNow'

type WeekProps = {
  hours: CalendarBlockData[][]
}

const Week = ({ hours }: WeekProps): JSX.Element => {
  const { items, loading, timezone } = useCalendarContext()
  const currentHourRef = useRef<HTMLTableCellElement>(null)
  const hasScrolledRef = useRef<boolean>(false)
  const { now } = useTimezonedNow(timezone)

  useEffect(() => {
    // Scroll into view only if items are loaded and we never scrolled into view before
    if (currentHourRef?.current && items && !hasScrolledRef.current) {
      // Schedule the scroll into view to be executed after react has finished rendering
      setTimeout(() => {
        currentHourRef.current?.scrollIntoView()
        hasScrolledRef.current = true
      }, 0)
    }
  }, [items])

  return (
    <>
      {hours.map((hours, columnIndex) => {
        return (
          <tr
            key={`weekly-row-${hours[0].start.toString()}`}
            data-testid={`weekly-row-${hours[0].start.toString()}`}
          >
            {hours.map((hour, index) => {
              const isCurrentHour =
                hour.start.hour === now?.hour &&
                hour.start.day === now?.day &&
                hour.start.month === now?.month &&
                hour.start.year === now?.year
              return (
                <Hour
                  key={hour.start.toString()}
                  index={columnIndex}
                  loading={loading}
                  hour={hour}
                  showLabel={index === 0}
                  ref={isCurrentHour ? currentHourRef : null}
                />
              )
            })}
          </tr>
        )
      })}
    </>
  )
}

const memoWeek = React.memo(Week)
export { memoWeek as Week }
