import React from 'react'
import {
  SettingsIcon,
  Tooltip,
  Sidebar,
  FileTextIcon,
} from '@buffer-mono/popcorn'

import {
  useCurrentOrganization,
  useIsAdmin,
} from '~publish/legacy/accountContext'
import { getAccountURL } from '~publish/legacy/utils/formatters/getURL'

import styles from './ManagementNav.module.css'

export const ManagementNav: React.FC = () => {
  const isAdmin = useIsAdmin()
  const ownerEmail = useCurrentOrganization()?.ownerEmail

  return (
    <Sidebar.List>
      <Sidebar.ListItem>
        <Sidebar.Button
          prefix={<FileTextIcon />}
          data-testid="profile-sidebar-replies-action"
          className={styles.button}
        >
          Manage Saved Replies
        </Sidebar.Button>
      </Sidebar.ListItem>
      <Sidebar.ListItem>
        {isAdmin ? (
          <Sidebar.Button
            as="a"
            href={`${getAccountURL()}/channels`}
            prefix={<SettingsIcon />}
            data-testid="profile-sidebar-channels-action"
            className={styles.button}
          >
            Manage Channels
          </Sidebar.Button>
        ) : (
          <Tooltip
            content={`It looks like you haven't been given permission to add/manage channels. Request access from ${
              ownerEmail ?? 'the owner'
            } or any other administrator.`}
          >
            <Sidebar.Button
              disabled
              prefix={<SettingsIcon />}
              data-testid="profile-sidebar-channels-action"
            >
              Manage Channels
            </Sidebar.Button>
          </Tooltip>
        )}
      </Sidebar.ListItem>
    </Sidebar.List>
  )
}
