import React from 'react'
import clsx from 'clsx'

import styles from './OnTrackRing.module.css'

export const OnTrackRing = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  return <div className={clsx(styles.onTrackRing, className)} {...rest} />
}
