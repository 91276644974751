import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import cloneDeep from 'lodash/cloneDeep'
import keyWrapper from '~publish/helpers/keywrapper'
import type { CustomLinksDetails } from '../profile-sidebar/types'

export const actionTypes = keyWrapper('GRID', {
  SAVE_POST_URL: 0,
  UPDATE_SINGLE_CUSTOM_LINK: 0,
  UPDATE_CUSTOM_LINKS: 0,
  DELETE_CUSTOM_LINK: 0,
  ADD_NEW_CUSTOM_LINK: 0,
  EDIT_CUSTOM_LINK_TEXT: 0,
  TOGGLE_CUSTOM_LINK_EDIT_MODE: 0,
  SWAP_CUSTOM_LINKS: 0,
})

type ProfileState = {
  customLinksDetails: CustomLinksDetails
}

export const profileInitialState: ProfileState = {
  customLinksDetails: {
    buttonColor: null,
    buttonContrastColor: null,
    customLinks: [],
  },
}

export interface GridState {
  byProfileId: {
    [key: string]: ProfileState
  }
}

export const initialState: GridState = {
  byProfileId: {},
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const getProfileId = (action) => {
  if (action.profileId) {
    return action.profileId
  }
  if (action.args) {
    return action.args.profileId
  }
  if (action.profile) {
    return action.profile.id
  }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
const profileReducer = (state = profileInitialState, action) => {
  switch (action.type) {
    case `singleProfile_${dataFetchActionTypes.FETCH_SUCCESS}`:
      if (action.result && action.result.customLinksDetails) {
        const { customLinksDetails } = action.result
        const { customLinks = [] } = customLinksDetails
        customLinks
          // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
          .map((item) => {
            item.order = parseInt(item.order, 10)
            return item
          })
          // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
          .sort((a, b) => Number(a.order) - Number(b.order))
        return {
          ...profileInitialState,
          customLinksDetails: action.result.customLinksDetails,
        }
      }
      return profileInitialState
    case `updateSingleCustomLink_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const { customLinksDetails } = state
      const { customLinks = [] } = customLinksDetails

      const resolvedLinks = (action.result.customLinks || []).map(
        // @ts-expect-error TS(7006) FIXME: Parameter 'resolvedLink' implicitly has an 'any' t... Remove this comment to see the full error message
        (resolvedLink) => {
          const matchingCustomLink = customLinks.find((customLink) => {
            return (
              customLink.text === resolvedLink.text &&
              customLink.url === resolvedLink.url &&
              customLink.order === resolvedLink.order
            )
          })

          if (matchingCustomLink) {
            resolvedLink.editing = matchingCustomLink.editing
          }
          return resolvedLink
        },
      )

      return {
        ...state,
        customLinksDetails: {
          ...state.customLinksDetails,
          customLinks: resolvedLinks,
        },
      }
    }
    case `updateCustomLinks_${dataFetchActionTypes.FETCH_SUCCESS}`: {
      const { customLinksDetails } = state
      const { customLinks = [] } = customLinksDetails

      const resolvedLinks = (action.result.profile.custom_links || []).map(
        // @ts-expect-error TS(7006) FIXME: Parameter 'resolvedLink' implicitly has an 'any' t... Remove this comment to see the full error message
        (resolvedLink) => {
          const matchingCustomLink = customLinks.find((customLink) => {
            return (
              customLink.text === resolvedLink.text &&
              customLink.url === resolvedLink.url &&
              customLink.order === resolvedLink.order
            )
          })

          if (matchingCustomLink) {
            resolvedLink.editing = matchingCustomLink.editing
          }
          return resolvedLink
        },
      )

      return {
        ...state,
        customLinksDetails: {
          ...state.customLinksDetails,
          buttonColor: action.result.profile.custom_links_color,
          buttonContrastColor:
            action.result.profile.custom_links_contrast_color,
          customLinks: resolvedLinks,
        },
      }
    }

    case actionTypes.ADD_NEW_CUSTOM_LINK: {
      const { customLinksDetails } = state
      const { customLinks = [] } = customLinksDetails
      return {
        ...state,
        customLinksDetails: {
          ...customLinksDetails,
          customLinks: [
            ...customLinks,
            {
              editing: false,
              text: action.item.text,
              url: action.item.url,
              order: customLinks.length,
            },
          ],
        },
      }
    }
    case actionTypes.TOGGLE_CUSTOM_LINK_EDIT_MODE: {
      const { customLinksDetails } = state
      const { customLinks = [] } = customLinksDetails

      const editedCustomLinks = cloneDeep(customLinks)
      let customLinksResult = editedCustomLinks.filter((link) => link._id)

      if (action.item._id) {
        editedCustomLinks.map((item) => {
          if (item._id === action.item._id) {
            item.editing = action.editing
          }
          return item
        })

        customLinksResult = editedCustomLinks
      }

      return {
        ...state,
        customLinksDetails: {
          ...customLinksDetails,
          customLinks: customLinksResult,
        },
      }
    }

    case actionTypes.UPDATE_SINGLE_CUSTOM_LINK: {
      const { customLinksDetails } = state
      const { customLinks = [] } = customLinksDetails
      const editedCustomLinks = cloneDeep(customLinks).map((item) => {
        if (action.item !== null) {
          if (item.order === action.item.order) {
            item.editing = false
            item.text = action.item.text
            item.url = action.item.url
          }
        }
        if (!action.item) {
          item.editing = false
        }
        return item
      })

      return {
        ...state,
        customLinksDetails: {
          ...customLinksDetails,
          customLinks: editedCustomLinks,
        },
      }
    }
    case actionTypes.UPDATE_CUSTOM_LINKS: {
      const { customLinksDetails } = state
      const { customLinks = [] } = customLinksDetails
      const editedCustomLinks = cloneDeep(customLinks).map((item) => {
        if (
          action.item != null &&
          action.linkText != null &&
          action.linkUrl != null
        ) {
          if (item.order === action.item.order) {
            item.editing = false
            item.text = action.linkText
            item.url = action.linkUrl
          }
        }
        if (!action.item) {
          item.editing = false
        }
        return item
      })

      return {
        ...state,
        customLinksDetails: {
          ...customLinksDetails,
          customLinks: editedCustomLinks,
        },
      }
    }
    case actionTypes.DELETE_CUSTOM_LINK: {
      const { customLinksDetails } = state
      const { customLinks = [] } = customLinksDetails

      const editedCustomLinks = cloneDeep(customLinks)

      return {
        ...state,
        customLinksDetails: {
          ...customLinksDetails,
          customLinks: editedCustomLinks.filter(
            (link) => link._id !== action.customLinkId,
          ),
        },
      }
    }
    case actionTypes.SWAP_CUSTOM_LINKS: {
      const { customLinksDetails } = state
      const { customLinks = [] } = customLinksDetails

      const editedCustomLinks = cloneDeep(customLinks)
      const { customLinkSource, customLinkTarget } = action

      editedCustomLinks.map((item) => {
        if (item._id === customLinkSource._id) {
          item.order = customLinkTarget.order
        }
        if (item._id === customLinkTarget._id) {
          item.order = customLinkSource.order
        }
        return item
      })

      return {
        ...state,
        customLinksDetails: {
          ...customLinksDetails,
          customLinks: editedCustomLinks.sort((a, b) => a.order - b.order),
        },
      }
    }

    default:
      return state
  }
}

export default (state = initialState, action: any): GridState => {
  let profileId
  switch (action.type) {
    case `singleProfile_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `updatePostLink_${dataFetchActionTypes.FETCH_START}`:
    case `updatePostLink_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `updatePostLink_${dataFetchActionTypes.FETCH_FAIL}`:
    case `updateSingleCustomLink_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `updateSingleCustomLink_${dataFetchActionTypes.FETCH_FAIL}`:
    case `updateCustomLinks_${dataFetchActionTypes.FETCH_SUCCESS}`:
    case `updateCustomLinks_${dataFetchActionTypes.FETCH_FAIL}`:
    case actionTypes.ADD_NEW_CUSTOM_LINK:
    case actionTypes.EDIT_CUSTOM_LINK_TEXT:
    case actionTypes.TOGGLE_CUSTOM_LINK_EDIT_MODE:
    case actionTypes.UPDATE_SINGLE_CUSTOM_LINK:
    case actionTypes.UPDATE_CUSTOM_LINKS:
    case actionTypes.DELETE_CUSTOM_LINK:
    case actionTypes.SWAP_CUSTOM_LINKS:
      profileId = getProfileId(action)

      if (profileId) {
        return {
          byProfileId: {
            ...state.byProfileId,
            [profileId]: profileReducer(state.byProfileId[profileId], action),
          },
        }
      }
      return state

    default:
      return state
  }
}
