import React from 'react'

import { Flex, Heading, Paragraph, Separator } from '@buffer-mono/popcorn'

import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'

import { PostingTimeForm } from './components/PostingTimeForm'
import { ScheduleTable } from './components/ScheduleTable'
import { TimezoneInputForm } from './components/TimezoneInputForm'
import { PostingGoals } from './components/PostingGoals'

import styles from './PostingSchedule.module.css'

const ErrorBoundary = getErrorBoundary(true)

const PostingSchedule = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <Flex direction="column" data-testid="posting-schedule-wrapper">
        <TimezoneInputForm />
        <Separator />
        <PostingGoals />
        <Separator />
        <Flex
          align="normal"
          direction="column"
          gap="lg"
          style={{ width: '100%' }}
        >
          <Flex align="end" justify="between" style={{ width: '100%' }}>
            <Flex direction="column">
              <Heading size="small" as="h3">
                Posting Times
              </Heading>
              <Paragraph className={styles.postingScheduleParagraph}>
                Your posting times tells Buffer when to send out posts in your
                Queue. For example, the next 5 posts you add to your Queue will
                be sent in the next 5 upcoming time slots you choose below.
              </Paragraph>
            </Flex>
          </Flex>
          <ScheduleTable />
          <PostingTimeForm />
        </Flex>
      </Flex>
    </ErrorBoundary>
  )
}

export { PostingSchedule }
