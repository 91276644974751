import React from 'react'
import SimpleModal from '@bufferapp/ui/SimpleModal'
import NonDismissibleModal from '@bufferapp/ui/NonDismissibleModal'
import { useSplit } from '@buffer-mono/features'

import { MODALS } from '../../common/types'

import Confirmation from './modals/Confirmation/Confirmation'
import ChannelConnectionPrompt from './modals/ChannelConnectionPrompt'
import PlanSelector from './modals/PlanSelector'
import { StartTrialModal } from './modals/StartTrial'
import { StartTrialModalReactive } from './modals/StartTrialPromptReactive'
import StickyModal from './modals/StickyModal'
import TrialExpired from './modals/TrialExpired'
import { TrialExpiredContainer } from './modals/TrialExpired/styles'
import QuantityUpdate from './modals/QuantityUpdate'
import ConnectChannelUpgrade from './modals/ConnectChannelUpgrade'
import ChecklistComplete from './modals/ChecklistComplete'
import { Paywall } from './modals/Paywall'
import ChannelConnectionOnboarding from './modals/ChannelConnectionOnboarding/ChannelConnectionOnboarding'
import ChannelRefreshSuccessModal from './modals/ChannelRefreshSuccess'
import { FeaturePaywall } from './modals/FeaturePaywall'
import DownloadAppModal from './modals/DownloadApp/DownloadAppModal'
import { InstagramFacebookReAuth } from './modals/InstagramFacebookReAuth'
import InstagramSelectAccountType from './modals/InstagramSelectAccountType/InstagramSelectAccountType'
import ChurnSurveyModal from './modals/ChurnSurvey'
import ConfirmCancellationModal from './modals/ConfirmCancellation'
import TrialSignupWelcomeModal from './modals/TrialSignupWelcomeModal'
import RemindersWizard from './modals/RemindersWizard/components/RemindersWizard/RemindersWizard'
import { TeamMemberDowngradeModal } from './modals/TeamMemberDowngradeModal'
import QuantityUpdateTieredPricing from './modals/QuantityUpdateTieredPricing'
import PostingPlanConfiguration from './modals/PostingPlanConfiguration'
import { useModalManager } from '../ModalManager/hooks/useModalManager'
import { useUser } from '../../common/context/User'
import {
  hasEditPermission,
  isFreeUser,
  isOnActiveTrial,
} from '../../common/utils/user'
import {
  cleanupUrlParams,
  getShouldShowChannelConnectionModal,
  hasSeenChecklistCompleteModal,
  shouldShowChannelConnectionOnboardingModal,
  shouldShowPaywallModal,
  shouldShowTrialSignupWelcomeModal,
} from './utils'
import { isOnboardingPage } from '../../exports/Navigator/utils/getActiveProductFromUrl'
import { FacebookSelectAccountTypeDialog } from './modals/FacebookSelectAccountType/FacebookSelectAccountType'
import PostingGoalConfiguration from './modals/PostingGoalConfiguration'

const ModalContent = (): JSX.Element | null => {
  const { modalKey, modalData, openModal, dismissModal } = useModalManager()
  const user = useUser()
  const { split, isReady: isPostingGoalsSplitReady } = useSplit(
    'geid-posting-goals-channel-setup',
  )

  const isPostingGoalChannelSetupEnabled = ['variant_a', 'variant_b'].includes(
    split.treatment,
  )

  if (!modalKey || !user.id) {
    return null
  }

  const isOnTrial = isOnActiveTrial(user)
  const userHasEditPermissions = hasEditPermission(user)

  // In some Organization billing states we want the same CTA
  // to either open the Quantity Update or the Plan Selector.
  // Those are when the user is on the Free plan or trialling.
  // In both case we specify this with a flag "shouldPickModalOnOrganizationState"
  // This property is being used by the MODALS.subscriptionUpdate that
  // is a wrapper modal for the 2 subscription update modals.
  const shouldUsePlanSelector = !modalData?.shouldPickModalOnOrganizationState
    ? true
    : isFreeUser(user) || isOnTrial

  const shouldShowChannelConnectionOnboarding =
    shouldShowChannelConnectionOnboardingModal(user, modalData?.channelIds)

  const shouldShowConnectChannelUpgradeModal =
    getShouldShowChannelConnectionModal({
      user,
      modalData,
    })

  const shouldShowPostingGoalConfiguration =
    (!!modalData?.channelId || (modalData?.channelIds?.length ?? 0) > 0) &&
    isPostingGoalChannelSetupEnabled

  const handleOpenPaywall = (): void => {
    dismissModal()
    if (shouldShowPaywallModal(user)) {
      openModal({ key: MODALS.paywall })
    }
  }

  switch (modalKey) {
    case MODALS.downloadApp:
      return <DownloadAppModal closeAction={dismissModal} />

    case MODALS.channelConnectionPrompt:
      return (
        <StickyModal closeAction={dismissModal}>
          <ChannelConnectionPrompt closeAction={dismissModal} />
        </StickyModal>
      )
    case MODALS.planSelector:
      if (userHasEditPermissions) {
        return (
          <SimpleModal closeAction={handleOpenPaywall}>
            <PlanSelector />
          </SimpleModal>
        )
      }
      return null

    case MODALS.instagramFacebookReAuth:
      return <InstagramFacebookReAuth />

    case MODALS.instagramSelectAccountType:
      return <InstagramSelectAccountType />
    case MODALS.facebookSelectAccountType:
      return <FacebookSelectAccountTypeDialog />

    case MODALS.success:
      if (!isOnboardingPage()) {
        return <Confirmation />
      }
      return null

    case MODALS.startTrial:
    case MODALS.startTrialNoClick:
      if (userHasEditPermissions) {
        return <StartTrialModal />
      }
      return null

    case MODALS.startTrialReactive:
      return <StartTrialModalReactive />

    case MODALS.trialExpired:
      return (
        <TrialExpiredContainer>
          <NonDismissibleModal>
            <TrialExpired />
          </NonDismissibleModal>
        </TrialExpiredContainer>
      )

    case MODALS.quantityUpdate:
      return (
        <SimpleModal closeAction={dismissModal}>
          <QuantityUpdate />
        </SimpleModal>
      )

    case MODALS.quantityUpdateTieredPricing:
      return (
        <SimpleModal closeAction={dismissModal}>
          <QuantityUpdateTieredPricing />
        </SimpleModal>
      )

    case MODALS.paywall:
      return (
        <NonDismissibleModal>
          <Paywall />
        </NonDismissibleModal>
      )

    case MODALS.featurePaywall:
      return (
        <SimpleModal closeAction={dismissModal}>
          <FeaturePaywall />
        </SimpleModal>
      )

    case MODALS.subscriptionUpdate:
      return (
        <SimpleModal closeAction={handleOpenPaywall}>
          {shouldUsePlanSelector ? <PlanSelector /> : <QuantityUpdate />}
        </SimpleModal>
      )

    case MODALS.channelConnectionSuccessModal:
      if (!isPostingGoalsSplitReady) {
        return null
      }
      if (
        shouldShowChannelConnectionOnboarding &&
        !shouldShowConnectChannelUpgradeModal
      ) {
        if (shouldShowPostingGoalConfiguration) {
          cleanupUrlParams()
          return (
            <PostingGoalConfiguration
              channelIds={
                modalData?.channelIds ?? [modalData?.channelId as string]
              }
              dismissModal={dismissModal}
              isSuccessConnection={true}
            />
          )
        }
        return <ChannelConnectionOnboarding />
      }
      if (shouldShowConnectChannelUpgradeModal) {
        return (
          <ConnectChannelUpgrade onOpenChange={(): void => dismissModal()} />
        )
      }
      return null

    case MODALS.channelRefreshSuccessModal:
      if (
        shouldShowChannelConnectionOnboarding &&
        !shouldShowConnectChannelUpgradeModal
      ) {
        return <ChannelRefreshSuccessModal />
      }
      return null

    // NOTE: We don't actually call this modal directly via apps any more.
    // We use the above MODALS.channelConnectionSuccessModal event to decide if this modal is shown
    // Keeping this logic here for now to make it easier to test.
    // REFACTOR: after consuming appshell directly in products and testing can be done in app we should then delete this.
    case MODALS.connectChannelUpgrade:
      if (shouldShowConnectChannelUpgradeModal) {
        return (
          <ConnectChannelUpgrade onOpenChange={(): void => dismissModal()} />
        )
      }
      return null

    case MODALS.checklistComplete:
      if (!hasSeenChecklistCompleteModal(user)) {
        return (
          <SimpleModal closeAction={dismissModal}>
            <ChecklistComplete closeAction={dismissModal} />
          </SimpleModal>
        )
      }
      return null

    case MODALS.churnSurvey:
      return (
        <SimpleModal closeAction={dismissModal}>
          <ChurnSurveyModal />
        </SimpleModal>
      )

    case MODALS.confirmCancellation:
      return (
        <SimpleModal closeAction={dismissModal}>
          <ConfirmCancellationModal />
        </SimpleModal>
      )

    case MODALS.trialSignupWelcomeModal:
      if (shouldShowTrialSignupWelcomeModal(user)) {
        return <TrialSignupWelcomeModal dismissModal={dismissModal} />
      }
      return null

    case MODALS.setupNotifications:
      return (
        <RemindersWizard
          source={modalData?.source}
          onContinue={modalData?.onContinue}
          onDismiss={modalData?.onDismiss}
          closeAction={dismissModal}
        />
      )

    case MODALS.teamMemberDowngrade:
      return <TeamMemberDowngradeModal closeAction={dismissModal} />

    case MODALS.postingPlanConfiguration:
      if (modalData?.channelId) {
        return <PostingPlanConfiguration channelId={modalData?.channelId} />
      }
      return null

    case MODALS.postingGoalConfiguration:
      if (modalData?.channelId) {
        return (
          <PostingGoalConfiguration
            channelIds={[modalData?.channelId]}
            dismissModal={dismissModal}
            remindLater={modalData?.source !== 'channel-page'}
          />
        )
      }
      return null

    default:
      return null
  }
}

const ModalContentMemo = React.memo(ModalContent)
export { ModalContentMemo as ModalContent }
