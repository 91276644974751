import React, { forwardRef, useState } from 'react'
import clsx from 'clsx'
import { useDroppable } from '@dnd-kit/core'
import { Skeleton } from '@buffer-mono/popcorn'

import { format } from '~publish/helpers/temporal'

import { AddButton } from './AddButton'
import ShowMoreLessButtons from './ShowMoreLessButtons'
import { useCalendarContext } from './context'
import { DraggableItem } from './DraggableItem'
import { Item } from './Item'
import type { CalendarBlockData } from './types'
import { DroppableItem } from './DroppableItem'
import { DraggableAndDroppableItem } from './DraggableAndDroppableItem'

import styles from './Day.module.css'
import { useTimezonedNow } from './hooks/useTimezonedNow'

const DEFAULT_ITEMS_LIMIT = 3
const FIVE_MINUTES_MS = 300000

interface DayProps {
  day: CalendarBlockData
}

const Day = forwardRef<HTMLTableCellElement, DayProps>(({ day }, ref) => {
  const { items, selectedDate, timezone, loading } = useCalendarContext()
  const { now, today, todayTimestamp, nowTimestamp } = useTimezonedNow(timezone)

  const [limit, setLimit] = useState(DEFAULT_ITEMS_LIMIT)

  const dropDisabled = day.startTimestamp < todayTimestamp

  const isToday =
    day.start.day === today.day &&
    day.start.month === today.month &&
    day.start.year === today.year
  const isCurrentMonth = day.start.month === selectedDate.month
  const dayId = day.startTimestamp.toString()

  const startOfDay = day.startTimestamp
  const endOfDay = day.endTimestamp
  const itemsToRender = items
    ?.filter((item) => {
      return item.timestamp >= startOfDay && item.timestamp < endOfDay
    })
    .sort((a, b) => a.timestamp - b.timestamp)

  const { setNodeRef, isOver, active } = useDroppable({
    id: dayId ?? '',
    disabled: dropDisabled,
    data: {
      type: 'hour',
      timestamp: day.startTimestamp,
    },
  })

  const showAddButton = !dropDisabled && !active && !loading

  return (
    <td
      data-testid={`monthly-day-${day}`}
      data-datetime={day.toString()}
      data-container-id={dayId}
      className={clsx(styles.day, {
        [styles.disabled]: dropDisabled,
        [styles.active]: isToday,
        [styles.draggingOver]: isOver,
        [styles.currentMonth]: isCurrentMonth,
        [styles.dragHappening]: Boolean(active),
      })}
      ref={ref}
    >
      <span className={styles.dayNumber}>{day.start.day}</span>
      {day.startTimestamp && showAddButton && (
        <AddButton
          data-testid={`post-button-${day.start.toString()}`}
          labelDate={format(day.start, 'eeee, d MMMM y') ?? ''}
          timestamp={
            day.creationTimestamp < nowTimestamp
              ? nowTimestamp + FIVE_MINUTES_MS
              : day.creationTimestamp
          }
          className={styles.addButton}
        />
      )}
      <div className={styles.dayContent} ref={setNodeRef}>
        {day.startTimestamp && now && (
          <>
            <div className={styles.itemsWrapper}>
              {itemsToRender?.slice(0, limit).map((item, index) => {
                if (item.draggable && item.droppable) {
                  return (
                    <DraggableAndDroppableItem
                      key={item.id}
                      id={item.id}
                      index={index}
                      timestamp={item.timestamp}
                      type={item.type}
                      disabled={
                        item.draggable === undefined
                          ? item.timestamp < now.epochSeconds
                          : !item.draggable
                      }
                    >
                      <Item {...item} />
                    </DraggableAndDroppableItem>
                  )
                }
                if (item.draggable) {
                  return (
                    <DraggableItem
                      key={item.id}
                      id={item.id}
                      index={index}
                      timestamp={item.timestamp}
                      type={item.type}
                      className={clsx(styles.item, {
                        [styles.activeItem]: active?.id === item.id,
                      })}
                      disabled={
                        item.draggable === undefined
                          ? item.timestamp < now.epochSeconds
                          : !item.draggable
                      }
                    >
                      <Item {...item} />
                    </DraggableItem>
                  )
                }
                if (item.droppable) {
                  return (
                    <DroppableItem
                      id={item.id}
                      key={item.id}
                      index={index}
                      timestamp={item.timestamp}
                      type={item.type}
                    >
                      <Item {...item} />
                    </DroppableItem>
                  )
                }
                return <Item {...item} key={item.id} />
              })}
              {loading && (itemsToRender ?? [])?.length < limit && (
                <Skeleton height={'28px'} />
              )}
            </div>
            <ShowMoreLessButtons
              itemsToRender={itemsToRender}
              limit={limit}
              className={styles.showMoreButton}
              setLimit={setLimit}
            />
          </>
        )}
      </div>
    </td>
  )
})

Day.displayName = 'Day'

const memoDay = React.memo(Day)
export { memoDay as Day }
