import twitter, { type EntityWithIndices } from 'twitter-text'
import { isValidHandle } from '@atproto/syntax'

import styles from './helpers.module.css'
import { removeUrlProtocol } from '~publish/legacy/composer/composer/utils/StringUtils'
import { concatMentionsToEntities } from '../common'

type BlueskyLink = {
  displayString: string
  indices: [number, number]
  rawString: string
  url: string
  className?: string
}

const getBlueskyMentions = (text: string): BlueskyLink[] | null => {
  const regex = /(^|\s)(@[a-zA-Z0-9.-]+)(\s|$)/gm
  const matches = text.matchAll(regex)

  if (!matches) {
    return null
  }

  const mentions: BlueskyLink[] = []

  for (const match of matches) {
    const mention = match[2]
    const handle = mention?.slice(1)

    if (mention && handle && isValidHandle(handle)) {
      const start = match.index + match[1].length

      mentions.push({
        displayString: mention,
        rawString: mention,
        url: `https://bsky.app/profile/${handle}`,
        className: styles.link,
        indices: [start, start + mention.length],
      })
    }
  }

  return mentions
}

const getEntities = (
  text: string,
  entities: EntityWithIndices[],
): BlueskyLink[] => {
  const mentions = getBlueskyMentions(text)

  return concatMentionsToEntities(mentions, entities)
}

function transformBlueskyLink(entityUrl: string): string {
  const PATH_PART_LIMIT = 16

  try {
    const url = new URL(entityUrl)
    const path = url.pathname.replace(/^\/$/, '') + url.search + url.hash

    if (path.length > PATH_PART_LIMIT) {
      return url.host + path.slice(0, PATH_PART_LIMIT - 3) + '...'
    }

    return url.host + path
  } catch (e) {
    return removeUrlProtocol(entityUrl)
  }
}

const parseBlueskyLinks = (text: string): BlueskyLink[] => {
  const entities = twitter
    .extractEntitiesWithIndices(text)
    .map((entity: EntityWithIndices): BlueskyLink | null => {
      if ('url' in entity && entity.url) {
        return {
          displayString: transformBlueskyLink(entity.url),
          indices: entity.indices,
          rawString: entity.url,
          url: entity.url,
          className: styles.link,
        }
      }

      if ('hashtag' in entity && entity.hashtag) {
        return {
          displayString: `#${entity.hashtag}`,
          indices: entity.indices,
          rawString: `#${entity.hashtag}`,
          url: `https://bsky.app/hashtag/${entity.hashtag}`,
          className: styles.link,
        }
      }

      return null
    })
    .filter(Boolean) as BlueskyLink[]

  return text ? getEntities(text, entities) : []
}

export { parseBlueskyLinks }
