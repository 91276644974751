import {
  useQuery,
  type ApolloError,
  type WatchQueryFetchPolicy,
} from '@apollo/client'
import React from 'react'
import { graphql, type FragmentOf, type ResultOf } from '~publish/graphql'
import { SidebarCollectionItem_Collection } from '../components/SidebarFeedNav/SidebarCollectionItem'
import { SidebarFeedItem_Feed } from '../components/SidebarFeedNav/SidebarFeedItem'

export const GetFeedsForSidebar = graphql(
  /* GraphQL */ `
    query GetFeedsForSidebar($organizationId: OrganizationId!) {
      feeds(input: { organizationId: $organizationId }) {
        collection {
          id
        }
        ...SidebarFeedItem_Feed
      }
      feedCollections(first: 100, input: { organizationId: $organizationId }) {
        edges {
          node {
            id
            ...SidebarCollectionItem_Collection
          }
        }
      }
    }
  `,
  [SidebarCollectionItem_Collection, SidebarFeedItem_Feed],
)

type UseFeedCollectionItemResult = {
  loading: boolean
  error?: ApolloError
  feeds: FragmentOf<typeof SidebarFeedItem_Feed>[]
  collections: ResultOf<
    typeof GetFeedsForSidebar
  >['feedCollections']['edges'][number]['node'][]
  totalFeeds: number
}

export const useFeedsForSidebar = (options: {
  organizationId: string | undefined
  fetchPolicy?: WatchQueryFetchPolicy
}): UseFeedCollectionItemResult => {
  const { organizationId, fetchPolicy = 'cache-and-network' } = options
  const { data, loading, error } = useQuery(GetFeedsForSidebar, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - organizationId is not always defined we skip if it is not defined
      organizationId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
    skip: !organizationId,
  })

  const rootFeeds = React.useMemo(
    () => data?.feeds?.filter((feed) => !feed?.collection) ?? [],
    [data?.feeds],
  )
  const collections = React.useMemo(
    () => data?.feedCollections?.edges?.map((edge) => edge.node) ?? [],
    [data?.feedCollections?.edges],
  )

  return {
    // on first load, organizationId can be delayed
    // counting that as loading to ensure we don't temporarily flash empty arrays as loaded
    loading: loading || !organizationId,
    error,
    feeds: rootFeeds,
    collections,
    totalFeeds: data?.feeds?.length ?? 0,
  }
}
