import twitter, { type EntityWithIndices } from 'twitter-text'

const makeUnicodeAwareIndexUnaware = (str: any, i: any) =>
  Array.from(str).slice(0, i).join('').length

export const parseFacebookEntities = (postText: any, entities: any) => {
  if (!entities) return []
  return entities.map(({ url, text, indices }: any) => ({
    displayString: text,
    indices: [
      makeUnicodeAwareIndexUnaware(postText, indices[0]),
      makeUnicodeAwareIndexUnaware(postText, indices[1]),
    ],
    rawString: text,
    url,
  }))
}

export const parseThreadsLinks = (text: any) =>
  twitter
    .extractEntitiesWithIndices(text)
    .map((entity: EntityWithIndices) => {
      if ('url' in entity && entity.url) {
        return {
          displayString: entity.url,
          indices: entity.indices,
          rawString: entity.url,
          url: entity.url,
        }
      }
      if ('hashtag' in entity && entity.hashtag) {
        return {
          displayString: `${entity.hashtag}`,
          indices: entity.indices,
          rawString: `#${entity.hashtag}`,
          url: `https://threads.net/search?q=${entity.hashtag}&serp_type=tags`,
        }
      }
      if ('screenName' in entity && entity.screenName) {
        return {
          displayString: `@${entity.screenName}`,
          indices: entity.indices,
          rawString: `@${entity.screenName}`,
          url: `https://threads.net/${entity.screenName}`,
        }
      }
      return null
    })
    .filter((entity) => entity !== null)

export const parseTwitterLinks = (text: any) =>
  twitter
    .extractEntitiesWithIndices(text)
    .map((entity) => {
      if ((entity as any).url) {
        return {
          displayString: (entity as any).url,
          indices: entity.indices,
          rawString: (entity as any).url,
          url: (entity as any).url,
        }
      }
      if ((entity as any).hashtag) {
        return {
          displayString: `#${(entity as any).hashtag}`,
          indices: entity.indices,
          rawString: `#${(entity as any).hashtag}`,
          url: `https://x.com/search?q=%23${(entity as any).hashtag}`,
        }
      }
      if ((entity as any).screenName) {
        return {
          displayString: `@${(entity as any).screenName}`,
          indices: entity.indices,
          rawString: `@${(entity as any).screenName}`,
          url: `https://x.com/${(entity as any).screenName}`,
        }
      }
      if ((entity as any).cashtag) {
        return {
          displayString: `$${(entity as any).cashtag}`,
          indices: entity.indices,
          rawString: `$${(entity as any).cashtag}`,
          url: `https://x.com/search?q=%24${(entity as any).cashtag}`,
        }
      }
      return null
    })
    .filter((entity) => entity !== null)

export default {
  parseFacebookEntities,
  parseTwitterLinks,
}
