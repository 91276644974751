import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { graphql } from '~publish/gql'
import { GET_ACCOUNT } from '~publish/legacy/accountContext'
import { logError } from '~publish/legacy/utils/logError'
import { toast } from '@buffer-mono/popcorn'

/**
 * TODO: This will get refactored to use updated GraphQL mutation
 */
export const UpdateOrganizationNameMutation = graphql(/* GraphQL */ `
  mutation OrganizationSetName($organizationId: String!, $name: String!) {
    organizationSetName(organizationId: $organizationId, name: $name)
  }
`)

type UpdateOrganizationName = ({
  organizationId,
  name,
}: {
  organizationId: string
  name: string
}) => Promise<boolean>

type UpdateOrganizationNameResponse = {
  updateOrganizationName: UpdateOrganizationName
  isLoading: boolean
  hasError: boolean
}

/**
 * Custom hook to update the current organization name.
 * @returns {UpdateOrganizationNameResponse} The function to trigger updating the organization name.
 */
export const useUpdateOrganizationName = (): UpdateOrganizationNameResponse => {
  const [executeUpdateOrganizationName, { loading, error }] = useMutation(
    UpdateOrganizationNameMutation,
  )

  const updateOrganizationName = useCallback(
    async ({
      organizationId,
      name,
    }: {
      organizationId: string
      name: string
    }) => {
      try {
        const { data, errors } = await executeUpdateOrganizationName({
          variables: {
            organizationId,
            name,
          },
          refetchQueries: [GET_ACCOUNT],
        })

        // Handle GraphQL errors if any
        if (errors?.length) {
          const unexpectedError = new Error(
            errors?.[0]?.message || 'Unexpected API response',
          )
          logError(unexpectedError, { metaData: { data, errors } })
          throw unexpectedError
        }
        toast.success('Organization name updated')

        return Boolean(data?.organizationSetName)
      } catch (error) {
        toast.error(
          'An error occurred while updating your organization name. Please try again.',
        )
        logError(error as Error, {
          metaData: { variables: { organizationId, name } },
        })
        throw error
      }
    },
    [executeUpdateOrganizationName],
  )

  return {
    updateOrganizationName,
    isLoading: loading,
    hasError: !!error,
  }
}
