import { useLazyQuery } from '@apollo/client'
import { PusherEvent, usePusherEvent } from '~publish/services/pusher'
import { graphql } from '~publish/gql'
import type {
  GetCalendarPostForRealTimeUpdateQuery,
  PostsFiltersInput,
  RealTimeUpdates_PostFragment,
} from '~publish/gql/graphql'
import { client } from '~publish/legacy/apollo-client'
import { isPostSatisfyingFilters } from '~publish/helpers/posts/isPostSatisfyingFilters'
import { useChannels } from '~publish/components/PublishSidebar/useChannels'
import { debugLog } from '~publish/helpers/debugLog'

export const GetCalendarPostForRealTimeUpdate = graphql(/* GraphQL */ `
  query GetCalendarPostForRealTimeUpdate($postId: PostId!) {
    post(input: { id: $postId }) {
      id
      dueAt
      ...CalendarPostCard_Post
      ...RealTimeUpdates_Post
    }
  }
`)

type PusherEventData = {
  update_id: string
  draft_id: string
  profile_id?: string
}

const postEvents = [
  PusherEvent.POST_UPDATED,
  PusherEvent.POST_TAG_ADDED,
  PusherEvent.POST_TAG_REMOVED,
  PusherEvent.POST_NOTE_ADDED,
  PusherEvent.POST_NOTE_DELETED,
  PusherEvent.POST_NOTE_UPDATED,
  PusherEvent.DRAFT_UPDATED,
  PusherEvent.POST_SENT,
  PusherEvent.DRAFT_MOVED,
  PusherEvent.DRAFT_APPROVED,
  PusherEvent.POST_CREATED,
]

type UseCalendarRealTimeUpdatesOptions = {
  filters: PostsFiltersInput
  refetch: () => Promise<void>
  isPostVisible: (id: string) => boolean
  dateRange: {
    startDate: string
    endDate: string
  }
}

/**
 * Hook that handles real-time updates to posts in the calendar view via Pusher events
 * Manages post visibility, ordering, and cache updates based on various events
 */
export const useCalendarRealTimeUpdates = ({
  filters,
  isPostVisible,
  refetch,
  dateRange,
}: UseCalendarRealTimeUpdatesOptions): void => {
  const { channels } = useChannels()
  const [getPost] = useLazyQuery(GetCalendarPostForRealTimeUpdate, {
    fetchPolicy: 'network-only',
  })

  /**
   * Updates a post's data and manages its visibility in the calendar
   */
  const updatePost = async (id: string): Promise<void> => {
    const wasPostVisibleBeforeUpdate = isPostVisible(id)

    const { data } = await getPost({
      variables: {
        postId: id,
      },
    })

    const updatedPost = data?.post
    if (!updatedPost) {
      return
    }

    const { satisfies: shouldUpdatedPostBeVisible } = isPostSatisfyingFilters(
      updatedPost as RealTimeUpdates_PostFragment,
      filters,
    )
    const isWithinDateRange =
      updatedPost.dueAt != null &&
      updatedPost.dueAt >= dateRange.startDate &&
      updatedPost.dueAt <= dateRange.endDate

    // Handle post that moves outside the calendar date range
    if (!isWithinDateRange) {
      if (wasPostVisibleBeforeUpdate) {
        await removePostFromCache(id)
      }
      return
    }

    // Handle post that should be removed due to filters
    if (wasPostVisibleBeforeUpdate && !shouldUpdatedPostBeVisible) {
      await removePostFromCache(id)
      return
    }

    // Handle post that should now be visible
    if (
      !wasPostVisibleBeforeUpdate &&
      shouldUpdatedPostBeVisible &&
      isWithinDateRange
    ) {
      await refetch()
      return
    }

    // Update post data for all other cases
    updateCachedPost(id, data)
  }

  /**
   * Checks if a channel exists on the fetched channels list
   * @param profileId - The ID of the profile
   * @returns True if the channel exists, false otherwise
   */
  // TODO: this is a temporary solution, because pusher events are sent to all
  // users of the organization regardless of which channels they have access to
  // we should update pusher events to be more specific to the user
  const isChannelAllowed = (profileId?: string): boolean => {
    return (
      profileId !== undefined &&
      channels.find((c) => c.id === profileId) !== undefined
    )
  }

  usePusherEvent(
    PusherEvent.POSTS_REORDERED,
    async (data?: {
      profile_id?: string
      update_ids?: Array<string>
    }): Promise<void> => {
      debugLog('💽 POSTS_REORDERED', data)
      const hasChanges = Number(data?.update_ids?.length) > 0
      const isProfileIncluded =
        // no filters means all channles are included
        filters?.channelIds?.length === 0 ||
        filters?.channelIds?.includes(data?.profile_id ?? '')
      if (!hasChanges || !isProfileIncluded) {
        return
      }

      if (!isChannelAllowed(data?.profile_id)) {
        return
      }

      if (data?.update_ids?.length === 1) {
        updatePost(data.update_ids[0])
      } else {
        refetch()
      }
    },
  )

  usePusherEvent(
    PusherEvent.POST_DELETED,
    async (data: PusherEventData): Promise<void> => {
      debugLog('💽 POST_DELETED', data)
      await removePostFromCache(data.update_id)
    },
  )

  usePusherEvent(postEvents, (data: PusherEventData): void => {
    debugLog('💽 CALENDAR_POST_EVENTS', data)
    if (!isChannelAllowed(data?.profile_id)) {
      return
    }

    updatePost(data.update_id ?? data.draft_id)
  })
}

/**
 * Removes a post from the Apollo cache
 */
async function removePostFromCache(id: string): Promise<void> {
  const normalizedId = client.cache.identify({
    id,
    __typename: 'Post',
  })

  const existingPost = client.cache.readFragment({
    id: normalizedId,
    fragment: graphql(/* GraphQL */ `
      fragment Post_evicting on Post {
        id
      }
    `),
  })

  if (!existingPost) {
    return
  }

  client.cache.evict({ id: normalizedId })
}

/**
 * Updates a post's data in the Apollo cache
 */
function updateCachedPost(
  id: string,
  data: GetCalendarPostForRealTimeUpdateQuery | undefined,
): void {
  client.cache.writeQuery({
    query: GetCalendarPostForRealTimeUpdate,
    variables: {
      postId: id,
    },
    data,
  })
}
