import { UploadSource } from './UploadSource'

export type UploadMetadata = {
  organizationId: string
  uploaderId: string
  trackingId: string
  source?: UploadSource
  parentUploadId?: string
  key?: string
}

export const UploadMetadata = {
  new(meta: UploadMetadata): UploadMetadata {
    return {
      uploaderId: meta.uploaderId,
      organizationId: meta.organizationId,
      trackingId: meta.trackingId,
      source: UploadSource.new(meta.source),
      parentUploadId: meta.parentUploadId,
    }
  },
}
