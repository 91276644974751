import React from 'react'
import Text from '@bufferapp/ui/Text'
import Notification from '@bufferapp/ui/Notification'

import Banner from '../../../../components/Banner'
import useEmailVerification from '../../../../common/hooks/useEmailVerification'
import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'

import { StyledButton } from './styles'

const EmailVerificationLinkRequestBanner = () => {
  const { bannerOptions } = useEmailVerification()
  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.emailVerification,
  })

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (bannerOptions.text) {
    return (
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      <Notification text={bannerOptions.text} />
    )
  }

  return (
    <Banner
      themeColor="orange"
      onCloseBanner={onDismissBanner}
      customHTML={
        <>
          <Text type="paragraph" color="#fff">
            We&apos;ve updated our security measures. To verify your email,
            please
          </Text>
          <StyledButton
            type="text"
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            onClick={bannerOptions.actionButton.action}
            label="click here"
            size="small"
          />
        </>
      }
    />
  )
}

export default EmailVerificationLinkRequestBanner
