import { Heading, IdeasIcon, Sidebar } from '@buffer-mono/popcorn'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { SidebarFeedNav } from '../../Feed/components/SidebarFeedNav/SidebarFeedNav'
import styles from './ContentSidebar.module.css'

export function ContentSidebar(): JSX.Element {
  return (
    <Sidebar collapsible="icon" className={styles.sidebar}>
      <Sidebar.Header data-nohover className={styles.sidebarHeader}>
        <Heading as="h3" size="small" className={styles.contentHeadingTitle}>
          Create
        </Heading>
        <Sidebar.Trigger className={styles.toggleButton} />
      </Sidebar.Header>
      <Sidebar.Content>
        <Sidebar.List>
          <Sidebar.ListItem>
            <Sidebar.Button
              prefix={<IdeasIcon />}
              as={NavLink}
              exact
              to="/create"
              size="medium"
              className={styles.ideasNavButton}
            >
              Ideas
            </Sidebar.Button>
          </Sidebar.ListItem>
        </Sidebar.List>
        <Sidebar.Separator className={styles.separator} />

        <SidebarFeedNav />
      </Sidebar.Content>
    </Sidebar>
  )
}
