import React from 'react'

import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

import { orangeDark } from '@bufferapp/ui/style/colors'

import {
  BannerStyled,
  BannerCloseButton,
  Wrapper,
  ButtonWrapper,
} from './style'

interface BannerProps {
  /** The main text of the banner */
  text?: string
  /** The text of the Call To Action of the banner */
  actionButton?: {
    label?: string
    action?: (...args: unknown[]) => unknown
  }
  /** Custom HTML */
  customHTML?: JSX.Element
  /** Theme color. Can be `'blue'` or `'orange'` */
  themeColor?: 'blue' | 'orange'
  /** Handler when the banner closes */
  onCloseBanner?: (...args: unknown[]) => unknown
  /** Allow functionality to dismiss banner **/
  dismissible?: boolean
  testId?: string
}

export default class Banner extends React.Component<
  BannerProps,
  { isOpen: boolean }
> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  constructor(props: any) {
    super(props)
    this.state = {
      isOpen: true,
    }
    this.closeBanner = this.closeBanner.bind(this)
  }

  closeBanner(): void {
    this.setState({ isOpen: false })
    const { onCloseBanner } = this.props
    if (onCloseBanner) {
      onCloseBanner()
    }
  }

  renderBannerContent(themeColor: string): JSX.Element {
    const { customHTML, text = '', actionButton = {}, testId = '' } = this.props
    if (customHTML) {
      return <Wrapper data-testid={testId}>{customHTML}</Wrapper>
    }
    return (
      <Wrapper data-testid={testId}>
        <Text type="paragraph" color="#FFF">
          {text}
        </Text>
        <ButtonWrapper>
          {actionButton.label && actionButton.action && (
            <Button
              type={themeColor === 'orange' ? 'orange' : 'primary'}
              onClick={actionButton.action}
              label={actionButton.label}
              size="small"
            />
          )}
        </ButtonWrapper>
      </Wrapper>
    )
  }

  render(): JSX.Element | null {
    const { isOpen } = this.state
    const { themeColor = 'blue', dismissible = true } = this.props

    if (isOpen) {
      return (
        <BannerStyled themeColor={themeColor}>
          {this.renderBannerContent(themeColor)}
          {dismissible && (
            <BannerCloseButton>
              <Button
                type="text"
                icon={
                  <CrossIcon
                    color={themeColor === 'blue' ? '#fff' : orangeDark}
                  />
                }
                hasIconOnly
                onClick={this.closeBanner}
                label="Close"
                size="small"
              />
            </BannerCloseButton>
          )}
        </BannerStyled>
      )
    }

    return null
  }
}
