import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { Tooltip } from '@buffer-mono/popcorn'
import React, { useState } from 'react'
import { SearchImagesModal } from './components/search-images-modal/SearchImagesModal'
import {
  BarButton,
  ButtonContainer,
  ChildrenArea,
  IntegrationsBarContainer,
  NewTag,
  SplitBarButton,
  StyledAddMediaIcon,
  StyledChevronIcon,
  StyledDropdown,
  StyledDropDownTrigger,
  StyledListItem,
} from './styles'
import type { IntegrationsContext, IntegrationsService } from './types'

/**
 *  COMPONENT: IDEAS INTEGRATIONS BAR
 */
export const IntegrationsBar = ({
  context,
  services,
  activeService,
  disableBar = false,
  container,
  children,
}: {
  context: IntegrationsContext
  services: Array<IntegrationsService>
  activeService: IntegrationsService | null
  disableBar?: boolean
  container?: HTMLElement | null
  children?: React.ReactNode
}): JSX.Element | null => {
  const [lastIntegrationUsed, setLastIntegrationUsed] = useState(
    localStorage.getItem('last_used_integration'),
  )

  const [sawNewIndicator, setSawNewIndicator] = useState(
    !!localStorage.getItem('integration_bar_seen_new_indicator'),
  )

  if (services.length === 0 && !children) return null

  const storedIntegration = services.find(
    (e) => e.title === lastIntegrationUsed,
  )

  const hasNewTags = !!services.find((e) => e.isNew) && !sawNewIndicator

  const tooltipLabel = disableBar
    ? 'No more media can be attached'
    : 'Select media from integrations'

  // @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
  const onServiceClick = (service): void => {
    localStorage.setItem('last_used_integration', service.title)
    setLastIntegrationUsed(service.title)
    service.onClick?.()
  }

  const removeNewIndicator = (): void => {
    localStorage.setItem('integration_bar_seen_new_indicator', 'true')
    setSawNewIndicator(true)
  }

  return (
    <>
      <IntegrationsBarContainer data-testid="integrations-bar">
        <ButtonContainer>
          {storedIntegration && (
            <Tooltip
              content={
                disableBar
                  ? 'No more media can be attached'
                  : storedIntegration.label
              }
            >
              <SplitBarButton
                onClick={(): void => storedIntegration?.onClick?.()}
                disabled={disableBar}
                aria-label={storedIntegration.label}
                type="button"
                darkerHover
              >
                {storedIntegration.icon?.({})}
              </SplitBarButton>
            </Tooltip>
          )}
          <DropdownMenu.Root onOpenChange={removeNewIndicator} modal={false}>
            <Tooltip content={tooltipLabel}>
              <StyledDropDownTrigger asChild disabled={disableBar}>
                <BarButton
                  aria-label="More integration options"
                  type="button"
                  slimPadding
                  hasNewTags={hasNewTags}
                  id="grouped-integrations-button"
                  data-testid="grouped-integrations-button"
                  disabled={disableBar}
                  darkerHover={!!storedIntegration}
                >
                  {!storedIntegration && <StyledAddMediaIcon />}
                  <StyledChevronIcon />
                </BarButton>
              </StyledDropDownTrigger>
            </Tooltip>

            <DropdownMenu.Portal container={container}>
              <StyledDropdown align="start">
                {services.map((service) => (
                  <StyledListItem
                    onClick={(): void => onServiceClick(service)}
                    key={service.id}
                    data-testid={`integrations-bar-${service.id}`}
                    disabled={disableBar}
                  >
                    {service.icon?.({}) ?? null}
                    {service.title}
                    {service.isNew && <NewTag isDropdownEnabled>New</NewTag>}
                  </StyledListItem>
                ))}
              </StyledDropdown>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </ButtonContainer>

        {children && <ChildrenArea>{children}</ChildrenArea>}
      </IntegrationsBarContainer>

      {/* GENERAL MODAL FOR INTEGRATIONS */}
      <SearchImagesModal
        open={!!activeService?.openSearchImagesModal}
        handleClose={(): void => activeService?.onClose?.()}
        service={activeService}
        context={context}
        container={container}
      />
    </>
  )
}

IntegrationsBar.defaultProps = {
  disableBar: false,
  children: null,
}
