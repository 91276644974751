import React from 'react'

import { HelpIcon, Sidebar, StatusDot, useSidebar } from '@buffer-mono/popcorn'

import styles from './Sidebar.module.css'
import {
  ProductNames,
  type ProductType,
} from '../../../common/constants/products'
import BufferLogoWithWords from './BufferLogoWithWords'
import {
  getPortForProduct,
  getPublishUrl,
  getUrlEnvModifier,
} from '../../../common/utils/urls'
import { getActiveProductFromUrl } from '../../../exports/Navigator/utils/getActiveProductFromUrl'
import { useCrossAppNavigate } from '../../../common/hooks/useCrossAppNavigate'
import { useUser } from '../../../common/context/User'
import StreakWidget from '../../NavBar/components/StreakWidget'
import AccountMenu from '../../NavBar/components/AccountMenu/AccountMenu'
import AccountMenuAvatar from '../../NavBar/components/AccountMenu/components/AccountMenuAvatar'
import clsx from 'clsx'
import HelpMenu from '../../NavBar/components/HelpMenu/HelpMenu'
import UpgradeCTA from '../../../common/components/UpgradeCTA/UpgradeCTA'
import { WhatsNew } from './WhatsNew'

export default function MainNavSideBar({
  productList,
}: {
  productList: ProductType[]
}): JSX.Element {
  const user = useUser()
  const navigateCrossApp = useCrossAppNavigate()
  const currentProduct = getActiveProductFromUrl()
  const environmentModifier = getUrlEnvModifier()

  const { toggleSidebar } = useSidebar()

  const avatar = user.avatar
  const isImpersonation = user.isImpersonation
  const hasVerifiedEmail = user.hasVerifiedEmail
  const showStatusDot = user.id && !hasVerifiedEmail
  const shouldShowUpgradeCTA = Boolean(UpgradeCTA())

  return (
    <Sidebar collapsible="offcanvas" className={styles.sidebar}>
      <Sidebar.Content className={styles.sidebarContent}>
        <Sidebar.Button
          onClick={(): void => {
            toggleSidebar()
            navigateCrossApp(getPublishUrl())
          }}
        >
          <BufferLogoWithWords />
        </Sidebar.Button>
        <Sidebar.List>
          {productList.map((product) => {
            const origin = `https://${
              product.subdomain
            }.${environmentModifier}buffer.com${getPortForProduct(product.id)}`
            const url = `${origin}/${product.pathname ?? ''}`

            return (
              <Sidebar.ListItem key={product.id}>
                {product.id === ProductNames.PUBLISH ||
                product.id === ProductNames.CREATE ? (
                  <Sidebar.Button
                    selected={product.id === currentProduct}
                    size="large"
                    className={styles.button}
                    onClick={(): void => {
                      toggleSidebar()
                      navigateCrossApp(url)
                    }}
                  >
                    {product.label}
                  </Sidebar.Button>
                ) : (
                  <Sidebar.Button
                    selected={product.id === currentProduct}
                    size="large"
                    as="a"
                    href={url}
                    className={styles.button}
                  >
                    {product.label}
                  </Sidebar.Button>
                )}
              </Sidebar.ListItem>
            )
          })}
        </Sidebar.List>
      </Sidebar.Content>
      <Sidebar.Footer>
        <Sidebar.List className={styles.footerList}>
          {shouldShowUpgradeCTA && (
            <Sidebar.ListItem>
              <Sidebar.Button as="span" size="large" className={styles.button}>
                <UpgradeCTA />
              </Sidebar.Button>
            </Sidebar.ListItem>
          )}
          <Sidebar.ListItem>
            <StreakWidget isMobile={true} handleOnClose={toggleSidebar} />
          </Sidebar.ListItem>
          <Sidebar.ListItem>
            <HelpMenu>
              <Sidebar.Button size="large" className={styles.button}>
                <HelpIcon />
                Help
              </Sidebar.Button>
            </HelpMenu>
          </Sidebar.ListItem>
          <Sidebar.ListItem>
            <WhatsNew>
              <Sidebar.Button size="large" className={styles.button}>
                <WhatsNew.Icon size="small" />
                What&apos;s New
              </Sidebar.Button>
            </WhatsNew>
          </Sidebar.ListItem>
          <Sidebar.ListItem>
            <AccountMenu user={user} handleOnClose={toggleSidebar}>
              <Sidebar.Button
                size="large"
                className={clsx(styles.button, styles.accountButton)}
              >
                <AccountMenuAvatar
                  avatar={avatar}
                  isImpersonation={isImpersonation}
                  size="small"
                />
                {showStatusDot && (
                  <StatusDot
                    data-testid="email-not-verified-badge"
                    className={styles.avatarBadge}
                  />
                )}

                {user?.name || 'Account'}
              </Sidebar.Button>
            </AccountMenu>
          </Sidebar.ListItem>
        </Sidebar.List>
      </Sidebar.Footer>
    </Sidebar>
  )
}
