import React, { useEffect, useMemo, useState } from 'react'
import SettingsPageLayout from './components/SettingsPageLayout'
import { LoadingIcon, Paragraph } from '@buffer-mono/popcorn'
import { useOrganizationId } from '~publish/legacy/accountContext'
import ConfirmDeleteTeamMemberModal from './components/ConfirmDeleteTeamMemberModal'
import ConfirmRemoveChannelAccessModal from './components/ConfirmRemoveChannelAccessModal'
import { useSplitEnabled } from '@buffer-mono/features'

const getTeamManageUrl = (): string => {
  if (window.location.hostname.includes('local.buffer.com')) {
    return 'https://local.buffer.com/manage'
  }
  if (window.location.hostname.includes('dev.buffer.com')) {
    return 'https://legacy-web.dev.buffer.com/manage'
  }
  return 'https://buffer.com/manage'
}

const Team = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [iframeHeight, setIframeHeight] = useState('100%')
  const iframeRef = React.useRef<HTMLIFrameElement>(null)

  const currentOrganizationId = useOrganizationId()

  const [showDeleteTeamMemberModal, setShowDeleteTeamMemberModal] =
    useState(false)
  const [deletingTeamMember, setDeletingTeamMember] = useState<
    | {
        name: string
        email: string
      }
    | undefined
  >(undefined)

  const [showRemoveChannelAccessModal, setShowRemoveChannelAccessModal] =
    useState(false)
  const [removingChannelAccessDetails, setRemovingChannelAccessDetails] =
    useState<
      | {
          channelName: string
          email: string
        }
      | undefined
    >(undefined)

  const initialUrl = useMemo(() => {
    const currentHash = window.location.hash
    const withoutHashSymbol = currentHash.replace('#', '')
    const organizationPart = currentOrganizationId
      ? `/${currentOrganizationId}`
      : ''
    // https://github.com/bufferapp/buffer-web/blob/main/app/controllers/manage.php#L56-L58
    const contentOnlyParam = '?iframe=true'
    return `${getTeamManageUrl()}${organizationPart}${withoutHashSymbol}${contentOnlyParam}`
  }, [currentOrganizationId])

  // If we have an org ID (data loaded) and the org is explicitly set to redirect,
  // then take them to the non-iframed version (buffer-web)
  const { isEnabled: legacyTeamRedirectEnabled } = useSplitEnabled(
    'should-redirect-to-old-team-page',
  )

  useEffect(() => {
    if (currentOrganizationId && legacyTeamRedirectEnabled) {
      window.location.href = getTeamManageUrl()
    }
  }, [currentOrganizationId, legacyTeamRedirectEnabled])

  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      switch (event.data.type) {
        case 'loaded':
          setIsLoading(false)
          break
        case 'resize': {
          const newHeight = event.data.payload.height
          // if newHeight is greater than viewport height then use that
          // otherwise use 100% as the default height
          if (newHeight > window.innerHeight - 64) {
            setIframeHeight(`${newHeight + 100}px`)
          } else {
            setIframeHeight('100%')
          }
          break
        }
        case 'routeChange':
          // update the hash with the route
          window.location.hash = event.data.payload.pathname
          break
        case 'confirmRemoveMember':
          setShowDeleteTeamMemberModal(true)
          setDeletingTeamMember(event.data.payload.member)
          break
        case 'confirmRemoveChannelAccess':
          setShowRemoveChannelAccessModal(true)
          setRemovingChannelAccessDetails(event.data.payload)
          break
        default:
          break
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [])

  // Handle browser back/forward button clicks
  useEffect(() => {
    const handleHashChange = (): void => {
      const newPath = window.location.hash.replace('#', '')
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          {
            type: 'routeChange',
            payload: {
              pathname: newPath,
            },
          },
          '*',
        )
      }
    }

    window.addEventListener('hashchange', handleHashChange)
    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [])

  return (
    <SettingsPageLayout wide>
      {isLoading && (
        <Paragraph>
          <LoadingIcon style={{ verticalAlign: 'middle' }} /> Loading...
        </Paragraph>
      )}
      <ConfirmDeleteTeamMemberModal
        isOpen={showDeleteTeamMemberModal}
        onConfirm={(): void => {
          if (iframeRef.current) {
            iframeRef.current.contentWindow?.postMessage(
              {
                type: 'removeMember',
              },
              '*',
            )
          }
        }}
        close={(): void => {
          setShowDeleteTeamMemberModal(false)
        }}
        member={deletingTeamMember}
      />
      <ConfirmRemoveChannelAccessModal
        isOpen={showRemoveChannelAccessModal}
        onConfirm={(): void => {
          if (iframeRef.current) {
            iframeRef.current.contentWindow?.postMessage(
              {
                type: 'removeChannelAccess',
              },
              '*',
            )
          }
        }}
        close={(): void => {
          setShowRemoveChannelAccessModal(false)
        }}
        data={removingChannelAccessDetails}
      />

      {!legacyTeamRedirectEnabled && (
        <iframe
          ref={iframeRef}
          title="Team"
          src={initialUrl}
          style={{
            width: '780px',
            height: iframeHeight,
            overflow: 'hidden',
            border: 'none',
          }}
        />
      )}
    </SettingsPageLayout>
  )
}

export default Team
