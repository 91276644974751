import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSplitEnabled } from '@buffer-mono/features'

import { useAccount } from '~publish/legacy/accountContext'
import { CLIENT_NAMES, SEGMENT_NAMES } from '~publish/legacy/constants'
import { toggleReminders } from '~publish/legacy/general-settings/thunks/toggleReminders'
import {
  selectCurrentProfileId,
  selectDefaultToReminders,
  selectedProfileServiceName,
  selectIsManagerInChannel,
  selectNotificationsEnabled,
  selectProfileServiceType,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'

import {
  Button,
  Flex,
  Heading,
  Label,
  Link,
  Notice,
  Paragraph,
  Separator,
  Switch,
  Text,
} from '@buffer-mono/popcorn'
import commonStyles from '../../GeneralSettings.module.css'
import styles from './RemindersSettings.module.css'

export function RemindersSettings({
  isRemindersOnly = false,
}: {
  isRemindersOnly?: boolean
}): JSX.Element {
  const { isEnabled: isInstagramRemindersPersonalProfilesEnabled } =
    useSplitEnabled('instagram-reminders-personal-profiles')

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization

  const profileId = useAppSelector(selectCurrentProfileId)
  const notificationsEnabled = useAppSelector((state) =>
    selectNotificationsEnabled(state, [profileId]),
  )
  const isManager = useAppSelector(selectIsManagerInChannel(profileId))
  const defaultToReminders = useAppSelector((state) =>
    selectDefaultToReminders(state, profileId),
  )
  const openWizardButtonCopy = notificationsEnabled
    ? t('remindersSetupWizard.testNotificationsButton')
    : t('remindersSetupWizard.setUpNotificationsButton')

  const onRemindersSetupButtonClick = (): void => {
    BufferTracker.remindersSetupCTAClicked({
      organizationId: currentOrganization?.id || null,
      clientName: CLIENT_NAMES.web,
      cta: SEGMENT_NAMES.REMINDERS_SETTINGS,
      placement: 'generalSettings',
    })

    const { actions, MODALS } = window.appshell || {}

    actions?.openModal(MODALS?.setupNotifications, {
      source: 'generalSettings',
    })
  }

  useEffect(() => {
    if (currentOrganization?.id) {
      BufferTracker.remindersSetupCTAViewed({
        organizationId: currentOrganization?.id || null,
        clientName: CLIENT_NAMES.web,
        cta: SEGMENT_NAMES.REMINDERS_SETTINGS,
        placement: 'generalSettings',
      })
    }
  }, [currentOrganization])

  const onToggle = (): void => {
    dispatch(
      toggleReminders({
        profileId,
        allowReminders: !defaultToReminders,
      }),
    )
  }

  const profileName = useAppSelector((state) =>
    selectedProfileServiceName(state),
  )
  const profileType = useAppSelector(selectProfileServiceType(profileId))

  const profileNameMap = {
    facebook: 'Meta',
    instagram: 'Instagram',
  }
  const shouldShowSwitchToProfessionalAccountNotice =
    isInstagramRemindersPersonalProfilesEnabled &&
    profileName === 'instagram' &&
    profileType === 'profile'

  return (
    <>
      <Flex
        style={{ width: '100%' }}
        justify="between"
        align="center"
        data-testid="reminders-settings-wrapper"
      >
        <Flex direction="column" gap="2xs">
          <Heading size="small" as="h3">
            {isRemindersOnly
              ? t('remindersSettings.notificationsOnlyChannels.title')
              : t('remindersSettings.title')}
          </Heading>
          <Paragraph className={commonStyles.settingsParagraph}>
            {isRemindersOnly
              ? t('remindersSettings.notificationsOnlyChannels.description', {
                  profileName:
                    profileNameMap[profileName as keyof typeof profileNameMap],
                  profileType,
                })
              : t('remindersSettings.description')}{' '}
            {!isRemindersOnly ? (
              <Link
                href="https://support.buffer.com/article/658-using-notification-publishing?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
                external={true}
              >
                {t('remindersSettings.learnMore')}
              </Link>
            ) : null}
          </Paragraph>
          {shouldShowSwitchToProfessionalAccountNotice && (
            <Notice variant="info" className={styles.notice}>
              <Flex gap="xs" direction="column">
                <Text>
                  Connect your Instagram as a Professional (Business or Creator)
                  account to enable automatic publishing. Instagram will guide
                  you through the process.
                </Text>
                <Flex gap="xs">
                  <Button
                    size="small"
                    onClick={(): void => {
                      const { actions } = window.appshell

                      actions.connectChannel({
                        selectedService:
                          Service.Instagram as unknown as Parameters<
                            typeof actions.connectChannel
                          >[0]['selectedService'],
                        customChannelIdToConvert: profileId,
                        authMethod: 'instagramLogin',
                        cta: 'publish-channelSettings-switchToAProfessionalAccount-1',
                      })
                    }}
                  >
                    Switch to Professional Account
                  </Button>
                  <Button
                    variant="tertiary"
                    size="small"
                    as="a"
                    href="https://support.buffer.com/article/554-using-instagram-with-buffer#Converting-your-Instagram-account-to-a-Professional-account-Creator-o-pCMTs?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
                    target="_blank"
                  >
                    Learn More
                  </Button>
                </Flex>
              </Flex>
            </Notice>
          )}
        </Flex>
        <Flex
          direction="column"
          align="end"
          gap="sm"
          className={commonStyles.actionsWrapper}
        >
          {!isRemindersOnly && (
            <Flex gap="xs" align="center">
              <Label>
                {defaultToReminders
                  ? t('remindersSettings.toggleLabel.on')
                  : t('remindersSettings.toggleLabel.off')}
              </Label>
              <Switch
                id="instagram-reminders-switch"
                disabled={!isManager || !notificationsEnabled}
                checked={defaultToReminders}
                onCheckedChange={onToggle}
              />
            </Flex>
          )}
          <Button
            size="large"
            onClick={onRemindersSetupButtonClick}
            variant={notificationsEnabled ? 'secondary' : 'primary'}
            className={commonStyles.actionButton}
            role="button"
          >
            {openWizardButtonCopy}
          </Button>
        </Flex>
      </Flex>
      <Separator />
    </>
  )
}
