import { Slot } from '@buffer-mono/popcorn'
import React, { useCallback } from 'react'

import {
  type CreateNewPostInComposerData,
  usePostComposer,
} from '~publish/hooks/usePostComposer'

type NewPostComposerTriggerProps = CreateNewPostInComposerData & {
  children: React.ReactNode
}

/**
 * Use this component to trigger opening the composer for a new post.
 *
 * @example
 * ```tsx
 * <NewPostComposerTrigger prefillPostData={{text: "Hello"}}>
 *  <Button>Open</Button>
 * </NewPostComposerTrigger>
 * ````
 */
const NewPostComposerTrigger = ({
  children,
  ...openComposerArgs
}: NewPostComposerTriggerProps): JSX.Element | null => {
  const { triggerAttributes, createNewPostInComposer } = usePostComposer()

  const handleOpen = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.preventDefault()
      createNewPostInComposer(openComposerArgs)
    },
    [createNewPostInComposer, openComposerArgs],
  )

  return (
    <Slot.Root {...triggerAttributes} onClick={handleOpen}>
      {children}
    </Slot.Root>
  )
}

export { NewPostComposerTrigger }
export type { NewPostComposerTriggerProps }
