import { useCallback } from 'react'
import type { Service } from '~publish/gql/graphql'

interface RefreshChannelArgs {
  channel: {
    id: string
    service: Service
    name: string
    apiVersion?: string | null
  }
  cta?: string
}

export const useRefreshChannelConnection = (): ((
  args: RefreshChannelArgs,
) => void) => {
  return useCallback((args: RefreshChannelArgs) => {
    if (window.appshell?.actions) {
      const { actions } = window.appshell || {}

      actions.connectChannel({
        selectedService: args.channel.service.toLowerCase() as Parameters<
          typeof actions.connectChannel
        >[0]['selectedService'],
        isRefreshingConnection: true,
        cta: args.cta,
        name: args.channel.name,
        selectedRefreshChannelId: args.channel.id,
        authMethod: args.channel.apiVersion ?? undefined,
      })
    }
  }, [])
}
