import React from 'react'

import {
  Button,
  CheckCircleIcon,
  DropdownMenu,
  EllipsisVerticalIcon,
  FileTextIcon,
  Flex,
  IconButton,
  IdeasIcon,
  toast,
} from '@buffer-mono/popcorn'

import type { CommentData } from '~publish/gql/graphql'

import styles from './CommentActions.module.css'

export const CommentActionsMenu = (): JSX.Element => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <Button size="medium" variant="tertiary">
          <EllipsisVerticalIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content data-no-focus-lock className={styles.optionsMenu}>
        <DropdownMenu.Group label="Feeling inspired by this comment?">
          <DropdownMenu.Item
            onClick={(): string | number =>
              toast.success('Create post to be implemented')
            }
          >
            <FileTextIcon
              size="medium"
              color="brand"
              className={styles.postIcon}
            />
            Start a Post
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={(): string | number =>
              toast.success('Create idea to be implemented')
            }
          >
            <IdeasIcon
              size="medium"
              color="success"
              className={styles.ideaIcon}
            />
            Create an Idea
          </DropdownMenu.Item>
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

export const CommentActions = ({
  status,
  onDismiss,
  onUndoDismiss,
  disabled = false,
}: {
  status: CommentData['status']
  onDismiss: () => void
  onUndoDismiss: () => void
  disabled?: boolean
}): JSX.Element => {
  return (
    <Flex>
      {status === 'dismissed' && (
        <IconButton
          label="Undo dismiss"
          onClick={onUndoDismiss}
          tooltip="Undo dismiss"
          variant="tertiary"
          className={styles.undoDismissButton}
          disabled={disabled}
        >
          <CheckCircleIcon />
        </IconButton>
      )}
      {(status === 'unreplied' || status === 'new') && (
        <IconButton
          label="Dismiss"
          onClick={onDismiss}
          tooltip="Dismiss this comment"
          variant="tertiary"
          className={styles.dismissButton}
          disabled={disabled}
        >
          <CheckCircleIcon />
        </IconButton>
      )}
      <CommentActionsMenu />
    </Flex>
  )
}
