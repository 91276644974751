import React, { useEffect, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/browser'
import { useSelector } from 'react-redux'
import { Flex } from '@buffer-mono/popcorn'

import useAuthorizationInfo from '../../../hooks/useAuthorizationInfo/useAuthorizationInfo'
import type { OrchestratorRootState } from '../../../../../../common/events/types'
import type { onRedirectParams } from '../../../types'
import { MASTODON_SERVER_GENERIC } from './constants'

import { Body, Header, Footer } from './components'

type MastodonProps = {
  onRedirect: (args: onRedirectParams) => void
  onClose: () => void
}

export function Mastodon({
  onRedirect,
  onClose,
}: MastodonProps): JSX.Element | null {
  const inputRef = useRef(null)
  const [selectedServer, setSelectedServer] = useState<string | null>(null)
  const [customServer, setCustomServer] = useState<string>('')
  const [followBuffer, setFollowBuffer] = useState<boolean>(true)

  const { selectedService, isRefreshingChannelConnection } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )

  const {
    handleGetAuthorizationInfo,
    url: redirectUrl,
    isAuthorizationInfoLoading: isLoading,
    authorizationInfoErrorMessage,
  } = useAuthorizationInfo()

  const isValidServer = Boolean(
    (selectedServer === MASTODON_SERVER_GENERIC && customServer.length > 0) ||
      (selectedServer && selectedServer !== MASTODON_SERVER_GENERIC),
  )

  function onConfirmServer(): void {
    if (selectedService && selectedServer) {
      const serverValue =
        selectedServer === MASTODON_SERVER_GENERIC
          ? customServer
          : selectedServer

      handleGetAuthorizationInfo({
        service: selectedService,
        isRefreshingConnection: isRefreshingChannelConnection,
        server: serverValue,
        followBuffer,
      })
    } else {
      Bugsnag.notify(
        new Error(
          `onConfirmServer: handleGetAuthorizationInfo was not triggered because there is an issue with either the selected service: ${selectedService} or the selected server ${selectedServer}`,
        ),
      )
    }
  }

  useEffect(() => {
    if (redirectUrl) {
      onRedirect({ redirectUrl })
    }
  }, [redirectUrl, onRedirect])

  return (
    <Flex direction="column">
      <Header onClose={onClose} />
      <Body
        inputRef={inputRef}
        setSelectedServer={setSelectedServer}
        setCustomServer={setCustomServer}
        selectedServer={selectedServer}
        hasError={Boolean(authorizationInfoErrorMessage)}
      />
      <Footer
        onConfirmServer={onConfirmServer}
        setFollowBuffer={setFollowBuffer}
        followBuffer={followBuffer}
        loading={Boolean(isLoading || redirectUrl)}
        isValidServer={isValidServer}
      />
    </Flex>
  )
}
