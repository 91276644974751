import { graphql } from '~publish/graphql'
import { isOfType } from '~publish/helpers/typeGuards'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'

const DeleteFeed = graphql(/* GraphQL */ `
  mutation DeleteFeed($input: DeleteFeedInput!) {
    deleteFeed(input: $input) {
      __typename
      ... on DeleteFeedResponse {
        __typename
        id
      }
      ... on UnexpectedError {
        __typename
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useDeleteFeed = () =>
  useTypedMutation(DeleteFeed, (data) => data.deleteFeed, {
    successTypename: 'DeleteFeedResponse',
    update(cache, { data }) {
      const result = data?.deleteFeed
      if (!result || !isOfType(result, 'DeleteFeedResponse')) return

      // Evict the deleted feed from the cache
      const normalizedId = cache.identify({
        id: result.id,
        __typename: 'Feed',
      })

      if (normalizedId) {
        cache.evict({ id: normalizedId })
        cache.gc() // Garbage collection to clean up any dangling references
      }
    },
  })
