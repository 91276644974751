import React from 'react'
import { Tooltip } from '@buffer-mono/popcorn'

import { Services } from '~publish/legacy/constants'
import { networkIconMap } from '~publish/legacy/shared-components'

import * as Styled from './Channel.styles'

/**
 *
 */
function Channel({ channel }: { channel: string }): JSX.Element | null {
  const icon = networkIconMap.get(channel)
  const IconComponent = icon ? icon.component : null

  return icon ? (
    <InfoTooltip channel={channel}>
      <Styled.IconWrapper
        data-testid="ai-assistant-tailoring-for-channel-icon"
        id="ai-assistant-tailoring-for-channel"
        color={icon.color}
      >
        {/* @ts-expect-error TS(2604) FIXME: JSX element type 'IconComponent' does not have any... Remove this comment to see the full error message */}
        <IconComponent
          data-testid={`ai-assistant-tailoring-for-${channel}-icon`}
          size="medium"
        />
      </Styled.IconWrapper>
    </InfoTooltip>
  ) : null
}

/**
 *
 */
function InfoTooltip({
  channel,
  children,
}: {
  channel: string
  children: React.ReactNode
}): JSX.Element | null {
  const channelConfig = Services.get(channel)

  return channelConfig ? (
    <Tooltip
      data-state="instant-open"
      sideOffset={8}
      side="bottom"
      content={`Tailoring for ${channelConfig.formattedName}`}
    >
      {children}
    </Tooltip>
  ) : null
}

export { Channel }
