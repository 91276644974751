import React from 'react'
import clsx from 'clsx'

import { useBreakpoint } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import { PlantOnTrack } from '../images/PlantOnTrack'
import { PlantHit } from '../images/PlantHit'
import { PlantStarted } from '../images/PlantStarted'
import { PlantAtRisk } from '../images/PlantAtRisk'
import { PlantAhead } from '../images/PlantAhead'
import { StreakBackgroundAtRisk } from '../images/StreakBackgroundAtRisk'
import { StreakBackgroundHit } from '../images/StreakBackgroundHit'

import styles from './StreakHeader.module.css'
import type { WeeklyPostingStreakData } from '../StreakWidget'
import { PlantOnTrackLarge } from '../images/PlantOnTrackLarge'

const StreakHeaderRoot = ({
  children,
  style,
}: {
  children: React.ReactNode
  style?: React.CSSProperties
}): JSX.Element => {
  return (
    <div className={clsx(styles.streakBackgroundContainer)} style={style}>
      {children}
    </div>
  )
}

const VARIANTS = {
  Default: StreakBackgroundHit,
  AtRisk: StreakBackgroundAtRisk,
  Hit: StreakBackgroundHit,
}

const StreakHeaderBackground = ({
  streak,
  children,
  ...rest
}: React.SVGProps<SVGSVGElement> & {
  streak?: WeeklyPostingStreakData['weeklyPostingStreak']
  children?: React.ReactNode
}): JSX.Element => {
  const { isEnabled: isResponsiveNavEnabled } = useSplitEnabled(
    'responsive-main-nav',
  )
  const breakpoint = useBreakpoint()
  const isDrawer =
    breakpoint !== 'md' &&
    breakpoint !== 'lg' &&
    breakpoint !== 'xl' &&
    isResponsiveNavEnabled

  if (children) {
    return <div className={styles.streakBackground}>{children}</div>
  }

  let Background =
    streak && streak.status in VARIANTS
      ? VARIANTS[streak.status as keyof typeof VARIANTS]
      : VARIANTS.Default

  if (streak && streak.status !== 'AtRisk' && (streak.periodsAhead ?? 0) > 0) {
    Background = VARIANTS.Default
  }

  return (
    <div className={clsx(styles.streakBackground, isDrawer && styles.isDrawer)}>
      <Background
        {...rest}
        className={clsx(styles.svgBackground, isDrawer && styles.isDrawer)}
      />
    </div>
  )
}

const STATUS_PLANT_MAP = {
  OnTrack: PlantOnTrack,
  Hit: PlantHit,
  Started: PlantStarted,
  AtRisk: PlantAtRisk,
  Ahead: PlantAhead,
}

const StreakHeaderContent = ({
  streak,
}: {
  streak?: WeeklyPostingStreakData['weeklyPostingStreak']
}): JSX.Element => {
  const { isEnabled: isResponsiveNavEnabled } = useSplitEnabled(
    'responsive-main-nav',
  )

  const breakpoint = useBreakpoint()
  const isDrawer =
    breakpoint !== 'md' &&
    breakpoint !== 'lg' &&
    breakpoint !== 'xl' &&
    isResponsiveNavEnabled

  // Started
  if (streak?.status === 'Started' || !streak || streak.status === 'Missed') {
    return (
      <div
        className={clsx(
          styles.streakIllustrationContainer,
          isDrawer && styles.streakIllustrationContainerMobile,
        )}
      >
        <img
          src="https://buffer-ui.s3.us-east-1.amazonaws.com/streaks/onboarding1.png"
          alt=""
          height={isDrawer ? 96 : 81}
        />
      </div>
    )
  }

  // Just started on track
  if (
    streak?.status === 'OnTrack' &&
    streak?.count === 0 &&
    streak?.periodsAhead === 0
  ) {
    return (
      <div className={styles.streakIllustrationContainer}>
        <div className={styles.imageContainer}>
          <div
            className={clsx(styles.mainCircle, styles.purple, {
              [styles.large]: isDrawer,
            })}
          >
            <PlantOnTrackLarge />
          </div>
        </div>
      </div>
    )
  }

  let Plant =
    streak && streak.status in STATUS_PLANT_MAP
      ? STATUS_PLANT_MAP[streak.status as keyof typeof STATUS_PLANT_MAP]
      : STATUS_PLANT_MAP.Started

  if (
    (streak?.status === 'Hit' || streak?.status === 'OnTrack') &&
    (streak?.periodsAhead ?? 0) > 0
  ) {
    Plant = PlantAhead
  }

  return (
    <div className={styles.streakIllustrationContainer}>
      <div className={clsx(styles.imageContainer, isDrawer && styles.isDrawer)}>
        <div
          className={clsx(styles.mainCircle, {
            [styles.atRisk]: streak && streak.status === 'AtRisk',
            [styles.large]: isDrawer,
          })}
        >
          {streak?.count}
        </div>
        <div
          className={clsx(styles.secondaryCircle, {
            [styles.atRisk]: streak && streak.status === 'AtRisk',
            [styles.large]: isDrawer,
          })}
        >
          <Plant />
        </div>
      </div>
    </div>
  )
}

export const StreakHeader = Object.assign(StreakHeaderRoot, {
  Background: StreakHeaderBackground,
  Content: StreakHeaderContent,
})
