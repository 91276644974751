import React from 'react'
import events from './Events'
import { toast, Link } from '@buffer-mono/popcorn'
import { Link as RRLink } from 'react-router-dom'
import { NewPostComposerTrigger } from '~publish/components/NewPostComposerTrigger'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import type { Tag } from '~publish/gql/graphql'

const AppHooks = {
  handleAppLoaded: (): void => {
    events.emit('loaded')
  },

  handleSavedDrafts: (args: {
    message: string
    fromIdea: boolean
    postData: {
      tags: Tag[]
      channelIds: string[]
    }
    entityType?: string
    deepLink?: string
  }): void => {
    const { message, fromIdea, entityType = 'post', deepLink, postData } = args

    if (deepLink) {
      toast.success(
        <div>
          {message}{' '}
          <Link as={RRLink} to={deepLink}>
            View {entityType}
          </Link>{' '}
          &bull;{' '}
          <NewPostComposerTrigger
            channels={postData.channelIds}
            prefillPostData={{ tags: postData.tags }}
            cta={SEGMENT_NAMES.TOAST_SUCCESS_CREATE_POST}
          >
            <Link as="button">Create another</Link>
          </NewPostComposerTrigger>
        </div>,
      )
    } else {
      toast.success(
        <div>
          {message}{' '}
          <NewPostComposerTrigger
            channels={postData.channelIds}
            prefillPostData={{ tags: postData.tags }}
            cta={SEGMENT_NAMES.TOAST_SUCCESS_CREATE_POST}
          >
            <Link as="button">Create another</Link>
          </NewPostComposerTrigger>
        </div>,
      )
    }
    events.emit('saved-drafts', { message, fromIdea })
  },

  handleBackdropClicked: () => {
    events.emit('backdrop-clicked')
  },

  // after a user starts a trial, send message with updated organizationsData
  // @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
  handleStartTrial: ({ message, removeScope }) => {
    events.emit('start-trial', { message, removeScope })
  },

  handleActionTaken: (message = {}) => {
    events.emit('action-taken', message)
  },
}

export default AppHooks
