import { useParams } from 'react-router-dom'
import React, { useState } from 'react'

import {
  AlertDialog,
  Button,
  ChevronDownIcon,
  DropdownMenu,
  Flex,
  Label,
  ScrollArea,
  TrashIcon,
} from '@buffer-mono/popcorn'

import { useTimeFormatPreference } from '~publish/hooks/useTimeFormatPreference'

import { usePostingSchedule } from '../../hooks/usePostingSchedule'
import { convertTo24HourTime } from '../../utils'
import type { DayOption } from '../../types'

import styles from './PostingTimeForm.module.css'

interface WeekdaysOptions {
  name: string
  value: DayOption
}

const weekdaysOptions: WeekdaysOptions[] = [
  { name: 'Every Day', value: 'every day' },
  { name: 'Weekdays', value: 'weekdays' },
  { name: 'Weekends', value: 'weekends' },
  { name: 'Monday', value: 'mon' },
  { name: 'Tuesday', value: 'tue' },
  { name: 'Wednesday', value: 'wed' },
  { name: 'Thursday', value: 'thu' },
  { name: 'Friday', value: 'fri' },
  { name: 'Saturday', value: 'sat' },
  { name: 'Sunday', value: 'sun' },
]

const twelveHours = Array.from({ length: 12 }, (_, i) =>
  (i + 1).toString().padStart(2, '0'),
)
const twentyFourHours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, '0'),
)
const minutes = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, '0'),
)

const PostingTimeForm = (): JSX.Element => {
  const is24hour = useTimeFormatPreference() === '24'
  const now = new Date()

  const amPm = now.getHours() >= 12 ? 'PM' : 'AM'
  const [formData, setFormData] = useState<{
    day: DayOption
    hours: number
    minutes: number
    amPm?: 'AM' | 'PM'
  }>({
    day: weekdaysOptions[0].value,
    hours: now.getHours(),
    minutes: now.getMinutes(),
    amPm,
  })

  const [submitting, setSubmitting] = React.useState(false)

  const displayHours = is24hour ? twentyFourHours : twelveHours

  const { id: channelId } = useParams<{
    id: string
  }>()
  const { isEmpty, clearSchedule, addPostingTime, hasEditingPrivilege } =
    usePostingSchedule(channelId)

  const handleSubmit = async (): Promise<void> => {
    setSubmitting(true)
    await addPostingTime(
      formData.day,
      convertTo24HourTime({
        hours: formData.hours,
        minutes: formData.minutes,
        amPm: is24hour ? undefined : formData.amPm,
      }),
    )
    setSubmitting(false)
  }

  if (!hasEditingPrivilege) {
    return <></>
  }

  return (
    <>
      {/* Clear all posting times */}

      <Flex justify="between">
        {/* Add a new posting time */}
        <Flex
          align="center"
          gap="xs"
          justify="start"
          data-testid="postingtime-form-wrapper"
        >
          <Label>Add a new posting time for</Label>
          <DropdownMenu>
            <DropdownMenu.Trigger>
              <Button
                data-testid="postingtime-form-days-selector"
                size="large"
                variant="secondary"
                style={{
                  minWidth: '130px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {weekdaysOptions.find((option) => option.value === formData.day)
                  ?.name ?? formData.day}
                <ChevronDownIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {weekdaysOptions.map((weekday) => (
                <DropdownMenu.Item
                  onClick={(): void => {
                    setFormData({
                      ...formData,
                      day: weekday.value,
                    })
                  }}
                  key={weekday.value}
                >
                  {weekday.name}
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu>
          <Label>at</Label>
          <DropdownMenu>
            <DropdownMenu.Trigger>
              <Button
                data-testid="postingtime-form-hours-selector"
                size="large"
                variant="secondary"
              >
                {(is24hour ? formData.hours : Number(formData.hours) % 12 || 12)
                  .toString()
                  .padStart(2, '0')}
                <ChevronDownIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className={styles.dropdown}>
              <ScrollArea>
                {displayHours.map((hour) => (
                  <DropdownMenu.Item
                    onClick={(): void => {
                      setFormData((prev) => ({
                        ...prev,
                        hours: Number(hour),
                      }))
                    }}
                    key={hour}
                  >
                    {hour}
                  </DropdownMenu.Item>
                ))}
              </ScrollArea>
            </DropdownMenu.Content>
          </DropdownMenu>
          <DropdownMenu>
            <DropdownMenu.Trigger>
              <Button
                data-testid="postingtime-form-minutes-selector"
                size="large"
                variant="secondary"
              >
                {formData.minutes.toString().padStart(2, '0')}{' '}
                <ChevronDownIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className={styles.dropdown}>
              <ScrollArea>
                {minutes.map((minute) => (
                  <DropdownMenu.Item
                    onClick={(): void => {
                      setFormData((prev) => ({
                        ...prev,
                        minutes: Number(minute),
                      }))
                    }}
                    key={minute}
                  >
                    {minute}
                  </DropdownMenu.Item>
                ))}
              </ScrollArea>
            </DropdownMenu.Content>
          </DropdownMenu>
          {!is24hour && (
            <DropdownMenu className={styles.dropdown}>
              <DropdownMenu.Trigger>
                <Button
                  data-testid="postingtime-form-am-pm-selector"
                  size="large"
                  variant="secondary"
                >
                  {formData.amPm} <ChevronDownIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {['AM', 'PM'].map((amPm) => (
                  <DropdownMenu.Item
                    onClick={(): void => {
                      setFormData((prev) => ({
                        ...prev,
                        amPm: is24hour ? undefined : (amPm as 'AM' | 'PM'),
                      }))
                    }}
                    key={amPm}
                  >
                    {amPm}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu>
          )}
          <Button
            size="large"
            variant="primary"
            loading={submitting}
            onClick={handleSubmit}
            data-testid="postingtime-form-submit-button"
          >
            Add Posting Time
          </Button>
        </Flex>

        {/* Clear all posting times */}
        {!isEmpty && (
          <AlertDialog>
            <AlertDialog.Trigger>
              <Button
                size="large"
                variant="critical-subtle"
                data-testid="posting-schedule-clear-all-trigger"
              >
                <TrashIcon /> Clear All
              </Button>
            </AlertDialog.Trigger>
            <AlertDialog.Content>
              <AlertDialog.Title>Clear all times?</AlertDialog.Title>
              <AlertDialog.Description>
                All your posting times will be removed. This action cannot be
                undone.
              </AlertDialog.Description>
              <AlertDialog.Actions>
                <AlertDialog.Cancel>
                  <Button size="large" variant="tertiary">
                    Cancel
                  </Button>
                </AlertDialog.Cancel>
                <AlertDialog.Action>
                  <Button
                    size="large"
                    onClick={clearSchedule}
                    variant="critical"
                    data-testid="posting-schedule-clear-all-confirm"
                  >
                    Clear
                  </Button>
                </AlertDialog.Action>
              </AlertDialog.Actions>
            </AlertDialog.Content>
          </AlertDialog>
        )}
      </Flex>
    </>
  )
}

export { PostingTimeForm }
