import twitterText from 'twitter-text'
import {
  SERVICE_INSTAGRAM,
  SERVICE_LINKEDIN,
  SERVICE_FACEBOOK,
  SERVICE_GOOGLEBUSINESS,
  SERVICE_STARTPAGE,
  SERVICE_TIKTOK,
  SERVICE_YOUTUBE,
} from '~publish/legacy/constants'
import type {
  Link,
  Image,
} from '~publish/legacy/composer/composer/entities/factories'
import { getMastodonMentions } from '~publish/legacy/utils/channels/mastodon/helpers'
import type { Content } from '../types'
import { DraftMethods } from '~publish/legacy/composer/composer/entities/Draft/DraftMethods'

export const DEFAULT_AVATAR =
  'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

export const hasOnlyUrl = (text: string): boolean => {
  const parsedUrlsWithIndices = twitterText.extractUrlsWithIndices(text)
  const urls = parsedUrlsWithIndices?.map(
    (urlWithIndices) => urlWithIndices.url,
  )

  if (urls.length && urls.length > 0) {
    let newText = text
    urls.forEach((url) => {
      newText = newText.replace(url, '')
    })
    if (newText.replace(/\s/g, '').length === 0) {
      return true
    }
  }

  return false
}

export const extractUrl = (
  text: string,
  returnFirstUrl?: boolean,
): string | null => {
  const parsedUrlsWithIndices = twitterText.extractUrlsWithIndices(text)
  const urls = parsedUrlsWithIndices?.map(
    (urlWithIndices) => urlWithIndices.url,
  )

  if (urls.length && urls.length > 0) {
    return returnFirstUrl ? urls[0] : urls[urls.length - 1]
  }

  return null
}

export const textExceedsCharLimit = (
  text: string,
  charLimit: number,
): boolean => {
  return twitterText.getTweetLength(text) > charLimit
}

export const truncateText = (
  text: string | undefined,
  charLimit: number,
  appendEllipsis = false,
): string | undefined => {
  let textToDisplay = text

  const hasTooLongText =
    textToDisplay && textExceedsCharLimit(textToDisplay, charLimit)

  if (hasTooLongText) {
    textToDisplay = textToDisplay?.substring(0, charLimit)
    textToDisplay = appendEllipsis ? `${textToDisplay}...` : textToDisplay
  }

  return textToDisplay
}

export const truncateBlueskyText = (
  text: string | undefined,
  charLimit: number,
): string | undefined => {
  if (!text) {
    return text
  }

  const characterCount = DraftMethods.getBlueskyTextCharacterCount(text)
  const hasTooLongText = characterCount > charLimit

  if (hasTooLongText) {
    // take into consideration that emojis are counted as one character and links take max 31 characters
    return text.substring(0, charLimit + (text.length - characterCount))
  }

  return text
}

export const truncateMastodonText = (
  text: string | undefined,
  charLimit: number,
): string | undefined => {
  if (!text) return text

  const parsedUrls = twitterText.extractUrls(text)
  const mentions = getMastodonMentions(text) || []

  if (parsedUrls.length === 0 && mentions.length === 0) {
    return truncateText(text, charLimit)
  }

  const linkLength = 23
  let tempText = text
  let characterCount = 0

  const links = parsedUrls || []
  links.forEach((link) => {
    characterCount += linkLength
    tempText = tempText.replace(link, '')
  })

  mentions.forEach((mention) => {
    characterCount += mention.displayString.length
    tempText = tempText.replace(mention.rawString, '')
  })

  characterCount += tempText.length
  const exceededCharacterCount = characterCount - charLimit

  if (exceededCharacterCount > 0) {
    return text.slice(0, -exceededCharacterCount)
  }

  return text
}

export const getMediaProperties = (
  content: Content,
  linkPreview: Link | null,
): {
  hasImages: boolean
  hasVideo: boolean
  hasLink: boolean
} => {
  const { images, gif, video, enabledAttachmentType } = content

  const hasMedia = enabledAttachmentType === 'MEDIA'
  const hasImages = !!(hasMedia && (images?.length || gif))
  const hasVideo = !!(hasMedia && video)
  const hasLink = !!(linkPreview && !hasImages && !hasVideo)

  return { hasImages, hasVideo, hasLink }
}

export enum FormatLayout {
  Column = 'column',
  Row = 'row',
}

export enum ImageOrientation {
  Square = 'square',
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export const getOrientation = ({
  height,
  width,
}: {
  height?: number | null
  width?: number | null
}): 'portrait' | 'landscape' | 'square' => {
  if (!height || !width || width > height) {
    return ImageOrientation.Landscape
  }

  if (height === width) {
    return ImageOrientation.Square
  }

  return ImageOrientation.Portrait
}

export const isImagePortrait = (image: Image): boolean =>
  getOrientation(image) === ImageOrientation.Portrait

export const isImageLandscape = (image: Image): boolean =>
  getOrientation(image) === ImageOrientation.Landscape

export const isImageSquare = (image: Image): boolean =>
  getOrientation(image) === ImageOrientation.Square

export const removeLinkFromEndOfText = (
  text: string,
  link?: string | null,
): string => {
  if (link && text.trim().endsWith(link)) {
    text = text.replace(link, '').trim()
  }
  return text
}

export const getPreviewCharLimit = (service: string): number => {
  switch (service) {
    case SERVICE_INSTAGRAM:
    case SERVICE_LINKEDIN:
    case SERVICE_FACEBOOK:
    case SERVICE_STARTPAGE:
      return 100
    case SERVICE_GOOGLEBUSINESS:
      return 220
    case SERVICE_TIKTOK:
      return 84
    default:
      return 125
  }
}

export const getPreviewTitleCharLimit = (service: string): number => {
  switch (service) {
    case SERVICE_YOUTUBE:
      return 100
    default:
      return 58
  }
}
