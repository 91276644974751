import React, { useCallback, useEffect, useState } from 'react'

import {
  Flex,
  Heading,
  Select,
  Separator,
  Sidebar,
  Skeleton,
  Text,
  toast,
} from '@buffer-mono/popcorn'

import SettingsPageLayout from './components/SettingsPageLayout'

import styles from './Settings.module.css'
import type {
  TimeFormat as TimeFormatEnum,
  StartOfWeek as StartOfWeekEnum,
} from '~publish/gql/graphql'
import { usePreferences } from './hooks/usePreferences'
import { useUpdateTimeFormatPreference } from './hooks/useUpdateTimeFormatPreference'
import { useUpdateStartOfWeekPreference } from './hooks/useUpdateStartOfWeekPreference'
import { TimeZoneSelect } from '~publish/components/TimeZoneSelect'
import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'
import { useUpdateTimeZonePreferences } from './hooks/useUpdateTimeZonePreferences'

const genericSuccessMessage = 'Great! Your preferences have been saved'

const TimeZone = ({
  isLoading,
  timezone,
}: {
  isLoading: boolean
  timezone: string | null | undefined
}): JSX.Element => {
  const {
    updateTimeZone,
    isLoading: isUpdatingTimeZone,
    hasError,
  } = useUpdateTimeZonePreferences()
  const fallbackTimeZone = getCurrentTimeZone()
  const defaultTimeZoneSelection = timezone || fallbackTimeZone

  const handleOnChange = useCallback(
    (value: string): void => {
      console.log('value', value)
      updateTimeZone({ timeZone: value }).then(() => {
        toast.success(genericSuccessMessage)
      })
    },
    [updateTimeZone],
  )

  useEffect(() => {
    if (hasError) {
      toast.error(
        'Sorry! There was a problem updating your selected timezone. Please try again.',
      )
    }
  }, [hasError])

  return (
    <Flex gap="xs" justify="between" fullWidth>
      <Flex gap="xs" align="baseline" direction="column">
        <Heading as="h2" size="xsmall" id="timezone-heading">
          Timezone
        </Heading>
        <Text id="timezone-desc" color="subtle">
          Used as the default timezone for new connected channels and for
          sending email notifications
        </Text>
      </Flex>
      <Skeleton
        show={isLoading || !defaultTimeZoneSelection}
        data-testid="timezone-skeleton"
      >
        <TimeZoneSelect
          variant="secondary"
          size="medium"
          onChange={handleOnChange}
          value={defaultTimeZoneSelection}
          disabled={isUpdatingTimeZone}
          aria-labelledby="timezone-heading"
          aria-describedby="timezone-desc"
        />
      </Skeleton>
    </Flex>
  )
}
const TimeFormat = ({
  timeFormat,
  isLoading,
}: {
  timeFormat: TimeFormatEnum | null
  isLoading: boolean
}): JSX.Element => {
  const timeFormatOptions = [
    { label: '12-hour', value: 'twelveHour', timeFormat: '12' },
    { label: '24-hour', value: 'twentyFourHour', timeFormat: '24' },
  ]

  const selectedOption = timeFormatOptions.find(
    (option) => option.timeFormat === timeFormat,
  )

  const timeFormatLabel = selectedOption?.label || '12-hour'

  const [selectedValue, setSelectedValue] = useState(selectedOption?.value)

  const {
    updateTimeFormatPreference,
    isLoading: isUpdatingTimeFormat,
    hasError,
  } = useUpdateTimeFormatPreference()

  const handleOnChange = useCallback(
    (value: string): void => {
      setSelectedValue(value)
      updateTimeFormatPreference({ timeFormat: value as TimeFormatEnum }).then(
        () => {
          toast.success(genericSuccessMessage)
        },
      )
    },
    [updateTimeFormatPreference],
  )

  useEffect(() => {
    if (hasError) {
      toast.error(
        'Sorry! There was a problem updating your selected time format. Please try again.',
      )
    }
  }, [hasError])

  return (
    <Flex gap="xs" justify="between" fullWidth>
      <Flex gap="xs" align="baseline" direction="column">
        <Heading as="h2" size="xsmall" id="time-format-heading">
          Time Format
        </Heading>
        <Text id="time-format-desc" color="subtle">
          Set the time format for the Calendar and Queue
        </Text>
      </Flex>
      <Skeleton
        show={isLoading || !timeFormat}
        data-testid="time-format-skeleton"
      >
        <Select onValueChange={handleOnChange} value={selectedValue}>
          <Select.Trigger
            id="time-format"
            aria-labelledby="time-format-heading"
            aria-describedby="time-format-desc"
            placeholder={timeFormatLabel}
            disabled={isUpdatingTimeFormat}
            size="medium"
          />
          <Select.Content
            position="popper"
            align="end"
            sideOffset={0}
            className={styles.selectContent}
          >
            {timeFormatOptions.map((option) => (
              <Select.Item key={option.value} value={option.value}>
                {option.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Skeleton>
    </Flex>
  )
}

const StartOfWeek = ({
  startOfWeek,
  isLoading,
}: {
  startOfWeek: StartOfWeekEnum | null
  isLoading: boolean
}): JSX.Element => {
  const {
    updateStartOfWeekPreference,
    isLoading: isUpdatingStartOfWeek,
    hasError,
  } = useUpdateStartOfWeekPreference()

  const startOfWeekOptions = [
    { label: 'Monday', value: 'monday' },
    { label: 'Sunday', value: 'sunday' },
  ]

  const selectedOption = startOfWeekOptions.find(
    (option) => option.value === startOfWeek,
  )
  const startOfWeekLabel = selectedOption?.label || 'Monday'

  const [selectedValue, setSelectedValue] = useState(selectedOption?.value)

  const handleOnChange = useCallback(
    (value: string): void => {
      setSelectedValue(value)
      updateStartOfWeekPreference({
        startOfWeek: value as StartOfWeekEnum,
      }).then(() => {
        toast.success(genericSuccessMessage)
      })
    },
    [updateStartOfWeekPreference],
  )

  useEffect(() => {
    if (hasError) {
      toast.error(
        'Sorry! There was a problem updating your selected start of week. Please try again.',
      )
    }
  }, [hasError])

  return (
    <Flex gap="xs" justify="between" fullWidth>
      <Flex gap="xs" align="baseline" direction="column">
        <Heading as="h2" size="xsmall" id="start-of-week-heading">
          Start of Week
        </Heading>
        <Text id="start-of-week-desc" color="subtle">
          Set the first day of the week for the Calendar and date picker
        </Text>
      </Flex>
      <Skeleton
        show={isLoading || !startOfWeek}
        data-testid="start-of-week-skeleton"
      >
        <Select onValueChange={handleOnChange} value={selectedValue}>
          <Select.Trigger
            id="start-of-week"
            aria-labelledby="start-of-week-heading"
            aria-describedby="start-of-week-desc"
            placeholder={startOfWeekLabel}
            disabled={isUpdatingStartOfWeek}
            size="medium"
          />
          <Select.Content
            position="popper"
            align="end"
            sideOffset={0}
            className={styles.selectContent}
          >
            {startOfWeekOptions.map((option) => (
              <Select.Item key={option.value} value={option.value}>
                {option.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Skeleton>
    </Flex>
  )
}

export const Preferences = ({
  isMobile,
  handleToggleSidebar,
}: {
  isMobile: boolean
  handleToggleSidebar: () => void
}): JSX.Element => {
  const { preferences, isLoading } = usePreferences()

  const timeFormat = preferences?.timeFormat as TimeFormatEnum
  const startOfWeek = preferences?.startOfWeek as StartOfWeekEnum
  const timezone = preferences?.timezone

  return (
    <SettingsPageLayout>
      <Flex
        gap="xl"
        direction="column"
        data-testid="email-notifications-skeleton"
      >
        <Flex gap="sm" direction="row" align="center">
          {isMobile && <Sidebar.Trigger onClick={handleToggleSidebar} />}
          <Heading as="h1" size="large">
            Preferences
          </Heading>
        </Flex>
        <section className={styles.fullWidth}>
          <TimeZone isLoading={isLoading} timezone={timezone} />
          <Separator />
          <TimeFormat timeFormat={timeFormat} isLoading={isLoading} />
          <Separator />
          <StartOfWeek startOfWeek={startOfWeek} isLoading={isLoading} />
        </section>
      </Flex>
    </SettingsPageLayout>
  )
}

export default Preferences
