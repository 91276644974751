import React, { useRef, useState } from 'react'
import Button from '@bufferapp/ui/Button'
import { ButtonsQueuingTypesMap, SaveButtonTypes } from '../../AppConstants'
import AppActionCreators from '../../action-creators/AppActionCreators'
import { ScheduleTime } from './DraftSaveButtons'
import type { BeforeFirstActionReturnType } from './hooks/useBeforeFirstAction'

interface StackedSaveButtonsProps {
  isSavingPossible: boolean
  isSavingDraftPossible: boolean
  timezone?: string
  draftMode?: boolean
  previewMode?: boolean
  firstStackedButtonLabel: string
  firstStackedButtonType: keyof typeof SaveButtonTypes
  otherStackedButtonsTypes?: string[]
  beforeFirstAction?: BeforeFirstActionReturnType
  saveButtonsCopy: Map<string, string>
}

export const StackedSaveButtons = ({
  isSavingPossible,
  isSavingDraftPossible,
  draftMode = false,
  previewMode = false,
  timezone,
  firstStackedButtonLabel,
  firstStackedButtonType,
  otherStackedButtonsTypes = [],
  beforeFirstAction,
  saveButtonsCopy,
}: StackedSaveButtonsProps): JSX.Element => {
  const stackedSafeButtonRef = useRef<HTMLDivElement | null>(null)
  const [buttonAction, setButtonAction] = useState<
    keyof typeof SaveButtonTypes | undefined
  >()
  const isSaveDisabled = draftMode ? !isSavingDraftPossible : !isSavingPossible
  const showDropDown = otherStackedButtonsTypes.length > 0
  const dropDownItems = otherStackedButtonsTypes.map((btnType) => ({
    id: btnType,
    title: saveButtonsCopy.get(btnType),
  }))
  const [popoverPosition, setPopoverPosition] = useState<'top' | 'bottom'>(
    'bottom',
  )

  const handleClickOutsideScheduleTime = (): void => {
    setButtonAction(undefined)
  }

  const handleClickAction = (
    buttonType: keyof typeof SaveButtonTypes,
    isDropdownItem: boolean,
  ): void => {
    const showDateTimePicker =
      (buttonType === SaveButtonTypes.SCHEDULE_DRAFT ||
        buttonType === SaveButtonTypes.SCHEDULE_POST) &&
      isDropdownItem

    if (showDateTimePicker) {
      setButtonAction(buttonType)
    } else {
      const queuingType = ButtonsQueuingTypesMap.get(buttonType)

      AppActionCreators.saveDrafts(queuingType, {
        shouldSkipEmptyTextAlert: false,
        hasCampaignsToTagsFeature: true,
      })
    }
  }

  const onFirstButtonClick = (): void => {
    if (beforeFirstAction?.stopDefaultAction && beforeFirstAction?.newAction) {
      beforeFirstAction.newAction()
      return
    }

    handleClickAction(firstStackedButtonType, false)
  }

  const onStackedButtonClick = ({
    id,
  }: {
    id: keyof typeof SaveButtonTypes
  }): void => {
    if (beforeFirstAction?.stopDefaultAction && beforeFirstAction?.newAction) {
      beforeFirstAction.newAction()
      return
    }

    handleClickAction(id, true)
  }

  const handleOpenDropdown = (): void => {
    if (stackedSafeButtonRef.current) {
      const popover =
        stackedSafeButtonRef.current.parentElement?.querySelector(
          'ul',
        )?.parentElement
      if (popover) {
        const buttonRect = stackedSafeButtonRef.current.getBoundingClientRect()
        const popoverRect = popover.getBoundingClientRect()

        const isOutsideWindow =
          buttonRect.bottom + popoverRect.height > window.innerHeight

        setPopoverPosition(isOutsideWindow ? 'top' : 'bottom')
      }
    }
  }

  return (
    <>
      {buttonAction && (
        <ScheduleTime
          timezone={timezone}
          draftMode={draftMode}
          buttonAction={buttonAction}
          onClickOutside={handleClickOutsideScheduleTime}
        />
      )}
      <div ref={stackedSafeButtonRef}>
        <Button
          data-testid="stacked-save-buttons"
          disabled={isSaveDisabled || previewMode}
          type="primary"
          isSplit={showDropDown}
          items={dropDownItems}
          label={firstStackedButtonLabel}
          onClick={onFirstButtonClick}
          onSelectClick={onStackedButtonClick}
          selectPosition={popoverPosition}
          onOpen={handleOpenDropdown}
        />
      </div>
    </>
  )
}
