import { EmptyState, RssIcon } from '@buffer-mono/popcorn'
import React from 'react'

import styles from './CollectionList.module.css'
import { TrackRender } from '~publish/hooks/tracking'

type CollectionListEmptyStateProps = {
  children: React.ReactNode
}
export const CollectionListEmptyState = ({
  children,
}: CollectionListEmptyStateProps): JSX.Element => {
  return (
    <EmptyState
      size="xlarge"
      variant="primary"
      className={styles.emptyStateContainer}
    >
      <EmptyState.Icon>
        <RssIcon />
      </EmptyState.Icon>
      <TrackRender componentName="CollectionListEmptyState" />
      <EmptyState.Heading>Add Feeds</EmptyState.Heading>
      <EmptyState.Description>
        Add RSS feeds from your favorite blogs and websites to get the latest
        content ideas and share them effortlessly.
      </EmptyState.Description>
      <EmptyState.Actions>{children}</EmptyState.Actions>
    </EmptyState>
  )
}
