import { ImageDimensions } from '../constants'

export const getResizedImageUrlWithWidth = (
  url: string,
  width: number,
): string => {
  let safeImageUrl = 'https://images.buffer.com/dynamic-internal'
  safeImageUrl += `/fit-in/${width}x/${url}`

  return safeImageUrl
}

export const getQueueResizedImageUrl = (url: string): string => {
  return getResizedImageUrlWithWidth(url, ImageDimensions.queueImage.width)
}
