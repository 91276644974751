import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import {
  Button,
  Flex,
  Heading,
  MessageCircleHeartIcon,
  Skeleton,
  Sidebar,
  useIsMobileScreenSize,
} from '@buffer-mono/popcorn'
import { useParams } from 'react-router-dom'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { PageLayout } from '~publish/components/PageLayout'
import { graphql, readFragment } from '~publish/graphql'
import { range } from '~publish/helpers/range'
import { isOfType } from '~publish/helpers/typeGuards'
import { usePageTitle } from '~publish/hooks/usePageTitle'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useFeedItems } from '../../hooks/useFeedItems'
import {
  FeedArticleCardSkeleton,
  FeedArticleLoadFailure,
} from '../FeedArticleList/FeedArticleCard'
import { FeedArticleList } from '../FeedArticleList/FeedArticleList'
import { FeedItemIcon } from '../Feed/FeedIcon'
import styles from './FeedList.module.css'

const FeedList_Feed = graphql(/* GraphQL */ `
  fragment FeedList_Feed on Feed {
    id
    name
    connectionMetadata {
      ... on RSSFeedConnectionMetadata {
        __typename
        url
      }
      ... on NewsSearchConnectionMetadata {
        __typename
        query
      }
    }
  }
`)

const GetFeedListDetails = graphql(
  /* GraphQL */ `
    query GetFeedListDetails($input: FeedInput!) {
      feed(input: $input) {
        ...FeedList_Feed
      }
    }
  `,
  [FeedList_Feed],
)

export const FeedList = (): JSX.Element | null => {
  const { feedId } = useParams<{ feedId: string }>()
  const organizationId = useOrganizationId()

  const isMobileScreenSize = useIsMobileScreenSize()

  const {
    data,
    loading: loadingFeedData,
    error,
  } = useQuery(GetFeedListDetails, {
    variables: {
      input: {
        id: feedId,
        organizationId: organizationId ?? '',
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  const feed = readFragment(FeedList_Feed, data?.feed)

  const {
    loading: loadingFeedItems,
    items,
    fetchingMore,
    lastElementRef,
    error: feedItemsError,
  } = useFeedItems({
    organizationId,
    feedIds: [feedId],
  })

  usePageTitle(feed?.name ? `${feed.name} (Feed)` : 'Feeds')
  const loadState = useMemo(() => {
    if (loadingFeedItems && !items?.length) {
      return { state: 'loading' } as const
    }
    if (items?.length) {
      return { state: 'success', items } as const
    }
    if (error || feedItemsError) {
      return { state: 'error', error: error || feedItemsError } as const
    }
    return { state: 'loading' } as const
  }, [error, feedItemsError, items, loadingFeedItems])

  const feedUrl = isOfType(
    feed?.connectionMetadata,
    'RSSFeedConnectionMetadata',
  )
    ? feed.connectionMetadata.url
    : undefined

  return (
    <PageLayout className={styles.pageLayout}>
      <PageLayout.Header>
        <PageLayout.HeaderRow>
          <Flex align="center" gap="sm">
            {isMobileScreenSize && (
              <Sidebar.Trigger aria-label="Toggle sidebar" />
            )}
            {loadingFeedData || !feed ? (
              <>
                <Skeleton width={36} height={36} aria-hidden="true" />
                <Skeleton
                  width={200}
                  height={36}
                  aria-label="Loading feed information"
                />
              </>
            ) : (
              <>
                <FeedItemIcon
                  feedName={feed.name}
                  url={feedUrl}
                  className={styles.feedNameIcon}
                  aria-hidden="true"
                />
                <Heading size="large" truncate className={styles.feedNameTitle}>
                  {feed.name}
                </Heading>
              </>
            )}
          </Flex>
          <PageLayout.Actions>
            <FeedbackWidget id="content-feeds-1" source="create">
              <Button variant="tertiary" size="large">
                <MessageCircleHeartIcon aria-hidden="true" />
                Share Feedback
              </Button>
            </FeedbackWidget>
          </PageLayout.Actions>
        </PageLayout.HeaderRow>
      </PageLayout.Header>
      <PageLayout.Container size="narrow" className={styles.feedContent}>
        {loadState.state === 'error' && <FeedArticleLoadFailure />}
        {loadState.state === 'loading' && (
          <div aria-live="polite" aria-label="Loading feed items">
            {range(20).map((index) => (
              <FeedArticleCardSkeleton key={index} />
            ))}
          </div>
        )}
        {loadState.state === 'success' && (
          <FeedArticleList
            items={loadState.items}
            fetchingMore={fetchingMore}
            lastElementRef={lastElementRef}
          />
        )}
      </PageLayout.Container>
    </PageLayout>
  )
}
