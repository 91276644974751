import React from 'react'

import {
  Button,
  Flex,
  Heading,
  Link,
  Separator,
  Sidebar,
  Text,
} from '@buffer-mono/popcorn'

import { useConnectedApps } from './hooks/useConnectedApps'
import { useRevokeApp } from './hooks/useRevokeApp'
import SettingsPageLayout from './components/SettingsPageLayout'
import ConfirmRevokeAccessModal from './components/ConfirmRevokeAccessModal'

import styles from './Settings.module.css'

const ConnectedApps = (): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [appId, setAppId] = React.useState('')
  const [appName, setAppName] = React.useState('')

  const { connectedApps } = useConnectedApps()
  const { revokeAccessConnectedApp } = useRevokeApp()

  const handleOpenModal = (id: string, appName: string): void => {
    setIsOpen(true)
    setAppId(id)
    setAppName(appName)
  }

  const handleRevokeAccess = (): void => {
    revokeAccessConnectedApp({ clientId: appId })
    setIsOpen(false)
  }

  const handleCloseModal = (): void => {
    setIsOpen(false)
  }

  return (
    <>
      <Flex gap="xs" justify="between" fullWidth>
        <ConfirmRevokeAccessModal
          isOpen={isOpen}
          onRevokeAccess={handleRevokeAccess}
          close={handleCloseModal}
          appName={appName}
        />
        <Flex gap="xs" align="baseline" direction="column">
          <Heading as="h2" size="xsmall" id="connected-apps-heading">
            Connected Apps
          </Heading>
          <Text id="connected-apps-desc" color="subtle">
            Connected Apps Get the most out of Buffer and share from your
            mobile, news reader, blog or anywhere!{' '}
            <Link href="https://buffer.com/extras" target="_blank">
              Get More Apps →
            </Link>
          </Text>
        </Flex>
      </Flex>
      {connectedApps?.map((app) => (
        <Flex
          gap="xl"
          justify="between"
          fullWidth
          key={app?.clientId}
          align="center"
          style={{ marginTop: 'var(--space-lg)' }}
        >
          <Flex gap="2xs" direction="column">
            <Heading
              as="h2"
              size="xsmall"
              id={`connected-apps-${app?.name.split(' ').join('-')}-heading`}
            >
              {app?.name}
            </Heading>
            <Text color="subtle">{app?.description}</Text>
          </Flex>

          <Button
            size="medium"
            variant="secondary"
            onClick={(): void => handleOpenModal(app?.clientId, app?.name)}
          >
            Revoke Access
          </Button>
        </Flex>
      ))}
    </>
  )
}

const Apps = (): JSX.Element => {
  return (
    <Flex gap="xs" justify="between" fullWidth>
      <Flex gap="xs" align="baseline" direction="column">
        <Heading as="h2" size="xsmall" id="apps-heading">
          Mobile Apps
        </Heading>
        <Text id="apps-desc" color="subtle">
          Share content and manage your Buffer account on the go with our mobile
          apps.
        </Text>
      </Flex>
      <Button
        as="a"
        size="medium"
        variant="secondary"
        href="https://play.google.com/store/apps/details?id=org.buffer.android"
        target="_blank"
        rel="noopener noreferrer"
        aria-describedby="apps-desc"
      >
        Android App
      </Button>
      <Button
        as="a"
        size="medium"
        variant="secondary"
        href="https://itunes.apple.com/app/apple-store/id490474324?pt=936146&ct=Web%20App%20Sidebar&mt=8"
        target="_blank"
        rel="noopener noreferrer"
        aria-describedby="apps-desc"
      >
        iOS App
      </Button>
    </Flex>
  )
}

const InstallExtension = (): JSX.Element => {
  return (
    <Flex gap="xs" justify="between" fullWidth>
      <Flex gap="xs" align="baseline" direction="column">
        <Heading as="h2" size="xsmall" id="browser-extension-heading">
          Browser Extension
        </Heading>
        <Text id="browser-extension-desc" color="subtle">
          Our browser extension lets you share content as you browse the web.
        </Text>
      </Flex>
      <Button
        as="a"
        size="medium"
        variant="secondary"
        href="https://buffer.com/extensions"
        target="_blank"
        rel="noopener noreferrer"
        aria-describedby="browser-extension-desc"
      >
        Install Extension
      </Button>
    </Flex>
  )
}

export const AppsExtrasPage = ({
  isMobile,
  handleToggleSidebar,
}: {
  isMobile: boolean
  handleToggleSidebar: () => void
}): JSX.Element => {
  return (
    <SettingsPageLayout>
      <Flex
        gap="xl"
        direction="column"
        data-testid="email-notifications-skeleton"
      >
        <Flex gap="sm" direction="row" align="center">
          {isMobile && <Sidebar.Trigger onClick={handleToggleSidebar} />}
          <Heading as="h1" size="large">
            Apps &amp; Extras
          </Heading>
        </Flex>
        <section className={styles.fullWidth}>
          <InstallExtension />
          <Separator />
          <Apps />
          <Separator />
          <ConnectedApps />
        </section>
      </Flex>
    </SettingsPageLayout>
  )
}

export default AppsExtrasPage
