export enum ProductLabels {
  PUBLISH = 'Publish',
  ANALYZE = 'Analyze',
  ENGAGE = 'Engage',
  START_PAGE = 'Start Page',
  CREATE = 'Create',
  COMMENTS = 'Community',
  ACCOUNT = 'Account',
  NEWSETTINGS = 'Settings',
  BUFFER = 'Buffer',
}

export enum ProductNames {
  PUBLISH = 'publish',
  ANALYZE = 'analyze',
  ENGAGE = 'engage',
  START_PAGE = 'start-page',
  CREATE = 'create',
  COMMENTS = 'comments',
  ACCOUNT = 'account',
  NEWSETTINGS = 'new-settings',
}

export type ProductType = {
  id: ProductNames
  label: ProductLabels
  isNew: boolean
  subdomain: string
  pathname?: string
  activeOnMatch?: RegExp
}
export const Products: ProductType[] = [
  {
    id: ProductNames.CREATE,
    label: ProductLabels.CREATE,
    isNew: false,
    subdomain: 'publish',
    pathname: 'create',
    activeOnMatch: /(content|create)/,
  },
  {
    id: ProductNames.PUBLISH,
    label: ProductLabels.PUBLISH,
    isNew: false,
    subdomain: 'publish',
    pathname: '',
  },
  {
    id: ProductNames.COMMENTS,
    label: ProductLabels.COMMENTS,
    isNew: false,
    subdomain: 'publish',
    pathname: 'comments',
  },
  {
    id: ProductNames.ANALYZE,
    label: ProductLabels.ANALYZE,
    isNew: false,
    subdomain: 'analyze',
    pathname: '',
  },
  {
    id: ProductNames.ENGAGE,
    label: ProductLabels.ENGAGE,
    isNew: false,
    subdomain: 'engage',
    pathname: '',
  },
  {
    id: ProductNames.START_PAGE,
    label: ProductLabels.START_PAGE,
    isNew: false,
    subdomain: 'start-page',
    pathname: '',
  },
  {
    id: ProductNames.ACCOUNT,
    label: ProductLabels.ACCOUNT,
    isNew: false,
    subdomain: 'account',
    pathname: '',
  },
  {
    id: ProductNames.NEWSETTINGS,
    label: ProductLabels.NEWSETTINGS,
    isNew: false,
    subdomain: 'publish',
    pathname: 'settings',
  },
]
