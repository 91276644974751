import React from 'react'

import { Text } from '@buffer-mono/popcorn'

import type { Channel } from '~publish/gql/graphql'

import { useCommentsScore } from '../hooks/useCommentsScoreQuery'

export const CommentsScore = ({
  channel,
}: {
  channel: Channel
}): JSX.Element => {
  const commentsScore = useCommentsScore({
    channelId: channel.id,
  })

  if (!commentsScore) return <p>Error loading comments score.</p>

  return (
    <Text align="center" size="lg">
      {commentsScore.currentWeekScore.totalValue} / 100
    </Text>
  )
}
