import styles from './PostingGoalConfiguration.module.css'
export const POSTING_GOAL_OPTIONS = [
  {
    title: 'Keep it steady · 1 time/week',
    description: 'Stay visible effortlessly (and grow your posting streak 🪴)',
    goal: 1,
    color: styles.purple,
  },
  {
    title: 'Build a presence · 3 times/week',
    description: 'Stay top-of-mind and spark conversations.',
    goal: 3,
    color: styles.orange,
  },
  {
    title: 'Reach new heights · 5 times/week',
    description: 'Maximize visibility and reach.',
    goal: 5,
    color: styles.teal,
  },
]

export const DEFAULT_POSTING_GOAL = POSTING_GOAL_OPTIONS[1]
