import React from 'react'
import styled from 'styled-components'

import { Sidebar } from '@buffer-mono/popcorn'

import { PublishSidebar } from '~publish/components/PublishSidebar'
import { isCommentsPage } from '~publish/legacy/routes'
import { CommentsSidebar } from '~publish/components/CommentsSidebar'
import { CommentsChannelConfigProvider } from '~publish/pages/Comments/CommentsChannelConfigContext'

const ContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`

interface PageWithSidebarWrapperProps {
  children: React.ReactNode
  className?: string
}

const PageWithSidebarWrapper = ({
  children,
  className,
}: PageWithSidebarWrapperProps): JSX.Element => {
  if (isCommentsPage()) {
    return (
      <Sidebar.Provider>
        {/*
          Enable StrictMode for the new Engage page to ensure the new code benefits from stricter checks and best practices.
          Enabling it app-wide could cause unexpected issues, given how Strict Mode works (e.g., extra renders, effect re-runs).
          We might want to enable it globally in the future, but for now, this incremental approach allows us to catch potential
          issues in new code without affecting the entire app.

          React more on Strict Mode: https://react.dev/reference/react/StrictMode
        */}
        <React.StrictMode>
          <CommentsChannelConfigProvider>
            <CommentsSidebar />
            <ContentStyle>{children}</ContentStyle>
          </CommentsChannelConfigProvider>
        </React.StrictMode>
      </Sidebar.Provider>
    )
  }

  return (
    <Sidebar.Provider>
      <PublishSidebar />
      <ContentStyle>{children}</ContentStyle>
    </Sidebar.Provider>
  )
}

export default PageWithSidebarWrapper
