function getUrlEnvModifier(): string {
  const [, envModifier] = window.location.hostname.match(
    /\w+\.(\w+\.)buffer\.com/,
  ) || [null, '']
  return envModifier
}

const productPorts = {
  publish: ':8888',
  analyze: ':8081',
  create: ':8888',
  comments: ':8888',
  'start-page': ':9999',
  // If no port is specified, we assume the product is running on port 8080
}

function getPortForProduct(product: string): string {
  const envModifier = getUrlEnvModifier()
  if (envModifier !== 'local.') return ''
  return productPorts[product as keyof typeof productPorts] || ':8080'
}

export function getSettingsUrl(): string {
  const envModifier = getUrlEnvModifier()
  const port = getPortForProduct('publish')
  return `https://publish.${
    envModifier || ''
  }buffer.com${port}/settings/billing`
}
