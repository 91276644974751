import type { Account } from '../../../common/types'
import { BannerTypes } from '../../../common/hooks/useDismissBanner'

export function shouldShowXMigrationBanner(user: Account): boolean {
  if (
    !user ||
    !user.currentOrganization ||
    !user.currentOrganization.channels
  ) {
    return false
  }

  const hasXChannelThatRequiresRefreshing =
    user.currentOrganization.channels.filter(
      (channel) =>
        channel.service === 'twitter' &&
        channel.apiVersion !== '2' &&
        !channel.isLocked,
    ).length > 0

  const hasNotDismissedBanner = !user?.dismissedBanners?.includes(
    BannerTypes.xMigrationBanner,
  )

  return hasXChannelThatRequiresRefreshing && hasNotDismissedBanner
}
