import React, { useEffect, useState } from 'react'
import { ConfettiAnimation } from './ConfettiAnimation'
import { ConfettiBackground } from './ConfettiBackground'

interface ConfettiProps {
  className?: string
  width?: number
  height?: number
  duration?: number
  startDelay?: number
}

export const Confetti: React.FC<ConfettiProps> = (props) => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState<
    boolean | null
  >(null)

  useEffect(() => {
    // Check if user prefers reduced motion
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    setPrefersReducedMotion(mediaQuery.matches)

    // Add listener to respond to changes in preference
    const handleChange = (e: MediaQueryListEvent): void => {
      setPrefersReducedMotion(e.matches)
    }

    mediaQuery.addEventListener('change', handleChange)
    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [])

  if (prefersReducedMotion === null) {
    return null
  }

  // Use static background if reduced motion is preferred
  if (prefersReducedMotion) {
    return (
      <ConfettiBackground
        className={props.className}
        width={props.width}
        height={props.height}
      />
    )
  }

  // Otherwise use animated confetti
  return <ConfettiAnimation {...props} />
}
