import React from 'react'

import {
  DropdownMenu,
  Flex,
  KeyboardIcon,
  Text,
  toast,
} from '@buffer-mono/popcorn'
import type { Account } from '../../../../../common/types'
import { getCookie } from '../../../../../common/utils/cookies'

export const DeveloperDataItem = (args: { user: Account }): JSX.Element => {
  const user = args.user
  const organizationID = user.currentOrganization?.id || ''
  const accountId = user.id || ''
  const token = getCookie({ key: 'local_buffer_session', prefix: '' })
  return (
    <>
      <DropdownMenu.Separator />
      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger>
          <KeyboardIcon />
          Developer Data
        </DropdownMenu.SubTrigger>
        <DropdownMenu.SubMenu>
          <DropdownMenu.Item
            onSelect={async (): Promise<void> => {
              await navigator.clipboard.writeText(organizationID)
              toast.success('Organization ID copied to clipboard', {
                duration: 2000,
              })
            }}
          >
            <Flex gap="2xs" direction="column">
              Organization ID
              <Text as="small" color="subtle">
                {organizationID}
              </Text>
            </Flex>
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onSelect={async (): Promise<void> => {
              await navigator.clipboard.writeText(accountId)
              toast.success('Account ID copied to clipboard', {
                duration: 2000,
              })
            }}
          >
            <Flex gap="2xs" direction="column">
              Account ID
              <Text as="small" color="subtle">
                {accountId}
              </Text>
            </Flex>
          </DropdownMenu.Item>
          {token && typeof token === 'string' && (
            <DropdownMenu.Item
              onSelect={async (): Promise<void> => {
                await navigator.clipboard.writeText(token)
                toast.success('GraphQL Token copied to clipboard', {
                  duration: 2000,
                })
              }}
            >
              <Flex gap="2xs" direction="column">
                GraphQL Token
                <Text as="small" color="subtle">
                  {token.substring(0, 23)}...
                </Text>
              </Flex>
            </DropdownMenu.Item>
          )}
        </DropdownMenu.SubMenu>
      </DropdownMenu.Sub>
    </>
  )
}
