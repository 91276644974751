import React from 'react'
import { Flex, Skeleton } from '@buffer-mono/popcorn'

import { type FragmentType, getFragmentData } from '~publish/gql'
import { PostCardProvider, PostCard_Post } from '~publish/components/PostCard'
import { PostCardHeader } from '~publish/components/PostCardHeader'
import { PostCardFooter } from '~publish/components/PostCardFooter'
import { PostCardTags } from '~publish/components/PostCardTags'
import { PostCardAddons } from '~publish/components/PostCardAddons'
import { PostCardContent } from '~publish/components/PostCardContent'
import { PostMediaBentoBoxPreview } from '~publish/components/PostCard/PostMedia'
import { PostCardAttachments } from '~publish/components/PostCard/PostCardAttachments'
import { PostCardErrorNotice } from '~publish/components/PostCard/PostCardErrorNotice'
import {
  useMetadataTitle,
  usePostData,
} from '~publish/components/PostCard/PostCardContext'
import {
  getAnalyzeUrl,
  getEngageUrl,
} from '~publish/components/PostCard/helpers'
import {
  CollapsiblePostMetrics,
  MetricsRefresh,
  MoreAnalyticsIconButton,
  PostMetricsGrid,
  PostMetricsWrapper,
} from '~publish/components/PostDetails/PostMetrics'
import { PostCardContent_Annotation } from '~publish/components/PostCardContent/PostCardContent'
import { usePostDetailsDialog } from '~publish/components/PostManagementRouter/helpers'

import { CalendarPostCard_Post } from '../fragments/CalendarPostCard_Post'
import { CalendarPostCardHeader } from './CalendarPostCardHeader'

import styles from './CalendarPostCard.module.css'

type CalendarPostCardProps = {
  post?: FragmentType<typeof PostCard_Post>
  partialData: FragmentType<typeof CalendarPostCard_Post>
}

const TagsAndAddons = (): JSX.Element => (
  <Flex direction="column" gap="xs" className={styles.tagsAddonsContainer}>
    <PostCardAddons />
    <PostCardTags maxVisibleTags={4} />
  </Flex>
)

const Metrics = (): JSX.Element | null => {
  const { metrics: metricsData, channel } = usePostData()
  const metrics = metricsData ?? []

  const engageUrl = getEngageUrl(channel)
  const analyzeUrl = getAnalyzeUrl(channel)

  const collapsibleView = metrics.length > 3

  if (collapsibleView) {
    return (
      <PostMetricsWrapper className={styles.metricsWrapper}>
        <CollapsiblePostMetrics
          visibleMetrics={metrics.slice(0, 3)}
          collapsedMetrics={metrics.slice(3)}
          engageUrl={engageUrl}
          analyzeUrl={analyzeUrl}
        />
        <MetricsRefresh />
      </PostMetricsWrapper>
    )
  }

  const shouldShowMoreAnalyticsCTA =
    channel.service === 'linkedin' ? channel.type === 'page' : true

  return (
    <PostMetricsWrapper className={styles.metricsWrapper}>
      <PostMetricsGrid metrics={metrics} engageUrl={engageUrl} />
      {shouldShowMoreAnalyticsCTA && analyzeUrl && (
        <MoreAnalyticsIconButton analyzeUrl={analyzeUrl} />
      )}
      <MetricsRefresh />
    </PostMetricsWrapper>
  )
}

export const CalendarPostCard = ({
  post,
  partialData,
}: CalendarPostCardProps): JSX.Element => {
  const postData = getFragmentData(PostCard_Post, post)
  const partialPostData = getFragmentData(CalendarPostCard_Post, partialData)
  const { openDetailsDialog } = usePostDetailsDialog({
    postId: postData?.id ?? partialPostData.id,
  })
  const title = useMetadataTitle(postData?.metadata ?? partialPostData.metadata)
  const type = postData?.metadata?.type ?? partialPostData.metadata?.type
  const annotations =
    getFragmentData(
      PostCardContent_Annotation,
      postData?.metadata?.annotations,
    ) ?? []

  const openExpandedDetailsDialog = (): void => {
    openDetailsDialog({
      cta: 'publish-calendar-postCardText-seeMore-1',
      expandText: 'true',
    })
  }
  return (
    <PostCardProvider
      post={
        post ?? (partialData as unknown as FragmentType<typeof PostCard_Post>)
      }
    >
      <article className={styles.cardContainer}>
        <PostCardErrorNotice clampErrorMessage />
        <CalendarPostCardHeader />
        <section className={styles.body}>
          <div className={styles.bodyWrapper}>
            <div className={styles.contentWrapper}>
              <PostCardHeader type={type} condensed />
              <PostCardContent
                title={title}
                text={postData?.text ?? partialPostData.text}
                annotations={annotations}
                onSeeMoreClick={openExpandedDetailsDialog}
              />
              <PostCardAttachments condensed />
            </div>
            <div>
              <PostMediaBentoBoxPreview
                assets={postData?.assets ?? partialPostData.assets}
                className={styles.bentoBox}
              />
            </div>
          </div>
          {postData ? (
            <TagsAndAddons />
          ) : (
            <div className={styles.tagsAddonsContainerSkeleton}>
              <Skeleton height={24} width={24} />
            </div>
          )}
        </section>
        {postData && <Metrics />}
        {postData ? (
          <PostCardFooter condensed />
        ) : (
          <PostCardFooter.Skeleton isSent={partialPostData.status === 'sent'} />
        )}
      </article>
    </PostCardProvider>
  )
}
