import React, { useMemo } from 'react'
import { Button, Flex } from '@buffer-mono/popcorn'

import styles from './SuggestionButtons.module.css'

const MAX_QUICK_REPLIES = 5

/**
 * Source of comment suggestions
 */
export type CommentSuggestionSource = 'Template' | 'Ai'

/**
 * Type of comment suggestions. We use the type to differentiate between text and emoji suggestions
 */
export type CommentSuggestionType = 'text' | 'emoji'

/**
 * Represents a suggested response or action for a comment
 */
export type Suggestion = {
  /**
   * The suggested text to respond with
   */
  text: string
  /**
   * The source of the suggestion (e.g., AI, predefined template)
   */
  source: CommentSuggestionSource
  /**
   * The type of the suggestion (e.g., text or emoji)
   */
  type: CommentSuggestionType
}

const DEFAULT_SUGGESTIONS: Suggestion[] = [
  { text: 'Thank you', source: 'Template', type: 'text' },
  { text: 'Appreciate it', source: 'Template', type: 'text' },
  { text: 'Much appreciated', source: 'Template', type: 'text' },
  { text: 'Great point!', source: 'Template', type: 'text' },
  { text: 'Love this', source: 'Template', type: 'text' },
  { text: 'Exactly', source: 'Template', type: 'text' },
  { text: 'So true', source: 'Template', type: 'text' },
  { text: 'Well said', source: 'Template', type: 'text' },
  { text: 'Couldn’t agree more', source: 'Template', type: 'text' },
  { text: 'Thanks a lot', source: 'Template', type: 'text' },
  { text: 'Totally agree', source: 'Template', type: 'text' },
  { text: 'Good call!', source: 'Template', type: 'text' },
  { text: 'Solid advice', source: 'Template', type: 'text' },
  { text: 'You’re right', source: 'Template', type: 'text' },
  { text: 'Very insightful', source: 'Template', type: 'text' },
  { text: 'I like that', source: 'Template', type: 'text' },
  { text: "That's interesting!", source: 'Template', type: 'text' },
  { text: 'Well put', source: 'Template', type: 'text' },
  { text: 'Nice thought', source: 'Template', type: 'text' },
  { text: 'Great insight', source: 'Template', type: 'text' },
  { text: 'Absolutely!', source: 'Template', type: 'text' },
  { text: 'Spot on', source: 'Template', type: 'text' },
  { text: 'Agree completely', source: 'Template', type: 'text' },
  { text: 'Nicely said', source: 'Template', type: 'text' },
  { text: 'True', source: 'Template', type: 'text' },
  { text: 'For sure', source: 'Template', type: 'text' },
  { text: 'Very true', source: 'Template', type: 'text' },
  { text: 'I hear you', source: 'Template', type: 'text' },
  { text: "That's a take", source: 'Template', type: 'text' },
  { text: '❤️', source: 'Template', type: 'emoji' },
  { text: '🙂', source: 'Template', type: 'emoji' },
  { text: '🔥', source: 'Template', type: 'emoji' },
  { text: '🚀', source: 'Template', type: 'emoji' },
  { text: '🙏', source: 'Template', type: 'emoji' },
]

type SuggestionButtonsProps = {
  /**
   * List of suggestions to display as buttons
   * @default DEFAULT_SUGGESTIONS
   */
  suggestions?: Suggestion[]
  /**
   * Callback when a suggestion is clicked
   */
  onSuggestionClick: (suggestion: Suggestion) => void
}

/**
 * Get a random subset of suggestions to display with
 * a mix of emojis and text suggestions. At least one emoji
 * with a max of 2 emojis and the rest text suggestions
 */
const getRandomSuggestions = ({
  suggestions = DEFAULT_SUGGESTIONS,
}: {
  suggestions: Suggestion[]
}): Suggestion[] => {
  const numEmojis = Math.random() < 0.5 ? 1 : 2 // Randomly pick 1 or 2 emojis
  const numTexts = MAX_QUICK_REPLIES - numEmojis // Ensure total is always 5

  const emojis = suggestions.filter((s: Suggestion) => s.type === 'emoji')
  const texts = suggestions.filter((s: Suggestion) => s.type === 'text')

  const shuffledEmojis = [...emojis].sort(() => Math.random() - 0.5)
  const shuffledTexts = [...texts].sort(() => Math.random() - 0.5)

  return [
    ...shuffledEmojis.slice(0, numEmojis),
    ...shuffledTexts.slice(0, numTexts),
  ]
}

export function SuggestionButtons({
  suggestions = DEFAULT_SUGGESTIONS,
  onSuggestionClick,
}: SuggestionButtonsProps): JSX.Element {
  const suggestionsToShow = useMemo(
    () => getRandomSuggestions({ suggestions }),
    [suggestions],
  )

  return (
    <Flex direction="row" gap="2xs" wrap="wrap">
      {suggestionsToShow.map((suggestion) => (
        <Button
          key={crypto.randomUUID()}
          variant="secondary"
          size="small"
          className={styles.suggestionButton}
          onClick={(): void => onSuggestionClick(suggestion)}
        >
          {suggestion.text}
        </Button>
      ))}
    </Flex>
  )
}
