import React from 'react'
import {
  Card,
  ChannelAvatar,
  CheckIcon,
  Flex,
  LockIcon,
  Text,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import type { Channel } from '../../../../../common/types'
import type { Service } from '../../../../../exports/Orchestrator/channelConnections/types'
import {
  capitalizeFirstLetter,
  getServiceName,
} from '../../../../../exports/Orchestrator/channelConnections/utils'
import styles from './ChannelsList.module.css'

const ChannelsList = (args: { channels: Channel[] }): JSX.Element => {
  const { channels } = args
  return (
    <Flex className={styles.channelList} direction="column" gap="sm">
      {channels.map((channel) => (
        <Card
          key={channel.id}
          className={clsx(
            styles.channelCard,
            channel.isLocked && styles.locked,
          )}
        >
          <Flex gap="md" align="center">
            <ChannelAvatar
              alt={channel.name}
              channel={channel.service as Service}
              src={channel.avatar}
              size="small"
            />
            <Flex direction="column" className={styles.channelInfo}>
              <Text>
                <strong>{channel.name}</strong>
              </Text>
              <Text color="subtle">
                {getServiceName(channel.service as Service)}{' '}
                {capitalizeFirstLetter(channel.type || '')}
              </Text>
            </Flex>
            <Flex align="center" gap="xs">
              {channel.isLocked ? (
                <LockIcon size="small" color="subtle" />
              ) : (
                <CheckIcon size="small" />
              )}
              <Text>
                <strong>{channel.isLocked ? 'Locked' : 'Connected'}</strong>
              </Text>
            </Flex>
          </Flex>
        </Card>
      ))}
    </Flex>
  )
}

export default ChannelsList
