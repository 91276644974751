import { Priority, type Action, useKBar, useMatches } from 'kbar'
import { useCallback, useEffect } from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import { ItemFactory, type KBarItem, type LauncherResults } from './entities'
import { useUser } from '../../common/context/User'
import type { Channel } from '../../common/types'
import { makeChannelActions, selectChannel } from './makeChannelActions'
import type { ChannelActionOverrides } from './useGlobalContextActions'
import { getOrganization } from '../../common/utils/user'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { LAUNCHER_IN_HELP_OBJECT_ID } from './'
import actions from './actions'
import useCreatePostRedirect from '../NavBar/components/GlobalAction/useCreatePostRedirect'
import useCreateIdeaRedirect from '../NavBar/components/GlobalAction/useCreateIdeaRedirect'

interface ChannelAction extends Action {
  channel: Channel
}

export function useHasSeenLauncherInHelpMenu(): boolean {
  const hasLauncherEnabled = useHasLauncherEnabled()
  const user = useUser()
  const hasSeenLauncherInHelpMenu =
    user.currentOrganization?.dismissedObjects?.find(
      (dismissedObject) => dismissedObject.id === LAUNCHER_IN_HELP_OBJECT_ID,
    )
  return (
    !hasSeenLauncherInHelpMenu &&
    !!user.currentOrganization?.id &&
    hasLauncherEnabled
  )
}

export function useHasLauncherEnabled(): boolean {
  const { isEnabled } = useSplitEnabled('quick-navigator')
  return isEnabled
}

export function useGetToggleLauncher(): (() => void) | undefined {
  const isEnabled = useHasLauncherEnabled()
  const { query } = useKBar()
  if (isEnabled) {
    return query.toggle
  }
}

export function useResults(): { results: KBarItem[] } {
  const { results, rootActionId } = useMatches()
  const kbar = useKBar((state) => state)
  // Only show empty state when there's no query and no parent action
  if (kbar.searchQuery.length === 0 && rootActionId === null) {
    return { results: [] }
  }

  return { results }
}

export function useTabNavigation({
  results,
}: {
  results: LauncherResults | KBarItem[]
}): void {
  const kbar = useKBar((state) => state)
  useEffect(() => {
    function handler(event: KeyboardEvent): void {
      if (event.key === 'Tab') {
        // stop tab from moving focus to browser
        event.preventDefault()
        event.stopPropagation()
        const action = ItemFactory.new(
          (results as KBarItem[])[kbar.activeIndex],
          true,
        )
        if (action.isParent) {
          kbar.query.setCurrentRootAction(action.id)
        }
      }
    }

    document.addEventListener('keydown', handler)
    return (): void => {
      document.removeEventListener('keydown', handler)
    }
  }, [kbar, results])
}

export function useGetParentAction(): ChannelAction | null {
  const kbar = useKBar((state) => state)
  if (kbar.currentRootActionId) {
    return kbar.actions[kbar.currentRootActionId] as unknown as ChannelAction
  }

  return null
}

export function useChannels({
  channelActionOverrides,
}: {
  channelActionOverrides: ChannelActionOverrides
}): {
  channelActions: Array<Action | ChannelAction>
} {
  const user = useUser()
  const channels = user.currentOrganization?.channels
  const channelActions = channels?.map((channel: Channel) => {
    return [
      {
        id: `channel-${channel.id}`,
        name: channel.name,
        section: 'channels',
        keywords: `${channel.name} ${channel.service}`,
        perform: (a: Action): void => {
          if (channelActionOverrides['tab/queue']) {
            channelActionOverrides['tab/queue'](a)
            return
          }
          selectChannel(channel)
        },
        priority: Priority.HIGH,
        channel,
      },
      ...makeChannelActions(channel, channelActionOverrides),
    ]
  })
  return { channelActions: channelActions?.flat() || [] }
}

export function useTrackOpened(): void {
  const user = useUser()
  const organization = getOrganization(user)
  const commonTrackingProperties =
    organization?.commonTrackingProperties || null
  useEffect(() => {
    BufferTracker.quickNavigatorOpened({
      organizationId: organization?.id || '',
      ...commonTrackingProperties,
    })
  }, [organization, commonTrackingProperties])
}

export function useTrackSelectedHandler(): (action: KBarItem) => Promise<void> {
  const user = useUser()
  const organization = getOrganization(user)
  const commonTrackingProperties =
    organization?.commonTrackingProperties || null

  const trackSelectedHandler = useCallback(
    async (action: KBarItem): Promise<void> => {
      const actionName = (action as ChannelAction).channel
        ? 'channel'
        : (action as ChannelAction).name.toLowerCase()
      BufferTracker.quickNavigatorActionSelected({
        organizationId: organization?.id || '',
        actionName,
        ...commonTrackingProperties,
      })
      // wait for tracking to finish before performing action (i.e., navigating)
      await new Promise((resolve) => setTimeout(resolve, 300))
    },
    [commonTrackingProperties, organization?.id],
  )

  return trackSelectedHandler
}

export const useActions = (): Action[] => {
  const staticActions = actions
  const { createPostRedirect } = useCreatePostRedirect()
  const { createIdeaRedirect } = useCreateIdeaRedirect()
  const newPostAction = {
    id: 'new-post',
    section: 'go to',
    name: 'New Post',
    keywords: 'create post',
    shortcut: ['n', 'p'],
    perform: (): void => createPostRedirect(),
  }
  const newIdeaAction = {
    id: 'new-idea',
    section: 'go to',
    name: 'New Idea',
    keywords: 'create idea',
    shortcut: ['n', 'i'],
    perform: (): void => createIdeaRedirect(),
  }

  return [...staticActions, newPostAction, newIdeaAction]
}
