import {
  AlphaBadge,
  Flex,
  LinkIcon,
  Notice,
  Text,
  toast,
  Tooltip,
  type TooltipProps,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import * as Dialog from '@radix-ui/react-dialog'
import { Plate, type TEditableProps } from '@udecode/plate'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AIContextualMenu from '~publish/legacy/ai/components/Triggers/AIContextualMenu'
import { renderPlaceholderWithAIButton } from '~publish/legacy/ai/helpers/renderPlaceholderWithAIButton'
import { EmojiPicker } from '~publish/legacy/editor/plugins'

import { useCMDEnterKeys } from '~publish/hooks/useCMDEnterKeys'
import { AIAssistantTrigger } from '~publish/legacy/ai/components/Triggers'
import {
  selectContentGenerationStatus,
  setAIAssistantPlacement,
  toggleAIAssistant,
} from '~publish/legacy/ai/state/slice'
import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'
import type { BufferValue } from '~publish/legacy/editor/BufferEditor/types.plate'
import { EditorErrorBoundary } from '~publish/legacy/editor/components/EditorErrorBoundary'
import {
  EmojiCombobox,
  EmojiPickerIcon,
} from '~publish/legacy/editor/plugins/emoji'
import {
  IntegrationsBar,
  useIntegrationServices,
} from '~publish/legacy/integrations-bar'
import {
  BarButton,
  VerticalDivider,
} from '~publish/legacy/integrations-bar/styles'
import type { IntegrationsContext } from '~publish/legacy/integrations-bar/types'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'
import { TagsSelector } from '~publish/legacy/shared-components'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import {
  MAX_THUMBNAILS,
  MediaManager,
} from '~publish/legacy/uploads/components/MediaManager'
import {
  selectCompletedCount,
  selectIncludedMedia,
  selectPendingCount,
} from '~publish/legacy/uploads/state/selectors'
import { getRandomNumber } from '~publish/legacy/utils/numbers'

// Importing types from Create page is unavoidable for now
import type { Uploader } from '@buffer-mono/uploader'
import { UploadSource } from '@buffer-mono/uploader'
import { useLocation } from 'react-router-dom'
import type { IdeaComposerLocationState } from '~publish/components/IdeaManagementRouter/hooks'
import { SplitProtected } from '~publish/components/SplitProtected'
import { capitalize } from '~publish/helpers/capitalize'
import { getPlainText } from '~publish/legacy/editor/BufferEditor'
import { CreateUploadDropzone } from '~publish/pages/Create/components/CreateUploadDropzone'
import { IDEAS_UPLOADER_ID } from '../constants'
import { isMediaConvertibleToPost } from '../helpers'
import { useIdeaComposerState } from '../IdeaComposer'
import { IDEA_EDITOR_ID } from './createIdeaEditor'
import { useVariableHeight } from './hooks'
import styles from './IdeaEditor.module.css'
import { IdeaGroupSelector } from './IdeaGroupSelector'
import {
  ButtonWrapper,
  HeaderText,
  IdeaTitleInput,
  IdeatorContent,
  IdeatorHeader,
  IntegrationsWrapper,
  MediaManagerWrapper,
  StyledButton,
} from './styles'

const baseEditableProps: TEditableProps<BufferValue> = {
  placeholder: '',
}

const TooltipWrapper = ({ children, ...props }: TooltipProps): JSX.Element => {
  if (!props.content) {
    return <>{children}</>
  }
  return (
    <Tooltip {...props}>
      <div>{children}</div>
    </Tooltip>
  )
}
/**
 * Component responsibile for rendering the core content of the IdeaComposer.
 * It manages most of the state updates to the idea being created or edited.
 * It syncs all changes back to the parent IdeaComposer through context
 * and handles media uploads through a dedicated uploader instance.
 *
 * Accepts a container prop used to portal the Dialog.Content to the desired location.
 *
 * @example
 * ```tsx
 * <IdeaEditor container={document.getElementById('portal-root')} />
 * ```
 */
export const IdeaEditor = ({
  container,
  uploader,
}: {
  container?: HTMLElement | null
  uploader: Uploader
}): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation<IdeaComposerLocationState>()
  const editorContentRef = React.useRef<HTMLDivElement>(null)

  const {
    editor,
    draftIdea,
    saveDraftIdea,
    setGroupId,
    setTitle,
    setText,
    setSelectedTags,
    error,
    isLoading,
    createPostFromIdea,
    constructIdeaComposerUrl,
  } = useIdeaComposerState()

  // This function is passed to Plate to be run on each editor update
  // to update the draftIdea text state
  const updateTextOnEditorValueChange = React.useCallback(
    (editorValue: BufferValue) => {
      const updatedText = getPlainText(editor)
      if (
        editorValue &&
        draftIdea?.content?.text?.trim() !== updatedText?.trim()
      ) {
        setText(updatedText)
      }
    },
    [draftIdea?.content?.text, editor, setText],
  )

  const [ctaPostTooltipLabel, setCtaPostTooltipLabel] = React.useState<
    string | null
  >(null)

  const { contentGenerationInProgress, contentGenerationError } =
    useAppSelector((state) => selectContentGenerationStatus(state))

  const context: IntegrationsContext = 'ideasEditor'

  /** Uploads and Integrations */
  const { services, activeService } = useIntegrationServices({
    uploader,
    context,
  })

  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, IDEAS_UPLOADER_ID),
  )
  const completedCount = useAppSelector((state) =>
    selectCompletedCount(state, IDEAS_UPLOADER_ID),
  )
  const isUploading = pendingCount > 0

  const media = useAppSelector((state) =>
    selectIncludedMedia(state, IDEAS_UPLOADER_ID),
  )

  const onDrop = React.useCallback(
    (files: File[]) => {
      uploader.addFiles(files, { source: UploadSource.dragAndDrop() })
    },
    [uploader],
  )

  const shouldShowNBMigration = useAppSelector(selectShouldShowNBMigration)
  const onOpenAIAssistant = (): void => {
    // Sets the placement (source) for tracking
    // Must be set before the upgrade modal is triggered as the modal
    // relies on the placement for tracking.
    dispatch(setAIAssistantPlacement({ placement: 'ideasEditor' }))

    // Display a upgrade flow for multi-product users
    // Restrict AI Assistant feature to New Buffer users only
    if (shouldShowNBMigration) {
      ModalActionCreators.openModal('AIAssistantMPUpgradePlan', {
        ctaButton: 'integrationsBar',
      })
      return
    }
    dispatch(toggleAIAssistant(true))
  }

  /** Plate Editor */
  const { editorHeightStyles } = useVariableHeight({ completedCount })

  const placeholder = t(`content.editor.placeholder.${getRandomNumber(10)}`)

  baseEditableProps.placeholder = placeholder.concat(' or ')
  baseEditableProps.renderPlaceholder = renderPlaceholderWithAIButton({
    onToggle: onOpenAIAssistant,
  })

  const editableProps: TEditableProps<BufferValue> = useMemo(
    () => ({
      ...baseEditableProps,
      spellCheck: true,
      autoFocus: true,
      readOnly: false,
      'data-draftid': IDEA_EDITOR_ID,
      'data-testid': IDEA_EDITOR_ID,
      style: { height: '100%' },
    }),
    [],
  )

  const createPostButtonDisabled = useMemo(() => {
    const hasMedia = completedCount !== 0
    const hasTextOrMedia = draftIdea?.content?.text || hasMedia

    const { valid: isMediaConvertible, message } =
      isMediaConvertibleToPost(media)

    setCtaPostTooltipLabel(message)
    return (
      isUploading ||
      !hasTextOrMedia ||
      !isMediaConvertible ||
      contentGenerationInProgress ||
      isLoading
    )
  }, [
    draftIdea?.content?.text,
    media,
    contentGenerationInProgress,
    completedCount,
    isUploading,
    isLoading,
  ])

  const saveButtonDisabled = useMemo(() => {
    const hasMedia = completedCount !== 0
    // debugger
    return (
      isUploading ||
      (!draftIdea?.content?.text && !hasMedia && !draftIdea?.content?.title) ||
      contentGenerationInProgress ||
      isLoading
    )
  }, [
    draftIdea?.content?.text,
    isUploading,
    draftIdea?.content?.title,
    completedCount,
    contentGenerationInProgress,
    isLoading,
  ])

  const postTooltipLabel = createPostButtonDisabled
    ? 'Include copy or media to create a post from this Idea'
    : ''

  const saveTooltipLabel = saveButtonDisabled
    ? 'Include a title, copy, or media to save this Idea'
    : ''

  const copyIdeaComposerUrl = async (): Promise<void> => {
    const ideaComposerUrl = constructIdeaComposerUrl()
    await navigator.clipboard.writeText(ideaComposerUrl)
    toast.success('Idea Composer URL copied to your clipboard')
  }

  useCMDEnterKeys(saveDraftIdea, !saveButtonDisabled)

  const errorMessage = contentGenerationError || error?.message
  const composerTitle = location?.state?.action
    ? capitalize(location?.state?.action)
    : draftIdea?.id
    ? 'Edit'
    : 'New'

  return (
    <>
      {errorMessage && (
        <Notice variant="error" className={styles.errorNotice}>
          <Text>{errorMessage}</Text>
        </Notice>
      )}
      <CreateUploadDropzone
        disabled={completedCount + pendingCount >= 10}
        onDrop={onDrop}
      >
        <Flex
          className={styles.ideaEditor}
          direction="column"
          justify="between"
          align="stretch"
        >
          <IdeatorHeader>
            <Dialog.Description asChild>
              <HeaderText>{composerTitle} Idea</HeaderText>
            </Dialog.Description>
            <Flex gap="xs" align="center">
              <IdeaGroupSelector
                selectedGroupId={draftIdea?.groupId}
                onGroupSelect={setGroupId}
              />
              <TagsSelector
                source="ideasComposer"
                preSelectedTags={draftIdea?.content?.tags ?? []}
                onSelectTags={setSelectedTags}
              />
            </Flex>
          </IdeatorHeader>
          <VisuallyHidden as="label" htmlFor="idea-title">
            Idea title
          </VisuallyHidden>
          <IdeaTitleInput
            id="idea-title"
            placeholder="Give your idea a title"
            type="text"
            value={draftIdea?.content?.title ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setTitle(e.target.value)
            }
          />
          <IdeatorContent ref={editorContentRef} style={editorHeightStyles}>
            <EditorErrorBoundary editor={editor}>
              <Plate<BufferValue>
                id="slate-idea-editor"
                editor={editor}
                editableProps={editableProps}
                onChange={updateTextOnEditorValueChange}
              >
                <EmojiCombobox
                  maxSuggestions={8}
                  portalElement={editorContentRef.current ?? undefined}
                />
                <AIContextualMenu editor={editor} placement="ideasEditor" />
              </Plate>
            </EditorErrorBoundary>
          </IdeatorContent>
          <MediaManagerWrapper id="ideas-media-manager">
            <MediaManager
              id="ideas"
              containerRef={container}
              uploader={uploader}
            />
          </MediaManagerWrapper>
          <IntegrationsWrapper>
            <IntegrationsBar
              context={context}
              services={services}
              activeService={activeService}
              disableBar={completedCount + pendingCount >= MAX_THUMBNAILS}
              container={container}
            >
              <VerticalDivider />
              <EmojiPicker editor={editor} parentContainer={container}>
                <BarButton
                  data-testid="emoji-picker-button"
                  aria-label="emoji picker"
                >
                  <EmojiPickerIcon />
                </BarButton>
              </EmojiPicker>
            </IntegrationsBar>
            <VerticalDivider />
            <AIAssistantTrigger onClick={onOpenAIAssistant} />
            <SplitProtected name="CT-copy-idea-composer-url">
              <VerticalDivider />
              <BarButton
                data-testid="copy-idea-composer-url-button"
                aria-label="copy idea composer URL"
                onClick={copyIdeaComposerUrl}
              >
                <LinkIcon />
              </BarButton>
              <AlphaBadge />
            </SplitProtected>
          </IntegrationsWrapper>
          <ButtonWrapper id="ideas-actions">
            <TooltipWrapper content={ctaPostTooltipLabel ?? postTooltipLabel}>
              <StyledButton
                type="secondary"
                label="Create Post"
                onClick={createPostFromIdea}
                disabled={createPostButtonDisabled}
              />
            </TooltipWrapper>
            <TooltipWrapper content={saveTooltipLabel}>
              <StyledButton
                type="primary"
                label="Save Idea"
                onClick={saveDraftIdea}
                disabled={saveButtonDisabled}
              />
            </TooltipWrapper>
          </ButtonWrapper>
        </Flex>
      </CreateUploadDropzone>
    </>
  )
}
