import type { ApolloError } from '@apollo/client'
import { toast } from '@buffer-mono/popcorn'
import React from 'react'
import type {
  LegacyCreateIdeaMutation,
  CreateIdeaPayload,
  Idea as IdeaGeneratedType,
  IdeaResponse,
  LegacyUpdateIdeaMutation,
  UpdateIdeaPayload,
} from '../../gql/graphql'
import { useQueryParam } from '../../hooks/useQueryParam'
import { useOrganizationId } from '../../legacy/accountContext'
import { useAppSelector } from '../../legacy/store'
import { selectIncludedMedia } from '../../legacy/uploads/state/selectors'
import { logError } from '../../legacy/utils/logError'
import { useCreateIdea } from './useCreateIdea'
import { useEditIdea } from './useEditIdea'
import { IDEAS_UPLOADER_ID } from './constants'
import type { DraftIdea } from './IdeaComposer'
import { isExistingIdea } from './helpers'

export type SaveIdeaError = {
  message: string
  code?:
    | 'InvalidInputError'
    | 'UnauthorizedError'
    | 'UnexpectedError'
    | 'LimitReachedError'
    | 'unknown'
}

const DEFAULT_ERROR_MESSAGE =
  'We ran into an error saving your idea. Please try again.'

const isCreateSuccess = (
  result: CreateIdeaPayload,
): result is IdeaGeneratedType | IdeaResponse => {
  return result.__typename === 'Idea' || result.__typename === 'IdeaResponse'
}

const isUpdateSuccess = (result: UpdateIdeaPayload): result is IdeaResponse => {
  return result.__typename === 'IdeaResponse'
}

type UseSaveIdeaProps = {
  onCompleted?: () => void
  onError?: (error: ApolloError) => void
}

type UseSaveIdeaReturn = {
  saveIdea: (idea: DraftIdea) => void
  isLoading: boolean
  error: SaveIdeaError | null
}

/**
 * Convenience wrapper hook to save (create or update) an idea.
 * It wraps the two mutations to handle some of the shared complete/error logic.
 * TODO: This wrapper could probably be removed if we simplify error handling.
 */
export const useSaveIdea = ({
  onCompleted,
  onError,
}: UseSaveIdeaProps): UseSaveIdeaReturn => {
  const organizationId = useOrganizationId()
  const [error, setError] = React.useState<SaveIdeaError | null>(null)
  const [placeAfterId] = useQueryParam('placeAfterId')
  const [ideaComposerSource] = useQueryParam<CTA>('ideaComposerSource')
  const [aiAssistedParam] = useQueryParam('aiAssisted')
  const aiAssisted = aiAssistedParam === 'true'

  const media = useAppSelector((state) =>
    selectIncludedMedia(state, IDEAS_UPLOADER_ID),
  )

  const resetErrorMessage = React.useCallback(() => {
    setTimeout(() => {
      setError(null)
    }, 500000)
  }, [])

  const onMutationError = (error: ApolloError): void => {
    logError(error, { metaData: { organizationId } })
    setError({ message: DEFAULT_ERROR_MESSAGE })
    resetErrorMessage()
    onError?.(error)
  }

  // Idea hooks
  const { createIdea, createIdeaLoading } = useCreateIdea({
    onCompleted: (data: LegacyCreateIdeaMutation) => {
      const result = data.createIdea as CreateIdeaPayload
      if (isCreateSuccess(result)) {
        setError(null)
        onCompleted?.()
        toast.success('Idea created successfully')
      } else {
        setError({
          message: result.message,
          code: result.__typename ?? 'unknown',
        })
        resetErrorMessage()
      }
    },
    onError: onMutationError,
  })

  const { updateIdea, updateIdeaLoading } = useEditIdea({
    onCompleted: (data: LegacyUpdateIdeaMutation) => {
      const result = data.updateIdea as UpdateIdeaPayload
      if (isUpdateSuccess(result)) {
        setError(null)
        onCompleted?.()
        toast.success('Idea updated successfully')
      } else {
        setError({
          message: result.message,
          code: result.__typename ?? 'unknown',
        })
        resetErrorMessage()
      }
    },
    onError: onMutationError,
  })

  const saveIdea = React.useCallback(
    (idea: DraftIdea) => {
      if (!idea) {
        return
      }
      const { groupId } = idea || {}

      // If the current idea is an existing idea, update it
      // otherwise create a new idea
      if (isExistingIdea(idea)) {
        updateIdea(
          {
            ...idea,
            content: {
              ...idea.content,
              media,
              aiAssisted,
            },
          },
          { source: ideaComposerSource },
        )
      } else {
        createIdea({
          content: { ...idea.content, aiAssisted, media },
          groupId,
          placeAfterId,
          source: ideaComposerSource,
        })
      }
    },
    [
      createIdea,
      ideaComposerSource,
      placeAfterId,
      updateIdea,
      media,
      aiAssisted,
    ],
  )

  return {
    saveIdea,
    isLoading: createIdeaLoading || updateIdeaLoading,
    error,
  }
}
