import { isOfType } from '~publish/helpers/typeGuards'
import { GetFeedsForSidebar } from './useFeedsForSidebar'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import { graphql } from '~publish/graphql'
import type {
  FeedConnectionError,
  NotFoundError,
  UnauthorizedError,
  UnexpectedError,
} from '~publish/gql/graphql'

type AllErrors =
  | FeedConnectionError
  | NotFoundError
  | UnauthorizedError
  | UnexpectedError

export type CreateFeedMutationFailureFeedback = {
  title: string
  description: string
  debug?: string
}

export type WrappedCreateFeedResponse =
  | { feedId: string; error: null }
  | { feedId: null; error: CreateFeedMutationFailureFeedback }

export const parseErrorMessage = (
  error: AllErrors,
): CreateFeedMutationFailureFeedback => {
  if (error.__typename === 'FeedConnectionError') {
    return {
      title:
        error.error === 'MalformedResponse'
          ? 'Invalid Feed'
          : 'Couldn’t load Feed',
      description: {
        MalformedResponse:
          'The provided URL does not appear to be a valid RSS or Atom feed. Please double check the feed URL.',
        NotAllowed:
          'Our servers were blocked from loading the feed. Please check that the feed is accessible and try again.',
        Timeout: 'The feed took too long to load. Please try again later.',
        Unknown: 'An error occurred while loading the feed.',
      }[error.error],
      debug: error.error === 'Unknown' ? error.message : undefined,
    }
  }
  if (error.__typename === 'NotFoundError') {
    return {
      title: 'Feed not found',
      description:
        'The provided URL was not found. Please double check the link - it is fairly common for feeds to be moved or deleted.',
    }
  }
  if (error.__typename === 'UnauthorizedError') {
    return {
      title: 'Couldn’t load Feed',
      description:
        'Our servers were blocked from loading the feed. Please check that the feed is accessible and try again.',
    }
  }
  return {
    title: 'Error while loading Feed',
    description:
      'An error occurred while loading the feed. Please try again later or contact support.',
    debug: error.message,
  }
}

const CreateFeed = graphql(/* GraphQL */ `
  mutation CreateFeed($input: CreateFeedInput!) {
    createFeed(input: $input) {
      __typename
      ... on FeedActionSuccess {
        __typename
        feed {
          __typename
          id
          name
          collection {
            id
          }
          connectionMetadata {
            __typename
            ... on RSSFeedConnectionMetadata {
              url
            }
            ... on NewsSearchConnectionMetadata {
              query
            }
          }
          type
        }
      }
      ... on FeedConnectionError {
        __typename
        error
        message
      }
      ... on MutationError {
        __typename
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useCreateFeed = () =>
  useTypedMutation(CreateFeed, (data) => data.createFeed, {
    successTypename: 'FeedActionSuccess',
    refetchQueries: ['GetFeedsForSidebar'],
    update(cache, { data }, { variables }) {
      const result = data?.createFeed
      if (!result || !isOfType(result, 'FeedActionSuccess')) return
      cache.modify({
        // Identify the collection object in the cache
        id: cache.identify({
          __typename: 'FeedCollection',
          id: variables?.input?.collectionId,
        }),
        fields: {
          feeds(existingItemRefs = [], { toReference }) {
            // Convert the new item into a normalized reference
            const newItemRef = toReference(result.feed)

            // Append the new item reference to the existing list
            return [...existingItemRefs, newItemRef]
          },
        },
      })

      if (variables?.input?.organizationId) {
        try {
          // Try to read the current query data
          const queryData = cache.readQuery({
            query: GetFeedsForSidebar,
            variables: { organizationId: variables.input.organizationId },
          })

          if (queryData) {
            // Update the feeds field in the cache
            cache.writeQuery({
              query: GetFeedsForSidebar,
              variables: { organizationId: variables.input.organizationId },
              data: {
                ...queryData,
                feedCollections: {
                  ...queryData.feedCollections,
                  edges: [
                    ...(queryData.feedCollections?.edges || []),
                    {
                      node: {
                        ...result.feed,
                      },
                    },
                  ],
                },
              },
            })
          }
        } catch (error) {
          // If the query isn't in the cache yet, we can ignore this
          console.warn('Could not update feeds cache', error)
        }
      }
    },
  })
