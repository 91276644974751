import React from 'react'
import style from './SettingsPageLayout.module.css'

const SettingsPageLayout = ({
  children,
  wide = false,
}: {
  children: React.ReactNode
  wide?: boolean
}): JSX.Element => {
  return (
    <main className={style.wrapper}>
      <div className={wide ? style.innerWide : style.inner}>{children}</div>
    </main>
  )
}

export default SettingsPageLayout
