import React from 'react'
import { Flex, Skeleton, Card, Text, Button, toast } from '@buffer-mono/popcorn'
import { type FragmentOf, graphql, readFragment } from '~publish/graphql'

export const PaymentDetails_Billing = graphql(/* GraphQL */ `
  fragment PaymentDetails_Billing on Billing {
    paymentDetails {
      hasPaymentDetails
      creditCard {
        brand
        last4
        expMonth
        expYear
      }
      paymentMethod
    }
  }
`)

export const PaymentDetails = ({
  loading,
  data,
}: {
  loading: boolean
  data?: FragmentOf<typeof PaymentDetails_Billing> | null
}): JSX.Element => {
  const paymentDetails = data
    ? readFragment(PaymentDetails_Billing, data).paymentDetails
    : null

  return (
    <>
      <Flex gap="xs" direction="row" align="center">
        Payment Method
        <Button
          size="small"
          variant="secondary"
          onClick={(): void => {
            toast.success('Update method not implemented')
          }}
        >
          Update Method
        </Button>
      </Flex>
      <Skeleton show={loading}>
        <Card>
          {paymentDetails?.hasPaymentDetails ? (
            <>
              <Text>{paymentDetails.creditCard?.brand}</Text>
              <Text>{paymentDetails.creditCard?.last4}</Text>
            </>
          ) : (
            <Text>No payment method</Text>
          )}
        </Card>
      </Skeleton>
    </>
  )
}

export default PaymentDetails
