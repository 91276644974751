import {
  grayDarker,
  grayDark,
  grayLight,
  grayLighter,
} from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  max-width: 512px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2,
  p {
    text-align: left;
  }
  > * {
    box-sizing: border-box;
  }
`

export const TitleSpacing = styled.div`
  margin: 0;
  padding: 0 16px;
  width: 100%;
  text-align: left;
  font-weight: 500;
  h3 {
    margin: 16px 0;
  }
`

export const Body = styled.div`
  justify-content: center;
  padding: 16px;
  width: 100%;
  background: ${grayLighter};
  border-top: 1px solid ${grayLight};
  border-bottom: 1px solid ${grayLight};
  p {
    margin: 0 0 16px;
    color: ${grayDark};
    strong {
      color: ${grayDarker};
    }
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
`

export const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  p {
    margin: 0 0 16px;
  }
  .underline {
    text-decoration: underline;
  }
`

export const Doodle = styled.div`
  flex: 0 1 177px;
  margin-right: 10px;
  height: 185px;
  background-image: url('https://s3.amazonaws.com/static.buffer.com/images/account/fb-auth-illustration-01%402x.png');
  background-size: contain;
  background-repeat: no-repeat;
`

export const CTAContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 1rem;
  flex-direction: row;
  justify-content: right;
`
