import React from 'react'
import styled from 'styled-components'
import { grayDarker, white } from '@bufferapp/ui/style/colors'
import { CloseIcon } from '@buffer-mono/popcorn'

const commonCss = `
  text-align: center;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  padding: 0;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  :before {
    vertical-align: baseline;
  }
  &:hover {
    cursor: pointer;
  }
  :hover {
    transform: scale(1.15);
  }
`

const LightButton = styled.button`
  ${commonCss}
  background: ${white};
  color: ${grayDarker};
`

const ButtonDefault = styled.button`
  ${commonCss}
  background: ${grayDarker};
  color: ${white};
`

const CloseButton = ({
  onClick,
  label = 'Click to close',
  className = '',
  light = false,
}: {
  onClick: (e: React.MouseEvent) => void
  label?: string
  className?: string
  light?: boolean
}): JSX.Element => {
  const onHandleClick = (e: React.MouseEvent): void => {
    e.preventDefault()
    onClick(e)
  }

  return light ? (
    <LightButton
      className={[className, 'closeButtonLight'].join(' ')}
      onClick={onHandleClick}
      aria-label={label}
    >
      <CloseIcon />
    </LightButton>
  ) : (
    <ButtonDefault
      className={className}
      onClick={onHandleClick}
      aria-label={label}
    >
      <CloseIcon />
    </ButtonDefault>
  )
}

export default CloseButton
