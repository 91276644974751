import React from 'react'

import type { Channel } from '~publish/gql/graphql'

import { Card, Flex, Heading, Notice, Text } from '@buffer-mono/popcorn'

import { CommentsList } from '../CommentsList'
import styles from './OverviewPage.module.css'
import { CommentsScore } from '../CommentsScore'

export const OverviewPage = ({
  channel,
}: {
  channel: Channel
}): JSX.Element => {
  return (
    <>
      <Notice variant="info" className={styles.notice}>
        <Notice.Heading>Work in Progress 🏗️</Notice.Heading>
        <Notice.Text>
          We&apos;re actively working on comments! Check back soon for
          engagement metrics, conversation tracking, and superfan
          identification.
        </Notice.Text>
      </Notice>
      <Flex direction="row" gap="lg" className={styles.container}>
        <Flex direction="column" gap="lg" className={styles.leftColumn}>
          <Card className={styles.card}>
            <Heading as="h3" size="medium">
              Featured Comments
            </Heading>
            <div>
              <CommentsList channel={channel} />
            </div>
          </Card>
          <Card className={styles.card}>
            <Heading as="h3" size="medium">
              Conversations
            </Heading>
            <Flex direction="column" className={styles.centerContent}>
              <Text size="md">Conversations will go here</Text>
            </Flex>
          </Card>
          <Card className={styles.card}>
            <Heading as="h3" size="medium">
              Mentions
            </Heading>
            <Flex direction="column" className={styles.centerContent}>
              <Text size="md">Mentions will go here</Text>
            </Flex>
          </Card>
        </Flex>

        <Flex direction="column" gap="lg" className={styles.rightColumn}>
          <Card className={styles.card}>
            <Heading as="h3" size="medium">
              Comments Score
            </Heading>
            <CommentsScore channel={channel} />
          </Card>
          <Card className={styles.card}>
            <Heading as="h3" size="medium">
              Superfans
            </Heading>
            <Text size="md">Superfans will go here</Text>
          </Card>
        </Flex>
      </Flex>
    </>
  )
}
