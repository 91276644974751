import {
  Combobox,
  EmptyState,
  Text,
  SearchIcon,
  ChannelAvatar,
  VisuallyHidden,
  AllChannelsIcon,
  Button,
  PlusIcon,
} from '@buffer-mono/popcorn'
import React from 'react'

import type { FilterByChannel_ChannelFragment } from '~publish/gql/graphql'
import { type FragmentType, graphql, getFragmentData } from '~publish/gql'
import { useAppSelector } from '~publish/legacy/store'

import { FilterButton } from '../FilterButton'

import styles from './FilterByChannel.module.css'
import {
  getAccountChannelsURL,
  getAccountURL,
} from '~publish/legacy/utils/formatters/getURL'

export const FilterByChannel_Channel = graphql(/* GraphQL */ `
  fragment FilterByChannel_Channel on Channel {
    id
    type
    name
    service
    avatar
    serverUrl
    timezone
    locationData {
      location
    }
  }
`)

/**
 * Masked type provided by parent query result.
 * Use `useFilterByChannelFragment` to get the unmasked type.
 */
export type MaskedFilterByChannelFragment = readonly FragmentType<
  typeof FilterByChannel_Channel
>[]

/**
 * Unmasked type extracted from parent query result.
 */
export type FilterByChannelFragment = FilterByChannel_ChannelFragment

/**
 * List of unmasked channel filter fragments extracted from parent query result.
 */
export type FilterByChannelFragments =
  readonly FilterByChannel_ChannelFragment[]

/**
 * Unmask channel filters from the parent query result into typed
 *  fragments.
 * @param channels masked channel filter fragments from the parent query result
 * @returns
 */
export const useFilterByChannelFragment = (
  channels: MaskedFilterByChannelFragment,
): FilterByChannelFragments =>
  getFragmentData(FilterByChannel_Channel, channels)

type FilterByChannelProps = {
  channels: MaskedFilterByChannelFragment
  /**
   * Selected channel ids
   */
  value: string[]
  onSelect: (channelIds: string[]) => void
}

/**
 * Component to enable filtering by channels, using Combobox
 * It exposes fragment to fetch channels, tags array and selected tags
 */
export const FilterByChannel = ({
  channels: passedChannels,
  value,
  onSelect,
}: FilterByChannelProps): JSX.Element => {
  const channels = getFragmentData(FilterByChannel_Channel, passedChannels)
  const profilesIdOrder =
    useAppSelector((state) =>
      state.profileSidebar.profiles.map((profile) => profile.id),
    ) ?? []

  const channelsSorted = Array.from(channels).sort((a, b) => {
    // TODO: Since we don't have order implemented on the GraphQL API,
    // we sort by the order of the profiles in the sidebar stored in Redux.
    // This should go away once we have order implemented on the API.
    // https://linear.app/buffer/issue/CORE-1569/update-accounts-channels-field-resolver-to-sort-channels-by-account
    const aIndex = profilesIdOrder.indexOf(a.id)
    const bIndex = profilesIdOrder.indexOf(b.id)
    if (aIndex === -1) {
      return 1
    }
    if (bIndex === -1) {
      return -1
    }
    return aIndex - bIndex
  })

  return (
    <Combobox multiple value={value} onChange={onSelect}>
      <Combobox.Trigger>
        <FilterButton
          size="medium"
          icon={<AllChannelsIcon />}
          count={value.length}
          variant="tertiary"
        >
          Channels
        </FilterButton>
      </Combobox.Trigger>
      <Combobox.Content>
        {channelsSorted.length > 0 && (
          <Combobox.Input
            placeholder="Search channels"
            prefix={<SearchIcon />}
          />
        )}
        <Combobox.List>
          <Combobox.Empty>
            {channelsSorted.length === 0 ? (
              <EmptyState size="small">
                <EmptyState.Icon variant="primary">
                  <AllChannelsIcon />
                </EmptyState.Icon>
                <EmptyState.Heading>No channels connected</EmptyState.Heading>
                <EmptyState.Description>
                  <Button as="a" href={`${getAccountURL()}/channels`}>
                    <PlusIcon /> Connect a Channel
                  </Button>
                </EmptyState.Description>
              </EmptyState>
            ) : (
              <EmptyState size="small">
                <EmptyState.Icon>
                  <SearchIcon />
                </EmptyState.Icon>
                <EmptyState.Description>
                  No channels found
                </EmptyState.Description>
              </EmptyState>
            )}
          </Combobox.Empty>
          {channelsSorted.map(
            ({ id, name = '', service, avatar, locationData, serverUrl }) => (
              <Combobox.Item
                key={id}
                value={id}
                keywords={[
                  name,
                  service,
                  ...(locationData?.location ? [locationData?.location] : []),
                ]}
              >
                <div className={styles.channel}>
                  <ChannelAvatar
                    size="small"
                    alt=""
                    src={avatar}
                    name={name}
                    channel={service}
                    className={styles.avatar}
                  />
                  <Text weight="medium" className={styles.name}>
                    {name}
                    <VisuallyHidden>({service})</VisuallyHidden>
                  </Text>
                  {locationData?.location && (
                    <Text color="subtle" className={styles.subtitle}>
                      {locationData?.location}
                    </Text>
                  )}
                  {serverUrl && (
                    <Text color="subtle" className={styles.subtitle}>
                      {serverUrl}
                    </Text>
                  )}
                </div>
              </Combobox.Item>
            ),
          )}
        </Combobox.List>

        {channelsSorted.length === 1 && (
          <Combobox.Footer>
            <Button
              as="a"
              href={getAccountChannelsURL()}
              variant="tertiary"
              size="large"
            >
              <PlusIcon /> Connect More Channels
            </Button>
          </Combobox.Footer>
        )}
      </Combobox.Content>
    </Combobox>
  )
}
