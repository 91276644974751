import styled from 'styled-components'

import { gray } from '@bufferapp/ui/style/colors'

export const PostTypeWrapper = styled.div`
  position: relative;
  clear: left;
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ced7df;
  justify-content: space-between;
`

export const RadioLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  margin-right: 16px;

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }: { disabled?: boolean }): string | false | undefined =>
    disabled &&
    `
  cursor: not-allowed;
  color: ${gray};`}
`

export const RadioInput = styled.input`
  margin-right: 6px;
  vertical-align: top;
  &:hover {
    cursor: pointer;
  }
  &[disabled] {
    cursor: not-allowed;
  }
`
