import type { Service } from '~publish/gql/graphql'
import { Service as ServiceEnum } from '~publish/legacy/constants/services/ServiceDefinitions'

export const CanvaLiterals = {
  PublishLabel: 'Add to Buffer',
  FileType: 'png',
  CanvaFilename: 'canva.png',
}

export type CanvaDesignConfig = {
  title: string
  service: Service | 'instagramStory' | 'omni'
  dimensions: {
    width: string
    height: string
    units: string
  }
}

export const StoryCanvaDesignConfig: CanvaDesignConfig = {
  title: 'Instagram Story',
  service: 'instagramStory',
  dimensions: {
    width: '1080',
    height: '1920',
    units: 'px',
  },
}

export const OmniCanvaDesignConfig: CanvaDesignConfig = {
  title: 'New Image',
  service: 'omni',
  dimensions: {
    width: '1200',
    height: '1200',
    units: 'px',
  },
}
export const CanvaDesignConfigValues: CanvaDesignConfig[] = [
  OmniCanvaDesignConfig,
  StoryCanvaDesignConfig,
  {
    title: 'Instagram',
    service: ServiceEnum.Instagram,
    dimensions: {
      width: '1080',
      height: '1350',
      units: 'px',
    },
  },
  {
    title: 'Facebook',
    service: ServiceEnum.Facebook,
    dimensions: {
      width: '940',
      height: '788',
      units: 'px',
    },
  },
  {
    title: 'Twitter',
    service: ServiceEnum.Twitter,
    dimensions: {
      width: '1600',
      height: '900',
      units: 'px',
    },
  },
  {
    title: 'Mastodon',
    service: ServiceEnum.Mastodon,
    dimensions: {
      width: '1600',
      height: '900',
      units: 'px',
    },
  },
  {
    title: 'LinkedIn',
    service: ServiceEnum.Linkedin,
    dimensions: {
      width: '1200',
      height: '627',
      units: 'px',
    },
  },
  {
    title: 'Pinterest',
    service: ServiceEnum.Pinterest,
    dimensions: {
      width: '1000',
      height: '1500',
      units: 'px',
    },
  },
  {
    title: 'TikTok',
    service: ServiceEnum.Tiktok,
    dimensions: {
      width: '1080',
      height: '1920',
      units: 'px',
    },
  },
  {
    title: 'Google Business Profile',
    service: ServiceEnum.Googlebusiness,
    dimensions: {
      width: '1200',
      height: '900',
      units: 'px',
    },
  },
  {
    title: 'Start Page Profile',
    service: ServiceEnum.StartPage,
    dimensions: {
      width: '1080',
      height: '1080',
      units: 'px',
    },
  },
  {
    title: 'Threads',
    service: ServiceEnum.Threads,
    dimensions: {
      width: '1080',
      height: '1350',
      units: 'px',
    },
  },
  {
    title: 'Bluesky',
    service: ServiceEnum.Bluesky,
    dimensions: {
      width: '1080',
      height: '1350',
      units: 'px',
    },
  },
]
