import React from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import {
  AudienceIcon,
  Button,
  DropdownMenu,
  FileTextIcon,
  IdeasIcon,
  AllChannelsIcon,
  Link,
  PlusIcon,
  StartPageIcon,
  Text,
  UpgradeBadge,
  CoachMark,
  useMediaQuery,
  IconButton,
} from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import {
  getAccountUrl,
  getTeamManageUrl,
  getStartPageUrl,
} from '../../../../common/utils/urls'
import { useUser } from '../../../../common/context/User'

import useCreateIdeaRedirect from './useCreateIdeaRedirect'
import useCreatePostRedirect from './useCreatePostRedirect'
import useCreatorCampCoachMark from './useCreatorCampCoachMark'
import {
  isEssentialsUser,
  isFreeUser,
  isOneBufferOrganization,
  userCanStartFreeTrial,
} from '../../../../common/utils/user'
import { setConnectChannelUpgrade } from '../../../../exports/Orchestrator/store/channelConnectionsReducer'

import { MODALS } from '../../../../common/types'
import { getActiveProductFromPath } from '../../../../common/utils/getProduct'
import { shouldShowConnectChannelUpgradePath } from './utils'
import trackGlobalPlusButtonOpened from '../../../../tracking/GlobalPlusButton/trackGlobalPlusButtonOpened'
import trackGlobalPlusButtonItemClicked from '../../../../tracking/GlobalPlusButton/trackGlobalPlusButtonItemClicked'

import styles from './GlobalAction.module.css'
import { ORCHESTRATOR_ACTIONS } from '../../../../common/events/orchestratorEvents'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'
import { MEDIA_QUERIES } from '../../../../common/constants/mediaQueries'

const TRACKING_PAYLOAD = {
  ctaView: 'navBar',
  ctaLocation: 'globalPlus',
  ctaButton: 'New',
  ctaVersion: '1',
}

const GlobalAction = (): JSX.Element => {
  const dispatch = useDispatch()
  const { openModal } = useModalManager()
  const user = useUser()
  const { createPostRedirect } = useCreatePostRedirect()
  const { createIdeaRedirect } = useCreateIdeaRedirect()

  const { isEnabled: isResponsiveNavEnabled } = useSplitEnabled(
    'responsive-main-nav',
  )

  const isTabletLarge = useMediaQuery(
    `(width < ${MEDIA_QUERIES.TABLET_LARGE}px)`,
  )

  const shouldUseSmallSize = isTabletLarge && isResponsiveNavEnabled

  const connectChannelCTA = `${getActiveProductFromPath()}-navBar-globalPlus-connectChannel-1`

  const showConnectChannelUpgradePath =
    shouldShowConnectChannelUpgradePath(user)
  const showInviteTeamUpgradePath = isFreeUser(user) || isEssentialsUser(user)
  const showCreateIdeaUpgradePath = !isOneBufferOrganization(user)
  const canStartTrial = userCanStartFreeTrial(user)

  const manageTeamLink = getTeamManageUrl(user)
  const createStartPageLink = getStartPageUrl('onboarding')
  const addChannelLink = getAccountUrl(
    `channels/connect?globalActionCTA=${connectChannelCTA}`,
  )

  const { isEnabled: isNewChannelStoreFrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  function handleMainButtonClick(isOpen: boolean): void {
    if (isOpen) {
      trackGlobalPlusButtonOpened({
        user,
        payload: TRACKING_PAYLOAD,
      })
    }
  }

  function handleSubItemClick(ctaButton: string): void {
    const upgradePathNameType = canStartTrial ? 'trial' : 'upgrade'
    const PAYLOAD = {
      ctaView: 'navBar',
      ctaLocation: 'globalPlus',
      ctaButton,
      ctaVersion: '1',
      upgradePathName: `globalPlus-${upgradePathNameType}`,
    }

    trackGlobalPlusButtonItemClicked({
      user,
      payload: PAYLOAD,
    })
  }

  function handleConnectChannel(): void {
    handleSubItemClick('newChannel')

    if (showConnectChannelUpgradePath) {
      dispatch(setConnectChannelUpgrade({}))
    } else {
      if (isNewChannelStoreFrontEnabled) {
        ORCHESTRATOR_ACTIONS.openChannelStorefront({
          cta: connectChannelCTA,
        })
      } else {
        window.location.assign(addChannelLink)
      }
    }
  }

  function handleCreateStartPage(): void {
    handleSubItemClick('startPage')
    window.location.assign(createStartPageLink)
  }

  function handleInviteTeam(): void {
    handleSubItemClick('inviteTeam')

    if (showInviteTeamUpgradePath) {
      openModal({
        key: MODALS.planSelector,
        data: {
          cta: `${getActiveProductFromPath()}-navBar-globalPlus-inviteTeam-1`,
          upgradePathName: 'globalPlus-upgrade',
        },
      })
    } else {
      window.location.assign(manageTeamLink)
    }
  }

  const {
    isCoachMarkEnabled,
    coachMarkTitle,
    coachMarkContent,
    coachMarkLink,
    onCoachMarkDismiss,
  } = useCreatorCampCoachMark()

  const dropdownMenu = (
    <DropdownMenu modal={false} onOpenChange={handleMainButtonClick}>
      <DropdownMenu.Trigger>
        {shouldUseSmallSize ? (
          <IconButton size="large" variant="primary" label="New">
            <PlusIcon />
          </IconButton>
        ) : (
          <Button size="large" variant="primary">
            <PlusIcon />
            New
          </Button>
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={styles.menu}>
        <DropdownMenu.Item
          className={styles.dropdownItem}
          onClick={(): void => {
            handleSubItemClick('post')
            createPostRedirect()
          }}
        >
          <FileTextIcon
            className={clsx(styles.dropdownItemIcon, styles.iconPost)}
            color="brand"
            size="medium"
          />
          <div className={styles.dropdownItemLabel}>
            <Text as="label" weight="medium">
              Post
            </Text>
            <Text as="small" color="subtle" size="sm" lineHeight="tight">
              Publish content to a channel
            </Text>
          </div>
          <div className={clsx(styles.dropdownItemEnd, styles.shortcutKey)}>
            <span>N</span>
            <span>P</span>
          </div>
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={styles.dropdownItem}
          onClick={(): void => {
            handleSubItemClick('idea')
            createIdeaRedirect()
          }}
        >
          <IdeasIcon
            color="success"
            size="medium"
            className={clsx(styles.dropdownItemIcon, styles.iconIdea)}
          />
          <div className={styles.dropdownItemLabel}>
            <Text as="label" weight="medium">
              Idea
            </Text>
            <Text as="small" color="subtle" size="sm" lineHeight="tight">
              Capture a content idea
            </Text>
          </div>
          {showCreateIdeaUpgradePath ? (
            <span className={styles.dropdownItemEnd}>
              <UpgradeBadge
                data-testid="connect-channel__upgrade-icon"
                size="small"
                className={styles.upgradeBadge}
              />
            </span>
          ) : (
            <div className={clsx(styles.dropdownItemEnd, styles.shortcutKey)}>
              <span>N</span>
              <span>I</span>
            </div>
          )}
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
        <DropdownMenu.Item
          className={styles.dropdownItem}
          onClick={handleConnectChannel}
        >
          <AllChannelsIcon className={styles.dropdownItemIcon} size="small" />
          <Text className={styles.dropdownItemLabel} weight="medium">
            Connect a New Channel
          </Text>
          {showConnectChannelUpgradePath && (
            <span className={styles.dropdownItemEnd}>
              <UpgradeBadge
                data-testid="connect-channel__upgrade-icon"
                size="small"
                className={styles.upgradeBadge}
              />
            </span>
          )}
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={styles.dropdownItem}
          onClick={(): void => handleCreateStartPage()}
        >
          <StartPageIcon className={styles.dropdownItemIcon} size="small" />
          <Link href={createStartPageLink} className={styles.dropdownItemLabel}>
            <Text weight="medium">Create a Start Page</Text>
          </Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className={styles.dropdownItem}
          onClick={handleInviteTeam}
        >
          <AudienceIcon className={styles.dropdownItemIcon} size="small" />
          <Text className={styles.dropdownItemLabel} weight="medium">
            Invite a Team Member{' '}
          </Text>
          {showInviteTeamUpgradePath && (
            <span className={styles.dropdownItemEnd}>
              <UpgradeBadge
                data-testid="invite-team__upgrade-icon"
                size="small"
                className={styles.upgradeBadge}
              />
            </span>
          )}
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  )

  return isCoachMarkEnabled ? (
    <div className={styles.coachMarkWrapper}>
      <CoachMark onDismiss={(): void => onCoachMarkDismiss()}>
        <CoachMark.Overlay>{dropdownMenu}</CoachMark.Overlay>
        <div className={styles.coachMarkSpotlight}>
          <CoachMark.Spotlight color="inverted" />
        </div>
        <CoachMark.Content>
          <CoachMark.Title>{coachMarkTitle}</CoachMark.Title>
          <CoachMark.Description>
            {coachMarkContent}.{' '}
            {coachMarkLink && (
              <Link href={coachMarkLink} external={true}>
                Learn more
              </Link>
            )}
          </CoachMark.Description>
          <CoachMark.Footer>
            <CoachMark.Dismiss>
              <Button>Got it</Button>
            </CoachMark.Dismiss>
          </CoachMark.Footer>
        </CoachMark.Content>
      </CoachMark>
    </div>
  ) : (
    dropdownMenu
  )
}

export default GlobalAction
