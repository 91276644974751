import React, { useCallback, useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import LocationFinder from './utils/LocationFinder'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import AppStore from '../../stores/AppStore'
import * as ReminderFieldsStyled from '~publish/legacy/reminders/components/new-reminders/components/forms/field/styles'
import type { ServiceLocation } from '~publish/legacy/composer/composer/components/location-bar/entities/ServiceLocation'
import { ChevronDownIcon, Popover } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'
import styles from './LocationComposerBar.module.css'

interface LocationOption {
  value: string
  label: string
  formattedAddress?: string
}

interface LocationComposerBarProps {
  draft: Draft
}

const getSelectedInstagramProfileId = (): string => {
  const selectedProfiles = AppStore.getSelectedProfiles().filter(
    (profile) => profile.service.name === 'instagram',
  )
  return selectedProfiles.length > 0 ? selectedProfiles[0].id : ''
}

const LocationComposerBar: React.FC<LocationComposerBarProps> = ({ draft }) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<LocationOption[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] =
    useState<LocationOption | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const profileId = getSelectedInstagramProfileId()

  useEffect(() => {
    const locationId =
      draft?.channelData?.instagram?.service_geolocation_id ?? null
    const locationName =
      draft?.channelData?.instagram?.service_geolocation_name ?? ''
    if (locationId && locationName) {
      setSelectedLocation({ value: locationId, label: locationName })
      setInputValue(locationName)
    }
  }, [draft])

  const loadOptions = useCallback(
    async (input: string) => {
      if (!input) {
        return []
      }

      if (!profileId) {
        return []
      }

      try {
        const locations = await LocationFinder.findLocations(profileId, input)
        return locations.map((loc: ServiceLocation) => ({
          value: loc.id,
          label: `${loc.name} - ${loc.formattedAddress}`,
          formattedAddress: loc.formattedAddress,
        }))
      } catch (error) {
        console.error('Failed to load locations:', error)
        return []
      }
    },
    [profileId],
  )

  const debouncedLoadOptions = useCallback(
    debounce(async (input: string) => {
      const newOptions = await loadOptions(input)
      setOptions(newOptions)
      setIsOpen(true)
    }, 300),
    [loadOptions],
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setInputValue(value)
    setSelectedLocation(null) // Clear selected location when input changes
    debouncedLoadOptions(value)
  }

  const handleOptionClick = (option: LocationOption): void => {
    setSelectedLocation(option)
    setInputValue(option.label)
    setIsOpen(false)
    inputRef.current?.focus()
    ComposerActionCreators.updateDraftInstagramData({
      ...draft.channelData?.instagram,
      service_geolocation_id: option.value,
      service_geolocation_name: option.label,
    })
  }

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
        // Revert to selected location if available, or clear if not
        if (selectedLocation) {
          setInputValue(selectedLocation.label)
        } else {
          setInputValue('')
        }
      }
    },
    [selectedLocation],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  // New composer preview mode Split check
  // We consider the new preview mode enabled if the user is in the preview mode and the split is enabled
  const { isEnabled: isNewPreviewSplitEnabled } = useSplitEnabled(
    'geid-new-composer-preview',
  )
  const isNewPreviewModeEnabled =
    AppStore.getIsPreviewMode() && isNewPreviewSplitEnabled
  const profile = AppStore.getProfile(profileId)

  if (
    !draft.service?.isInstagram() ||
    draft.isStoryPost() ||
    isNewPreviewModeEnabled ||
    profile?.apiVersion === 'instagramLogin' ||
    profile?.serviceType === 'profile'
  ) {
    return null
  }

  return (
    <ReminderFieldsStyled.Wrapper>
      <ReminderFieldsStyled.LabelWrapper>
        <ReminderFieldsStyled.Label>Location</ReminderFieldsStyled.Label>
      </ReminderFieldsStyled.LabelWrapper>
      <ReminderFieldsStyled.InputWrapper>
        <Popover open={isOpen}>
          <Popover.Anchor>
            <input
              className={styles.input}
              ref={inputRef}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={(event): void => {
                if (event.key === 'Escape') {
                  event.stopPropagation()
                  setIsOpen(false)
                }

                if (event.key === 'Tab') {
                  dropdownRef.current?.focus()
                }
              }}
              placeholder="Type the location"
            />
          </Popover.Anchor>
          <ChevronDownIcon className={styles.chevronIcon} />
          {isOpen && options.length > 0 && (
            <Popover.Content
              onOpenAutoFocus={(event): void => {
                event.preventDefault()
              }}
              className={styles.locationDropdown}
              ref={dropdownRef}
              onKeyDown={(event): void => {
                if (event.key === 'Escape') {
                  event.stopPropagation()
                  setIsOpen(false)
                  inputRef.current?.focus()
                }
              }}
            >
              {options.map((option) => (
                <button
                  className={styles.locationOption}
                  key={option.value}
                  onClick={(): void => handleOptionClick(option)}
                >
                  {option.label}
                </button>
              ))}
            </Popover.Content>
          )}
        </Popover>
      </ReminderFieldsStyled.InputWrapper>
    </ReminderFieldsStyled.Wrapper>
  )
}

export default LocationComposerBar
