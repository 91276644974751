import React from 'react'

import { getProductPriceCycleText } from '../../../../../common/utils/product'

import {
  CurrentPlanInfoContainer,
  PlanName,
  ChannelsCount,
  UsersCount,
  CurrentPaymentContainer,
  Row,
} from './CurrentPlanInfo.style'

interface CurrentPlanInfoProps {
  planName: string
  planPrice: number
  planCycle: string
  numberOfChannels: number
  numberOfUsers: string
}

const CurrentPlanInfo = (props: CurrentPlanInfoProps): JSX.Element => {
  const { planName, planPrice, planCycle, numberOfChannels, numberOfUsers } =
    props

  return (
    <CurrentPlanInfoContainer>
      <PlanName role="textbox" aria-label="Current plan">
        Your current plan: <span>{planName}</span>
      </PlanName>
      <Row>
        <ChannelsCount>{numberOfChannels} channels</ChannelsCount>
        <UsersCount>{numberOfUsers}</UsersCount>
      </Row>
      <CurrentPaymentContainer>
        Currently paying:{' '}
        <span>{getProductPriceCycleText(planPrice, planCycle)}</span>
      </CurrentPaymentContainer>
    </CurrentPlanInfoContainer>
  )
}

export default CurrentPlanInfo
