export const feedSuggestions = [
  {
    title: 'Design Milk',
    category: 'Art & Media',
    url: 'https://design-milk.com/feed/',
    thumbnailUrl: 'https://design-milk.com/img/favicon/dm-favicon-192-192.png',
    link: 'https://design-milk.com',
  },
  {
    title: 'Colossal',
    category: 'Art & Media',
    url: 'https://www.thisiscolossal.com/feed/',
    thumbnailUrl:
      'https://www.thisiscolossal.com/wp-content/uploads/2024/08/icon-crow-150x150.png',
    link: 'https://thisiscolossal.com',
  },
  {
    title: "It's Nice That",
    category: 'Art & Media',
    url: 'https://feeds2.feedburner.com/itsnicethat/SlXC',
    thumbnailUrl: 'https://www.itsnicethat.com/apple-touch-icon.png',
    link: 'https://itsnicethat.com',
  },
  {
    title: 'Booooooom',
    category: 'Art & Media',
    url: 'https://www.booooooom.com/feed/',
    thumbnailUrl: undefined,
    link: 'https://www.booooooom.com/',
  },
  {
    title: 'Contemporary Art Daily',
    category: 'Art & Media',
    url: 'https://www.contemporaryartdaily.com/feed/',
    thumbnailUrl: 'https://www.contemporaryartdaily.com/logo5-nav.png',
    link: 'https://contemporaryartdaily.com/',
  },
  {
    title: 'Post Secret',
    category: 'Art & Media',
    url: 'https://postsecret.com/feed/',
    thumbnailUrl:
      'https://i0.wp.com/postsecret.com/wp-content/uploads/2020/08/ps_favicon.jpg?fit=32%2C32&ssl=1',
    link: 'https://postsecret.com/',
  },
  {
    title: 'Ignant',
    category: 'Art & Media',
    url: 'https://www.ignant.com/feed/',
    thumbnailUrl:
      'https://www.ignant.com/android-chrome-512x512.png?v=kP3MA599J8',
    link: 'https://www.ignant.com/',
  },
  {
    title: "this isn't happiness",
    category: 'Art & Media',
    url: 'https://feeds.feedburner.com/thisisnthappiness',
    thumbnailUrl: 'https://x818.com/faviconisn.ico',
    link: 'https://thisisnthappiness.com/',
  },
  {
    title: 'Hyperallergic',
    category: 'Art & Media',
    url: 'https://hyperallergic.com/feed/',
    thumbnailUrl:
      'https://hyperallergic-newspack.s3.amazonaws.com/uploads/2020/11/cropped-Hyperallergic-favicon-100x100.png',
    link: 'https://hyperallergic.com/',
  },
  {
    title: 'ArtsJournal',
    category: 'Art & Media',
    url: 'https://www.artsjournal.com/feed',
    thumbnailUrl:
      'https://www.artsjournal.com/belknap/wp-content/uploads/2021/06/AJcircleicon-smn-150x150.webp',
    link: 'https://www.artsjournal.com/',
  },
  {
    title: 'Art and Design - The New York Times',
    category: 'Art & Media',
    url: 'https://www.nytimes.com/svc/collections/v1/publish/https:/www.nytimes.com/section/arts/design/rss.xml',
    thumbnailUrl:
      'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    link: 'https://www.nytimes.com/section/arts/design',
  },
  {
    title: 'ARTNews',
    category: 'Art & Media',
    url: 'https://www.artnews.com/feed/rss/',
    thumbnailUrl:
      'https://www.artnews.com/wp-content/themes/vip/pmc-artnews-2019/assets/app/icons/favicon.png',
    link: 'https://www.artnews.com/',
  },
  {
    title: 'Hi-Fructose Magazine',
    category: 'Art & Media',
    url: 'https://hifructose.com/feed/',
    thumbnailUrl:
      'https://hifructose.com/wp-content/uploads/2023/10/hi-fructose-logo-fancy-150x150.png',
    link: 'https://hifructose.com/',
  },
  {
    title: 'Artsy',
    category: 'Art & Media',
    url: 'https://www.artsy.net/rss/news',
    thumbnailUrl: 'https://d1s2w0upia4e9w.cloudfront.net/images/icon-512.png',
    link: 'https://www.artsy.net/',
  },
  {
    title: 'Engadget',
    category: 'Tech',
    url: 'https://www.engadget.com/rss.xml',
    thumbnailUrl: 'https://s.yimg.com/kw/assets/eng-e-558x270.png',
    link: 'https://www.engadget.com/',
  },
  {
    title: 'The Verge',
    category: 'Tech',
    url: 'https://www.theverge.com/rss/index.xml',
    thumbnailUrl:
      'https://www.theverge.com/static-assets/icons/android-chrome-512x512.png',
    link: 'https://www.theverge.com/',
  },
  {
    title: 'Wired',
    category: 'Tech',
    url: 'https://www.wired.com/feed/rss',
    thumbnailUrl: 'https://www.wired.com/apple-touch-icon-152x152.png',
    link: 'https://www.wired.com/',
  },
  {
    title: 'TechCrunch',
    category: 'Tech',
    url: 'https://techcrunch.com/feed/',
    thumbnailUrl:
      'https://techcrunch.com/wp-content/uploads/2015/02/cropped-cropped-favicon-gradient.png?w=32',
    link: 'https://techcrunch.com/',
  },
  {
    title: 'Lifehacker',
    category: 'Tech',
    url: 'https://lifehacker.com/feed/rss',
    thumbnailUrl: 'https://lifehacker.com/images/apple-touch-icon.png',
    link: 'https://lifehacker.com/',
  },
  {
    title: 'Gizmodo',
    category: 'Tech',
    url: 'https://gizmodo.com/feed',
    thumbnailUrl: 'https://gizmodo.com/android-chrome-512x512.png',
    link: 'https://gizmodo.com/',
  },
  {
    title: 'Mashable',
    category: 'Tech',
    url: 'https://mashable.com/feeds/rss/all',
    thumbnailUrl: 'https://mashable.com/favicons/android-chrome-512x512.png',
    link: 'https://mashable.com/',
  },
  {
    title: 'Fast Company',
    category: 'Tech',
    url: 'https://feeds.feedburner.com/fastcompany/headlines',
    thumbnailUrl:
      'https://www.fastcompany.com/asset_files/static/logos/fastcompany/fc-fb-icon_big.png',
    link: 'https://www.fastcompany.com/',
  },
  {
    title: 'Ars Technica',
    category: 'Tech',
    url: 'https://feeds.arstechnica.com/arstechnica/features',
    thumbnailUrl:
      'https://cdn.arstechnica.net/wp-content/uploads/2016/10/cropped-ars-logo-512_480-60x60.png',
    link: 'https://arstechnica.com/',
  },
  {
    title: 'The Keyword - Google',
    category: 'Tech',
    url: 'https://blog.google/rss/',
    thumbnailUrl: 'https://blog.google/static/blogv2/images/google.png',
    link: 'https://blog.google/',
  },
  {
    title: 'MacRumors',
    category: 'Tech',
    url: 'https://feeds.macrumors.com/MacRumors-All',
    thumbnailUrl: 'https://www.macrumors.com/manifest/iconb-512x512.png',
    link: 'https://www.macrumors.com/',
  },
  {
    title: 'Android Central',
    category: 'Tech',
    url: 'https://www.androidcentral.com/feed',
    thumbnailUrl:
      'https://cdn.mos.cms.futurecdn.net/flexiimages/m3uyybbdos1632303243.png',
    link: 'https://www.androidcentral.com/',
  },
  {
    title: 'TED Talks',
    category: 'Tech',
    url: 'https://feeds.feedburner.com/tedtalks_video',
    thumbnailUrl:
      'https://pl.tedcdn.com/rss_feed_images/ted_talks_main_podcast/video.png',
    link: 'https://www.ted.com/talks',
  },
  {
    title: 'Slashdot',
    category: 'Tech',
    url: 'https://rss.slashdot.org/Slashdot/slashdotMain',
    thumbnailUrl: 'https://rss.slashdot.org/apple-touch-icon.png',
    link: 'https://slashdot.org/',
  },
  {
    title: 'VentureBeat',
    category: 'Tech',
    url: 'https://feeds.feedburner.com/venturebeat/SZYF',
    thumbnailUrl:
      'https://venturebeat.com/wp-content/themes/vb-news/brand/img/logos/VB_Extended_Logo_40H.png',
    link: 'https://venturebeat.com/',
  },
  {
    title: 'TheNextWeb',
    category: 'Tech',
    url: 'https://feeds2.feedburner.com/thenextweb',
    thumbnailUrl:
      'https://next.tnwcdn.com/assets/img/favicon/favicon-194x194.png',
    link: 'https://thenextweb.com/',
  },
  {
    title: 'Hackaday',
    category: 'Tech',
    url: 'https://hackaday.com/blog/feed',
    thumbnailUrl:
      'https://hackaday.com/wp-content/themes/hackaday-2/img/hackaday-logo_1024x1024.png?v=3',
    link: 'https://hackaday.com',
  },
  {
    title: 'Make Magazine',
    category: 'Tech',
    url: 'https://makezine.com/feed/',
    thumbnailUrl:
      'https://i0.wp.com/makezine.com/wp-content/uploads/2018/06/favicon-32x32.png?fit=32%2C32&ssl=1',
    link: 'https://makezine.com/',
  },
  {
    title: 'MIT Technology Review',
    category: 'Tech',
    url: 'https://www.technologyreview.com/feed/',
    thumbnailUrl:
      'https://wp.technologyreview.com/wp-content/uploads/2024/09/cropped-TR-Logo-Block-Centered-R.png?w=32',
    link: 'https://technologyreview.com',
  },
  {
    title: 'Hacker News',
    category: 'Tech',
    url: 'https://news.ycombinator.com/rss',
    thumbnailUrl: 'https://news.ycombinator.com/y18.svg',
    link: 'https://news.ycombinator.com/',
  },
  {
    title: '9to5Mac',
    category: 'Tech',
    url: 'https://9to5mac.com/feed/',
    thumbnailUrl:
      'https://9to5mac.com/wp-content/uploads/sites/6/2019/10/cropped-cropped-mac1-1.png?w=32',
    link: 'https://9to5mac.com/',
  },
  {
    title: 'Cnet',
    category: 'Tech',
    url: 'https://www.cnet.com/rss/news/',
    thumbnailUrl:
      'https://gcp-assets-origin-fly.cnet.com/bundles/cnetcss/images/core/logo/cnet_og.png',
    link: 'https://www.cnet.com/',
  },
  {
    title: 'Technology - The New York Times',
    category: 'Tech',
    url: 'https://www.nytimes.com/svc/collections/v1/publish/https:/www.nytimes.com/section/technology/rss.xml',
    thumbnailUrl:
      'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    link: 'https://www.nytimes.com/section/technology',
  },
  {
    title: 'Business Insider',
    category: 'Business',
    url: 'https://feeds2.feedburner.com/businessinsider',
    thumbnailUrl:
      'https://www.businessinsider.com/public/assets/BI/US/favicons/android-chrome-512x512.png?v=2023-11',
    link: 'https://www.businessinsider.com/',
  },
  {
    title: 'Fast Company',
    category: 'Business',
    url: 'https://feeds.feedburner.com/fastcompany/headlines',
    thumbnailUrl:
      'https://www.fastcompany.com/asset_files/static/logos/fastcompany/fc-fb-icon_big.png',
    link: 'https://www.fastcompany.com/',
  },
  {
    title: 'Harvard Business Review',
    category: 'Business',
    url: 'https://hbr.org/resources/xml/atom/tip.xml',
    thumbnailUrl: 'https://hbr.org/resources/css/images/hbr_logo.svg',
    link: 'https://hbr.org/',
  },
  {
    title: "Seth's Blog",
    category: 'Business',
    url: 'https://seths.blog/feed/atom/',
    thumbnailUrl:
      'https://seths.blog/wp-content/themes/godin/img/favicons/apple-touch-icon.png',
    link: 'https://seths.blog/',
  },
  {
    title: 'Entrepreneur',
    category: 'Business',
    url: 'https://www.entrepreneur.com/rss-feed/latest',
    thumbnailUrl:
      'https://assets.entrepreneur.com/static/20210208112149-ENT-logo-blk.png',
    link: 'https://www.entrepreneur.com/',
  },
  {
    title: 'The World This Week | The Economist',
    category: 'Business',
    url: 'https://www.economist.com/the-world-this-week/rss.xml',
    thumbnailUrl: 'https://www.economist.com/engassets/google-search-logo.png',
    link: 'https://www.economist.com/the-world-this-week/',
  },
  {
    title: 'The Hubspot Marketing Blog',
    category: 'Business',
    url: 'https://blog.hubspot.com/marketing/rss.xml',
    thumbnailUrl:
      'https://www.hubspot.com/hubfs/HubSpot_Logos/HubSpot-Inversed-Favicon.png',
    link: 'https://blog.hubspot.com/marketing',
  },
  {
    title: 'Time',
    category: 'Business',
    url: 'https://time.com/feed/',
    thumbnailUrl: 'https://time.com/img/favicons/favicon-192.png',
    link: 'https://time.com/',
  },
  {
    title: 'VentureBeat',
    category: 'Business',
    url: 'https://feeds.feedburner.com/venturebeat/SZYF',
    thumbnailUrl:
      'https://venturebeat.com/wp-content/themes/vb-news/brand/img/logos/VB_Extended_Logo_40H.png',
    link: 'https://venturebeat.com/',
  },
  {
    title: 'Copyblogger',
    category: 'Business',
    url: 'https://copyblogger.com/feed/',
    thumbnailUrl:
      'https://copyblogger.com/wp-content/themes/copyblogger_5.0/images/favicon.ico',
    link: 'https://copyblogger.com/',
  },
  {
    title: 'Inc. Magazine',
    category: 'Business',
    url: 'https://www.inc.com/rss',
    thumbnailUrl: 'https://images.inc.com/rss/inc_com_rss.jpg',
    link: 'https://www.inc.com/',
  },
  {
    title: 'Business - The New York Times',
    category: 'Business',
    url: 'https://www.nytimes.com/svc/collections/v1/publish/https:/www.nytimes.com/section/business/rss.xml',
    thumbnailUrl:
      'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    link: 'https://www.nytimes.com/section/business',
  },
  {
    title: 'Tim Ferris',
    category: 'Business',
    url: 'https://tim.blog/feed/',
    thumbnailUrl:
      'https://i0.wp.com/tim.blog/wp-content/uploads/2019/12/cropped-site-icon-tim-ferriss-2.png?fit=32%2C32&ssl=1',
    link: 'https://tim.blog/',
  },
  {
    title: 'Small Business Trends',
    category: 'Business',
    url: 'https://feeds.feedburner.com/SmallBusinessTrends',
    thumbnailUrl: 'https://smallbiztrends.com/favicon.ico',
    link: 'https://smallbiztrends.com/',
  },
  {
    title: 'BBC News',
    category: 'News',
    url: 'https://feeds.bbci.co.uk/news/rss.xml',
    thumbnailUrl:
      'https://news.bbcimg.co.uk/nol/shared/img/bbc_news_120x60.gif',
    link: 'https://www.bbc.co.uk/news',
  },
  {
    title: 'The New York Times',
    category: 'News',
    url: 'https://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml',
    thumbnailUrl:
      'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    link: 'https://nytimes.com/',
  },
  {
    title: 'The Guardian',
    category: 'News',
    url: 'https://www.theguardian.com/uk/rss',
    thumbnailUrl:
      'https://assets.guim.co.uk/images/guardian-logo-rss.c45beb1bafa34b347ac333af2e6fe23f.png',
    link: 'https://www.theguardian.com/',
  },
  {
    title: 'NPR',
    category: 'News',
    url: 'https://feeds.npr.org/1001/rss.xml',
    thumbnailUrl:
      'https://media.npr.org/images/podcasts/primary/npr_generic_image_300.jpg?s=200',
    link: 'https://www.npr.org/',
  },
  {
    title: 'The World This Week | The Economist',
    category: 'News',
    url: 'https://www.economist.com/the-world-this-week/rss.xml',
    thumbnailUrl:
      'https://www.economist.com/engassets/ico/apple-touch-icon.png',
    link: 'https://www.economist.com/the-world-this-week/',
  },
  {
    title: 'Time',
    category: 'News',
    url: 'https://time.com/feed/',
    thumbnailUrl: 'https://time.com/img/favicons/favicon-192.png',
    link: 'https://time.com/',
  },
  {
    title: 'Slate Magazine',
    category: 'News',
    url: 'https://slate.com/feeds/all.rss',
    thumbnailUrl: 'https://slate.com/media/sites/slate-com/icon.512x512.png',
    link: 'https://slate.com/',
  },
  {
    title: 'The Atlantic',
    category: 'News',
    url: 'https://www.theatlantic.com/feed/all/',
    thumbnailUrl:
      'https://cdn.theatlantic.com/_next/static/images/apple-touch-icon-152x152-aafde20dd981a38fcd549b29b2b3b785.png',
    link: 'https://www.theatlantic.com/',
  },
  {
    title: 'Yahoo News',
    category: 'News',
    url: 'https://news.yahoo.com/rss',
    thumbnailUrl:
      'https://l.yimg.com/rz/d/yahoo_news_en-US_s_f_p_168x21_news.png',
    link: 'https://www.yahoo.com/news',
  },
  {
    title: 'ABC News',
    category: 'News',
    url: 'https://abcnews.go.com/abcnews/topstories',
    thumbnailUrl:
      'https://s.abcnews.com/images/site/abcnews_google_rss_logo.png',
    link: 'https://abcnews.go.com/',
  },
  {
    title: 'Culture - The New Yorker',
    category: 'News',
    url: 'https://www.newyorker.com/feed/culture',
    thumbnailUrl: 'https://www.newyorker.com/apple-touch-icon-152x152.png',
    link: 'https://www.newyorker.com/culture',
  },
  {
    title: 'Vice',
    category: 'News',
    url: 'https://www.vice.com/en/feed/',
    thumbnailUrl:
      'https://www.vice.com/wp-content/uploads/sites/2/2024/06/cropped-site-icon-1.png?w=32',
    link: 'https://www.vice.com/en/',
  },
  {
    title: 'Politico',
    category: 'News',
    url: 'https://rss.politico.com/politics-news.xml',
    thumbnailUrl:
      'https://static.politico.com/6b/bb/dfaa58914f269b548734c4d73842/apple-touch-icon.png?v=2',
    link: 'https://politico.com',
  },
  {
    title: 'Vox',
    category: 'News',
    url: 'https://www.vox.com/rss/index.xml',
    thumbnailUrl:
      'https://www.vox.com/static-assets/icons/android-chrome-512x512.png',
    link: 'https://vox.com',
  },
  {
    title: 'The Washington Post',
    category: 'News',
    url: 'https://feeds.washingtonpost.com/rss/world',
    thumbnailUrl: 'https://www.washingtonpost.com/touch-icon-iphone-retina.png',
    link: 'https://www.washingtonpost.com/world/',
  },
  {
    title: 'Al Jazeera',
    category: 'News',
    url: 'https://www.aljazeera.com/xml/rss/all.xml',
    thumbnailUrl: 'https://www.aljazeera.com/images/logo_aje.png',
    link: 'https://aljazeera.com',
  },
  {
    title: 'Buzzfeed',
    category: 'Entertainment',
    url: 'https://www.buzzfeed.com/index.xml',
    thumbnailUrl:
      'https://webappstatic.buzzfeed.com/static/images/public/rss/logo.png',
    link: 'https://www.buzzfeed.com/',
  },
  {
    title: 'Rolling Stone',
    category: 'Entertainment',
    url: 'https://www.rollingstone.com/feed/rss/',
    thumbnailUrl:
      'https://www.rollingstone.com/wp-content/uploads/2024/12/R-Avatar_512x512_flat.png?w=32',
    link: 'https://www.rollingstone.com/',
  },
  {
    title: 'SlashFilm',
    category: 'Entertainment',
    url: 'https://feeds.feedburner.com/slashfilm',
    thumbnailUrl:
      'https://www.slashfilm.com/img/slash-film-favicon-448x448.png',
    link: 'https://www.slashfilm.com/',
  },
  {
    title: 'IndieWire',
    category: 'Entertainment',
    url: 'https://www.indiewire.com/feed/rss/',
    thumbnailUrl:
      'https://www.indiewire.com/wp-content/uploads/2018/04/cropped-iw_favicon.png?w=32',
    link: 'https://www.indiewire.com/',
  },
  {
    title: 'TMZ.com',
    category: 'Entertainment',
    url: 'https://www.tmz.com/rss.xml',
    thumbnailUrl:
      'https://assets.tmz.com/www.tmz.com/main/default/cache/images/feedlogo2.v2014_05_09_134122.gif',
    link: 'https://tmz.com',
  },
  {
    title: 'Cracked.com',
    category: 'Entertainment',
    url: 'https://feeds.feedburner.com/CrackedRSS',
    thumbnailUrl:
      'https://ui-seo-1.crackedcdn.com/images/favicon/apple-touch-icon.png?v=bf463fe7b8c0b5b2ce038c9ee5164e28',
    link: 'https://www.cracked.com/',
  },
  {
    title: 'Deadline.com',
    category: 'Entertainment',
    url: 'https://deadline.com/feed/rss/',
    thumbnailUrl:
      'https://deadline.com/wp-content/uploads/2019/04/cropped-icon-512x512.png?w=32',
    link: 'https://deadline.com/',
  },
  {
    title: 'Movies - The New York Times',
    category: 'Entertainment',
    url: 'https://www.nytimes.com/svc/collections/v1/publish/https:/www.nytimes.com/section/movies/rss.xml',
    thumbnailUrl:
      'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    link: 'https://www.nytimes.com/section/movies',
  },
  {
    title: 'Vulture',
    category: 'Entertainment',
    url: 'https://feeds.feedburner.com/nymag/vulture',
    thumbnailUrl:
      'https://assets.vulture.com/media/sites/vulture/icon.196x196.png',
    link: 'https://www.vulture.com/',
  },
  {
    title: 'Variety',
    category: 'Entertainment',
    url: 'https://variety.com/feed/rss/',
    thumbnailUrl:
      'https://variety.com/wp-content/uploads/2018/06/variety-favicon.png?w=32',
    link: 'https://variety.com/',
  },
  {
    title: "Ain't It Cool News",
    category: 'Entertainment',
    url: 'https://www.aintitcool.com/node/feed/',
    thumbnailUrl: 'https://www.aintitcool.com/favicon.ico',
    link: 'https://www.aintitcool.com/',
  },
  {
    title: 'Nerdist',
    category: 'Entertainment',
    url: 'https://nerdist.com/feed/',
    thumbnailUrl:
      'https://nerdist.com/wp-content/uploads/2018/08/cropped-apple-touch-icon-152x152_preview-32x32.png',
    link: 'https://nerdist.com/',
  },
  {
    title: 'Wired',
    category: 'Science',
    url: 'https://www.wired.com/feed/rss',
    thumbnailUrl: 'https://www.wired.com/apple-touch-icon-152x152.png',
    link: 'https://www.wired.com/',
  },
  {
    title: 'Scientific American',
    category: 'Science',
    url: 'https://www.scientificamerican.com/platform/syndication/rss/',
    thumbnailUrl: 'https://www.scientificamerican.com/favicon.ico',
    link: 'https://www.scientificamerican.com/',
  },
  {
    title: 'Science Journal',
    category: 'Science',
    url: 'https://www.science.org/rss/news_current.xml',
    thumbnailUrl: 'https://www.atypon.com/images/atypon_logo_small.gif',
    link: 'https://science.org',
  },
  {
    title: 'MIT Technology Review',
    category: 'Science',
    url: 'https://www.technologyreview.com/feed/',
    thumbnailUrl:
      'https://wp.technologyreview.com/wp-content/uploads/2024/09/cropped-TR-Logo-Block-Centered-R.png?w=32',
    link: 'https://technologyreview.com',
  },
  {
    title: 'Nature',
    category: 'Science',
    url: 'https://www.nature.com/nature.rss',
    thumbnailUrl: 'https://www.nature.com/uploads/product/nature/rss.png',
    link: 'https://nature.com',
  },
  {
    title: 'New Scientist',
    category: 'Science',
    url: 'https://www.newscientist.com/feed/home/',
    thumbnailUrl:
      'https://www.newscientist.com/build/images/ns-logo-scaled.ed2dc11a.png',
    link: 'https://newscientist.com',
  },
  {
    title: 'ScienceDaily',
    category: 'Science',
    url: 'https://www.sciencedaily.com/rss/all.xml',
    thumbnailUrl: undefined,
    link: 'https://sciencedaily.com',
  },
  {
    title: 'Space.com',
    category: 'Science',
    url: 'https://www.space.com/feeds.xml',
    thumbnailUrl: 'https://www.space.com/apple-touch-icon.png',
    link: 'https://space.com',
  },
  {
    title: 'Science - The New York Times',
    category: 'Science',
    url: 'https://www.nytimes.com/svc/collections/v1/publish/https:/www.nytimes.com/section/science/rss.xml',
    thumbnailUrl:
      'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    link: 'https://nytimes.com',
  },
  {
    title: 'Information is Beautiful',
    category: 'Science',
    url: 'https://feeds.feedburner.com/InformationIsBeautiful',
    thumbnailUrl:
      'https://informationisbeautiful.net/android-chrome-512x512.png',
    link: 'https://informationisbeautiful.net',
  },
  {
    title: 'Futurity',
    category: 'Science',
    url: 'https://www.futurity.org/feed/',
    thumbnailUrl:
      'https://www.futurity.org/wp/wp-content/uploads/2018/08/futurity_ico-70x70.png',
    link: 'https://futurity.org',
  },
  {
    title: 'Phys.org',
    category: 'Science',
    url: 'https://phys.org/rss-feed/',
    thumbnailUrl: 'https://sciencex.com/gfx/shared/phys_icon_192.png',
    link: 'https://phys.org',
  },
  {
    title: 'Futurism',
    category: 'Science',
    url: 'https://futurism.com/feed',
    thumbnailUrl: 'https://futurism.com/favicon.png',
    link: 'https://futurism.com',
  },
  {
    title: 'Science and Technology | The Economist',
    category: 'Science',
    url: 'https://www.economist.com/science-and-technology/rss.xml',
    thumbnailUrl: 'https://www.economist.com/favicon.ico',
    link: 'https://economist.com',
  },
  {
    title: 'Creator Science',
    category: 'Buffer Favorites',
    url: 'https://creatorscience.com/rss/',
    thumbnailUrl: 'https://creatorscience.com/favicon.png',
    link: 'https://creatorscience.com/',
  },
  {
    title: "Lindey Gamble's Newsletter",
    category: 'Buffer Favorites',
    url: 'https://rss.beehiiv.com/feeds/3W8Cmlhot6.xml',
    thumbnailUrl:
      'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80/uploads/publication/logo/03814cd3-1939-4076-89ee-5c53cb819018/Lindsey_Gamble.png',
    link: 'https://lindseygamble.beehiiv.com/',
  },
  {
    title: 'Passionfruit',
    category: 'Buffer Favorites',
    url: 'https://passionfru.it/feed/',
    thumbnailUrl:
      'https://fp01.imgix.net/sites/5/2022/05/cropped-9NZerGV3-Icon.png?auto=compress&fit=fit&fm=jpg&h=32&w=32',
    link: 'https://passionfru.it/',
  },
  {
    title: 'ICYMI by Lia Haberman',
    category: 'Buffer Favorites',
    url: 'https://liahaberman.substack.com/feed',
    thumbnailUrl:
      'https://substackcdn.com/image/fetch/w_256,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F65902fdd-d477-4ac0-8b71-f23650c5f0fc_360x360.png',
    link: 'https://liahaberman.substack.com/',
  },
  {
    title: 'Link in Bio',
    category: 'Buffer Favorites',
    url: 'https://www.milkkarten.net/feed',
    thumbnailUrl:
      'https://substackcdn.com/image/fetch/w_256,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F59825d03-06f8-49a7-bc7f-afe611e7f28f_1280x1280.png',
    link: 'https://www.milkkarten.net/',
  },
  {
    title: 'Geekout Newsletter',
    category: 'Buffer Favorites',
    url: 'https://rss.beehiiv.com/feeds/e2fyt8op5j.xml',
    thumbnailUrl:
      'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80/uploads/publication/logo/f9337118-ad64-4fbc-898c-05c6259cceb1/Geekout_Newsletter_Square_Avatar_800x800.jpeg',
    link: 'https://geekout.mattnavarra.com/',
  },
  {
    title: 'SparkToro Blog',
    category: 'Buffer Favorites',
    url: 'https://sparktoro.com/blog/feed/',
    thumbnailUrl: 'https://sparktoro.com/apple-touch-icon-180x180.png',
    link: 'https://sparktoro.com/blog',
  },
]
