import React from 'react'
import { gql } from '@apollo/client'
import { toast } from '@buffer-mono/popcorn'

import type { CommentData } from '~publish/gql/graphql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import { useFilters } from '../CommentsFilters/useFilters'

const ReplyToCommentGQL = gql`
  mutation ReplyToComment($input: ReplyToCommentInput!) {
    replyToComment(input: $input) {
      __typename
      ... on ReplyToCommentSuccess {
        comment {
          ... on BaseComment {
            id
            text
            publishedAt
            status
            parentId
            author {
              username
              avatar
              __typename
            }
            assets {
              id
              source
              thumbnail
              type
              mimeType
            }
          }
          ... on CommentFromBuffer {
            id
            text
            author {
              username
              avatar
            }
            publishedAt
            status
            assets {
              id
              type
              source
              thumbnail
            }
          }
          ... on CommentFromThirdParty {
            id
            text
            author {
              username
              avatar
            }
            publishedAt
            status
            assets {
              id
              type
              source
              thumbnail
            }
          }
        }
      }
      ... on NotFoundError {
        message
      }
      ... on UnauthorizedError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`

type UseReplyToCommentReturn = {
  replyToComment: (
    comment: CommentData,
    text: string,
  ) => Promise<CommentData | undefined>
  loading: boolean
  error?: Error
}

type UpdateContext = {
  originalComment: CommentData
}

export const useReplyToComment = (): UseReplyToCommentReturn => {
  const [{ status }] = useFilters()

  const [mutate, { loading, error }] = useTypedMutation(
    ReplyToCommentGQL,
    (data) => data.replyToComment,
    {
      successTypename: 'ReplyToCommentSuccess',
      update: (cache, { data }, options) => {
        const result = data?.replyToComment
        if (!result || result.__typename !== 'ReplyToCommentSuccess') {
          return
        }

        const newReply = result.comment
        if (!newReply) {
          return
        }

        // Get the parent comment from the context
        const originalComment = (options?.context as UpdateContext)
          ?.originalComment
        if (!originalComment) {
          return
        }

        const lastReply = {
          id: newReply.id,
          text: newReply.text,
          organizationId: originalComment.organizationId,
          serviceType: originalComment.serviceType,
          publishedAt: newReply.publishedAt,
          parentId: newReply.parentId,
          status: newReply.status,
          isOwnReply: true,
          author: {
            username: newReply.author.username,
            name: newReply.author.username,
            avatar: newReply.author.avatar,
          },
          assets: newReply.assets || [],
        }

        // 1. Update the comment in the cache with the new status and lastReply
        cache.modify({
          id: cache.identify({
            __typename: originalComment.__typename,
            id: originalComment.id,
          }),
          fields: {
            status: () => 'replied',
            lastReply: () => lastReply,
          },
        })

        // 2. Decide if the comment should be removed from the current view
        const shouldRemoveFromList = status === 'unreplied'

        if (shouldRemoveFromList) {
          // Find all comments queries in the cache and update them
          setTimeout(() => {
            cache.modify({
              fields: {
                comments: (existingComments, { readField, storeFieldName }) => {
                  const match = storeFieldName.match(/"status":\["(.*?)"\]/)
                  const statusInStore = match ? match[1] : null

                  // Skip if this isn't the current filter's query
                  if (statusInStore !== status) {
                    return existingComments
                  }

                  // Filter out the comment from the edges array
                  const newEdges = existingComments.edges.filter(
                    (edge: any) => {
                      const commentId = readField('id', readField('node', edge))
                      return commentId !== originalComment.id
                    },
                  )

                  // Return the new list without the dismissed comment
                  return {
                    ...existingComments,
                    edges: newEdges,
                  }
                },
              },
            })
          }, 300)
        }
      },
    },
  )

  const replyToComment = React.useCallback(
    async (
      comment: CommentData,
      text: string,
    ): Promise<CommentData | undefined> => {
      try {
        const result = await mutate({
          variables: {
            input: {
              commentId: comment.id,
              reply: {
                text,
              },
            },
          },
          context: {
            originalComment: comment,
          },
        })

        if (!result.success) {
          throw new Error(result.error.message)
        }

        toast.success('Reply sent successfully')

        return result.data.comment
      } catch (error) {
        console.error('Error replying to comment:', error)
        toast.error('An error occurred while replying to the comment')
      }
    },
    [mutate],
  )

  return {
    replyToComment,
    loading,
    error,
  }
}
