import { connect } from 'react-redux'
import HashtagGroupWrapper from './components/HashtagGroupWrapper'
import { actions } from './reducer'
import type { HashtagGroup } from './types'
import type { AppDispatch, RootState } from '../store/index'

export default connect(
  (state: RootState) => ({
    hashtagGroups: state.hashtagGroups.groups,
    name: state.hashtagGroups.name,
    text: state.hashtagGroups.text,
    snippetId: state.hashtagGroups.snippetId,
  }),
  (
    dispatch: AppDispatch,
    ownProps: {
      onInsertHashtagGroupClick?: (name: string, text: string) => void
    },
  ) => ({
    onCancelHashtagGroup: (): void => {
      dispatch(actions.handleCancelHashtagGroupClick())
    },
    onSaveHashtagGroup: (): void => {
      dispatch(actions.handleSaveHashtagGroupClick())
    },
    onUpdateHashtagGroup: (): void => {
      dispatch(actions.handleUpdateHashtagGroupClick())
    },
    onDeleteHashtagGroup: (group: HashtagGroup): void => {
      dispatch(
        actions.handleDeleteHashtagGroup({
          name: group.name,
          text: group.text,
          snippetId: group._id,
        }),
      )
    },
    onEditHashtagGroup: (group: HashtagGroup): void => {
      dispatch(
        actions.handleEditHashtagGroup({
          name: group.name,
          text: group.text,
          snippetId: group._id,
        }),
      )
    },
    onChangeGroupName: (name: string): void => {
      dispatch(
        actions.handleChangeGroupName({
          name,
        }),
      )
    },
    onChangeGroupText: (text: string): void => {
      dispatch(
        actions.handleChangeGroupText({
          text,
        }),
      )
    },
    onHandleInsertHashtagGroupClick: (name: string, text: string): void => {
      ownProps.onInsertHashtagGroupClick?.(name, text)

      dispatch(actions.handleInsertHashtagGroupClick({ name, text }))
    },
  }),
)(HashtagGroupWrapper)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
