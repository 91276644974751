import React from 'react'
import styled from 'styled-components'
import type { Video } from '~publish/legacy/composer/composer/entities/factories'

import * as Styles from './styles'

import { VideoPlayer } from '../../../Common/VideoPlayer'

const StyledVideoPlayer = styled(VideoPlayer)`
  ${Styles.Wrapper}
`

const AttachmentVideo = ({ video }: { video: Video }): JSX.Element => {
  return <StyledVideoPlayer video={video} />
}

export { AttachmentVideo }
