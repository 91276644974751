import type { RootState } from '~publish/legacy/store'
import type { GeneralSettingsState, TrackingSettings } from './reducer'
import type { LinkShortener } from './types'

export const selectGeneralSettings = (
  state: RootState,
): GeneralSettingsState => {
  return state?.generalSettings
}

export const selectIsLinkShorteningEnabled = (state: RootState): boolean => {
  return state?.generalSettings?.linkShortening.linkShorteners.some(
    (linkShortener) =>
      linkShortener.selected && linkShortener.domain !== 'No Shortening',
  )
}

export const selectIsBitlyConnected = (state: RootState): boolean => {
  const bitly = state?.generalSettings?.linkShortening.linkShorteners.find(
    (linkShortener) => linkShortener.domain === 'bit.ly',
  )

  // bitly is the one that's selected
  if (bitly?.selected) return true

  const selected = state?.generalSettings?.linkShortening.linkShorteners.find(
    (linkShortener) => linkShortener.selected,
  )

  // none selected
  if (!selected) return false

  // the one that is selected is the same account as bitly - will likely have a
  // custom domain, but the account is still a bitly account
  const hasLogin = typeof bitly?.login === 'string' && bitly?.login.length > 0
  return selected.login === bitly?.login && hasLogin
}

export const selectLinkShorteners = (state: RootState): LinkShortener[] => {
  return state?.generalSettings.linkShortening.linkShorteners
}

export const selectTrackingSettings = (
  state: RootState,
): TrackingSettings | undefined => {
  return state?.generalSettings?.trackingSettings
}

export const selectRemindersAreEnabled = (state: RootState): boolean => {
  return state?.generalSettings?.remindersAreEnabled
}
