import React from 'react'
import clsx from 'clsx'

import { ImageIcon, PlayFilledIcon } from '@buffer-mono/popcorn'

import type { Asset } from '~publish/gql/graphql'
import { MediaMarker } from '~publish/components/MediaMarker'

import styles from './CommentMediaAsset.module.css'

type CommentMediaAssetProps = {
  asset: Asset
  className?: string
}

export const getIcon = (asset: Asset): JSX.Element | null => {
  if (asset.type === 'video') {
    return <PlayFilledIcon />
  }

  return null
}

export const CommentMediaAsset = ({
  asset,
  className,
}: CommentMediaAssetProps): JSX.Element | null => {
  const [hasError, setHasError] = React.useState(false)

  const handleImageError = (): void => {
    setHasError(true)
  }

  if (hasError) {
    return (
      <div className={clsx(styles.asset, styles.error, className)}>
        <div className={styles.fallback}>
          <ImageIcon size="medium" />
        </div>
      </div>
    )
  }

  return (
    <div className={clsx(styles.asset, className)}>
      <img
        src={asset.thumbnail}
        alt={asset.type}
        className={styles.thumbnail}
        loading="lazy"
        onError={handleImageError}
      />
      {getIcon(asset) && (
        <MediaMarker className={styles.badge} aria-hidden>
          {getIcon(asset)}
        </MediaMarker>
      )}
    </div>
  )
}
