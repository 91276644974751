export type Profile = {
  id: string
  service: string
  reconnecting: boolean
  profile_username: string
  formatted_username: string
  avatar_https: string
  handle: string
  service_type: string
  api_version?: string
}

export enum ChannelType {
  business = 'business',
  page = 'page',
  profile = 'profile',
  group = 'group',
  account = 'account',
  channel = 'channel',
}

export enum ChannelDisplayType {
  business = 'Business',
  page = 'Page',
  profile = 'Profile',
  group = 'Group',
  account = 'Account',
  channel = 'Channel',
}
