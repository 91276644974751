import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  Flex,
  Heading,
  CounterInput,
  Paragraph,
  toast,
  useDebounceCallback,
} from '@buffer-mono/popcorn'

import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'

import { useUpdateChannelPostingGoal } from '../../hooks/useUpdateChannelPostingGoal'

import commonStyles from '../../PostingSchedule.module.css'

export function PostingGoals(): JSX.Element {
  const { postingGoal, id: channelId } = useSelectedChannel() ?? {}
  const lastToast = useRef<string | number | null>(null)

  const [updateChannelPostingGoal] = useUpdateChannelPostingGoal()
  const [goal, setGoal] = useState(postingGoal?.goal ?? 0)

  useEffect(() => {
    setGoal(postingGoal?.goal ?? 0)
  }, [postingGoal])

  const submitGoal = useDebounceCallback(async (goal: number) => {
    try {
      const { success, error } = await updateChannelPostingGoal({
        variables: {
          input: {
            channelId,
            goal,
          },
        },
      })

      if (!success) {
        toast.error(`Failed to set posting goal: ${error.message}`)
      }

      if (lastToast.current) {
        toast.dismiss(lastToast.current)
      }

      if (goal === 0) {
        lastToast.current = toast.success('Your posting goal was removed.', {
          duration: 5000,
        })
      } else {
        lastToast.current = toast.success('Your posting goal is set.', {
          duration: 5000,
        })
      }
    } catch (error) {
      setGoal(postingGoal?.goal ?? 0)
      toast.error('Failed to set posting goal.')
    }
  }, 1000)

  useEffect(
    function executePendingGoalUpdates() {
      return () => {
        if (submitGoal.isPending()) {
          submitGoal.flush()
        }
      }
    },
    [submitGoal],
  )

  const clickSetPostingGoal = (): void => {
    const { actions } = window.appshell ?? {}
    actions.openModal('postingGoalConfiguration', {
      channelId,
    })
  }

  const handleGoalChange = (value: number): void => {
    setGoal(value)
    submitGoal(value)
  }

  return (
    <Flex
      justify="between"
      align="center"
      style={{ width: '100%' }}
      data-testid="timezone-form-wrapper"
    >
      <Flex direction="column">
        <Heading size="small" as="h3" id="posting-goal-heading">
          Posting Goal
        </Heading>
        <Paragraph className={commonStyles.postingScheduleParagraph}>
          Choose how often you aim to post on this channel each week.
        </Paragraph>
      </Flex>
      <Flex direction="row" align="center" gap="xs">
        {postingGoal ? (
          <CounterInput
            value={goal}
            min={0}
            max={100}
            step={1}
            onChange={handleGoalChange}
            aria-labelledby="posting-goal-heading"
          />
        ) : (
          <Button variant="secondary" onClick={clickSetPostingGoal}>
            Set Posting Goal
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
