import { useCallback } from 'react'
import type { Temporal } from '@js-temporal/polyfill'

import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'
import { useSelectedDate } from './useSelectedDate'
import {
  useCalendarDateRangeViewMode,
  type CalendarDateRangeViewMode,
} from './useCalendarDateRangeViewMode'
import { useLocalStorage, useCachedMemo } from '@buffer-mono/popcorn'
import { getDateRange } from '~publish/helpers/temporal'

const DEFAULT_TIMEZONE = getCurrentTimeZone()

/**
 * Interface representing the information and functions returned by the useCalendar hook.
 */
interface CalendarInfo {
  selectedDate: Temporal.ZonedDateTime
  viewMode: CalendarDateRangeViewMode
  startDate: Temporal.ZonedDateTime
  endDate: Temporal.ZonedDateTime
  /** Function to navigate to today's date */
  navigateToToday: () => void
  /** Function to navigate to the previous week or month */
  navigateToPrevious: () => void
  /** Function to navigate to the next week or month */
  navigateToNext: () => void
  /** Function to toggle between week and month view */
  toggleViewMode: (mode?: CalendarDateRangeViewMode) => void
}

type Options = {
  timestamp?: number
  weekStartsOn?: 0 | 1
  timezone?: string
}

export const LAST_VIEW_MODE_LOCAL_STORAGE_KEY = 'calendar-view-mode'
export const DEFAULT_VIEW_MODE = 'week'
/**
 * A custom React hook that provides logic for the calendar component.
 * It manages the selected date, view mode, date range, and navigation functions.
 *
 * @param {Object} options - The options for the calendar.
 * @param {number} [options.timestamp] - Initial timestamp in milliseconds since epoch.
 * @param {0 | 1} [options.weekStart=0] - Day of the week to start on (0 for Sunday, 1 for Monday).
 * @returns {CalendarInfo} An object containing calendar state and functions.
 */
export const useCalendar = (options: Options = {}): CalendarInfo => {
  const {
    timestamp,
    weekStartsOn = 0,
    timezone = DEFAULT_TIMEZONE,
  } = {
    ...options,
  }
  const [viewMode = DEFAULT_VIEW_MODE, setViewMode] =
    useCalendarDateRangeViewMode()
  const [selectedDate, setSelectedDate] = useSelectedDate(timestamp, {
    timezone,
  })

  // Calculate the date range based on the selected date, view mode, and week start
  const dateRange = useCachedMemo(
    () => getDateRange(selectedDate, viewMode, weekStartsOn),
    (oldRange, newRange) =>
      oldRange.start.equals(newRange.start) &&
      oldRange.end.equals(newRange.end),
    [selectedDate, viewMode, weekStartsOn],
  )

  const navigateToToday = useCallback((): void => {
    setSelectedDate(undefined) // This will reset the selected date to today's date
  }, [setSelectedDate])

  const navigateToPrevious = useCallback((): void => {
    setSelectedDate(
      viewMode === 'week'
        ? selectedDate.subtract({ weeks: 1 })
        : selectedDate.subtract({ months: 1 }),
    )
  }, [selectedDate, viewMode, setSelectedDate])

  const navigateToNext = useCallback((): void => {
    setSelectedDate(
      viewMode === 'week'
        ? selectedDate.add({ weeks: 1 })
        : selectedDate.add({ months: 1 }),
    )
  }, [selectedDate, viewMode, setSelectedDate])

  const [, setLastViewMode] = useLocalStorage<CalendarDateRangeViewMode>(
    LAST_VIEW_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_VIEW_MODE,
  )

  const toggleViewMode = useCallback(
    (viewMode?: CalendarDateRangeViewMode): void => {
      setViewMode((prev) => viewMode || (prev === 'week' ? 'month' : 'week'))
      setLastViewMode(
        (prev) => viewMode || (prev === 'week' ? 'month' : 'week'),
      )
    },
    [setLastViewMode, setViewMode],
  )

  return {
    selectedDate,
    viewMode,
    startDate: dateRange.start,
    endDate: dateRange.end,
    navigateToToday,
    navigateToPrevious,
    navigateToNext,
    toggleViewMode,
  }
}
