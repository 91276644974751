export const ActionTypes = {
  APP_RESET: 'APP_RESET',
  APP_RECEIVE_USER_DATA: 'APP_RECEIVE_USER_DATA',
  APP_RECEIVE_ORGANIZATIONS_DATA: 'APP_RECEIVE_ORGANIZATIONS_DATA',
  APP_RECEIVE_METADATA: 'APP_RECEIVE_METADATA',
  APP_RECEIVE_OPTIONS: 'APP_RECEIVE_OPTIONS',
  APP_RECEIVE_CONNECTED_CHANNELS: 'APP_RECEIVE_CONNECTED_CHANNELS',
  APP_RECEIVE_IMAGE_DIMENSIONS_KEY: 'APP_RECEIVE_IMAGE_DIMENSIONS_KEY',
  APP_RECEIVE_TWITTER_AUTOCOMPLETE_BOOTSTRAP_DATA:
    'APP_RECEIVE_TWITTER_AUTOCOMPLETE_BOOTSTRAP_DATA',
  COMPOSER_HIDE_SUGGESTED_MEDIA: 'COMPOSER_HIDE_SUGGESTED_MEDIA',
  COMPOSER_UPDATE_DRAFTS_THREAD: 'COMPOSER_UPDATE_DRAFTS_THREAD',
  COMPOSER_UPDATE_AI_ASSISTED: 'COMPOSER_UPDATE_AI_ASSISTED',
  COMPOSER_UPDATE_DRAFT_ADD_POST_TO_THREAD:
    'COMPOSER_UPDATE_DRAFT_ADD_POST_TO_THREAD',
  COMPOSER_UPDATE_POST_TEXT: 'COMPOSER_UPDATE_POST_TEXT',
  COMPOSER_TRANSITION_FINISHED: 'COMPOSER_TRANSITION_FINISHED',
  COMPOSER_SET_ACTIVE_THREAD_ID: 'COMPOSER_SET_ACTIVE_THREAD_ID',
  COMPOSER_SWITCH_ACTIVE_THREAD_EDITOR: 'COMPOSER_SWITCH_ACTIVE_THREAD_EDITOR',
  COMPOSER_DELETE_THREADED_DRAFT: 'COMPOSER_DELETE_THREADED_DRAFT',
  COMPOSER_UPDATE_DRAFT_MEDIA_ORDER: 'COMPOSER_UPDATE_DRAFT_MEDIA_ORDER',
  COMPOSER_CREATE_PROFILES: 'COMPOSER_CREATE_PROFILES',
  COMPOSER_SET_DRAFTS_INITIAL_TEXT: 'COMPOSER_SET_DRAFTS_INITIAL_TEXT',
  COMPOSER_UPDATE_DRAFT_EDITOR_STATE: 'COMPOSER_UPDATE_DRAFT_EDITOR_STATE',
  COMPOSER_UPDATE_TOGGLE_SIDEBAR: 'COMPOSER_UPDATE_TOGGLE_SIDEBAR',
  COMPOSER_UPDATE_TOGGLE_SIDEBAR_LEFT: 'COMPOSER_UPDATE_TOGGLE_SIDEBAR_LEFT',
  COMPOSER_UPDATE_DRAFT_COMMENT: 'COMPOSER_UPDATE_DRAFT_COMMENT',
  COMPOSER_DRAFT_UPDATED: 'COMPOSER_DRAFT_UPDATED',
  COMPOSER_UPDATE_DRAFT_COMMENT_CHARACTER_COUNT:
    'COMPOSER_UPDATE_DRAFT_COMMENT_CHARACTER_COUNT',
  COMPOSER_UPDATE_DRAFT_TITLE_CHARACTER_COUNT:
    'COMPOSER_UPDATE_DRAFT_TITLE_CHARACTER_COUNT',
  COMPOSER_UPDATE_DRAFT_SOURCE_LINK: 'COMPOSER_UPDATE_DRAFT_SOURCE_LINK',
  COMPOSER_UPDATE_DRAFTS_SOURCE_LINK: 'COMPOSER_UPDATE_DRAFTS_SOURCE_LINK',
  COMPOSER_UPDATE_DRAFT_SOURCE_LINK_DATA:
    'COMPOSER_UPDATE_DRAFT_SOURCE_LINK_DATA',
  COMPOSER_UPDATE_DRAFT_TITLE: 'COMPOSER_UPDATE_DRAFT_TITLE',
  COMPOSER_UPDATE_DRAFT_UPDATE_TYPE: 'COMPOSER_UPDATE_DRAFT_UPDATE_TYPE',
  COMPOSER_UPDATE_DRAFT_SELECTED_STICKERS:
    'COMPOSER_UPDATE_DRAFT_SELECTED_STICKERS',
  COMPOSER_UPDATE_DRAFTS_INITIAL_STICKERS:
    'COMPOSER_UPDATE_DRAFTS_INITIAL_STICKERS',
  COMPOSER_UPDATE_DRAFT_GOOGLE_BUSINESS_DATA:
    'COMPOSER_UPDATE_DRAFT_GOOGLE_BUSINESS_DATA',
  COMPOSER_UPDATE_DRAFT_MASTODON_DATA: 'COMPOSER_UPDATE_DRAFT_MASTODON_DATA',
  COMPOSER_UPDATE_DRAFT_INSTAGRAM_DATA: 'COMPOSER_UPDATE_DRAFT_INSTAGRAM_DATA',
  COMPOSER_UPDATE_DRAFT_LINKEDIN_DATA: 'COMPOSER_UPDATE_DRAFT_LINKEDIN_DATA',
  COMPOSER_UPDATE_DRAFT_YOUTUBE_DATA: 'COMPOSER_UPDATE_DRAFT_YOUTUBE_DATA',
  COMPOSER_UPDATE_DRAFT_TIKTOK_DATA: 'COMPOSER_UPDATE_DRAFT_TIKTOK_DATA',
  COMPOSER_UPDATE_DRAFTS_TITLE: 'COMPOSER_UPDATE_DRAFTS_TITLE',
  COMPOSER_UPDATE_DRAFT_START_PAGE_DATA:
    'COMPOSER_UPDATE_DRAFT_START_PAGE_DATA',
  COMPOSER_UPDATE_DRAFT_LINK_DATA: 'COMPOSER_UPDATE_DRAFT_LINK_DATA',
  COMPOSER_UPDATE_DRAFTS_LINK_DATA: 'COMPOSER_UPDATE_DRAFTS_LINK_DATA',
  COMPOSER_UPDATE_DRAFT_LIST_PLACES: 'COMPOSER_UPDATE_DRAFT_LIST_PLACES',
  COMPOSER_UPDATE_DRAFTS_COMMENT: 'COMPOSER_UPDATE_DRAFTS_COMMENT',
  COMPOSER_UPDATE_DRAFTS_UPDATE_TYPE: 'COMPOSER_UPDATE_DRAFTS_UPDATE_TYPE',
  COMPOSER_UPDATE_DRAFTS_IS_REMINDER: 'COMPOSER_UPDATE_DRAFTS_IS_REMINDER',
  COMPOSER_UPDATE_DRAFT_IS_REMINDER: 'COMPOSER_UPDATE_DRAFT_IS_REMINDER',
  COMPOSER_PARSE_MASTODON_TEXT: 'COMPOSER_PARSE_MASTODON_TEXT',
  COMPOSER_ADD_DRAFT_IMAGE: 'COMPOSER_ADD_DRAFT_IMAGE',
  COMPOSER_DRAFT_IMAGE_ADDED: 'COMPOSER_DRAFT_IMAGE_ADDED',
  COMPOSER_REMOVE_DRAFT_IMAGE: 'COMPOSER_REMOVE_DRAFT_IMAGE',
  COMPOSER_REMOVE_DRAFT_DOCUMENT: 'COMPOSER_REMOVE_DRAFT_DOCUMENT',
  APP_SELECT_SUBPROFILE: 'APP_SELECT_SUBPROFILE',
  APP_SELECT_SUBPROFILES: 'APP_SELECT_SUBPROFILES',
  APP_UNSELECT_SUBPROFILE: 'APP_UNSELECT_SUBPROFILE',
  COMPOSER_ADD_DRAFT_VIDEO: 'COMPOSER_ADD_DRAFT_VIDEO',
  COMPOSER_ADD_DRAFT_GIF: 'COMPOSER_ADD_DRAFT_GIF',
  COMPOSER_DRAFT_VIDEO_ADDED: 'COMPOSER_DRAFT_VIDEO_ADDED',
  COMPOSER_DRAFT_GIF_ADDED: 'COMPOSER_DRAFT_GIF_ADDED',
  COMPOSER_REMOVE_DRAFT_VIDEO: 'COMPOSER_REMOVE_DRAFT_VIDEO',
  COMPOSER_REMOVE_DRAFT_GIF: 'COMPOSER_REMOVE_DRAFT_GIF',
  COMPOSER_UPDATE_DRAFT_TEMP_IMAGE: 'COMPOSER_UPDATE_DRAFT_TEMP_IMAGE',
  COMPOSER_REMOVE_DRAFT_TEMP_IMAGE: 'COMPOSER_REMOVE_DRAFT_TEMP_IMAGE',
  COMPOSER_ADD_DRAFTS_RETWEET: 'COMPOSER_ADD_DRAFTS_RETWEET',
  COMPOSER_SAVE_DRAFTS: 'COMPOSER_SAVE_DRAFTS',
  COMPOSER_RECEIVE_SAVED_DRAFTS: 'COMPOSER_RECEIVE_SAVED_DRAFTS',
  COMPOSER_SELECT_PROFILES_ON_BEHALF_OF_USER:
    'COMPOSER_SELECT_PROFILES_ON_BEHALF_OF_USER',
  COMPOSER_SELECT_PROFILE: 'COMPOSER_SELECT_PROFILE',
  COMPOSER_SELECT_PROFILES: 'COMPOSER_SELECT_PROFILES',
  COMPOSER_QUEUE_PROFILES_SUBPROFILES_DROPDOWNS_TO_EXPAND:
    'COMPOSER_QUEUE_PROFILES_SUBPROFILES_DROPDOWNS_TO_EXPAND',
  COMPOSER_EMPTY_PROFILES_SUBPROFILES_DROPDOWNS_TO_EXPAND:
    'COMPOSER_EMPTY_PROFILES_SUBPROFILES_DROPDOWNS_TO_EXPAND',
  COMPOSER_UNSELECT_PROFILE: 'COMPOSER_UNSELECT_PROFILE',
  COMPOSER_UNSELECT_PROFILES: 'COMPOSER_UNSELECT_PROFILES',
  COMPOSER_EXPAND: 'COMPOSER_EXPAND',
  COMPOSER_COLLAPSE: 'COMPOSER_COLLAPSE',
  COMPOSER_TOGGLE_DRAFT_ATTACHMENT: 'COMPOSER_TOGGLE_DRAFT_ATTACHMENT',
  COMPOSER_DISABLE_DRAFT_ATTACHMENT: 'COMPOSER_DISABLE_DRAFT_ATTACHMENT',
  COMPOSER_ENABLE_DRAFTS_ATTACHMENT: 'COMPOSER_ENABLE_DRAFTS_ATTACHMENT',
  COMPOSER_DRAFT_ATTACHMENT_TOGGLED: 'COMPOSER_DRAFT_ATTACHMENT_TOGGLED',
  COMPOSER_ENABLE: 'COMPOSER_ENABLE',
  COMPOSER_DISABLE: 'COMPOSER_DISABLE',
  COMPOSER_PARSE_DRAFT_TEXT_LINKS: 'COMPOSER_PARSE_DRAFT_TEXT_LINKS',
  COMPOSER_PARSE_DRAFTS_TEXT_LINKS: 'COMPOSER_PARSE_DRAFTS_TEXT_LINKS',
  COMPOSER_DRAFT_LINK_SHORTENED: 'COMPOSER_DRAFT_LINK_SHORTENED',
  COMPOSER_DRAFT_LINK_UNSHORTENED: 'COMPOSER_DRAFT_LINK_UNSHORTENED',
  COMPOSER_DRAFT_LINK_RESHORTENED: 'COMPOSER_DRAFT_LINK_RESHORTENED',
  COMPOSER_ADD_DRAFT_AVAILABLE_IMAGES: 'COMPOSER_ADD_DRAFT_AVAILABLE_IMAGES',
  COMPOSER_REMOVE_DRAFT_AVAILABLE_IMAGES:
    'COMPOSER_REMOVE_DRAFT_AVAILABLE_IMAGES',
  COMPOSER_DRAFT_FILE_UPLOAD_STARTED: 'COMPOSER_DRAFT_FILE_UPLOAD_STARTED',
  COMPOSER_DRAFT_FILE_UPLOAD_PROGRESS: 'COMPOSER_DRAFT_FILE_UPLOAD_PROGRESS',
  COMPOSER_ADD_DRAFT_UPLOADED_IMAGE: 'COMPOSER_ADD_DRAFT_UPLOADED_IMAGE',
  UPLOADED_LINK_THUMBNAIL: 'UPLOADED_LINK_THUMBNAIL',
  COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_IMAGE:
    'COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_IMAGE',
  COMPOSER_ADD_DRAFT_UPLOADED_GIF: 'COMPOSER_ADD_DRAFT_UPLOADED_GIF',
  COMPOSER_ADD_DRAFT_UPLOADED_DOCUMENT: 'COMPOSER_ADD_DRAFT_UPLOADED_DOCUMENT',
  COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_GIF:
    'COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_GIF',
  COMPOSER_ADD_DRAFT_UPLOADED_VIDEO_WITHOUT_TRANSCODING:
    'COMPOSER_ADD_DRAFT_UPLOADED_VIDEO_WITHOUT_TRANSCODING',
  COMPOSER_ADD_DRAFT_UPLOADED_VIDEO: 'COMPOSER_ADD_DRAFT_UPLOADED_VIDEO',
  COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_VIDEO:
    'COMPOSER_ADD_DRAFTS_AUTO_UPLOADED_VIDEO',
  COMPOSER_VIDEO_PROCESSED: 'COMPOSER_VIDEO_PROCESSED',
  COMPOSER_SET_LINKEDIN_CAROUSEL_TITLE: 'COMPOSER_SET_LINKEDIN_CAROUSEL_TITLE',
  COMPOSER_UPDATE_DRAFTS_SCHEDULED_AT: 'COMPOSER_UPDATE_DRAFTS_SCHEDULED_AT',
  COMPOSER_UPDATE_DRAFT_CAMPAIGN_ID: 'COMPOSER_UPDATE_DRAFT_CAMPAIGN_ID',
  COMPOSER_UPDATE_DRAFTS_CAMPAIGN_ID: 'COMPOSER_UPDATE_DRAFTS_CAMPAIGN_ID',
  COMPOSER_UPDATE_DRAFTS_TAGS: 'COMPOSER_UPDATE_DRAFTS_TAGS',
  COMPOSER_UPDATE_DRAFT_IMAGE_USER_TAGS:
    'COMPOSER_UPDATE_DRAFT_IMAGE_USER_TAGS',
  COMPOSER_UPDATE_DRAFT_IMAGE_USER_TAGS_ALL_DRAFTS:
    'COMPOSER_UPDATE_DRAFT_IMAGE_USER_TAGS_ALL_DRAFTS',
  COMPOSER_UPDATE_DRAFTS_IMAGE_USER_TAGS:
    'COMPOSER_UPDATE_DRAFTS_IMAGE_USER_TAGS',
  COMPOSER_CREATE_NEW_SUBPROFILE: 'COMPOSER_CREATE_NEW_SUBPROFILE',
  COMPOSER_CREATE_NEW_SUBPROFILE_PENDING:
    'COMPOSER_CREATE_NEW_SUBPROFILE_PENDING',
  COMPOSER_CREATE_NEW_SUBPROFILE_FAILED:
    'COMPOSER_CREATE_NEW_SUBPROFILE_FAILED',
  PROFILE_DROPDOWN_HIDDEN: 'PROFILE_DROPDOWN_HIDDEN',
  COMPOSER_COLLAPSE_PROFILE_SUBPROFILE_DROPDOWN:
    'COMPOSER_COLLAPSE_PROFILE_SUBPROFILE_DROPDOWN',
  COMPOSER_EXPAND_PROFILE_SUBPROFILE_DROPDOWN:
    'COMPOSER_EXPAND_PROFILE_SUBPROFILE_DROPDOWN',
  APP_SELECT_GROUP_PROFILES: 'APP_SELECT_GROUP_PROFILES',
  APP_UNSELECT_GROUP_PROFILES: 'APP_UNSELECT_GROUP_PROFILES',
  COMPOSER_PROFILE_GROUP_CREATED: 'COMPOSER_PROFILE_GROUP_CREATED',
  COMPOSER_PROFILE_GROUP_UPDATED: 'COMPOSER_PROFILE_GROUP_UPDATED',
  COMPOSER_PROFILE_GROUP_DELETED: 'COMPOSER_PROFILE_GROUP_DELETED',
  APP_RECEIVE_PROFILE_SLOT_DATA: 'APP_RECEIVE_PROFILE_SLOT_DATA',
  QUEUE_NOTIFICATION: 'QUEUE_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  REMOVE_NOTIFICATION_COOKIE: 'REMOVE_NOTIFICATION_COOKIE',
  APP_TOGGLE_ALL_PROFILES: 'APP_TOGGLE_ALL_PROFILES',
  APP_REFRESH_SUBPROFILE_DATA: 'APP_REFRESH_SUBPROFILE_DATA',
  COMPOSER_ADD_DRAFT_UPLOADED_LINK_THUMBNAIL:
    'COMPOSER_ADD_DRAFT_UPLOADED_LINK_THUMBNAIL',
  COMPOSER_UPDATE_DRAFT_LINK_THUMBNAIL: 'COMPOSER_UPDATE_DRAFT_LINK_THUMBNAIL',
  COMPOSER_UPDATE_DRAFT_VIDEO_THUMBNAIL:
    'COMPOSER_UPDATE_DRAFT_VIDEO_THUMBNAIL',
  COMPOSER_UPDATE_DRAFT_VIDEO_TITLE: 'COMPOSER_UPDATE_DRAFT_VIDEO_TITLE',
  COMPOSER_UPDATE_PREVIOUS_LINK_THUMBNAIL:
    'COMPOSER_UPDATE_PREVIOUS_LINK_THUMBNAIL',
  COMPOSER_UPDATE_NEXT_LINK_THUMBNAIL: 'COMPOSER_UPDATE_NEXT_LINK_THUMBNAIL',
  UPDATE_DRAFT_HAS_SAVING_ERROR: 'UPDATE_DRAFT_HAS_SAVING_ERROR',
  COMPOSER_CLEAR_INLINE_ERRORS: 'COMPOSER_CLEAR_INLINE_ERRORS',
  UPDATE_DRAFT_IS_SAVED: 'UPDATE_DRAFT_IS_SAVED',
  APP_LOADED: 'APP_LOADED',
  COMPOSER_APPLY_OMNI_UPDATE: 'COMPOSER_APPLY_OMNI_UPDATE',
  REMOVE_ALL_NOTIFICATIONS_BY_SCOPE: 'REMOVE_ALL_NOTIFICATIONS_BY_SCOPE',
  REMOVE_COMPOSER_NOTICES: 'REMOVE_COMPOSER_NOTICES',
  COMPOSER_UPDATE_IMAGE_ALT_TEXT: 'COMPOSER_UPDATE_IMAGE_ALT_TEXT',
  COMPOSER_UPDATE_UPLOADED_IMAGE_DIMENSIONS:
    'COMPOSER_UPDATE_UPLOADED_IMAGE_DIMENSIONS',
  COMPOSER_DRAFTS_PREVENT_AUTO_ATTACHING_URLS:
    'COMPOSER_DRAFTS_PREVENT_AUTO_ATTACHING_URLS',
  COMPOSER_FORCE_FOCUS: 'COMPOSER_FORCE_FOCUS',
  COMPOSER_STOP_FORCE_FOCUS: 'COMPOSER_STOP_FORCE_FOCUS',
  RESET_PROFILES_DATA: 'RESET_PROFILES_DATA',
  UPDATE_SELECTED_PROFILES: 'UPDATE_SELECTED_PROFILES',
  APP_REFRESH_FACEBOOK_DOMAIN_OWNERSHIP_DATA:
    'APP_REFRESH_FACEBOOK_DOMAIN_OWNERSHIP_DATA',
  APP_RECEIVE_FACEBOOK_DOMAIN_OWNERSHIP_DATA:
    'APP_RECEIVE_FACEBOOK_DOMAIN_OWNERSHIP_DATA',
  APP_UPDATE_OMNIBOX_STATE: 'APP_UPDATE_OMNIBOX_STATE',
  APP_UPDATE_PARTIALLY_SAVED_DRAFTS_PROFILES_IDS:
    'APP_UPDATE_PARTIALLY_SAVED_DRAFTS_PROFILES_IDS',
  COMPOSER_UPDATE_INSTAGRAM_STATE: 'COMPOSER_UPDATE_INSTAGRAM_STATE',
  APP_REMEMBER_TWITTER_MAX_PROFILE_NOTIF_CLOSED_ONCE:
    'APP_REMEMBER_TWITTER_MAX_PROFILE_NOTIF_CLOSED_ONCE',
  EVENT_SHOW_SWITCH_PLAN_MODAL: 'EVENT_SHOW_SWITCH_PLAN_MODAL',
  COMPOSER_UPDATE_INSTAGRAM_DRAFT_THUMBNAIL:
    'COMPOSER_UPDATE_INSTAGRAM_DRAFT_THUMBNAIL',
  COMPOSER_UPDATE_DRAFT_THUMBNAIL_GENERATED:
    'COMPOSER_UPDATE_DRAFT_THUMBNAIL_GENERATED',
  APP_SET_THUMBNAIL_LOADING: 'APP_SET_THUMBNAIL_LOADING',
  RESET_ORGANIZATIONS_DATA: 'RESET_ORGANIZATIONS_DATA',
  UPDATE_CONNECT_CHANNEL_POPOVER_VISIBLE:
    'UPDATE_CONNECT_CHANNEL_POPOVER_VISIBLE',
  COMPOSER_INSERT_HASHTAG_TO_CAPTION: 'COMPOSER_INSERT_HASHTAG_TO_CAPTION',
  COMPOSER_UPDATE_TOGGLE_POST_PREVIEW: 'COMPOSER_UPDATE_TOGGLE_POST_PREVIEW',
  // composer modal action types
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_MODAL: 'OPEN_MODAL',
  SET_APP_NOTIFICATION_MESSAGE: 'SET_APP_NOTIFICATION_MESSAGE',
  COMPOSER_UPDATE_INSTAGRAM_DRAFTS_FEEDBACK:
    'COMPOSER_UPDATE_INSTAGRAM_DRAFTS_FEEDBACK',
  COMPOSER_UPDATE_SIDEPANEL_ONTOP: 'COMPOSER_UPDATE_SIDEPANEL_ONTOP',
  COMPOSER_UPDATE_SHOULD_SHORTEN_LINKS: 'COMPOSER_UPDATE_SHOULD_SHORTEN_LINKS',
  COMPOSER_UPDATE_REGENERATE_SHORTENED_LINKS:
    'COMPOSER_UPDATE_REGENERATE_SHORTENED_LINKS',
  COMPOSER_SET_IS_TWITTER_PREMIUM: 'COMPOSER_SET_IS_TWITTER_PREMIUM',
  UPDATE_SHORTENER_FOR_PROFILE: 'UPDATE_SHORTENER_FOR_PROFILE',
} as const
