import type { onRedirectParams } from '../../../types'

export enum ViewType {
  accountSelection = 'account-selection',
  FacebookGroups = 'facebook-groups',
}

export type AccountTypeSelectionProps = {
  onRedirect: (args: onRedirectParams) => void
  closeAction: () => void
}

export type DefaultViewProps = {
  onRedirect: (args: onRedirectParams) => void
  onChangeView: (nextView: ViewType) => void
}
