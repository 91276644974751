import { useQuery } from '@apollo/client'
import {
  ArrowLeftIcon,
  ChannelAvatar,
  type ChannelType,
  Flex,
  IconButton,
  Tabs,
} from '@buffer-mono/popcorn'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { PageLayout } from '~publish/components/PageLayout'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { GeneralSettings } from '~publish/legacy/general-settings'
import { PostingSchedule } from '~publish/legacy/posting-schedule/'
import { actions as profileSidebarActions } from '~publish/legacy/profile-sidebar/reducer'
import { selectProfileById } from '~publish/legacy/profile-sidebar/selectors'
import { actions as profileActions } from '~publish/legacy/profiles/reducer'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { useCurrentOrganization } from '~publish/legacy/accountContext'
import { ChannelProvider } from '../ChannelContext'
import { GetChannelInfo } from '..'

type SettingsTab = 'general' | 'posting-schedule'

/**
 * This page integrates legacy components as a temporary solution. We should rewrite this page using GraphQL API and Popcorn
 */
export const ChannelSettings = ({
  isForNewSettings = false,
}: {
  // This is for when we embed this page in the new settings section of Publish
  // it is navigated to from clicking a specific channel there, so we show a
  // different header here (see below)
  isForNewSettings?: boolean
}): JSX.Element => {
  const { id: channelId } = useParams<{
    id: string
  }>()
  const [tab = 'posting-schedule', setTab] = useQueryParam<SettingsTab>('tab')
  const [loading, setLoading] = React.useState(true)

  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => selectProfileById(state, channelId))

  const organization = useCurrentOrganization()
  const organizationId = organization?.id

  const { data } = useQuery(GetChannelInfo, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - organizationId is not always defined we skip if it is not defined
      organizationId,
      channelId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !organizationId,
  })

  const channel = data?.channel

  // TODO: refactor this to use GraphQL API
  // If no profile in Redux for the current channel, fetch it
  React.useEffect(() => {
    if (loading && !profile) {
      dispatch(
        profileActions.fetchSingleProfile({
          profileId: channelId,
          message: '',
        }),
      )
    }
  }, [loading, profile, dispatch, channelId])

  // Once the profile is fetched, trigger profile selection to kick off
  // everything needed for the settings page
  React.useEffect(() => {
    if (loading && profile) {
      dispatch(profileSidebarActions.selectProfile({ profile }))
      setLoading(false)
    }
  }, [dispatch, channelId, profile, loading])

  return (
    <Tabs
      asChild
      value={tab}
      onChange={(selectedTab): void => setTab(selectedTab as SettingsTab)}
    >
      <PageLayout>
        <PageLayout.Header>
          {isForNewSettings ? (
            <PageLayout.HeaderRow>
              <PageLayout.Title>
                <Flex align="center" direction="row" gap="md">
                  <IconButton
                    as={Link}
                    to={`/settings/channels`}
                    label="Back to Channels"
                    tooltip="Back to Channels"
                    variant="tertiary"
                  >
                    <ArrowLeftIcon />
                  </IconButton>
                  <ChannelAvatar
                    src={channel?.avatar}
                    channel={channel?.service as ChannelType}
                    alt={`avatar for ${channel?.name}`}
                    size="mid"
                  />
                  {channel?.name}
                </Flex>
              </PageLayout.Title>
            </PageLayout.HeaderRow>
          ) : (
            <PageLayout.HeaderRow>
              <PageLayout.Title>
                <IconButton
                  as={Link}
                  to={`/channels/${channelId}`}
                  label="Back to Channel"
                  tooltip="Back to Channel"
                  variant="tertiary"
                >
                  <ArrowLeftIcon />
                </IconButton>
                Settings
              </PageLayout.Title>
            </PageLayout.HeaderRow>
          )}
          <PageLayout.HeaderRow>
            <Tabs.TabList>
              <Tabs.Tab value="posting-schedule">Posting Schedule</Tabs.Tab>
              <Tabs.Tab value="general">General</Tabs.Tab>
            </Tabs.TabList>
          </PageLayout.HeaderRow>
        </PageLayout.Header>
        <PageLayout.Container>
          <Tabs.Panel value="posting-schedule">
            {channel && (
              <ChannelProvider channel={channel}>
                <PostingSchedule />
              </ChannelProvider>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="general">
            <GeneralSettings />
          </Tabs.Panel>
        </PageLayout.Container>
      </PageLayout>
    </Tabs>
  )
}
