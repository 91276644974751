import React, { forwardRef, type KeyboardEventHandler } from 'react'
import { useDraggable, useDroppable } from '@dnd-kit/core'
import clsx from 'clsx'

import {
  ArrowDownUpIcon,
  Badge,
  GripVerticalIcon,
  IconButton,
} from '@buffer-mono/popcorn'

import { type FragmentType, getFragmentData } from '~publish/gql'
import {
  PostCard,
  PostCardCompact,
  PostCard_Post,
} from '~publish/components/PostCard'
import useDraggingCursor from '~publish/hooks/useDraggingCursor'

import styles from './DraggablePostCard.module.css'

type DraggablePostCardProps = {
  post: FragmentType<typeof PostCard_Post>
  inOverlay?: boolean
  className?: string
  style?: React.CSSProperties
}

export const DraggablePostCard = forwardRef<
  HTMLDivElement,
  DraggablePostCardProps
>(({ post: passedPost, className, style, inOverlay = false }, forwardedRef) => {
  const post = getFragmentData(PostCard_Post, passedPost)
  const {
    attributes,
    listeners,
    setNodeRef: setDraggableNodeRef,
    transform,
    isDragging,
  } = useDraggable({
    id: post.id,
    data: { postId: post.id, type: 'post', dueAt: post.dueAt },
  })
  const {
    setNodeRef: setDroppableNodeRef,
    isOver,
    active,
  } = useDroppable({
    id: post.id,
    data: { postId: post.id, type: 'post', dueAt: post.dueAt },
  })
  useDraggingCursor(isDragging)

  const isDraggedPost = isDragging && inOverlay
  const showDropOverlay = isOver && active?.id !== post.id
  const { onKeyDown, ...mouseListeners } = listeners ?? {}

  return (
    <div
      ref={(node): void => {
        setDroppableNodeRef(node)
        setDraggableNodeRef(node)
        if (typeof forwardedRef === 'function') forwardedRef(node)
        else if (forwardedRef) forwardedRef.current = node
      }}
      className={clsx(
        styles.base,
        {
          [styles.dragging]: isDragging,
          [styles.dragOverlay]: inOverlay,
          [styles.dragOver]: showDropOverlay,
        },
        className,
      )}
      style={
        {
          '--translate-x':
            isDraggedPost && transform ? `${transform.x}px` : undefined,
          '--translate-y':
            isDraggedPost && transform ? `${transform.y}px` : undefined,
          '--scale-x':
            isDraggedPost && transform?.scaleX
              ? `${transform.scaleX}`
              : undefined,
          '--scale-y':
            isDraggedPost && transform?.scaleY
              ? `${transform.scaleY}`
              : undefined,
          ...style,
        } as React.CSSProperties
      }
      {...mouseListeners}
    >
      {showDropOverlay && (
        <div className={styles.swapOverlay}>
          <Badge className={styles.swapBadge} size="large">
            <ArrowDownUpIcon /> Swap posts
          </Badge>
        </div>
      )}
      <IconButton
        variant="tertiary"
        size="small"
        label="Drag to reschedule"
        className={styles.dragHandle}
        {...attributes}
        onKeyDown={onKeyDown as KeyboardEventHandler}
        data-dnd
      >
        <GripVerticalIcon />
      </IconButton>
      {!inOverlay && isDragging && <div className={styles.originalPosition} />}
      {inOverlay ? (
        <PostCardCompact
          post={passedPost}
          className={styles.postCardDragOverlay}
        />
      ) : (
        <PostCard
          post={passedPost}
          className={styles.postCardDragOverlay}
          draggable={!showDropOverlay}
        />
      )}
    </div>
  )
})

DraggablePostCard.displayName = 'DraggablePostCard'
