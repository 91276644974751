import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import type { DragStartEvent } from '@dnd-kit/core'

import { useDebounce } from '@buffer-mono/popcorn'

import { Calendar as CalendarComponent } from '~publish/components/Calendar'
import { useCalendar } from '~publish/components/Calendar/hooks/useCalendar'
import { getFragmentData } from '~publish/gql'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useTimezone } from '~publish/hooks/useTimezone'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import { CalendarPostCard_Post } from '~publish/pages/Calendar/fragments/CalendarPostCard_Post'
import { usePostDraggedTracking } from '~publish/hooks/usePostDraggedTracking'
import { useWeekStartsOn } from '~publish/hooks/useWeekStartsOn'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'
import type { CalendarPostCard_PostFragment } from '~publish/gql/graphql'
import type {
  CalendarItem,
  RenderItemFunction,
} from '~publish/components/Calendar/types'
import {
  mergePostsAndSlots,
  type SlotOrPost,
  type Slot,
} from '~publish/pages/Channel/QueueList/postSlotManager'

import type { PostForCalendarHookResponse } from './hooks/useCalendarAndPostsList'
import { useHandleDragEnd } from './hooks/useHandleDragEnd'
import { useCalendarSlots } from './hooks/useCalendarSlots'
import { useMoveToCustomTimeAlertDialog } from './hooks/useMoveToCustomTimeAlertDialog'
import { PostItem } from './PostItem'
import { SlotItem } from './SlotItem'
import { PostingGoalSlotsTour } from '../../components/PostingGoalSlotsTour'

const CTA_ADD_HOUR = 'publish-calendar-hour-addPost-1'
const CTA_ADD_DAY = 'publish-calendar-day-addPost-1'
const FIVE_MINUTES_MS = 5 * 60 * 1000

interface CalendarProps {
  hasTwentyFourHourTimeFormat: boolean
  selectedChannelIds: string[] | void
  viewOnly: boolean
  postsQuery: PostForCalendarHookResponse
}

/**
 * Custom hook to keep track of the previous value of a variable across renders
 *
 * @template T - The type of the value to track
 * @param {T} value - The current value to track
 * @returns {T | undefined} - The previous value (undefined on first render)
 *
 * @example
 * // Track previous view mode to prevent tour flickering
 * const prevViewMode = usePreviousValue(viewMode)
 */
function usePreviousValue<T>(value: T): T | undefined {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function Calendar({
  hasTwentyFourHourTimeFormat,
  selectedChannelIds,
  viewOnly,
  postsQuery,
}: CalendarProps): JSX.Element {
  const timezone = useTimezone()
  const dayToStartWeek = useWeekStartsOn()
  const weekStartsOn = dayToStartWeek === 'monday' ? 1 : 0
  const { viewMode } = useCalendar({ timezone, weekStartsOn })
  const { data, loading } = postsQuery
  const [startTour, setStartTour] = useState(false)
  const debouncedStartTour = useDebounce(startTour, 400)
  const prevViewMode = usePreviousValue(viewMode) // [Hack]: Avoid tour flickering when changing view mode
  const showTour = debouncedStartTour && !loading && prevViewMode === viewMode

  const posts = useMemo(
    () => data?.posts.edges?.map((edge) => edge.node) || [],
    [data],
  )

  const slots = useCalendarSlots({ selectedChannelIds })

  const items = useMemo(
    () =>
      buildItemsFromPostsAndSlots(
        mergePostsAndSlots({
          slots,
          posts: posts.map((post) =>
            getFragmentData(CalendarPostCard_Post, post),
          ),
        }),
        selectedChannelIds?.length === 1,
      ),
    [posts, slots, selectedChannelIds],
  )

  const { triggerAttributes, createNewPostInComposer } = usePostComposer()
  const selectedTags = useSelectedTags()

  const openComposer = useCallback(
    (timestamp: number): void => {
      const channels =
        selectedChannelIds === undefined || selectedChannelIds.length === 0
          ? []
          : selectedChannelIds

      const now = new Date().getTime()
      if (timestamp < now) {
        timestamp = now + FIVE_MINUTES_MS
      }

      createNewPostInComposer({
        cta: viewMode === 'week' ? CTA_ADD_HOUR : CTA_ADD_DAY,
        channels,
        prefillPostData: {
          dueAt: Math.round(timestamp / 1000),
          tags: selectedTags,
        },
      })
    },
    [createNewPostInComposer, selectedChannelIds, selectedTags, viewMode],
  )

  const firstVisibleSlot = useMemo(() => {
    return items.find((slot) => slot.type === 'slot')
  }, [items])
  const renderItem = useCallback<RenderItemFunction>(
    ({ id, timestamp, type, isDraggingOver }) => {
      if (type === 'slot' && selectedChannelIds) {
        const isFirstSlot = firstVisibleSlot?.timestamp === timestamp
        if (isFirstSlot) {
          setStartTour(true) // Start the tour after the first slot is rendered
          return (
            <SlotItem
              timestamp={timestamp}
              channelId={selectedChannelIds[0]}
              data-tour-id="slot"
              isDraggingOver={isDraggingOver}
              is24HourFormat={hasTwentyFourHourTimeFormat}
            />
          )
        }
        return (
          <SlotItem
            timestamp={timestamp}
            channelId={selectedChannelIds[0]}
            isDraggingOver={isDraggingOver}
            is24HourFormat={hasTwentyFourHourTimeFormat}
          />
        )
      }
      if (type === 'post') {
        const postIndex = posts.findIndex((post) => post?.id === id)
        const post = posts[postIndex]
        if (!post) return <></>
        return (
          <PostItem
            post={post}
            timestamp={timestamp}
            viewMode={viewMode}
            timezone={timezone}
            isDraggingOver={isDraggingOver}
          />
        )
      }
      return <></>
    },
    [
      firstVisibleSlot?.timestamp,
      hasTwentyFourHourTimeFormat,
      posts,
      selectedChannelIds,
      timezone,
      viewMode,
    ],
  )

  // Track post drag events
  const trackPostDrag = usePostDraggedTracking()
  const handleDragStart = useCallback(
    (event: DragStartEvent): void => {
      if (!event.active.id) return
      const postRaw = posts?.find((p) => p.id === event.active.id)
      const post = getFragmentData(CalendarPostCard_Post, postRaw)
      if (post) trackPostDrag(post)
    },
    [posts, trackPostDrag],
  )
  const [showDialog, Dialog] = useMoveToCustomTimeAlertDialog()

  const handleDragEnd = useHandleDragEnd({
    posts:
      data?.posts.edges?.map((edge) =>
        getFragmentData(CalendarPostCard_Post, edge.node),
      ) ?? [],
    preserveTime: viewMode === 'month',
    timezone,
    is24HourFormat: hasTwentyFourHourTimeFormat,
    onMovingToCustomSchedule: async (
      previousDueAt,
      newDueAt,
    ): Promise<boolean> => {
      if (!previousDueAt) return true

      const result = await showDialog({
        fromDate: previousDueAt,
        toDate: newDueAt,
      })
      return result === 'confirmed' // continue with the move to custom time
    },
  })

  const addItem = useMemo(
    () => ({
      ...triggerAttributes,
      onClick: openComposer,
    }),
    [triggerAttributes, openComposer],
  )

  return (
    <>
      <CalendarComponent
        timezone={timezone}
        weekStartsOn={weekStartsOn}
        is24HourFormat={hasTwentyFourHourTimeFormat}
        readOnly={viewOnly}
        loading={loading}
        items={loading && items.length === 0 ? undefined : items}
        renderItem={renderItem}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        addItemProps={addItem}
      />
      {Dialog}
      {showTour && <PostingGoalSlotsTour />}
    </>
  )
}

function isSlot(
  entry: SlotOrPost<CalendarPostCard_PostFragment>,
): entry is Slot {
  return 'date' in entry && !('__typename' in entry)
}

function isPost(
  entry: SlotOrPost<CalendarPostCard_PostFragment>,
): entry is CalendarPostCard_PostFragment & { dueAt: string } {
  return (
    '__typename' in entry && entry.__typename === 'Post' && entry.dueAt != null
  )
}

function buildItemsFromPostsAndSlots(
  entries: SlotOrPost<CalendarPostCard_PostFragment>[],
  isSingleChannel = false,
): CalendarItem[] {
  return entries
    .filter((entry) => {
      if (isPost(entry)) {
        return Boolean(entry.dueAt)
      }
      if (isSlot(entry)) {
        return Boolean(entry.date)
      }
      return false
    })
    .map((entry) => {
      if (isPost(entry)) {
        return {
          id: entry.id,
          timestamp: new Date(entry.dueAt).getTime(),
          type: 'post',
          draggable: !PostEntity.isSent(entry) && entry.status !== 'error',
          droppable:
            !PostEntity.isSent(entry) &&
            entry.status !== 'error' &&
            isSingleChannel,
        }
      }
      return {
        id: `slot:${entry.date}`,
        timestamp: new Date(entry.date).getTime(),
        type: 'slot',
        draggable: false,
        droppable: true,
      }
    })
}
