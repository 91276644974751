import styled, { keyframes } from 'styled-components'

const slideInLeft = keyframes({
  from: { transform: 'translateX(100%)' },
  to: { transform: 'translateX(0)' },
})

const slideOutLeft = keyframes({
  from: { transform: 'translateX(0)' },
  to: { transform: 'translateX(100%)' },
})

const slideInRight = keyframes({
  from: { transform: 'translateX(-100%)' },
  to: { transform: 'translateX(0)' },
})

const slideOutRight = keyframes({
  from: { transform: 'translateX(0)' },
  to: { transform: ` translateX(-100%)` },
})

export const StyledWrapper = styled.div<{
  $isVisible?: boolean
  $customStyles?: Record<string, string>
}>`
  display: ${({ $isVisible }): string => ($isVisible ? 'block' : 'none')};
  position: absolute;
  width: 364px;
  background-color: white;
  top: 0;
  right: 0;
  border-radius: 8px;
  transition: z-index 0.3s ease-in-out;
  border-top: 1px solid transparent;
  ${({ $customStyles }): Record<string, string> | undefined => $customStyles}

  &.open.right {
    animation: ${slideInRight} 0.3s ease-in-out;
  }

  &.closed.right {
    animation: ${slideOutRight} 0.3s ease-in-out;
  }

  &.open.left {
    animation: ${slideInLeft} 0.3s ease-in-out;
  }

  &.closed.left {
    animation: ${slideOutLeft} 0.3s ease-in-out;
  }
`

export const CloseButton = styled.div<{
  position?: {
    top?: string
    right?: string
  }
}>`
  position: absolute;
  cursor: pointer;
  top: ${(props): string => props.position?.top || '34px'};
  right: ${(props): string => props.position?.right || '24px'};
`
