import { getCookie } from '../../common/utils/cookies'
import { getActiveProductFromPath } from '../../common/utils/getProduct'
import {
  isOnActiveTrial,
  isFreeUser,
  userCanStartFreeTrial,
  isAgencyUser,
  isOnAgencyTrial,
  getUserChannelSlots,
  getUserUnlockedChannels,
  getUserChannelCount,
  getUsersCurrentChannelSlotDetails,
  getOrganizationId,
  hasPaymentDetails,
  getChangePlanOptions,
  isPaidRevenueCatBillingGateway,
  getUserBillingData,
  hasTeamMembers,
  hasEditPermission,
  hasPermissionToManageBilling,
  isPaymentMethodBank,
} from '../../common/utils/user'
import {
  calculateNewQuantityWithChannelIncrease,
  getSupportedChannelsByProduct,
  getChannelFromList,
} from '../../common/utils/channels'
import { maxChannelsAllowedPerPurchaseViaStripe } from '../../common/utils/validation'

import { SUPPORTED_PRODUCTS as CHANNEL_PROMPT_PRODUCTS } from './modals/ChannelConnectionPrompt'
import {
  type Account,
  type Billing,
  type ChannelSlotDetails,
  type DismissedObject,
  type Plan,
  type ChangePlanOption,
  PlanInterval,
} from '../../common/types'
import type { ErrorState } from '../../common/hooks/Subscription/types'
import {
  getSubscriptionInterval,
  getSubscriptionPlanData,
} from '../../common/utils/billing'
import { TRIAL_CHANNELS_LIMIT } from '../../common/utils/constants'
import { ProductNames } from '../../common/constants/products'
import type { WeeklyPostingStreakData } from '../NavBar/components/StreakWidget/StreakWidget'

const TEAM_CTAS = [
  'dropdown-inviteYourTeam',
  'dropdown-team',
  'navBar-inviteYourTeam',
  'approvals-queueTab',
  'approvals-tags-upgrade',
]

export function hasSeenFreeUserStartTrialPrompt(): boolean {
  return Boolean(
    getCookie({
      key: 'startTrialPrompt',
    }),
  )
}

export function shouldShowChannelConnectionPrompt(user: any): boolean {
  const activeProduct = getActiveProductFromPath()
  const isSupportedProduct = CHANNEL_PROMPT_PRODUCTS.includes(activeProduct)
  const supportedChannels = getSupportedChannelsByProduct({
    channels: user?.currentOrganization?.channels,
    product: activeProduct,
  })
  const hasNoChannels = supportedChannels?.length === 0
  if (isSupportedProduct && hasNoChannels) {
    if (activeProduct === 'publish') {
      // GROW-1021: Don't show the prompt on publish since the welcome checklist provides the same function.
      return false
    }
    if (!isFreeUser(user)) {
      return true
    }
  }

  return false
}

export const isComposerOpen = (): boolean => {
  return Boolean(window.__isComposerOpen)
}

export function shouldShowFreeUserStartTrialPrompt(user: any): boolean {
  const activeProduct = getActiveProductFromPath()
  const hasConnecteChannels =
    (user?.currentOrganization?.channels?.length ?? 0) > 0

  /* team member without edit privileges shouldn't see the prompt */
  const userHasEditPermissions = hasEditPermission(user) || false
  return (
    !shouldTriggerChannelOnboardingModalFromUrl() &&
    activeProduct === 'publish' &&
    !isComposerOpen() &&
    userCanStartFreeTrial(user) &&
    hasConnecteChannels &&
    !hasSeenFreeUserStartTrialPrompt() &&
    userHasEditPermissions
  )
}

export function shouldShowPaywallModal(user: any): boolean {
  const activeProduct = getActiveProductFromPath()
  return (
    (activeProduct === 'analyze' || activeProduct === 'engage') &&
    isFreeUser(user)
  )
}

export function filterListOfPlans(planOptions: any, planToExclude: any) {
  const planOptionsFiltered = planOptions.filter(
    (plan: any) => plan.planId !== planToExclude,
  )

  return planOptionsFiltered
}

export function userHasFeatureFlip(user: any, featureFlip: any): boolean {
  if (!user || !user.featureFlips) return false

  return user.featureFlips.includes(featureFlip)
}

export function getPlanByPlanId(planId: any, planOptions: any) {
  return planOptions.find((plan: any) => plan.planId === planId)
}

export function getPlanByPlanIdAndInterval(
  planId: any,
  interval: any,
  planOptions: any,
) {
  return planOptions.find(
    (plan: any) => plan?.planId === planId && plan?.planInterval === interval,
  )
}

export function getCurrentPlanFromPlanOptions(planOptions: any) {
  return planOptions.find((plan: any) => plan.isCurrentPlan)
}

const includesAny = (str: string, arr: string[]) => {
  return arr.some((substring) => str.includes(substring))
}

// Bussiness logic rule:
// We should ALWAYS default the user to the Team plan regardless of what plan they are currently on.
// Exception to the rule is is they are on the agency plan they should default to their current plan.
// We are running an experiment for Free users: the users with split enabled will default to essentials
// unless the user has team members or the ctas from the Plan selector where called were related to teams,
// in that case,  we should default to the Team plan.
export function getDefaultSelectedPlan(
  planOptions: any,
  isDefaultPlanNewLogic = false,
  hasTeamMembers = false,
  ctasFromPlanSelector = '',
) {
  const currentPlan = getCurrentPlanFromPlanOptions(planOptions)

  /* if current plan is agency the interval for default plan should be year */
  const currentInterval =
    currentPlan?.planId !== 'free'
      ? currentPlan?.planInterval || 'year'
      : 'year'

  let recommendedDefaultPlan = isDefaultPlanNewLogic ? 'essentials' : 'team'

  // if the user is behind the split for testing the new logic to select the Default Plan
  if (isDefaultPlanNewLogic) {
    // we have to check if the user has team members
    // and the ctas from the Plan selector where called
    if (hasTeamMembers || includesAny(ctasFromPlanSelector, TEAM_CTAS)) {
      recommendedDefaultPlan = 'team'
    }
  }

  const recommendedDefaultPlanData = getPlanByPlanIdAndInterval(
    recommendedDefaultPlan,
    currentInterval,
    planOptions,
  )

  const defaultSelectedPlan =
    !currentPlan || currentPlan.planId !== 'agency'
      ? recommendedDefaultPlanData
      : currentPlan

  return defaultSelectedPlan
}

export function calculateAgencySlotPrice(
  numberOfSlots: any,
  slotPrice: any,
  flatFee: any,
  minimumQuantity: any,
) {
  if (numberOfSlots <= minimumQuantity) {
    return flatFee
  }

  const numberOfExtraChannels = numberOfSlots - minimumQuantity

  return flatFee + numberOfExtraChannels * slotPrice
}

export function calculateTotalSlotsPrice(
  planId: any,
  numberOfSlots: any,
  slotPrice: any,
  minimumQuantity: any,
  flatFee: any,
) {
  if (planId === 'agency') {
    return calculateAgencySlotPrice(
      numberOfSlots,
      slotPrice,
      flatFee,
      minimumQuantity,
    )
  }

  return numberOfSlots * slotPrice
}

// This will return an array of plan that should be displayed to the user
// in the selection screen inside the plan selector
const formatPriceNote = (
  plan: ChangePlanOption,
  channelsCount: number,
): string | undefined => {
  const { planId = 'free', planInterval, priceNote } = plan
  if (planId === 'agency') return priceNote?.replace('For 10 channels. ', '')
  if (planId === 'free') {
    const intervalNote = planInterval === 'year' ? '\n\n' : '\n'
    return `Per month, up to 3 channels${intervalNote}`
  } else {
    const channelsText = `channel${channelsCount > 1 ? 's' : ''}`
    const intervalNote = planInterval === 'year' ? '\n(charged yearly)' : ''
    return `Per month for ${channelsCount} ${channelsText}${intervalNote}`
  }
}

const formatBasePrice = (
  plan: ChangePlanOption,
  channelsCount: number,
): number => {
  if (plan.planId === 'agency') {
    const basePrice = (plan.basePrice || 0) / 10
    return (
      basePrice * Math.min(channelsCount, 10) +
      basePrice * 0.5 * Math.max(channelsCount - 10, 0)
    )
  }
  return Number((plan.basePrice || 0) * channelsCount)
}

const updatePlan = (
  plan: ChangePlanOption,
  channelsCount: number,
): ChangePlanOption => {
  return {
    ...plan,
    basePrice: formatBasePrice(plan, channelsCount),
    priceNote: formatPriceNote(plan, channelsCount),
  }
}

export function getAvailablePlansForDisplay(
  user: Account,
  planOptions: any,
  showAgencyPlan: boolean,
  isShowTotalCostEnabled: boolean,
  channelsCount: number,
) {
  const isOnFreePlan = isFreeUser(user)
  let shouldIncludeAgencyPlan =
    isAgencyUser(user) || isOnAgencyTrial(user) || showAgencyPlan

  // Handle specific case where an agency user clicks on "Downgrade to Free" plan
  // In this case we want to switch back to the view with the free plan but not agency
  if (isAgencyUser(user) === true && showAgencyPlan === false) {
    shouldIncludeAgencyPlan = false
  }

  /* if the user is behind the split for testing the new logic to show the Total Plan Cost,
    we should change basePrice and priceNote for each plan */
  if (isShowTotalCostEnabled) {
    planOptions = planOptions.map((plan: any) =>
      updatePlan(plan, channelsCount),
    )
  }

  const planOptionsWithoutFreePlans = filterListOfPlans(planOptions, 'free')
  const planOptionsWithoutAgencyPlans = filterListOfPlans(planOptions, 'agency')

  if (shouldIncludeAgencyPlan) {
    return planOptionsWithoutFreePlans
  }

  if (isOnFreePlan) {
    const plansWithoutFreeOrAgency = filterListOfPlans(
      planOptionsWithoutFreePlans,
      'agency',
    )

    return plansWithoutFreeOrAgency
  }

  return planOptionsWithoutAgencyPlans
}

// Calculates whether the ChannelConnectionModal should be displayed
// Raules are to only show this modal if the user is trying to connect more channels
// than they have already paid for
export function getShouldShowChannelConnectionModal(args: {
  user: Account
  modalData: any
}): boolean {
  const { user, modalData } = args
  const isOnTrial = isOnActiveTrial(user)
  // Preparing the data for the "shouldShowChannelConnectionModal" definition
  const channelIds = modalData?.channelIds || []
  const currentChannelQuantity = isOnTrial
    ? TRIAL_CHANNELS_LIMIT
    : getUserChannelSlots(user)
  const currentUserUnlockedChannels = getUserUnlockedChannels(user)
  const userChannelsWithoutNewlyConnected = currentUserUnlockedChannels.filter(
    (currentChannel) => !channelIds.includes(currentChannel.id),
  )

  const newChannelQuantity = calculateNewQuantityWithChannelIncrease({
    numberOfUnlockedChannels: userChannelsWithoutNewlyConnected.length,
    currentChannelSlots: currentChannelQuantity || TRIAL_CHANNELS_LIMIT,
    numberOfNewlyConnectedChannels: channelIds.length,
  })

  // We should not show the modal under the following conditions
  // 1. Customer is OneBuffer
  // 2. Resulting new quantity is higher than the available slots.
  // 3. USer is on active trial and new quantity is higher than the trial limit
  return !!(
    user?.currentOrganization?.isOneBufferOrganization &&
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    newChannelQuantity > currentChannelQuantity
  )
}

export function hasSeenChecklistCompleteModal(user: Account): boolean {
  if (
    user?.currentOrganization?.dismissedObjects?.find(
      (item: any) => item.id === 'welcome_checklist_complete',
    )
  ) {
    return true
  }

  return Boolean(localStorage.getItem('isChecklistCompleteModalDismissed'))
}

export function hasSeenTrialSignupWelcomeModal(user: Account): boolean {
  if (
    user?.currentOrganization?.dismissedObjects?.find(
      (item: any) => item.id === 'trial_signup_welcome_modal',
    )
  ) {
    return true
  }

  return Boolean(localStorage.getItem('isChecklistCompleteModalDismissed'))
}

export function hasDismissedTrialExpiredModal(user: Account): boolean {
  if (
    user?.currentOrganization?.dismissedObjects?.find(
      (item: DismissedObject) => item.id === 'trial_expired_modal',
    )
  ) {
    return true
  }

  return Boolean(getCookie({ key: 'trialOverDismissed' }))
}

export function shouldTriggerChannelOnboardingModalFromUrl(): boolean {
  const urlParams = new URLSearchParams(window.location.search)

  return Boolean(urlParams.get('channelConnectionSuccess') === 'true')
}

export function shouldTriggerChannelRefreshModalFromUrl(): boolean {
  const urlParams = new URLSearchParams(window.location.search)

  return Boolean(urlParams.get('channelRefreshSuccess') === 'true')
}

export function shouldShowChannelConnectionOnboardingModal(
  user?: Account,
  channelIds?: string[],
): boolean {
  if (!user || !channelIds) return false

  const usersChannelList = getUserUnlockedChannels(user)
  const channelId = channelIds[0]
  const channelData = getChannelFromList(channelId, usersChannelList)

  return Boolean(channelData) && !shouldSkipCelebrationModals()
}

export function shouldSkipCelebrationModals(): boolean {
  const urlParams = new URLSearchParams(window.location.search)
  return Boolean(urlParams.get('skipCelebrationModal'))
}

export function shouldPrefillComposerDataAfterConnection(): boolean {
  const urlParams = new URLSearchParams(window.location.search)
  return Boolean(urlParams.get('prefillDraftData'))
}

export function isEmailVerificationError(error: ErrorState): boolean {
  const emailVerificationError =
    'Your email must be verified in order to select a plan.'

  return error?.message === emailVerificationError
}

export function shouldTriggerSuccessModalFromUrl(): boolean {
  const urlParams = new URLSearchParams(window.location.search)

  return Boolean(urlParams.get('checkoutSuccess') === 'true')
}

/**
 * Removes specific URL parameters from the current window's URL.
 * @returns void
 */
export function cleanupUrlParams(): void {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.delete('channelConnectionSuccess')
  urlParams.delete('channelRefreshSuccess')
  urlParams.delete('channelId')
  window.history.replaceState(null, '', `${window.location.pathname}`)
}

/**
 * Return specific user data for the PlanSelector component.
 */
type UserDataForPlanSelector = {
  organizationId?: string
  isAFreeUser: boolean
  isActiveTrial: boolean
  userHasPaymentDetails: boolean
  planOptions: any
  isAgencyUser: boolean
  isPaidRevenueCat: boolean
  billingData?: Billing
  currentQuantity: number
  initialChannelQuantity: number
  hasTeamMembers: boolean
  isPayingWithBank: boolean
}

export function getUserDataForPlanSelector(
  user: Account,
): UserDataForPlanSelector {
  const experimentChannelCount = getUserChannelCount(user) || 1
  const isAFreeUser = isFreeUser(user)
  const { currentQuantity }: ChannelSlotDetails =
    getUsersCurrentChannelSlotDetails(user) || ({} as ChannelSlotDetails)

  return {
    organizationId: getOrganizationId(user),
    isAFreeUser,
    isActiveTrial: isOnActiveTrial(user),
    userHasPaymentDetails: hasPaymentDetails(user) || false,
    planOptions: getChangePlanOptions(user),
    isAgencyUser: isAgencyUser(user) || isOnAgencyTrial(user),
    isPaidRevenueCat: isPaidRevenueCatBillingGateway(user),
    billingData: getUserBillingData(user),
    currentQuantity,
    initialChannelQuantity:
      (isAFreeUser && experimentChannelCount) || currentQuantity,
    hasTeamMembers: hasTeamMembers(user),
    isPayingWithBank: isPaymentMethodBank(user),
  }
}

export function getBillingDataForPlanSelector(billingData: Billing): {
  currentPlan?: Plan
  currentPlanInterval?: PlanInterval
} {
  return {
    currentPlan: getSubscriptionPlanData(billingData),
    currentPlanInterval: getSubscriptionInterval(billingData),
  }
}

/**
 * Helper function to get the base price of a plan by its ID and interval.
 *
 * @param planId - The ID of the plan.
 * @param interval - The interval of the plan ('month' or 'year').
 * @param planOptions - Array of available plan options.
 * @returns The base price of the plan or 0 if plan cannot be found.
 */
function getPlanPrice(
  planId: string | undefined,
  interval: PlanInterval.month | PlanInterval.year,
  planOptions: ChangePlanOption[],
): number | null {
  if (planId) {
    const plan = planOptions.find(
      (option) => option.planId === planId && option.planInterval === interval,
    )
    return plan && plan.basePrice ? plan.basePrice : null
  }
  return null
}

/**
 * Function to calculate the absolute savings when choosing a yearly plan
 * over a monthly plan.
 *
 * @param channelsCount - The number of channels selected in the counter.
 * @param selectedPlan - The selected plan option.
 * @param planOptions - Array of available plan options.
 * @returns A string describing the savings per year or null id we cannot calculate it.
 */
export function getAbsoluteSavings(
  channelsCount: number,
  selectedPlan: ChangePlanOption,
  planOptions: ChangePlanOption[],
): string | null {
  const { currency, planInterval, planId, basePrice } = selectedPlan

  if (basePrice === undefined) return null

  // Determine the monthly and yearly prices based on the selected plan's interval.
  const monthlyPrice =
    planInterval === PlanInterval.month
      ? basePrice
      : getPlanPrice(planId, PlanInterval.month, planOptions)

  const yearlyPrice =
    planInterval === 'year'
      ? basePrice
      : getPlanPrice(planId, PlanInterval.year, planOptions)

  if (
    channelsCount > maxChannelsAllowedPerPurchaseViaStripe ||
    !monthlyPrice ||
    !yearlyPrice
  ) {
    return null
  }

  // Calculate the annual savings by comparing the monthly and yearly prices.
  const planReductor = planId === 'agency' ? 10 : 1
  const monthlySaving = (monthlyPrice - yearlyPrice) / planReductor
  const annualSavings =
    planId === 'agency'
      ? (monthlySaving * Math.min(channelsCount, 10) +
          monthlySaving * 0.5 * Math.max(channelsCount - 10, 0)) *
        12
      : monthlySaving * channelsCount * 12

  // Format the savings amount with commas for readability.
  const formattedSavings = annualSavings.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const savingsLabel = planInterval === 'year' ? 'Saving' : 'Save'

  return `${savingsLabel} ${currency}${formattedSavings}/year`
}

export enum DeviceType {
  Android = 'Android',
  IOS = 'iOS',
  Desktop = 'Desktop',
}

// Function to determine the device type
export function getDeviceType(): DeviceType {
  const userAgent = navigator.userAgent

  // Check for Android devices
  if (/android/i.test(userAgent)) {
    return DeviceType.Android
  }

  // Check for iOS devices (iPhone, iPad, iPod)
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return DeviceType.IOS
  }

  // If none of the above, assume desktop
  return DeviceType.Desktop
}

export const shouldShowDownloadAppModal = (): boolean => {
  const deviceUsed = getDeviceType()
  return deviceUsed !== DeviceType.Desktop
}

export const getAppLink = (deviceUsed: DeviceType): string => {
  switch (deviceUsed) {
    case DeviceType.Android:
      return 'https://play.google.com/store/apps/details?id=org.buffer.android&utm_source=buffer-web-onboarding&utm_campaign=buffer-native-app-download-prompt'
    case DeviceType.IOS:
      return 'https://apps.apple.com/app/apple-store/id490474324?pt=936146&ct=buffer-native-app-download-prompt&mt=8'
    default:
      return ''
  }
}

/**
 *
 * CONDITIONS TO SHOW THE TRIAL EXPIRED MODAL:
 *  - The user is in the publish product
 *  - The user cannot start a free trial - in this case we have to show the startTrial modal
 *  - The user has not dismissed the trial expired modal
 *  - The system is awaiting user action - it means that the last billing event received from Stripe was a trial ended one
 *  - The user has permissions to manage billing
 *
 * @returns true if the conditions to show the trial expired modal are met
 */
export const shouldShowTrialExpiredModal = (user: Account): boolean => {
  const isAwaitingUserAction =
    user?.currentOrganization?.billing?.subscription?.trial
      ?.isAwaitingUserAction || false
  const hasDismissedTrialExpModal = hasDismissedTrialExpiredModal(user)
  const userCanManageBilling = hasPermissionToManageBilling(user) || false

  /* we have to check that the user cannot start a trial because it could be that the user had a trial
     more than 6 months ago, so isAwaitingUserAction is true and canStartTrial is also true */

  return (
    getActiveProductFromPath() === ProductNames.PUBLISH &&
    !isComposerOpen() &&
    isAwaitingUserAction &&
    userCanManageBilling &&
    !userCanStartFreeTrial(user) &&
    !hasDismissedTrialExpModal
  )
}

/**
 *
 * CONDITIONS TO SHOW THE TEAM MEMBER DOWNGRADE MODAL:
 *  - The user is a team member
 *  - The team member organization is in the free plan
 *  - shouldShowTeamMemberOrganizationDowngradedBanner is true for admin and non admin team members
 *
 * @returns true if the conditions to show the team member downgrade modal are met
 */
export const shouldShowTeamMemberDowngradeModal = (user: Account): boolean => {
  return (
    user?.currentOrganization
      ?.shouldShowTeamMemberOrganizationDowngradedBanner || false
  )
}

/**
 *
 * CONDITIONS TO SHOW THE TRIAL SIGNUP WELCOME MODAL:
 *  - The user is on a trial
 *  - The user is on Publish
 *  - The user has not seen the trial signup welcome modal (dismissedObjects)
 *
 * @returns true if the conditions to show the team member downgrade modal are met
 */
export const shouldShowTrialSignupWelcomeModal = (user: Account): boolean => {
  return (
    isOnActiveTrial(user) &&
    getActiveProductFromPath() === ProductNames.PUBLISH &&
    !hasSeenTrialSignupWelcomeModal(user)
  )
}

/**
 * Checks if the user has signed up more than one week ago.
 *
 * @param user - The user account to check.
 * @returns A boolean indicating whether the user signed up more than a week ago.
 */
export const userHasSignedUpOneWeekAgo = (user: Account): boolean => {
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000
  const userCreationDate = new Date(user.createdAt || Date.now())
  const oneWeekAgo = new Date(Date.now() - oneWeekInMilliseconds)

  return userCreationDate < oneWeekAgo
}

/**
 * Determines if the user has completed a streak based on their weekly posting streak data.
 *
 * @param streak - The WeeklyPostingStreakData object containing the user's streak information.
 * @returns A boolean indicating whether the user has completed a streak (true) or not (false).
 */
export const userHasCompletedAStreak = (
  streak?: WeeklyPostingStreakData['weeklyPostingStreak'],
): boolean => {
  return (streak?.longestCount ?? 0) > 0
}
