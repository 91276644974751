import { RESTRICTED_SERVICES_FOR_MP_USERS } from '../../../common/constants/channels'
import { getProductUrl } from '../../../common/hooks/useGetPortalSession'
import type { Account } from '../../../common/types'
import { getCookie } from '../../../common/utils/cookies'
import { getAccountBaseUrl } from '../../../common/utils/urls'
import {
  isAtChannelLimit,
  isAtTrialChannelLimit,
  isFreeUser,
  isOneBufferOrganization,
} from '../../../common/utils/user'

import { type AuthMethods, InstagramAuthMethod, Service } from './types'

export const getRedirectUri = <T extends Service>(
  service: T,
  authMethod?: AuthMethods[T],
): string => {
  const baseURL = getAccountBaseUrl()

  if (authMethod === InstagramAuthMethod.instagramLogin) {
    return `${baseURL}/oauth/instagram_login_api/callback`
  }

  return `${baseURL}/oauth/${service}/callback`
}

function writeCookie(key: string, value: string, expires: string): void {
  document.cookie = `oauthChannels${key}=${value};path=/;expires=${expires};domain=.buffer.com`
}

export function expireCookie(key: string, value: string | null): void {
  if (value) {
    writeCookie(key, value, 'Thu, 01 Jan 1970 00:00:00 UTC')
  }
}

export function setCookie(key: string, value: string): void {
  const now = new Date()
  // In case something goes wrong we don't want the cookie to persist and potentially create side effects
  const FiveMinutes = 5 * 60 * 1000
  now.setTime(now.getTime() + FiveMinutes)
  writeCookie(key, value, now.toUTCString())
}

// We need this cookie to keep track of the state code after the third party login redirect
// and check this cookie state matches the state in the rediretURI to ensure nothing melicious happened inbetween the redirect
export function setChannelStateCookies(service: Service, state: string): void {
  const now = new Date()
  const FifteenMinutes = 15 * 60 * 1000
  now.setTime(now.getTime() + FifteenMinutes)

  writeCookie(service, state, now.toUTCString())
}

export function setReconnectingChannelCookie(
  selectedRefreshChannelId: string,
): void {
  const now = new Date()
  const FifteenMinutes = 15 * 60 * 1000
  now.setTime(now.getTime() + FifteenMinutes)

  writeCookie(
    'ReconnectingChannel',
    selectedRefreshChannelId,
    now.toUTCString(),
  )
}

export function getReconnectingChannelCookie(): string | null {
  const channelId = getCookie<string>({
    key: 'ReconnectingChannel',
    prefix: 'oauthChannels',
  })

  expireCookie('ReconnectingChannel', channelId)

  return channelId
}

export function setRedirectURLCookie(url: string): void {
  const now = new Date()
  const FifteenMinutes = 15 * 60 * 1000
  now.setTime(now.getTime() + FifteenMinutes)

  writeCookie('RedirectURL', url, now.toUTCString())
}

export function setBufferPageSourceURLCookie(url: string): void {
  const now = new Date()
  const FifteenMinutes = 15 * 60 * 1000
  now.setTime(now.getTime() + FifteenMinutes)

  writeCookie('BufferPageSourceURL', url, now.toUTCString())
}

export const getServiceName = (service: Service): string => {
  if (service === Service.googlebusiness) return 'Google'
  if (service === Service.twitter) return 'Twitter / X'
  if (service === Service.youtube) return 'YouTube'
  if (service === Service.linkedin) return 'LinkedIn'
  if (service === Service.tiktok) return 'TikTok'
  return capitalizeFirstLetter(service)
}

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.substring(1)

export function checkShouldShowPaywallUpgrade(
  account: Account,
  service: Service | null,
  isUpgradePath = false,
): boolean {
  // This checks if the account has loaded the information about the currentOrganization
  // if the account has not loaded the currentOrganization then we would show the Paywall
  // in cases where the user should not see it
  if (Object.keys(account?.currentOrganization || {}).length === 0) return false

  if (!service && !isUpgradePath) return false

  const isAtPlanChannelLimit = isAtChannelLimit(account)
  // If a user is on a trial plan and they are trying to connect a new channel and their limit is reached
  if (isAtTrialChannelLimit(account, service)) return true

  const isMPUser = !isOneBufferOrganization(account)

  // MP users must upgrade to a new Buffer plan to access certain channels
  const MPCheckForUpgradePath = Boolean(isMPUser && isUpgradePath)
  if (
    (isMPUser &&
      service &&
      RESTRICTED_SERVICES_FOR_MP_USERS.includes(service)) ||
    MPCheckForUpgradePath
  ) {
    return true
  }

  // If a user is not at their channel limit they do not need to see a paywall
  if (!isAtPlanChannelLimit) {
    return false
  }

  // At this stage this means that a customer is trying to connect a new channel over their plans limit.
  // ---------------------------------------------------------------------------------------------------

  // These states should be shown the Paywall Upgrade Modal:
  // - If a user is not on New Buffer
  // OR
  // - If a user is on a Free plan
  if (!isOneBufferOrganization(account) || isFreeUser(account)) {
    return true
  }

  // Special case: Triggering the paywall via an upgrade path
  // Curently being used by Global Plus Button in Nav
  if (isUpgradePath && isAtPlanChannelLimit) {
    return true
  }

  return false
}

export function setShouldFollowBufferCookie(): void {
  const now = new Date()
  const FifteenMinutes = 15 * 60 * 1000
  now.setTime(now.getTime() + FifteenMinutes)

  writeCookie('FollowBuffer', 'true', now.toUTCString())
}

export function setConnectionSuccessQueryParamsForCustomChannel(
  channelId: string,
): URL {
  const url = new URL(
    `${getProductUrl('publish')}/profile/${channelId}/tab/queue`,
  )
  url.searchParams.append('channelId', channelId)
  url.searchParams.set('channelConnectionSuccess', 'true')
  return url
}

export function setMastondonServer(server: string): void {
  const now = new Date()
  const FifteenMinutes = 15 * 60 * 1000
  now.setTime(now.getTime() + FifteenMinutes)

  writeCookie('MastodonServer', server, now.toUTCString())
}

export function handleSettingCookiesForConnection({
  server,
  followBuffer,
  selectedRefreshChannelId,
}: {
  server?: string
  followBuffer?: boolean
  selectedRefreshChannelId?: string
}): void {
  if (followBuffer) {
    setShouldFollowBufferCookie()
  }

  if (selectedRefreshChannelId) {
    setReconnectingChannelCookie(selectedRefreshChannelId)
  }

  if (server) {
    setMastondonServer(server)
  }
}

// This function is used to determine if a service requires further configuration
// such as gathering additional information from the user before they can connect a channel
// usually involving an extra UI step
export function serviceRequiresFurtherConfiguration({
  service,
  isRefreshingConnection,
}: {
  service: Service
  isRefreshingConnection: boolean
}): boolean {
  const isServiceThatRequiresFurtherConfiguration =
    service === Service.instagram ||
    service === Service.instagram_login_api ||
    service === Service.mastodon ||
    service === Service.facebook

  // Bluesky requires further configuration for both new conenctions and refreshing
  if (service === Service.bluesky) {
    return true
  }

  return isServiceThatRequiresFurtherConfiguration && !isRefreshingConnection
}

export function getCustomChannelIdToConvertFromParams(): string | undefined {
  const params = new URLSearchParams(document.location.search)
  const state = params.get('state')
  const channelIdToConvert = state?.split('custom_channel_id_to_convert_')[1]
  return channelIdToConvert || undefined
}

export const avatarLimits = {
  MIN_WIDTH: 180,
  MIN_HEIGHT: 180,

  MAX_WIDTH: 1024,
  MAX_HEIGHT: 1024,

  MAX_SIZE: 2 * 1024 * 1024,
}

export function getClampedImageSize(
  width: number,
  height: number,
): {
  width: number
  height: number
} {
  if (width > height) {
    if (width > avatarLimits.MAX_WIDTH) {
      return {
        width: avatarLimits.MAX_WIDTH,
        height: Math.round(height * (avatarLimits.MAX_WIDTH / width)),
      }
    }
  } else {
    if (height > avatarLimits.MAX_HEIGHT) {
      return {
        width: Math.round(width * (avatarLimits.MAX_HEIGHT / height)),
        height: avatarLimits.MAX_HEIGHT,
      }
    }
  }

  return { width, height }
}

export function isImageTooLarge(image: {
  width: number
  height: number
}): boolean {
  const { width, height } = image

  return width > avatarLimits.MAX_WIDTH || height > avatarLimits.MAX_HEIGHT
}

export function getImage(file: File): Promise<{
  file: File
  fileDataUrl: string
  width: number
  height: number
}> {
  return new Promise((resolve) => {
    const fileAsObjectUrl = URL.createObjectURL(file)
    const img = new Image()

    img.onload = async (): Promise<void> => {
      if (
        isImageTooLarge({
          width: img.width,
          height: img.height,
        })
      ) {
        const { width, height } = getClampedImageSize(img.width, img.height)
        const newImg = await createImageBitmap(img, {
          resizeHeight: height,
          resizeWidth: width,
        })
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')

        if (ctx) {
          ctx.drawImage(newImg, 0, 0)

          canvas.toBlob((blob) => {
            if (blob) {
              // Return resized image as a file
              resolve({
                fileDataUrl: canvas.toDataURL(file.type),
                file: new File([blob], file.name, {
                  type: file.type,
                  lastModified: file.lastModified,
                }),
                width,
                height,
              })
            } else {
              // Return original image if getting blob fails
              resolve({
                fileDataUrl: fileAsObjectUrl,
                file,
                width: img.width,
                height: img.height,
              })
            }
          }, file.type)
        } else {
          // Return original image if canvas context is not available
          resolve({
            fileDataUrl: fileAsObjectUrl,
            file,
            width: img.width,
            height: img.height,
          })
        }
      } else {
        // Return original image if it's not too large
        resolve({
          fileDataUrl: fileAsObjectUrl,
          file,
          width: img.width,
          height: img.height,
        })
      }
    }
    img.src = fileAsObjectUrl
  })
}

export function validateDimensions(width: number, height: number): boolean {
  return (
    width >= avatarLimits.MIN_WIDTH &&
    height >= avatarLimits.MIN_HEIGHT &&
    width <= avatarLimits.MAX_WIDTH &&
    height <= avatarLimits.MAX_HEIGHT
  )
}
