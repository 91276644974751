import React from 'react'
import HashtagGroupItem from '../HashtagGroupItem'
import countHashtagsInText from '../../utils/HashtagCounter'
import type { HashtagGroup } from '../../types'

const contentStyle: React.CSSProperties = {
  flex: '1',
  overflowY: 'auto',
  paddingTop: '24px',
  paddingBottom: '46px',
}

interface HashtagGroupListProps {
  hashtagGroups: HashtagGroup[]
  onDeleteHashtagGroupClick: (group: HashtagGroup) => void
  onEditHashtagGroupClick: (group: HashtagGroup) => void
  onInsertHashtagGroupClick: (name: string, text: string) => void
}

const HashtagGroupList = ({
  hashtagGroups,
  onInsertHashtagGroupClick,
  onEditHashtagGroupClick,
  onDeleteHashtagGroupClick,
}: HashtagGroupListProps): JSX.Element => {
  const children = hashtagGroups.map((group) => (
    <HashtagGroupItem
      key={group.id || group._id}
      numberOfHashtags={countHashtagsInText(group.text)}
      group={group}
      onInsertHashtagGroupClick={(): void => {
        onInsertHashtagGroupClick(group.name, group.text)
      }}
      onEditHashtagGroupClick={onEditHashtagGroupClick}
      onDeleteHashtagGroupClick={onDeleteHashtagGroupClick}
    />
  ))
  return <div style={contentStyle}>{children}</div>
}

export default HashtagGroupList
