import { useEffect, useRef, useState } from 'react'
import Brainfish from '@brainfish-ai/web-widget'
import { env } from '../../env'
import type { Account } from '../types'

interface BrainfishWidgetProps {
  shouldShowBrainfish: boolean
  user: Account
}

// prettier-ignore
/* eslint-disable */
export const useBrainfishWidget = ({
  shouldShowBrainfish,
  user,
}: BrainfishWidgetProps) => {
  const isInitialized = useRef(false)
  const isIdentified = useRef(false)
  const [isReady, setIsReady] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!shouldShowBrainfish || isInitialized.current) return

    const initBrainfish = async () => {
      try {
        await Brainfish.Widgets.init({
          widgetKey: env.BRAINFISH_WIDGET_KEY,
        })

        if (window.Beacon) {
          window.Beacon('on', 'open', () => Brainfish.Widgets.close())
        }

        setIsReady(true)
      } catch (error) {
        console.error('Failed to initialize Brainfish:', error)
        setIsReady(false)
      }
    }

    initBrainfish()
    isInitialized.current = true
  }, [shouldShowBrainfish])

  useEffect(() => {
    if (
      !shouldShowBrainfish ||
      isIdentified.current ||
      !isInitialized.current ||
      !user.id ||
      user.isImpersonation
    ) {
      return
    }

    const identifyBrainfish = async () => {
      try {
        window.BrainfishAnalytics('identify', {
          userId: user.id,
          email: user.email,
          properties: {
            signUpDate: user.createdAt,
            name: user?.name,
            channelCountAcrossAllOrgs: user.channels?.length,
            organizationCount: user.organizations?.length,
            currentOrganizationId: user?.currentOrganization?.id,
            hasVerifiedEmail: user.hasVerifiedEmail,
          },
        })
      } catch (error) {
        console.error('Failed to identify user in Brainfish:', error)
      }
    }

    identifyBrainfish()
    isIdentified.current = true
  }, [user, isInitialized.current])

  const openBrainfish = () => {
    Brainfish.Widgets.open()
    setIsOpen(true)
  }

  const closeBrainfish = () => {
    Brainfish.Widgets.close()
    setIsOpen(false)
  }

  const handleOpen = () => {
    const button = document.querySelector('.brainfish-trigger-button')
    const isBeaconOpen =
      window?.Beacon && window?.Beacon('info')?.status?.isOpened
    if (button && isBeaconOpen) {
      button.classList.add('hidden')
    }
    setIsOpen(true)
  }

  const handleClose = () => {
    const isBeaconOpen =
      window?.Beacon && window?.Beacon('info')?.status?.isOpened
    if (!isBeaconOpen) {
      const button = document.querySelector('.brainfish-trigger-button')
      if (button) {
        button.classList.remove('hidden')
      }
    }
    setIsOpen(false)
  }

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('on', 'open', handleOpen)
      window.Beacon('on', 'close', handleClose)
    }
    window.addEventListener('onBrainfishWidgetOpen', handleOpen)
    window.addEventListener('onBrainfishWidgetClose', handleClose)

    return () => {
      window.removeEventListener('onBrainfishWidgetOpen', handleOpen)
      window.removeEventListener('onBrainfishWidgetClose', handleClose)
    }
  }, [window.Beacon])

  return {
    openBrainfish,
    closeBrainfish,
    isReady,
    isOpen,
  }
}
