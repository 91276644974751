import React, { useEffect, useState, useRef } from 'react'

import type { Draft } from '../../composer/composer/entities/Draft'
import ComposerActionCreators from '../../composer/composer/action-creators/ComposerActionCreators'
import { ActionButtonMessage, SplitBarButtonLinkShortener } from '../styles'
import { toast, Tooltip } from '@buffer-mono/popcorn'

import { LinkShortenerIcon } from './LinkShortenerIcon'
import { trackLinkShortenerToggleClick } from '../tracking'
import { useAppDispatch } from '~publish/legacy/store'
import { changeLinkShortener as changeLinkShortenerThunk } from '~publish/legacy/general-settings/thunks/changeLinkShortener'
import { LinkShorteningCoachmark } from './LinkShorteningCoachmark'

const NO_SHORTENING_OPTION = 'No Shortening'
const DEFAULT_SHORTENER = 'buff.ly'

export const LinkShortenerButton = ({
  draft,
  selectedProfileId,
  domainForLinkShorteningSelectedProfile,
}: {
  draft: Draft
  selectedProfileId: string
  domainForLinkShorteningSelectedProfile: string
}): React.JSX.Element | null => {
  const [showMessage, setShowMessage] = useState(false)
  const [showingMessageInProgress, setShowingMessageInProgress] =
    useState(false)
  const hasBeenRendered = React.useRef(false)
  const lastSaveRequest = useRef<string | undefined>(undefined)
  const dispatch = useAppDispatch()

  useEffect(() => {
    function hasSelectedProfileEnabledShortening(): boolean {
      return domainForLinkShorteningSelectedProfile !== NO_SHORTENING_OPTION
    }

    const isFirstRender = !hasBeenRendered.current

    if (isFirstRender) {
      hasBeenRendered.current = true

      ComposerActionCreators.updateShouldShortenLinks(
        draft.id,
        hasSelectedProfileEnabledShortening(),
      )
      ComposerActionCreators.updateRegenerateShortenedLinks(
        draft.id,
        hasSelectedProfileEnabledShortening(),
      )
    } else {
      dispatch(
        changeLinkShortenerThunk({
          profileId: selectedProfileId,
          domain: draft.shortenLinksToggle
            ? DEFAULT_SHORTENER
            : NO_SHORTENING_OPTION,
        }),
      )
    }
  }, [])

  const onToggleLinkShortener = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.preventDefault()

    const newStatusShortenLinks = !draft.shortenLinksToggle

    ComposerActionCreators.updateShouldShortenLinks(
      draft.id,
      newStatusShortenLinks,
    )

    const oldDomain = draft.shortenLinksToggle
      ? DEFAULT_SHORTENER
      : NO_SHORTENING_OPTION
    const newDomain = newStatusShortenLinks
      ? DEFAULT_SHORTENER
      : NO_SHORTENING_OPTION
    lastSaveRequest.current = newDomain

    dispatch(
      changeLinkShortenerThunk({
        profileId: selectedProfileId,
        domain: newDomain,
      }),
    ).then((response) => {
      // transform links to shortened/expanded only if request was successful and domain matches
      // NOTE - if we do not await successful saving, we expose ourselves to a race condition.
      // Link shortening relies on state saved server side (the link shortening preference)
      // so we run the risk of regenerating shortened links before the new preference is saved
      if (
        response.meta.requestStatus === 'fulfilled' &&
        newDomain === lastSaveRequest.current
      ) {
        ComposerActionCreators.updateRegenerateShortenedLinks(
          draft.id,
          newStatusShortenLinks,
        )
      } else if (
        response.meta.requestStatus === 'rejected' &&
        newDomain === lastSaveRequest.current
      ) {
        // rollback on failure + send a toast to the user
        lastSaveRequest.current = oldDomain
        toast.error('Failed to save link shortening preference')
        ComposerActionCreators.updateShouldShortenLinks(
          draft.id,
          !newStatusShortenLinks,
        )
      }
    })

    setShowMessage(true)
    setShowingMessageInProgress(true)

    setTimeout(() => {
      setShowMessage(false) // Hide the message after a delay
      setShowingMessageInProgress(false)

      // track the event if the toggle is turned on/off
      const isShortened = draft.shortenLinksToggle
      trackLinkShortenerToggleClick(isShortened)
    }, 1500)
  }

  const shouldDisplayLinkShortenerButton =
    !draft.isStoryPost() && !draft.service.isOmni

  if (!shouldDisplayLinkShortenerButton) return null

  return (
    <Tooltip
      content={
        'Link Shortening is ' + (draft.shortenLinksToggle ? 'on' : 'off')
      }
      sideOffset={5}
      data-state="instant-open"
    >
      <SplitBarButtonLinkShortener
        className="link-shortening-icon"
        data-testid="link-shortening-icon"
        onClick={onToggleLinkShortener}
        data-tip={
          'Link Shortening is ' + (draft.shortenLinksToggle ? 'on' : 'off')
        }
        aria-label="link shortening"
        enabled={draft.shortenLinksToggle}
        showMessage={showMessage}
      >
        <LinkShorteningCoachmark
          isShorteningEnabled={draft.shortenLinksToggle}
        />
        <LinkShortenerIcon isDisabled={!draft.shortenLinksToggle} />
        <ActionButtonMessage shouldShow={showMessage}>
          {'Shortening ' + (draft.shortenLinksToggle ? 'on' : 'off')}{' '}
        </ActionButtonMessage>
      </SplitBarButtonLinkShortener>
    </Tooltip>
  )
}
