import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSplitEnabled } from '@buffer-mono/features'
import { Dialog, ArrowLeftIcon, IconButton } from '@buffer-mono/popcorn'

import { setShowChannelStorefront } from '../../../../../../store/channelConnectionsReducer'
import type { OrchestratorRootState } from '../../../../../../../../common/events/types'

export function BackButton({ onBack }: { onBack?: () => void }): JSX.Element {
  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  const dispatch = useDispatch()
  const { cta } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )

  function backToChannelStorefront(): void {
    dispatch(setShowChannelStorefront({ cta }))
  }

  // TODO: This check can be removed after the Account up gets a visual refresh
  // The channel storefront page will be made obsolete.
  // We are checking this now to avoid showing the channel stroefront modal ontop of the old page
  const isOnChannelStorefront =
    window.location.href.includes('/channels/connect') ||
    !isChannelStorefrontEnabled

  if (isOnChannelStorefront) {
    return (
      <Dialog.Close>
        <IconButton label="Close" variant="tertiary" size="small">
          <ArrowLeftIcon />
        </IconButton>
      </Dialog.Close>
    )
  }

  if (onBack) {
    return (
      <IconButton
        onClick={(): void => onBack()}
        label="Back"
        variant="tertiary"
        size="small"
      >
        <ArrowLeftIcon />
      </IconButton>
    )
  }

  return (
    <IconButton
      onClick={backToChannelStorefront}
      label="Back to channel storefront"
      variant="tertiary"
      size="small"
    >
      <ArrowLeftIcon />
    </IconButton>
  )
}
