import type { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions'
import { graphql } from '~publish/gql'
import { client } from '~publish/legacy/apollo-client'
import { UpdatedNoteOnPost } from './useAddNoteToPost'
import { useTypedMutation } from '../../hooks/useTypedMutation'

export const UpdateNoteOnPost = graphql(/* GraphQL */ `
  mutation UpdateNoteOnPost(
    $postId: PostId!
    $noteId: NoteId!
    $text: String!
  ) {
    updateNoteOnPost(input: { postId: $postId, noteId: $noteId, text: $text }) {
      __typename
      ... on UpdateNoteOnPostSuccess {
        post {
          id
          notes {
            ...PostCardNotes_Note
          }
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useUpdateNoteOnPost = () =>
  useTypedMutation(UpdateNoteOnPost, (data) => data.updateNoteOnPost, {
    successTypename: 'UpdateNoteOnPostSuccess',
    optimisticResponse: (
      { postId, noteId, text },
      { IGNORE },
    ): MutationBaseOptions['optimisticResponse'] => {
      const existingPost = client.readFragment({
        id: client.cache.identify({ __typename: 'Post', id: postId }),
        fragment: UpdatedNoteOnPost,
      })

      if (!existingPost) {
        return IGNORE
      }
      const updatedNotes = existingPost.notes.map((note) => {
        if (note.id === noteId) {
          return { ...note, text, updatedAt: new Date().toISOString() }
        }
        return note
      })

      return {
        __typename: 'Mutation',
        updateNoteOnPost: {
          __typename: 'UpdateNoteOnPostSuccess',
          post: {
            id: postId,
            __typename: 'Post',
            notes: updatedNotes,
          },
        },
      }
    },
  })
