export const DATES = {
  inMonthsFromNow(months: number): Date {
    const now = new Date()

    return new Date(now.setMonth(now.getMonth() + months))
  },
  inDaysFromNow(days: number): Date {
    const now = new Date()

    return new Date(now.setHours(now.getHours() + days * 24))
  },
}

export function setCookie({
  key,
  value,
  expires,
}: {
  key: string
  value: string | boolean
  expires: Date
}): void {
  document.cookie = `appshell_${key}=${value};domain=.buffer.com;path=/;expires=${expires.toUTCString()}`
}

export function getCookie<T = string | boolean | null>({
  key,
  prefix = 'appshell_',
}: {
  key: string
  prefix?: string
}): T {
  const regex = new RegExp(`${prefix}${key}=([^;]+)`, 'g')
  const cookie = document.cookie.match(regex) || [null]
  const [, value] = cookie[0]?.split('=') || [null, false]

  return value as T
}
