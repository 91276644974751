import React, { useState } from 'react'
import { ChannelFieldCharacterCount, Input } from '../../channel-fields/styles'
import DateOptionalTimePicker from '../Common/DateOptionalTimePicker'
import ButtonSelect from '../Common/ButtonSelect/ButtonSelect'
import { Label, SelectLabel, Wrapper } from '../styles'
import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'

import type { GoogleBusinessChannelData } from '../../../entities/Draft/types'

const EventBar = ({
  data,
  uses24hTime,
}: {
  data: GoogleBusinessChannelData
  uses24hTime: boolean
}): JSX.Element => {
  const [shouldShowLink, setShouldShowLink] = useState(
    data.cta && data.cta !== 'none',
  )

  const onInputChange = (
    val: string,
    attr: keyof GoogleBusinessChannelData,
  ): void => {
    ComposerActionCreators.updateDraftGoogleBusinessData({
      ...data,
      [attr]: val,
    })
  }

  return (
    <>
      <Wrapper>
        <Label>Event Title</Label>
        <Input
          onChange={(e): void => onInputChange(e.target.value, 'title')}
          value={data.title ?? ''}
        />
        {data.title && data.title.length > 50 && (
          <ChannelFieldCharacterCount
            count={data.title?.length}
            maxCount={58}
          />
        )}
      </Wrapper>
      <DateOptionalTimePicker data={data} uses24hTime={uses24hTime} />
      <Wrapper>
        <SelectLabel>Button</SelectLabel>
        <ButtonSelect data={data} onSetShouldShowLink={setShouldShowLink} />
      </Wrapper>
      {shouldShowLink && (
        <Wrapper>
          <Label>Button Link</Label>
          <Input
            placeholder="Example: https://buffer.com"
            onChange={(e): void => onInputChange(e.target.value, 'link')}
            value={data.link ?? ''}
          />
        </Wrapper>
      )}
    </>
  )
}

export default EventBar
