import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  EmptyState,
  CriticalIcon,
  Text,
  useLocalStorage,
} from '@buffer-mono/popcorn'

import { PageLayout } from '~publish/components/PageLayout'
import { RegisteredBannersProvider } from '~publish/components/RegisteredBanner'
import { ThreadsFreeOBPaywall } from '~publish/legacy/composer/composer/components/ThreadsFreeOBPaywall'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { TimezoneProvider } from '~publish/hooks/useTimezone'
import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'
import { PostManagementRouter } from '~publish/components/PostManagementRouter'
import { ChannelError } from '~publish/components/ChannelError'
import { getMatch, newCalendarSingleChannel } from '~publish/legacy/routes'
import { useTimeFormatPreference } from '~publish/hooks/useTimeFormatPreference'
import { ALL_CHANNELS_TIMEZONE_STORAGE_KEY } from '~publish/components/AllChannelsTimeZoneSelect'

import { AllChannelsPausedQueueNotice } from '../AllChannels/AllChannelsPausedQueueNotice'
import { ChannelProvider } from '../Channel/ChannelContext'
import { ChannelPageNotifications, GetChannelInfo } from '../Channel'
import { CalendarHeader } from './CalendarHeader/CalendarHeader'
import { useCalendarState } from './hooks/useCalendarState'
import isViewOnly from './hooks/useIsViewOnly'
import { Calendar } from './Calendar'

import styles from './CalendarPage.module.css'

function CalendarPage(): JSX.Element {
  return (
    <>
      <PostManagementRouter />

      <CalendarProviders>
        <CalendarPageContent />
      </CalendarProviders>
    </>
  )
}

export default CalendarPage

const CalendarPageContent = (): JSX.Element => {
  const calendarState = useCalendarState()

  const { filters, postsQuery } = calendarState
  const { channelsFilter, tagsFilter, tags, channels, error, isSingleChannel } =
    filters

  const timeFormatPreference = useTimeFormatPreference()
  const hasTwentyFourHourTimeFormat = timeFormatPreference === '24'

  const viewOnly = isViewOnly({
    channelsSelected: channelsFilter.sanitized,
    listOfChannels: channels,
  })

  const [openModal, setOpenModal] = useState({
    open: false,
    ctaString: '',
    service: '',
  })

  const hasPausedQueues = channels.some((channel) => channel.isQueuePaused)

  if (error) {
    return (
      <EmptyState size="medium" variant="critical">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Failed to load</EmptyState.Heading>
        <EmptyState.Description>
          Error happened, please let our team know about it.{' '}
          <Text color="critical">{error.message}</Text>
        </EmptyState.Description>
      </EmptyState>
    )
  }

  return (
    <PageLayout className={styles.layout} legacyNotResponsive>
      {openModal?.open && (
        <PageLayout.Container>
          <ThreadsFreeOBPaywall
            closeFirstModal={(): void =>
              setOpenModal({ open: false, ctaString: '', service: '' })
            }
            ctaString={openModal.ctaString}
            service={openModal.service}
          />
        </PageLayout.Container>
      )}
      <PageLayout.NotificationsContainer>
        {isSingleChannel ? (
          <ChannelPageNotifications />
        ) : (
          hasPausedQueues && <AllChannelsPausedQueueNotice />
        )}
      </PageLayout.NotificationsContainer>
      <CalendarHeader
        tags={tags}
        isSingleChannel={isSingleChannel}
        selectedTagIds={tagsFilter.sanitized}
        onSelectTags={tagsFilter.onSelect}
        selectedChannelIds={channelsFilter.sanitized}
        channels={channels}
        onSelectChannels={channelsFilter.onSelect}
      />

      <PageLayout.Container size="full" className={styles.calendarContainer}>
        <Calendar
          hasTwentyFourHourTimeFormat={hasTwentyFourHourTimeFormat}
          selectedChannelIds={channelsFilter.sanitized}
          postsQuery={postsQuery}
          viewOnly={viewOnly}
        />
      </PageLayout.Container>
    </PageLayout>
  )
}

function CalendarProviders({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const singleChannelMatch = getMatch({
    pathname: window.location.pathname,
    route: newCalendarSingleChannel.route,
  })
  const isSingleChannel = !!singleChannelMatch
  const [allChannelsTimeZone] = useLocalStorage(
    ALL_CHANNELS_TIMEZONE_STORAGE_KEY,
    getCurrentTimeZone(),
  )
  if (isSingleChannel) {
    return <SingleChannelProvider>{children}</SingleChannelProvider>
  }

  return (
    <TimezoneProvider timeZone={allChannelsTimeZone}>
      <RegisteredBannersProvider
        key={`registered-notice-provider-allChannels-calendar`}
      >
        {children}
      </RegisteredBannersProvider>
    </TimezoneProvider>
  )
}

function SingleChannelProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const organizationId = useOrganizationId()
  const id = useParams<{ id: string }>().id

  const { data, error } = useQuery(GetChannelInfo, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - organizationId may be undefined query is skipped if it is
      organizationId,
      channelId: id,
    },
    skip: !organizationId,
  })

  if (error) {
    return <ChannelError error={error} />
  }

  const channel = data?.channel
  if (!channel) {
    return <></>
  }
  return (
    <ChannelProvider channel={channel}>
      <TimezoneProvider timeZone={channel.timezone}>
        <RegisteredBannersProvider
          key={`registered-banner-provider-${channel.name}-calendar`}
        >
          {children}
        </RegisteredBannersProvider>
      </TimezoneProvider>
    </ChannelProvider>
  )
}
