import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

import { graphql } from '~publish/gql'
import { logError } from '~publish/legacy/utils/logError'
import { GetAccountPreferences } from './usePreferences'
import { GET_ACCOUNT } from '~publish/legacy/accountContext'

export const UpdateAccountTimezoneMutation = graphql(/* GraphQL */ `
  mutation updateAccountTimezone($input: UpdateAccountTimezoneInput!) {
    updateAccountTimezone(input: $input) {
      __typename
      ... on UpdateAccountTimezoneSuccess {
        timezone
      }
      ... on InvalidInputError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

type UseUpdateTimeZonePreferencesResponse = {
  updateTimeZone: ({ timeZone }: { timeZone: string }) => Promise<void>
  isLoading: boolean
  hasError: boolean
}
export const useUpdateTimeZonePreferences =
  (): UseUpdateTimeZonePreferencesResponse => {
    const [executeUpdateAccountTimezone, { loading, error }] = useMutation(
      UpdateAccountTimezoneMutation,
    )

    const updateTimeZone = useCallback(
      async ({ timeZone }: { timeZone: string }) => {
        try {
          const { data, errors } = await executeUpdateAccountTimezone({
            variables: {
              input: {
                timezone: timeZone,
              },
            },
            refetchQueries: [GetAccountPreferences, GET_ACCOUNT],
          })

          if (
            data?.updateAccountTimezone?.__typename === 'UnexpectedError' ||
            data?.updateAccountTimezone?.__typename === 'InvalidInputError' ||
            data?.updateAccountTimezone?.__typename === 'NotFoundError'
          ) {
            const error = new Error(data.updateAccountTimezone.message)
            logError(error, { metaData: { data, errors } })
            throw error
          }

          // Handle GraphQL errors if any
          if (
            errors?.length ||
            data?.updateAccountTimezone?.__typename !==
              'UpdateAccountTimezoneSuccess'
          ) {
            const unexpectedError = new Error(
              errors?.[0]?.message || 'Unexpected API response',
            )
            logError(unexpectedError, { metaData: { data, errors } })
            throw unexpectedError
          }
        } catch (error) {
          logError(error as Error, {
            metaData: { variables: { timezone: timeZone } },
          })
          throw error
        }
      },
      [executeUpdateAccountTimezone],
    )

    return {
      updateTimeZone,
      isLoading: loading,
      hasError: !!error,
    }
  }
