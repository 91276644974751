import React, { useState } from 'react'
import { Temporal } from '@js-temporal/polyfill'

import {
  AlertDialog,
  type AlertDialogProps,
  Button,
  Checkbox,
  Flex,
  Paragraph,
  Strong,
} from '@buffer-mono/popcorn'

import { useTimezone } from '~publish/hooks/useTimezone'
import { format } from '~publish/helpers/temporal'
import { useTimeFormatPreference } from '~publish/hooks/useTimeFormatPreference'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

import styles from './MoveToCustomTimeAlertDialog.module.css'

interface MoveToCustomTimeAlertDialogProps {
  onOpenChange: AlertDialogProps['onOpenChange']
  open: AlertDialogProps['open']
  /**
   * The date of the post before it was moved to the custom time in ISO format
   */
  fromDate: string
  /**
   * The date of the post after it was moved to the custom time in ISO format
   */
  toDate: string
  onConfirm: () => void
  onCancel: () => void
}

export const MoveToCustomTimeAlertDialog = ({
  onOpenChange,
  open,
  fromDate,
  toDate,
  onConfirm,
  onCancel,
}: MoveToCustomTimeAlertDialogProps): JSX.Element => {
  const timezone = useTimezone()
  const timeFormatPreference = useTimeFormatPreference()
  const hasTwentyFourHourTimeFormat = timeFormatPreference === '24'
  const { isActive, dismiss } = useDismissableBanner(
    'move-to-custom-time-alert-dialog',
  )
  const [dontShowAgain, setDontShowAgain] = useState(!isActive)

  const fromString = format(
    Temporal.Instant.from(fromDate).toZonedDateTimeISO(timezone),
    hasTwentyFourHourTimeFormat ? 'MMM d HH:mm' : 'MMM d h:mm a',
  )
  const toString = format(
    Temporal.Instant.from(toDate).toZonedDateTimeISO(timezone),
    hasTwentyFourHourTimeFormat ? 'MMM d HH:mm' : 'MMM d h:mm a',
  )

  const handleOpenChange = (open: boolean): void => {
    if (!open) {
      onCancel()
    }
    onOpenChange?.(open)
  }

  const handleConfirm = (): void => {
    if (dontShowAgain) {
      dismiss()
    }
    onConfirm()
  }

  return (
    <AlertDialog onOpenChange={handleOpenChange} open={open}>
      <AlertDialog.Content>
        <AlertDialog.Title>Moving Post to a Custom Time</AlertDialog.Title>
        <AlertDialog.Description>
          <Flex direction="column" gap="md">
            <Paragraph>
              You’re moving this post from a queue slot at{' '}
              <Strong>
                {fromString} <i>({timezone})</i>
              </Strong>
              , to a custom time{' '}
              <Strong>
                {toString} <i>({timezone})</i>
              </Strong>
              , which isn’t part of your regular posting schedule.
            </Paragraph>
            <Paragraph>
              The post will be published at the new time you’ve selected, but
              won’t use a slot in your queue.
            </Paragraph>
          </Flex>
        </AlertDialog.Description>
        <AlertDialog.Separator />
        <AlertDialog.Actions>
          <Checkbox
            name="dont-show-again"
            checked={dontShowAgain}
            onChange={(checked): void => setDontShowAgain(Boolean(checked))}
            className={styles.dontShowAgain}
          >
            Don’t show this again
          </Checkbox>
          <AlertDialog.Cancel>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button variant="primary" onClick={handleConfirm}>
              Move to Custom Time
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}
