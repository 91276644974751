import React from 'react'
import { ColorPicker } from '~publish/legacy/shared-components'

import styles from './index.module.css'
import { Button, Heading, Text } from '@buffer-mono/popcorn'

const DEFAULT_COLOR = '#000000'

interface LinksHeaderProps {
  colorButtons?: string
  textColor?: string
  maxCustomLinks?: number
  onAddLinkClick?: () => void
  setColorButton: (color: string) => void
  setTextColor: (color: string) => void
  onUpdateCustomLinksColor: (colors: {
    customLinkColor: string
    customLinkContrastColor: string
  }) => void
  addLinkDisabled?: boolean
  hasWriteAccess: boolean
}

const LinksHeader = ({
  onAddLinkClick = () => {},
  setColorButton,
  setTextColor,
  maxCustomLinks,
  colorButtons,
  textColor,
  onUpdateCustomLinksColor,
  addLinkDisabled = false,
  hasWriteAccess,
}: LinksHeaderProps): React.ReactElement => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <Heading size="small"> Custom Links</Heading>

        <Text as="p" color="subtle">
          Add up to {maxCustomLinks} custom links to the top of your Shop grid
          page.
        </Text>
      </div>
      {hasWriteAccess && (
        <div className={styles.addLinkSection}>
          <div className={styles.linkColorSection}>
            <ColorPicker
              label="Link Color"
              defaultColor={colorButtons || DEFAULT_COLOR}
              onChange={(color, contrastColor) => {
                setColorButton(color)
                setTextColor(contrastColor)
              }}
              onBlur={(color, contrastColor) => {
                setColorButton(color)
                setTextColor(contrastColor)
                onUpdateCustomLinksColor({
                  customLinkColor: colorButtons || '',
                  customLinkContrastColor: textColor || '',
                })
              }}
            />
          </div>
          <Button
            variant="primary"
            onClick={onAddLinkClick}
            disabled={addLinkDisabled}
          >
            Add Link
          </Button>
        </div>
      )}
    </div>
  )
}

export default LinksHeader
