import { useState } from 'react'
import type { z } from 'zod'

type InputValidation = {
  isValid: boolean
  errorMessages: string[] | null
  checkIsValid: ({ inputValue }: { inputValue: string }) => boolean
}

/**
 * Custom hook to use basic validation for a single string input
 * @returns {InputValidation} An object containing the isValid state, the errorMessages state, and the checkIsValid function
 */
export const useInputValidation = ({
  schema,
  inputValue,
}: {
  schema: z.ZodType<string>
  inputValue: string | null
}): InputValidation => {
  const [errorMessages, setErrorMessages] = useState<string[] | null>(null)
  const [isValid, setIsValid] = useState<boolean>(
    schema.safeParse(inputValue).success,
  )

  const checkIsValid = ({ inputValue }: { inputValue: string }): boolean => {
    const isValid = schema.safeParse(inputValue)

    if (isValid.success === false) {
      const errors = isValid.error.issues.map((issue) => issue.message)
      setErrorMessages(errors)
      setIsValid(false)
      return false
    }
    setErrorMessages(null)
    setIsValid(true)
    return true
  }

  if (!inputValue) {
    return {
      isValid: false,
      errorMessages: ['Input value is required'],
      checkIsValid,
    }
  }

  return {
    isValid,
    errorMessages,
    checkIsValid,
  }
}
