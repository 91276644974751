import twitter from 'twitter-text'
import type { LinkedInAnnotation } from '~publish/legacy/post/types'
import { concatMentionsToEntities } from '../common'

export const getLinkedInMentions = (
  liAnnotations?: LinkedInAnnotation[] | null,
):
  | {
      displayString: string
      indices: [number, number]
      rawString: string
    }[]
  | null => {
  if (!liAnnotations || liAnnotations.length === 0) {
    return null
  }

  return liAnnotations.map((annotation) => {
    const { start } = annotation
    const end = start + annotation.length

    return {
      displayString: annotation.localizedName,
      indices: [start, end],
      rawString: annotation.localizedName,
    }
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'entities' implicitly has an 'any' type.
const getEntities = (entities, liAnnotations?: LinkedInAnnotation[] | null) => {
  const mentions = getLinkedInMentions(liAnnotations)

  return concatMentionsToEntities(mentions, entities)
}

export function isAnnotation(
  [start, end]: [number, number],
  liAnnotations: LinkedInAnnotation[],
): boolean {
  return liAnnotations.some((annotation) => {
    const { start: annotationStart, length } = annotation
    const annotationEnd = annotationStart + length

    return start >= annotationStart && end <= annotationEnd
  })
}

export const parseLinkedInLinks = (
  text?: string | null,
  liAnnotations?: LinkedInAnnotation[] | null,
) => {
  const entities = text
    ? twitter
        .extractEntitiesWithIndices(text)
        .map((entity) => {
          if (
            'url' in entity &&
            entity.url &&
            (!liAnnotations || !isAnnotation(entity.indices, liAnnotations))
          ) {
            return {
              displayString: entity.url,
              indices: entity.indices,
              rawString: entity.url,
              url: entity.url,
            }
          }
          if ('hashtag' in entity && entity.hashtag) {
            return {
              displayString: `#${entity.hashtag}`,
              indices: entity.indices,
              rawString: `#${entity.hashtag}`,
            }
          }
          return null
        })
        .filter((entity) => entity !== null)
    : []

  return getEntities(entities, liAnnotations)
}
