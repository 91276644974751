import { toast } from '@buffer-mono/popcorn'

import { graphql } from '~publish/gql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'
import { isOfType } from '~publish/helpers/typeGuards'

import { useFilters } from '../CommentsFilters/useFilters'

const DismissCommentMutationGQL = graphql(/* GraphQL */ `
  mutation DismissComment($input: DismissCommentInput!) {
    dismissComment(input: $input) {
      __typename
      ... on DismissCommentSuccess {
        comment {
          ... on BaseComment {
            __typename
            id
            status
            text
            updatedAt
            parentId
          }
          ... on CommentFromThirdParty {
            labels
          }
          ... on CommentFromBuffer {
            publishingStatus {
              status
              errorMessage
            }
          }
        }
      }
      ... on NotFoundError {
        message
      }
      ... on UnauthorizedError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

type UseDismissCommentResult = {
  dismissComment: (commentId: string) => Promise<void>
  loading: boolean
}

export const useDismissComment = (): UseDismissCommentResult => {
  const [{ status }] = useFilters()

  const [mutate, { loading }] = useTypedMutation(
    DismissCommentMutationGQL,
    (data) => data.dismissComment,
    {
      successTypename: 'DismissCommentSuccess',
      update: (cache, { data }) => {
        const result = data?.dismissComment
        if (!isOfType(result, 'DismissCommentSuccess')) {
          return
        }

        const updatedComment = result.comment
        if (!updatedComment) return

        // 1. Update the comment in the cache with the new status
        cache.modify({
          id: cache.identify({
            __typename: updatedComment.__typename,
            id: updatedComment.id,
          }),
          fields: {
            status: () => updatedComment.status,
          },
        })

        // 2. Decide if the comment should be removed from the current view
        // For dismiss, we remove from the list if we're in the "unreplied" view
        const shouldRemoveFromList = status === 'unreplied'

        if (shouldRemoveFromList) {
          // Find all comments queries in the cache and update them
          cache.modify({
            fields: {
              comments: (existingComments, { readField, storeFieldName }) => {
                const match = storeFieldName.match(/"status":\["(.*?)"\]/)
                const statusInStore = match ? match[1] : null

                // Skip if this isn't the current filter's query
                if (statusInStore !== status) {
                  return existingComments
                }

                // Filter out the comment from the edges array
                const newEdges = existingComments.edges.filter((edge: any) => {
                  const commentId = readField('id', readField('node', edge))
                  return commentId !== updatedComment.id
                })

                // Return the new list without the dismissed comment
                return {
                  ...existingComments,
                  edges: newEdges,
                }
              },
            },
          })
        }
      },
    },
  )

  const dismissComment = async (commentId: string): Promise<void> => {
    try {
      await mutate({
        variables: {
          input: {
            commentId,
          },
        },
      })
      toast.success('Comment dismissed successfully')
    } catch (error) {
      console.error('Error dismissing comment:', error)
      toast.error('An error occurred while dismissing the comment')
    }
  }

  return {
    dismissComment,
    loading,
  }
}
