import { graphql } from '~publish/gql'
import { useLazyQuery } from '@apollo/client'
import type { CommentData } from '~publish/gql/graphql'

const CommentContextQueryGQL = graphql(/* GraphQL */ `
  query CommentContext($input: CommentContextInput!) {
    commentContext(input: $input) {
      comment {
        ... on BaseComment {
          __typename
          id
          text
          publishedAt
          status
          parentId
          serviceType
          isOwnReply
          assets {
            id
            mimeType
            source
            thumbnail
            type
          }
          author {
            username
            name
            avatar
          }
          post {
            id
            text
            dueAt
            channelService
            assets {
              ...PostMediaAsset_Asset
            }
            tags {
              name
              color
            }
            metadata {
              ... on CommonPostMetadata {
                type
              }
            }
          }
        }
        ... on CommentFromThirdParty {
          labels
          suggestions {
            text
            source
          }
          sentimentAnalysis {
            type
          }
        }
        ... on CommentFromBuffer {
          publishingStatus {
            status
          }
        }
      }
      context {
        ... on BaseComment {
          __typename
          id
          text
          publishedAt
          status
          parentId
          serviceType
          isOwnReply
          assets {
            id
            mimeType
            source
            thumbnail
            type
          }
          author {
            username
            name
            avatar
          }
          post {
            id
            text
            dueAt
            channelService
            assets {
              ...PostMediaAsset_Asset
            }
            tags {
              name
              color
            }
            metadata {
              ... on CommonPostMetadata {
                type
              }
            }
          }
        }
        ... on CommentFromThirdParty {
          labels
          suggestions {
            text
            source
          }
          sentimentAnalysis {
            type
          }
        }
        ... on CommentFromBuffer {
          publishingStatus {
            status
          }
        }
      }
    }
  }
`)

type UseCommentContextResult = {
  getCommentContext: (commentId: string) => Promise<{
    originalComment: CommentData | null
    context: CommentData[]
  }>
  loading: boolean
  error: Error | null
}

export const useCommentContext = (): UseCommentContextResult => {
  const [getContext, { loading, error }] = useLazyQuery(CommentContextQueryGQL)

  const getCommentContext = async (
    commentId: string,
  ): Promise<{
    originalComment: CommentData | null
    context: CommentData[]
  }> => {
    const { data } = await getContext({
      variables: {
        input: {
          commentId,
        },
      },
    })

    return {
      originalComment: data?.commentContext?.comment as CommentData | null,
      context: data?.commentContext?.context as CommentData[],
    }
  }

  return {
    getCommentContext,
    loading,
    error: error ?? null,
  }
}
