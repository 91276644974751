import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import clsx from 'clsx'

import {
  ChannelAvatar,
  Flex,
  Heading,
  IconButton,
  Separator,
  SettingsIcon,
  StarIcon,
  Text,
  CircleIcon,
  UnstyledButton,
  PencilIcon,
  Popover,
} from '@buffer-mono/popcorn'
import { MODAL_ACTIONS } from '@buffer-mono/app-shell/src/common/events/modalEvents'
import { MODALS } from '@buffer-mono/app-shell/src/common/types'
import { AccountType } from '@buffer-mono/app-shell/src/common/events/types'

import { GET_ACCOUNT, useIsAdmin } from '~publish/legacy/accountContext'
import type { ChannelPage_ChannelFragment } from '~publish/gql/graphql'
import { PageLayout } from '~publish/components/PageLayout'
import { useAppDispatch } from '~publish/legacy/store'
import { isFacebookGroup } from '~publish/helpers/channels'
import { actions as profilesActions } from '~publish/legacy/profiles/reducer'
import { channelSettings } from '~publish/legacy/routes'

import { useSelectedChannel } from './ChannelContext'
import { usePostingGoalAutoRefresh } from './usePostingGoalAutoRefresh'
import {
  PostingGoalCoachMark,
  usePostingGoalCoachMark,
} from './PostingGoalCoachMark'

import styles from './ChannelPageTitle.module.css'

export function ChannelPageTitle(): JSX.Element {
  const channel = useSelectedChannel()
  const isAdmin = useIsAdmin()
  const [isHovered, setIsHovered] = React.useState(false)
  const openPersonalAccountEditModal = useOpenPersonalAccountEditModal()
  const showPostingGoalInfo = channel?.postingGoal?.goal !== 0

  if (!channel) return <></>

  const serverUrl = isFacebookGroup(channel) ? null : channel.serverUrl
  const secondaryChannelText = channel.locationData?.location ?? serverUrl
  const isEditable = isChannelEditable(channel, isAdmin)

  return (
    <Flex
      direction="row"
      align="center"
      gap="md"
      data-testid="channel-page-title"
    >
      <ChannelAvatar
        src={channel.avatar}
        alt={channel.service}
        channel={channel.service}
        showEditOverlay={isHovered}
        size="medium"
        onEditClick={
          isEditable
            ? (): void => openPersonalAccountEditModal(channel)
            : undefined
        }
      />
      <div>
        <Flex direction="row" align="center" gap="xs">
          <PageLayout.Title
            onClick={
              isEditable
                ? (): void => openPersonalAccountEditModal(channel)
                : undefined
            }
            className={isEditable ? styles.editableChannelName : undefined}
            onMouseEnter={(): void => setIsHovered(true)}
            onMouseLeave={(): void => setIsHovered(false)}
          >
            {channel.name}
          </PageLayout.Title>
          <IconButton
            id="settings-menu"
            variant="tertiary"
            label="Channel settings"
            tooltip="Channel Settings"
            size="small"
            data-tour-id="channel-settings"
            as={Link}
            to={`/channels/${channel.id}/settings`}
          >
            <SettingsIcon />
          </IconButton>
        </Flex>
        {!showPostingGoalInfo && secondaryChannelText && (
          <Text color="subtle">{secondaryChannelText}</Text>
        )}
        {showPostingGoalInfo && <PostingGoalInfo />}
      </div>
    </Flex>
  )
}

const SHIMMER_DURATION = 1000

function PostingGoalInfo(): JSX.Element {
  const channel = useSelectedChannel()
  const isAdmin = useIsAdmin()
  const [isShimmering, setIsShimmering] = useState(false)
  const prevCounts = useRef<Record<string, number> | null>(null)
  const coachMark = usePostingGoalCoachMark()

  usePostingGoalAutoRefresh(channel?.id, {
    skip: !channel?.postingGoal || channel.postingGoal.goal === 0,
  })

  const { postingGoal } = channel ?? {}

  useEffect(() => {
    if (!postingGoal) return

    if (
      prevCounts.current &&
      (postingGoal.sentCount > prevCounts.current.sentCount ||
        postingGoal.scheduledCount > prevCounts.current.scheduledCount)
    ) {
      setIsShimmering(true)

      const timer = setTimeout(function resetAnimation() {
        setIsShimmering(false)
      }, SHIMMER_DURATION)

      return () => clearTimeout(timer)
    }

    // Update previous values for next comparison
    prevCounts.current = {
      sentCount: postingGoal.sentCount,
      scheduledCount: postingGoal.scheduledCount,
    }
  }, [postingGoal])

  const clickSetPostingGoal = (): void => {
    if (!channel) return
    const { actions } = window.appshell ?? {}
    actions.openModal('postingGoalConfiguration', {
      channelId: channel.id,
      source: 'channel-page',
    })

    if (coachMark.isActive) {
      coachMark.dismiss()
    }
  }

  if (!channel) return <></>

  if (!postingGoal)
    return isAdmin ? (
      <PostingGoalCoachMark>
        <UnstyledButton
          onClick={clickSetPostingGoal}
          className={styles.postingGoal}
        >
          <Text>+ Set a posting goal</Text>
        </UnstyledButton>
      </PostingGoalCoachMark>
    ) : (
      <></>
    )

  const StatusIcon =
    postingGoal.sentCount > postingGoal.goal ? StarIcon : CircleIcon

  const remainingCount = Math.max(
    postingGoal.goal - postingGoal.sentCount - postingGoal.scheduledCount,
    0,
  )

  const progressStages: Record<string, number> = {
    [styles.quarter]: postingGoal.goal / 4,
    [styles.half]: postingGoal.goal / 2,
    [styles.threeQuarters]: (postingGoal.goal * 3) / 4,
    [styles.full]: postingGoal.goal,
  }
  const progressStage = Object.keys(progressStages).findLast(
    (stage) => postingGoal.sentCount >= progressStages[stage],
  )

  return (
    <Popover>
      <Popover.Trigger>
        <UnstyledButton className={styles.postingGoal}>
          <div
            className={clsx(styles.postingGoalBadge, progressStage, {
              [styles.success]: postingGoal.sentCount > 0,
            })}
          >
            <StatusIcon />
          </div>

          <Text
            className={clsx({
              [styles.shimmer]: isShimmering,
            })}
          >
            {postingGoal.sentCount}/{postingGoal.goal} posts sent this week
          </Text>
        </UnstyledButton>
      </Popover.Trigger>
      <Popover.Content
        arrow
        align="start"
        side="right"
        data-testid="posting-goal-info"
      >
        <IconButton
          as={NavLink}
          to={channelSettings.getRoute({
            channelId: channel.id,
            tab: 'posting-schedule',
          })}
          variant="tertiary"
          size="small"
          label="Edit posting goal"
          tooltip="Edit posting goal"
          className={styles.editPostingGoalButton}
        >
          <PencilIcon />
        </IconButton>
        <Heading size="small" className={styles.postingGoalHeading}>
          Posting Goal
        </Heading>
        <Text as="p" className={styles.postingGoalText}>
          {postingGoal.goal} {postingGoal.goal === 1 ? 'post' : 'posts'} per
          week
        </Text>
        <Separator
          decorative
          style={
            {
              '--separator-gutter': 'var(--space-sm)',
            } as React.CSSProperties
          }
        />
        <Flex direction="row" gap="xs">
          <Text as="p">
            <b>{postingGoal.sentCount}</b> Sent
          </Text>
          <span>⋅</span>
          <Text as="p">
            <b>{postingGoal.scheduledCount}</b> Scheduled
          </Text>
          <span>⋅</span>
          <Text as="p">
            <b>{remainingCount}</b> To Do
          </Text>
        </Flex>
      </Popover.Content>
    </Popover>
  )
}

function useOpenPersonalAccountEditModal(): (
  channel: ChannelPage_ChannelFragment,
) => void {
  const apollo = useApolloClient()
  const dispatch = useAppDispatch()

  return (channel: ChannelPage_ChannelFragment): void => {
    const modalMap: Partial<Record<string, MODALS>> = {
      facebook: MODALS.facebookSelectAccountType,
      instagram: MODALS.instagramSelectAccountType,
    }
    const modal = modalMap[channel.service]

    if (!modal) {
      return
    }

    const accountTypeMap: Record<string, AccountType> = {
      profile: AccountType.personal,
      group: AccountType.group,
    }

    MODAL_ACTIONS.openModal(modal, {
      accountType: accountTypeMap[channel.type],
      isRefreshingConnection: true,
      channelId: channel.id,
      channelName: channel.name,
      channelAvatar: channel.avatar,
      channelUrl: channel.serverUrl ?? undefined,
      onContinue: () => {
        apollo.refetchQueries({ include: [GET_ACCOUNT] })
        dispatch(
          profilesActions.fetchSingleProfile({
            profileId: channel.id,
            message: '',
          }),
        )
      },
    })
  }
}

function isChannelEditable(
  channel: ChannelPage_ChannelFragment,
  isAdmin = false,
): boolean {
  return (
    ((channel.service === 'instagram' && channel.type === 'profile') ||
      (channel.service === 'facebook' && channel.type === 'group')) &&
    isAdmin
  )
}
