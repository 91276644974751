import React from 'react'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import styled from 'styled-components'
import { NotifyMeIcon, Tooltip } from '@buffer-mono/popcorn'
import { orangeLight } from '@bufferapp/ui/style/colors'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;
  margin: 0 8px 0 -8px;
  color: ${orangeLight};
`

export const RemindersOmniIndicator = ({
  draft,
}: {
  draft: Draft
}): React.JSX.Element => {
  const composerFeedbackMessages = AppStore.getComposerFeedbackMessages(draft)

  if (
    AppStore.getExpandedComposerId() === draft.id ||
    !draft.isReminder ||
    composerFeedbackMessages.length > 0
  ) {
    return <></>
  }

  return (
    <Tooltip content="You'll receive a mobile notification to post yourself">
      <Wrapper>
        <NotifyMeIcon />
      </Wrapper>
    </Tooltip>
  )
}
