import React from 'react'
import { formatDistanceToNowStrict } from 'date-fns'

import { Badge, Flex, Text } from '@buffer-mono/popcorn'

export const CommentHeader = ({
  isOwnReply,
  author,
  publishedAt,
}: {
  isOwnReply: boolean
  author: { username: string }
  publishedAt?: string | null
}): JSX.Element => {
  return (
    <Flex align="center" gap="xs">
      {isOwnReply ? (
        <Badge color="subtle" size="medium">
          {author.username}
        </Badge>
      ) : (
        <Text weight="bold">{author.username}</Text>
      )}
      {publishedAt && (
        <Text size="sm" color="subtle">
          {formatDistanceToNowStrict(new Date(publishedAt))} ago
        </Text>
      )}
    </Flex>
  )
}
