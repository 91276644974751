import React from 'react'

type UseEscapeKeyOptions = {
  /**
   * Whether the escape key handler should be active
   * @default true
   */
  isEnabled?: boolean
}

/**
 * Hook to handle escape key press events
 * @param callback Function to call when escape key is pressed
 * @param options Configuration options
 */
export function useEscapeKey(
  callback: () => void,
  options: UseEscapeKeyOptions = {},
): void {
  const { isEnabled = true } = options

  React.useEffect(() => {
    if (!isEnabled) return

    function handleKeyDown(event: KeyboardEvent): void {
      if (event.code === 'Escape') {
        callback()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [callback, isEnabled])
}
