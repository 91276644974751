export const Limits = Object.freeze({
  feeds: Object.freeze({
    free: {
      total: 3,
      perCollection: 3,
    },
    paid: {
      total: 50,
      perCollection: 10,
    },
  }),
  collections: Object.freeze({
    free: 20,
    paid: 20,
  }),
})
