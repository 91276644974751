import { useMemo, useEffect, useState } from 'react'
import type { QueryResult } from '@apollo/client'
import { endOfWeek, type Day, eachWeekOfInterval } from 'date-fns'

import { graphql, getFragmentData } from '~publish/gql'
import type {
  GetCalendarAndPostListQueryVariables,
  GetCalendarAndPostListQuery,
  CalendarPostCard_PostFragment,
  PostStatus,
} from '~publish/gql/graphql'
import { CalendarPostCard_Post } from '~publish/pages/Calendar/fragments/CalendarPostCard_Post'
import { useOrganizationId } from '~publish/legacy/accountContext'
import {
  type ManyQueriesResult,
  type SingleQueryResult,
  useLazyManyQueries,
} from '~publish/hooks/useLazyManyQueries'

import { useCalendarRealTimeUpdates } from './useCalendarRealTimeUpdates'
import { useWeekStartsOn } from '~publish/hooks/useWeekStartsOn'
import { getDateRange } from '~publish/helpers/temporal'
import { Temporal } from '@js-temporal/polyfill'
import { useTimezone } from '~publish/hooks/useTimezone'
import { tz } from '@date-fns/tz'

export type QueryVariables = GetCalendarAndPostListQueryVariables
export type Data = GetCalendarAndPostListQuery

const POST_LIMIT = 200

export const GetCalendarAndPostList = graphql(/* GraphQL */ `
  query GetCalendarAndPostList(
    $startDate: DateTime!
    $endDate: DateTime!
    $channelIds: [ChannelId!]
    $organizationId: OrganizationId!
    $postsLimit: Int!
    $tagIds: [TagId!]
    $status: [PostStatus!]
    $after: String
  ) {
    posts(
      first: $postsLimit
      after: $after
      input: {
        organizationId: $organizationId
        filter: {
          channelIds: $channelIds
          dueAt: { start: $startDate, end: $endDate }
          tagIds: $tagIds
          status: $status
        }
        sort: [
          { field: dueAt, direction: asc }
          { field: createdAt, direction: desc }
        ]
      }
    ) {
      edges {
        node {
          id
          dueAt
          ...CalendarPostCard_Post
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export type PostForCalendarHookResponse = {
  data: GetCalendarAndPostListQuery | undefined
  loading: boolean
  refetch: () => void
  variables: GetCalendarAndPostListQueryVariables
}

type PostForCalendarHookProps = {
  currentDate: number
  calendarMode: 'week' | 'month'
  channelsSelected: string[] | void
  tagIds: string[] | void
  status: PostStatus[] | void
}

// TODO replace with fragment
type NewPostType = NonNullable<
  GetCalendarAndPostListQuery['posts']['edges']
>[number]['node']

export const asPostsFragment = (
  data: GetCalendarAndPostListQuery | null | undefined,
): (NewPostType | undefined | null)[] => {
  return data?.posts.edges?.map((edge) => edge.node) ?? []
}

export const usePostsFragment = asPostsFragment

export const getPosts = (
  data: GetCalendarAndPostListQuery | null | undefined,
): CalendarPostCard_PostFragment[] => {
  return (
    data?.posts.edges?.map((edge) =>
      getFragmentData(CalendarPostCard_Post, edge.node),
    ) ?? []
  )
}

/**
 * Hook that manages the data fetching for calendar posts view, supporting both weekly and monthly displays.
 * Integrates with Redux for refetch signaling and feature flags for query behavior.
 *
 * - Calculates date ranges based on calendar mode (week/month)
 * - Handles post fetching with pagination and interval generation
 * - Manages refetch cycles through Redux state monitoring
 *
 * @param {Object} props - Calendar view configuration
 * @param {number} props.currentDate - Unix timestamp for current view's date
 * @param {('week'|'month')} props.calendarMode - Calendar display mode
 * @param {string[]|undefined} props.channelsSelected - Array of channel IDs to filter by
 * @param {string[]|undefined} props.tagIds - Array of tag IDs to filter by
 * @param {string[]|undefined} props.status - Array of post statuses to filter by
 *
 * @returns {Object} Calendar data and controls
 * @returns {GetCalendarAndPostListQuery|undefined} returns.data - GraphQL query results
 * @returns {IntervalsResponse} returns.intervals - Time intervals for the calendar grid
 * @returns {boolean} returns.loading - Loading state for post data
 * @returns {Function} returns.refetch - Trigger to reload post data
 * @returns {QueryVariables} returns.variables - Current GraphQL variables
 *
 * @effect Monitors Redux state for refetch signals
 * @effect Dispatches Redux action to clear refetch flag after reload
 * @dependencies Uses organization context, browser timezone, and feature flags
 */
export const useCalendarAndPostsList = (
  props: PostForCalendarHookProps,
): PostForCalendarHookResponse => {
  const { currentDate, channelsSelected, calendarMode, tagIds, status } = props
  const timeZone = useTimezone()
  const currentDateZoned = Temporal.Instant.fromEpochMilliseconds(
    currentDate,
  ).toZonedDateTime({ timeZone, calendar: 'iso8601' })
  const weekStartsOn = useWeekStartsOn() === 'monday' ? 1 : 0
  const range = getDateRange(currentDateZoned, calendarMode, weekStartsOn)

  const [startDate, endDate] = [
    range.start.toInstant().toString(),
    range.end.toInstant().toString(),
  ]

  const organizationId = useOrganizationId()

  const variables = useVariables({
    startDate,
    endDate,
    organizationId,
    channelsSelected,
    tagIds,
    status,
  })

  const { data, loading, refetch } = useClientPaginatedPostList(variables)

  useCalendarRealTimeUpdates({
    filters: {
      channelIds: channelsSelected ?? [],
      tagIds: tagIds ?? [],
      status: status ?? [],
    },
    refetch: async (): Promise<void> => {
      await refetch()
    },
    isPostVisible: (id) => {
      return data?.posts?.edges?.some((edge) => edge.node.id === id) ?? false
    },
    dateRange: {
      startDate: variables.startDate,
      endDate: variables.endDate,
    },
  })

  return useMemo(
    () => ({
      data,
      loading,
      refetch,
      variables,
    }),
    [data, loading, refetch, variables],
  )
}

type ClientPaginatedPostListResult = Omit<
  QueryResult<GetCalendarAndPostListQuery, QueryVariables>,
  | 'observable'
  | 'startPolling'
  | 'stopPolling'
  | 'subscribeToMore'
  | 'updateQuery'
  | 'reobserve'
  | 'fetchMore'
  | 'refetch'
> & {
  refetch:
    | ManyQueriesResult<GetCalendarAndPostListQuery, QueryVariables>['refetch']
    | QueryResult<GetCalendarAndPostListQuery, QueryVariables>['refetch']
}

/**
 * Hook implementing a Date-Segmented strategy for calendar post fetching.
 *
 * Date-Segmented Mode:
 * - Splits date range into weekly segments
 * - Initial fetch of 200 posts per week
 * - Background fetch more posts per week when hasNextPage is true
 * - Deduplicates posts by ID when merging results
 *
 * @param {Object} variables - GraphQL query variables
 * @param {string} variables.startDate - ISO string start date
 * @param {string} variables.endDate - ISO string end date
 * @param {string} variables.organizationId - Organization identifier
 * @param {string[]|undefined} variables.channelIds - Channel filters
 * @param {string[]|undefined} variables.tagIds - Tag filters
 * @param {string[]|undefined} variables.status - Status filters
 *
 * @returns {Object} Combined query results
 * @returns {GetCalendarAndPostListQuery} returns.data - Merged post data
 * @returns {boolean} returns.loading - Loading state
 * @returns {QueryVariables} returns.variables - Current query variables
 * @returns {Function} returns.refetch - Function to reload data
 *
 * @effect Initiates background fetches for pages with hasNextPage true
 */
function useClientPaginatedPostList(
  variables: QueryVariables,
): ClientPaginatedPostListResult {
  const weekStartsOn: Day = useWeekStartsOn() === 'monday' ? 1 : 0
  const timezone = useTimezone()
  const [manyQueriesResultsOnCompleted, setManyQueriesResultsFirstLoad] =
    useState<SingleQueryResult<GetCalendarAndPostListQuery, QueryVariables>[]>(
      [],
    )
  const [firstLoad, setFirstLoad] = useState(true)
  const [
    executeQueries,
    { results: manyQueriesResults, ...manyQueriesResultRest },
  ] = useLazyManyQueries<GetCalendarAndPostListQuery, QueryVariables>({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ results }) => {
      setManyQueriesResultsFirstLoad(results)
      setFirstLoad(false)
    },
  })

  useEffect(() => {
    async function fetchPosts(): Promise<void> {
      const dates = getWeeksInRange(
        new Date(variables.startDate),
        new Date(variables.endDate),
        weekStartsOn,
        timezone,
      )

      const queriesToExecutePerDay = dates.map((date) => ({
        query: GetCalendarAndPostList,
        variables: {
          ...variables,
          startDate: date.start,
          endDate: date.end,
          postsLimit: POST_LIMIT,
        },
      }))
      if (!variables.organizationId) return
      await executeQueries(queriesToExecutePerDay)
    }

    fetchPosts()
  }, [variables, executeQueries, weekStartsOn, timezone])

  useEffect(
    function fetchMoreIfNeeded() {
      // Fetch more posts for queries that have a next page
      const idleCallback = requestIdleCallback(() => {
        manyQueriesResults
          .filter((res) => res?.data?.posts.pageInfo.hasNextPage)
          .forEach(({ fetchMore, variables, data }) =>
            fetchMore({
              variables: {
                ...variables,
                after: data?.posts.pageInfo.endCursor,
              },
            }),
          )
      })
      return () => cancelIdleCallback(idleCallback)
    },
    [manyQueriesResults],
  )

  return {
    data: mergeResults(
      // To avoid days loading one by one, we use the results from the
      // onCompleted hook on the first load. After the first load, we use the
      // results from the useLazyManyQueries hook to take advantage of the cache.
      firstLoad ? manyQueriesResultsOnCompleted : manyQueriesResults,
    ),
    variables,
    ...manyQueriesResultRest,
    loading:
      manyQueriesResultRest.loading ||
      manyQueriesResults.some((res) => res?.data?.posts.pageInfo.hasNextPage),
  }
}

function mergeResults(
  results: SingleQueryResult<GetCalendarAndPostListQuery, QueryVariables>[],
): GetCalendarAndPostListQuery {
  const edges = results.flatMap((res) => res?.data?.posts.edges ?? [])

  const uniqueEdges = edges.filter(
    (edge, index, self) =>
      index === self.findIndex((t) => t.node.id === edge.node.id),
  )
  return {
    posts: {
      edges: uniqueEdges,
      pageInfo: {
        hasNextPage: false,
        endCursor: null,
      },
    },
  }
}

/**
 * Custom hook that generates stable query variables for the GraphQL query.
 * Implements memoization to prevent unnecessary re-renders and query refetches.
 * Uses a stable key generation strategy for arrays by sorting and joining values.
 */
function useVariables(variables: {
  startDate: string
  endDate: string
  organizationId: string | undefined
  channelsSelected?: string[] | void
  tagIds?: string[] | void
  status?: string[] | void
}): QueryVariables {
  const {
    startDate,
    endDate,
    organizationId,
    channelsSelected,
    tagIds,
    status,
  } = variables
  return useMemo(
    () =>
      ({
        startDate,
        endDate,
        organizationId,
        channelIds: channelsSelected,
        tagIds,
        status,
      } as QueryVariables),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // HACK: use a stable key to avoid recreating the variables object
      // TODO: Review the creation of filters on useCalendarState since it is not stable
      startDate,
      endDate,
      organizationId,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      channelsSelected?.sort().join(','),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tagIds?.sort().join(','),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      status?.sort().join(','),
    ],
  )
}

/**
 * Generates a list of week date ranges in the range between startDate and endDate.
 * @param startDate - Start date
 * @param endDate - End date
 * @param weekStartsOn - Week start day
 * @returns List of dates in range as { start: string, end: string }
 */
function getWeeksInRange(
  startDate: Date,
  endDate: Date,
  weekStartsOn: Day,
  timezone: string,
): Array<{ start: string; end: string }> {
  const dates: Array<{ start: string; end: string }> = []
  const weeks = eachWeekOfInterval(
    {
      start: startDate,
      end: endDate,
    },
    {
      weekStartsOn,
      in: tz(timezone),
    },
  )

  weeks.forEach((weekStart) => {
    dates.push({
      start: weekStart.toISOString(),
      end: endOfWeek(weekStart, { weekStartsOn }).toISOString(),
    })
  })

  return dates
}
