import React, {
  type FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Flex } from '@buffer-mono/popcorn'
import { useMutation } from '@apollo/client'
import { UserContext } from '../../../../../common/context/User'
import {
  CREATE_CUSTOM_CHANNELS,
  UPDATE_CUSTOM_CHANNEL,
} from '../../../../../common/graphql/channels'
import { trackCustomChannelSetupViewed } from '../../tracking'
import { useSelector } from 'react-redux'
import type { OrchestratorRootState } from '../../../../../common/events/types'
import trackChannelConnectionStarted from '../../../../../tracking/trackChannelConnectionStarted'

import { setConnectionSuccessQueryParamsForCustomChannel } from '../../utils'
import { isValidHandle, isValidPassword } from './utils/validations'

import { Header, Footer, Body } from './components'
import styles from './BlueskyAuth.module.css'
import { ChannelAccountType, Service } from '../../types'

export default function BlueskyAuth({
  onClose,
  isRefreshingChannelConnection,
  name,
  channelId,
}: {
  onClose: () => void
  isRefreshingChannelConnection: boolean
  name?: string
  channelId?: string
}): React.JSX.Element {
  const { selectedService } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )
  const [blueskyData, setBlueskyData] = useState({
    handle: name || '',
    appPassword: '',
    followBuffer: true,
  })
  const [errorData, setErrorData] = useState({
    handleError: '',
    appPasswordError: '',
    responseError: '',
  })

  const user = useContext(UserContext)

  const { cta } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )

  const [upsertCustomChannels, { data, loading, error }] = useMutation(
    isRefreshingChannelConnection
      ? UPDATE_CUSTOM_CHANNEL
      : CREATE_CUSTOM_CHANNELS,
  )
  const message =
    data?.createCustomChannels?.message || data?.updateCustomChannel?.message

  useEffect(() => {
    if (data) {
      const newChannelId = data.createCustomChannels?.channels?.[0]?.id

      if (newChannelId) {
        // Redirect to '/channels' and trigger channel connection success modal
        const newURLWithSuccessParams =
          setConnectionSuccessQueryParamsForCustomChannel(newChannelId)
        window.location.href = newURLWithSuccessParams.toString()
      } else if (data.updateCustomChannel?.channel?.id) {
        onClose()
      } else if (message) {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          responseError: message,
        }))
      }
    }
  }, [data, message, onClose, isRefreshingChannelConnection])

  useEffect(() => {
    if (error) {
      setErrorData((prevErrorData) => ({
        ...prevErrorData,
        responseError: error.message,
      }))
    }
  }, [error])

  useEffect(() => {
    if (selectedService) {
      trackCustomChannelSetupViewed({ account: user, service: selectedService })
    }
  }, [selectedService, user])

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const isValidHandleData = isValidHandle(blueskyData.handle)
      const isValidAppPassword = isValidPassword(
        blueskyData.appPassword,
        isRefreshingChannelConnection,
      )

      if (!isValidHandleData.isValid) {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          handleError: isValidHandleData.errorMessage,
        }))
      }

      if (!isValidAppPassword.isValid) {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          appPasswordError: isValidAppPassword.errorMessage,
        }))
      }

      if (!isValidHandleData.isValid || !isValidAppPassword.isValid) {
        return
      }

      if (!isRefreshingChannelConnection) {
        trackChannelConnectionStarted({
          payload: {
            service: Service.bluesky,
            channelType: ChannelAccountType.profile,
            cta,
          },
          user,
        })
      }

      upsertCustomChannels({
        variables: {
          input: isRefreshingChannelConnection
            ? {
                channelId,
                customChannelMetadata: {
                  blueskyMetadata: {
                    handle: blueskyData.handle,
                    appPassword: blueskyData.appPassword
                      ? blueskyData.appPassword
                      : undefined,
                  },
                },
              }
            : {
                channels: [
                  {
                    organizationId: user?.currentOrganization?.id,
                    service: 'bluesky',
                    type: 'profile',
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    customChannelMetadata: {
                      blueskyMetadata: {
                        handle: blueskyData.handle,
                        appPassword: blueskyData.appPassword,
                        followBuffer: blueskyData.followBuffer,
                      },
                    },
                  },
                ],
              },
        },
      })
    },
    [
      blueskyData,
      channelId,
      upsertCustomChannels,
      user,
      isRefreshingChannelConnection,
    ],
  )

  return (
    <>
      <Header onClose={onClose} />
      <form className={styles.container} onSubmit={handleSubmit}>
        <Flex direction="column" align="center" justify="between" fullWidth>
          <Body
            isRefreshingChannelConnection={isRefreshingChannelConnection}
            errorData={errorData}
            blueskyData={blueskyData}
            setErrorData={setErrorData}
            setBlueskyData={setBlueskyData}
          />
          <Footer
            loading={loading}
            blueskyData={blueskyData}
            setBlueskyData={setBlueskyData}
            isRefreshingChannelConnection={isRefreshingChannelConnection}
          />
        </Flex>
      </form>
    </>
  )
}
