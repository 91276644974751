import {
  actions as dataFetchActions,
  actionTypes as dataFetchActionTypes,
} from '@buffer-mono/async-data-fetch'
import { actions as notificationActions } from '@buffer-mono/legacy-bufferapp-notifications'
import { campaignTab } from '~publish/legacy/routes'
import { CampaignTab } from '~publish/legacy/campaign/types'
import { PostActionNotificationSuccess } from '~publish/legacy/post/types'
import { actionTypes } from './actionTypes'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    switch (action.type) {
      case `deletePost_${dataFetchActionTypes.FETCH_SUCCESS}`: {
        const label = action.args?.isDraft ? 'draft' : 'post'
        dispatch(
          notificationActions.createNotification({
            notificationType: 'success',
            message: `Okay, we've deleted that ${label}!`,
          }),
        )
        break
      }
      case actionTypes.POST_CONFIRMED_DELETE:
        dispatch(
          dataFetchActions.fetch({
            name: 'deletePost',
            args: {
              updateId: action.updateId,
              profileId: action.profileId,
            },
          }),
        )
        break
      case actionTypes.POST_SHARE_NOW:
        dispatch(
          dataFetchActions.fetch({
            name: 'sharePostNow',
            args: {
              updateId: action.post.id,
              profileId: action.profileId,
              profileService: action.post.profile_service,
            },
          }),
        )
        break
      case actionTypes.POST_REQUEUE:
        dispatch(
          dataFetchActions.fetch({
            name: 'requeuePost',
            args: {
              updateId: action.post.id,
              profileId: action.profileId,
            },
          }),
        )
        break
      case actionTypes.VIEW_CAMPAIGN_PAGE: {
        const { campaignId, isSent } = action
        if (isSent) {
          dispatch(campaignTab.goTo({ campaignId, tab: CampaignTab.Sent }))
        } else {
          dispatch(campaignTab.goTo({ campaignId, tab: CampaignTab.Scheduled }))
        }
        break
      }
      case `sharePostNow_${dataFetchActionTypes.FETCH_SUCCESS}`:
        if (action.args.profileService === 'tiktok') {
          dispatch(
            notificationActions.createNotification({
              notificationType: 'success',
              message: PostActionNotificationSuccess.SHARE_NOW_ASYNC,
            }),
          )
        } else {
          dispatch(
            notificationActions.createNotification({
              notificationType: 'success',
              message: PostActionNotificationSuccess.SHARE_NOW,
            }),
          )
        }

        break
      case `sharePostNow_${dataFetchActionTypes.FETCH_FAIL}`:
        dispatch(
          notificationActions.createNotification({
            notificationType: 'error',
            message: action.error,
          }),
        )
        break
      case actionTypes.POST_DROPPED: {
        dispatch(
          dataFetchActions.fetch({
            name: 'dropPost',
            args: {
              updateId: action.updateId,
              timestamp: action.timestamp,
            },
          }),
        )
        break
      }
      case actionTypes.POSTS_SWAPPED: {
        dispatch(
          dataFetchActions.fetch({
            name: 'swapPosts',
            args: {
              updateSourceId: action.postSource.id,
              sourcePinned: action.postSource.postProps.pinned,
              sourceDueAt: action.postTarget.postProps.due_at,

              updateTargetId: action.postTarget.id,
              targetPinned: action.postTarget.postProps.pinned,
              targetDueAt: action.postSource.postProps.due_at,
            },
          }),
        )
        break
      }

      default:
        break
    }
  }
