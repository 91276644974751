import React from 'react'
import { PlusIcon, Sidebar, UpgradeBadge } from '@buffer-mono/popcorn'

import {
  useCurrentOrganization,
  useIsAdmin,
} from '~publish/legacy/accountContext'
import { getAccountURL } from '~publish/legacy/utils/formatters/getURL'
import { useIsAtPlanLimit } from '~publish/hooks/useIsAtPlanLimit'
import { useCta } from '~publish/hooks/useCta'

import styles from './ChannelNav.module.css'
import { useSplitEnabled } from '@buffer-mono/features'

export function NewChannelButton(): JSX.Element {
  const currentOrganization = useCurrentOrganization()
  const isAtPlanLimit = useIsAtPlanLimit()
  const isAdmin = useIsAdmin()

  const hasPaymentDetails =
    currentOrganization?.billing?.paymentDetails?.hasPaymentDetails ?? null
  const currentChannelQuantity = currentOrganization?.channels?.length ?? 0

  const { clicked: ctaClicked } = useCta(
    'publish-sidebar-channels-addChannels-1',
    {
      upgradePathName: 'addChannel-upgrade',
      extraProperties: {
        currentChannelQuantity,
        isAtPlanLimit,
        hasPaymentDetails,
      },
    },
  )

  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  const openChannelStorefront = (): void => {
    ctaClicked()
    window.appshell?.actions?.openChannelStorefront({
      cta: 'publish-sidebar-channels-newChannel-1',
    })
  }

  if (!isAdmin) {
    return <></>
  }

  return (
    <Sidebar.ListItem>
      {isChannelStorefrontEnabled ? (
        <Sidebar.Button
          size="large"
          prefix={
            <span className={styles.icon}>
              <PlusIcon />
            </span>
          }
          suffix={isAtPlanLimit && <UpgradeBadge />}
          className={styles.newChannelButton}
          onClick={openChannelStorefront}
        >
          New Channel
        </Sidebar.Button>
      ) : (
        <Sidebar.Button
          as="a"
          size="large"
          href={`${getAccountURL()}/channels/connect`}
          prefix={
            <span className={styles.icon}>
              <PlusIcon />
            </span>
          }
          suffix={isAtPlanLimit && <UpgradeBadge />}
          className={styles.newChannelButton}
          onClick={ctaClicked}
        >
          New Channel
        </Sidebar.Button>
      )}
    </Sidebar.ListItem>
  )
}
