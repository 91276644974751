import React from 'react'
import clsx from 'clsx'

import { Flex } from '@buffer-mono/popcorn'

import type { Asset } from '~publish/gql/graphql'

import { CommentMediaAsset } from './CommentMediaAsset'
import styles from './CommentMedia.module.css'

type CommentMediaProps = {
  assets: Asset[]
  className?: string
}

export const CommentMedia = ({
  assets,
  className,
}: CommentMediaProps): JSX.Element | null => {
  return (
    <Flex className={clsx(styles.assets, className)} gap="2xs">
      {assets.map((asset) => (
        <CommentMediaAsset key={asset.id} asset={asset as Asset} />
      ))}
    </Flex>
  )
}
