import React, { type ChangeEvent } from 'react'

import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import { Input } from '../../channel-fields/styles'
import FooterField from '../../shared/FooterField'

const PinTitle = ({
  draftId,
  title,
}: {
  draftId: string
  title: string | null
}): JSX.Element => {
  const saveTitle = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target

    ComposerActionCreators.updateDraftTitle(draftId, value)
    ComposerActionCreators.updateDraftTitleCharacterCount(draftId)
  }

  return (
    <FooterField
      label="Title"
      ariaLabel="pinTitle"
      fieldContent={
        <Input
          aria-labelledby="pinTitle"
          type="text"
          onChange={saveTitle}
          name="pinTitle"
          placeholder="Your pin title"
          value={title || ''}
        />
      }
    />
  )
}

export default PinTitle
