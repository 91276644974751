import { Button, ChevronDownIcon, ChevronUpIcon } from '@buffer-mono/popcorn'
import React, { memo } from 'react'

interface ShowMoreLessButtonsProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof Button>,
    'onClick' | 'children'
  > {
  itemsToRender?: unknown[]
  limit: number
  setLimit: (limit: number) => void
}

const DEFAULT_ITEMS_LIMIT = 3
function ShowMoreLessButtonsRaw({
  itemsToRender,
  limit,
  setLimit,
  ...props
}: ShowMoreLessButtonsProps): React.ReactNode {
  const showMore = itemsToRender && itemsToRender.length > limit
  const showLess =
    itemsToRender &&
    itemsToRender.length === limit &&
    limit !== DEFAULT_ITEMS_LIMIT

  if (showMore)
    return (
      <Button
        variant="tertiary"
        size="small"
        onClick={(): void => setLimit(itemsToRender.length)}
        {...props}
      >
        <ChevronDownIcon /> {itemsToRender.length - limit} More
      </Button>
    )

  if (showLess)
    return (
      <Button
        variant="tertiary"
        size="small"
        onClick={(): void => setLimit(DEFAULT_ITEMS_LIMIT)}
        {...props}
      >
        <ChevronUpIcon /> Show less
      </Button>
    )

  return null
}

export default memo(ShowMoreLessButtonsRaw)
