import {
  Lightbox,
  IconButton,
  LogOutIcon,
  DropdownMenu,
  type LightboxProps,
  Flex,
  Text,
  TrashIcon,
  useControllableState,
  useLocalStorage,
  type ButtonProps,
  Slot,
  Badge,
} from '@buffer-mono/popcorn'
import React, { useMemo } from 'react'
import { NewTag } from '~publish/legacy/integrations-bar/styles'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { MAX_THUMBNAILS } from '~publish/legacy/uploads/components/MediaManager'
import {
  selectCompletedUploads,
  selectPendingCount,
  selectCompletedCount,
  selectPendingProgress,
} from '~publish/legacy/uploads/state/selectors'
import { IDEAS_UPLOADER_ID } from '../../constants'
import {
  Upload,
  UploadSource,
  type BufferUpload,
  type Uploader,
  type UploadMetadata,
} from '@buffer-mono/uploader'
import { isNotNull } from '~publish/helpers/typeGuards'
import { getQueueResizedImageUrl } from '~publish/legacy/utils/bufferImages'
import { escapeParens } from '~publish/legacy/composer/composer/utils/StringUtils'
import CircularUploadIndicator from '~publish/legacy/composer/composer/components/progress-indicators/CircularUploadIndicator'
import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import {
  reorderUploads,
  uploadRemoved,
} from '~publish/legacy/uploads/state/slice'
import { useCustomSensors } from '~publish/hooks/useCustomSensors'
import { SortableContext } from '@dnd-kit/sortable'
import { SortableThumbnail } from '~publish/legacy/uploads/components/SortableThumbnail'
import styles from './AssetManager.module.css'
import type { IntegrationsService } from '~publish/legacy/integrations-bar/types'
import { SearchImagesModal } from '~publish/legacy/integrations-bar/components/search-images-modal/SearchImagesModal'
import clsx from 'clsx'

const MediaThumbnail = ({
  upload,
  onClick,
  onDelete,
}: {
  upload: Upload | BufferUpload
  onClick?: () => void
  onDelete?: (event: React.MouseEvent) => void
}): JSX.Element => {
  return (
    <Flex onClick={onClick} className={styles.thumbnailContainer}>
      {Upload.isVideo(upload) && <div className={styles.thumbnailOverlay} />}
      {Upload.isDocumentUpload(upload) ? (
        <img
          alt={upload.alt || upload.name}
          src={getQueueResizedImageUrl(escapeParens(upload.thumbnailUrl))}
          crossOrigin={'anonymous'}
          role="presentation"
          className={styles.thumbnail}
        />
      ) : (
        <img
          src={Upload.getThumbnailUrl(upload)}
          alt={upload.alt || upload.name}
          role="presentation"
          className={styles.thumbnail}
        />
      )}
      <IconButton
        label="Delete"
        variant="critical"
        onClick={onDelete}
        className={styles.deleteButton}
        size="small"
        tooltip="Delete"
      >
        <TrashIcon />
      </IconButton>
    </Flex>
  )
}

const mapAssetsToLightboxSlides = (
  assets: BufferUpload[],
): LightboxProps['slides'] => {
  return assets
    .map((asset) => {
      if (asset.mediaType === 'video') {
        return {
          type: 'video' as const,
          poster: asset.thumbnailUrl,
          width: asset.videoMetadata?.width ?? 0,
          height: asset.videoMetadata?.height ?? 0,
          sources: [{ src: asset.url, type: asset.type }],
        }
      }

      if (asset.mediaType === 'image') {
        return {
          type: 'image' as const,
          src: asset.thumbnailUrl ?? asset.url,
          // alt: getAltText(asset),
          width: asset.dimensions?.width ?? 0,
          height: asset.dimensions?.height ?? 0,
        }
      }
      return null
    })
    .filter(isNotNull)
}

export const AssetManager = ({
  uploader,
}: {
  uploader: Uploader
}): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const sensors = useCustomSensors()

  const [isLightboxOpen, setIsLightboxOpen] = React.useState(false)

  React.useEffect(() => {
    if (isLightboxOpen) {
      const timer = setTimeout(() => {
        document.body.style.pointerEvents = 'auto'
      }, 0)

      return () => clearTimeout(timer)
    } else {
      document.body.style.pointerEvents = 'none'
    }
  }, [isLightboxOpen])

  const pendingProgress = useAppSelector((state) =>
    selectPendingProgress(state, IDEAS_UPLOADER_ID),
  )

  const completedUploads = useAppSelector((state) =>
    selectCompletedUploads(state, IDEAS_UPLOADER_ID),
  )
  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, IDEAS_UPLOADER_ID),
  )
  const isUploading = pendingCount > 0

  const lightboxSlides = useMemo(
    () => mapAssetsToLightboxSlides(completedUploads),
    [completedUploads],
  )

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent): void => {
      const { active, over } = event

      if (over && active.id !== over?.id) {
        dispatch(
          reorderUploads({
            activeId: active.id,
            targetId: over?.id,
          }),
        )
      }
    },
    [dispatch],
  )

  const onDeleteClick = React.useCallback(
    (event: React.MouseEvent, upload: Upload): void => {
      event.stopPropagation()
      uploader.removeUpload(upload)
      dispatch(uploadRemoved(upload.id))
    },
    [uploader, dispatch],
  )

  if (completedUploads.length === 0 && !isUploading) {
    return null
  }

  return (
    <Flex
      align="center"
      gap="xs"
      className={styles.assetManager}
      data-component="asset-manager"
    >
      {completedUploads.length > 0 && (
        <Flex gap="xs" align="center" wrap="wrap" className={styles.thumbnails}>
          <Lightbox
            slides={lightboxSlides}
            open={isLightboxOpen}
            onOpenChange={setIsLightboxOpen}
          >
            <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
              <SortableContext items={completedUploads}>
                {completedUploads.map((up, index) => (
                  <SortableThumbnail key={up.id} id={up.id}>
                    <Lightbox.Trigger
                      data-no-dnd
                      key={up.id}
                      index={index}
                      asChild
                    >
                      <MediaThumbnail
                        upload={up}
                        onClick={(): void => setIsLightboxOpen(true)}
                        onDelete={(e): void => onDeleteClick(e, up)}
                      />
                    </Lightbox.Trigger>
                  </SortableThumbnail>
                ))}
              </SortableContext>
            </DndContext>
          </Lightbox>
        </Flex>
      )}
      {isUploading && (
        <Flex className={clsx(styles.thumbnail, 'thumbnail-loader')}>
          <CircularUploadIndicator
            data-testid="thumbnail-upload-progress"
            size={44}
            progress={pendingProgress || 0}
            finishingUpText="99%"
          />
          <Badge size="xsmall" className={styles.countBadge}>
            <Text size="sm" lineHeight="tight">
              {pendingCount}
            </Text>
          </Badge>
        </Flex>
      )}
    </Flex>
  )
}

export const ActiveIntegrationButton = ({
  services,
  children,
}: {
  services: IntegrationsService[]
  children?: React.ReactNode
}): JSX.Element => {
  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, IDEAS_UPLOADER_ID),
  )
  const completedCount = useAppSelector((state) =>
    selectCompletedCount(state, IDEAS_UPLOADER_ID),
  )

  const [lastIntegration] = useLocalStorage('last_used_integration', '')
  const activeService = services.find((e) => e.title === lastIntegration)

  if (!activeService) {
    return <>{children}</>
  }

  const Icon = activeService?.icon

  return (
    <IconButton
      key={activeService?.id}
      label={activeService?.label ?? 'Integration'}
      disabled={completedCount + pendingCount >= MAX_THUMBNAILS}
      data-testid={`integrations-bar-${activeService?.id}`}
      onClick={activeService?.onClick}
      size="small"
      variant="tertiary"
    >
      <Icon />
    </IconButton>
  )
}

export const UploadButton = ({
  children,
  onFilesAdded,
  ...props
}: ButtonProps & {
  onFilesAdded: (files: File[], source?: UploadMetadata['source']) => void
}): JSX.Element => {
  const uploadInputId = React.useId()
  const uploadInputRef = React.useRef<HTMLInputElement>(null)

  const handleUploadClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    uploadInputRef.current?.click()
    event.stopPropagation()
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const files: File[] = Array.from(event.target.files ?? [])
    if (!files.length) {
      return
    }
    onFilesAdded(files, UploadSource.filePicker())
  }

  return (
    <Slot.Root
      className={styles.uploadButton}
      onClick={handleUploadClick}
      {...props}
    >
      <>
        {children}
        <input
          key={uploadInputId}
          hidden
          multiple
          type="file"
          ref={uploadInputRef}
          onChange={handleFileChange}
        />
      </>
    </Slot.Root>
  )
}

export const IntegrationsDropdown = ({
  open: openProp,
  defaultOpen,
  onOpenChange,
  services,
  activeService,
  className,
  children,
  onFilesAdded,
}: {
  open?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  services: IntegrationsService[]
  activeService: IntegrationsService | null
  className?: string
  children: React.ReactNode
  onFilesAdded: (files: File[], source?: UploadMetadata['source']) => void
}): JSX.Element => {
  const uploadInputId = React.useId()
  const uploadInputRef = React.useRef<HTMLInputElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLastIntegrationUsed] = useLocalStorage(
    'last_used_integration',
    '',
  )

  const container = document.getElementById('portals-container')
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  })

  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, IDEAS_UPLOADER_ID),
  )
  const completedCount = useAppSelector((state) =>
    selectCompletedCount(state, IDEAS_UPLOADER_ID),
  )
  const handleUploadClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    setLastIntegrationUsed('')
    uploadInputRef.current?.click()
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const files: File[] = Array.from(event.target.files ?? [])
    if (!files.length) {
      return
    }
    onFilesAdded(files, UploadSource.filePicker())
  }

  const onServiceClick = (service: IntegrationsService): void => {
    setLastIntegrationUsed(service.title)
    setOpen(false)
    service.onClick?.()
  }

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
        <DropdownMenu.Content align="end" className={className}>
          <DropdownMenu.Item
            onClick={handleUploadClick}
            disabled={completedCount + pendingCount >= MAX_THUMBNAILS}
          >
            <LogOutIcon className={styles.sideways} data-icon={undefined} />
            Upload
          </DropdownMenu.Item>
          {services.map((service) => (
            <DropdownMenu.Item
              onClick={(): void => onServiceClick(service)}
              key={service.id}
              data-testid={`integrations-bar-${service.id}`}
              disabled={completedCount + pendingCount >= MAX_THUMBNAILS}
            >
              {service.icon?.({}) ?? null}
              {service.title}
              {service.isNew && <NewTag isDropdownEnabled>New</NewTag>}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
      <input
        key={uploadInputId}
        hidden
        multiple
        type="file"
        ref={uploadInputRef}
        onChange={handleFileChange}
      />
      <SearchImagesModal
        open={!!activeService?.openSearchImagesModal}
        handleClose={(): void => activeService?.onClose?.()}
        service={activeService}
        context={'ideasEditor'}
        container={container}
      />
    </>
  )
}
