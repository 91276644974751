import React from 'react'
import {
  Flex,
  Dialog,
  Button,
  BufferIcon,
  ArrowDownUpIcon,
  CloseIcon,
  BlueskyIcon,
} from '@buffer-mono/popcorn'

import { BackButton } from '../../../services/_shared/components/BackButton/BackButton'

import styles from './Header.module.css'

export function Header({ onClose }: { onClose: () => void }): JSX.Element {
  return (
    <Dialog.Header>
      <Flex direction="row" justify="between" align="center">
        <BackButton />
        <Flex gap="sm" align="center">
          <BufferIcon />
          <ArrowDownUpIcon className={styles.arrowsIcon} color="subtle" />
          <BlueskyIcon color="brand" />
        </Flex>
        <Button variant="tertiary" className={styles.button} onClick={onClose}>
          <CloseIcon />
        </Button>
      </Flex>
    </Dialog.Header>
  )
}
