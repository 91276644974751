import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { Flex, Skeleton, SkeletonText } from '@buffer-mono/popcorn'

import { type FragmentType, graphql, getFragmentData } from '~publish/gql'
import type { PostCard_PostFragment } from '~publish/gql/graphql'
import { useDateTimeFormatter } from '~publish/hooks/useDateTimeFormatter'
import { PostCardContent } from '~publish/components/PostCardContent'
import { PostCardAddons } from '~publish/components/PostCardAddons'
import { PostCardTags } from '~publish/components/PostCardTags'

import { PostCardAttachments } from './PostCardAttachments'
import {
  PostCardContextProvider,
  useMetadataTitle,
  usePostData,
} from './PostCardContext'
import { PostCardErrorNotice } from './PostCardErrorNotice'
import { PostCardFooter } from '../PostCardFooter'
import { PostCardHeader } from '../PostCardHeader'
import { PostMediaBentoBoxPreview, PostMediaRowPreview } from './PostMedia'
import { PostCardMetrics } from './PostCardMetrics'
import { PostCardProcessingNotice } from './PostCardProcessingNotice'
import { PostCardNotes } from './PostCardNotes'
import { PostCardPausedQueueNotice } from './PostCardPausedQueueNotice'

import styles from './PostCard.module.css'
import { PostCardDisconnectedChannelNotice } from './PostCardDisconnectedChannelNotice'
import { PostCardContent_Annotation } from '../PostCardContent/PostCardContent'

export const PostCard_Post = graphql(/* GraphQL */ `
  fragment PostCard_Post on Post {
    id
    isProcessing @client
    allowedActions
    ideaId
    status
    notificationStatus
    sharedNow
    via
    schedulingType
    author {
      __typename
      id
      email
      avatar
      isDeleted
    }
    isCustomScheduled
    isPinned
    externalLink
    createdAt
    updatedAt
    dueAt
    sentAt
    metricsUpdatedAt
    text
    externalLink
    metadata {
      __typename
      ... on CommonPostMetadata {
        type
        annotations {
          __typename
          ...PostCardContent_Annotation
        }
      }
      ... on InstagramPostMetadata {
        firstComment
        link
        geolocation {
          id
          text
        }
      }
      ... on PinterestPostMetadata {
        title
        url
        board {
          serviceId
          name
          url
          description
          avatar
        }
      }
      ... on GoogleBusinessPostMetadata {
        title
      }
      ... on TwitterPostMetadata {
        threadCount
        thread {
          text
          assets {
            __typename
            ...PostMediaAsset_Asset
          }
        }
        retweet {
          id
          url
          text
          createdAt
          user {
            name
            username
            avatar
          }
        }
      }
      ... on LinkedInPostMetadata {
        firstComment
        linkAttachment {
          __typename
          url
          expandedUrl
          title
          thumbnail
          text
        }
      }
      ... on FacebookPostMetadata {
        linkAttachment {
          __typename
          url
          expandedUrl
          title
          thumbnail
          text
        }
      }
      ... on ThreadsPostMetadata {
        threadCount
        thread {
          text
          assets {
            __typename
            ...PostMediaAsset_Asset
          }
        }
      }
      ... on MastodonPostMetadata {
        threadCount
        thread {
          text
          assets {
            __typename
            ...PostMediaAsset_Asset
          }
        }
      }
      ... on BlueskyPostMetadata {
        threadCount
        thread {
          text
          assets {
            __typename
            ...PostMediaAsset_Asset
          }
        }
        linkAttachment {
          __typename
          url
          expandedUrl
          title
          thumbnail
          text
        }
      }
    }
    channel {
      __typename
      id
      type
      name
      avatar
      service
      products
      serviceId
      serverUrl
      timezone
      displayName
      isQueuePaused
      isDisconnected
      locationData {
        location
      }
    }
    tags {
      id
      name
      color
    }
    notes {
      ...PostCardNotes_Note
    }
    error {
      message
      supportUrl
    }
    assets {
      __typename
      ...PostMediaAsset_Asset
    }
    metrics {
      type
      name
      displayName
      description
      value
      nullableValue
      unit
    }
  }
`)

export const NOTES_LIMIT = 35

const readablePostStatus: Record<PostCard_PostFragment['status'], string> = {
  draft: 'draft',
  needs_approval: 'needs approval',
  sent: 'sent',
  error: 'errored',
  scheduled: 'scheduled',
  sending: 'being sent',
} as const

export const PostCardProvider = ({
  post,
  children,
}: {
  post: FragmentType<typeof PostCard_Post>
  children: React.ReactNode
}): JSX.Element => {
  const postData = getFragmentData(PostCard_Post, post)
  return (
    <PostCardContextProvider post={postData}>
      {children}
    </PostCardContextProvider>
  )
}

const PostCardContainer = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { className?: string }
>(({ className, ...props }, ref): JSX.Element => {
  const post = usePostData()
  const dateTimeFormatter = useDateTimeFormatter()

  return (
    <article
      {...props}
      data-post-id={post.id}
      data-testid="post-card"
      className={clsx(
        styles.cardContainer,
        post.isProcessing && styles.processing,
        className,
      )}
      ref={ref}
      aria-label={`Post ${readablePostStatus[post.status]} for ${
        post.channel.name
      } ${post.channel.service} channel ${
        post.dueAt ? `on ${dateTimeFormatter(post.dueAt)}` : ''
      }`}
    >
      {props.children}
    </article>
  )
})
PostCardContainer.displayName = 'PostCardContainer'

const PostCardFrame = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { className?: string }
>((props, ref): JSX.Element => {
  return (
    <div
      {...props}
      className={clsx(styles.cardFrame, props.className)}
      ref={ref}
    >
      {props.children}
    </div>
  )
})
PostCardFrame.displayName = 'PostCardFrame'

const PostCardBody = (): JSX.Element => {
  const post = usePostData()
  const title = useMetadataTitle(post?.metadata)
  const annotations =
    getFragmentData(PostCardContent_Annotation, post?.metadata?.annotations) ??
    []

  return (
    <section className={styles.body}>
      <div className={styles.bodyWrapper}>
        <PostCardHeader type={post.metadata?.type} />
        <PostCardContent
          title={title}
          text={post.text}
          annotations={annotations}
        />
        <div className={styles.mediaRow}>
          <PostMediaRowPreview assets={post.assets} />
        </div>
        <PostCardAttachments />
        <TagsAndAddons />
      </div>
      <div className={styles.mediaBentoBox}>
        <PostMediaBentoBoxPreview assets={post.assets} />
      </div>
    </section>
  )
}

const TagsAndAddons = (): JSX.Element => (
  <Flex justify="between" align="end" className={styles.tagsAddonsContainer}>
    <PostCardTags />
    <PostCardAddons />
  </Flex>
)

export const PostCard = forwardRef<
  HTMLDivElement,
  {
    post: FragmentType<typeof PostCard_Post>
    className?: string
    borderless?: boolean
    rounded?: 'sm' | 'md'
    showChannelNotices?: boolean
    draggable?: boolean
  }
>(
  (
    {
      post,
      className,
      borderless,
      rounded = 'sm',
      showChannelNotices = false,
      draggable = false,
    },
    ref,
  ) => {
    return (
      <PostCardProvider post={post}>
        <PostCardContainer
          className={clsx(
            styles[`rounded-${rounded}`],
            draggable && styles.draggable,
            className,
          )}
        >
          <PostCardFrame ref={ref}>
            <div
              className={clsx(styles.inner, {
                [styles.borderless]: borderless,
              })}
            >
              <PostCardErrorNotice />
              <PostCardProcessingNotice />
              {showChannelNotices && <PostCardPausedQueueNotice />}
              {showChannelNotices && <PostCardDisconnectedChannelNotice />}
              <PostCardBody />
              <PostCardMetrics />
              <PostCardFooter />
            </div>
            <PostCardNotes />
          </PostCardFrame>
        </PostCardContainer>
      </PostCardProvider>
    )
  },
)
PostCard.displayName = 'PostCard'

export const PostCardCompact = forwardRef<
  HTMLDivElement,
  { post: FragmentType<typeof PostCard_Post>; className?: string }
>(({ post, className }, ref) => (
  <PostCardProvider post={post}>
    <PostCardContainer>
      <PostCardFrame ref={ref} className={className}>
        <div className={styles.inner}>
          <PostCardBody />
        </div>
      </PostCardFrame>
    </PostCardContainer>
  </PostCardProvider>
))
PostCardCompact.displayName = 'PostCardCompact'

export const PostCardSkeleton = forwardRef<
  HTMLDivElement,
  { className?: string }
>(({ className }, ref) => (
  <div ref={ref} className={clsx(styles.skeleton, className)}>
    <div className={styles.body}>
      <div className={styles.bodyWrapper}>
        <Flex gap="sm" align="center">
          <Skeleton rounded width={36} height={36} />
          <SkeletonText lines={1} width={120} />
        </Flex>
        <SkeletonText lines={3} width={'100%'} />
        <Flex className={styles.skeletonTags} gap="sm" align="center">
          <SkeletonText lines={1} width={80} />
          <SkeletonText lines={1} width={60} />
        </Flex>
      </div>
      <Skeleton className={styles.skeletonMedia} />
    </div>
  </div>
))

PostCardSkeleton.displayName = 'PostCardSkeleton'
