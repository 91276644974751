import React from 'react'
import styled from 'styled-components'
import { AnimatedList } from '~publish/legacy/shared-components'
import ListItem from '../ListItem'

const StyledList = styled(AnimatedList)`
  padding-left: 0px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
`

const skeletonCampaign = {
  id: 'Loading ID',
  name: 'Loading Name',
  sent: 0,
  scheduled: 0,
  lastUpdated: 'Loading date',
}

const numberItems = [1, 2, 3, 4, 5]

interface SkeletonListProps {
  showCampaignActions: boolean
}

const SkeletonList = ({
  showCampaignActions,
}: SkeletonListProps): JSX.Element => {
  const listItems = numberItems.map((number) => (
    <ListItem
      key={`skeleton-${number}`}
      campaign={skeletonCampaign}
      showCampaignActions={showCampaignActions}
      displaySkeleton
      hideAnalyzeReport
    />
  ))
  return <StyledList>{listItems}</StyledList>
}

export default SkeletonList
