import { Products, ProductLabels } from '../../../common/constants/products'
import type {
  ProductNames,
  ProductType,
} from '../../../common/constants/products'

export function getActiveProduct(): ProductType | null {
  const subdomain = window.location.hostname.split('.')[0].toLowerCase()

  const matchingProducts = Products.filter(
    ({ subdomain: productSubdomain }) => productSubdomain === subdomain,
  )
  const pathname = window.location.pathname.split('/')[1]

  const product =
    matchingProducts.find(
      ({ pathname: productPathname, activeOnMatch }) =>
        activeOnMatch?.test(pathname) ?? productPathname === pathname,
    ) ?? matchingProducts.find(({ pathname }) => pathname === '')

  return product ?? null
}

export function getActiveProductFromUrl(): ProductNames | null {
  const activeProduct = getActiveProduct()

  return activeProduct?.id ?? null
}

export function getActiveProductName(): ProductLabels {
  const activeProduct = getActiveProduct()
  return activeProduct?.label ?? ProductLabels.BUFFER
}

export function isOnboardingPage(): boolean {
  return (
    getActiveProductFromUrl()?.toString() === 'account' &&
    window.location.pathname.includes('onboarding')
  )
}
