export const getBaseURL = (): string => {
  return window.location.hostname === 'publish.local.buffer.com'
    ? 'local.buffer.com'
    : 'buffer.com'
}

export const openPreviewPage = (url: string): void => {
  window.open(url, '_blank')
}

export const isValidURL = (str: string): boolean => {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  return regexp.test(str)
}

export const urlHasProtocol = (url: string): boolean =>
  url.indexOf('https://') !== -1 || url.indexOf('http://') !== -1

export const getChannelProperties = (channel?: {
  id?: string
  serviceId?: string
  serviceUsername?: string
}): {
  channelId: string
  channelServiceId: string
  channelUsername: string
} => ({
  channelId: channel?.id || '',
  channelServiceId: channel?.serviceId || '',
  channelUsername: channel?.serviceUsername || '',
})

export const DEFAULT_COLOR = '#000000'
export const DEFAULT_CONTRAST_COLOR = '#FFFFFF'
