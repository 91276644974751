import React from 'react'

import { StatusDot, GiftIcon, Slot } from '@buffer-mono/popcorn'

import { useFeatureOsWidget } from '../../../common/hooks/useFeatureOsWidget'

import styles from './WhatsNew.module.css'

type WhatsNewRootProps = React.HTMLAttributes<HTMLButtonElement>

export function WhatsNewRoot(props: WhatsNewRootProps): JSX.Element | null {
  const { className, children, ...rest } = props
  const featureOsWidget = useFeatureOsWidget({
    show: 'changelog',
    enableIndicator: true,
  })

  return (
    <Slot.Root className={className} onClick={featureOsWidget?.open} {...rest}>
      {children}
    </Slot.Root>
  )
}

export function WhatsNewIcon({
  size = 'large',
}: {
  size?: 'small' | 'large'
}): JSX.Element {
  const featureOsWidget = useFeatureOsWidget({
    show: 'changelog',
    enableIndicator: true,
  })

  if (size === 'small') {
    return (
      <span className={styles.whatsNewIcon}>
        <GiftIcon />
        {featureOsWidget?.hasNewChangelog && (
          <StatusDot className={styles.statusDot} />
        )}
      </span>
    )
  }

  return (
    <span className={styles.fullsizeIconButton}>
      <GiftIcon />
      {featureOsWidget?.hasNewChangelog && (
        <StatusDot className={styles.statusDot} />
      )}
    </span>
  )
}

export const WhatsNew = Object.assign(WhatsNewRoot, {
  Icon: WhatsNewIcon,
})
