import * as React from 'react'

export const StreakBackgroundPlain = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={212}
    height={67}
    fill="none"
    viewBox="0 0 212 67"
    aria-hidden="true"
    {...props}
  >
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <rect
          width={212}
          height={212}
          x={212}
          y={-64}
          fill="#D1BDFF"
          rx={5}
          transform="rotate(90 212 -64)"
        />
        <rect
          width={347.766}
          height={54.327}
          x={145.541}
          y={-140.722}
          fill="#E4D7FF"
          rx={5}
          transform="rotate(135 145.541 -140.722)"
        />
        <circle
          cx={188.997}
          cy={24.282}
          r={65.9}
          fill="#D1BDFF"
          transform="rotate(90 188.997 24.282)"
        />
        <rect
          width={189.354}
          height={97.603}
          x={92.633}
          y={-106.268}
          fill="#D1BDFF"
          rx={5}
          transform="rotate(135 92.633 -106.268)"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <rect width={212} height={67} fill="#fff" rx={8} />
      </clipPath>
      <clipPath id="b">
        <rect
          width={212}
          height={212}
          x={212}
          y={-64}
          fill="#fff"
          rx={5}
          transform="rotate(90 212 -64)"
        />
      </clipPath>
    </defs>
  </svg>
)
