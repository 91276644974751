import { useEffect, useState } from 'react'
import { AppshellEventKeys } from '../../../common/events/types'

type UseShouldShowNewlyCommentsDot = {
  showDot: boolean
  setShowDot: (show: boolean) => void
}

export const useShouldShowNewlyCommentsDot =
  (): UseShouldShowNewlyCommentsDot => {
    const [showDot, setShowDot] = useState(false)

    const handleNewCommentEvent = (): void => {
      // if we are not on the comments page, show the dot
      if (window.location.pathname.includes('/comments')) {
        setShowDot(false)
      } else {
        setShowDot(true)
      }
    }

    // Listen for comments ingested events
    useEffect(() => {
      window.addEventListener(
        AppshellEventKeys.NEW_COMMENT_EVENT_KEY,
        handleNewCommentEvent,
      )

      return function cleanup(): void {
        window.removeEventListener(
          AppshellEventKeys.NEW_COMMENT_EVENT_KEY,
          () => setShowDot(false),
        )
      }
    }, [])

    return { showDot, setShowDot }
  }
