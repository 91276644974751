import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Text } from '@bufferapp/ui'
import { Person } from '@bufferapp/ui/Icon'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import uuid from 'uuid/v4'
import TagInput from '../TagInput'
import TagListItem from '../TagListItem'
import ImageLabel from '../ImageLabel'
import { getClientXY, getCoordinates, removeClientXY } from '../../utils/Tags'

import {
  AspectRatio,
  BottomContent,
  CoordinateMarker,
  FooterButtons,
  Image,
  ImageWrapper,
  InputWrapper,
  Line,
  Modal,
  ModalInner,
  PersonIcon,
  ResponsiveContainer,
  RightContent,
  RightHeader,
  SaveButton,
  TagList,
  TextWrapper,
  Title,
  TopContent,
} from './style'
import { calculateAspectRatio } from '~publish/legacy/composer/composer/utils/StringUtils'

const UserTags = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'media' implicitly has an 'any' ty... Remove this comment to see the full error message
  media,
  userTags = [],
  // @ts-expect-error TS(7031) FIXME: Binding element 'saveGlobalTags' implicitly has an... Remove this comment to see the full error message
  saveGlobalTags,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCancel' implicitly has an 'any'... Remove this comment to see the full error message
  onCancel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'translations' implicitly has an '... Remove this comment to see the full error message
  translations,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedChannels' implicitly has ... Remove this comment to see the full error message
  selectedChannels,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trackTag' implicitly has an 'any'... Remove this comment to see the full error message
  trackTag,
  // @ts-expect-error TS(7031) FIXME: Binding element 'trackAllTags' implicitly has an '... Remove this comment to see the full error message
  trackAllTags,
}) => {
  const initialCoordinateState = {
    x: null,
    y: null,
    clientX: null,
    clientY: null,
  }

  const [coordinates, setCoordinates] = useState(initialCoordinateState)
  const [tags, setTags] = useState(getClientXY(userTags))
  const hasUserTags = tags && tags.length > 0
  const [showTags, setShowTags] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [showInput, setShowInput] = useState(false)
  const [inputError, setInputError] = useState(false)
  const tagInputRef = useRef(null)

  const MAX_TAG_LIMIT = 20

  const reachedMaxLimit = tags && tags.length >= MAX_TAG_LIMIT
  const inputValueLength = inputValue.replace(/ /g, '').length
  const isAddTagDisabled = !coordinates.y || inputValueLength < 1
  const isTagInputDisabled = !coordinates.y

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const addTag = (e) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'tag' implicitly has an 'any' type.
    const usernameAlreadyAdded = tags.some((tag) => tag.username === inputValue)
    if (usernameAlreadyAdded) {
      setInputError(true)
      if (e) e.preventDefault()
      return
    }

    setInputError(false)

    const { x, y, clientX, clientY } = coordinates
    const userTag = {
      username: inputValue,
      x,
      y,
      clientX,
      clientY,
    }
    setTags([...tags, userTag])
    setInputValue('')
    setCoordinates(initialCoordinateState)
    setShowTags(true)
    if (tagInputRef.current) {
      // @ts-expect-error TS(2339) FIXME: Property 'blur' does not exist on type 'never'.
      tagInputRef.current.blur()
    }
    setShowInput(false)
    // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
    selectedChannels.forEach((channel) => {
      if (
        channel.service?.name === 'instagram' &&
        channel.serviceType === 'business'
      ) {
        trackTag({ channel, username: inputValue })
      }
    })
    if (e) e.preventDefault()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const cancelAddTag = (e) => {
    setInputValue('')
    setCoordinates(initialCoordinateState)
    if (tagInputRef.current) {
      // @ts-expect-error TS(2339) FIXME: Property 'blur' does not exist on type 'never'.
      tagInputRef.current.blur()
    }
    setShowInput(false)
    if (e) e.preventDefault()
  }

  const saveTags = () => {
    const globalTags = removeClientXY(tags)
    saveGlobalTags(globalTags)
    // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
    selectedChannels.forEach((channel) => {
      if (
        channel.service?.name === 'instagram' &&
        channel.serviceType === 'business'
      ) {
        trackAllTags({ channel, tags })
      }
    })
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'removedUserTag' implicitly has an 'any'... Remove this comment to see the full error message
  const removeTag = (removedUserTag) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'tag' implicitly has an 'any' type.
    const newTagArray = tags.filter((tag) => tag !== removedUserTag)
    setTags(newTagArray)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onImageClick = (e) => {
    const coords = getCoordinates({ e, media })
    setCoordinates(coords)
    // show input once a tag has been added
    setShowInput(true)
    setTimeout(() => {
      if (tagInputRef.current) {
        // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type 'never'.
        tagInputRef.current.focus()
      }
    }, 0)
    e.preventDefault()
  }

  const onTogglePersonIcon = () => setShowTags(!showTags)

  return (
    <Modal>
      <ModalInner>
        <ResponsiveContainer>
          <ImageWrapper>
            <Image
              alt={translations.imgAltText}
              src={media.url}
              onClick={onImageClick}
            />
            {tags && (
              <>
                {/* @ts-expect-error TS(7006) FIXME: Parameter 'tag' implicitly has an 'any' type. */}
                {tags.map((tag) => (
                  <ImageLabel tag={tag} showTags={showTags} key={uuid()} />
                ))}
              </>
            )}
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <CoordinateMarker coordinates={coordinates} />
            {showInput && (
              <InputWrapper
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                coordinates={coordinates}
                onSubmit={addTag}
                error={inputError}
              >
                <TagInput
                  translations={translations}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  disabled={isAddTagDisabled}
                  inputDisabled={isTagInputDisabled}
                  addTag={addTag}
                  cancel={cancelAddTag}
                  reachedMaxLimit={reachedMaxLimit}
                  ref={tagInputRef}
                  error={inputError}
                />
              </InputWrapper>
            )}
          </ImageWrapper>
          {media.width && media.height && (
            <AspectRatio>
              {calculateAspectRatio(media.width, media.height)}
            </AspectRatio>
          )}
        </ResponsiveContainer>
        {hasUserTags && (
          <PersonIcon onClick={onTogglePersonIcon}>
            <Person size="medium" />
          </PersonIcon>
        )}
        <RightContent>
          <TopContent>
            <RightHeader>
              <Title type="h3">{translations.rightHeader}</Title>
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
              <Text>{translations.rightHeaderSubtext}</Text>
            </RightHeader>
            {tags && (
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              <TagList showingInput={showInput}>
                {/* @ts-expect-error TS(7006) FIXME: Parameter 'tag' implicitly has an 'any' type. */}
                {tags.map((tag, index) => (
                  <TagListItem
                    tag={tag}
                    index={index}
                    lastItem={tags.length === index + 1}
                    key={uuid()}
                    removeTag={(tagItem) => removeTag(tagItem)}
                    translations={translations}
                  />
                ))}
              </TagList>
            )}
          </TopContent>
          <BottomContent>
            <Line />
            <TextWrapper>
              {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
              <Text>{translations.footerText}</Text>
            </TextWrapper>
            <FooterButtons disabled={showInput}>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ onClick: any; label: any; type: string; }'... Remove this comment to see the full error message */}
              <Button
                onClick={onCancel}
                label={translations.btnCancel}
                type="text"
              />
              <SaveButton>
                {/* @ts-expect-error TS(2740) FIXME: Type '{ onClick: () => void; type: string; label: ... Remove this comment to see the full error message */}
                <Button
                  onClick={saveTags}
                  type="secondary"
                  label={translations.btnSave}
                  fullWidth
                />
              </SaveButton>
            </FooterButtons>
          </BottomContent>
        </RightContent>
      </ModalInner>
    </Modal>
  )
}

UserTags.propTypes = {
  media: PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  userTags: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
      x: PropTypes.string,
      y: PropTypes.string,
    }),
  ),
  selectedChannels: PropTypes.arrayOf(
    PropTypes.shape({
      serviceId: PropTypes.string,
      id: PropTypes.string,
      service: PropTypes.shape({ username: PropTypes.string }),
    }),
  ).isRequired,
  saveGlobalTags: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  trackTag: PropTypes.func.isRequired,
  trackAllTags: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    rightHeader: PropTypes.string,
    rightHeaderSubtext: PropTypes.string,
    placeholder: PropTypes.string,
    inputLabel: PropTypes.string,
    inputBtnLabel: PropTypes.string,
    footerText: PropTypes.string,
    maxLimitText: PropTypes.string,
    btnSave: PropTypes.string,
    btnCancel: PropTypes.string,
    imgAltText: PropTypes.string,
  }).isRequired,
}

UserTags.defaultProps = {
  userTags: [],
}

export default UserTags
