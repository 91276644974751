import React, { useCallback, useMemo, useState } from 'react'
import { isValidURL, urlHasProtocol } from '~publish/legacy/grid/util'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import { gray } from '@bufferapp/ui/style/colors'
import { Tooltip } from '@buffer-mono/popcorn'
import AppStore from '../stores/AppStore'
import styles from './css/ShopgridComposerBar.module.css'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import * as ReminderFieldsStyled from '~publish/legacy/reminders/components/new-reminders/components/forms/field/styles'

const useIsInstagramEligible = (draft: Draft): boolean => {
  return useMemo(() => {
    const isInstagram = draft.service.isInstagram()
    const selectedInstagramProfiles = AppStore.getSelectedProfiles().filter(
      (profile) => profile.service.name === 'instagram',
    )
    const hasInstagramSelected = selectedInstagramProfiles.some(
      (profile) => profile.instagramDirectEnabled,
    )
    return (
      hasInstagramSelected &&
      isInstagram &&
      AppStore.getOrganizationsData().selected.hasGridFeature &&
      !draft.isReelsPost() &&
      !draft.isStoryPost()
    )
  }, [draft.updateType])
}

const ShopgridComposerBar = ({
  draft,
}: {
  draft: Draft
}): React.JSX.Element => {
  const [shopgridLink, setShopgridLink] = useState(
    draft?.channelData?.instagram?.link || '',
  )
  const helpUrl = `https://support.buffer.com/article/590-instagram-shop-grid?${HC_UTM_PARAMS}`
  const isInstagramEligible = useIsInstagramEligible(draft)

  const updateShopgridLink = useCallback(
    (value: string) => {
      const newLink =
        isValidURL(value) && !urlHasProtocol(value) ? `https://${value}` : value
      setShopgridLink(newLink)
      ComposerActionCreators.updateDraftInstagramData({
        ...draft.channelData?.instagram,
        link: newLink,
      })
    },
    [draft?.channelData?.instagram],
  )

  const isEditDisabled = useMemo(
    () =>
      (draft.instagramFeedback || []).some((f) => f.code === 'ASPECT_RATIO'),
    [draft.instagramFeedback],
  )

  if (!isInstagramEligible) {
    return <></>
  }

  return (
    <ReminderFieldsStyled.Wrapper>
      <ReminderFieldsStyled.LabelWrapper>
        <ReminderFieldsStyled.Label>
          Shop Grid Link
          <span className={styles.shopgridHelpIcon}>
            <Tooltip content="Learn more">
              <a
                href={helpUrl}
                target="_blank"
                rel="noopener noreferrer"
                title="Learn more about Shop Grid"
                onClick={(e): void => {
                  e.preventDefault()
                  window.open(helpUrl, '_blank')
                }}
              >
                <InfoIcon color={gray} />
              </a>
            </Tooltip>
          </span>
        </ReminderFieldsStyled.Label>
      </ReminderFieldsStyled.LabelWrapper>
      <ReminderFieldsStyled.InputWrapper>
        <ReminderFieldsStyled.Input
          disabled={isEditDisabled}
          onChange={(e): void => updateShopgridLink(e.target.value)}
          onBlur={(e): void => updateShopgridLink(e.target.value)}
          name="shopgridLink"
          placeholder="Website or Product URL"
          value={shopgridLink}
        />
      </ReminderFieldsStyled.InputWrapper>
    </ReminderFieldsStyled.Wrapper>
  )
}

export default ShopgridComposerBar
