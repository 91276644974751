import { Button } from '@bufferapp/ui'
import React, { useEffect, useState, useRef, type RefObject } from 'react'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import AppActionCreators from '../../action-creators/AppActionCreators'

import { InstagramThumbnailMaxSize } from '../../AppConstants'
import { isEdge, resizeImageIfNeeded } from '../../utils/DOMUtils'
import InstagramThumbnailSlider from '../InstagramThumbnailSlider'
import { getOrientation } from '../post-preview/previews/Common/utils'
import { useUpdateInstagramThumbnail } from './hooks'
import * as Styles from './styles'

const createNewThumbnail = ({
  imageFileName = '',
  video,
}: {
  imageFileName: string
  video: RefObject<HTMLVideoElement>
}): Promise<File | undefined> => {
  return new Promise((resolve) => {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement

    if (!video.current || !canvas) {
      return
    }

    const maxSize = !isEdge() ? InstagramThumbnailMaxSize : null
    const { width, height } = resizeImageIfNeeded(maxSize, {
      width: video.current.videoWidth,
      height: video.current.videoHeight,
    })
    canvas.width = width
    canvas.height = height

    canvas.getContext('2d')?.drawImage(video.current, 0, 0, width, height)

    canvas.toBlob((blob) => {
      if (!blob) return

      const imageFile = new File([blob], imageFileName, { type: blob.type })
      resolve(imageFile)
    })
  })
}

export interface ThumbnailEditorProps {
  draft: Draft
}

const ThumbnailEditor = ({ draft }: ThumbnailEditorProps): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [videoTitle, setVideoTitle] = useState<string>()
  const video = useRef<HTMLVideoElement>(null)

  const { updateThumbnail } = useUpdateInstagramThumbnail()

  useEffect(() => {
    if (draft?.video?.name) setVideoTitle(draft.video.name)
  }, [draft?.video?.name])

  const orientation = getOrientation({
    height: draft?.video?.height,
    width: draft?.video?.width,
  })

  const handleSave = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    e.preventDefault()

    setLoading(true)
    AppActionCreators.setThumbnailLoading(true) // disables add to queue btn

    createNewThumbnail({
      imageFileName: draft.video ? `${draft.video.name}_thumbnail.png` : '',
      video,
    }).then((imageFile) => {
      if (!imageFile) {
        return
      }

      updateThumbnail({
        imageFile,
        videoTitle,
        draftId: draft.id,
      })
    })
  }

  return (
    <Styles.Wrapper id="instagramThumbnail">
      <Styles.Modal hideCloseButton>
        <Styles.Card>
          <Styles.Header>
            <Styles.HeaderTitle>Edit Thumbnail</Styles.HeaderTitle>
            <Styles.TitleText>
              Choose a frame from your video as a thumbnail.
            </Styles.TitleText>
            {draft.service.canEditVideoTitle && (
              <>
                <Styles.Title htmlFor="video-title-input">Title</Styles.Title>
                <Styles.Input
                  type="text"
                  value={videoTitle}
                  onChange={(e): void => setVideoTitle(e.target.value)}
                  id="video-title-input"
                />
              </>
            )}
          </Styles.Header>
          <Styles.MediaContainer orientation={orientation}>
            <Styles.Video
              // Comment out 'crossOrigin' for stories to load videos
              crossOrigin="anonymous"
              id="thumbnailVideo"
              src={draft?.video?.url}
              muted
              preload="auto"
              ref={video}
            />
            <InstagramThumbnailSlider videoRef={video} />
            <canvas id="canvas" style={{ display: 'none' }} />
          </Styles.MediaContainer>

          <Styles.Footer>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: boo... Remove this comment to see the full error message */}
            <Button
              type="primary"
              label={loading ? 'Saving...' : 'Save'}
              disabled={loading}
              size="medium"
              onClick={handleSave}
            />
          </Styles.Footer>
        </Styles.Card>
      </Styles.Modal>
    </Styles.Wrapper>
  )
}

export default ThumbnailEditor
