import { BufferTrackerReact } from '@buffer-mono/tracking-plan'
import type { Channel, Post } from '~publish/gql/graphql'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useCommonTrackingProperties } from './useCommonTrackingProperties'

type TrackPostDraggedPostType = Pick<Post, 'id' | 'dueAt' | 'status'> & {
  channel: Pick<Channel, 'id' | 'serviceId' | 'type' | 'displayName' | 'name'>
}

type TrackPostDragged = (post: TrackPostDraggedPostType) => void

/**
 * A custom hook used for managing data required by "Post Dragged" tracking event.
 *
 * The hook returns a function, `trackPostDragged`, to track when a post is dragged using the `BufferTrackerReact.postDragged` method.
 *
 * @typedef {Object} TrackPostDraggedPostType - The post and channel data to be tracked when a post is dragged.
 * @property {string} id - The unique identifier for the post.
 * @property {string} dueAt - The date and time when the post is due.
 * @property {string} status - The current status of the post (e.g., 'scheduled', 'draft', etc.).
 * @property {Object} channel - The channel information for the post.
 * @property {string} channel.id - The unique identifier for the channel.
 * @property {string} channel.serviceId - The service identifier (e.g., 'facebook', 'twitter').
 * @property {string} channel.type - The type of the channel (e.g., 'social', 'email').
 * @property {string} channel.displayName - The display name of the channel (if available).
 * @property {string} channel.name - The name of the channel.
 *
 * @returns {function(TrackPostDraggedPostType): void} - A function to trigger the tracking of a post being dragged.
 *
 * @example
 * const trackPostDragged = usePostDraggedTracking();
 * trackPostDragged(post);
 */
export const usePostDraggedTracking = (): TrackPostDragged => {
  const organizationId = useOrganizationId()
  const commonTrackingProperties = useCommonTrackingProperties()

  const trackPostDragged: TrackPostDragged = (post) => {
    const { id: postId, channel, dueAt, status } = post
    const {
      id: channelId,
      serviceId: channelServiceId,
      type: channelType,
      displayName: channelDisplayName,
      name: channelName,
    } = channel

    BufferTrackerReact.postDragged({
      ...commonTrackingProperties,
      channelId,
      channelServiceId,
      channelType,
      channelUsername: channelDisplayName ?? channelName ?? '',
      clientName: 'publishWeb',
      dueAt: dueAt ?? '',
      organizationId: organizationId ?? '',
      postId,
      postStatus: status,
    })
  }

  return trackPostDragged
}
