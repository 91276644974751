import styled from 'styled-components'

import { white, green, grayDarker } from '@bufferapp/ui/style/colors'

const ModalHeight = '500px'

export const TrialExpiredContainer = styled.div`
  position: fixed;
  z-index: 9999;
`

export const ScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 864px;
  height: ${ModalHeight};

  h2 {
    max-width: 300px;
    margin: 0;
    line-height: 36px;
  }

  p {
    margin: 8px 0 16px;
  }
`

export const SectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  padding: 48px;
  width: 51%;
`

interface ISectionRight {
  backgroundImageURL: string
}

export const SectionRight = styled.div<ISectionRight>`
  width: 50%;
  height: ${ModalHeight};

  background-image: url(${(props: ISectionRight): string =>
    props.backgroundImageURL});
  background-repeat: no-repeat;
  background-size: cover;
`

export const ButtonContainer = styled.div`
  margin-top: 10px;

  > div:first-child {
    margin-right: 8px;
  }
`

export const Details = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;

  li {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 12px;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: ${grayDarker};
    }
  }
`

export const Check = styled.span`
  display: flex;
  width: 16px;
  height: 16px;
  margin-right: 11px;

  align-items: center;
  justify-content: center;
  border-radius: 36px;
  background-color: ${green};

  svg {
    width: 9px;
    height: 9px;
    color: ${white};
  }
`
