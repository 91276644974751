import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { ORGANIZATION_ACTIONS } from '../events/orgEvents'
import { UPDATE_SUBSCRIPTION_QUANTITY_BY_CHANNEL_IDS } from '../graphql/billing'
import { QUERY_ACCOUNT } from '../graphql/account'
import { prefixWithProduct } from '../utils/prefixWithProduct'
import type { Account } from '../types'

const forceRefetchProfilesAfterChannelUnlock = ({
  channelIds,
}: {
  channelIds: string[]
}): void => {
  // When users connect a channel while at their plan limit, they are
  // prompted to pay in order to unlock that channel. Once they do, they
  // are able to use Publish, however, we can't directly refetch their
  // profiles as it's done by dispatching an action that makes an RPC
  // request. This workaround forces a page refresh to refetch their
  // profiles if they have just recently successfully connected a channel
  // and are in Publish.
  const publishEnvUrls = [
    'publish.local.buffer.com:8888/channels',
    'publish.dev.buffer.com/channels',
    'publish.buffer.com/channels',
  ]

  const isVisitingPublish = publishEnvUrls.some((url) =>
    window.location.href.includes(url),
  )

  if (isVisitingPublish) {
    const justConnectedChannelId = channelIds[0]
    window.location.href = `${window.location.href}?channelId=${justConnectedChannelId}&channelConnectionSuccess=true&tab=queue`
  }
}

const useUpdateSubscriptionQuantityByChannelIds = ({
  user,
  channelIds,
  cta,
  upgradePathName,
}: {
  user: Account
  channelIds: string[]
  cta: string
  upgradePathName: string
}): {
  updateSubscriptionQuantityByChannelIds: () => void
  error: { message: string } | null
  isProcessing: boolean
} => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState<{
    message: string
  } | null>(null)

  const [
    updateSubscriptionQuantityByChannelIdsRequest,
    { data, error: mutationError },
  ] = useMutation(UPDATE_SUBSCRIPTION_QUANTITY_BY_CHANNEL_IDS, {
    refetchQueries: [{ query: QUERY_ACCOUNT }],
    awaitRefetchQueries: true,
  })

  function updateSubscriptionQuantityByChannelIds(): void {
    setIsProcessing(true)
    updateSubscriptionQuantityByChannelIdsRequest({
      variables: {
        organizationId: user.currentOrganization?.id,
        channelIds,
        attribution: { cta: prefixWithProduct(cta), upgradePathName },
      },
    })
  }

  useEffect(() => {
    if (mutationError) {
      setError({
        message: 'There has been an issue. Please try again.',
      })
    } else if (
      data?.billingIncreaseSubscriptionQuantityByChannelIds?.userFriendlyMessage
    ) {
      setError({
        message:
          data.billingIncreaseSubscriptionQuantityByChannelIds
            .userFriendlyMessage,
      })
      setIsProcessing(false)
    } else if (data?.billingIncreaseSubscriptionQuantityByChannelIds) {
      ORGANIZATION_ACTIONS.billingUpdated({ user })
      setIsProcessing(false)
      forceRefetchProfilesAfterChannelUnlock({
        channelIds,
      })
    }
    // linting complains here that we should 'user' as a dependency, etc,
    // but doing so creates an infinite loop of this effect running
  }, [mutationError, data])

  return {
    updateSubscriptionQuantityByChannelIds,
    error,
    isProcessing,
  }
}

export default useUpdateSubscriptionQuantityByChannelIds
