import React from 'react'

import { Heading, Skeleton } from '@buffer-mono/popcorn'

import { useTimezone } from '~publish/hooks/useTimezone'

import { Temporal } from '@js-temporal/polyfill'
import styles from './DateSeparator.module.css'

export function SeparatorHeader({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <Heading size="small" as="h3" className={styles.header}>
      {children}
    </Heading>
  )
}

export function DateSeparator({
  currentDate,
  previousDate,
}: {
  currentDate: string
  previousDate?: string
}): JSX.Element | null {
  const timezone = useTimezone()
  const currentDateZoned =
    Temporal.Instant.from(currentDate).toZonedDateTimeISO(timezone)
  const previousDateZoned = previousDate
    ? Temporal.Instant.from(previousDate).toZonedDateTimeISO(timezone)
    : undefined

  const isFirstPost = !previousDateZoned
  const isDifferentDateGroup =
    !!currentDateZoned &&
    !!previousDateZoned &&
    !currentDateZoned.toPlainDate().equals(previousDateZoned.toPlainDate())

  const shouldRenderDateSeparator = isFirstPost || isDifferentDateGroup

  if (!shouldRenderDateSeparator) {
    return null
  }

  const { prefix, date } = formatDateSeparator(currentDateZoned)

  return (
    <SeparatorHeader>
      {prefix}, <small className={styles.subheader}> {date}</small>
    </SeparatorHeader>
  )
}

export function SeparatorHeaderSkeleton(): JSX.Element {
  return <Skeleton height={10} width={150} className={styles.skeleton} />
}

export function formatDateSeparator(date: Temporal.ZonedDateTime): {
  prefix: string
  date: string
} {
  const now = Temporal.Now.zonedDateTimeISO(date.timeZoneId)
  const today = now.startOfDay()
  const yesterday = today.subtract({ days: 1 })
  const tomorrow = today.add({ days: 1 })

  let prefix = date.toPlainDate().toLocaleString(undefined, { weekday: 'long' })

  // Add special prefixes for Today, Yesterday, and Tomorrow
  if (date.toPlainDate().equals(yesterday.toPlainDate())) {
    prefix = 'Yesterday'
  } else if (date.toPlainDate().equals(today.toPlainDate())) {
    prefix = 'Today'
  } else if (date.toPlainDate().equals(tomorrow.toPlainDate())) {
    prefix = 'Tomorrow'
  }

  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
  }
  if (date.year !== today.year) {
    dateFormatOptions.year = 'numeric'
  }

  // Format the date using the provided timezone
  const formattedDate = date.toLocaleString(undefined, dateFormatOptions)

  return { prefix, date: formattedDate }
}
