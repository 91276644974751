import React, { useEffect } from 'react'
import Joyride, {
  ACTIONS,
  EVENTS,
  STATUS,
  type CallBackProps,
  type Step,
} from 'react-joyride'

import { useLocalStorage } from '@buffer-mono/popcorn'
import { useSplit } from '@buffer-mono/features'

import {
  channel as channelRoute,
  getMatch,
  newCalendarSingleChannel,
} from '~publish/legacy/routes'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'

import { StepCoachmark } from './StepCoachmark'

export enum TourStep {
  SLOT = 0,
  MORE_MENU = 1,
}
const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000
const CUSTOM_ACTIONS = {
  OPEN_COMPOSER: 'OpenComposer',
} as const

export const POSTING_GOAL_SLOTS_TOUR_STORAGE_KEY =
  'preference:posting-goal-slots-tour'
export enum TourStorageStep {
  CLOSED = 'closed', // finished the tour
  STEP_0 = '0',
  STEP_1 = '1',
}

/**
 * Steps for the tour when the channel has a posting goal
 * @returns Step[]
 */
export const postingGoalSteps: Step[] = [
  {
    target: '[data-tour-id="slot"]',
    title: 'Use our recommended posting times 🎯',
    content:
      "Based on our research, we've suggested these times to maximize engagement. You can post at other times too!",
    disableBeacon: true,
    offset: 0,
    data: {
      actions: [
        {
          text: 'Next',
        },
      ],
    },
  },
  {
    target: '[data-tour-id="channel-settings"]',
    title: 'Adjust your schedule ✏️',
    content: 'Edit your posting goal, posting times, and other settings here.',
    offset: 0,
    data: {
      actions: [
        {
          text: 'Got It',
        },
      ],
    },
  },
]

/**
 * Steps for the tour when the channel does not have a posting goal
 * @returns Step[]
 */
export const postingScheduleSteps: Step[] = [
  {
    target: '[data-tour-id="slot"]',
    title: "Don't have a goal in mind? No worries! ⏰",
    content:
      "We've recommended some posting times based on our research, so you can schedule posts in one click.",
    disableBeacon: true,
    offset: 0,
    data: {
      actions: [
        {
          text: '+ New Post',
          action: CUSTOM_ACTIONS.OPEN_COMPOSER,
        },
      ],
    },
  },
]

/**
 * Returns true if the user is in a single channel view (calendar or queue)
 * @returns boolean
 */
function isInSingleChannelView(): boolean {
  const singleChannelCalendarMatch = getMatch({
    pathname: location.pathname,
    route: newCalendarSingleChannel.route,
  })

  const singleChannelMatch = getMatch({
    pathname: location.pathname,
    route: channelRoute.route,
  })

  return !!singleChannelCalendarMatch || !!singleChannelMatch
}

/**
 * Returns true if the posting goals split is enabled
 * @returns boolean
 */
export function usePostingGoalsSplit(): boolean {
  const { split } = useSplit('geid-posting-goals-channel-setup')

  return (split?.treatment ?? 'ineligible').startsWith('variant_')
}

/**
 * Checks if any dialog is currently open in the document by looking for elements with
 * role="dialog" and data-state="open"
 * @returns {boolean} True if any dialog is open, false otherwise
 */
function isAnyDialogOpen(): boolean {
  const dialogs = document.querySelectorAll(
    '[role="dialog"][data-state="open"]',
  )
  return dialogs.length > 0
}

/**
 * React hook that tracks whether any dialog is open in the document
 * Uses a MutationObserver to watch for changes to dialog states
 *
 * [HACK]: This is needed because due to how the Composer is implemented, we
 * need to render floating elements (popover, dialogs, etc) in the
 * "portals-container" and those floating elements are not properly placed on
 * their corresponding part of the DOM with their corresponding z-index. Once we
 * move the composer to use Popcorn this should be fixed and this kind of hacks
 * will not be needed anymore.
 * @returns {boolean} True if any dialog is open, false otherwise
 */
function useIsDialogOpen(): boolean {
  const [isDialogOpen, setIsDialogOpen] = React.useState(isAnyDialogOpen())

  useEffect(
    function trackDialogStateChanges() {
      const checkDialogs = (): void => {
        setIsDialogOpen(isAnyDialogOpen())
      }

      const observer = new MutationObserver(checkDialogs)
      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['data-state'],
      })

      return () => observer.disconnect()
    },
    [setIsDialogOpen],
  )

  return isDialogOpen
}

export const PostingGoalSlotsTour = (): JSX.Element => {
  const [slotsTourCurrentStep, setSlotsTourCurrentStep] = useLocalStorage(
    POSTING_GOAL_SLOTS_TOUR_STORAGE_KEY,
    TourStorageStep.STEP_0,
  )
  const splitEnabled = usePostingGoalsSplit()
  const guide = useDismissableBanner('posting-goal-slots-tour')
  const channel = useSelectedChannel()

  // Channel created in the last 30 minutes
  const isRecentlyConnectedChannel =
    new Date(channel.createdAt).getTime() >= Date.now() - THIRTY_MINUTES_IN_MS

  const steps =
    (channel.postingGoal?.goal ?? 0) > 0
      ? postingGoalSteps
      : postingScheduleSteps

  const { isOpen: isPostComposerOpen, createNewPostInComposer } =
    usePostComposer()

  const [run, setRun] = React.useState(false)
  const isDialogOpen = useIsDialogOpen()
  const [stepIndex, setStepIndex] = React.useState(
    parseInt(slotsTourCurrentStep, 10),
  )

  useEffect(() => {
    const currentStep = parseInt(slotsTourCurrentStep, 10)
    if (currentStep >= 0) {
      setRun(true)
    }
  }, [slotsTourCurrentStep])

  const resetTour = (): void => {
    setSlotsTourCurrentStep(TourStorageStep.CLOSED)
    setRun(false)
    setStepIndex(-1)
    guide.dismiss()
  }

  const joyrideCallback = (data: CallBackProps): void => {
    const { action, index, status, type, size, step } = data

    if (
      action === ACTIONS.CLOSE ||
      status === STATUS.FINISHED ||
      status === STATUS.SKIPPED
    ) {
      resetTour()
      // If the user clicked action button in the last step, and has custom action, open the composer
      if (step.data?.actions?.[0]?.action === CUSTOM_ACTIONS.OPEN_COMPOSER) {
        createNewPostInComposer({
          channels: [channel.id],
        })
      }
      return
    }

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const isLastStep = index === size - 1

      if (isLastStep && action === ACTIONS.NEXT) {
        // This is the last step and the user clicked "Next"
        resetTour()
      } else {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
        setStepIndex(nextStepIndex)
        setSlotsTourCurrentStep(nextStepIndex.toString() as TourStorageStep)
      }
    }
  }

  if (
    !isInSingleChannelView() ||
    !guide.isActive ||
    !splitEnabled ||
    !isRecentlyConnectedChannel ||
    isPostComposerOpen ||
    isDialogOpen
  ) {
    return <></>
  }

  return (
    <Joyride
      steps={steps}
      continuous
      showSkipButton={false}
      run={run}
      stepIndex={stepIndex}
      callback={joyrideCallback}
      disableOverlayClose
      disableOverlay
      disableScrolling
      disableScrollParentFix
      tooltipComponent={(props): JSX.Element => (
        <StepCoachmark {...props} open />
      )}
      styles={{
        options: {
          arrowColor: 'transparent',
          overlayColor: 'transparent',
        },
        tooltip: {
          padding: 0,
        },
      }}
    />
  )
}
