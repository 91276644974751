import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import {
  Flex,
  Heading,
  Sidebar,
  Label,
  Skeleton,
  Input,
  Button,
  Separator,
  Text,
  toast,
  OrderedList,
} from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useAccount } from '~publish/legacy/accountContext'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { graphql } from '~publish/gql'

import SettingsPageLayout from './components/SettingsPageLayout'

import style from './Settings.module.css'

export const GetOrganizationReferalCode = graphql(/* GraphQL */ `
  query organizationReferralCode($organizationId: String!) {
    organizationReferralCode(organizationId: $organizationId) {
      referralCode
    }
  }
`)

const howItWorksSteps = [
  'Find the unique referral link above for your organization.',
  'Share your link publicly or with someone who’d enjoy using Buffer.',
  'Your friend signs up for their free Buffer account.',
  'You and your friend can grow on social and beyond.',
]

const ReferalCode = (): JSX.Element => {
  const { account } = useAccount()
  const { triggerAttributes, createNewPostInComposer } = usePostComposer()

  const organizationName = account?.currentOrganization?.name
  const organizationId = account?.currentOrganization?.id

  const { data, loading } = useQuery(GetOrganizationReferalCode, {
    variables: {
      organizationId,
    },
  })

  const referralCode = data?.organizationReferralCode?.referralCode
  const referralCodeLink = `https://buffer.com/join/${referralCode}`

  const openComposer = useCallback((): void => {
    createNewPostInComposer({
      cta: 'publish-settings-referAFriend-shareNow-1',
      channels: 'all',
      prefillPostData: {
        text: `I’m using Buffer to grow an engaged audience on social and beyond. Give it a try and create your free (forever) account today: https://buffer.com/join/${referralCode}`,
      },
    })
  }, [createNewPostInComposer, referralCode])

  const handleCopyLink = ({ link }: { link: string }): void => {
    BufferTracker.referralLinkCopied({
      organizationId: organizationId || '',
    })

    navigator.clipboard.writeText(link)

    toast.success('Referral link copied to clipboard')
  }

  const handleShareNow = (): void => {
    BufferTracker.referralPostStarted({
      organizationId: organizationId || '',
    })

    openComposer()
  }

  return (
    <Flex gap="sm" direction="column" fullWidth>
      <Label>{organizationName}&apos;s referral link:</Label>
      <Flex gap="sm" direction="row" align="center" fullWidth>
        {loading || !referralCode ? (
          <Skeleton width="100%" height="20px" />
        ) : (
          <Input
            className={clsx(style.formInput, style.fullWidth)}
            value={referralCodeLink}
            readOnly
          />
        )}
        <Button
          size="medium"
          variant="secondary"
          onClick={(): void => handleCopyLink({ link: referralCodeLink })}
        >
          Copy Link
        </Button>
        <Button
          {...triggerAttributes}
          size="medium"
          variant="primary"
          onClick={handleShareNow}
        >
          Share Now
        </Button>
      </Flex>
    </Flex>
  )
}

export const ReferAFriend = ({
  isMobile,
  handleToggleSidebar,
}: {
  isMobile: boolean
  handleToggleSidebar: () => void
}): JSX.Element => {
  return (
    <SettingsPageLayout>
      <Flex gap="xl" direction="column">
        <section className={style.fullWidth}>
          <Flex gap="sm" direction="column">
            <Flex gap="sm" direction="row" align="center">
              {isMobile && <Sidebar.Trigger onClick={handleToggleSidebar} />}
              <Heading as="h1" size="large">
                Refer a Friend
              </Heading>
            </Flex>
            <Text color="subtle">
              Enjoying Buffer? <br />
              Your friends will too! Share Buffer to help them grow an audience
              with a totally free account.
              <br />
              You’ll also support Buffer’s growth, allowing us to continue
              improving the service.
            </Text>
          </Flex>
        </section>

        <section className={style.fullWidth}>
          <ReferalCode />
          <Separator />

          <Flex gap="sm" direction="column" fullWidth>
            <Heading size="small">How it works:</Heading>
            <Flex fullWidth gap="sm" direction="row" align="center">
              <OrderedList key=".0">
                {howItWorksSteps.map((step, index) => (
                  <React.Fragment key={index}>
                    <OrderedList.Item>{step}</OrderedList.Item>
                  </React.Fragment>
                ))}
              </OrderedList>
            </Flex>
          </Flex>
        </section>
      </Flex>
    </SettingsPageLayout>
  )
}

export default ReferAFriend
