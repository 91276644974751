import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../shared-components/modal/modal'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import AppActionCreators from '../action-creators/AppActionCreators'
import Button from './shared/Button'
import styles from './css/EmptyTextAlert.module.css'

class EmptyTextAlert extends React.Component {
  static propTypes = {
    queueingType: PropTypes.string.isRequired,
  }

  onClickCancel = () => {
    ModalActionCreators.closeModal()
  }

  onClickSendAndRemember = () => {
    ModalActionCreators.closeModal()
    AppActionCreators.rememberModalView('remember_confirm_saving_modal')
    // @ts-expect-error TS(2339) FIXME: Property 'queueingType' does not exist on type 'Re... Remove this comment to see the full error message
    AppActionCreators.saveDrafts(this.props.queueingType)
  }

  onClickSend = () => {
    ModalActionCreators.closeModal()
    // @ts-expect-error TS(2339) FIXME: Property 'queueingType' does not exist on type 'Re... Remove this comment to see the full error message
    AppActionCreators.saveDrafts(this.props.queueingType)
  }

  render() {
    return (
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; classNames: { modal: ... Remove this comment to see the full error message
      <Modal classNames={{ modal: styles.modalStyles }}>
        <h2 className={styles.title}>Send update?</h2>
        <p>Looks like you're posting an update to Facebook without any text.</p>
        <p>Keen to post this to Facebook anyway?</p>
        <div className={styles.buttonsContainer}>
          <Button className={styles.cancelButton} onClick={this.onClickCancel}>
            Cancel
          </Button>
          <Button
            className={styles.sendAndRemember}
            onClick={this.onClickSendAndRemember}
          >
            Send and don't ask again
          </Button>
          <Button className={styles.sendButton} onClick={this.onClickSend}>
            Send
          </Button>
        </div>
      </Modal>
    )
  }
}

export default EmptyTextAlert
