import React from 'react'

import {
  BufferIcon,
  Button,
  Link,
  LockOpenIcon,
  Notice,
  Skeleton,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { useQuery } from '@apollo/client'
import { type FragmentOf, graphql, readFragment } from '~publish/graphql'

const ChannelsPricingNotice_Billing = graphql(/* GraphQL */ `
  fragment ChannelsPricingNotice_Billing on Billing @_unmask {
    __typename
    gateway {
      gatewayType
      gatewayId
      gatewayPlatform
    }
    ... on OBBilling {
      subscription {
        trial {
          isActive
        }
        plan {
          id
          name
          limits {
            channels
          }
        }
      }
    }
    ... on MPBilling {
      subscriptions {
        plan
        product
      }
    }
  }
`)

const ChannelsPricingNotice_Limits = graphql(/* GraphQL */ `
  fragment ChannelsPricingNotice_Limits on OrganizationLimits {
    channels
  }
`)

const GetBilling = graphql(
  /* GraphQL */ `
    query GetBilling {
      account {
        id
        currentOrganization {
          id
          limits {
            ...ChannelsPricingNotice_Limits
          }
          billing {
            ...ChannelsPricingNotice_Billing
          }
        }
      }
    }
  `,
  [ChannelsPricingNotice_Billing, ChannelsPricingNotice_Limits],
)

type BillingMask = FragmentOf<typeof ChannelsPricingNotice_Billing>

/**
 * @param billing: BillingMask
 * @returns string The name of the plan for MP or OB billing
 */
const getPlanName = (billing?: BillingMask): string => {
  if (!billing) {
    return 'Free'
  }
  if (billing.__typename === 'OBBilling') {
    return billing.subscription.plan.name
  }
  if (billing.__typename === 'MPBilling') {
    const subscription = billing.subscriptions.find(
      (sub) => sub.product === 'publish',
    )
    return subscription?.plan || 'Free'
  }
  return 'Free'
}

function getMobileBillingDetails(billing?: BillingMask):
  | {
      app: string
      link: string
    }
  | undefined {
  const billingGatewayPlatform = billing?.gateway?.gatewayPlatform
  if (billingGatewayPlatform === 'android') {
    return {
      app: 'Buffer for Android',
      link: 'https://play.google.com/store/apps/details?id=org.buffer.android',
    }
  }
  if (billingGatewayPlatform === 'apple') {
    return {
      app: 'Buffer for iOS',
      link: 'https://apps.apple.com/app/apple-store/id490474324',
    }
  }
}

function isOnTrial(billing?: BillingMask): boolean {
  if (billing && billing.__typename === 'OBBilling') {
    return billing?.subscription?.trial?.isActive || false
  }
  return false
}

const TRIAL_CHANNELS_LIMIT = 20

const ChannelsPricingNotice = ({
  numLockedChannels,
  lockedChannelsVisible,
}: {
  numLockedChannels: number
  lockedChannelsVisible: boolean
}): JSX.Element => {
  const { data, loading } = useQuery(GetBilling)

  const billing = readFragment(
    ChannelsPricingNotice_Billing,
    data?.account.currentOrganization?.billing,
  )
  const limits = readFragment(
    ChannelsPricingNotice_Limits,
    data?.account.currentOrganization?.limits,
  )
  const planName = getPlanName(billing)

  const isMobileBilling = billing?.gateway?.gatewayPlatform !== 'web'
  const mobileBillingDetails = getMobileBillingDetails(billing)

  const isTrial = isOnTrial(billing)

  return (
    <Skeleton show={loading}>
      <Notice icon={<BufferIcon style={{ marginTop: '2px' }} />}>
        <Notice.Heading>Get to know your plan</Notice.Heading>
        <Notice.Text>
          You are on the {planName} plan{isTrial ? ' trial' : ''} and can
          connect up to {isTrial ? TRIAL_CHANNELS_LIMIT : limits?.channels}{' '}
          channels{isTrial ? ' during your trial period' : ''}.
          {numLockedChannels > 0 && (
            <> {numLockedChannels} of your channels are locked.</>
          )}
          {isMobileBilling && mobileBillingDetails && (
            <>
              {' '}
              Please use the{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                data-testid="mobile-billing-link"
                href={mobileBillingDetails.link}
              >
                {mobileBillingDetails.app}
              </Link>{' '}
              app to manage your subscription.
            </>
          )}
        </Notice.Text>
        {!isMobileBilling &&
          !lockedChannelsVisible &&
          numLockedChannels > 0 && (
            <Notice.Actions>
              <VisuallyHidden id="locked-channels-notice">
                {numLockedChannels} of your channels are locked.
              </VisuallyHidden>
              <Button
                as="a"
                href="#locked-channels"
                size="small"
                variant="secondary"
                aria-describedby="locked-channels-notice"
              >
                <LockOpenIcon /> Unlock Channels
              </Button>
            </Notice.Actions>
          )}
      </Notice>
    </Skeleton>
  )
}

export default ChannelsPricingNotice
