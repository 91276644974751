import AppDispatcher from '../../dispatcher'
import { ActionTypes } from '../../state/ActionTypes'

const actionCreators = {
  closeModal: (): void =>
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.CLOSE_MODAL,
    }),

  openModal: (name: string, props: any): void =>
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.OPEN_MODAL,
      name,
      props,
    }),

  setAppNotificationMessage: (message: string): void =>
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.SET_APP_NOTIFICATION_MESSAGE,
      message,
    }),
}

export default actionCreators
