import React from 'react'
import { Link } from 'react-router-dom'

import {
  DropdownMenu,
  Button,
  ChevronDownIcon,
  Switch,
} from '@buffer-mono/popcorn'

import { channelSettings } from '~publish/legacy/routes'

import { useSlotsEnabled } from '../../hooks/useSlotsEnabled'

import styles from './MoreActions.module.css'

interface MoreActionsProps extends React.HTMLAttributes<HTMLButtonElement> {
  channelId: string
}

export function MoreActions({
  channelId,
  ...props
}: MoreActionsProps): JSX.Element {
  const [showTimeSlots, setShowTimeSlots] = useSlotsEnabled()

  const handleShowTimeSlots = (e: Event): void => {
    e.preventDefault()
    setShowTimeSlots(!showTimeSlots)
  }

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <Button variant="tertiary" {...props}>
          More
          <ChevronDownIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={styles.content}>
        <DropdownMenu.Item
          className={styles.item}
          onSelect={handleShowTimeSlots}
        >
          Show Time Slots
          <Switch size="small" checked={showTimeSlots} />
        </DropdownMenu.Item>
        <DropdownMenu.Item>
          <Link
            to={channelSettings.getRoute({
              channelId,
              tab: 'posting-schedule',
            })}
            className={styles.link}
          >
            Manage Time Slots
          </Link>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
