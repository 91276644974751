import { NewspaperIcon } from '@buffer-mono/popcorn'
import React, { useState } from 'react'

type FeedItemIconProps = {
  feedName: string
  url?: string
  size?: number
  className?: string
  thumbnailUrl?: string
}

export const FeedItemIcon = (props: FeedItemIconProps): JSX.Element => {
  const { feedName, url, size = 16, thumbnailUrl, ...rest } = props
  const [hasError, setHasError] = useState(false)

  const domain = url ? new URL(url).hostname : null

  return domain && !hasError ? (
    <img
      aria-hidden="true"
      src={thumbnailUrl || `https://icons.duckduckgo.com/ip3/${domain}.ico`}
      alt={`${feedName} icon`}
      width={size}
      height={size}
      onError={(e): void => {
        e.currentTarget.onerror = null // Prevent infinite loop
        setHasError(true)
      }}
      {...rest}
    />
  ) : (
    <NewspaperIcon {...rest} />
  )
}
