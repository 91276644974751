import React from 'react'

import PostTypeBar from './PostTypeBar'
import {
  PostTypeFacebookReel,
  PostTypeFacebookStory,
  PostTypePost,
} from '../../../../post/constants'
import { type Draft, DraftMethods } from '../../entities/Draft'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import { useCurrentOrganization } from '~publish/legacy/accountContext/index'

type PostTypes = {
  value: string
  name: string
  disabledCallback?: () => boolean
  disabledMessage?: string
}[]

const FacebookPostTypeBar = ({ draft }: { draft: Draft }): JSX.Element => {
  const currentOrganization = useCurrentOrganization()

  if (!draft.service.isFacebook()) {
    return <></>
  }

  const shouldShowStory = currentOrganization?.isOneBufferOrganization
  const selectedFacebookProfiles = AppStore.getSelectedProfilesForService(
    draft.id,
  )
  const facebookGroupIsSelected = selectedFacebookProfiles.some(
    (profile) => profile.serviceType === 'group',
  )

  if (!selectedFacebookProfiles || facebookGroupIsSelected) {
    return <></>
  }

  const disabledMessage = draft.hasImagesAttachment()
    ? 'You have to delete images in order to create a Reel'
    : 'You have to delete gif in order to create a Reel'

  const postTypes: PostTypes = [
    { value: PostTypePost, name: 'Post' },
    {
      value: PostTypeFacebookReel,
      name: 'Reel',
      disabledCallback: () =>
        draft.hasImagesAttachment() || draft.hasGifAttachment(),
      disabledMessage,
    },
    ...(shouldShowStory
      ? [
          {
            value: PostTypeFacebookStory,
            disabledCallback: () => DraftMethods.getMediaCount(draft) > 1,
            disabledMessage: 'Story can contain only one media item',
            name: 'Story',
          },
        ]
      : []),
  ]

  return (
    <PostTypeBar
      postTypes={postTypes}
      defaultPostType={PostTypePost}
      draft={draft}
    />
  )
}

export default FacebookPostTypeBar
