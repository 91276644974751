import React, { useEffect } from 'react'
import { useReward } from 'react-rewards'

import {
  Avatar,
  BufferIcon,
  Button,
  Card,
  ChannelAvatar,
  ChannelIcon,
  ChevronDownIcon,
  DropdownMenu,
  Flex,
  Heading,
  SuccessDialog,
  Text,
} from '@buffer-mono/popcorn'

import {
  getPublishUrl,
  isInPublish,
} from '../../../../../../common/utils/urls'

import type { Service } from '../../../../../../exports/Orchestrator/channelConnections/types'
import useChannels from '../../../../hooks/useChannels'
import { cleanupUrlParams } from '../../../../utils'
import { getServiceName } from '../../../../../../exports/Orchestrator/channelConnections/utils'

import { MODAL_ACTIONS } from '../../../../../../common/events/modalEvents'
import trackCTAClicked from '../../../../../../tracking/UpgradePaths/trackCTAClicked'
import { useUser } from '../../../../../../common/context/User'

import { useModalManager } from '../../../../../ModalManager/hooks/useModalManager'

import styles from './NewChannelConnectionOnboardingContent.module.css'

function handleCreatePostCTA({
  socialChannelId,
  handleOnDismissModal,
}: {
  socialChannelId: string
  handleOnDismissModal: () => void
}): void {
  const cta = 'connectChannelOnboarding-createAPostCta'
  handleOnDismissModal()
  const channelsQuery = `channelId[]=${socialChannelId}`
  const newPostRoute = `post/new?${channelsQuery}&cta=${cta}`

  const isInPublishApp = isInPublish()

  // If we are in publish and have access to openComposer. Open the Composer directly
  if (window.__openComposer && isInPublishApp) {
    const preSelectedChannels = [socialChannelId]
    window.__openComposer(
      {
        channels: preSelectedChannels,
        cta: `publish-modal-${cta}-1`,
      },
      {
        prefillFromLocalStorage: true,
      },
    )
  } else {
    const redirectUrl = getPublishUrl(newPostRoute)
    window.location.assign(redirectUrl)
  }
}

function handleConfigurePostingPlanCTA({
  handleOnDismissModal,
  profileId,
}: {
  handleOnDismissModal: ({
    shouldUseTracking,
  }: {
    shouldUseTracking: boolean
  }) => void
  profileId: string
}): void {
  handleOnDismissModal({ shouldUseTracking: false })

  /* TODO: use openModal method from the useModalManager hook passed as param */
  MODAL_ACTIONS.openModal('postingPlanConfiguration', {
    channelId: profileId,
  })
}

export const NewChannelConnectionOnboardingContent = (): JSX.Element => {
  const user = useUser()
  const { modalData, dismissModal: handleOnDismissModal } = useModalManager()

  const { unlockedChannels = [] } = useChannels()

  const { reward } = useReward('confetti-container', 'confetti', {
    spread: 110,
    elementCount: 180,
    startVelocity: 50,
    lifetime: 1000,
    colors: ['#5E68C2', '#FF3363', '#FFBB00', '#2AFF28', '#2AB6FF', '#EF0583'],
  })

  const postingSchedualButtonText = 'Create Your Posting Plan'

  const postingScheduleAction = ({
    profileId,
  }: {
    profileId: string
  }): void => {
    const ctaButton = postingSchedualButtonText.replace(/\s/g, '')
    trackCTAClicked({
      user,
      payload: {
        ctaView: 'connectChannelOnboarding',
        ctaLocation: 'celebrationModal',
        ctaButton,
        ctaVersion: '1',
        upgradePathName: modalData?.upgradePathName || '',
      },
    })
    handleConfigurePostingPlanCTA({
      handleOnDismissModal,
      profileId,
    })
  }

  useEffect(() => {
    reward()
    cleanupUrlParams()
  }, [])

  const DEFAULT_AVATAR = 'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

  const closeModal = (): void => {
    handleOnDismissModal()
  }

  return (
    <SuccessDialog open={true} onOpenChange={closeModal}>
      <SuccessDialog.Content className={styles.content}>
        <div className={styles.logoAvatarWrapper}>
          <Card className={styles.logoWrapper}>
            <BufferIcon size="large" color="brand" />
          </Card>
          <div className={styles.avatarChannelIconWrapper}>
            <Avatar
              src={unlockedChannels[0].avatar || DEFAULT_AVATAR}
              alt={unlockedChannels[0].name}
              size="large"
            />
            <div className={styles.channelIconWrapper}>
              <ChannelIcon
                color="inverted"
                size="medium"
                type={unlockedChannels[0].service as Service}
              />
            </div>
          </div>
        </div>
        <Heading size="large">
          {getServiceName(unlockedChannels[0].service as Service)} Connected 🎉
        </Heading>
        <SuccessDialog.Description className={styles.description}>
          <Text>
            You&apos;ve connected{' '}
            {getServiceName(unlockedChannels[0].service as Service)}, get
            started by creating a post.
          </Text>
        </SuccessDialog.Description>
        <SuccessDialog.Actions>
          <Button
            className={styles.styledButton}
            variant="primary"
            size="large"
            onClick={(): void =>
              handleCreatePostCTA({
                socialChannelId: unlockedChannels[0].id,
                handleOnDismissModal,
              })
            }
          >
            Create a Post
          </Button>
          {unlockedChannels.length === 1 && (
            <Button
              className={styles.styledButton}
              variant="tertiary"
              size="large"
              onClick={(): void =>
                postingScheduleAction({
                  profileId: unlockedChannels[0].id,
                })
              }
            >
              {postingSchedualButtonText}
            </Button>
          )}
          {unlockedChannels.length > 1 && (
            <DropdownMenu
              className={styles.styledDropdownMenu}
              trigger={
                <Button
                  className={styles.styledButton}
                  variant="secondary"
                  size="large"
                >
                  {postingSchedualButtonText} for <ChevronDownIcon />
                </Button>
              }
            >
              {unlockedChannels.map((channel) => (
                <DropdownMenu.Item
                  className={styles.styledDropdownMenuItem}
                  key={channel.id}
                  onClick={(): void =>
                    postingScheduleAction({
                      profileId: channel.id,
                    })
                  }
                >
                  <Flex
                    key={channel.id}
                    className={styles.channelCard}
                    gap="md"
                    align="center"
                  >
                    <ChannelAvatar
                      alt={channel.name}
                      channel={channel.service as Service}
                      src={channel.avatar}
                    />
                    <Flex direction="column" className={styles.channelInfo}>
                      <Text>{channel.name}</Text>
                    </Flex>
                  </Flex>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu>
          )}
        </SuccessDialog.Actions>
        <div id="confetti-container" className={styles.confettiContainer} />
      </SuccessDialog.Content>
    </SuccessDialog>
  )
}
