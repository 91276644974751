import { useQuery } from '@apollo/client'
import { graphql } from '~publish/graphql'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useCommentsConfig } from '../CommentsChannelConfigContext'
import type { CommentsChannelNav } from '~publish/components/CommentsSidebar/useCommentsChannels'
import type { CommentStatus } from '~publish/gql/graphql'

export const CommentsCountsQuery = graphql(`
  query CommentsCountsQuery($input: CommentsCountsInput!) {
    commentsCounts(input: $input) {
      counts {
        channelId
        count
      }
    }
  }
`)

/**
 * Hook to fetch the number of comments for an array of channels
 * @param channels - The channels to fetch comments count for
 * @param filters - Optional filters for the comments count
 * @returns The number of comments for the channels, or null if loading/error/unsupported
 */
export function useCommentsCounts(
  channels: CommentsChannelNav[],
  filters?: {
    status: CommentStatus[]
    publishedAt?: {
      start?: string
      end?: string
    }
  },
): { channelId: string | null; count: number }[] | null {
  const organizationId = useOrganizationId()
  const {
    config: { isSupportedInComments },
  } = useCommentsConfig()
  const supportedChannels = channels.filter(isSupportedInComments)

  const { data, loading } = useQuery(CommentsCountsQuery, {
    variables: {
      input: {
        organizationId: organizationId || '',
        filters: {
          channelIds: supportedChannels.map((channel) => channel.id),
          status: filters?.status,
          publishedAt: filters?.publishedAt,
        },
      },
    },
    skip: !organizationId || supportedChannels.length === 0,
  })

  if (!organizationId || loading || !data) return null

  return data.commentsCounts.counts.map((count) => ({
    channelId: count.channelId ?? null,
    count: count.count,
  }))
}
