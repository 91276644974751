import { graphql } from '~publish/graphql'
import { toast } from '@buffer-mono/popcorn'
import { useQuery, NetworkStatus, type ApolloError } from '@apollo/client'
import type {
  GetFeedItemsQuery,
  GetFeedItemsQueryVariables,
} from '~publish/gql/graphql'
import { useInfiniteScrollPagination } from '~publish/hooks/useInfiniteScrollPagination'
import { useMemo } from 'react'

export type FeedItem = NonNullable<
  GetFeedItemsQuery['feedItems']['edges']
>[number]['node']

export const GetFeedItems = graphql(/* GraphQL */ `
  query GetFeedItems(
    $feedCollectionIds: [FeedCollectionId!]
    $feedIds: [FeedId!]
    $organizationId: OrganizationId!
    $after: String
  ) {
    feedItems(
      first: 20
      after: $after
      input: {
        organizationId: $organizationId
        filter: { feedCollectionIds: $feedCollectionIds, feedIds: $feedIds }
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          title
          contentTextPreview
          articleUrl
          feed {
            id
            type
            name
            connectionMetadata {
              ... on RSSFeedConnectionMetadata {
                __typename
                url
              }
            }
          }
        }
      }
    }
  }
`)

type UseFeedItemsInput = {
  organizationId?: string
  feedCollectionId?: string
  feedIds?: string[]
}

type UseFeedCollectionItemResult = {
  loading: boolean
  error?: ApolloError
  items: FeedItem[] | null
  fetchingMore: boolean
  refetch: () => void
  lastElementRef: (node: HTMLElement | null) => void
}

export const useFeedItems = (
  input: UseFeedItemsInput,
): UseFeedCollectionItemResult => {
  const { organizationId, feedCollectionId, feedIds } = input

  const { data, error, fetchMore, loading, networkStatus, refetch } = useQuery<
    GetFeedItemsQuery,
    GetFeedItemsQueryVariables
  >(GetFeedItems, {
    variables: {
      feedCollectionId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - organizationId is not always defined we skip if it is not defined
      organizationId,
      feedCollectionIds: feedCollectionId ? [feedCollectionId] : undefined,
      feedIds,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !organizationId,
  })

  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  const items = useMemo(() => {
    return data?.feedItems?.edges?.map((edge) => edge.node) ?? null
  }, [data?.feedItems?.edges])

  const [lastElementRef] = useInfiniteScrollPagination({
    loading: fetchingMore,
    hasNextPage: data?.feedItems.pageInfo.hasNextPage,
    fetchMore: async () => {
      try {
        await fetchMore({
          variables: {
            after: data?.feedItems.pageInfo.endCursor,
          },
        })
      } catch (error) {
        toast.error('Error loading more feed items')
        throw new Error(
          'Failed to fetch more feed items:' + (error as Error).message,
        )
      }
    },
  })

  return {
    loading,
    lastElementRef,
    fetchingMore,
    error,
    items,
    refetch,
  }
}
