import { useMemo } from 'react'
import { NetworkStatus, useQuery } from '@apollo/client'

import { graphql } from '~publish/gql'
import { useInfiniteScrollPagination } from '~publish/hooks/useInfiniteScrollPagination'
import type { PostStatus, PostSortInput } from '~publish/gql/graphql'
import { useRealTimePusherUpdates } from './useRealTimePusherUpdates'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { debugLog } from '~publish/helpers/debugLog'
import { toast } from '@buffer-mono/popcorn'

export const POSTS_PER_PAGE = 20
export const MAX_POSTS_REFETCH_LIMIT = 300

export const GetPostList = graphql(/* GraphQL */ `
  query GetPostList(
    $first: Int!
    $after: String
    $organizationId: OrganizationId!
    $channelIds: [ChannelId!]
    $tagIds: [TagId!]
    $status: [PostStatus!]
    $sort: [PostSortInput!]
  ) {
    posts(
      input: {
        organizationId: $organizationId
        filter: { channelIds: $channelIds, tagIds: $tagIds, status: $status }
        sort: $sort
      }
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          dueAt
          sentAt
          ...PostCard_Post
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`)

export type SortPreset = 'upcomingFirst' | 'mostRecentlyPostedFirst'

export type UsePaginatedPostListOptions = {
  status: PostStatus[]
  channelIds?: string[]
  tagIds?: string[]
  sortPreset?: SortPreset
  perPage?: number
}

const mapSortPresetToSortOrder = (sortPreset: SortPreset): PostSortInput[] => {
  switch (sortPreset) {
    case 'upcomingFirst':
      return [
        { field: 'dueAt', direction: 'asc' },
        { field: 'createdAt', direction: 'desc' },
      ]
    case 'mostRecentlyPostedFirst':
      return [
        { field: 'dueAt', direction: 'desc' },
        { field: 'createdAt', direction: 'desc' },
      ]
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const usePaginatedPostList = ({
  status,
  channelIds,
  tagIds,
  sortPreset = 'upcomingFirst',
  perPage = POSTS_PER_PAGE,
}: UsePaginatedPostListOptions) => {
  const organizationId = useOrganizationId()
  const { data, refetch, fetchMore, error, networkStatus, variables } =
    useQuery(GetPostList, {
      variables: {
        first: perPage,
        after: null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error - organizationId is not always defined we skip if it is not defined
        organizationId,
        status,
        channelIds,
        tagIds,
        sort: mapSortPresetToSortOrder(sortPreset),
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
      skip: !organizationId,
    })

  const loading = networkStatus === NetworkStatus.loading
  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  if (networkStatus === NetworkStatus.refetch) {
    debugLog('🔄 refetching posts...')
  }

  useRealTimePusherUpdates({
    filters: {
      status,
      tagIds,
      channelIds,
    },
    variables,
    refetch: async (options): Promise<void> => {
      debugLog('⚡ posts refetch triggered', options?.mode)

      await refetch({
        first:
          // HACK: fetch one more post than current in case in insert mode
          // this works up to a limit, but it helps resolving issue of new posts not showing up
          data?.posts?.edges?.length
            ? // HACK: we currently have very high limits on the number of posts we can fetch
              // so we need to limit the number of posts we fetch to avoid overwhelming the server
              Math.min(
                data.posts.edges.length + (options?.mode === 'insert' ? 1 : 0),
                MAX_POSTS_REFETCH_LIMIT,
              )
            : POSTS_PER_PAGE,
      })
    },
    isPostVisible: (id: string): boolean => {
      return data?.posts?.edges
        ? data.posts.edges.some((edge) => {
            return edge.node.id === id
          })
        : false
    },
    sort: sortPreset,
  })

  const [lastElementRef] = useInfiniteScrollPagination({
    loading: fetchingMore,
    hasNextPage: data?.posts.pageInfo.hasNextPage,
    fetchMore: async () => {
      try {
        await fetchMore({
          variables: {
            after: data?.posts.pageInfo.endCursor,
          },
        })
      } catch (error) {
        toast.error('Error fetching more posts')
        throw new Error(
          'Failed to fetch more posts:' + (error as Error).message,
        )
      }
    },
  })

  return useMemo(
    () => ({
      data,
      loading,
      fetchingMore,
      error,
      lastElementRef,
      refetch,
    }),
    [data, loading, fetchingMore, error, lastElementRef, refetch],
  )
}
