/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import type { SelectedTag } from '~publish/legacy/campaign/types'
import { BufferLoading } from '~publish/legacy/shared-components'
import type { RootState } from '~publish/legacy/store'
import { getEnvironment } from '~publish/legacy/store/selectors'

const Composer = lazy(
  () =>
    import(
      /* webpackChunkName: "composer" */ '~publish/legacy/composer/interfaces/buffer-publish'
    ),
)

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ConnectedComposer = (props) => (
  <Suspense fallback={<BufferLoading size={64} dark />}>
    <Composer {...props} />
  </Suspense>
)

export default connect(
  (state: RootState, ownProps) => {
    if (state.user && state.profileSidebar) {
      const {
        // @ts-expect-error TS(2339) FIXME: Property 'onCancelDismissModal' does not exist on ... Remove this comment to see the full error message
        onCancelDismissModal,
        // @ts-expect-error TS(2339) FIXME: Property 'showDismissComposerModal' does not exist... Remove this comment to see the full error message
        showDismissComposerModal,
        // @ts-expect-error TS(2339) FIXME: Property 'onConfirmDismissComposer' does not exist... Remove this comment to see the full error message
        onConfirmDismissComposer,
      } = ownProps

      let { selectedProfileId } = state.profileSidebar // Provided when in the context of a single profile (e.g. queue)

      let options = {}

      selectedProfileId = state.composerPopover.selectedProfileId ?? ''
      const selectedProfileIds = state.composerPopover.selectedProfileIds
      const {
        post,
        editMode,
        emptySlotMode,
        hasDefaultTime,
        shouldResetComposerData,
        shareAgainMode,
        duplicatedFrom,
        sentPost,
        draftMode,
      } = state.composerPopover
      const { profiles } = state.profileSidebar

      const getTagById = (): SelectedTag | undefined => {
        if (
          state.composerPopover.preSelectedTags &&
          state.composerPopover.preSelectedTags.length > 0
        ) {
          const campaignId = state.campaign.campaign?.id
          const tagEntities = state.tags.entities

          if (campaignId && tagEntities) {
            const tag = tagEntities[campaignId]
            if (tag) {
              return {
                id: tag.id,
                color: tag.color,
                name: tag.name,
              }
            }
          }
        }
      }

      options = {
        editMode,
        draftMode,
        emptySlotMode,
        hasDefaultTime,
        shouldResetComposerData,
        shareAgainMode: shareAgainMode || false,
        duplicatedFrom: duplicatedFrom || null,
        post,
        previewMode: !profiles || !profiles.length,
        sentPost,
        initialCampaignId: state.campaign.campaign?.id || null,
        initialTag: getTagById(),
      }

      const profilesToBeSelected =
        selectedProfileIds && !!selectedProfileIds.length
          ? selectedProfileIds
          : [selectedProfileId]

      return {
        userData: state.user,
        profiles: state.profileSidebar.profiles.map((profile) => ({
          ...profile,
          shouldBeAutoSelected: profilesToBeSelected.includes(profile.id),
        })),
        organizations: state.organizations,
        enabledApplicationModes: state.temporaryBanner.enabledApplicationModes,
        environment: getEnvironment(state),
        profilesToBeSelected,
        tabId: state.profileNav.selectedTabId,
        campaigns: state.campaignsList.campaigns ?? [],
        showDismissComposerModal,
        onCancelDismissModal,
        onConfirmDismissComposer,
        translations: state.i18n.translations,
        ...options,
      }
    }
    return {}
  },
  (dispatch, ownProps) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
    onEvent: (type, data) => {
      if (type === 'backdrop-clicked') {
        // @ts-expect-error TS(2339) FIXME: Property 'onComposerOverlayClick' does not exist o... Remove this comment to see the full error message
        ownProps.onComposerOverlayClick()
      }
      dispatch({ type: 'COMPOSER_EVENT', eventType: type, data })
    },
  }),
)(ConnectedComposer)
