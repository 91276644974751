import ValidationFail from '~publish/legacy/validation/ValidationFail'
import type { AspectRatioRange } from '~publish/legacy/media/aspect-ratio/types'
import {
  getAspectRatio,
  getAspectRatioString,
  isRatioWithinRange,
} from '../../aspect-ratio/aspect-ratio'
import { type AspectRatioRangeRule, ValidationRuleName } from '../types'
import { createRuleHandler } from './createRuleHandler'
import { getRuleMessages } from './getRuleMessages'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'

export const aspectRatioRange = createRuleHandler<AspectRatioRangeRule>({
  ruleName: ValidationRuleName.AspectRatioRange,
  messages: {
    specific:
      'This image does not meet the expected aspect ratios ({min} to {max})',
    generic:
      "One or more images aren't matching the expected aspect ratios ({min} to {max})",
  },
  validate: (rule, media) => {
    const ratio = getAspectRatio({
      width: Number(media.width),
      height: Number(media.height),
    })

    const expected: AspectRatioRange = {
      minRatio: getAspectRatio(rule.ruleConfig.min),
      maxRatio: getAspectRatio(rule.ruleConfig.max),
    }

    if (!isRatioWithinRange({ ratio, range: expected })) {
      const messages = getRuleMessages(rule, { toString: getAspectRatioString })

      return new ValidationFail(
        messages.generic,
        VALIDATION_CODE.ASPECT_RATIO,
        messages,
        {},
      )
    }

    return null
  },
})
