import React from 'react'

import {
  ArrowDownUpIcon,
  BufferIcon,
  ChannelIcon,
  Dialog,
  Flex,
  Heading,
} from '@buffer-mono/popcorn'
import { constructTitleWithGrammer } from '../ChannelConnectionOnboarding/utils'
import ChannelsList from './components/ChannelsList'
import { ConnectChannelUpgradeActions } from './components/ConnectChannelUpgradeActions'
import { ConnectChannelUpgradeMessage } from './components/ConnectChannelUpgradeMessage'
import useChannels from './../../hooks/useChannels'

import type { Service } from '../../../../exports/Orchestrator/channelConnections/types'

import styles from './ConnectChannelUpgrade.module.css'
import { useUser } from '../../../../common/context/User'
import { isOnNonAgencyTieredPricing } from '../../../../common/utils/billing'

const ConnectChannelUpgrade = ({
  onOpenChange,
}: {
  onOpenChange: () => void
}): JSX.Element => {
  const { channelIds, unlockedChannels, lockedChannels, lockedChannelIds } =
    useChannels()
  const totalConnectedChannels = unlockedChannels.concat(lockedChannels)
  const title = constructTitleWithGrammer(channelIds.length)

  const account = useUser()
  const isOnTieredPricing = isOnNonAgencyTieredPricing(
    account?.currentOrganization?.billing,
  )

  const lastConnectedChannelService = totalConnectedChannels[
    totalConnectedChannels?.length - 1
  ]?.service as Service

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content className={styles.content}>
        <Flex
          direction="column"
          gap="xl"
          align="center"
          fullWidth
          fullHeight
          className={styles.contentWrapper}
          data-testid={`channel-connection-update-modal-body`}
        >
          <Flex justify="center" gap="sm" fullWidth>
            <BufferIcon />
            {lastConnectedChannelService && (
              <>
                <ArrowDownUpIcon color="subtle" className={styles.arrows} />
                <ChannelIcon
                  type={lastConnectedChannelService}
                  color="branded"
                />
              </>
            )}
          </Flex>
          <Dialog.CloseButton />
          <Dialog.Body>
            <Flex
              direction="column"
              align="center"
              justify="center"
              gap="lg"
              fullWidth
              fullHeight
            >
              <Heading size="large">{title}</Heading>
              <ConnectChannelUpgradeMessage
                totalChannelsCount={totalConnectedChannels.length}
                lockedChannelIds={lockedChannelIds}
                isOnTieredPricing={isOnTieredPricing}
              />
              <ChannelsList channels={totalConnectedChannels} />
            </Flex>
          </Dialog.Body>
        </Flex>
        <Flex fullWidth>
          <Dialog.Footer className={styles.footer}>
            <ConnectChannelUpgradeActions />
          </Dialog.Footer>
        </Flex>
      </Dialog.Content>
    </Dialog>
  )
}

export default ConnectChannelUpgrade
