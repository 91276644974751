import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ErrorBanner } from '~publish/legacy/shared-components'
import { Text } from '@bufferapp/ui'
import { fontWeightBold } from '@bufferapp/ui/style/fonts'
import type { Service } from '~publish/legacy/constants/services/ServiceDefinitions'

const TextWithStyles = styled(Text)`
  margin: 0;
`

const ExtraMessageWithStyles = styled(Text)`
  margin: 0;
  font-weight: ${fontWeightBold};
`

interface ProfilesDisconnectedBannerProps {
  canReconnectChannels?: boolean
  displayExtraMessage?: boolean
  ownerEmail?: string
  service: Service
}
const ProfilesDisconnectedBanner = ({
  displayExtraMessage = false,
  canReconnectChannels = true,
  ownerEmail = 'the owner',
  service,
}: ProfilesDisconnectedBannerProps): JSX.Element => {
  const { t } = useTranslation()

  const onReconnectClick = (): void => {
    if (window.appshell?.actions) {
      const { actions } = window.appshell || {}
      actions.connectChannel({
        selectedService: service as unknown as Parameters<
          typeof actions.connectChannel
        >[0]['selectedService'],
        isRefreshingConnection: true,
        cta: `publish-queue-channelRefreshBanner-${service}-1`,
      })
    }
  }
  return (
    <ErrorBanner
      title={t('profiles-disconnected-banner.headline')}
      onClick={onReconnectClick}
      actionLabel={
        canReconnectChannels ? t('profiles-disconnected-banner.cta') : undefined
      }
    >
      {displayExtraMessage && (
        <ExtraMessageWithStyles type="p">
          {t('profiles-disconnected-banner.extraMessage.instagram')}
        </ExtraMessageWithStyles>
      )}
      <TextWithStyles type="p">
        {canReconnectChannels
          ? t('profiles-disconnected-banner.body')
          : t('profiles-disconnected-banner.permissionBody', {
              email: ownerEmail,
            })}
      </TextWithStyles>
    </ErrorBanner>
  )
}

export default ProfilesDisconnectedBanner
