import React from 'react'
import { Button, Dialog } from '@buffer-mono/popcorn'

const ConfirmRevokeAccessModal = ({
  onRevokeAccess,
  close,
  isOpen,
  appName,
}: {
  onRevokeAccess: () => void
  close: () => void
  isOpen: boolean
  appName: string
}): JSX.Element => {
  function handleSubmit(): void {
    onRevokeAccess()
    close()
  }

  return (
    <Dialog modal open={isOpen}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>Are you sure?</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Dialog.Description>
            You are about to revoke access to <strong>{appName}</strong>. This
            will prevent the app from working with your Buffer account. Are you
            sure you want to continue?
          </Dialog.Description>
        </Dialog.Body>
        <Dialog.Footer>
          <Button variant="tertiary" size="large" onClick={close}>
            Cancel
          </Button>

          <Button variant="primary" size="large" onClick={handleSubmit}>
            Yes, Revoke Access
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

export default ConfirmRevokeAccessModal
