import { type Idea, type Media, MediaType } from '~publish/pages/Create/types'
import type { DraftIdea } from './IdeaComposer'
import {
  type AnyObject,
  createComboboxPlugin,
  createPlateEditor,
  type CreatePlateEditorOptions,
  createPlugins,
} from '@udecode/plate'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import { createEmojiSearchPlugin } from '~publish/legacy/editor/plugins/emoji'
import { createLinkPlugin } from '~publish/legacy/editor/plugins/link'
import { SimpleLinkElementUI } from '~publish/legacy/editor/plugins/link/ui/SimpleLinkElementUI'
import { createGenerateContentPlugin } from '~publish/legacy/editor/plugins/generate-content'
import { createContentGenerationPlugin } from '~publish/legacy/ai/plugins/content-generation'
import { IDEA_EDITOR_ID } from './constants'

export const createIdeaEditor = (
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: IDEA_EDITOR_ID,
    plugins: createPlugins<BufferValue, BufferEditor>([
      createComboboxPlugin<AnyObject, BufferValue>(),
      createEmojiSearchPlugin(),
      createLinkPlugin({
        component: SimpleLinkElementUI,
      }),
      createGenerateContentPlugin(),
      createContentGenerationPlugin(),
    ]),
    ...options,
  })
}

export type PostMediaValidity = {
  valid: boolean
  message: string | null
}

export const hasMultipleVideos = (media?: Media[]): boolean => {
  let videoCount = 0
  media?.forEach((media) => {
    if (media.type === MediaType.video) {
      videoCount += 1
    }
  })
  return videoCount > 1
}

export const hasMixedMediaTypes = (media?: Media[]): boolean => {
  let hasMixedMedia = false
  let lastMediaType: MediaType | null = null
  media?.forEach((media) => {
    if (lastMediaType && media.type !== lastMediaType) {
      hasMixedMedia = true
      return
    }
    lastMediaType = media.type
  })
  return hasMixedMedia
}

export const isMediaConvertibleToPost = (
  media?: Media[],
): PostMediaValidity => {
  if (hasMultipleVideos(media)) {
    return {
      valid: false,
      message:
        'Multiple videos aren’t supported on posts at this time. Remove video(s) to create a post',
    }
  }

  if (hasMixedMediaTypes(media)) {
    return {
      valid: false,
      message:
        'Multiple media types aren’t supported on posts at this time. Remove a media type to create a post',
    }
  }
  return {
    valid: true,
    message: null,
  }
}

export function isExistingIdea(idea: DraftIdea | Idea): idea is Idea {
  return 'id' in idea
}
