import React from 'react'
import { isCommentsPage } from '~publish/legacy/routes'
import { PusherEvent, usePusherEvent } from '~publish/services/pusher'

type NewCommentReceivedHookResponse = {
  showNewCommentsButton: boolean
  hideNewCommentsButton: () => void
}

export const useNewCommentsAvailableButton =
  (): NewCommentReceivedHookResponse => {
    const [showNewCommentsButton, setShowNewCommentsButton] =
      React.useState(false)

    const hideNewCommentsButton = React.useCallback(() => {
      setShowNewCommentsButton(false)
    }, [])

    /**
     * Subscription to COMMENT_INGESTED event
     */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePusherEvent(PusherEvent.COMMENT_INGESTED, (): void => {
      // if we are in Community, show the button to reload comments
      if (isCommentsPage()) {
        setShowNewCommentsButton(true)
      } else {
        setShowNewCommentsButton(false)
      }
    })

    return { showNewCommentsButton, hideNewCommentsButton }
  }
