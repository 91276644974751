// These come from how Vite is running
const IS_WITH_PROD = import.meta.env.MODE === 'dev-with-prod'
const IS_WITH_STAGING = import.meta.env.MODE === 'dev-with-staging'
const IS_DEV = import.meta.env.MODE === 'development'

export type Env = {
  NODE_ENV: string
  API_GATEWAY_URL: string
  SEGMENT_KEY: string
  VITE_COMMIT_HASH: string
  HELP_SCOUT_BEACON_ID: string
  VITE_SPLIT_ENV: string
  VITE_FEATUREOS_TOKEN: string
  BRAINFISH_WIDGET_KEY: string
}
type EnvName = 'production' | 'staging' | 'development'

const common = {
  VITE_COMMIT_HASH: import.meta.env.VITE_COMMIT_HASH,
  VITE_FEATUREOS_TOKEN: 'lu0LF_sZxVeiu752eqzT5Q',
}

const environments: Record<EnvName, Env> = {
  production: {
    NODE_ENV: 'production',
    API_GATEWAY_URL: 'https://graph.buffer.com',
    SEGMENT_KEY: 'ue6pVIpurOYbMqQTDTBYulBdB57t8kAA',
    HELP_SCOUT_BEACON_ID: '30561188-9d9f-4262-a05a-49c34d1baf22',
    BRAINFISH_WIDGET_KEY:
      'bf_search_widget_bv15qEgl0vkZKmIRySQAAE7Yb7xsVSsaF1qPbd',
    VITE_SPLIT_ENV: 'production',
    ...common,
  },
  staging: {
    NODE_ENV: 'staging',
    API_GATEWAY_URL: 'https://graph.dev.buffer.com',
    SEGMENT_KEY: 'ue6pVIpurOYbMqQTDTBYulBdB57t8kAA',
    HELP_SCOUT_BEACON_ID: '93a38e6b-040d-45da-90e5-1e49de84dc20',
    BRAINFISH_WIDGET_KEY: 'bf_agent_aePm8mKc93vJDgWXCNVSvxnAKp3hl5DpXBcZjc',
    VITE_SPLIT_ENV: 'staging',
    ...common,
  },
  development: {
    NODE_ENV: 'development',
    API_GATEWAY_URL: 'https://graph.local.buffer.com',
    SEGMENT_KEY: 'ue6pVIpurOYbMqQTDTBYulBdB57t8kAA',
    HELP_SCOUT_BEACON_ID: 'e1f1f90c-dbb1-4c0d-b9ae-b5c6739cffa8',
    BRAINFISH_WIDGET_KEY: 'bf_agent_aePm8mKc93vJDgWXCNVSvxnAKp3hl5DpXBcZjc',
    VITE_SPLIT_ENV: 'local',
    ...common,
  },
}

function getEnvFromHostname(): EnvName {
  if (window.location.hostname.includes('.dev')) {
    return 'staging'
  }
  if (window.location.hostname.includes('.local')) {
    return 'development'
  }
  return 'production'
}

function selectEnv(): Env {
  const envName = getEnvFromHostname()
  let env = environments[envName]

  if (IS_WITH_PROD) {
    env = {
      ...environments.production,
      NODE_ENV: 'development',
    }
  }
  if (IS_WITH_STAGING) {
    return {
      ...environments.staging,
      NODE_ENV: 'development',
    }
  }
  if (IS_DEV) {
    return environments.development
  }

  return env
}

const env = selectEnv()

/**
 * Attach to window object so we can conditionally load various scripts
 * or change keys in the `index.html` file.
 */
if (window) {
  window.env = env
}

export { env }
