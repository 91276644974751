import type { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions'
import { graphql } from '~publish/gql'
import { isOfType } from '~publish/helpers/typeGuards'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'

const DeleteFeedCollection = graphql(/* GraphQL */ `
  mutation DeleteFeedCollection($input: DeleteFeedCollectionInput!) {
    deleteFeedCollection(input: $input) {
      __typename
      ... on FeedCollectionDeleteSuccess {
        feedCollectionId
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useDeleteFeedCollection = () =>
  useTypedMutation(DeleteFeedCollection, (data) => data.deleteFeedCollection, {
    successTypename: 'FeedCollectionDeleteSuccess',
    optimisticResponse: (
      variables,
      { IGNORE },
    ): MutationBaseOptions['optimisticResponse'] => {
      const { feedCollectionId } = variables.input
      if (!feedCollectionId) {
        return IGNORE
      }

      return {
        __typename: 'Mutation',
        deleteFeedCollection: {
          __typename: 'FeedCollectionDeleteSuccess',
          feedCollectionId,
        },
      }
    },
    update(cache, { data }) {
      const result = data?.deleteFeedCollection
      if (isOfType(result, 'FeedCollectionDeleteSuccess')) {
        const normalizedId = cache.identify({
          id: result.feedCollectionId,
          __typename: 'FeedCollection',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    },
  })
