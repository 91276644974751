import React from 'react'
import { Skeleton, Flex } from '@buffer-mono/popcorn'
import styles from './SidebarSkeletons.module.css'

const skeletonCount = ['1', '2', '3']

export const SidebarSkeletons = (): JSX.Element => {
  return (
    <Flex direction="column" gap="2xs">
      {skeletonCount.map((item) => (
        <Skeleton key={item} className={styles.itemSkeleton} />
      ))}
    </Flex>
  )
}
