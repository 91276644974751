import {
  DropdownMenu,
  LockIcon,
  UpgradeBadge,
  UploadIcon,
  useDropdownMenu,
} from '@buffer-mono/popcorn'
import React from 'react'
import { FreeFeedsLimitReachedDialog } from '../FreeFeedsLimitReachedDialog/FreeFeedsLimitReachedDialog'
import {
  ImportFeedsDialog,
  type ImportFeedsDialogProps,
} from './ImportFeedsDialog'
import styles from './ImportFeedsDialog.module.css'
import { HardLimitReachedDialog } from '../HardLimitReachedDialog/HardLimitReachedDialog'

export type ImportFeedsDialogDropdownMenuItemProps = Omit<
  ImportFeedsDialogProps,
  'onUpgrade' | 'children'
> & {
  limitReached: boolean
  tier: 'free' | 'paid'
  onUpgrade: (args: { cta: CTA; upgradePathName: string }) => void
}
export const ImportFeedsDialogDropdownMenuItem = ({
  onUpgrade,
  ...props
}: ImportFeedsDialogDropdownMenuItemProps): JSX.Element => {
  // Make limitReached static to prevent the import dialog from disappearing when limitReached changes
  const [limitReached] = React.useState(props.limitReached)
  const { close } = useDropdownMenu()

  const closeDropdownOnDialogClose = (open: boolean): void => {
    if (!open) {
      close()
    }
  }

  const handleUpgradeClick = (): void => {
    onUpgrade({
      cta: 'create-feeds-upgradeDialog-upgradeButton-1',
      upgradePathName: 'feeds-importOpml-upgrade',
    })
    // UpgradeDialog overlay blocks plan selector so we need to close it once upgrade button is clicked
    close()
  }

  return (
    <HardLimitReachedDialog
      key="importFeedsDialog-hardLimitReached"
      enabled={limitReached && props.tier === 'paid'}
      type="feeds"
    >
      <FreeFeedsLimitReachedDialog
        key="importFeedsDialog-upgradeDialog"
        enabled={limitReached && props.tier === 'free'}
        onUpgrade={handleUpgradeClick}
      >
        <ImportFeedsDialog
          enabled={!limitReached}
          onOpenChange={closeDropdownOnDialogClose}
          {...props}
        >
          <DropdownMenu.Item
            onSelect={(e): void => {
              e.preventDefault()
            }}
          >
            <UploadIcon /> Import from OPML
            {props.limitReached && props.tier === 'paid' ? (
              <LockIcon className={styles.suffixIcon} />
            ) : props.limitReached && props.tier === 'free' ? (
              <UpgradeBadge size="xsmall" className={styles.suffixIcon} />
            ) : null}
          </DropdownMenu.Item>
        </ImportFeedsDialog>
      </FreeFeedsLimitReachedDialog>
    </HardLimitReachedDialog>
  )
}
