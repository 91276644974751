/* eslint-disable no-use-before-define */
import partition from 'lodash/partition'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import Request from '@bufferapp/buffer-js-request'
import { AppEnvironments } from '~publish/legacy/constants'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'
import transformChannelData from './ChannelDataTransformer'
import AppActionCreators from '../action-creators/AppActionCreators'
import { AttachmentTypes, QueueingTypes } from '../AppConstants'
import AppStore from '../stores/AppStore'
import API from './API'
import { ensureUrlProtocol } from './StringUtils'
import { getCurrentPage } from './TrackingUtils'

import { EditorStateProxy } from '../entities/EditorStateProxy'
import { DraftMethods } from '../entities/Draft'
import { Document } from '../entities/Document'

class APIRequestError extends Error {
  name = 'APIRequestError'
}

const WebAPIUtils = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'modalKey' implicitly has an 'any' type.
  rememberModalView: (modalKey) => {
    const reqSettings = { credentials: 'same-origin' }
    Request.get(`/message/read/${modalKey}`, {}, reqSettings)
  },
  /**
   * Save the drafts to the API.
   *
   * data = {
   *   queueingType: QueueingTypes.X,
   *   customScheduleTime: timestamp || null,
   *   profiles: [profile, …], // All selected profiles
   *   drafts: [draft, …], // All enabled drafts
   * }
   */
  // @ts-expect-error TS(7031) FIXME: Binding element 'queueingType' implicitly has an '... Remove this comment to see the full error message
  saveDrafts: ({ queueingType, customScheduleTime, profiles, drafts }) => {
    const { updateId } = AppStore.getMetaData()
    const { partiallySavedDraftsProfilesIds } = AppStore.getAppState()

    // Transform each draft's data to match the API's format

    // @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
    const draftsDataToSave = drafts.map((draft) => ({
      serviceName: draft.service.name,
      formattedData: getFormattedAPIData(draft.service.name, {
        queueingType,
        customScheduleTime: customScheduleTime || draft.scheduledAt,
        // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
        serviceProfiles: profiles.filter((profile) => {
          const wasUpdateSavedAlreadyForProfile = (
            partiallySavedDraftsProfilesIds.get(draft.service.name) || []
          ).includes(profile.id)

          return (
            profile.service.name === draft.service.name &&
            !wasUpdateSavedAlreadyForProfile
          )
        }),
        serviceDraft: draft,
        isEditingUpdate: updateId !== null,
        isSavedUpdate: draft.isSaved === true,
      }),
    }))

    // Further split requests to prevent more than 10 profiles per request
    const maxProfilesIdsPerRequest = 10
    const splitDraftsDataToSave = [].concat(
      // @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
      ...draftsDataToSave.map((draft) =>
        draft.formattedData.profile_ids.length < maxProfilesIdsPerRequest
          ? [draft]
          : draft.formattedData.profile_ids
              // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
              .map((_, i) =>
                i % maxProfilesIdsPerRequest === 0
                  ? draft.formattedData.profile_ids.slice(
                      i,
                      i + maxProfilesIdsPerRequest,
                    )
                  : null,
              )
              // @ts-expect-error TS(7006) FIXME: Parameter 'profilesIdsGroup' implicitly has an 'an... Remove this comment to see the full error message
              .filter((profilesIdsGroup) => profilesIdsGroup !== null)
              // @ts-expect-error TS(7006) FIXME: Parameter 'profilesIdsGroup' implicitly has an 'an... Remove this comment to see the full error message
              .map((profilesIdsGroup) => ({
                ...draft,
                formattedData: {
                  ...draft.formattedData,
                  profile_ids: profilesIdsGroup,
                },
              })),
      ),
    )

    /**
     * Make one query per draft.
     *
     * Transform any unexpected network failure into a resolved object
     * with success=false for consistency with API errors, and in order
     * for Promise.all() to not fail early
     */
    const savePromises = splitDraftsDataToSave.map(
      ({ serviceName, formattedData }) => {
        const updatePost =
          queueingType === QueueingTypes.SAVE ||
          queueingType === QueueingTypes.SAVE_AND_APPROVE
        const endpoint = updatePost
          ? `updates/${updateId}/update.json`
          : 'updates/create.json'

        return (
          // @ts-expect-error TS(2339) FIXME: Property 'post' does not exist on type 'typeof API... Remove this comment to see the full error message
          API.post(endpoint, formattedData)
            .catch(() => ({
              success: false,
              message: `Whoops, Buffer's servers couldn't be reached to save the
            update, sorry about that. Would you be up for trying again?`,
            }))
            // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
            .then((response) => {
              return Object.assign(response, { serviceName })
            })
        )
      },
    )

    return Promise.all(savePromises).then((responses) => {
      /**
       * Since we're splitting requests to never have more than 10 profiles each,
       * we'll sometimes get multiple (un)successful responses for the same draft:
       * - Unsuccessful responses are merged back into one response per draft.
       * - Successful responses are split in two buckets: "fully" successful responses
       *   on one side (i.e. responses for drafts that were saved to all their selected
       *   profiles), and "partially" successful responses (i.e. responses for drafts
       *   that couldn't be saved to all their selected profiles). A draft that was split
       *   in multiple requests will have all of its responses in one of those buckets,
       *   never in both.
       *   - "Fully" successful responses are then merged back into one response per draft
       *   - And we save the "partially" successful responses are used to
       */
      // eslint-disable-next-line prefer-const
      let [unfilteredSuccessfulResponses, unsuccessfulResponses] = partition(
        responses,
        (response) => response.success,
      )

      // eslint-disable-next-line prefer-const
      let [partiallySuccessfulResponses, successfulResponses] = partition(
        unfilteredSuccessfulResponses,
        (successfulResponse) =>
          unsuccessfulResponses.find(
            (unsuccessfulResponse) =>
              unsuccessfulResponse.serviceName ===
              successfulResponse.serviceName,
          ),
      )

      // If a service's responses span both successfulResponses and unsuccessfulResponses,
      // keep track of successful saves for that service in order to skip them during next save
      AppActionCreators.updatePartiallySavedDraftsProfilesIds(
        partiallySuccessfulResponses.map(({ serviceName, updates }) => ({
          draftId: serviceName,
          // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
          profilesIds: updates.map(({ profile_id: profileId }) => profileId), // TODO: issue when mult partials?
        })),
      )

      // Merge "fully" successful responses back together again into one response per service
      successfulResponses = successfulResponses.reduce(
        (mergedSuccessfulResponses, response) => {
          const existingServiceEntry = mergedSuccessfulResponses.find(
            // @ts-expect-error TS(7006) FIXME: Parameter 'mergedResponse' implicitly has an 'any'... Remove this comment to see the full error message
            (mergedResponse) =>
              mergedResponse.serviceName === response.serviceName,
          )

          if (existingServiceEntry) {
            existingServiceEntry.updates.splice(-1, 0, ...response.updates)
          } else {
            mergedSuccessfulResponses.push(response)
          }

          return mergedSuccessfulResponses
        },
        [],
      )

      // Merge unsuccessful responses back together again into one response per service
      unsuccessfulResponses = unsuccessfulResponses.reduce(
        (mergedUnsuccessfulResponses, response) => {
          const hasExistingServiceEntry = !!mergedUnsuccessfulResponses.find(
            // @ts-expect-error TS(7006) FIXME: Parameter 'mergedResponse' implicitly has an 'any'... Remove this comment to see the full error message
            (mergedResponse) =>
              mergedResponse.serviceName === response.serviceName,
          )

          if (!hasExistingServiceEntry) {
            mergedUnsuccessfulResponses.push(response)
          }

          return mergedUnsuccessfulResponses
        },
        [],
      )

      return {
        successfulResponses,
        unsuccessfulResponses: overrideAPIErrorResponseMessages({
          unsuccessfulResponses,
          partiallySuccessfulResponses,
          queueingType,
        }),
      }
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  approveDrafts: (ids) =>
    Promise.all(
      // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
      ids.map((id) =>
        // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'typeof API'... Remove this comment to see the full error message
        API.get(`updates/${id}/approve.json`).catch(() => ({ success: false })),
      ),
    ),

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  createNewSubprofile: (profileId, data) => {
    AppActionCreators.createSubprofilePending(profileId)

    return (
      // @ts-expect-error TS(2339) FIXME: Property 'post' does not exist on type 'typeof API... Remove this comment to see the full error message
      API.post(`profiles/${profileId}/subprofiles/create.json`, data)
        .catch(() => {
          throw new APIRequestError(`Whoops, Buffer's servers couldn't be reached
          to save the board, sorry about that. Would you be up for trying again?`)
        })
        // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
        .then((response) => {
          if (
            !Object.prototype.hasOwnProperty.call(response, 'success') ||
            response.success === false
          ) {
            throw new APIRequestError(`The board couldn't be saved, sorry about that.
            Would you be up for trying again?`)
          } else {
            return {
              profileId: response.subprofile.profile_id,
              id: response.subprofile.id,
              avatar: response.subprofile.avatar,
              name: response.subprofile.name,
              isShared: response.subprofile.is_shared,
            }
          }
        })
    )
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  fetchProfileSubprofiles: (profileId) =>
    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'typeof API'... Remove this comment to see the full error message
    API.get(`profiles/${profileId}/fetch_subprofiles.json`).then(
      // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      (response) => ({
        subprofiles: response.subprofiles.map(
          // @ts-expect-error TS(7031) FIXME: Binding element 'profile_id' implicitly has an 'an... Remove this comment to see the full error message
          // eslint-disable-next-line camelcase
          ({ profile_id, id, avatar, name, is_shared }) => ({
            profileId: profile_id,
            isShared: is_shared,
            id,
            avatar,
            name,
          }),
        ),
      }),
    ),

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  getProfileSlotDataForDateRange: (profileId, startDay, endDay) =>
    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'typeof API'... Remove this comment to see the full error message
    API.get(`profiles/${profileId}/schedules/slots.json`, {
      start_day: startDay,
      end_day: endDay,
    }),

  // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
  getFacebookDomainOwnershipForProfile: (profileId, url) =>
    // @ts-expect-error TS(2339) FIXME: Property 'get' does not exist on type 'typeof API'... Remove this comment to see the full error message
    API.get(`profiles/${profileId}/facebook_domain_ownership.json`, {
      profile_id: profileId,
      url,
    }),
}

function overrideAPIErrorResponseMessages({
  // @ts-expect-error TS(7031) FIXME: Binding element 'unsuccessfulResponses' implicitly... Remove this comment to see the full error message
  unsuccessfulResponses,
  // @ts-expect-error TS(7031) FIXME: Binding element 'partiallySuccessfulResponses' imp... Remove this comment to see the full error message
  partiallySuccessfulResponses,
  // @ts-expect-error TS(7031) FIXME: Binding element 'queueingType' implicitly has an '... Remove this comment to see the full error message
  queueingType,
}) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
  return unsuccessfulResponses.map((response) => {
    const hasPartiallySuccessfulResponses = !!partiallySuccessfulResponses.find(
      // @ts-expect-error TS(7031) FIXME: Binding element 'serviceName' implicitly has an 'a... Remove this comment to see the full error message
      ({ serviceName }) => serviceName === response.serviceName,
    )

    if (hasPartiallySuccessfulResponses) {
      const errorMessageVerbQueueingTypeMap = new Map([
        [QueueingTypes.QUEUE, 'queued'],
        [QueueingTypes.NEXT, 'queued'],
        [QueueingTypes.NOW, 'posted'],
        [QueueingTypes.CUSTOM, 'saved'],
        [QueueingTypes.SAVE, 'saved'],
        [QueueingTypes.SAVE_AND_APPROVE, 'saved and approved'],
        [QueueingTypes.ADD_DRAFT, 'saved'],
        [QueueingTypes.NEXT_DRAFT, 'saved'],
        [QueueingTypes.CUSTOM_DRAFT, 'saved'],
      ])

      response.message = `Some updates were successfully ${errorMessageVerbQueueingTypeMap.get(
        queueingType,
      )},
        but some others weren't, here's what happened if you want to try again for those
        that failed:<br/>${response.message}`
    }

    return response
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'isEditingUpdate' implicitly has an 'any... Remove this comment to see the full error message
export function getFormattedMediaFields(isEditingUpdate, serviceDraft) {
  const hasImageAttachment =
    Array.isArray(serviceDraft.images) && serviceDraft.images.length > 0
  const hasVideoAttachment = !!serviceDraft.video

  // Not null or undefined
  const hasGifAttachment = !!serviceDraft.gif
  const documentAttachmet = DraftMethods.getDocumentAttachment(serviceDraft)
  const hasEnabledMediaAttachment =
    serviceDraft.enabledAttachmentType === AttachmentTypes.MEDIA &&
    (hasImageAttachment ||
      hasVideoAttachment ||
      hasGifAttachment ||
      !!documentAttachmet)
  const hasEnabledLinkAttachment =
    serviceDraft.enabledAttachmentType === AttachmentTypes.LINK &&
    serviceDraft.link !== null

  // Default to non-set values on create, and nulls on update in order to
  // override those fields in the API
  let formattedMediaFields = isEditingUpdate
    ? {
        media: '',
        extra_media: '',
      }
    : {}

  // @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
  const getFormattedImageFields = (image, canHaveUserTags) => {
    const formattedData = {
      progress: 100,
      uploaded: true,
      photo: image.url,
      picture: image.url,
      thumbnail: image.url,
      alt_text: image.altText || null,
      source: image.source,
      height: image.height,
      width: image.width,
    }

    if (canHaveUserTags && image.userTags && image.userTags.length) {
      return {
        ...formattedData,
        service_user_tags: image.userTags,
      }
    } else {
      return formattedData
    }
  }

  if (hasEnabledLinkAttachment) {
    const doesLinkAttachmentHaveThumbnail = serviceDraft.link.thumbnail !== null

    formattedMediaFields = {
      ...formattedMediaFields,
      // @ts-expect-error TS(2322) FIXME: Type '{ link: any; title: any; description: any; }... Remove this comment to see the full error message
      media: {
        link: serviceDraft.link.url,
        title: serviceDraft.link.title,
        description: serviceDraft.link.description,
      },
    }

    if (doesLinkAttachmentHaveThumbnail) {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      Object.assign(formattedMediaFields.media, {
        preview: serviceDraft.link.thumbnail.url,
        preview_safe: serviceDraft.link.thumbnailHttps,
        picture: serviceDraft.link.thumbnail.url,
        original_preview: serviceDraft.link.thumbnail.originalUrl,
      })
    }
  } else if (hasEnabledMediaAttachment) {
    if (hasImageAttachment) {
      const [firstImage, ...otherImages] = serviceDraft.images

      formattedMediaFields = {
        ...formattedMediaFields,
        // @ts-expect-error TS(2322) FIXME: Type '{ progress: number; uploaded: boolean; photo... Remove this comment to see the full error message
        media: getFormattedImageFields(
          firstImage,
          serviceDraft.service.canHaveUserTags,
        ),
      }

      // If several images were attached, attach remaining ones to the extra_media field
      if (otherImages.length > 0) {
        // @ts-expect-error TS(2322) FIXME: Type '{}' is not assignable to type 'null | undefi... Remove this comment to see the full error message
        formattedMediaFields.extra_media = {}
        // @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
        otherImages.forEach((image, index) => {
          // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
          formattedMediaFields.extra_media[index] = getFormattedImageFields(
            image,
            serviceDraft.service.canHaveUserTags,
          )
        })
      }
    } else if (hasGifAttachment) {
      const gif = serviceDraft.gif.url

      formattedMediaFields = {
        ...formattedMediaFields,
        // @ts-expect-error TS(2322) FIXME: Type '{ progress: number; uploaded: true; photo: a... Remove this comment to see the full error message
        media: {
          progress: 100,
          uploaded: true,
          photo: gif,
          picture: gif,
          thumbnail: gif,
        },
      }
    } else if (hasVideoAttachment) {
      const { video } = serviceDraft

      formattedMediaFields = {
        ...formattedMediaFields,
        // @ts-expect-error TS(2322) FIXME: Type '{ progress: number; uploaded: true; uploadin... Remove this comment to see the full error message
        media: {
          progress: 100,
          uploaded: true,
          uploading_type: 'video',
          video: {
            title: video.name,
            id: video.uploadId,
            details: {
              location: video.originalUrl,
              transcoded_location: video.url,
              file_size: video.size,
              duration: video.duration,
              duration_millis: video.durationMs,
              width: video.width,
              height: video.height,
            },
            thumb_offset: video.thumbOffset,
            thumbnails: video.availableThumbnails,
          },
          thumbnail: video.thumbnail,
        },
      }
    } else if (documentAttachmet) {
      formattedMediaFields = {
        ...formattedMediaFields,
        // @ts-expect-error TS(2322) FIXME: Type '{ progress: number; uploaded: true; uploadin... Remove this comment to see the full error message
        media: Document.toPostMedia(documentAttachmet),
      }
    }
  }

  return formattedMediaFields
}

// @ts-expect-error TS(7006) FIXME: Parameter 'serviceDraft' implicitly has an 'any' t... Remove this comment to see the full error message
function getFormattedThreadData(serviceDraft, isEditingUpdate) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'threadedDraft' implicitly has an 'any' ... Remove this comment to see the full error message
  return serviceDraft.thread.map((threadedDraft) => {
    // getFormattedMediaFields() expects service to be set on the draft
    threadedDraft.service = serviceDraft.service
    const threadedUpdate = {
      text: threadedDraft.text,
      ...getFormattedMediaFields(isEditingUpdate, threadedDraft),
    }

    const hasThreadedRetweetAttachment =
      threadedDraft.enabledAttachmentType === AttachmentTypes.RETWEET &&
      threadedDraft.retweet !== null
    if (hasThreadedRetweetAttachment) {
      const { retweet } = threadedDraft

      Object.assign(threadedUpdate, {
        is_native_retweet: false,
        retweeted_tweet_id: retweet.tweetId,
        retweet: {
          user_id: retweet.userId,
          tweet_id: retweet.tweetId,
          user_name: retweet.userName,
          display_name: retweet.userDisplayName,
          url: retweet.tweetUrl,
          avatar_http: retweet.avatarUrl,
          avatar_https: retweet.avatarUrl,
          comment: threadedUpdate.text,
        },
      })
    }

    return threadedUpdate
  })
}

/**
 * Transform data to the format the API consumes.
 *
 * unformattedData = {
 *   queueingType: QueueingTypes.X,
 *   customScheduleTime: timestamp || null,
 *   serviceProfiles: [profile, …], // Selected profiles for that service
 *   serviceDraft: draft, // Draft for that service
 *   isEditingUpdate: boolean,
 * }
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'serviceName' implicitly has an 'any' ty... Remove this comment to see the full error message
function getFormattedAPIData(serviceName, unformattedData) {
  const appMetaData = AppStore.getMetaData()
  const { serviceDraft, isEditingUpdate, isSavedUpdate } = unformattedData
  const serviceDraftText = serviceDraft.text
  const serviceProfilesIds = unformattedData.serviceProfiles.map(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => profile.id,
  )
  const serviceSelectedSubprofiles = unformattedData.serviceProfiles.map(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => profile.selectedSubprofileId,
  )
  const hasEnabledLinkAttachment =
    serviceDraft.enabledAttachmentType === AttachmentTypes.LINK &&
    serviceDraft.link !== null
  const hasEnabledRetweetAttachment =
    serviceDraft.enabledAttachmentType === AttachmentTypes.RETWEET &&
    serviceDraft.retweet !== null
  const hadEnabledRetweetAttachment =
    appMetaData.retweetData !== null && !hasEnabledRetweetAttachment
  const shouldShortenLinks = !DraftMethods.hasUnshortenedUrls(serviceDraft)
  const isTwitterThread =
    serviceDraft.updateType === 'thread' &&
    serviceDraft.service.isTwitter() &&
    Array.isArray(serviceDraft.thread)

  const getConditionalFields = () => {
    let conditionalFields = {}

    const didUserSetScheduledAtBeforeSession = appMetaData.didUserSetScheduledAt
    const didUserChangeScheduledAtDuringSession =
      appMetaData.scheduledAt !== unformattedData.customScheduleTime

    // Only set scheduled_at and pinned fields when an update is created with a
    // custom time, or when it's edited with a custom time and this time wasn't
    // automatically generated by our queueing engine (i.e. the time was user-set)
    if (
      didUserSetScheduledAtBeforeSession ||
      didUserChangeScheduledAtDuringSession
    ) {
      // @ts-expect-error TS(2339) FIXME: Property 'scheduled_at' does not exist on type '{}... Remove this comment to see the full error message
      conditionalFields.scheduled_at = unformattedData.customScheduleTime
      // @ts-expect-error TS(2339) FIXME: Property 'pinned' does not exist on type '{}'.
      conditionalFields.pinned = !!serviceDraft.isPinnedToSlot
    }

    if (serviceDraft.service.hasSubprofiles) {
      if (isEditingUpdate && appMetaData.editMode) {
        // The API expects subprofile_id (a single id) when editing updates
        const [firstSubprofileId] = serviceSelectedSubprofiles
        // @ts-expect-error TS(2339) FIXME: Property 'subprofile_id' does not exist on type '{... Remove this comment to see the full error message
        conditionalFields.subprofile_id = firstSubprofileId
      } else {
        // @ts-expect-error TS(2339) FIXME: Property 'subprofile_ids' does not exist on type '... Remove this comment to see the full error message
        conditionalFields.subprofile_ids = serviceSelectedSubprofiles
      }
    }

    // @ts-expect-error TS(2339) FIXME: Property 'serviceUpdateId' does not exist on type ... Remove this comment to see the full error message
    if (appMetaData.serviceUpdateId) {
      // @ts-expect-error TS(2339) FIXME: Property 'service_update_id' does not exist on typ... Remove this comment to see the full error message
      conditionalFields.service_update_id = appMetaData.serviceUpdateId
    }

    if (serviceDraft.ideaId) {
      // @ts-expect-error TS(2339) FIXME: Property 'ideaId' does not exist on type '{}'.
      conditionalFields.ideaId = serviceDraft.ideaId
    }

    if (serviceDraft.tags) {
      // @ts-expect-error TS(2339) FIXME: Property 'tags' does not exist on type '{}'.
      conditionalFields.tags = serviceDraft.tags
    }

    if (serviceDraft.service.canHaveSourceUrl) {
      const { sourceLink } = serviceDraft

      if (sourceLink !== null) {
        const sourceUrl = ensureUrlProtocol(sourceLink.url)
        // @ts-expect-error TS(2339) FIXME: Property 'source_url' does not exist on type '{}'.
        conditionalFields.source_url = sourceUrl
      }
    }

    if (serviceDraft.updateType) {
      // @ts-expect-error TS(2339) FIXME: Property 'update_type' does not exist on type '{}'... Remove this comment to see the full error message
      conditionalFields.update_type = serviceDraft.updateType
    }

    const { campaignId } = serviceDraft
    // @ts-expect-error TS(2339) FIXME: Property 'campaign_id' does not exist on type '{}'... Remove this comment to see the full error message
    conditionalFields.campaign_id = campaignId

    if (hasEnabledRetweetAttachment) {
      const { retweet } = serviceDraft

      conditionalFields = Object.assign(conditionalFields, {
        is_native_retweet: !isTwitterThread,
        retweeted_tweet_id: retweet.tweetId,
        retweet: {
          user_id: retweet.userId,
          tweet_id: retweet.tweetId,
          user_name: retweet.userName,
          display_name: retweet.userDisplayName,
          url: retweet.tweetUrl,
          avatar_http: retweet.avatarUrl,
          avatar_https: retweet.avatarUrl,
          comment: serviceDraftText,
        },
      })
      // The API expects retweet to be null when editing an update and removing its retweet attachment
    } else if (isEditingUpdate && hadEnabledRetweetAttachment) {
      conditionalFields = Object.assign(conditionalFields, {
        retweet: null,
      })
    }

    if (serviceDraft.service.isPinterest()) {
      conditionalFields = Object.assign(conditionalFields, {
        title: serviceDraft.title,
      })
    }

    if (serviceDraft.thread) {
      // @ts-expect-error TS(2339) FIXME: Property 'thread' does not exist on type '{}'.
      conditionalFields.thread = getFormattedThreadData(
        serviceDraft,
        isEditingUpdate,
      )
    }

    // If Thread was deleted, we want to ensure we pass null here so it's preserved in DB
    if (Array.isArray(AppStore.getMetaData().thread) && !serviceDraft.thread) {
      // @ts-expect-error TS(2339) FIXME: Property 'thread' does not exist on type '{}'.
      conditionalFields.thread = null
    }

    // If UpdateType was unset, we want to ensure we pass null here so it's preserved in DB
    if (AppStore.getMetaData().updateType && !serviceDraft.updateType) {
      // @ts-expect-error TS(2339) FIXME: Property 'update_type' does not exist on type '{}'... Remove this comment to see the full error message
      conditionalFields.update_type = null
    }

    return conditionalFields
  }
  const { tabId, emptySlotMode } = AppStore.getMetaData()

  const annotations =
    serviceName === Service.Linkedin
      ? EditorStateProxy.getAllLinkedinAnnotations(serviceDraft.editorState)
      : []

  let schedulingType
  if (serviceDraft.isReminder === true) {
    schedulingType = 'reminder'
  } else {
    schedulingType = 'direct'
  }

  return {
    now: unformattedData.queueingType === QueueingTypes.NOW,
    top:
      unformattedData.queueingType === QueueingTypes.NEXT ||
      unformattedData.queueingType === QueueingTypes.NEXT_DRAFT,
    is_draft:
      unformattedData.queueingType === QueueingTypes.ADD_DRAFT ||
      unformattedData.queueingType === QueueingTypes.NEXT_DRAFT ||
      unformattedData.queueingType === QueueingTypes.CUSTOM_DRAFT,
    shorten: shouldShortenLinks,
    // When editing updates, the API expects only the text field to be used (not fb_text)
    text: serviceDraftText,
    scheduling_type: schedulingType,
    fb_text:
      serviceName === 'facebook' && !isSavedUpdate ? serviceDraftText : '',
    entities:
      serviceName === 'facebook'
        ? EditorStateProxy.getFacebookAutocompleteEntities(
            serviceDraft.editorState,
          )
        : null,
    annotations,
    profile_ids: serviceProfilesIds,
    attachment: hasEnabledLinkAttachment,
    via:
      appMetaData.appEnvironment === AppEnvironments.EXTENSION
        ? 'bookmarklet'
        : null,
    source: appMetaData.browser,
    version: appMetaData.extensionVersion,
    duplicated_from: appMetaData.duplicatedFrom || null,
    created_source: getCurrentPage(tabId, emptySlotMode),
    channel_data: transformChannelData({
      draft: serviceDraft,
    }),
    ...getConditionalFields(),
    ...getFormattedMediaFields(isEditingUpdate, serviceDraft),
    ai_assisted: !!serviceDraft.aiAssisted,
  }
}

export default WebAPIUtils
