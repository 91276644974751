/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import MagicWandIcon from '@bufferapp/ui/Icon/Icons/MagicWand'

import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import { store, useAppSelector, useAppDispatch } from '~publish/legacy/store'
import {
  resetContentGeneration,
  selectContentGenerationSelection,
  setContentGenerationSelection,
  toggleAIAssistant,
  setAIAssistantPlacement,
} from '~publish/legacy/ai/state/slice'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'

import * as Styled from './AIContextualMenu.styles'
import getNewPosition from '~publish/legacy/ai/helpers/getNewPosition'
import { isSafari } from '~publish/legacy/composer/composer/utils/DOMUtils'
import moveGrammarlyButton from '~publish/legacy/ai/helpers/grammarlyButton'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'
import { IDEA_EDITOR_ID } from '~publish/components/IdeaComposer/constants'

/**
 *
 */
function AIContextualMenu({
  editor,
  placement,
}: {
  editor: any
  placement: 'publishComposer' | 'ideasEditor'
}): JSX.Element | null {
  const [position, setPosition] = React.useState({ top: 0, left: 0 })
  const [selected, setSelected] = React.useState<string | null>(null)

  const contentGenerationSelection = useAppSelector((state) =>
    selectContentGenerationSelection(state),
  )

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    editor?.onSelectionCheck?.(onSelectionChange)

    document.addEventListener('mouseup', moveGrammarlyButtonWhenVisible)
    document.addEventListener('keydown', onArrowPressUp)

    if (!isSafari()) {
      /* This event works different in Safari, so it is not needed to add the handler */
      document.addEventListener('selectionchange', onSelectionChange)
    } else {
      /* This is mostly a workaround for safari because it not hiding the Refine button when
      the editor losses the focus */
      document.addEventListener('click', onDocumentClick)
    }

    return () => {
      if (!isSafari()) {
        document.removeEventListener('selectionchange', onSelectionChange)
      } else {
        document.removeEventListener('click', onDocumentClick)
      }
      document.removeEventListener('mouseup', moveGrammarlyButtonWhenVisible)
      document.removeEventListener('keydown', onArrowPressUp)
    }
  }, [])

  const updatePosition = (newTop: number, newLeft: number): void => {
    setPosition((prevState) => {
      // Check if the new values are different from the current ones
      if (newTop !== prevState.top || newLeft !== prevState.left) {
        // Update the state only if they are different
        return { top: newTop, left: newLeft }
      } else {
        // Return the current state to prevent unnecessary re-renders
        return prevState
      }
    })
  }

  const hasEditorFocus = (): boolean => {
    const selector = `[data-draftid="${editor.id}"]`
    const container = document.querySelector(selector)

    if (document.activeElement !== container) {
      return false
    }
    return true
  }

  const moveGrammarlyButtonWhenVisible = (): void => {
    setTimeout(() => {
      /* We need to wait for Grammarly Button shows up */
      moveGrammarlyButton()
    }, 500)
  }

  /**
   *
   * EVENT HANDLERS
   *
   */
  const onArrowPressUp = (event: KeyboardEvent): void => {
    if (
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    ) {
      moveGrammarlyButtonWhenVisible()
    }
  }

  const onDocumentClick = (event: any): void => {
    const selector = `[data-draftid="${editor.id}"]`
    const container = document.querySelector(selector)

    /* This is only for Safari to be able to hide refine button when the editor losses the focus */
    const clickOutside = !container?.contains(event.target)
    if (clickOutside && document.activeElement !== container) {
      setSelected(null)
    }
  }

  /**
   * We have two possible events to get the changes in selections (from window and from editor)
   * but both are not firing in all the cases, for example, when the user selects all the text
   * in the editor but a char and remove the selected text with Del key => window.onselectiononchange doesn’t fire,
   * editor.onchange yes but it is taking the window selection text as selected text, so,
   * it will continue showing the Refine button. Nevertheless, when all the text is selected and removed with
   * Del key, both events fires but the editor one fires first and it is taking the window selection text as selected text
   * when is null. To solve these scenarios, we will manage both events with this handler, get the selected text from both sources
   * and if they are the same, we will update the selected state, if not, we will reset the selected state.
   */

  const onSelectionChange = (): void => {
    if (hasEditorFocus()) {
      const selectedText = editor?.getSelection()

      /* If one of the sources is null then reset the selected text */
      if (selectedText) {
        moveGrammarlyButtonWhenVisible()
        const { top: calculatedTop, left: calculatedLeft } =
          getNewPosition(editor)
        updatePosition(calculatedTop, calculatedLeft)
      }
      setSelected(selectedText)
    } else {
      setSelected(null)
    }
  }

  const onRefineClick = (
    event: React.MouseEvent | React.KeyboardEvent,
  ): void => {
    if (!selected) return
    event.preventDefault()
    event.stopPropagation()

    // Sets the placement (source) for tracking
    // Must be set before the upgrade modal is triggered as the modal
    // relies on the placement for tracking.
    dispatch(setAIAssistantPlacement({ placement }))

    // Display a upgrade flow for multi-product users
    // Restrict AI Assistant feature to New Buffer users only
    if (selectShouldShowNBMigration(store.getState())) {
      ModalActionCreators.openModal('AIAssistantMPUpgradePlan', {
        ctaButton: 'contextualMenu',
      })
      return
    }

    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetContentGeneration())

    dispatch(setContentGenerationSelection(selected))

    dispatch(toggleAIAssistant(true))
    ComposerActionCreators.updateSidebarOnTop(null, 'aiassistant')
  }

  const { top, left } = useMemo(() => position || {}, [position])

  const isIdeas = editor.id === IDEA_EDITOR_ID
  const expandedComposer = AppStore.getExpandedComposerId() === editor.id

  const isPromptChange =
    selected !== contentGenerationSelection || !contentGenerationSelection

  const showRefineButton =
    !!selected && isPromptChange && (expandedComposer || isIdeas)

  return showRefineButton ? (
    <Styled.MenuButton
      id="ai-assistant-refine-button"
      data-testid="ai-assistant-refine-button"
      top={top}
      left={left}
      onClick={onRefineClick}
      secondary
    >
      <Styled.ButtonContent>
        <MagicWandIcon />
        Refine with AI
      </Styled.ButtonContent>
    </Styled.MenuButton>
  ) : null
}

export default React.memo(AIContextualMenu)
