import React from 'react'
import Banner from '../../../../components/Banner'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import { ButtonWrapper } from './styles'
import { orangeDarker } from '@bufferapp/ui/style/colors'
import { useUser } from '../../../../common/context/User'
import { getBillingPaymentMethodUrl } from '../../../../common/utils/urls'
import {
  calculateDaysTillUserCreditCardExpires,
  isUserCreditCardExpired,
} from '../../../../common/utils/user'

const EXPIRED_CARD_COPY =
  'Your credit card on file is expired! Please update your billing information to ensure your subscription remains in good standing.'
const EXPIRING_CARD_COPY = (remainingDays: number): string =>
  `Your credit card on file will expire in ${remainingDays} days! Please update your billing information to ensure your subscription remains in good standing. `

const ExpiredCardBanner = (): JSX.Element => {
  const user = useUser()

  const remainingDaysTillExpiry = calculateDaysTillUserCreditCardExpires(user)
  const alertCopy = isUserCreditCardExpired(user)
    ? EXPIRED_CARD_COPY
    : EXPIRING_CARD_COPY(remainingDaysTillExpiry)

  return (
    <Banner
      customHTML={
        <>
          <Text type="p" color={orangeDarker}>
            <strong>ACTION REQUIRED:</strong> {alertCopy}
          </Text>
          <ButtonWrapper>
            <Button
              type="orange"
              onClick={(): void => {
                window.location.assign(getBillingPaymentMethodUrl())
              }}
              label={'Update Billing Info'}
              size="small"
            />
          </ButtonWrapper>
        </>
      }
      themeColor="orange"
      dismissible={false}
    />
  )
}

export default ExpiredCardBanner
