import {
  setAIAssistantPlacement,
  toggleAIAssistant,
} from '~publish/legacy/ai/state/slice'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'
import store, { useAppDispatch } from '~publish/legacy/store'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'

export const useOpenAiAssistant = (): (() => void) => {
  const dispatch = useAppDispatch()

  const onToggleAIAssistant = (): void => {
    // Sets the placement (source) for tracking
    // Must be set before the upgrade modal is triggered as the modal
    // relies on the placement for tracking.
    dispatch(setAIAssistantPlacement({ placement: 'publishComposer' }))

    // Display a upgrade flow for multi-product users
    // Restrict AI Assistant feature to New Buffer users only
    if (selectShouldShowNBMigration(store.getState())) {
      ModalActionCreators.openModal('AIAssistantMPUpgradePlan', {
        ctaButton: 'integrationsBar',
      })
      return
    }

    dispatch(toggleAIAssistant(true))

    ComposerActionCreators.updateSidebarOnTop(null, 'aiassistant')
  }

  return onToggleAIAssistant
}
