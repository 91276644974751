import React from 'react'

import { NetworkStatus, type ApolloError, useQuery } from '@apollo/client'
import { toast } from '@buffer-mono/popcorn'

import { graphql } from '~publish/gql'
import type { CommentsQuery, CommentStatus } from '~publish/gql/graphql'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useInfiniteScrollPagination } from '~publish/hooks/useInfiniteScrollPagination'

import type { BaseCommentsFilters } from '../CommentsFilters/useFilters'

export const COMMENTS_PER_PAGE = 20

export const getCommentsQuery = graphql(/* GraphQL */ `
  query Comments($input: CommentsInput!, $first: Int!, $after: String) {
    comments(input: $input, first: $first, after: $after) {
      edges {
        node {
          ... on BaseComment {
            __typename
            id
            text
            publishedAt
            status
            parentId
            serviceType
            isOwnReply
            organizationId
            lastReply {
              id
              text
              organizationId
              serviceType
              publishedAt
              parentId
              status
              isOwnReply
              author {
                username
                name
                avatar
              }
              assets {
                id
                mimeType
                source
                thumbnail
                type
              }
            }
            assets {
              id
              mimeType
              source
              thumbnail
              type
            }
            author {
              username
              name
              avatar
            }
            post {
              id
              text
              dueAt
              channelService
              assets {
                ...PostMediaAsset_Asset
              }
              tags {
                name
                color
              }
              metadata {
                ... on CommonPostMetadata {
                  type
                }
              }
            }
          }
          ... on CommentFromThirdParty {
            labels
            suggestions {
              text
              source
            }
            sentimentAnalysis {
              type
            }
          }
          ... on CommentFromBuffer {
            publishingStatus {
              status
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
`)

type UseCommentsQueryProps = {
  channelIds?: string[]
  filters?: Omit<BaseCommentsFilters, 'status'> & {
    status?: CommentStatus | 'all'
  }
  skip?: boolean
}

export function useCommentsQuery({
  channelIds,
  filters,
  skip,
}: UseCommentsQueryProps): {
  data: CommentsQuery | undefined
  loading: boolean
  error: ApolloError | undefined
  fetchingMore: boolean
  lastElementRef: (node: Element | null) => void
  fetchNewComments: () => Promise<void>
} {
  const organizationId = useOrganizationId()
  const { status, sortDirection, dateStart, dateEnd } = filters ?? {}
  const { data, loading, error, fetchMore, networkStatus } = useQuery(
    getCommentsQuery,
    {
      variables: {
        input: {
          filters: {
            channelIds,
            status: status && status !== 'all' ? [status] : undefined,
            publishedAt: {
              start: dateStart ?? undefined,
              end: dateEnd ?? undefined,
            },
          },
          sort: [
            {
              field: 'publishedAt',
              direction: sortDirection ?? 'desc', // default: newest first, desc
            },
          ],
          organizationId: organizationId ?? '',
        },
        first: COMMENTS_PER_PAGE,
        after: null,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      skip: !organizationId || skip,
    },
  )

  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  const fetchNewComments = React.useCallback(async (): Promise<void> => {
    try {
      await fetchMore({
        variables: {
          input: {
            filters: {
              channelIds,
              status: status && status !== 'all' ? [status] : undefined,
              publishedAt: {
                start: dateStart ?? undefined,
                end: dateEnd ?? undefined,
              },
            },
            sort: [
              {
                field: 'publishedAt',
                direction: sortDirection ?? 'desc',
              },
            ],
            organizationId,
          },
          first: COMMENTS_PER_PAGE,
          after: null,
        },
        updateQuery: (
          prev: CommentsQuery,
          { fetchMoreResult }: { fetchMoreResult: CommentsQuery | null },
        ) => {
          if (!fetchMoreResult) return prev
          return {
            comments: {
              ...fetchMoreResult.comments,
              edges: fetchMoreResult.comments.edges,
            },
          }
        },
      })
    } catch (error) {
      toast.error('Error fetching new comments')
      throw new Error(
        'Failed to fetch new comments: ' + (error as Error).message,
      )
    }
  }, [
    channelIds,
    status,
    dateStart,
    dateEnd,
    sortDirection,
    organizationId,
    fetchMore,
  ])

  const [lastElementRef] = useInfiniteScrollPagination({
    loading: fetchingMore,
    hasNextPage: data?.comments.pageInfo.hasNextPage,
    fetchMore: async () => {
      try {
        await fetchMore({
          variables: {
            input: {
              filters: {
                channelIds,
                status: status && status !== 'all' ? [status] : undefined,
                publishedAt: {
                  start: dateStart ?? undefined,
                  end: dateEnd ?? undefined,
                },
              },
              sort: [
                {
                  field: 'publishedAt',
                  direction: sortDirection ?? 'desc',
                },
              ],
              organizationId,
            },
            first: COMMENTS_PER_PAGE,
            after: data?.comments.pageInfo.endCursor,
          },
          updateQuery: (
            prev: CommentsQuery,
            { fetchMoreResult }: { fetchMoreResult: CommentsQuery | null },
          ) => {
            if (!fetchMoreResult) return prev
            return {
              comments: {
                ...fetchMoreResult.comments,
                edges: [
                  ...prev.comments.edges,
                  ...fetchMoreResult.comments.edges,
                ],
              },
            }
          },
        })
      } catch (error) {
        toast.error('Error fetching more comments')
        throw new Error(
          'Failed to fetch more comments: ' + (error as Error).message,
        )
      }
    },
  })

  return React.useMemo(
    () => ({
      data,
      loading,
      error,
      fetchingMore,
      lastElementRef,
      fetchNewComments,
    }),
    [data, loading, error, fetchingMore, lastElementRef, fetchNewComments],
  )
}
