import React from 'react'
import { Button, CoachMark } from '@buffer-mono/popcorn'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { FEEDS_ANNOUNCEMENT_DIALOG_BANNER_ID } from '~publish/components/FeedsAnnouncementDialog'
import { useSplitEnabled } from '@buffer-mono/features'

/**
 * Coach mark component that highlights the "Add Feed" feature
 * for users who haven't added any feeds yet.
 */
export const AddFeedCoachMark = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { isEnabled: isAnnouncementSplitEnabled } =
    useSplitEnabled('feeds_announcement')
  const { isActive: isFeedsAnnouncementDialogActive } = useDismissableBanner(
    FEEDS_ANNOUNCEMENT_DIALOG_BANNER_ID,
  )
  const { isActive: isBannerActive, dismiss } = useDismissableBanner(
    'add-new-feed-coach-mark',
    {
      trackDismiss: true,
    },
  )

  // Show coach mark only after the dialog has been dismissed
  const shouldShowCoachMark =
    isAnnouncementSplitEnabled &&
    isBannerActive &&
    !isFeedsAnnouncementDialogActive

  if (!shouldShowCoachMark) {
    return <>{children}</>
  }

  return (
    <CoachMark open={isBannerActive}>
      <CoachMark.Overlay onClick={dismiss}>
        <CoachMark.Spotlight size="small" />
        {children}
      </CoachMark.Overlay>
      <CoachMark.Content side="right" align="start" showCloseIcon={false}>
        <CoachMark.Title>Add Your First Feed!</CoachMark.Title>
        <CoachMark.Description>
          Subscribe to your favorite blogs, websites, or creators to get the
          latest content ideas.
        </CoachMark.Description>
        <CoachMark.Footer>
          <Button variant="primary" onClick={dismiss}>
            Got it!
          </Button>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
