import { Button, Dialog, Flex } from '@buffer-mono/popcorn'
import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import React, { useEffect } from 'react'
import { useUser } from '../../../../common/context/User'
import useDismissObject from '../../../../common/hooks/useDismissObject'
import { StyledImage } from './styles'

const TrialSignupWelcomeModalContent = ({
  dismissModal,
}: {
  dismissModal: () => void
}): JSX.Element => {
  const user = useUser()

  // Mechanism to dismiss the modal from appearing again
  const { onDismissObject } = useDismissObject({
    organizationId: user.currentOrganization?.id || '',
    dismissedObject: {
      id: 'trial_signup_welcome_modal',
    },
  })

  const onOpenChange = (open: boolean): void => {
    if (open) return

    onDismissObject()
    dismissModal()
  }

  const commonTrackingProperties =
    user.currentOrganization?.commonTrackingProperties

  useEffect(() => {
    if (!user.currentOrganization?.id) return
    console.log('============> [trialSignupWelcomeModalViewed]')
    BufferTracker.trialSignupWelcomeModalViewed({
      organizationId: user.currentOrganization?.id,
      clientName: Client.PublishWeb,
      ...commonTrackingProperties,
    })
  }, [user.currentOrganization?.id])

  return (
    <Dialog open={true} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Flex justify="center" direction="column" align="center" gap="lg">
          <Dialog.Header>
            <Flex justify="center" direction="column" align="center" gap="lg">
              <StyledImage
                alt="Welcome to your trial image"
                src="https://buffer-ui.s3.amazonaws.com/welcome-trial-signup.png"
              />
            </Flex>
          </Dialog.Header>
          <Dialog.Body>
            <Flex justify="center" direction="column" align="center" gap="xs">
              <Dialog.Title>Welcome to your Buffer free trial 👋</Dialog.Title>
              <Dialog.Description align="center" color="subtle">
                Enjoy improved scheduling capabilities, a full analytics
                dashboard, collaboration tools and more and more for 14 days.
                There is no commitment to upgrade afterward unless you want to
                keep using these features.
              </Dialog.Description>
            </Flex>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              size="large"
              variant="primary"
              onClick={(): void => onOpenChange(false)}
            >
              Let&apos;s Explore
            </Button>
          </Dialog.Footer>
        </Flex>
      </Dialog.Content>
    </Dialog>
  )
}

export default React.memo(TrialSignupWelcomeModalContent)
