import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { useCalendar } from '~publish/components/Calendar/hooks/useCalendar'
import { getFragmentData } from '~publish/gql'
import { useTimezone } from '~publish/hooks/useTimezone'
import { useWeekStartsOn } from '~publish/hooks/useWeekStartsOn'
import { useOrganizationId } from '~publish/legacy/accountContext'
import {
  generatePostingSlotsUntilDate,
  type Slot,
} from '~publish/pages/Channel/QueueList/postSlotManager'
import { QueueList_Channel } from '~publish/pages/Channel/QueueList'
import { GetChannelInfo } from '~publish/pages/Channel'
import { endOfDay } from '~publish/helpers/temporal'
import { useSlotsEnabled } from '~publish/hooks/useSlotsEnabled'
export function useCalendarSlots({
  selectedChannelIds,
}: {
  selectedChannelIds?: string[] | void
}): Slot[] {
  const [showTimeSlots] = useSlotsEnabled()
  const timezone = useTimezone()
  const dayToStartWeek = useWeekStartsOn()
  const weekStartsOn = dayToStartWeek === 'monday' ? 1 : 0
  const { endDate } = useCalendar({ timezone, weekStartsOn })
  const [slots, setSlots] = useState<Slot[]>([])
  const [getChannelInfo, { data, error }] = useLazyQuery(GetChannelInfo)
  const organizationId = useOrganizationId()

  useEffect(() => {
    if (!showTimeSlots) setSlots([])
  }, [showTimeSlots])

  useEffect(
    function fetchSlots() {
      if (!showTimeSlots || !organizationId || !selectedChannelIds?.[0]) return
      getChannelInfo({
        variables: { organizationId, channelId: selectedChannelIds?.[0] },
      })
    },
    [showTimeSlots, organizationId, selectedChannelIds, getChannelInfo],
  )

  useEffect(() => {
    async function generateSlots(): Promise<void> {
      if (selectedChannelIds?.length !== 1 || !showTimeSlots || !organizationId)
        return

      const channel = getFragmentData(QueueList_Channel, data?.channel)
      const postingSchedule =
        channel?.postingSchedule.filter((slot) => !slot.paused) ?? []

      if (error || postingSchedule.length === 0) return

      const slots = generatePostingSlotsUntilDate({
        postingSchedule,
        timezone,
        endDate: endOfDay(endDate).toPlainDateTime().toString(),
      })

      setSlots(slots)
    }
    generateSlots()
  }, [
    data?.channel,
    endDate,
    error,
    organizationId,
    selectedChannelIds?.length,
    showTimeSlots,
    timezone,
  ])

  return slots
}
