import React from 'react'
import styled from 'styled-components'

import { stringTokenizer } from '~publish/legacy/i18n'
import { Text, Modal } from '@bufferapp/ui'

const TextWithStyle = styled(Text)`
  margin: 90px 12px 20px;
  align-self: baseline;
`

interface TrialCompleteModalProps {
  hasExpiredProTrial: boolean
  hasExpiredBusinessTrial: boolean
  isPremiumBusiness: boolean
  translations: {
    header: string
    completeAndUpgrade: string
    cancelTrial: string
  }
  cancelTrial: (...args: unknown[]) => unknown // eslint-disable-line
  completeAndUpgrade: (...args: unknown[]) => unknown
}

const TrialCompleteModal = ({
  translations,
  hasExpiredProTrial,
  hasExpiredBusinessTrial,
  isPremiumBusiness,
  cancelTrial,
  completeAndUpgrade,
}: TrialCompleteModalProps): JSX.Element => {
  const currentTrial = () => {
    if (hasExpiredProTrial) return 'Pro'
    if (hasExpiredBusinessTrial) {
      if (isPremiumBusiness) {
        return 'Premium'
      }
      return 'Business'
    }
  }

  const titleMessage = stringTokenizer(
    translations.header,
    '{plan}',
    currentTrial(),
  )

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; background: string; act... Remove this comment to see the full error message
    <Modal
      background="url('https://s3.amazonaws.com/buffer-publish/images/b4b-welcome-modal-background.svg') no-repeat"
      action={{
        label: translations.completeAndUpgrade,
        callback: () => {
          completeAndUpgrade()
        },
      }}
      secondaryAction={{
        label: translations.cancelTrial,
        callback: () => {
          cancelTrial()
        },
      }}
    >
      <TextWithStyle type="h2">{titleMessage}</TextWithStyle>
    </Modal>
  )
}

export default TrialCompleteModal
