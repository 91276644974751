import React from 'react'

import {
  ArrowDownUpIcon,
  BufferIcon,
  ChannelIcon,
  CloseIcon,
  Dialog,
  Flex,
  IconButton,
} from '@buffer-mono/popcorn'

import { BackButton } from '../../../services/_shared/components/BackButton/BackButton'

import styles from './Header.module.css'

export function Header({
  isRefreshingConnection = false,
  onBack,
}: {
  isRefreshingConnection?: boolean
  onBack?: () => void
}): JSX.Element {
  return (
    <Dialog.Header className={styles.wrapper}>
      <div>
        {isRefreshingConnection ? undefined : <BackButton onBack={onBack} />}
      </div>
      <div>
        {!isRefreshingConnection && (
          <Flex gap="sm" align="center">
            <BufferIcon />
            <ArrowDownUpIcon className={styles.arrowsIcon} color="subtle" />
            <ChannelIcon type="instagram" color="branded" />
          </Flex>
        )}
      </div>
      <Dialog.Close>
        <IconButton label="Close" variant="tertiary" size="small">
          <CloseIcon />
        </IconButton>
      </Dialog.Close>
    </Dialog.Header>
  )
}
