import React from 'react'
import Banner from '../../../../components/Banner'
import Text from '@bufferapp/ui/Text'
import { orangeDarker } from '@bufferapp/ui/style/colors'
import { useUser } from '../../../../common/context/User'

const TeamMemberOrgDowngradedBanner = (): JSX.Element => {
  const user = useUser()
  const ownerEmail = user.currentOrganization?.ownerEmail
  const ownerEmailText = ownerEmail ? `, ${ownerEmail}, ` : ' '

  return (
    <Banner
      customHTML={
        <>
          <Text type="p" color={orangeDarker}>
            The organization owner{ownerEmailText}has downgraded to a plan that
            does not support team members, which means you no longer have access
            to publishing. Please contact them to upgrade.
          </Text>
        </>
      }
      themeColor="orange"
      dismissible={false}
      testId="payments-past-due-banner"
    />
  )
}

export default TeamMemberOrgDowngradedBanner
