import {
  Flex,
  useControllableState,
  VisuallyHidden,
  useMergeRefs,
} from '@buffer-mono/popcorn'
import type { Uploader } from '@buffer-mono/uploader'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import { white } from '@bufferapp/ui/style/colors'
import * as Dialog from '@radix-ui/react-dialog'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { createIdeaEditor } from '~publish/components/LegacyIdeaComposer/IdeaEditor'
import { serializeQueryParams } from '~publish/hooks/useQueryParam'
import { useOrganizationLimits } from '~publish/legacy/accountContext'
import type { SelectedTag } from '~publish/legacy/campaign/types'
import { SlateJsProxy } from '~publish/legacy/composer/composer/entities/EditorStateProxy'
import { getPlainText } from '~publish/legacy/editor/BufferEditor'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { createPage, ideaNewRoute } from '~publish/legacy/routes'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectIncludedMedia } from '~publish/legacy/uploads/state/selectors'
import { preloadMedia } from '~publish/legacy/uploads/state/thunks/preloadMedia'
import { selectUserIdAndEmail } from '~publish/legacy/user/selectors'
import { UNASSIGNED_GROUP_ID } from '~publish/pages/Create/components/Board/helpers/helpers'
import { useIdeasCount } from '~publish/pages/Create/hooks/useIdeasCount'
import type { Idea, IdeaContent } from '~publish/pages/Create/types'
import { createPostFromIdea as createPostFromIdeaThunk } from '../../pages/Create/state/thunks/createPostFromIdea' // Still not sure where this thunk should live so keeping in Create page for now
import type { IdeaComposerLocationState } from '../IdeaManagementRouter/hooks'
import { AIAssistantIdeasSidePanel } from './AIAssistantSidePanel'
import { ConfirmCloseModal } from './ConfirmCloseModal'
import { IDEAS_UPLOADER_ID } from './constants'
import { isExistingIdea } from './helpers'
import styles from './IdeaComposer.module.css'
import { IdeaEditor } from './IdeaEditor'
import { IdeasLimitReachedModal } from './IdeasLimitReachedModal'
import { useSaveIdea, type SaveIdeaError } from './useSaveIdea'

export type NewIdea = {
  id?: string
  content: Omit<IdeaContent, 'aiAssisted'>
  groupId?: Idea['groupId']
}

export type DraftIdea = NewIdea | Idea

type IdeaComposerContentProps = {
  onClose: () => void
  uploader: Uploader
}

/**
 * Internal component responsible for managing dialog interactions and overall Dialog composition.
 * - Dialog portal rendering and positioning
 * - Overlay click handling and close confirmations
 * - Conditional rendering of composer content or paywall
 * - Rendering of side panels
 *
 * @private
 */
const IdeaComposerContent = React.forwardRef<
  HTMLDivElement,
  IdeaComposerContentProps
>((props, ref): JSX.Element => {
  const { onClose, uploader } = props
  const { hasUnsavedChanges, draftIdea } = useIdeaComposerState()

  const wrapperRef = React.useRef(null)
  const mergedRef = useMergeRefs(ref, wrapperRef)

  const container = document.getElementById('portals-container')

  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    React.useState(false)

  const { ideas: ideasLimit } = useOrganizationLimits() ?? {}
  const { ideasCount } = useIdeasCount()
  const isIdeasLimitReached = ideasLimit && ideasCount >= ideasLimit
  const location = useLocation<IdeaComposerLocationState>()
  const isEditAction = location?.state?.action === 'edit'

  /**
   * Check for unsaved changes before closing the composer.
   */
  const handleClose = React.useCallback(
    (event?: React.MouseEvent | KeyboardEvent) => {
      event?.stopPropagation()
      event?.preventDefault()
      if (hasUnsavedChanges()) {
        setIsDiscardChangesDialogOpen(true)
      } else {
        onClose()
      }
    },
    [hasUnsavedChanges, onClose],
  )

  /**
   * Overlay click handler - if the click is outside the dialog content, close the composer.
   * Due to the custom overlay setup, clicks inside the composer can sometimes trigger this event.
   */
  const onOverlayClick = (event: React.MouseEvent | KeyboardEvent): void => {
    const target = event.target as HTMLElement
    const wrapper = wrapperRef.current as HTMLElement | null
    if (wrapper?.contains(target)) return

    handleClose(event)
  }

  // Prevent event from propagating or triggering.
  // Used to prevent interactions outside the dialog content so we can manage it ourselves.
  // This is necessary because certain interactions seem to bypass our change checks.
  // E.g., opening Canva modal and clicking its close button will incorrectly trigger
  // Dialog.Content's onInteractOutside event and close the composer without change detection.
  const blockEvent = React.useCallback(
    (
      e:
        | CustomEvent<{ originalEvent: PointerEvent }>
        | CustomEvent<{ originalEvent: FocusEvent }>
        | React.MouseEvent,
    ) => {
      e.stopPropagation()
      e.preventDefault()
    },
    [],
  )

  return (
    <Dialog.Portal container={container}>
      {/*
        This component is a div instead of Dialog.Overlay
        Context: https://github.com/radix-ui/primitives/issues/1159
      */}
      {/* disabling eslint rules because overlay doesn't need key listeners */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={styles.overlay} onClick={onOverlayClick} />
      {isIdeasLimitReached && !isEditAction ? (
        <IdeasLimitReachedModal onClose={onClose} />
      ) : (
        <Dialog.Content
          id="ideas-content-wrapper"
          className={styles.content}
          onInteractOutside={blockEvent}
          onEscapeKeyDown={handleClose}
          ref={mergedRef}
          aria-describedby={undefined}
        >
          <VisuallyHidden>
            <Dialog.Title>
              {draftIdea?.id
                ? draftIdea.content.title
                  ? `Editing idea: ${draftIdea.content.title}`
                  : 'Editing idea'
                : 'Creating idea'}
            </Dialog.Title>
          </VisuallyHidden>
          <Flex
            className={styles.contentWithSidepanel}
            direction="row"
            justify="start"
            align="center"
            gap="xs"
          >
            {/* AI ASSISTANT SIDE PANEL */}
            <AIAssistantIdeasSidePanel>
              <IdeaEditor container={container} uploader={uploader} />
            </AIAssistantIdeasSidePanel>
          </Flex>

          <Dialog.Close asChild>
            <button className={styles.closeButton} onClick={handleClose}>
              <VisuallyHidden>Close</VisuallyHidden>
              <CrossIcon
                className={styles.closeIcon}
                size="medium"
                verticalAlign="top"
                color={white}
              />
            </button>
          </Dialog.Close>

          {/* Discard changes confirmation dialog */}
          <Dialog.Root
            open={isDiscardChangesDialogOpen}
            onOpenChange={setIsDiscardChangesDialogOpen}
          >
            <ConfirmCloseModal
              container={container}
              onCancel={(event: React.MouseEvent): void => {
                event.preventDefault()
                event.stopPropagation()
                setIsDiscardChangesDialogOpen(false)
              }}
              onConfirmClose={(): void => onClose()}
            />
          </Dialog.Root>
        </Dialog.Content>
      )}
    </Dialog.Portal>
  )
})

IdeaComposerContent.displayName = 'IdeaComposerContent'

type DiffableIdea = {
  title?: string
  text?: string
  media?: string[]
  tags?: string[]
  groupId?: string
}

/**
 * Flatten idea into a object that's easy to compare for changes.
 * This is used to determine if the idea has unsaved changes.
 * Only include properties that should be watched.
 *
 * E.g. 'aiAssisted' property shouldn't prevent closing if it's the only change detected.
 */
const getDiffableIdea = (idea: DraftIdea): DiffableIdea => ({
  title: idea.content.title?.trim() ?? '',
  text: idea.content.text?.trim() ?? '',
  media: idea.content.media?.map((el) => el.url ?? '') ?? [],
  tags: idea.content.tags?.map((el) => el.id) ?? [],
  groupId: idea.groupId?.trim() ?? UNASSIGNED_GROUP_ID,
})

/**
 * Empty idea used as a default value for the composer if no initialIdea is provided.
 */
const emptyIdea: DraftIdea = {
  content: {
    title: '',
    text: '',
    media: [],
  },
}

export type ConstuctIdeaComposerUrl = () => string

export type IdeaComposerContextValue = {
  draftIdea?: DraftIdea
  error: SaveIdeaError | null
  isLoading: boolean
  hasUnsavedChanges: () => boolean
  editor: BufferEditor
  setGroupId: (groupId: string | undefined) => void
  setTitle: (title: string) => void
  setText: (text: string) => void
  setSelectedTags: (tags: SelectedTag[]) => void
  createPostFromIdea: () => void
  constructIdeaComposerUrl: ConstuctIdeaComposerUrl
  saveDraftIdea: () => void
  closeComposer: () => void
}
const IdeaComposerContext = React.createContext<IdeaComposerContextValue>(
  undefined as unknown as IdeaComposerContextValue,
)

type IdeaComposerProps = {
  initialIdea?: DraftIdea
  open?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  uploader: Uploader
}

/**
 * Main composer component for creating and editing ideas.
 * This root component takes an initial idea that defaults to an empty idea,
 * manages controllable open state, and sets up the context for the composer.
 *
 * It manages all of the state related to the idea being edited and provides
 * actions for editing it, as well as preloading existing data, setting up the editor,
 * and passing down open state callbacks.
 *
 * It renders the IdeaComposerContext.Provider, the Dialog.Root, and the IdeaComposerContent.
 * Slate edits are synced back to `draftIdea.content.text`.
 * Media is managed separately in the Redux store and is selected from there when needed.
 * It does not seem worth the complexity to keep state in sync between Redux and IdeaComposer state.
 *
 * @example
 * ```tsx
 * <IdeaComposer
 *   initialIdea={{ content: { title: '', text: '', media: [] } }}
 *   defaultOpen={true}
 *   onOpenChange={(isOpen) => console.log('Composer open state:', isOpen)}
 * />
 * ```
 */
export const IdeaComposer = React.forwardRef<HTMLDivElement, IdeaComposerProps>(
  (props, ref): JSX.Element => {
    const { initialIdea = emptyIdea, uploader } = props

    /** PRIMARY STATE */
    const [open, setOpen] = useControllableState<boolean>({
      prop: props.open,
      defaultProp: props.defaultOpen,
      onChange: props.onOpenChange,
    })

    // Composer ID used as a key to reset the context when the composer is closed
    const composerId = React.useId()
    const dispatch = useAppDispatch()

    // Keep original idea for comparison
    const originalIdea = React.useRef<DraftIdea | undefined>(initialIdea)
    const [draftIdea, setDraftIdea] = React.useState<DraftIdea>(initialIdea)

    /**
     * LOAD MEDIA
     *
     * This should only be run once at initialization
     */
    React.useEffect(() => {
      if (
        isExistingIdea(initialIdea) &&
        initialIdea.content.media &&
        initialIdea.content.media?.length
      ) {
        dispatch(
          preloadMedia({
            media: initialIdea.content.media || [],
            uploaderId: IDEAS_UPLOADER_ID,
          }),
        )
      }
      // Disabling exhaustive-deps here because we only want this to run once
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * PLATE EDITOR INITIALIZATION
     *
     * This ensures the editor is only created once the composer is opened
     * and is destroyed when the composer is closed
     */
    const editorRef = React.useRef<BufferEditor>(createIdeaEditor())
    const editor = editorRef.current

    // When intitializing, if the draftIdea received text from initialIdea
    // parse the text to Slate state
    React.useEffect(() => {
      if (draftIdea?.content?.text) {
        SlateJsProxy.createStateFromText(editor, draftIdea.content.text)
      }
    }, [])

    const draftIdeaMedia = useAppSelector((state) =>
      selectIncludedMedia(state, IDEAS_UPLOADER_ID),
    )

    const hasUnsavedChanges = React.useCallback(() => {
      if (!draftIdea || !originalIdea.current) return false
      const currentIdea = {
        ...draftIdea,
        content: {
          ...draftIdea.content,
          media: draftIdeaMedia,
          text: getPlainText(editor),
        },
      }
      return !isEqual(
        getDiffableIdea(currentIdea),
        getDiffableIdea(originalIdea.current),
      )
    }, [draftIdea, draftIdeaMedia, editor])

    /**
     * IDEA COMPOSER ACTIONS AND SETTERS
     */
    const closeComposer = React.useCallback(() => {
      setOpen(false)
    }, [setOpen])

    const { saveIdea, isLoading, error } = useSaveIdea({
      onCompleted: closeComposer,
    })

    const saveDraftIdea = React.useCallback(() => {
      saveIdea({
        ...draftIdea,
        content: {
          ...draftIdea.content,
          // Include media from Redux state
          media: draftIdeaMedia,
        },
      })
    }, [draftIdea, draftIdeaMedia, saveIdea])

    const setGroupId = React.useCallback(
      (groupId: string | undefined) => {
        setDraftIdea((current) => ({ ...current, groupId }))
      },
      [setDraftIdea],
    )

    const setTitle = React.useCallback(
      (title: string) => {
        setDraftIdea((current) => ({
          ...current,
          content: { ...current.content, title },
        }))
      },
      [setDraftIdea],
    )

    const setText = React.useCallback(
      (text: string) => {
        setDraftIdea((current) => {
          if (current?.content?.text !== text) {
            return {
              ...current,
              content: { ...current.content, text },
            }
          }
          return current
        })
      },
      [setDraftIdea],
    )

    const setSelectedTags = React.useCallback(
      (tags: SelectedTag[]) => {
        setDraftIdea((current) => ({
          ...current,
          content: { ...current.content, tags },
        }))
      },
      [setDraftIdea],
    )

    /**
     * CREATE POST FROM IDEA
     */
    const user = useAppSelector(selectUserIdAndEmail)
    const createPostFromIdea = React.useCallback(() => {
      dispatch(
        createPostFromIdeaThunk({
          idea: {
            ...draftIdea,
            content: {
              ...draftIdea.content,
              // Include media from Redux state
              media: draftIdeaMedia,
            },
          },
          user,
        }),
      )
      closeComposer()
    }, [closeComposer, dispatch, draftIdea, draftIdeaMedia, user])

    const constructIdeaComposerUrl =
      React.useCallback<ConstuctIdeaComposerUrl>(() => {
        const search = serializeQueryParams({
          source: 'create-ideaComposer-toolbar-copyComposerUrl-1',
          title: draftIdea.content.title,
          text: draftIdea.content.text,
          media: draftIdeaMedia?.map((media) => media.url),
        })
        let publishUrl = 'https://publish.buffer.com'
        if (window.location.hostname === 'publish.local.buffer.com') {
          publishUrl = 'https://publish.local.buffer.com:8888'
        }

        // TODO: Remove manual encoding
        // Our FE uses query-string for better query param handling, like array format, eg "&media[]=".
        // While our FE understands the array format and doesn't need the brackets encoded,
        // there seems to be some naive query parsing happening in the login service or somewhere
        // that sees the brackets and encodes the URL again, causing double encoding.
        //
        // I've tried to track down where the double encoding happens but with no luck.
        //
        // Encoding/decoding on the FE is already handled by query-string, it just doesn't encode the array brackets.
        // Because of this, we don't want to use something like 'encodeUriComponent' because everything is already encoded.
        // To solve the problem, we leave encoding to query-string, but manually encode only array brackets to prevent double encoding.
        // This regex is a hack workaround to do that.
        const manuallyEncodedSearch = search.replace(
          /([?&]|^)([^=]+)\[]=/g,
          '$1$2%5B%5D=',
        )

        return `${publishUrl}${createPage.route}${ideaNewRoute.route}?${manuallyEncodedSearch}`
      }, [draftIdea, draftIdeaMedia])

    const contextValue = React.useMemo(
      () => ({
        saveDraftIdea,
        editor,
        error,
        isLoading,
        hasUnsavedChanges,
        draftIdea,
        setGroupId,
        setTitle,
        setText,
        setSelectedTags,
        createPostFromIdea,
        constructIdeaComposerUrl,
        closeComposer,
      }),
      [
        saveDraftIdea,
        editor,
        error,
        isLoading,
        hasUnsavedChanges,
        draftIdea,
        setGroupId,
        setTitle,
        setText,
        setSelectedTags,
        createPostFromIdea,
        constructIdeaComposerUrl,
        closeComposer,
      ],
    )

    return (
      <IdeaComposerContext.Provider key={composerId} value={contextValue}>
        <Dialog.Root open={open} modal={false} onOpenChange={setOpen}>
          <IdeaComposerContent
            onClose={closeComposer}
            ref={ref}
            uploader={uploader}
          />
        </Dialog.Root>
      </IdeaComposerContext.Provider>
    )
  },
)

IdeaComposer.displayName = 'IdeaComposer'

export const useIdeaComposerState = (): IdeaComposerContextValue => {
  const context = React.useContext(IdeaComposerContext)
  if (!context) {
    throw new Error(
      'useIdeaComposer must be used within an IdeaComposerProvider',
    )
  }
  return context
}

export const useIdeaComposerEditor = (): BufferEditor => {
  const { editor } = useIdeaComposerState()
  return editor
}
