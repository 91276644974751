import type { ModalData } from '../../common/events/types'
import { MODALS, type PromiseCB } from '../../common/types'
import { showModal } from '../../exports/Orchestrator/store/modalsReducer'
import store from '../../exports/Orchestrator/store/orchestratorStore'

type RefetchAccountIfNeededProps = {
  modalKey: string
  refetchAccountData: PromiseCB
}

export async function refetchAccountIfNeeded({
  modalKey,
  refetchAccountData,
}: RefetchAccountIfNeededProps): Promise<void> {
  // This was added to fix the data sync when
  // the ChannelConnectionUpgradeModal was being call by the account app
  // We need to make sure even if users had removed channels etc
  // we refetched the latest data

  if (
    modalKey === MODALS.connectChannelUpgrade ||
    modalKey === MODALS.channelConnectionSuccessModal
  ) {
    await refetchAccountData()
  }
}

/**
 * This function is used to handle the modal event
 * It is called when the modal event is triggered
 */
export const handleModalEvent = async (
  event: Event,
  refetchAccountData: PromiseCB,
): Promise<void> => {
  const { modal: modalKey, data: modalData } = (
    event as CustomEvent<{ modal: string; data?: ModalData }>
  ).detail

  await refetchAccountIfNeeded({ modalKey, refetchAccountData })

  /* send this tag to Clarity to be able to track the refactor modal management when it is used
  form other apps */
  if (window.clarity && modalKey) {
    window.clarity('set', 'new_modal_management', modalKey)
  }

  store.dispatch(showModal({ modalKey, modalData }))
}

type HandleOpenModalProps = {
  openModal: (props: { key: string; data?: ModalData }) => void
}

export function handleChannelConnectionOnboardingViaURL({
  openModal,
}: HandleOpenModalProps): void {
  const urlParams = new URLSearchParams(window.location.search)
  const channelIds = urlParams.getAll('channelId')
  const ctaFromURL = urlParams.getAll('cta')

  let ctaView, ctaLocation, ctaButton, ctaVersion

  if (ctaFromURL.length > 0) {
    ;[ctaView, ctaLocation, ctaButton, ctaVersion] = ctaFromURL[0].split('-')
  } else {
    ctaView = 'queue'
    ctaLocation = 'connectChannelOnboardingModal'
    ctaButton = 'createAPost'
    ctaVersion = '1'
  }

  if (channelIds.length > 0) {
    openModal({
      key: MODALS.channelConnectionSuccessModal,
      data: {
        ctaView,
        ctaLocation,
        ctaButton,
        ctaVersion,
        channelIds,
        upgradePathName: 'connectChannel-upgrade',
      },
    })
  }
}

export function handleChannelRefreshViaURL({
  openModal,
}: HandleOpenModalProps): void {
  const urlParams = new URLSearchParams(window.location.search)
  const channelIds = urlParams.getAll('channelId')
  const ctaFromURL = urlParams.getAll('cta')

  let ctaView, ctaLocation, ctaButton, ctaVersion

  if (ctaFromURL.length > 0) {
    ;[ctaView, ctaLocation, ctaButton, ctaVersion] = ctaFromURL[0].split('-')
  } else {
    ctaView = 'queue'
    ctaLocation = 'channelRefreshSuccessModal'
    ctaButton = 'createAPost'
    ctaVersion = '1'
  }

  if (channelIds.length > 0) {
    openModal({
      key: MODALS.channelRefreshSuccessModal,
      data: {
        ctaView,
        ctaLocation,
        ctaButton,
        ctaVersion,
        channelIds,
        upgradePathName: 'connectChannel-upgrade',
      },
    })
  }
}
