import { PusherEvent, usePusherEvent } from '~publish/services/pusher'

const RELEVANT_EVENTS: Array<PusherEvent> = [
  PusherEvent.POST_CREATED,
  PusherEvent.POST_SENT,
  PusherEvent.POST_UPDATED,
  PusherEvent.POST_DELETED,
  PusherEvent.DRAFT_UPDATED,
  PusherEvent.DRAFT_APPROVED,
  PusherEvent.DRAFT_MOVED,
  PusherEvent.QUEUE_CHANGED,
  PusherEvent.POSTS_REORDERED,
]

export function useStreaksAutoRefresh(): void {
  usePusherEvent(RELEVANT_EVENTS, () => {
    // Signal to the appshell to refetch the streaks query
    // The final logic for whether or not to refetch is handled in the streaks widget
    const { eventKeys } = window.appshell || {}
    if (eventKeys) {
      window.dispatchEvent(new CustomEvent(eventKeys.STREAK_UPDATE_EVENT_KEY))
    }
  })
}
