import React from 'react'
import {
  CriticalIcon,
  EmptyState,
  SearchIcon,
  SkeletonText,
  Text,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { isPast } from 'date-fns'

import {
  PostCard,
  PostCardSkeleton,
  PostCard_Post,
} from '~publish/components/PostCard'

import {
  DateSeparator,
  SeparatorHeaderSkeleton,
} from '../../../components/DateSeparator'
import { usePaginatedPostList } from './usePaginatedPostList'

import { getFragmentData, type FragmentType } from '~publish/gql'
import {
  isPausedPost,
  isScheduledPost,
  isUnscheduledApproval,
  isUnscheduledDraft,
  postStatusByTab,
  getPostDisplayDate,
} from '~publish/helpers/post'
import { range } from '~publish/helpers/range'
import type { PostTab } from '~publish/components/PostTabs'
import { PostingTimeline } from '~publish/components/PostingTimeline'

export const PostListSkeleton = (): JSX.Element => (
  <PostingTimeline role="feed" aria-busy>
    <VisuallyHidden>Loading posts</VisuallyHidden>
    {range(20).map((index) => (
      <React.Fragment key={index}>
        {index % 3 === 0 ? <SeparatorHeaderSkeleton /> : null}
        <PostingTimeline.Entry>
          <PostingTimeline.Label>
            <SkeletonText lines={1} width="6ch" />
          </PostingTimeline.Label>
          <PostingTimeline.Content>
            <PostCardSkeleton />
          </PostingTimeline.Content>
        </PostingTimeline.Entry>
      </React.Fragment>
    ))}
  </PostingTimeline>
)

const PostListEmptyState = (): JSX.Element => {
  return (
    <EmptyState size="large">
      <EmptyState.Icon>
        <SearchIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>No posts found</EmptyState.Heading>
      <EmptyState.Description>
        There are no posts that meet your selected filters
      </EmptyState.Description>
    </EmptyState>
  )
}

type PostListProps = {
  status: PostTab
  channelIds?: string[]
  tagIds?: string[]
  emptyState?: React.ReactNode
  showChannelNotices?: boolean
}

export const PostList = ({
  status,
  channelIds,
  tagIds,
  emptyState,
  showChannelNotices = false,
}: PostListProps): JSX.Element => {
  const { data, loading, error, lastElementRef, fetchingMore } =
    usePaginatedPostList({
      status: postStatusByTab[status],
      channelIds,
      tagIds,
      // For the 'sent' tab we want to see the most recently posted first
      sortPreset:
        status === 'sent' ? 'mostRecentlyPostedFirst' : 'upcomingFirst',
    })

  if (loading || (typeof data === 'undefined' && !error)) {
    return <PostListSkeleton />
  }

  if (error) {
    return (
      <EmptyState variant="critical" size="large">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>
          Error happened, let our team know
        </EmptyState.Heading>
        <EmptyState.Description>
          Please let our team know about it, we&apos;ll fix it as soon as
          possible. <Text color="critical">{error.message}</Text>
        </EmptyState.Description>
      </EmptyState>
    )
  }

  // no posts
  if (!loading && !data?.posts.edges?.length) {
    return emptyState ? <>{emptyState}</> : <PostListEmptyState />
  }

  const flattenedPostEdges = data?.posts.edges?.map((edge) => edge.node) ?? []

  const posts = getFragmentData(PostCard_Post, flattenedPostEdges)
  const pausedPosts = posts.filter(isPausedPost)
  const unscheduledDrafts = posts.filter(isUnscheduledDraft)
  const unscheduledApprovals = posts.filter(isUnscheduledApproval)
  const scheduledPosts = posts.filter(isScheduledPost)

  const lastPostId = posts.at(-1)?.id

  return (
    <PostingTimeline
      role="feed"
      aria-busy={fetchingMore}
      data-testid="post-list"
    >
      {pausedPosts.length > 0 && (
        <>
          <PostingTimeline.Header key="unpublished-posts-header">
            Not Published
          </PostingTimeline.Header>
          {pausedPosts.map((post) => (
            <PostingTimeline.Entry key={post.id}>
              <PostingTimeline.TimeLabel
                date={post.dueAt}
                status={post.status}
                notificationStatus={post.notificationStatus}
                schedulingType={post.schedulingType}
                overdue={isPast(post.dueAt) && post.error === null}
                customScheduled={post.isCustomScheduled}
                includeDate
              />
              <PostingTimeline.Content>
                <PostCard
                  key={post.id}
                  post={post as FragmentType<typeof PostCard_Post>}
                  ref={post.id === lastPostId ? lastElementRef : null}
                  showChannelNotices={showChannelNotices}
                />
              </PostingTimeline.Content>
            </PostingTimeline.Entry>
          ))}
        </>
      )}
      {unscheduledDrafts.length > 0 && (
        <>
          <PostingTimeline.Header key="unscheduled-drafts-header">
            Unscheduled
          </PostingTimeline.Header>
          {unscheduledDrafts.map((post) => (
            <PostingTimeline.Entry key={post.id}>
              <PostingTimeline.TimeLabel
                status={post.status}
                notificationStatus={post.notificationStatus}
                schedulingType={post.schedulingType}
              />
              <PostingTimeline.Content>
                <PostCard
                  key={post.id}
                  post={post as FragmentType<typeof PostCard_Post>}
                  ref={post.id === lastPostId ? lastElementRef : null}
                  showChannelNotices={showChannelNotices}
                />
              </PostingTimeline.Content>
            </PostingTimeline.Entry>
          ))}
        </>
      )}
      {unscheduledApprovals.length > 0 && (
        <>
          <PostingTimeline.Header key="unscheduled-approvals-header">
            Unscheduled
          </PostingTimeline.Header>
          {unscheduledApprovals.map((post) => (
            <PostingTimeline.Entry key={post.id}>
              <PostingTimeline.TimeLabel
                status={post.status}
                notificationStatus={post.notificationStatus}
                schedulingType={post.schedulingType}
              />
              <PostingTimeline.Content>
                <PostCard
                  key={post.id}
                  post={post as FragmentType<typeof PostCard_Post>}
                  ref={post.id === lastPostId ? lastElementRef : null}
                  showChannelNotices={showChannelNotices}
                />
              </PostingTimeline.Content>
            </PostingTimeline.Entry>
          ))}
        </>
      )}
      {scheduledPosts.map((post, index, list) => {
        const currentDate = getPostDisplayDate(post)
        const prevPost = list[index - 1]
        const prevDate = prevPost ? getPostDisplayDate(prevPost) : undefined
        const isTentative =
          post.status === 'draft' || post.status === 'needs_approval'

        return (
          <React.Fragment key={`date-header-${post.id}`}>
            {/* TODO: refactor date header to be part of the PostingTimeline */}
            <DateSeparator currentDate={currentDate} previousDate={prevDate} />
            <PostingTimeline.Entry key={post.id}>
              <PostingTimeline.TimeLabel
                date={currentDate}
                status={post.status}
                notificationStatus={post.notificationStatus}
                schedulingType={post.schedulingType}
                tentative={isTentative}
                overdue={
                  isPast(post.dueAt) &&
                  post.status !== 'sent' &&
                  post.error === null
                }
                customScheduled={post.isCustomScheduled}
              />
              <PostingTimeline.Content>
                <PostCard
                  key={post.id}
                  post={post as FragmentType<typeof PostCard_Post>}
                  ref={post.id === lastPostId ? lastElementRef : null}
                  showChannelNotices={showChannelNotices}
                />
              </PostingTimeline.Content>
            </PostingTimeline.Entry>
          </React.Fragment>
        )
      })}

      {data?.posts.pageInfo.hasNextPage && (
        <PostingTimeline.Loading key="load-more" />
      )}
    </PostingTimeline>
  )
}
