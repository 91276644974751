import {
  CompassIcon,
  DropdownMenu,
  useDropdownMenu,
} from '@buffer-mono/popcorn'
import React from 'react'
import {
  ExploreFeedsDialog,
  type ExploreFeedsDialogProps,
} from './ExploreFeedsDialog'

export const ExploreFeedsDialogDropdownMenuItem = ({
  onUpgrade,
  ...props
}: Omit<ExploreFeedsDialogProps, 'onUpgrade'> & {
  onUpgrade: (args: { cta: CTA; upgradePathName: string }) => void
}): JSX.Element => {
  const { close } = useDropdownMenu()

  const closeDropdownOnDialogClose = (open: boolean): void => {
    if (!open) {
      close()
    }
  }

  const handleUpgradeClick = (): void => {
    onUpgrade({
      cta: 'create-feeds-upgradeDialog-upgradeButton-1',
      upgradePathName: 'feeds-exploreFeeds-upgrade',
    })
    // UpgradeDialog overlay blocks plan selector so we close the dropdown menu, also closing the UpgradeDialog
    close()
  }

  return (
    <ExploreFeedsDialog
      onOpenChange={closeDropdownOnDialogClose}
      onUpgrade={handleUpgradeClick}
      {...props}
    >
      <DropdownMenu.Item
        onSelect={(e): void => {
          e.preventDefault()
        }}
      >
        <CompassIcon /> Explore Feeds
      </DropdownMenu.Item>
    </ExploreFeedsDialog>
  )
}
