import React from 'react'
import { Input, Button, Label } from '@buffer-mono/popcorn'

import styles from '../styles.module.css'
import { isValidURL } from '~publish/legacy/grid/util/index'
import type { CustomLink } from '~publish/legacy/profile-sidebar/types'

interface EditingLinkFormProps {
  onCancelClick: (item: CustomLink) => void
  onSaveClick: (item: CustomLink) => void

  item: CustomLink
}

const EditingLinkForm = ({
  item,
  onCancelClick,
  onSaveClick,
}: EditingLinkFormProps): JSX.Element => {
  const [text, setText] = React.useState(item.text)
  const [url, setUrl] = React.useState(item.url)

  return (
    <>
      <div className={styles.editingMyLinksItem}>
        <div className={styles.linkTextInput}>
          <Label>Link Text</Label>
          <Input
            type="text"
            name="text"
            value={text}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setText(e.target.value)
            }}
            placeholder='Describe link (e.g. "Shop Sale")'
            maxLength={35}
          />
        </div>
        <div className={styles.linkUrlInput}>
          <Label>Link URL</Label>
          <Input
            type="text"
            name="url"
            value={url}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setUrl(e.target.value)
            }}
            placeholder="Your website or URL"
          />
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        <Button variant="tertiary" onClick={(): void => onCancelClick(item)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={text?.trim() === '' || !isValidURL(url || '')}
          onClick={(): void =>
            onSaveClick({
              ...item,
              text,
              url,
            })
          }
        >
          Save Link
        </Button>
      </div>
    </>
  )
}

export default EditingLinkForm
