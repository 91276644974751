import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import type { CommentStatus } from '~publish/gql/graphql'
import { useQueryParam } from '~publish/hooks/useQueryParam'

export type FilterStatus = CommentStatus | 'all'
export type SortDirection = 'asc' | 'desc'
export type DateRange =
  | 'last7days'
  | 'last30days'
  | 'last6months'
  | 'lastyear'
  | 'alltime'

export type BaseCommentsFilters = {
  status?: FilterStatus
  sortDirection?: 'asc' | 'desc'
  dateStart?: string
  dateEnd?: string
}

type Filters = BaseCommentsFilters & {
  publishedAt: DateRange | undefined
}

type FilterSetters = {
  setStatus: (status: FilterStatus) => void
  setSortDirection: (direction: SortDirection | undefined) => void
  setPublishedAt: (publishedAt: DateRange | undefined) => void
  clearFilters: (options?: {
    includeStatus?: boolean
    additionalParams?: Record<string, string | null>
  }) => void
  updateUrlParams: (updates: Record<string, string | null>) => void
  clearFiltersAndUpdateTab: (tab: string) => void
}

const DATE_RANGES: Record<DateRange, number | null> = {
  last7days: 7,
  last30days: 30,
  last6months: 6 * 30,
  lastyear: 365,
  alltime: null,
}

export const DEFAULT_STATUS = 'unreplied'

export const useFilters = (): [Filters, FilterSetters] => {
  const location = useLocation()
  const history = useHistory()
  const [status, setStatus] =
    useQueryParam<FilterStatus>('status') || DEFAULT_STATUS
  const [sortDirection, setSortDirection] = useQueryParam<SortDirection>('sort')
  const [publishedAt, setPublishedAt] = useQueryParam<DateRange>('publishedAt')
  const [dateStart, setDateStart] = React.useState<string | undefined>()
  const [dateEnd, setDateEnd] = React.useState<string | undefined>()

  // Memoize the date range calculation to avoid unnecessary recalculations
  React.useEffect(() => {
    if (publishedAt && publishedAt !== 'alltime') {
      const days = DATE_RANGES[publishedAt]
      if (days) {
        const startDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000)
        setDateStart(startDate.toISOString())
        setDateEnd(new Date().toISOString())
      }
    } else {
      setDateStart(undefined)
      setDateEnd(undefined)
    }
  }, [publishedAt])

  const updateUrlParams = React.useCallback(
    (updates: Record<string, string | null>) => {
      const searchParams = new URLSearchParams(location.search)
      Object.entries(updates).forEach(([key, value]) => {
        if (value === null) {
          searchParams.delete(key)
        } else {
          searchParams.set(key, value)
        }
      })
      const newSearch = searchParams.toString()
      const newLocation = {
        ...location,
        search: newSearch ? `?${newSearch}` : '',
      }

      // Use replace to avoid adding to history stack
      history.replace(newLocation)
    },
    [location, history],
  )

  const clearFilters = React.useCallback(
    (
      options: {
        includeStatus?: boolean
        additionalParams?: Record<string, string | null>
      } = { includeStatus: false },
    ): void => {
      const updates: Record<string, string | null> = {
        ...options.additionalParams,
      }
      if (options.includeStatus) {
        updates.status = null
      }
      updates.sort = null
      updates.publishedAt = null
      updateUrlParams(updates)
    },
    [updateUrlParams],
  )

  const clearFiltersAndUpdateTab = React.useCallback(
    (newTab: string): void => {
      clearFilters({
        includeStatus: newTab === 'overview',
        additionalParams: { tab: newTab },
      })
    },
    [clearFilters],
  )

  // Memoize the filter state to prevent unnecessary re-renders
  const filters: Filters = React.useMemo(
    () => ({
      status: status || DEFAULT_STATUS,
      sortDirection,
      publishedAt,
      dateStart,
      dateEnd,
    }),
    [status, sortDirection, publishedAt, dateStart, dateEnd],
  )

  // Memoize the setters to maintain referential stability
  const setters = React.useMemo(
    () => ({
      setStatus,
      setSortDirection,
      setPublishedAt,
      clearFilters,
      updateUrlParams,
      clearFiltersAndUpdateTab,
    }),
    [
      setStatus,
      setSortDirection,
      setPublishedAt,
      clearFilters,
      updateUrlParams,
      clearFiltersAndUpdateTab,
    ],
  )

  return [filters, setters]
}
