import React, { forwardRef, useMemo } from 'react'
import {
  Button,
  ExternalLinkIcon,
  FileTextIcon,
  Flex,
  IconButton,
  IdeasIcon,
  Link,
  NewspaperIcon,
  Skeleton,
  SkeletonText,
  Text,
  EmptyState,
  CriticalIcon,
  CompassIcon,
  useIsSmallDesktopScreenSize,
  useIsOffline,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import { formatDistance } from 'date-fns'
import { useScraperData } from '~publish/hooks/resources/useScraperData'
import type { FeedItem as FeedItemType } from '../../hooks/useFeedItems'
import styles from './FeedArticleList.module.css'
import { isOfType } from '~publish/helpers/typeGuards'
import { graphql } from '~publish/graphql'
import { FeedArticleImageThumbnail } from './FeedArticleImageThumbnail'
import { FeedItemIcon } from '../Feed/FeedIcon'

export const FeedArticleCard_FeedItem = graphql(/* GraphQL */ `
  fragment FeedArticleCard_FeedItem on FeedItem {
    id
    title
    articleUrl
    contentTextPreview
    createdAt
    feed {
      id
      type
      connectionMetadata {
        __typename
        ... on RSSFeedConnectionMetadata {
          url
        }
      }
    }
  }
`)

export const FeedArticleCardSkeleton = (): JSX.Element => {
  const isSmallDesktopScreenSize = useIsSmallDesktopScreenSize()
  return (
    <Flex
      gap="xs"
      className={clsx(
        styles.articleLayout,
        isSmallDesktopScreenSize && styles.articleLayoutSmallDesktop,
      )}
      fullWidth
    >
      <Skeleton width={isSmallDesktopScreenSize ? '100%' : 200} height={140} />
      <Flex className={styles.articleDisplay} gap="xs" direction="column">
        <Flex gap="2xs" direction="column" fullWidth>
          <SkeletonText lines={2} />
          <SkeletonText lines={2} />
        </Flex>
        <Flex gap="2xs" justify="between" fullWidth>
          <SkeletonText lines={1} width={150} />
        </Flex>
      </Flex>
    </Flex>
  )
}

type FeedArticleLoadFailureProps = {
  message?: string
}

export const FeedArticleLoadFailure = ({
  message,
}: FeedArticleLoadFailureProps): JSX.Element => {
  const isOffline = useIsOffline()
  if (isOffline) {
    return (
      <EmptyState variant="neutral" size="large">
        <EmptyState.Icon>
          <CompassIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>You&apos;re offline</EmptyState.Heading>
        <EmptyState.Description>
          Feeds aren&apos;t available when you&apos;re offline. Please check
          your connection and try again.{' '}
          {message && <Text color="critical">{message}</Text>}
        </EmptyState.Description>
      </EmptyState>
    )
  }

  return (
    <EmptyState variant="critical" size="large">
      <EmptyState.Icon>
        <CriticalIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>We hit an error</EmptyState.Heading>
      <EmptyState.Description>
        Please let our team know about it, we&apos;ll fix so you can get back to
        your feeds. {message && <Text color="critical">{message}</Text>}
      </EmptyState.Description>
    </EmptyState>
  )
}

type FeedArticleCardProps = {
  item: FeedItemType
  handleCreatePost: (item: FeedItemType & { imageUrls: string[] }) => void
  handleCreateIdea: (item: FeedItemType & { imageUrls: string[] }) => void
  onArticleClick: (item: FeedItemType) => void
}

export const FeedArticleCard = forwardRef<HTMLDivElement, FeedArticleCardProps>(
  ({ item, handleCreatePost, handleCreateIdea, onArticleClick }, ref) => {
    const isSmallDesktopScreenSize = useIsSmallDesktopScreenSize()
    const feedUrl = React.useMemo(() => {
      if (isOfType(item.feed.connectionMetadata, 'RSSFeedConnectionMetadata')) {
        return item.feed.connectionMetadata.url
      }
    }, [item])
    const itemType = item.feed.type
    const rootUrl = useMemo(() => {
      return feedUrl ? new URL(feedUrl).origin : null
    }, [feedUrl])

    // Grab image from scraper cache
    const { data } = useScraperData(item.articleUrl)
    const imageUrls = data?.images?.[0]?.url ? [data.images[0].url] : []

    const onCreatePost = (): void => {
      handleCreatePost({
        ...item,
        imageUrls,
      })
    }

    const onCreateIdea = (): void => {
      handleCreateIdea({
        ...item,
        imageUrls,
      })
    }

    const handleArticleClick = React.useCallback((): void => {
      onArticleClick(item)
    }, [item, onArticleClick])

    const textPreviewFormatted = useMemo(() => {
      return item.contentTextPreview.replace(/\s+/g, ' ')
    }, [item])

    return (
      <article
        className={clsx(
          styles.articleLayout,
          isSmallDesktopScreenSize && styles.articleLayoutSmallDesktop,
        )}
        ref={ref}
      >
        <FeedArticleImageThumbnail
          title={item.title}
          articleUrl={item.articleUrl}
          onClick={handleArticleClick}
          href={item.articleUrl}
          target="_blank"
          rel="noreferrer noopener"
        />
        <Flex
          className={styles.articleDisplay}
          gap="xs"
          direction="column"
          fullWidth
        >
          <Flex gap="2xs" direction="column" fullWidth>
            <div className={styles.headerContainer}>
              <header className={styles.articleHeader}>
                <Link
                  className={styles.articleTitle}
                  color="inherit"
                  href={item.articleUrl}
                  external
                  onClick={handleArticleClick}
                >
                  <h3 className={styles.articleTitle}>{item.title}</h3>
                </Link>
                <Text size="sm">
                  <Flex gap="xs" align="center">
                    {rootUrl && (
                      <FeedItemIcon feedName={item.feed.name} url={rootUrl} />
                    )}
                    {!rootUrl && itemType === 'NewsSearch' && (
                      <NewspaperIcon
                        className={styles.feedNameFavicon}
                        color="subtle"
                        aria-hidden="true"
                      />
                    )}
                    <Text color="subtle" truncate>
                      {item.feed.name}
                    </Text>
                  </Flex>
                </Text>
              </header>
            </div>
            <Text className={styles.articleDescription} color="subtle">
              {textPreviewFormatted}
            </Text>
          </Flex>
          <Flex direction="row" justify="between" align="center" fullWidth>
            <Text
              as="time"
              color="subtle"
              dateTime={new Date(item.createdAt).toISOString()}
            >
              {formatDistance(new Date(item.createdAt), new Date(), {
                addSuffix: true,
              }).replace('about ', '')}
            </Text>
            <Flex gap="xs" align="center" className={styles.articleActions}>
              <IconButton
                tooltip="Open Link"
                label="Open Article Link"
                variant="secondary"
                as="a"
                href={item.articleUrl}
                target="_blank"
                rel="noreferrer noopener"
                onClick={handleArticleClick}
              >
                <ExternalLinkIcon aria-hidden="true" />
              </IconButton>
              <Button
                variant="secondary"
                onClick={onCreatePost}
                aria-label={
                  isSmallDesktopScreenSize
                    ? 'Create Post from Article'
                    : undefined
                }
              >
                <FileTextIcon aria-hidden="true" />
                {isSmallDesktopScreenSize ? '' : 'Create Post'}
              </Button>
              <Button
                variant="secondary"
                onClick={onCreateIdea}
                aria-label={
                  isSmallDesktopScreenSize
                    ? 'Save Article as an Idea'
                    : undefined
                }
              >
                <IdeasIcon aria-hidden="true" />
                {isSmallDesktopScreenSize ? '' : 'Save as Idea'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </article>
    )
  },
)

FeedArticleCard.displayName = 'FeedItem'
