import React from 'react'

import { parseTwitterLinks } from '~publish/legacy/utils/channels/twitter/helpers'
import * as Styles from './styles'

interface AttachmentRetweetData {
  avatarUrl: string
  userName: string
  userDisplayName: string
  text: string
}

const AttachmentRetweet = ({
  data,
}: {
  data: AttachmentRetweetData
}): JSX.Element => {
  const { avatarUrl, userName, userDisplayName, text } = data
  return (
    <Styles.Wrapper data-testid="twitter-preview-retweet">
      <Styles.UserWrapper>
        <Styles.Avatar src={avatarUrl} alt={`${userName}'s avatar`} />
        <Styles.DisplayName>{userDisplayName}</Styles.DisplayName>
        <Styles.Username>@{userName}</Styles.Username>
      </Styles.UserWrapper>
      <Styles.LinkifiedText
        links={parseTwitterLinks(text)}
        size="mini"
        whitespace="pre-wrap"
        newTab
      >
        {text}
      </Styles.LinkifiedText>
    </Styles.Wrapper>
  )
}

export { AttachmentRetweet }
