import { CheckCircleIcon, SimpleSpinner } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import React, { useCallback } from 'react'
import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useOrganizationId } from '~publish/legacy/accountContext'
import type { FeedItem as FeedItemType } from '../../hooks/useFeedItems'
import { FeedArticleCard } from './FeedArticleCard'
import styles from './FeedArticleList.module.css'

type FeedArticleListProps = {
  lastElementRef: (node: HTMLElement | null) => void
  items: FeedItemType[] | null
  fetchingMore: boolean
  feedCollectionId?: string
}

export const FeedArticleList = (props: FeedArticleListProps): JSX.Element => {
  const { lastElementRef, items, fetchingMore, feedCollectionId } = props
  const organizationId = useOrganizationId()
  const lastItemId = items?.at(-1)?.id

  const { createNewPostInComposer } = usePostComposer()
  const { createIdeaWithComposer } = useIdeaComposer()

  const handleCreatePost = useCallback(
    (item: FeedItemType & { imageUrls: string[] }): void => {
      createNewPostInComposer({
        cta: 'create-feedCollection-feedItem-createPost-1',
        channels: [],
        prefillPostData: {
          text: `${item.title}\n\n${item.articleUrl}`,
          imageUrls: item.imageUrls,
        },
      })

      BufferTracker.feedItemInteracted({
        product: 'publish',
        organizationId: organizationId ?? '',
        feedItemId: item.id,
        feedId: item.feed.id,
        feedCollectionId: feedCollectionId ?? '',
        interactionType: 'createPost',
        clientName: 'publishWeb',
        feedItemContentUrl: item.articleUrl,
      })
    },
    [createNewPostInComposer, organizationId, feedCollectionId],
  )

  const handleCreateIdea = useCallback(
    (item: FeedItemType & { imageUrls: string[] }): void => {
      createIdeaWithComposer({
        source: 'create-feedCollection-feedItem-createIdea-1',
        title: item.title,
        text: `${item.title}\n\n${item.contentTextPreview}\n\n${item.articleUrl}`,
        media: item.imageUrls,
      })

      BufferTracker.feedItemInteracted({
        product: 'publish',
        organizationId: organizationId ?? '',
        feedItemId: item.id,
        feedId: item.feed.id,
        feedCollectionId: feedCollectionId ?? '',
        interactionType: 'createIdea',
        clientName: 'publishWeb',
        feedItemContentUrl: item.articleUrl,
      })
    },
    [createIdeaWithComposer, organizationId, feedCollectionId],
  )

  const onArticleClick = useCallback(
    (item: FeedItemType): void => {
      BufferTracker.feedItemInteracted({
        product: 'publish',
        organizationId: organizationId ?? '',
        feedItemId: item.id,
        feedId: item.feed.id,
        feedCollectionId: feedCollectionId ?? '',
        interactionType: 'viewArticle',
        clientName: 'publishWeb',
        feedItemContentUrl: item.articleUrl,
      })
    },
    [organizationId, feedCollectionId],
  )

  return (
    <>
      <div
        className={styles.contentList}
        role="feed"
        aria-label="Feed articles list"
      >
        {items?.map((item, index) => (
          // Some feed items seem to be included twice, possibly from
          // different feeds/searches. Index helps prevent duplicate key
          <FeedArticleCard
            key={`${item.id}-${index}`}
            ref={item.id === lastItemId ? lastElementRef : null}
            item={item}
            handleCreatePost={handleCreatePost}
            handleCreateIdea={handleCreateIdea}
            onArticleClick={onArticleClick}
          />
        ))}
      </div>
      {fetchingMore && (
        <div className={styles.caughtUp} aria-live="polite">
          <SimpleSpinner size="medium" aria-label="Loading more content" />
        </div>
      )}
      {!fetchingMore && (items?.length || 0) > 0 && (
        <div className={styles.caughtUp} aria-live="polite">
          <div className={styles.caughtUpBody}>
            <CheckCircleIcon aria-hidden="true" />
            <span>You’re all caught up!</span>
          </div>
        </div>
      )}
    </>
  )
}
