import React from 'react'

import {
  Button,
  ChevronDownIcon,
  Dialog,
  DropdownMenu,
  Flex,
  Checkbox,
} from '@buffer-mono/popcorn'
import styles from './Footer.module.css'

import type { IBlueskyAuthFooter } from '../../types'

export function Footer({
  loading,
  isRefreshingChannelConnection,
  blueskyData,
  setBlueskyData,
}: IBlueskyAuthFooter): JSX.Element {
  const notValidForSubmit = !blueskyData?.handle || !blueskyData?.appPassword

  return (
    <Dialog.Footer className={styles.footer}>
      <Flex
        align="center"
        justify="between"
        className={styles.actionsContainer}
      >
        <DropdownMenu className={styles.helpMenu}>
          <DropdownMenu.Trigger>
            <Button
              variant="tertiary"
              size="large"
              className={styles.helpButton}
            >
              Need Help
              <ChevronDownIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="start">
            <DropdownMenu.Item>
              <a
                className={styles.helpLink}
                href="https://support.buffer.com/article/855-using-bluesky-with-buffer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Using Bluesky with Buffer
              </a>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
        <Flex align="center" gap="sm">
          {!isRefreshingChannelConnection && (
            <Checkbox
              id="followbuffer"
              defaultChecked
              checked={blueskyData?.followBuffer}
              onChange={(checked): void => {
                setBlueskyData({
                  ...blueskyData,
                  followBuffer: checked === true,
                })
              }}
            >
              <strong>Follow us on Bluesky</strong>
            </Checkbox>
          )}
          <Button size="large" disabled={loading || notValidForSubmit}>
            {loading ? 'Connecting Channel' : 'Continue'}
          </Button>
        </Flex>
      </Flex>
    </Dialog.Footer>
  )
}
