import {
  comboboxActions,
  createPluginFactory,
  getEditorString,
} from '@udecode/plate'
import { SearchIndex } from 'emoji-mart'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import { EmojiSearchElementUI } from './components'
import { EmojiElement, EmojiSearchElement } from './nodes'

import type { EmojiPluginOptions, EmojiSearchData } from './types'
import { emojisToComboboxItems } from './utils'
import { withEmoji } from './withEmoji'

const handleEmojiSearch = (editor: BufferEditor) => () => {
  const currentEmojiSearchNode = EmojiSearchElement.findEmojiSearch(editor)
  if (currentEmojiSearchNode) {
    const nodeText = getEditorString(editor, currentEmojiSearchNode[1])
    const searchText = nodeText.startsWith(EmojiSearchElement.trigger)
      ? nodeText.slice(EmojiSearchElement.trigger.length)
      : nodeText
    if (!searchText) return

    SearchIndex.search(searchText).then(
      (results: EmojiSearchData[] | null | undefined): void => {
        if (results) {
          comboboxActions.items(emojisToComboboxItems(results))
        }
      },
    )
  }
}

/**
 * Enables support for autocompleting emojis.
 */
export const createEmojiSearchPlugin = createPluginFactory<
  EmojiPluginOptions,
  BufferValue
>({
  key: EmojiElement.type,
  isLeaf: true,
  withOverrides: withEmoji,
  options: {
    id: EmojiSearchElement.type,
    insertSpaceAfterEmoji: true,
    trigger: EmojiSearchElement.trigger,
  },
  plugins: [
    {
      key: EmojiSearchElement.type,
      isElement: true,
      isInline: true,
      component: EmojiSearchElementUI,
      handlers: {
        onChange: handleEmojiSearch,
      },
    },
  ],
})
