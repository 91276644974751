import React from 'react'
import {
  Flex,
  Heading,
  Sidebar,
  Separator,
  Form,
  Switch,
  Text,
  Button,
  Label,
  Skeleton,
} from '@buffer-mono/popcorn'

import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import type { BetaFeature } from '~publish/gql/graphql'
import { useAccount } from '~publish/legacy/accountContext'

import { useBetaFeatures } from './hooks/useBetaFeatures'
import { useUpdateBetaFeaturesEnrolment } from './hooks/useUpdateBetaFeaturesEnrolment'
import SettingsPageLayout from './components/SettingsPageLayout'
import BetaFeaturesSkeleton from './components/BetaFeaturesSkeleton'
import { getHelloNextLoginServiceUrl } from './util/helloNextLoginServiceUrl'

import style from './Settings.module.css'

const EnableBetaFeatures = ({
  isBetaEnabled,
  organizationId,
}: {
  isBetaEnabled?: boolean | null
  organizationId: string
}): JSX.Element => {
  const { enableBetaFeatures, disableBetaFeatures } =
    useUpdateBetaFeaturesEnrolment()

  /**
   * We use an 'inner state' to manage the switch state. This is because the
   * switch state should be updated immediately while the API call is being
   * made. Othersise, the UI feels unresponsive.
   */
  const [betaEnabledInner, setBetaEnabledInner] = React.useState(
    !!isBetaEnabled,
  )

  const handleSwitchChange = (checked: boolean): void => {
    if (checked) {
      setBetaEnabledInner(true)
      enableBetaFeatures({ organizationId })
    } else {
      setBetaEnabledInner(false)
      disableBetaFeatures({ organizationId })
    }
  }

  return (
    <Flex gap="xs" direction="row" justify="between" fullWidth>
      <Flex gap="sm" direction="column">
        <Heading size="small">Enable Beta features:</Heading>
        <Text color="subtle">
          Try new Buffer features early and Share feedback with us to help
          improve Buffer!
        </Text>
      </Flex>
      <Form style={{ justifySelf: 'flex-start' }}>
        <Skeleton show={isBetaEnabled === null || isBetaEnabled === undefined}>
          <Form.Field name="betaFeatures">
            <Form.Control>
              <Switch
                checked={betaEnabledInner}
                onCheckedChange={handleSwitchChange}
              />
            </Form.Control>
          </Form.Field>
        </Skeleton>
      </Form>
    </Flex>
  )
}

export const BetaFeatures = ({
  isMobile,
  handleToggleSidebar,
}: {
  isMobile: boolean
  handleToggleSidebar: () => void
}): JSX.Element => {
  const { account } = useAccount()
  const { betaFeatures } = useBetaFeatures()
  const isBetaFeatureEnabled = account?.currentOrganization?.isBetaEnabled

  const hasVerifiedEmail = Boolean(account?.hasVerifiedEmail)

  const handleShareFeedback = (feature: BetaFeature): void => {
    window.open(
      hasVerifiedEmail
        ? `${getHelloNextLoginServiceUrl()}&redirect=${feature.feedbackUrl}`
        : `${feature.feedbackUrl}`,
      '_blank',
    )
  }

  const handleJoinDiscord = (): void => {
    BufferTracker.joinCommunityButtonClicked({
      clientName: 'core',
      product: 'Publish',
      organizationId: account?.currentOrganization?.id,
    })
    window.open('https://discord.com/invite/aQdKKr6kDY', '_blank')
  }

  const handleCheckRoadmap = (): void => {
    window.open('https://suggestions.buffer.com/roadmap', '_blank')
  }

  return (
    <SettingsPageLayout>
      <Flex
        gap="lg"
        direction="column"
        style={{ paddingBottom: 'var(--space-4xl)' }}
      >
        <section className={style.fullWidth}>
          <Flex gap="sm" direction="row" align="center">
            {isMobile && <Sidebar.Trigger onClick={handleToggleSidebar} />}
            <Heading as="h1" size="large">
              Beta Features
            </Heading>
          </Flex>
        </section>

        <section className={style.fullWidth}>
          <Flex gap="xs" direction="column" fullWidth>
            <EnableBetaFeatures
              isBetaEnabled={isBetaFeatureEnabled}
              organizationId={account?.currentOrganization?.id}
            />
            <Separator />

            <Flex gap="lg" direction="column" fullWidth>
              <Flex gap="xs" direction="column" fullWidth>
                <Heading size="medium">Current Beta features</Heading>
                <Text color="subtle">
                  {isBetaFeatureEnabled
                    ? 'Here are the features in Beta, which you may spot as you use Buffer.'
                    : 'Join our Beta program above to access these current Beta features.'}
                </Text>
              </Flex>
              <Flex gap="xl" justify="between" direction="column" fullWidth>
                {betaFeatures && betaFeatures?.length > 0 ? (
                  betaFeatures?.map((feature) => (
                    <Flex
                      gap="2xs"
                      key={feature.name}
                      direction="column"
                      fullWidth
                    >
                      <Label
                        style={{
                          color: isBetaFeatureEnabled
                            ? 'var(--color-text-neutral)'
                            : 'var(--color-text-subtle)',
                        }}
                        size="medium"
                      >
                        {feature.name}
                      </Label>
                      <Flex gap="sm" direction="row" fullWidth>
                        <Text color="subtle">{feature.description}</Text>
                        {feature?.feedbackUrl && (
                          <Button
                            variant="secondary"
                            size="medium"
                            style={{ justifySelf: 'flex-end' }}
                            disabled={!isBetaFeatureEnabled}
                            onClick={(): void => handleShareFeedback(feature)}
                          >
                            Share Feedback
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  ))
                ) : (
                  <BetaFeaturesSkeleton betaFeaturesCount={0} />
                )}
              </Flex>
            </Flex>
            {isBetaFeatureEnabled && (
              <>
                <Separator />
                <Flex gap="lg" direction="column" fullWidth>
                  <Heading as="h2" size="small">
                    Beta Program!
                  </Heading>
                  <Flex gap="xl" direction="column" fullWidth>
                    <Flex gap="xl" direction="row" fullWidth align="center">
                      <Flex gap="2xs" direction="column">
                        <Label>Discord community</Label>
                        <Text color="subtle">
                          Join our Discord community to connect with the Buffer
                          team and other Buffer Beta users.
                        </Text>
                      </Flex>
                      <Button
                        variant="secondary"
                        size="medium"
                        onClick={handleJoinDiscord}
                      >
                        Join us on Discord
                      </Button>
                    </Flex>
                    <Flex gap="xl" fullWidth align="center">
                      <Flex gap="2xs" direction="column" fullWidth>
                        <Label>Our Roadmap</Label>
                        <Text color="subtle">
                          As you try out our Beta features, we&apos;d love to
                          hear your feedback and get your help in shaping
                          Buffer.
                        </Text>
                      </Flex>
                      <Button
                        variant="secondary"
                        size="medium"
                        onClick={handleCheckRoadmap}
                      >
                        Check Our roadmap
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        </section>
      </Flex>
    </SettingsPageLayout>
  )
}

export default BetaFeatures
