import React, { useCallback, useMemo, useState } from 'react'
import { DropdownMenu, StatusDot } from '@buffer-mono/popcorn'

import styles from './HelpMenu.module.css'
import { useSplitEnabled } from '@buffer-mono/features'

type HandlersMap = {
  [key: string]: (e: Event) => void
}
import {
  useGetToggleLauncher,
  useHasSeenLauncherInHelpMenu,
} from '../../../Launcher/hooks'
import { useUser } from '../../../../common/context/User'
import { useMutation } from '@apollo/client'
import { DISMISS_OBJECT } from '../../../../common/graphql/account'
import { LAUNCHER_IN_HELP_OBJECT_ID } from '../../../Launcher'
import { getHelpDropdownItems } from '../../utils/dropdown-items'
type HelpMenuProps = {
  children: React.ReactNode
}

const HelpMenu = ({ children }: HelpMenuProps): JSX.Element => {
  /* This is a PERMANENT split used by Advocacy for volume Spikes when they need to "hide" the Beacon icon. */
  const { isEnabled: showBeaconInHelpMenu } = useSplitEnabled(
    'move-beacon-to-help-menu',
  )
  const user = useUser()
  const launcherIsANewItemInHelpMenu = useHasSeenLauncherInHelpMenu()
  const [dismissObject, { called: dismissedNewLabel }] =
    useMutation(DISMISS_OBJECT)

  const [helpMenuDropdownWasOpenedBefore, setHelpMenuDropdownWasOpened] =
    useState(false)
  const [quickNavigatorHasNewLabel, setQuickNavigatorNewLabel] = useState(false)

  const toggleLauncher = useGetToggleLauncher()
  const items = getHelpDropdownItems({
    hasVerifiedEmail: user.hasVerifiedEmail ?? false,
    toggleLauncher,
    launcherIsANewItemInHelpMenu: quickNavigatorHasNewLabel,
    showBeaconInHelpMenu,
  })

  async function hideNewTag(): Promise<void> {
    if (launcherIsANewItemInHelpMenu && !dismissedNewLabel) {
      await dismissObject({
        variables: {
          organizationId: user.currentOrganization?.id,
          dismissedObject: {
            id: LAUNCHER_IN_HELP_OBJECT_ID,
          },
        },
      })
      setHelpMenuDropdownWasOpened(true)
    } else if (helpMenuDropdownWasOpenedBefore) {
      // remove *new* label from quick navigator in help menu
      setQuickNavigatorNewLabel(false)
    }
  }

  const handleOpenChange = (open: boolean): void => {
    if (open) {
      hideNewTag()
    }
  }

  /* using useCallback and useMemo to avoid unnecessary re-renders */
  const handleItemClick = useCallback(
    async (e: Event, onItemClick?: () => void) => {
      e.preventDefault()
      if (onItemClick) onItemClick()
    },
    [],
  )

  const itemClickHandlers = useMemo(() => {
    return items.reduce<HandlersMap>((acc, item) => {
      acc[item.id] = (e: Event): Promise<void> =>
        handleItemClick(e, item?.onItemClick)
      return acc
    }, {})
  }, [items, handleItemClick])

  return (
    <DropdownMenu modal={false} onOpenChange={handleOpenChange}>
      <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {items.map((item) =>
          item.subItems ? (
            <DropdownMenu.Sub key={item.id}>
              <DropdownMenu.SubTrigger>{item.title}</DropdownMenu.SubTrigger>
              <DropdownMenu.SubMenu>
                {item.subItems.map((subItem) => (
                  <DropdownMenu.Item
                    key={subItem.id}
                    id={subItem.id}
                    onSelect={itemClickHandlers[subItem.id]}
                    shortcut={subItem?.shortcuts?.join(' ').toUpperCase()}
                    asChild
                  >
                    <a href={subItem.url} target="_blank" rel="noreferrer">
                      {subItem.title}
                      {subItem.new && (
                        <StatusDot
                          className={styles.statusDotInMenuItem}
                          data-testid="help-menu-item-new-badge"
                        />
                      )}
                    </a>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.SubMenu>
            </DropdownMenu.Sub>
          ) : (
            <DropdownMenu.Item
              key={item.id}
              id={item.id}
              onSelect={itemClickHandlers[item.id]}
              shortcut={item?.shortcuts?.join(' ').toUpperCase()}
              asChild
            >
              <a href={item.url} target="_blank" rel="noreferrer">
                {item.title}
                {item.new && (
                  <StatusDot
                    className={styles.statusDotInMenuItem}
                    data-testid="help-menu-item-new-badge"
                  />
                )}
              </a>
            </DropdownMenu.Item>
          ),
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

export default HelpMenu
