import React from 'react'

import { Day } from './Day'
import type { CalendarBlockData } from './types'

import styles from './Month.module.css'

type MonthProps = {
  weeks: CalendarBlockData[][]
}

const Month: React.FC<MonthProps> = ({ weeks }) => {
  return (
    <>
      {weeks.map((week) => (
        <tr
          key={week[0].start.weekOfYear}
          className={styles.row}
          data-testid={`monthly-row-${week[0].start.weekOfYear}`}
          style={{ '--number-weeks': weeks.length } as React.CSSProperties}
        >
          {week.map((day) => (
            <Day key={day.start.toString()} day={day} />
          ))}
        </tr>
      ))}
    </>
  )
}

Month.displayName = 'Month'

const memoMonth = React.memo(Month)
export { memoMonth as Month }
