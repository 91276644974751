import React, { useState } from 'react'
import LinksHeader from './LinksHeader'
import EditingLinkForm from './EditingLinkForm'
import LinkDragWrapper from './LinkDragWrapper'

import styles from './styles.module.css'
import { DEFAULT_COLOR, DEFAULT_CONTRAST_COLOR } from '../../util/index'
import type {
  CustomLink,
  CustomLinksDetails,
} from '~publish/legacy/profile-sidebar/types'

interface EditingLinkItemProps {
  customLinkItem: CustomLink
  onUpdateCustomLinks: (item: CustomLink) => void
  onCancelCustomLinkEdit: (item: CustomLink) => void
}

const EditingLinkItem = ({
  customLinkItem,
  onUpdateCustomLinks,
  onCancelCustomLinkEdit,
}: EditingLinkItemProps): JSX.Element => {
  return (
    <EditingLinkForm
      item={customLinkItem}
      onCancelClick={onCancelCustomLinkEdit}
      onSaveClick={onUpdateCustomLinks}
    />
  )
}

interface CustomLinksProps {
  customLinksDetails: CustomLinksDetails
  onUpdateCustomLinksColor: (colors: {
    customLinkColor: string
    customLinkContrastColor: string
  }) => void
  onDeleteCustomLink: (itemId: string) => void
  maxCustomLinks: number
  onToggleEditMode: (item: CustomLink, editing: boolean) => void
  onSwapCustomLinks: (from: CustomLink, to: CustomLink) => void
  onSaveNewLinkClick: (item: CustomLink) => void
  onUpdateSingleCustomLink: (item: CustomLink) => void
  hasWriteAccess: boolean
}

const CustomLinks = ({
  customLinksDetails,
  onUpdateCustomLinksColor,
  onDeleteCustomLink,
  maxCustomLinks,
  onToggleEditMode,
  onSwapCustomLinks,
  onSaveNewLinkClick,
  onUpdateSingleCustomLink,
  hasWriteAccess,
}: CustomLinksProps): JSX.Element => {
  const [colorButtons, setColorButton] = useState(
    customLinksDetails.buttonColor || DEFAULT_COLOR,
  )
  const [textColor, setTextColor] = useState(
    customLinksDetails.buttonContrastColor || DEFAULT_CONTRAST_COLOR,
  )
  const [newLinks, addNewLink] = useState<CustomLink[]>([])

  const onCancelClick = (item: CustomLink): void => {
    addNewLink(
      newLinks.filter((currentItem) => currentItem.order !== item.order),
    )
  }

  const totalLinks =
    newLinks.length + (customLinksDetails.customLinks?.length || 0)

  const onAddLinkClick = (): void => {
    addNewLink([
      ...newLinks,
      {
        _id: `${Date.now()}`,
        id: `${Date.now()}`,
        text: '',
        url: '',
        order: Math.max(0, ...newLinks.map((l) => l.order || 0)) + 1,
      },
    ])
  }
  const { customLinks = [] } = customLinksDetails

  return (
    <div className={styles.myLinksSection}>
      <LinksHeader
        maxCustomLinks={maxCustomLinks}
        onAddLinkClick={onAddLinkClick}
        setColorButton={setColorButton}
        setTextColor={setTextColor}
        colorButtons={colorButtons}
        textColor={textColor}
        onUpdateCustomLinksColor={onUpdateCustomLinksColor}
        addLinkDisabled={totalLinks >= maxCustomLinks}
        hasWriteAccess={hasWriteAccess}
      />
      <div className={styles.myLinksBody}>
        {customLinks?.map((customLinkItem) => {
          return (
            <React.Fragment key={`link_${customLinkItem.id}`}>
              {!customLinkItem.editing && (
                <LinkDragWrapper
                  item={customLinkItem}
                  textColor={textColor}
                  bgColor={colorButtons}
                  onSwapCustomLinks={onSwapCustomLinks}
                  onToggleEditMode={onToggleEditMode}
                  onDeleteCustomLink={onDeleteCustomLink}
                  hasWriteAccess={hasWriteAccess}
                />
              )}
              {customLinkItem.editing && (
                <EditingLinkItem
                  key={customLinkItem.order}
                  customLinkItem={customLinkItem}
                  onUpdateCustomLinks={onUpdateSingleCustomLink}
                  onCancelCustomLinkEdit={() => {
                    onToggleEditMode(customLinkItem, false)
                  }}
                />
              )}
            </React.Fragment>
          )
        })}

        {newLinks.map((newLinkItem) => {
          return (
            <EditingLinkForm
              key={newLinkItem.order}
              item={newLinkItem}
              onSaveClick={(item: CustomLink): void => {
                onSaveNewLinkClick(item)
                onCancelClick(item)
              }}
              onCancelClick={onCancelClick}
            />
          )
        })}
      </div>
    </div>
  )
}

export default CustomLinks
