import React, { forwardRef, useState } from 'react'
import clsx from 'clsx'
import { useDroppable } from '@dnd-kit/core'
import { Flex, Skeleton, Text, useMergeRefs } from '@buffer-mono/popcorn'

import { format } from '~publish/helpers/temporal'

import { AddButton } from './AddButton'
import { DraggableItem } from './DraggableItem'
import { useCalendarContext } from './context'
import { Item } from './Item'
import ShowMoreLessButtons from './ShowMoreLessButtons'
import type { CalendarBlockData } from './types'
import { DroppableItem } from './DroppableItem'
import { DraggableAndDroppableItem } from './DraggableAndDroppableItem'

import styles from './Hour.module.css'
import { useTimezonedNow } from './hooks/useTimezonedNow'

const HOUR_FORMAT = {
  '24': 'H:00',
  '12': 'h a',
}
const DEFAULT_ITEMS_LIMIT = 3
const FIVE_MINUTES_MS = 300000

type HourProps = {
  hour: CalendarBlockData
  showLabel: boolean
  loading: boolean
  index: number
}

const Hour = forwardRef<React.ElementRef<'td'>, HourProps>(
  ({ hour, loading, showLabel = false, index }, forwardedRef): JSX.Element => {
    const { items, is24HourFormat, timezone } = useCalendarContext()
    const [limit, setLimit] = useState(DEFAULT_ITEMS_LIMIT)
    const { now, nowTimestamp } = useTimezonedNow(timezone)

    // Disable drop if the hour is in the past and not an skipped DST hour
    const dropDisabled =
      hour.startTimestamp < nowTimestamp && !hour.isDSTSkippedHour
    const hourLabel = format(
      hour.start,
      HOUR_FORMAT[is24HourFormat ? '24' : '12'],
    )
    const addButtonLabel = `${format(
      hour.start,
      'eeee, d MMMM y',
    )} at ${hourLabel}`

    const itemsToRender = items
      ?.filter((item) => {
        return (
          item.timestamp >= hour.startTimestamp &&
          item.timestamp <= hour.endTimestamp
        )
      })
      .sort((a, b) => a.timestamp - b.timestamp)

    const showHourLabel = showLabel && index % 2 === 0

    const { setNodeRef, isOver, active } = useDroppable({
      id: hour.startTimestamp ?? '',
      disabled: dropDisabled,
      data: {
        type: 'hour',
        timestamp: hour.startTimestamp,
      },
    })

    const mergedRef = useMergeRefs(forwardedRef, setNodeRef)

    // Show add button if the hour is not disabled, not loading and not a skipped DST hour
    const showAddButton = !dropDisabled && !loading && !hour.isDSTSkippedHour

    return (
      <td
        className={clsx(styles.hour, {
          [styles.disabled]: dropDisabled,
          [styles.draggingOver]: isOver,
          [styles.dragHappening]: Boolean(active),
          [styles.dst]: hour.isDSTSkippedHour,
        })}
        ref={dropDisabled ? forwardedRef : mergedRef}
        data-datetime={hour.start.toString()}
        data-index={index}
        data-hour-label={
          showHourLabel ? getHourLabel(index % 24, is24HourFormat) : ''
        }
      >
        {hour.isDSTSkippedHour && (
          <div className={styles.dstOverlay}>
            <Text size="sm" color="subtle">
              Daylight Saving Time (skipped hour)
            </Text>
          </div>
        )}
        {now &&
          !hour.isDSTSkippedHour &&
          hour.startTimestamp &&
          itemsToRender?.slice(0, limit).map((item, index) => {
            if (item.draggable && item.droppable) {
              return (
                <DraggableAndDroppableItem
                  key={item.id}
                  id={item.id}
                  index={index}
                  timestamp={item.timestamp}
                  type={item.type}
                >
                  <Item {...item} />
                </DraggableAndDroppableItem>
              )
            }
            if (item.draggable) {
              return (
                <DraggableItem
                  key={item.id}
                  id={item.id}
                  index={index}
                  timestamp={item.timestamp}
                  type={item.type}
                  className={clsx(styles.item, {
                    [styles.activeItem]: active?.id === item.id,
                  })}
                  disabled={
                    item.draggable === undefined
                      ? item.timestamp < now.epochSeconds
                      : !item.draggable
                  }
                >
                  <Item {...item} />
                </DraggableItem>
              )
            }
            if (item.droppable) {
              return (
                <DroppableItem
                  key={item.id}
                  id={item.id}
                  index={index}
                  timestamp={item.timestamp}
                  type={item.type}
                >
                  <Item {...item} />
                </DroppableItem>
              )
            }
            return <Item {...item} key={item.id} />
          })}
        {loading && !itemsToRender && <Skeleton height={'58px'} />}

        <Flex gap="xs" justify="between">
          <ShowMoreLessButtons
            itemsToRender={itemsToRender ?? []}
            limit={limit}
            setLimit={setLimit}
          />
          {showAddButton && hour.creationTimestamp && (
            <AddButton
              timestamp={
                hour.creationTimestamp < nowTimestamp
                  ? nowTimestamp + FIVE_MINUTES_MS
                  : hour.creationTimestamp
              }
              labelDate={addButtonLabel}
              className={clsx(styles.addButton, styles.newAddButton)}
            />
          )}
        </Flex>
      </td>
    )
  },
)

Hour.displayName = 'Hour'

const memoHour = React.memo(Hour)
export { memoHour as Hour }

function getHourLabel(
  hour: number,
  is24HourFormat: boolean,
): `${number}${number}` | `${number} AM` | `${number} PM` {
  if (is24HourFormat) {
    return `${hour.toString().padStart(2, '0')}` as `${number}${number}`
  }
  return `${hour % 12 === 0 ? 12 : hour % 12} ${hour < 12 ? 'AM' : 'PM'}` as
    | `${number} AM`
    | `${number} PM`
}
