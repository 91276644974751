import React, { useState, useRef } from 'react'

import { Text, Button, Popover } from '@buffer-mono/popcorn'
import styles from './styles.module.css'

import ColorSelectorPopup from './components/ColorSelectorPopup'
import { getColorContrast, getColors } from './utils/HexValidations'

export const colorSwatches = {
  black: '#000000',
  white: '#FFFFFF',
  darkPurple: '#3A619C',
  purple: '#979BC8',
  lightBlue: '#0098DB',
  aqua: '#2AB4B4',
  yellow: '#FCE86A',
  brown: '#AF847A',
  orange: '#E7764C',
  red: '#E94D41',
} as const

export const DEFAULT_COLOR = colorSwatches.black

const ColorPicker = ({
  onChange,
  label,
  defaultColor,
  onBlur,
}: {
  onChange: (color: string, contrastColor: string) => void
  label: string
  defaultColor: string
  onBlur: (color: string, contrastColor: string) => void
}): JSX.Element => {
  const [color, setColor] = useState(defaultColor || DEFAULT_COLOR)
  const [isValidHex, setIsValidHex] = useState(true)

  const targetRef = useRef<HTMLDivElement>(null)

  return (
    <div
      className={styles.colorPickerWrapper}
      style={
        {
          '--color': color,
          '--contrast-color': getColorContrast(color),
        } as React.CSSProperties
      }
    >
      <Text as="label">{label}</Text>
      <div className={styles.colorSelectorWrapper} ref={targetRef}>
        <Popover
          onOpenChange={(open: boolean): void => {
            if (!open) {
              setColor(color)
              onBlur(color, getColorContrast(color))
            }
          }}
        >
          <Popover.Trigger>
            <Button className={styles.colorButton} variant="secondary">
              <div className={styles.colorPreview} /> {color}
            </Button>
          </Popover.Trigger>
          <Popover.Content>
            <ColorSelectorPopup
              onColorChange={(newColor): void => {
                const { color, contrastColor } = getColors(newColor)

                setColor(color)
                onChange(color, contrastColor)
              }}
              colorSelected={color}
              isValidHex={isValidHex}
              setIsValidHex={setIsValidHex}
              onBlur={(): void => {
                if (onBlur) {
                  onBlur(color, getColorContrast(color))
                }
              }}
            />
          </Popover.Content>
        </Popover>
      </div>
    </div>
  )
}

export default ColorPicker
