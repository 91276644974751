import React from 'react'
import { ComposerSidepanel } from '~publish/legacy/shared-components'
import { PostPreview } from '../post-preview/PostPreview'
import type {
  DraftPreview,
  ProfilePreview,
} from '../post-preview/previews/types'

export const PostPreviewSidepanel = ({
  isVisible,
  draftPreview,
  profilePreview,
}: {
  isVisible: boolean
  draftPreview: DraftPreview
  profilePreview: ProfilePreview
}): JSX.Element => (
  <ComposerSidepanel customStyles={{ height: '100%' }} isVisible={isVisible}>
    <PostPreview draftPreview={draftPreview} profilePreview={profilePreview} />
  </ComposerSidepanel>
)
