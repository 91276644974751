import React from 'react'

export const ConfettiBackground: React.FC<{
  className?: string
  height?: number
  width?: number
}> = ({ className, height = 57, width = 735 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      className={className}
    >
      <g clipPath="url(#a)">
        <path
          fill="#2AFF28"
          d="M52.223 5.169c1.667 0 3.02 1.341 3.02 2.993 0 1.653-1.353 2.994-3.02 2.994s-3.02-1.341-3.02-2.994c0-1.652 1.353-2.993 3.02-2.993Z"
        />
        <path
          fill="#EFC905"
          d="m613.216 45.508-3.966-5.309 2.181-12.781 3.966 5.307-2.181 12.782ZM142.269 47.138l-5.386-3.588 2.962-8.638 5.386 3.587-2.962 8.639Z"
        />
        <path
          fill="#2AB6FF"
          d="m214.897-1.615 6.319 3.175h-12.638l6.319-3.175ZM94.54 44.844l4.775 3.965h-9.55l4.775-3.965Z"
        />
        <path
          fill="#EF0583"
          d="M651.727 19.03c-.112 2.252-1.582 4.046-3.282 4.004-1.699-.042-2.988-1.903-2.876-4.155.112-2.251 1.582-4.045 3.282-4.003 1.699.041 2.988 1.903 2.876 4.154Z"
        />
        <path
          fill="#2AFF28"
          d="m113.526 17.586 6.923-3.965 16.063 2.668-6.922 3.964-16.064-2.667Z"
        />
        <path
          fill="#EFC905"
          d="m207.457 3.172 5.173-3.596 11.909 2.34-5.172 3.596-11.91-2.34Z"
        />
        <path
          fill="#2AB6FF"
          d="m285.531 54.92-5.277 2.246.236-4.746 5.041 2.5Z"
        />
        <path
          fill="#2AFF28"
          d="m71.427 42.988-9.683 1.87.209-4.21 9.474 2.34ZM528.394.894l-6.683 4.799.493-9.913 6.19 5.114Z"
        />
        <path
          stroke="#78FF1D"
          strokeWidth="3.043"
          d="M572.461 37.542c2.373.246 4.994.792 7.922 1.7"
        />
        <path
          stroke="#FFE41D"
          strokeWidth="7.804"
          d="M567.318 10.14c-.143-3.223-.046-6.354.807-9.161"
        />
        <path
          fill="#5E68C2"
          d="m450.135 16.233 4.139 7.83 11.711-.239-4.09-7.577-11.76-.014Z"
        />
        <path
          fill="#EAECFF"
          d="m604.766 56.905 4.943 7.596 11.655-.84-4.868-7.346-11.73.59ZM664.37-.11l-9.723 4.802 3.303 7.9 9.387-4.704L664.37-.11Z"
        />
        <path
          fill="#FF3363"
          d="m364.293 58.004.901 8.328 11.349 2.045-.953-8.076-11.297-2.297Z"
        />
        <path
          fill="#EAECFF"
          d="m209.721 44.979 2.649 8.142 11.655.839-2.648-7.888-11.656-1.093Z"
        />
        <path
          fill="#FB0"
          d="m265.44 10.072 11.371-5.33-3.481-9.147L262.349.818l3.091 9.254Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h735v57H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
