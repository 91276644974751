import type { Account } from '~publish/legacy/accountContext/types'

export default function isOnNonAgencyTieredPricing(
  account: Account | null,
): boolean {
  if (
    !account ||
    !account.currentOrganization ||
    !account.currentOrganization.billing ||
    !account.currentOrganization.billing.pricingTiers
  ) {
    return false
  }
  // Non-agency tiered plans are the only ones with more than 2 pricing tiers
  return account.currentOrganization.billing.pricingTiers.length > 2
}
