import type { Account } from '~publish/legacy/accountContext/types'

export function isRevenueCatBillingGateway(account: Account): boolean {
  const currentOrganization = account?.currentOrganization
  const billingGateway = currentOrganization?.billing?.gateway?.gatewayType
  return billingGateway === 'revenuecat'
}

export function isPaymentMethodBank(account: Account): boolean {
  return (
    account?.currentOrganization?.billing?.paymentDetails?.paymentMethod ===
      'bank' || false
  )
}
