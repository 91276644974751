import { useMemo } from 'react'
import { useCurrentOrganization } from '~publish/legacy/accountContext'
import type { CurrentOrganization } from '~publish/legacy/accountContext/types'

export function useCommonTrackingProperties(): Partial<
  Pick<
    CurrentOrganization['commonTrackingProperties'],
    | 'atEventBillingState'
    | 'atEventBillingPlan'
    | 'atEventBillingCycle'
    | 'atEventBillingGateway'
    | 'atEventIsNewBuffer'
    | 'atEventChannelQty'
  >
> {
  const currentOrganization = useCurrentOrganization()

  return useMemo(() => {
    const commonTrackingProperties =
      currentOrganization?.commonTrackingProperties
    return {
      organizationId: currentOrganization?.id ?? '',
      atEventBillingState:
        commonTrackingProperties?.atEventBillingState ?? null,
      atEventBillingPlan: commonTrackingProperties?.atEventBillingPlan ?? null,
      atEventBillingCycle:
        commonTrackingProperties?.atEventBillingCycle ?? null,
      atEventBillingGateway:
        commonTrackingProperties?.atEventBillingGateway ?? null,
      atEventIsNewBuffer: commonTrackingProperties?.atEventIsNewBuffer ?? null,
      atEventChannelQty: commonTrackingProperties?.atEventChannelQty ?? null,
    }
  }, [currentOrganization])
}
