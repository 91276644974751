import React from 'react'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'

import * as Styles from './styles'

import Banner from '../../../../components/Banner'

type Props = {
  channel: string
}

const ChannelConnectionUpgradeBanner = ({
  channel = 'Linkedin',
}: Props): JSX.Element => {
  return (
    <Banner
      themeColor="orange"
      customHTML={
        <>
          <Text type="paragraph" color="#fff">
            <strong>IMPORTANT UPDATE:</strong> Our {channel} integration has
            been enhanced for better performance. Refresh your connection today
            to keep your posts going out smoothly.
          </Text>
          <Styles.ButtonWrapper>
            <Button
              type="orange"
              onClick={(): void => {
                window.location.href = 'https://account.buffer.com/channels'
              }}
              label="Visit Channels Page"
              size="small"
            />
          </Styles.ButtonWrapper>
        </>
      }
      dismissible={false}
    />
  )
}

export default ChannelConnectionUpgradeBanner
