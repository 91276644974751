/* eslint-disable camelcase, @typescript-eslint/naming-convention */
import React, { forwardRef, type JSX, type MouseEventHandler } from 'react'
import { graphql } from '~publish/gql'
import type { IdeaCard_IdeaFragment as IdeaFragment } from '~publish/gql/graphql'

import clsx from 'clsx'

import {
  IconButton,
  TrashIcon,
  Flex,
  Tag,
  Paragraph,
  VisuallyHidden,
  Heading,
  Checkbox,
  UnstyledButton,
  Text,
} from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import { type Idea, type Media, MediaType } from '~publish/pages/Create/types'

import { useSanitizedAndHighlightedText } from './helpers'

import { MediaCombinedPreview } from '~publish/components/MediaCombinedPreview'
import {
  type MediaMarkerType,
  MediaTypeMarker,
} from '~publish/components/MediaMarker'
import { IdeaCardActions } from './IdeaCardActions'
import { DeleteIdeasDialog } from '~publish/components/DeleteIdeasDialog'

import styles from './IdeaCard.module.css'
import { format } from 'date-fns'
import { ChannelServiceTag } from '~publish/components/ChannelServiceTag'
import { useNewIdeaComposerSplit } from '~publish/components/IdeaComposer/hooks'

const CardHeading = ({
  idea,
}: {
  idea: Idea | IdeaFragment
}): JSX.Element | null => {
  const { isEnabled: isNewIdeaComposerEnabled } = useNewIdeaComposerSplit()

  if (!isNewIdeaComposerEnabled) {
    return idea.content.title ? (
      <Heading as="h4" size="small" className={styles.title}>
        {idea.content.title}
      </Heading>
    ) : null
  }

  return idea.content.title || idea.content.date ? (
    <Flex direction="column" gap="3xs">
      {idea.content.date && (
        <Text as="time" size="sm" color="subtle" weight="bold">
          {format(idea.content.date, 'MMM d')}
        </Text>
      )}
      {idea.content.title && (
        <Heading as="h4" size="small" className={styles.title}>
          {idea.content.title}
        </Heading>
      )}
    </Flex>
  ) : null
}

// This fragment is exported but not used yet
// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
export const IdeaCard_Idea = graphql(/* GraphQL */ `
  fragment IdeaCard_Idea on Idea {
    id
    content {
      title
      text
      aiAssisted
      date
      services
      media {
        id
        url
        alt
        thumbnailUrl
        type
        size
        source {
          name
          id
        }
      }
      tags {
        id
        name
        color
      }
    }
    organizationId
    groupId
    position
    createdAt
    updatedAt
  }
`)

export type IdeaCardProps = {
  idea: Idea | IdeaFragment
  variant?: 'default' | 'compact'
  selectable?: boolean
  selected?: boolean
  onSelect?: (id: string) => void
  // TODO: we should just pass id instead of the whole entity
  // for nowm keeping idea object for backward compatibility
  // after usage is refactored, we can remove update it
  onDelete?: (idea: Idea | IdeaFragment) => Promise<void>
  onOpen?: (idea: Idea | IdeaFragment) => void
  onMoveIntoGroup?: (id: string, groupId: string | null) => void
}

const IdeaCard = forwardRef<HTMLDivElement, IdeaCardProps>(
  (
    {
      variant = 'default',
      idea,
      selectable,
      selected,
      onDelete,
      onOpen,
      onSelect,
      onMoveIntoGroup,
    },
    forwardedRef,
  ) => {
    const { isEnabled: isNewIdeaComposerEnabled } = useNewIdeaComposerSplit()
    const { isEnabled: isIdeasRevampEnabled } =
      useSplitEnabled('CT-ideas-revamp')

    const tags = idea?.content?.tags ?? []
    const hasTags = tags.length > 0

    const media = idea?.content?.media ?? []
    const hasMedia = media.length > 0

    const handleClick: MouseEventHandler = (): void => {
      /**
       * If we are in multi-select mode, click on the idea card will select it
       */
      if (selectable) {
        onSelect?.(idea.id)
        return
      }

      onOpen?.(idea)
    }

    const handleDelete = React.useCallback(async (): Promise<void> => {
      await onDelete?.(idea)
    }, [idea, onDelete])

    const handleSelect = (): void => {
      onSelect?.(idea.id)
    }

    const handleMoveIntoGroup = (groupId: string | null): void => {
      onMoveIntoGroup?.(idea.id, groupId)
    }

    return (
      <article
        ref={forwardedRef}
        data-testid="idea-card"
        data-idea-id={idea.id}
        data-idea-position={idea.position}
        data-selected={selected}
        data-selectable={selectable}
        className={clsx(styles.card, styles[variant])}
      >
        {hasMedia && (
          <div
            className={styles.mediaContainer}
            data-media-count={media.length}
          >
            <MediaCombinedPreview>
              {media.map((media) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error null is not assignable to type
                <IdeaMedia key={media.id} media={media} />
              ))}
            </MediaCombinedPreview>
          </div>
        )}

        <div className={styles.content}>
          <CardHeading idea={idea} />
          {isNewIdeaComposerEnabled && !!idea.content?.services?.length && (
            <Flex wrap="wrap" gap="2xs" className={styles.services}>
              {idea.content.services.map((service) => (
                <ChannelServiceTag
                  key={`${idea.id}-${service}-tag`}
                  service={service}
                />
              ))}
            </Flex>
          )}
          {idea.content.text && <SanitizedIdeaText text={idea.content.text} />}

          {hasTags ? (
            <Flex wrap="wrap" gap="2xs" className={styles.tags}>
              {tags.map((tag) => (
                <Tag key={tag.id} color={tag.color}>
                  {tag.name}
                </Tag>
              ))}
            </Flex>
          ) : null}
        </div>

        {/* this button stretches to cover the entire card and add accessible focus and click events */}
        <UnstyledButton
          data-dnd
          className={styles.overlayButton}
          onClick={handleClick}
        >
          <VisuallyHidden>
            {selectable ? 'Select idea' : 'Open idea'}
          </VisuallyHidden>
        </UnstyledButton>

        {selectable && (
          <label
            className={styles.selectionIndicator}
            htmlFor={`select-idea-${idea.id}`}
          >
            <Checkbox
              id={`select-idea-${idea.id}`}
              tabIndex={-1}
              checked={selected}
              onChange={handleSelect}
            />
            <VisuallyHidden>Select idea</VisuallyHidden>
          </label>
        )}

        {!selectable &&
          (isIdeasRevampEnabled ? (
            <IdeaCardActions
              idea={idea}
              className={styles.ideaCardMenu}
              onSelect={onSelect ? handleSelect : undefined}
              onDelete={handleDelete}
              onMoveIntoGroup={
                onMoveIntoGroup ? handleMoveIntoGroup : undefined
              }
            />
          ) : (
            <DeleteIdeaIconButton onDelete={handleDelete} />
          ))}
      </article>
    )
  },
)

IdeaCard.displayName = 'IdeaCard'

type SanitizedIdeaTextProps = {
  text?: string | null
}

const SanitizedIdeaText = ({
  text,
}: SanitizedIdeaTextProps): JSX.Element | null => {
  const htmlText = useSanitizedAndHighlightedText(text)

  if (!text) {
    return null
  }

  return (
    <Paragraph
      className={styles.text}
      dangerouslySetInnerHTML={{ __html: htmlText }}
    />
  )
}

type IdeaMediaProps = {
  media: Media | NonNullable<Idea['content']['media']>[number]
}

const ideaTypeToMarker: Partial<Record<MediaType, MediaMarkerType>> = {
  [MediaType.video]: 'video',
  [MediaType.gif]: 'gif',
  [MediaType.document]: 'pdf',
}

const IdeaMedia = ({ media }: IdeaMediaProps): JSX.Element | null => {
  // TODO: API should support resizing, this is a temporary solution
  // to alleviate performance concerns
  const optimizedThumbnail = `https://safeimage.buffer.com/500,fit/${
    media.thumbnailUrl || media.url
  }`

  const markerType = ideaTypeToMarker[media.type]

  return (
    <div className={styles.mediaWrapper}>
      <img
        src={optimizedThumbnail}
        alt={media.alt ?? 'Media attachment'}
        className={styles.media}
        loading="lazy"
      />
      {markerType && (
        <MediaTypeMarker
          className={styles.mediaTypeIndicator}
          type={markerType}
        />
      )}
    </div>
  )
}

const DeleteIdeaIconButton = ({
  onDelete,
}: {
  onDelete: () => Promise<void>
}): JSX.Element => {
  return (
    <DeleteIdeasDialog onDelete={onDelete}>
      <IconButton
        variant="critical"
        label="Delete idea"
        tooltip="Delete idea"
        className={styles.deleteButton}
      >
        <TrashIcon />
      </IconButton>
    </DeleteIdeasDialog>
  )
}

DeleteIdeaIconButton.displayName = 'DeleteIdeaIconButton'

export type IdeaCardSkeletonProps = {
  variant: 1 | 2 | 3 | 4
}

const IdeaCardSkeleton = React.forwardRef<
  React.ElementRef<'div'>,
  IdeaCardSkeletonProps
>(({ variant = 1 }, ref): JSX.Element => {
  return (
    <article
      className={clsx(styles.skeleton, styles[`variant${variant}`])}
      aria-hidden
      ref={ref}
    >
      <div className={styles.media} />
    </article>
  )
})

IdeaCardSkeleton.displayName = 'IdeaCard.Skeleton'

const IdeaCardObject = Object.assign(IdeaCard, {
  Skeleton: IdeaCardSkeleton,
})

export { IdeaCardObject as IdeaCard }
