import { getActiveProductFromPath } from '../../../../common/utils/getProduct'
import { getPublishUrl, isInPublish } from '../../../../common/utils/urls'
import { useProductHistory } from '../../../../exports/Navigator/utils/useProductHistory'

export default function useCreateIdeaRedirect(): {
  createIdeaRedirect: () => void
} {
  const { history } = useProductHistory()
  const isInPublishApp = isInPublish()

  function createIdeaRedirect(): void {
    // If the view param isn't set, create routing will override our navigation
    const contentPlannerView = localStorage.getItem('contentPlannerLastViewed')
    const createIdeaRoute = 'create/ideas/new'
    const cta =
      `${getActiveProductFromPath()}-navBar-globalPlus-newIdea-1` as const

    if (isInPublishApp && history) {
      history.push({
        pathname: `/${createIdeaRoute}`,
        search: `?source=${cta}`,
      })
    } else {
      const redirectUrl = getPublishUrl(
        `${createIdeaRoute}?source=${cta}&view=${contentPlannerView}`,
      )
      window.location.assign(redirectUrl)
    }
  }

  return { createIdeaRedirect }
}
