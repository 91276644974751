import React, { useState } from 'react'
import { Dialog, useMediaQuery } from '@buffer-mono/popcorn'
import { useDispatch, useSelector } from 'react-redux'
import { useSplitEnabled } from '@buffer-mono/features'

import type { OrchestratorRootState } from '../../../../../common/events/types'
import { useUser } from '../../../../../common/context/User'
import { trackChannelConnectionAborted } from '../../tracking'
import { resetChannelConnectionsState } from '../../../store/channelConnectionsReducer'
import ChannelStoreFront from '../ChannelStoreFront/ChannelStoreFront'
import BlueskyAuth from '../BlueskyAuth/BlueskyAuth'
import { type onRedirectParams, Service } from '../../types'
import InstagramSelectAccountType from '../InstagramSelectAccountType/InstagramSelectAccountType'
import { Mastodon } from '../services/Mastodon/Mastodon'
import { Facebook } from '../services/Facebook/Facebook'
import { InstagramFacebookAuth } from '../services/Instagram/FacebookAuth/FacebookAuth'
import { SimpleModal } from '@bufferapp/ui'

import styles from './ChannelConnectionsDialog.module.css'
import { ExitChannelConnectionDialog } from '../ExitChannelConnectionDialog/ExitChannelConnectionDialog'

interface ChannelConnectionsDialogProps {
  onRedirect: (args: onRedirectParams) => void
}

function ChannelConnectionsDialog({
  onRedirect,
}: ChannelConnectionsDialogProps): JSX.Element | null {
  const user = useUser()
  const {
    selectedService,
    name,
    selectedRefreshChannelId,
    isRefreshingChannelConnection,
    showChannelStorefront,
  } = useSelector((state: OrchestratorRootState) => state.channelConnections)

  const dispatch = useDispatch()

  function cancelConnection(): void {
    if (selectedService) {
      trackChannelConnectionAborted({
        account: user,
        service: selectedService,
      })
    }
    dispatch(resetChannelConnectionsState({}))
  }
  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )

  const showBlueskyAuth = selectedService === Service.bluesky
  const showMastodonAuth = selectedService === Service.mastodon
  const showFacebookAuth = selectedService === Service.facebook

  const showNewInstagramAuth =
    (selectedService === Service.instagram ||
      selectedService === Service.instagram_login_api) &&
    !showChannelStorefront

  const showPopcornDialog =
    showNewInstagramAuth ||
    selectedService === Service.bluesky ||
    selectedService === Service.mastodon ||
    selectedService === Service.facebook

  const shouldUsePopcornDialog = showPopcornDialog || showChannelStorefront

  const isMobile = useMediaQuery('(width < 641px)')
  const dialogSize = isMobile ? 'maximize' : 'xlarge'

  const [showExitChannelConnectionDialog, setShowExitChannelConnectionDialog] =
    useState(false)

  const { isEnabled: isInstagramLoginEnabled } =
    useSplitEnabled('instagram-login')
  const showOldInstagramViaFacebookAuth =
    selectedService === Service.instagram && !isInstagramLoginEnabled

  if (showOldInstagramViaFacebookAuth) {
    // IMPORTANT: Most users will go through the new Instagram login flow.
    // However, we do have a small number of users who experience permissions
    // issues and need to manually connect via this old Facebook flow with
    // advocacy's help. Because of this, we'll need to keep this split and flow
    // active.
    return (
      <SimpleModal closeAction={cancelConnection}>
        <InstagramFacebookAuth
          onRedirect={onRedirect}
          cancelConnection={cancelConnection}
        />
      </SimpleModal>
    )
  }

  return (
    <Dialog
      open={shouldUsePopcornDialog}
      onOpenChange={
        showChannelStorefront && isChannelStorefrontEnabled
          ? cancelConnection
          : (): void => setShowExitChannelConnectionDialog(true)
      }
    >
      <Dialog.Content className={styles.dialog} size={dialogSize}>
        {showChannelStorefront && isChannelStorefrontEnabled && (
          <ChannelStoreFront />
        )}
        {showExitChannelConnectionDialog && (
          <ExitChannelConnectionDialog
            setShowExitChannelConnectionDialog={
              setShowExitChannelConnectionDialog
            }
            onOpenChange={cancelConnection}
          />
        )}
        {showNewInstagramAuth && (
          <InstagramSelectAccountType onRedirect={onRedirect} />
        )}
        {showBlueskyAuth && (
          <BlueskyAuth
            onClose={cancelConnection}
            isRefreshingChannelConnection={isRefreshingChannelConnection}
            name={name}
            channelId={selectedRefreshChannelId}
          />
        )}
        {showMastodonAuth && (
          <Mastodon onRedirect={onRedirect} onClose={cancelConnection} />
        )}
        {showFacebookAuth && <Facebook onRedirect={onRedirect} />}
      </Dialog.Content>
    </Dialog>
  )
}

export default ChannelConnectionsDialog
