import React from 'react'
import { Text, Button } from '@bufferapp/ui'
import { fontWeightBold } from '@bufferapp/ui/style/fonts'
import { gray, grayDark } from '@bufferapp/ui/style/colors'
import HashtagGroupList from '../HashtagGroupList'

import * as Styles from './styles'
import type { HashtagGroup } from '../../types'

const emptyHeaderStyle = {
  paddingTop: '130px',
  paddingBottom: '8px',
  fontWeight: fontWeightBold,
  color: grayDark,
}

const emptyParagraphStyle = {
  color: gray,
}

interface HashtagGroupManagerProps {
  onCreateHashtagGroup: () => void
  hashtagGroups: HashtagGroup[]
  onInsertHashtagGroupClick: (name: string, text: string) => void
  onEditHashtagGroupClick: (group: HashtagGroup) => void
  onDeleteHashtagGroupClick: (group: HashtagGroup) => void
}

const HashtagGroupManager = ({
  onCreateHashtagGroup,
  hashtagGroups,
  onInsertHashtagGroupClick,
  onEditHashtagGroupClick,
  onDeleteHashtagGroupClick,
}: HashtagGroupManagerProps): JSX.Element => (
  <>
    <Styles.Title>Hashtag Manager</Styles.Title>
    {!hashtagGroups ||
      (hashtagGroups.length === 0 && (
        <Styles.EmptyWrapper>
          <div style={emptyHeaderStyle}>
            <Text type="span">No hashtag groups yet!</Text>
          </div>
          <div style={emptyParagraphStyle}>
            <Text type="span">
              Save and reuse your favorite hashtags by creating a hashtag group.
              Click the button below to get started.
            </Text>
          </div>
        </Styles.EmptyWrapper>
      ))}
    {hashtagGroups?.length > 0 && (
      <HashtagGroupList
        hashtagGroups={hashtagGroups}
        onInsertHashtagGroupClick={onInsertHashtagGroupClick}
        onEditHashtagGroupClick={onEditHashtagGroupClick}
        onDeleteHashtagGroupClick={onDeleteHashtagGroupClick}
      />
    )}
    <Styles.ButtonWrapper>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
      <Button
        type="secondary"
        label="Create Hashtag Group"
        onClick={onCreateHashtagGroup}
        fullWidth
      />
    </Styles.ButtonWrapper>
  </>
)

export default HashtagGroupManager
