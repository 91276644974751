import React from 'react'
import { Flex, Heading, Separator, Sidebar } from '@buffer-mono/popcorn'

import SettingsPageLayout from './components/SettingsPageLayout'
import Photo from './ProfileSettings/Photo'
import Name from './ProfileSettings/Name'
import Email, { EmailVerificationBanner } from './ProfileSettings/Email'
import Password from './ProfileSettings/Password'
import Delete from './ProfileSettings/DeleteAccount'
import TwoFactor from './ProfileSettings/TwoFactor'

export const ProfileSettings = ({
  isMobile,
  handleToggleSidebar,
}: {
  isMobile: boolean
  handleToggleSidebar: () => void
}): JSX.Element => {
  return (
    <SettingsPageLayout>
      <section>
        <Flex gap="xl" direction="column">
          <Flex gap="sm" direction="row" align="center">
            {isMobile && <Sidebar.Trigger onClick={handleToggleSidebar} />}
            <Heading as="h1" size="large">
              Profile
            </Heading>
          </Flex>
          <EmailVerificationBanner />
          <Photo />
          <Name />
          <Email />
          <Password />
        </Flex>
      </section>
      <Separator />
      <TwoFactor />
      <Separator />
      <Delete />
    </SettingsPageLayout>
  )
}

export default ProfileSettings
