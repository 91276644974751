import React from 'react'
import {
  Flex,
  Skeleton,
  Text,
  Link,
  Button,
  DropdownMenu,
  IconButton,
  EllipsisVerticalIcon,
  Badge,
  Card,
  Tooltip,
  toast,
  SocialIconsIcon,
  ShoppingCartIcon,
  Separator,
  EmptyState,
  AudienceIcon,
  InfoIcon,
} from '@buffer-mono/popcorn'
import { type FragmentOf, graphql, readFragment } from '~publish/graphql'
import styles from './PlanContent.module.css'

/**
 * Fragment for discount data. Applies to both OBBilling and MPBilling.
 */
export const Discount_Billing = graphql(/* GraphQL */ `
  fragment Discount_Billing on Billing {
    discount {
      gatewayId
      name
      type
      duration
      value
      durationInMonths
    }
  }
`)

/**
 * Fragment for upcoming invoice data.
 */
export const UpcomingInvoice_Billing = graphql(/* GraphQL */ `
  fragment UpcomingInvoice_Billing on Billing {
    upcomingInvoiceAmount
    upcomingInvoice {
      amountWithoutTax
      amountTax
      currency
    }
  }
`)

/**
 * Fragment for subscription and plan information.
 * Applies to OBBilling.
 */
export const PlanContent_Billing = graphql(
  /* GraphQL */ `
    fragment PlanContent_Billing on OBBilling {
      canStartTrial
      balance
      ...Discount_Billing
      ...UpcomingInvoice_Billing
      pricingTiers {
        flatFee
        unitPrice
        upperBound
      }
      subscription {
        periodEnd
        isCanceledAtPeriodEnd
        isPaymentPastDue
        canceledAt
        trial {
          isActive
          remainingDays
          startDate
          endDate
        }
        plan {
          id
          name
          interval
          limits {
            channels
            members
          }
          prices {
            baseMonthlyPrice
            basePlanPrice
            monthlyFlatFee
            monthlyChannelPrice
            channelTierUnit
          }
        }
        scheduledUpdate {
          newPlan
          newInterval
          newQuantity
          effectiveDate
        }
      }
    }
  `,
  [Discount_Billing, UpcomingInvoice_Billing],
)

/**
 * Fragment for subscription and plan information for legacy billing.
 */
export const LegacyPlanContent_Billing = graphql(
  /* GraphQL */ `
    fragment LegacyPlanContent_Billing on MPBilling {
      canAccessPublishing
      canAccessAnalytics
      billingRedirectUrl
      balance
      ...Discount_Billing
      ...UpcomingInvoice_Billing
      subscriptions {
        plan
        product
      }
    }
  `,
  [Discount_Billing, UpcomingInvoice_Billing],
)

/**
 * Fragment for organization data used on the billing page.
 */
export const OrganizationDataForBilling_AccountOrganization =
  graphql(/* GraphQL */ `
    fragment OrganizationDataForBilling_AccountOrganization on AccountOrganization {
      members {
        totalCount
      }
      channels {
        id
        isLocked
      }
      limits {
        channels
      }
    }
  `)

// TODO: check if used elsewhere
/**
 * Formats number to currency with 2 decimals and no decimal for whole numbers
 * @param {number} value number to format.
 * @return {string} formatted string
 */
export function formatCurrency(value: number): string {
  if (value) {
    if (Number.isInteger(value)) {
      return value.toString()
    } else {
      return value.toFixed(2)
    }
  } else {
    return '0'
  }
}

const getDiscountCopy = (
  discountData: FragmentOf<typeof Discount_Billing>,
): {
  discountBadgeCopy: string | null
  discountDescription: string | null
} => {
  const discount = readFragment(Discount_Billing, discountData).discount
  if (!discount) {
    return {
      discountBadgeCopy: null,
      discountDescription: null,
    }
  }

  let discountDescription = `${discount.value}% off`
  let discountBadgeCopy = `${discount.value}% off`
  if (discount.type === 'amount') {
    discountDescription = `$${formatCurrency(discount.value)}`
    discountBadgeCopy = `$${formatCurrency(discount.value)} off`
  }

  switch (discount.duration) {
    case 'forever':
      discountDescription += ' forever'
      break
    case 'once':
      discountDescription += ' on your next invoice'
      break
    case 'repeating':
      discountDescription += ` for ${discount.durationInMonths} months`
      break
  }
  return {
    discountBadgeCopy,
    discountDescription,
  }
}

/**
 * Formats the user limit copy.
 * @param userLimit - The user limit.
 * @returns The formatted user limit copy.
 */
const getUsersCopy = (userLimit: number): string => {
  let userLimitCopy
  switch (userLimit) {
    case 0:
      userLimitCopy = 'Limited to 1'
      break
    case 9999:
      userLimitCopy = 'Unlimited'
      break
    default:
      userLimitCopy = `Limited to ${userLimit}`
  }
  return userLimitCopy
}

/**
 * Renders the legacy plans card.
 * @param loading - Whether the data is loading.
 * @returns The rendered legacy plans card.
 */
const LegacyPlansList = ({ loading }: { loading: boolean }): JSX.Element => {
  return (
    <Skeleton show={loading}>
      <Card className={styles.planCard}>
        <Flex direction="column" gap="md">
          <Text size="lg" weight="bold">
            Legacy Plans here
          </Text>
          <Text size="sm">
            Need help?{' '}
            <Link href="https://help.buffer.com/">Contact support</Link>
          </Text>
        </Flex>
      </Card>
    </Skeleton>
  )
}

/**
 * Formats a number to a currency string.
 * @param amount - The amount to format.
 * @param currency - The currency to format the amount in.
 * @returns The formatted currency string.
 */
export const numberWithCurrency = (
  amount: number,
  currency: string,
): string => {
  const locale = navigator.language || 'en-EN'
  return amount.toLocaleString(locale, {
    style: 'currency',
    currency: currency.toUpperCase(),
  })
}

/**
 * Formats the upcoming invoice amount.
 * @param totalInvoiceAmount - The total invoice amount.
 * @param totalInvoiceAmountExcludingTax - The total invoice amount excluding tax.
 * @param taxAmount - The tax amount.
 * @param currency - The currency to format the amount in.
 * @param hasPaymentDetails - Whether the user has payment details.
 * @returns The formatted upcoming invoice amount.
 */
const getUpcomingInvoiceCopy = (
  upcomingInvoiceData: FragmentOf<typeof UpcomingInvoice_Billing>,
  hasPaymentDetails: boolean,
): {
  upcomingInvoiceAmountExcludingTax: string
  upcomingInvoiceAmountTax: string
} => {
  const { upcomingInvoice } = readFragment(
    UpcomingInvoice_Billing,
    upcomingInvoiceData,
  )

  if (!hasPaymentDetails || !upcomingInvoice) {
    return {
      upcomingInvoiceAmountExcludingTax: '$0.00',
      upcomingInvoiceAmountTax: '$0.00',
    }
  }

  // TODO: can we make these non-nullable on the core-api?
  const { amountWithoutTax, amountTax, currency } = upcomingInvoice

  return {
    upcomingInvoiceAmountExcludingTax: numberWithCurrency(
      Number(amountWithoutTax ?? 0),
      currency ?? 'USD',
    ),
    upcomingInvoiceAmountTax: numberWithCurrency(
      Number(amountTax ?? 0),
      currency ?? 'USD',
    ),
  }
}

/**
 * Renders the plan card.
 * @param data - The billing data to render.
 * @returns The rendered plan card.
 */
const PlanCard = ({
  billingData,
  organizationData,
}: {
  billingData: FragmentOf<typeof PlanContent_Billing>
  organizationData: FragmentOf<
    typeof OrganizationDataForBilling_AccountOrganization
  > | null
}): JSX.Element => {
  // Read fragments
  const billing = readFragment(PlanContent_Billing, billingData)
  const organization = readFragment(
    OrganizationDataForBilling_AccountOrganization,
    organizationData,
  )
  const subscription = billing.subscription

  // TODO:
  const hasPaymentDetails = true

  // Get copy and states
  const { discountBadgeCopy, discountDescription } = getDiscountCopy(billing)
  const { upcomingInvoiceAmountExcludingTax, upcomingInvoiceAmountTax } =
    getUpcomingInvoiceCopy(billing, hasPaymentDetails)
  const isActiveTrial = subscription.trial?.isActive
  const connectedChannelsCount = organization?.channels.length ?? 0
  const lockedChannelsCount =
    organization?.channels.filter((channel) => {
      return channel.isLocked
    }).length ?? 0
  const channelsLimit = organization?.limits.channels

  // TODO: handle null states here
  if (!subscription) {
    return <Skeleton show={true} />
  }

  const usersCopy = getUsersCopy(subscription.plan.limits.members ?? 0)

  const handleChangePlan = (): void => {
    toast.success('Change plan not implemented')
  }

  const handleAddRemoveChannels = (): void => {
    toast.success('Add/Remove Channels not implemented')
  }

  const handleCancelPlan = (): void => {
    toast.success('Cancel plan not implemented')
  }

  const handleUnlockChannels = (): void => {
    toast.success('Unlock channels not implemented')
  }

  return (
    <Flex direction="column" gap="md" fullWidth>
      {/* Header */}
      <Flex direction="row" fullWidth>
        {/* Plan details */}
        <Flex direction="column" gap="xs" fullWidth>
          {/* Plan name and interval */}
          <Flex direction="row" gap="xs" align="center">
            <Text size="lg" weight="bold">
              Plan: {subscription?.plan?.name ?? 'Team'}
            </Text>
            <Badge size="small" variant="neutral">
              {subscription?.plan?.interval === 'year' ? 'ANNUAL' : 'MONTHLY'}
            </Badge>
            {discountBadgeCopy && (
              <Tooltip content={discountDescription}>
                <Badge size="small" variant="brand">
                  {discountBadgeCopy}
                </Badge>
              </Tooltip>
            )}
            {isActiveTrial && (
              <Badge size="small" variant="brand">
                Trial
              </Badge>
            )}
          </Flex>
          {/* Renewal date */}
          <Text size="sm" color="subtle">
            Your subscription renews on{' '}
            {subscription.periodEnd &&
              new Date(subscription.periodEnd).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}
          </Text>
        </Flex>
        {/* Actions */}
        <Flex direction="row" gap="xs" align="center">
          <Button size="small" variant="primary" onClick={handleChangePlan}>
            Change Plan
          </Button>
          <DropdownMenu>
            <DropdownMenu.Trigger asChild>
              <IconButton label="More" size="medium" variant="secondary">
                <EllipsisVerticalIcon />
              </IconButton>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end">
              <DropdownMenu.Item onClick={handleAddRemoveChannels}>
                Add/Remove Channels
              </DropdownMenu.Item>
              <DropdownMenu.Item variant="critical" onClick={handleCancelPlan}>
                Cancel Plan
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu>
        </Flex>
      </Flex>

      <Card className={styles.planCard}>
        <Flex
          direction="row"
          align="center"
          justify="between"
          className={styles.statCardContainer}
        >
          {/* Users */}
          <Flex direction="column" align="center" gap="xs">
            <EmptyState size="small" variant="primary">
              <EmptyState.Icon>
                <AudienceIcon />
              </EmptyState.Icon>
              <EmptyState.Heading>Users</EmptyState.Heading>
              <EmptyState.Description>{usersCopy}</EmptyState.Description>
            </EmptyState>
            <Text size="md" weight="bold">
              {organization?.members?.totalCount}
            </Text>
          </Flex>
          <Separator
            orientation="vertical"
            className={styles.verticalSeparator}
          />
          {/* Channels */}
          <Flex direction="column" align="center" gap="xs">
            <EmptyState size="small" variant="primary">
              <EmptyState.Icon>
                <SocialIconsIcon />
              </EmptyState.Icon>
              <EmptyState.Heading>Channels</EmptyState.Heading>
              {/* TODO: handle agency and tiered pricing description */}
              <EmptyState.Description>
                {subscription.plan.interval === 'year'
                  ? `$${subscription.plan.prices.basePlanPrice} each per year`
                  : `$${subscription.plan.prices.baseMonthlyPrice} each per month`}
              </EmptyState.Description>
            </EmptyState>
            <Text size="md" weight="bold">
              {isActiveTrial
                ? `${connectedChannelsCount}`
                : `${connectedChannelsCount}/${channelsLimit}`}
            </Text>
            {lockedChannelsCount > 0 && (
              <Flex direction="column" gap="xs" align="center">
                <Text size="sm" color="critical">
                  {lockedChannelsCount} channel
                  {lockedChannelsCount !== 1 ? 's' : ''} locked
                </Text>
                <Button
                  size="small"
                  variant="primary"
                  onClick={handleUnlockChannels}
                >
                  Unlock
                </Button>
              </Flex>
            )}
          </Flex>
          <Separator
            orientation="vertical"
            className={styles.verticalSeparator}
          />
          {/* Upcoming bill */}
          <Flex direction="column" align="center" gap="xs">
            <EmptyState size="small" variant="primary">
              <EmptyState.Icon>
                <ShoppingCartIcon />
              </EmptyState.Icon>
              <EmptyState.Heading>
                {isActiveTrial ? 'First bill after trial' : 'Upcoming bill'}
              </EmptyState.Heading>
              <EmptyState.Description>
                <Flex direction="column" gap="xs" align="center">
                  <Tooltip
                    content="This amount could change if adjustments are made mid-billing cycle to your number of connected channels. We'll always account for those changes and include the details in your invoice."
                    side="bottom"
                  >
                    <Flex direction="row" gap="xs" align="center">
                      <Text size="md" color="subtle">
                        Usage changes
                        <InfoIcon />
                      </Text>
                    </Flex>
                  </Tooltip>
                  {!hasPaymentDetails && isActiveTrial ? (
                    <Text size="sm" color="subtle">
                      You won&apos;t be charged unless you choose to subscribe
                    </Text>
                  ) : (
                    <>
                      <Text size="md" weight="bold">
                        {upcomingInvoiceAmountExcludingTax}
                      </Text>
                      {upcomingInvoiceAmountTax !== '$0.00' && (
                        <Text size="md" weight="regular">
                          Plus tax ({upcomingInvoiceAmountTax})
                        </Text>
                      )}
                    </>
                  )}
                </Flex>
              </EmptyState.Description>
            </EmptyState>
          </Flex>
        </Flex>
      </Card>

      <Text size="sm">
        Need help? <Link href="https://help.buffer.com/">Contact support</Link>
      </Text>
    </Flex>
  )
}

/**
 * Type guard to check if the billing data is legacy.
 * @param data - The billing data to check.
 * @returns True if the billing data is legacy, false otherwise.
 */
const isLegacyBilling = (
  data:
    | FragmentOf<typeof PlanContent_Billing>
    | FragmentOf<typeof LegacyPlanContent_Billing>,
): data is FragmentOf<typeof LegacyPlanContent_Billing> => {
  return 'subscriptions' in data
}

/**
 * Renders the plan content based on the billing data.
 * @param loading - Whether the data is loading.
 * @param data - The billing data to render.
 * @returns The rendered plan content.
 */
export const PlanContent = ({
  loading,
  billingData,
  organizationData,
}: {
  loading: boolean
  billingData?:
    | FragmentOf<typeof PlanContent_Billing>
    | FragmentOf<typeof LegacyPlanContent_Billing>
    | null
  organizationData: FragmentOf<
    typeof OrganizationDataForBilling_AccountOrganization
  > | null
}): JSX.Element => {
  if (!billingData) {
    return <Skeleton show={loading} />
  }

  return (
    <Skeleton show={loading}>
      {isLegacyBilling(billingData) ? (
        <LegacyPlansList loading={loading} />
      ) : (
        <PlanCard
          billingData={billingData}
          organizationData={organizationData}
        />
      )}
    </Skeleton>
  )
}

export default PlanContent
