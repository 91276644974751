import { MODAL_ACTIONS } from '../../../common/events/modalEvents'
import { ORCHESTRATOR_ACTIONS } from '../../../common/events/orchestratorEvents'
import { MODALS } from '../../../common/types'
import { Service } from '../../Orchestrator/channelConnections/types'

export function triggerChannelConnectBasedOnUrl(): void {
  const { hash } = window.location
  if (hash.startsWith('#connectChannel=')) {
    const channel = hash.replace('#connectChannel=', '')
    if (Object.keys(Service).includes(channel as Service)) {
      ORCHESTRATOR_ACTIONS.connectChannel({
        selectedService: channel as Service,
        cta: 'directLink-directLink-directLink-1',
      })
    }
  }
}

/**
 * Triggers the posting goal configuration modal based on the URL hash and pathname.
 *
 * This function checks if the URL hash starts with '#postingGoalConfiguration='
 * and if the channel ID in the hash matches the channel ID in the pathname.
 * If both conditions are met, it opens the posting goal configuration modal
 * with the extracted channel ID.
 */
export function triggerPostingGoalConfigurationBasedOnUrl(): void {
  const { hash, pathname } = window.location
  if (hash.startsWith('#postingGoalConfiguration')) {
    const channelIdFromPath = pathname.match(/\/channels\/([^/]+)/)?.[1]
    if (channelIdFromPath) {
      MODAL_ACTIONS.openModal(MODALS.postingGoalConfiguration, {
        channelId: channelIdFromPath,
      })
    }
  }
}

// List of modals that should not be opened automatically from the URL.
const MODALS_TO_FILTER_FROM_AUTOMATIC_URL = [
  MODALS.postingGoalConfiguration,
].map(toString)

const MODALS_TO_OPEN_FROM_URL = Object.keys(MODALS).filter(
  (k) => !MODALS_TO_FILTER_FROM_AUTOMATIC_URL.includes(k),
)

export const openModalFromUrl = (): void => {
  const { hash } = window.location
  const matchingModal = MODALS_TO_OPEN_FROM_URL.find(
    (k) => k === hash.replace('#', ''),
  )

  if (matchingModal) {
    const modalKey = matchingModal
    const modalData = {
      cta: 'directLink-directLink-directLink-1',
      upgradePathName: 'directLink-upgrade',
    }

    MODAL_ACTIONS.openModal(modalKey, modalData)
  }
}

export const handleOrchestratorActionsOnload = (): void => {
  triggerChannelConnectBasedOnUrl()
  openModalFromUrl()
  triggerPostingGoalConfigurationBasedOnUrl()
}
