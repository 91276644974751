import { type RouterActions, push } from 'redux-first-history'
import { type match, matchPath } from 'react-router-dom'
import type { CampaignTab } from '~publish/legacy/campaign/types'
import { Services } from '~publish/legacy/constants/services'
import { withCopiedSearchParams } from './helpers/withCopiedSearchParams'

type GlobalPostFilters = {
  channelIds?: string[]
  tagIds?: string[]
}

// Routes utils
export const getMatch = <
  P extends Record<string, string> = Record<string, string>,
>({
  pathname,
  route,
}: {
  pathname: string
  route: string | string[]
}): match<P> | null =>
  matchPath<P>(pathname, {
    path: route,
  })

export const getParams = <
  P extends Record<string, string> = Record<string, string>,
>({
  pathname,
  route,
}: {
  pathname: string
  route: string | string[]
}): P | null => {
  const match = getMatch<P>({ pathname, route })
  return match?.params || null
}

export const goTo = (path: string): RouterActions => push(path)

export const DEFAULT_TAB_ID = 'queue'

// Profiles routes
export const profileServicePages = {
  route: `/profile/:service(${Services.map((service) => service.name).join(
    '|',
  )})`,
}
export const profileServiceWithTabPages = {
  route: `/profile/:service(${Services.map((service) => service.name).join(
    '|',
  )})/tab/:tabId(queue|sentPosts|drafts|settings|approvals)`,
}
export const profilePages = {
  route: '/profile/:profileId',
  getRoute: ({ profileId }: { profileId: string }): string =>
    `/profile/${profileId}`,
  goTo: ({ profileId }: { profileId: string }): RouterActions =>
    push(`/profile/${profileId}`),
}

export const profileTabPages = {
  route: '/profile/:profileId/tab/:tabId',
  getRoute: ({
    profileId,
    tabId = 'queue',
  }: {
    profileId: string
    tabId?: string
  }): string => `/profile/${profileId}/tab/${tabId}`,
  defaultRoute: '/profile/:profileId/tab/queue',
  goTo: ({
    profileId,
    tabId = 'queue',
  }: {
    profileId: string
    tabId: string
  }): RouterActions => push(`/profile/${profileId}/tab/${tabId}`),
}

export const profileChildTabPages = {
  route: '/profile/:profileId/tab/:tabId/:childTabId?',
  getRoute: ({
    profileId,
    tabId,
    childTabId,
  }: {
    profileId: string
    tabId: string
    childTabId: string
  }): string => `/profile/${profileId}/tab/${tabId}/${childTabId}`,
  goTo: ({
    profileId,
    tabId,
    childTabId,
  }: {
    profileId: string
    tabId: string
    childTabId: string
  }): RouterActions => push(`/profile/${profileId}/tab/${tabId}/${childTabId}`),
}

type ServiceRouteParams = {
  service: string
  tabId?: string
}
export const isServiceRoute = (args: {
  pathname: string
}): ServiceRouteParams | null => {
  const { pathname } = args
  return getParams({
    pathname,
    route: [profileServiceWithTabPages.route, profileServicePages.route],
  })
}

type ProfileRouteParams = {
  profileId: string
  tabId?: string
  childTabId?: string
}
export const getProfilesParams = (args: {
  pathname: string
}): ProfileRouteParams | null => {
  const { pathname } = args
  return getParams({
    pathname,
    route: [profileChildTabPages.route, profilePages.route],
  })
}

// Miscellaneous routes
export const generic = {
  route: '/',
  goTo: (): RouterActions => push('/'),
}

// Preferences routes
export const preferencesPage = {
  route: '/preferences',
  goTo: (): RouterActions => push('/preferences'),
}

export const preferencesAppsExtras = {
  route: '/preferences/appsandextras',
  goTo: (): RouterActions => push('/preferences/appsandextras'),
}

export const preferencesSecurity = {
  route: '/preferences/security',
  goTo: (): RouterActions => push('/preferences/security'),
}

export const preferencesGeneral = {
  route: '/preferences/general',
  goTo: (): RouterActions => push('/preferences/general'),
}

export const preferencesNotifications = {
  route: '/preferences/notifications',
  goTo: (): RouterActions => push('/preferences/notifications'),
}

// Campaigns routes
export const campaignsPage = {
  route: '/campaigns',
  goTo: (): RouterActions => push('/campaigns'),
}

export const tagsPage = {
  route: '/tags',
  goTo: (): RouterActions => push('/tags'),
}
export const campaignCreate = {
  route: '/campaigns/new',
  goTo: (): RouterActions => push('/campaigns/new'),
}

export const channelGroups = {
  route: '/channel-groups',
  goTo: (): RouterActions => push('/channel-groups'),
}

const commonChannelFilterParams = [
  'channels[]',
  'tags[]',
  'filter_preset',
] as const
const getChannelFilterParams = (forwardParams?: boolean): string[] =>
  forwardParams ? [...commonChannelFilterParams] : []

export const allChannels = {
  route: '/all-channels',
  queryParams: [
    { param: 'tab', values: ['queue', 'sent', 'drafts', 'approvals'] },
    { param: 'channels', values: undefined },
    { param: 'tags', values: undefined },
    { param: 'filter_preset', values: undefined },
  ],
  buildPathname: (): string => '/all-channels',
  goTo: (): RouterActions => push('/all-channels'),
  getRoute: (filters?: GlobalPostFilters & { tab?: string }): string =>
    withCopiedSearchParams(
      '/all-channels',
      [],
      [
        ...(filters?.channelIds?.map(
          (channelId) => ['channels[]', channelId] as [string, string],
        ) ?? []),
        ...(filters?.tagIds?.map(
          (tagId) => ['tags[]', tagId] as [string, string],
        ) ?? []),
        ...((filters?.tab ? [['tab', filters.tab]] : []) as [string, string][]),
      ],
    ),
}

export const newCalendarAllChannels = {
  route: '/all-channels/calendar/:granularity',
  queryParams: [
    { param: 'channels', values: undefined },
    { param: 'tags', values: undefined },
    { param: 'filter_preset', values: undefined },
  ],
  buildPathname: (params: { granularity: 'week' | 'month' }): string =>
    `/all-channels/calendar/${params.granularity}`,

  getRoute: (
    granularity: 'week' | 'month',
    filters?: GlobalPostFilters,
    forwardParams?: boolean,
  ): string =>
    withCopiedSearchParams(
      `/all-channels/calendar/${granularity}`,
      [...getChannelFilterParams(forwardParams), 'date'],
      [
        ...(filters?.channelIds?.map(
          (channelId) => ['channels[]', channelId] as [string, string],
        ) ?? []),
        ...(filters?.tagIds?.map(
          (tagId) => ['tags[]', tagId] as [string, string],
        ) ?? []),
      ],
    ),
}

export const channel = {
  route: '/channels/:id',
  queryParams: [
    {
      param: 'tab',
      values: ['queue', 'sent', 'drafts', 'approvals'] as string[],
    },
    { param: 'tags', values: undefined },
  ] as const,
  goTo: ({
    channelId,
    tab,
  }: {
    channelId: string
    tab?: string
  }): RouterActions => {
    if (tab) return push(`/channels/${channelId}?tab=${tab}`)
    return push(`/channels/${channelId}`)
  },
  buildPathname: (params: { id: string }): string => `/channels/${params.id}`,
  getRoute: (
    channelId: string,
    filters: Pick<GlobalPostFilters, 'tagIds'> & { tab?: string } = {},
  ): string =>
    withCopiedSearchParams(
      `/channels/${channelId}`,
      [],
      [
        !!filters.tab && ['tab', filters.tab],
        ...(filters.tagIds?.map(
          (tagId) => ['tags[]', tagId] as [string, string],
        ) ?? []),
      ],
    ),
}

export const postEditRoute = {
  route: '/posts/:postId/edit',
  getRoute: ({ postId }: { postId: string }): string => `/posts/${postId}/edit`,
}

export const postDetailsRoute = {
  route: '/posts/:postId',
  getRoute: ({ postId }: { postId: string }): string => `/posts/${postId}`,
}

export const postNewRoute = {
  route: '/posts/new',
}

export const ideaEditRoute = {
  route: '/ideas/:ideaId/edit',
  getRoute: ({ ideaId }: { ideaId: string }): string => `/ideas/${ideaId}/edit`,
}

export const ideaDetailsRoute = {
  route: '/ideas/:ideaId',
  getRoute: ({ ideaId }: { ideaId: string }): string => `/ideas/${ideaId}`,
}

export const ideaNewRoute = {
  route: '/ideas/new',
}

export const newCalendarSingleChannel = {
  route: '/channels/:id/calendar/:granularity' as const,
  queryParams: [{ param: 'tags', values: undefined }] as const,
  buildPathname: (params: {
    id: string
    granularity: 'week' | 'month'
  }): string => `/channels/${params.id}/calendar/${params.granularity}`,
  getRoute: (
    channelId: string,
    granularity: 'week' | 'month',
    filters: GlobalPostFilters = {},
    forwardParams?: boolean,
  ): string =>
    withCopiedSearchParams(
      `/channels/${channelId}/calendar/${granularity}`,
      [...getChannelFilterParams(forwardParams), 'date'],
      filters.tagIds?.map((tagId) => ['tags[]', tagId] as [string, string]) ??
        [],
    ),
}

export const channelSettings = {
  route: '/channels/:id/settings',
  goTo: ({
    channelId,
    tab,
  }: {
    channelId: string
    tab?: 'posting-schedule' | 'general'
  }): RouterActions =>
    push(`/channels/${channelId}/settings${tab ? `?tab=${tab}` : ''}`),
  getRoute: ({
    channelId,
    tab,
  }: {
    channelId: string
    tab?: 'posting-schedule' | 'general'
  }): string => `/channels/${channelId}/settings${tab ? `?tab=${tab}` : ''}`,
}

export const channelShopGrid = {
  route: '/channels/:id/shop-grid',
  goTo: ({ channelId }: { channelId: string }): RouterActions =>
    push(`/channels/${channelId}/shop-grid`),
}

export const tagCreate = {
  route: '/tags/new',
  goTo: (): RouterActions => push('/tags/new'),
}

export const campaignEdit = {
  route: '/campaigns/:id/edit',
  goTo: ({
    campaignId,
    from,
  }: {
    campaignId: string
    from: string
  }): RouterActions => push(`/campaigns/${campaignId}/edit`, { from }),
}

export const tagEdit = {
  route: '/tags/:id/edit',
  goTo: ({ tagId, from }: { tagId: string; from: string }): RouterActions =>
    push(`/tags/${tagId}/edit`, { from }),
}

export const campaignTab = {
  route: '/campaigns/:id/:tab',
  getRoute: ({
    campaignId,
    tab,
  }: {
    campaignId: string
    tab: CampaignTab
  }): string => `/campaigns/${campaignId}/${tab}`,
  goTo: ({
    campaignId,
    tab,
  }: {
    campaignId: string
    tab: CampaignTab
  }): RouterActions => push(`/campaigns/${campaignId}/${tab}`),
}

export const tagTab = {
  route: '/tags/:id/:tab',
  getRoute: ({ tagId, tab }: { tagId: string; tab: string }): string =>
    `/tags/${tagId}/${tab}`,
  goTo: ({ tagId, tab }: { tagId: string; tab: string }): RouterActions =>
    push(`/tags/${tagId}/${tab}`),
}

// Content Routes
export const createPage = {
  route: '/create',
  goTo: (): RouterActions => push('/create'),
}

export const commentsPage = {
  route: '/comments',
  goTo: (): RouterActions => push('/comments'),
  queryParams: [
    {
      param: 'status',
      values: ['all', 'unreplied', 'replied', 'dismissed', 'new'] as string[],
    },
  ] as const,
  buildPathname: (): string => '/comments',
}

export type CommentsChannelView = 'comments' | 'posts'

export const commentsChannel = {
  route: '/comments/:channelId/:view?',
  goTo: ({
    channelId,
    view,
  }: {
    channelId: string
    view?: CommentsChannelView
  }): RouterActions =>
    push(
      view === 'posts'
        ? `/comments/${channelId}/posts`
        : `/comments/${channelId}`,
    ),
  queryParams: [
    {
      param: 'tab',
      values: ['overview', 'comments'] as string[],
    },
    {
      param: 'status',
      values: ['all', 'unreplied', 'replied', 'dismissed', 'new'] as string[],
    },
    {
      param: 'sort',
      values: ['asc', 'desc'] as string[],
    },
    {
      param: 'publishedAt',
      values: [
        'alltime',
        'last7days',
        'last30days',
        'last6months',
        'lastyear',
      ] as string[],
    },
  ] as const,
  buildPathname: ({
    channelId,
    view,
  }: {
    channelId: string
    view?: CommentsChannelView
  }): string =>
    view === 'posts'
      ? `/comments/${channelId}/posts`
      : `/comments/${channelId}`,
  getRoute: ({
    channelId,
    view,
  }: {
    channelId: string
    view?: CommentsChannelView
  }): string => {
    // Only append /posts for posts view, leave comments as the default
    return view === 'posts'
      ? `/comments/${channelId}/posts`
      : `/comments/${channelId}`
  },
}

export const isCommentsPage = (): boolean => {
  const commentsPageMatch = getMatch({
    pathname: location.pathname,
    route: commentsPage.route,
  })
  const channelPageMatch = getMatch({
    pathname: location.pathname,
    route: commentsChannel.route,
  })
  return !!commentsPageMatch || !!channelPageMatch
}

export const singleFeedPage = {
  route: '/create/feeds/:feedId',
  linkTo: (feedId: string): string => `/create/feeds/${feedId}`,
}

export const feedCollectionPage = {
  route: '/create/feed/collections/:feedCollectionId',
  linkTo: (feedCollectionId: string): string =>
    `/create/feed/collections/${feedCollectionId}`,
}

export const createNew = {
  route: '/create/ideas/new',
  goTo: (): RouterActions => push('/create/ideas/new'),
}

// Blueprints Routes
export const blueprintsPage = {
  route: '/recipes',
  goTo: (): RouterActions => push('/recipes'),
}

// Organization Routes
export const organization = {
  route: '/org/:id',
  goTo: ({ orgId }: { orgId: string }): RouterActions => push(`/org/${orgId}`),
}

// Calendar Routes
export const calendarPage = {
  route: '/calendar',
  goTo: (): RouterActions => push('/calendar'),
}

const getCalendarRoute = (
  granularity: 'week' | 'month',
  filters?: GlobalPostFilters,
  forwardParams?: boolean,
): string => {
  const { channelIds, tagIds } = filters || {}
  const channels = (channelIds?.map((channelId) => ['channels[]', channelId]) ??
    []) as [string, string][]
  const tags = (tagIds?.map((tagId) => ['tags[]', tagId]) ?? []) as [
    string,
    string,
  ][]
  return withCopiedSearchParams(
    `/calendar/${granularity}`,
    getChannelFilterParams(forwardParams),
    [...channels, ...tags],
  )
}

export const calendarMonth = {
  route: '/calendar/month',
  goTo: (): RouterActions =>
    push(
      withCopiedSearchParams('/calendar/month', [
        'channels[]',
        'tags[]',
        'filter_preset',
      ]),
    ),
  getRoute: (filters?: GlobalPostFilters, forwardParams?: boolean): string => {
    return getCalendarRoute('month', filters, forwardParams)
  },
  goToWithChannel: ({ channelId }: { channelId: string }): RouterActions =>
    push(
      withCopiedSearchParams(
        '/calendar/month',
        ['channels[]', 'tags[]'],
        [['channels[]', channelId]],
      ),
    ),
}

export const calendarWeek = {
  route: '/calendar/week',
  goTo: (): RouterActions =>
    push(
      withCopiedSearchParams('/calendar/week', [
        'channels[]',
        'filter_preset',
        'tags[]',
      ]),
    ),
  getRoute: (filters?: GlobalPostFilters, forwardParams?: boolean): string => {
    return getCalendarRoute('week', filters, forwardParams)
  },
  goToWithChannel: ({ channelId }: { channelId: string }): RouterActions =>
    push(
      withCopiedSearchParams(
        '/calendar/week',
        ['channels[]', 'tags[]'],
        [['channels[]', channelId]],
      ),
    ),
}

// Composer Routes
export const compose = {
  route: '/compose',
  goTo: (): RouterActions => push('/compose'),
}

export const newPost = {
  route: '/post/new',
  goTo: (): RouterActions => push('/post/new'),
}

// Deep link routes
export const linkToPost = {
  route: '/link-to-post',
  getRoute: ({
    profileId,
    postId,
    noteId,
  }: {
    profileId: string
    postId: string
    noteId?: string
  }): string =>
    `/link-to-post?profileId=${profileId}&postId=${postId}&noteId=${noteId}}`,
  goTo: (): RouterActions => push('/link-to-post'),
}

// Settings Routes
export const settings = {
  route: '/settings',
  goTo: (): RouterActions => push('/settings'),
  getRoute: (): string => '/settings',
  goToPage: (page: string): RouterActions => push(`/settings/${page}`),
}

export const appsAndExtrasRoute = {
  route: '/settings/apps-extras',
  goTo: (): RouterActions => push('/settings/apps-extras'),
}

export const settingsPreferencesRoute = {
  route: '/settings/preferences',
  goTo: (): RouterActions => push('/settings/preferences'),
}

export const settingsNotificationsRoute = {
  route: '/settings/notifications',
  goTo: (): RouterActions => push('/settings/notifications'),
}

export const generalSettingsRoute = {
  route: '/settings/general',
  goTo: (): RouterActions => push('/settings/general'),
}

export const referAFriendRoute = {
  route: '/settings/refer-a-friend',
  goTo: (): RouterActions => push('/settings/refer-a-friend'),
}

export const betaFeaturesRoute = {
  route: '/settings/beta',
  goTo: (): RouterActions => push('/settings/beta'),
}

export const settingsTeamRoute = {
  route: '/settings/team',
  goTo: (): RouterActions => push('/settings/team'),
}

export const settingsChannelsRoute = {
  route: '/settings/channels',
  goTo: (): RouterActions => push('/settings/channels'),
}

export const settingsBillingRoute = {
  route: '/settings/billing',
  goTo: (): RouterActions => push('/settings/billing'),
}

export const settingsChannelsSettingsRoute = {
  route: '/settings/channels/:id',
  goTo: (channelId: string): RouterActions =>
    push('/settings/channels/' + channelId),
}
