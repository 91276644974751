import { gql, type TypedDocumentNode } from '@apollo/client'
import type {
  Mutation,
  UpdateChannelPostingGoalInput,
} from '../../../../gql/graphql'

type UpdateChannelPostingGoalMutation = TypedDocumentNode<
  Mutation['updateChannelPostingGoal'],
  { input: UpdateChannelPostingGoalInput }
>

export const UPDATE_CHANNEL_POSTING_GOAL_MUTATION: UpdateChannelPostingGoalMutation =
  gql(/* GraphQL */ `
    mutation UpdateChannelPostingGoal($input: UpdateChannelPostingGoalInput!) {
      updateChannelPostingGoal(input: $input) {
        __typename
        ... on UpdateChannelPostingGoalResponse {
          channel {
            id
            postingGoal {
              goal
              sentCount
              scheduledCount
              periodStart
              periodEnd
              status
            }
          }
        }
        ... on MutationError {
          message
        }
      }
    }
  `)
