import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Flex,
  Heading,
  Sidebar,
  Button,
  Form,
  Input,
  Label,
  Text,
  SkeletonText,
  CriticalIcon,
  useDebounceCallback,
} from '@buffer-mono/popcorn'

import SettingsPageLayout from './components/SettingsPageLayout'
import { useAccount } from '~publish/legacy/accountContext'

import style from './Settings.module.css'
import { organizationNameSchema } from './validation/organizationName'
import { useUpdateOrganizationName } from './hooks/useUpdateOrganizationName'
import { useInputValidation } from './hooks/useInputValidation'
const OrganizationName = (): JSX.Element => {
  const { account } = useAccount()
  const currentOrganizationName = account?.currentOrganization?.name ?? ''
  const [newOrganizationName, setNewOrganizationName] = useState(
    currentOrganizationName,
  )

  const { updateOrganizationName } = useUpdateOrganizationName()

  const [invalidInput, setInvalidInput] = useState(false)
  const [isDisabled, setIsDisabled] = useState(
    currentOrganizationName === newOrganizationName,
  )

  const { checkIsValid, isValid, errorMessages } = useInputValidation({
    schema: organizationNameSchema,
    inputValue: currentOrganizationName,
  })

  useEffect(() => {
    if (currentOrganizationName === newOrganizationName) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [currentOrganizationName, newOrganizationName])

  const handleSubmit = async (): Promise<void> => {
    const result = await updateOrganizationName({
      organizationId: account?.currentOrganization?.id,
      name: newOrganizationName.trim(),
    })

    if (result) {
      setInvalidInput(false)
    }
  }

  const handleValidateInput = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const inputValue = event.target.value
      setNewOrganizationName(inputValue.trim())
      const isValid = checkIsValid({ inputValue })
      if (!isValid) {
        setInvalidInput(true)
        return
      }

      if (invalidInput && isValid) {
        setInvalidInput(false)
      }
    },
    200,
  )

  return (
    <Form
      name="updateOrganizationNameForm"
      className={style.fullWidth}
      onSubmit={handleSubmit}
    >
      <Flex gap="sm" direction="row" align="end">
        <Form.Field
          name="organizationName"
          className={clsx(style.fullWidth, style.field)}
        >
          <Form.Label htmlFor="organizationName">Organization Name</Form.Label>
          <Form.Control>
            <span>
              <Input
                id="organizationName"
                defaultValue={currentOrganizationName ?? ''}
                data-1p-ignore="true"
                aria-invalid={!isValid}
                onChange={handleValidateInput}
                className={style.formInput}
              />
            </span>
          </Form.Control>
        </Form.Field>
        <Button
          size="medium"
          type="submit"
          variant="secondary"
          disabled={isDisabled || !isValid}
          className={style.fixedWidthButton}
        >
          Save Changes
        </Button>
      </Flex>
      {!isValid &&
        errorMessages &&
        errorMessages.map((error, index) => (
          <Text key={`${index}-org-nameinput-error`} color="critical" size="sm">
            <CriticalIcon size="xsmall" style={{ verticalAlign: 'middle' }} />{' '}
            {error}
          </Text>
        ))}
    </Form>
  )
}

const CreatedAt = (): JSX.Element => {
  const { account } = useAccount()
  const organizationCreatedAt = account?.currentOrganization?.createdAt
  const formattedDate = organizationCreatedAt
    ? new Date(organizationCreatedAt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : ''

  return (
    <Flex gap="2xs" direction="column" className={style.fullWidth}>
      <Label>Created at</Label>
      {organizationCreatedAt ? (
        <Text
          as="time"
          color="subtle"
          dateTime={formattedDate ? new Date(formattedDate).toISOString() : ''}
        >
          {formattedDate}
        </Text>
      ) : (
        <SkeletonText lines={1} width="100px" />
      )}
    </Flex>
  )
}

export const GeneralSettings = ({
  isMobile,
  handleToggleSidebar,
}: {
  isMobile: boolean
  handleToggleSidebar: () => void
}): JSX.Element => {
  return (
    <SettingsPageLayout>
      <section>
        <Flex gap="xl" direction="column">
          <Flex gap="sm" direction="row" align="center">
            {isMobile && <Sidebar.Trigger onClick={handleToggleSidebar} />}
            <Heading as="h1" size="large">
              General
            </Heading>
          </Flex>
          <CreatedAt />
          <OrganizationName />
        </Flex>
      </section>
    </SettingsPageLayout>
  )
}

export default GeneralSettings
