import { ensureValidHandle, InvalidHandleError } from '@atproto/syntax'

export function isValidPassword(
  password: string,
  isRefreshingChannelConnection: boolean,
): {
  isValid: boolean
  errorMessage: string
} {
  if (!isRefreshingChannelConnection && password.length === 0) {
    return {
      isValid: false,
      errorMessage: 'App Password is required',
    }
  }

  // check if password match xxxx-xxxx-xxxx-xxxx pattern
  if (
    password &&
    password.match(/^[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}$/i) ===
      null
  ) {
    return {
      isValid: false,
      errorMessage: 'App Password should match xxxx-xxxx-xxxx-xxxx pattern',
    }
  }

  return {
    isValid: true,
    errorMessage: '',
  }
}

const handleErrorsMap = {
  'Disallowed characters in handle (ASCII letters, digits, dashes and periods only)':
    'Special characters are not allowed. Use only letters, numbers, dashes, and periods.',
  'Handle is too long (253  chars max)':
    'The handle is too long. The maximum length is 253 characters.',
  'Handle domain needs at least two parts':
    'The handle domain needs at least two parts (for example: one.two).',
  'Handle part too long (max 63 chars)':
    'Each part of the handle can be up to 63 characters long.',
  'Handle parts can not start or end with hyphens':
    "Handle parts can't start or end with hyphens.",
  'Handle final component (TLD) must start with ASCII letter':
    'The last part of the handle (TLD) must start with a letter. ',
} as Record<string, string>

export function isValidHandle(handle: string): {
  isValid: boolean
  errorMessage: string
} {
  try {
    ensureValidHandle(handle)

    return {
      isValid: true,
      errorMessage: '',
    }
  } catch (error) {
    if (error instanceof InvalidHandleError) {
      return {
        isValid: false,
        errorMessage: handleErrorsMap[error.message] || error.message,
      }
    }

    return {
      isValid: false,
      errorMessage: 'Handle is invalid',
    }
  }
}
