import {
  CornerDownRightIcon,
  DropdownMenu,
  EllipsisIcon,
  FolderIcon,
  IconButton,
  LockIcon,
  RssIcon,
  Sidebar,
  useSidebar,
} from '@buffer-mono/popcorn'
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { graphql, readFragment, type FragmentOf } from '~publish/graphql'
import { isOfType } from '~publish/helpers/typeGuards'
import { singleFeedPage } from '~publish/legacy/routes'
import { FeedItemIcon } from '../Feed/FeedIcon'
import { DeleteDropdownMenuItem } from './DeleteConfirmationDialog'
import styles from './SidebarFeedNav.module.css'
import { HardLimitReachedDialog } from '../HardLimitReachedDialog/HardLimitReachedDialog'

const MoveToCollectionCheckboxItem = ({
  collection,
  feedId,
  feedCollectionId,
  onMoveToCollection,
}: {
  collection: { id: string; name: string; limitReached: boolean }
  feedId: string
  feedCollectionId?: string
  onMoveToCollection: (feedId: string, collectionId?: string) => void
}): JSX.Element => {
  const isLocked = collection.limitReached && collection.id !== feedCollectionId
  return (
    <HardLimitReachedDialog
      key={`moveToCollection-hardLimitReached-${collection.id}`}
      enabled={isLocked}
      type="feedspercollection"
    >
      <DropdownMenu.CheckboxItem
        key={collection.id}
        checked={collection.id === feedCollectionId}
        // We use onSelect because we don't actually care about onCheckedChange
        onSelect={(event): void => {
          if (!isLocked) {
            onMoveToCollection(feedId, collection.id)
          } else {
            event.preventDefault()
          }
        }}
        className={styles.moveToCollectionDropdownItem}
      >
        <FolderIcon />
        {collection.name}
        {isLocked && <LockIcon className={styles.suffixIcon} />}
      </DropdownMenu.CheckboxItem>
    </HardLimitReachedDialog>
  )
}

export const SidebarFeedItem_Feed = graphql(/* GraphQL */ `
  fragment SidebarFeedItem_Feed on Feed {
    id
    name
    collection {
      id
    }
    connectionMetadata {
      ... on RSSFeedConnectionMetadata {
        __typename
        url
      }
      ... on NewsSearchConnectionMetadata {
        __typename
        query
      }
    }
  }
`)

type SidebarFeedItemProps = {
  data: FragmentOf<typeof SidebarFeedItem_Feed>
  collections: Array<{ id: string; name: string; limitReached: boolean }>
  indent: 0 | 1 | 2
  onMoveToCollection: (feedId: string, collectionId?: string) => void
  onDelete: (feedId: string) => void
}

export const SidebarFeedItem = ({
  data,
  indent = 0,
  collections,
  onMoveToCollection,
  onDelete,
}: SidebarFeedItemProps): JSX.Element | null => {
  const { state } = useSidebar()
  const { feedId: selectedFeedId } = useParams<{ feedId?: string }>()
  const feed = readFragment(SidebarFeedItem_Feed, data)

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
    // Stop propagation to prevent the click from triggering a navigation
    event.stopPropagation()
    onDelete(feed.id)
  }

  return (
    <Sidebar.ListItem className={styles.listItem}>
      <Sidebar.Button
        key={feed.id}
        as={NavLink}
        to={singleFeedPage.linkTo(feed.id)}
        indent={indent}
        selected={selectedFeedId === feed.id}
        className={styles.sidebarLink}
        prefix={
          <FeedItemIcon
            feedName={feed.name}
            url={
              isOfType(feed.connectionMetadata, 'RSSFeedConnectionMetadata')
                ? feed.connectionMetadata.url
                : undefined
            }
          />
        }
      >
        {feed.name}
      </Sidebar.Button>
      {/* Feed settings dropdown on hover */}
      {state === 'expanded' && (
        <DropdownMenu>
          <DropdownMenu.Trigger>
            <IconButton
              label="More"
              size="medium"
              variant="tertiary"
              className={styles.listItemHoverButton}
            >
              <EllipsisIcon />
            </IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <CornerDownRightIcon />
                Move to
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubMenu className={styles.moveToCollectionSubMenu}>
                <DropdownMenu.CheckboxItem
                  key="no-collection"
                  checked={!feed.collection}
                  // We use onSelect because we don't actually care about onCheckedChange
                  onSelect={(event): void => {
                    // Only perform the mutation for this item if the feed is in a collection
                    if (feed.collection) {
                      onMoveToCollection(feed.id)
                    } else {
                      event.preventDefault()
                    }
                  }}
                >
                  <RssIcon />
                  Feeds
                </DropdownMenu.CheckboxItem>
                {collections.map((collection) => (
                  <MoveToCollectionCheckboxItem
                    key={collection.id}
                    collection={collection}
                    feedId={feed.id}
                    feedCollectionId={feed.collection?.id}
                    onMoveToCollection={onMoveToCollection}
                  />
                ))}
              </DropdownMenu.SubMenu>
            </DropdownMenu.Sub>
            <DeleteDropdownMenuItem mode="feed" onConfirm={handleDelete} />
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
    </Sidebar.ListItem>
  )
}
