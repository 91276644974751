import type { ChannelBanner } from '~publish/gql/graphql'
import type { ChannelMask } from '../hooks/useChannelsForSettings'

export enum Status {
  Locked = 'locked',
  RequiresRefreshing = 'requiresRefreshing',
  Active = 'active',
}

type ChannelStatus = {
  status: Status
}

type ChannelStatusProps = {
  channel: ChannelMask
}

export const getChannelStatus = ({
  channel,
}: ChannelStatusProps): ChannelStatus => {
  const needsRefreshing =
    channel.banners?.some(
      (banner: ChannelBanner) =>
        banner === 'tiktokUpgrade' ||
        banner === 'pinterestUpgrade' ||
        banner === 'linkedinUpgrade',
    ) ||
    (channel.service === 'twitter' && channel.apiVersion !== '2')

  let status = Status.RequiresRefreshing
  if (channel.isLocked) {
    status = Status.Locked
  } else if (!needsRefreshing && !channel.isDisconnected) {
    status = Status.Active
  }

  return {
    status,
  }
}
