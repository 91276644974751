import React, { useState } from 'react'
import {
  BufferTrackerReact as BufferTracker,
  Client,
} from '@buffer-mono/tracking-plan'
import {
  Button,
  CheckIcon,
  Image,
  UpgradeDialog,
  Paragraph,
  Strong,
} from '@buffer-mono/popcorn'

import { useCurrentOrganization } from '~publish/legacy/accountContext'

import styles from './IdeaBoardGroupLimitPaywall.module.css'

export const upgradePathName = 'ideasGroupLimit-upgrade'

type CtaOptions = {
  product?: string
  view?: string
  location: string
  button: string
  version?: number
}

function buildCtaString({
  product = 'create',
  view = 'board',
  location,
  button,
  version = 1,
}: CtaOptions): string {
  return `${product}-${view}-${location}-${button}-${version}`
}

type IdeaBoardGroupLimitPaywallProps = {
  cta: CtaOptions
  children: React.ReactNode
}

export function IdeaBoardGroupLimitPaywall({
  children,
  cta: ctaOptions,
}: IdeaBoardGroupLimitPaywallProps): JSX.Element {
  const currentOrganization = useCurrentOrganization()
  const [open, setOpen] = useState(false)
  const cta = buildCtaString(ctaOptions)

  const handleOpen = (isOpen: boolean): void => {
    if (isOpen) {
      BufferTracker.cTAClicked({
        clientName: Client.PublishWeb,
        product: 'publish',
        cta,
        upgradePathName,
        organizationId: currentOrganization?.id ?? '',
      })
      BufferTracker.upgradePathViewed({
        clientName: Client.PublishWeb,
        product: 'publish',
        organizationId: currentOrganization?.id ?? '',
        upgradePathName,
      })
    }
    setOpen(isOpen)
  }

  return (
    <UpgradeDialog open={open} onOpenChange={handleOpen}>
      <UpgradeDialog.Trigger>{children}</UpgradeDialog.Trigger>
      <UpgradeDialog.Content aria-describedby={undefined}>
        <UpgradeDialog.TextContent>
          <UpgradeDialog.Title>
            You&apos;ve hit your limit of 3 groups on the free plan
          </UpgradeDialog.Title>
          <Paragraph>
            Upgrade your plan to unlock <Strong>unlimited groups</Strong>.
          </Paragraph>
          <ul className={styles.list}>
            <li>
              <CheckIcon size="small" color="brand" aria-hidden />
              <Paragraph>Up to 2,000 scheduled posts</Paragraph>
            </li>
            <li>
              <CheckIcon size="small" color="brand" aria-hidden />
              <Paragraph>Analytics on best times to post</Paragraph>
            </li>
            <li>
              <CheckIcon size="small" color="brand" aria-hidden />
              <Paragraph>Engagement tools to answer comments</Paragraph>
            </li>
          </ul>
          <Button
            variant="primary"
            size="large"
            className={styles.button}
            onClick={(): void => {
              setOpen(false)
              window?.appshell.actions.openModal(
                window?.appshell.MODALS.planSelector,
                {
                  cta: 'publish-createBoard-newGroupUpgradeModal-upgrade-1',
                  upgradePathName,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error - FIXME: Object literal may only specify known properties, and 'planSelector' does not exist in type 'ModalData'
                  planSelector: {
                    initialStep: 'upgrade',
                  },
                },
              )
            }}
          >
            See Upgrade Options
          </Button>
        </UpgradeDialog.TextContent>
        <UpgradeDialog.Image className={styles.image}>
          <Image
            src="https://buffer-publish.s3.amazonaws.com/images/Upsell-Board-New-Column/Upsell-Board-New-Column_688w.png"
            alt=""
            placeholder="https://buffer-publish.s3.amazonaws.com/images/Upsell-Board-New-Column/Upsell-Board-New-Column_50w.png"
            srcSet={[
              {
                srcSet:
                  'https://buffer-publish.s3.amazonaws.com/images/Upsell-Board-New-Column/Upsell-Board-New-Column_320w.webp 320w',
                media: '(max-width: 319px)',
                type: 'image/webp',
              },
              {
                srcSet:
                  'https://buffer-publish.s3.amazonaws.com/images/Upsell-Board-New-Column/Upsell-Board-New-Column_480w.webp 480w',
                media: '(max-width: 479px)',
                type: 'image/webp',
              },
              {
                srcSet:
                  'https://buffer-publish.s3.amazonaws.com/images/Upsell-Board-New-Column/Upsell-Board-New-Column_688w.webp 688w',
                media: '(max-width: 687px)',
                type: 'image/webp',
              },
              {
                srcSet:
                  'https://buffer-publish.s3.amazonaws.com/images/Upsell-Board-New-Column/Upsell-Board-New-Column_320w.png 320w',
                media: '(max-width: 319px)',
                type: 'image/png',
              },
              {
                srcSet:
                  'https://buffer-publish.s3.amazonaws.com/images/Upsell-Board-New-Column/Upsell-Board-New-Column_480w.png 480w',
                media: '(max-width: 479px)',
                type: 'image/png',
              },
              {
                srcSet:
                  'https://buffer-publish.s3.amazonaws.com/images/Upsell-Board-New-Column/Upsell-Board-New-Column_688w.png 688w',
                media: '(max-width: 687px)',
                type: 'image/png',
              },
            ]}
          />
        </UpgradeDialog.Image>
      </UpgradeDialog.Content>
    </UpgradeDialog>
  )
}
