import { SplitProtected } from '~publish/components/SplitProtected'

import {
  Button,
  Dialog,
  Flex,
  LinkIcon,
  Notice,
  Text,
  Textarea,
  toast,
  useControllableState,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import {
  UploadSource,
  type Uploader,
  type UploadMetadata,
} from '@buffer-mono/uploader'
import { Plate, type TEditableProps } from '@udecode/plate'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useOrganizationLimits } from '~publish/legacy/accountContext'
import { AIAssistant } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'
import AIContextualMenu from '~publish/legacy/ai/components/Triggers/AIContextualMenu'
import { renderPlaceholderWithAIButton } from '~publish/legacy/ai/helpers/renderPlaceholderWithAIButton'
import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'

import { useCMDEnterKeys } from '~publish/hooks/useCMDEnterKeys'
import {
  selectContentGenerationStatus,
  setAIAssistantPlacement,
  toggleAIAssistant,
} from '~publish/legacy/ai/state/slice'
import { SlateJsProxy } from '~publish/legacy/composer/composer/entities/EditorStateProxy'
import { getPlainText } from '~publish/legacy/editor/BufferEditor'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import { EditorErrorBoundary } from '~publish/legacy/editor/components/EditorErrorBoundary'
import { EmojiCombobox } from '~publish/legacy/editor/plugins/emoji'
import { BarButton } from '~publish/legacy/integrations-bar'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import {
  selectCompletedCount,
  selectIncludedMedia,
  selectPendingCount,
} from '~publish/legacy/uploads/state/selectors'
import { preloadMedia } from '~publish/legacy/uploads/state/thunks/preloadMedia'
import { getRandomNumber } from '~publish/legacy/utils/numbers'
import { UNASSIGNED_GROUP_ID } from '~publish/pages/Create/components/Board/helpers/helpers'
import { CreateUploadDropzone } from '~publish/pages/Create/components/CreateUploadDropzone'
import { useIdeasCount } from '~publish/pages/Create/hooks/useIdeasCount'
import type { Idea, IdeaContent } from '~publish/pages/Create/types'
import type { IdeaComposerLocationState } from '../IdeaManagementRouter/hooks'
import styles from './IdeaComposer.module.css'
import {
  IdeaComposerProvider,
  useIdeaComposerState,
} from './IdeaComposerContext'
import { ConfirmCloseDialog } from './components/ConfirmCloseDialog'
import { IdeasLimitReachedModal } from './components/IdeasLimitReachedModal'
import { PropertyFields } from './components/PropertyFields'
import { Sidepanel } from './components/Sidepanel'
import { IDEA_EDITOR_ID, IDEAS_UPLOADER_ID } from './constants'
import {
  createIdeaEditor,
  isExistingIdea,
  isMediaConvertibleToPost,
} from './helpers'

export type NewIdea = {
  id?: string
  content: Omit<IdeaContent, 'aiAssisted'>
  groupId?: Idea['groupId']
}

export type DraftIdea = NewIdea | Idea

const Header = (): JSX.Element => {
  const { draftIdea, error, setTitle, constructIdeaComposerUrl } =
    useIdeaComposerState()

  const copyIdeaComposerUrl = async (): Promise<void> => {
    const ideaComposerUrl = constructIdeaComposerUrl()
    await navigator.clipboard.writeText(ideaComposerUrl)
    toast.success('Idea Composer URL copied to your clipboard')
  }

  return (
    <Dialog.Header className={styles.header}>
      <VisuallyHidden as="label" htmlFor="idea-title">
        <Dialog.Title>Idea Composer</Dialog.Title>
      </VisuallyHidden>
      <VisuallyHidden as="label">
        <Dialog.Description>
          Create and edit your ideas here.
        </Dialog.Description>
      </VisuallyHidden>
      {error && (
        <Notice variant="error" className={styles.errorNotice}>
          <Text>{error}</Text>
        </Notice>
      )}
      <Flex justify="between" align="center" gap="xs">
        <VisuallyHidden as="label" htmlFor="idea-title">
          Idea title
        </VisuallyHidden>
        <Textarea
          id="idea-title"
          placeholder="Give your idea a title"
          size="large"
          resize="auto"
          rows={1}
          value={draftIdea?.content?.title}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void =>
            setTitle(e.target.value)
          }
          className={styles.titleInput}
        />
        <SplitProtected name="CT-copy-idea-composer-url">
          <BarButton
            data-testid="copy-idea-composer-url-button"
            aria-label="copy idea composer URL"
            onClick={copyIdeaComposerUrl}
          >
            <LinkIcon />
          </BarButton>
          {/* <AlphaBadge /> */}
        </SplitProtected>
      </Flex>

      <PropertyFields />
    </Dialog.Header>
  )
}

const baseEditableProps: TEditableProps<BufferValue> = {
  placeholder: '',
}

const Body = (): JSX.Element => {
  const bodyRef = React.useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const { draftIdea, editor, setText, toggleSidepanel } = useIdeaComposerState()
  const { t } = useTranslation()

  const shouldShowNBMigration = useAppSelector(selectShouldShowNBMigration)
  const onOpenAIAssistant = (): void => {
    // Sets the placement (source) for tracking
    // Must be set before the upgrade modal is triggered as the modal
    // relies on the placement for tracking.
    dispatch(setAIAssistantPlacement({ placement: 'ideasEditor' }))

    // Display a upgrade flow for multi-product users
    // Restrict AI Assistant feature to New Buffer users only
    if (shouldShowNBMigration) {
      ModalActionCreators.openModal('AIAssistantMPUpgradePlan', {
        ctaButton: 'integrationsBar',
      })
      return
    }
    dispatch(toggleAIAssistant(true))
    toggleSidepanel()
  }

  const placeholder = t(`content.editor.placeholder.${getRandomNumber(10)}`)

  baseEditableProps.placeholder = placeholder.concat(' or ')
  baseEditableProps.renderPlaceholder = renderPlaceholderWithAIButton({
    onToggle: onOpenAIAssistant,
  })

  const editableProps: TEditableProps<BufferValue> = React.useMemo(
    () => ({
      ...baseEditableProps,
      spellCheck: true,
      autoFocus: true,
      readOnly: false,
      'data-draftid': IDEA_EDITOR_ID,
      'data-testid': IDEA_EDITOR_ID,
      className: styles.editor,
    }),
    [],
  )

  const updateTextOnEditorValueChange = React.useCallback(
    (editorValue: BufferValue) => {
      const updatedText = getPlainText(editor)
      if (
        editorValue &&
        draftIdea?.content?.text?.trim() !== updatedText?.trim()
      ) {
        setText(updatedText)
      }
    },
    [draftIdea?.content?.text, editor, setText],
  )

  return (
    <Dialog.Body className={styles.body} ref={bodyRef}>
      <EditorErrorBoundary editor={editor}>
        <Plate<BufferValue>
          id="slate-idea-editor"
          editor={editor}
          editableProps={editableProps}
          onChange={updateTextOnEditorValueChange}
        >
          <EmojiCombobox
            maxSuggestions={8}
            portalElement={bodyRef.current ?? undefined}
          />
          <AIContextualMenu editor={editor} placement="ideasEditor" />
        </Plate>
      </EditorErrorBoundary>
    </Dialog.Body>
  )
}

const Footer = (): JSX.Element => {
  const { createPostFromIdea, draftIdea, isLoading, saveDraftIdea } =
    useIdeaComposerState()

  const { contentGenerationInProgress } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )

  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, IDEAS_UPLOADER_ID),
  )

  const createPostButtonDisabled = React.useMemo(() => {
    const hasTextOrMedia =
      draftIdea?.content?.text || !!draftIdea?.content?.media?.length

    const { valid: isMediaConvertible } = isMediaConvertibleToPost(
      draftIdea?.content?.media,
    )

    return (
      pendingCount > 0 ||
      !hasTextOrMedia ||
      !isMediaConvertible ||
      contentGenerationInProgress ||
      isLoading
    )
  }, [draftIdea?.content, contentGenerationInProgress, pendingCount, isLoading])

  const saveButtonDisabled = React.useMemo(() => {
    const hasTitleOrTextOrMedia =
      draftIdea?.content?.text ||
      !!draftIdea?.content?.media?.length ||
      !!draftIdea?.content?.title

    return !hasTitleOrTextOrMedia || contentGenerationInProgress || isLoading
  }, [draftIdea?.content, contentGenerationInProgress, isLoading])

  useCMDEnterKeys(saveDraftIdea, !saveButtonDisabled)

  // const postTooltipLabel = createPostButtonDisabled
  //   ? 'Include copy or media to create a post from this Idea'
  //   : ''

  // const saveTooltipLabel = saveButtonDisabled
  //   ? 'Include a title, copy, or media to save this Idea'
  //   : ''

  return (
    <Dialog.Footer id="ideas-actions">
      <Button
        variant="secondary"
        size="large"
        onClick={createPostFromIdea}
        disabled={createPostButtonDisabled}
      >
        Create Post
      </Button>
      <Button
        variant="primary"
        size="large"
        onClick={saveDraftIdea}
        disabled={saveButtonDisabled}
      >
        Save Idea
      </Button>
    </Dialog.Footer>
  )
}

/**
 * Empty idea used as a default value for the composer if no initialIdea is provided.
 */
const emptyIdea: DraftIdea = {
  content: {
    title: '',
    text: '',
    media: [],
    services: [],
    date: undefined,
  },
}

export type ConstuctIdeaComposerUrl = () => string

type DiffableIdea = {
  title?: string
  text?: string
  media?: string[]
  tags?: string[]
  groupId?: string
  services?: string[]
  date?: string
}

/**
 * Flatten idea into a object that's easy to compare for changes.
 * This is used to determine if the idea has unsaved changes.
 * Only include properties that should be watched.
 *
 * E.g. 'aiAssisted' property shouldn't prevent closing if it's the only change detected.
 */
const getDiffableIdea = (idea: DraftIdea): DiffableIdea => ({
  title: idea.content.title?.trim() ?? '',
  text: idea.content.text?.trim() ?? '',
  media: idea.content.media?.map((el) => el.url ?? '') ?? [],
  tags: idea.content.tags?.map((el) => el.id) ?? [],
  groupId: idea.groupId?.trim() ?? UNASSIGNED_GROUP_ID,
  services: idea.content.services ?? [],
  date: idea.content.date ?? undefined,
})

type IdeaComposerProps = {
  initialIdea?: DraftIdea
  open?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  uploader: Uploader
}

/**
 * Main composer component for creating and editing ideas.
 * This root component takes an initial idea that defaults to an empty idea,
 * manages controllable open state, and sets up the context for the composer.
 *
 * It manages all of the state related to the idea being edited and provides
 * actions for editing it, as well as preloading existing data, setting up the editor,
 * and passing down open state callbacks.
 *
 * It renders the IdeaComposerContext.Provider, the Dialog.Root, and the IdeaComposerContent.
 * Slate edits are synced back to `draftIdea.content.text`.
 * Media is managed separately in the Redux store and is selected from there when needed.
 * It does not seem worth the complexity to keep state in sync between Redux and IdeaComposer state.
 *
 * @example
 * ```tsx
 * <IdeaComposer
 *   initialIdea={{ content: { title: '', text: '', media: [] } }}
 *   defaultOpen={true}
 *   onOpenChange={(isOpen) => console.log('Composer open state:', isOpen)}
 * />
 * ```
 */
export const IdeaComposer = (props: IdeaComposerProps): JSX.Element => {
  const { initialIdea = emptyIdea, uploader } = props
  const dispatch = useAppDispatch()

  // Keep original idea for comparison
  const originalIdea = React.useRef<DraftIdea | undefined>(initialIdea)
  const [draftIdea, setDraftIdea] = React.useState<DraftIdea>(initialIdea)

  /** PRIMARY STATE */
  const [open, setOpen] = useControllableState<boolean>({
    prop: props.open,
    defaultProp: props.defaultOpen,
    onChange: props.onOpenChange,
  })
  const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] =
    React.useState(false)
  const [isSidepanelOpen, setIsSidepanelOpen] = React.useState(false)
  const toggleSidepanel = React.useCallback(() => {
    setIsSidepanelOpen((isOpen) => !isOpen)
  }, [])

  // Composer ID used as a key to reset the context when the composer is closed
  const composerId = React.useId()

  const closeComposer = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  /**
   * PLATE EDITOR INITIALIZATION
   *
   * This ensures the editor is only created once the composer is opened
   * and is destroyed when the composer is closed
   */
  const editorRef = React.useRef<BufferEditor>(createIdeaEditor())
  const editor = editorRef.current
  const ideaText = getPlainText(editor)

  /**
   * LOAD MEDIA
   *
   * This should only be run once at initialization
   */
  React.useEffect(() => {
    if (
      isExistingIdea(initialIdea) &&
      initialIdea.content.media &&
      initialIdea.content.media?.length
    ) {
      dispatch(
        preloadMedia({
          media: initialIdea.content.media || [],
          uploaderId: IDEAS_UPLOADER_ID,
        }),
      )
    }
    // Disabling exhaustive-deps here because we only want this to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // When intitializing, if the draftIdea received text from initialIdea
  // parse the text to Slate state
  React.useEffect(() => {
    if (draftIdea?.content?.text) {
      SlateJsProxy.createStateFromText(editor, draftIdea.content.text)
    }
    // Disabling exhaustive-deps here because we only want this to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const draftIdeaMedia = useAppSelector((state) =>
    selectIncludedMedia(state, IDEAS_UPLOADER_ID),
  )

  const currentIdea = React.useMemo(
    () => ({
      ...draftIdea,
      content: {
        ...draftIdea.content,
        date: draftIdea.content.date ?? undefined,
        media: draftIdeaMedia,
        text: ideaText,
      },
    }),
    [draftIdea, draftIdeaMedia, ideaText],
  )

  const hasUnsavedChanges = React.useMemo(() => {
    if (!currentIdea || !originalIdea.current) return false
    const idea = getDiffableIdea(currentIdea)
    const original = getDiffableIdea(originalIdea.current)
    return !isEqual(idea, original)
  }, [currentIdea, originalIdea])

  /**
   * Check for unsaved changes before closing the composer.
   */
  const handleOpenChange = React.useCallback(
    (open: boolean) => {
      if (!open) {
        if (hasUnsavedChanges) {
          setIsDiscardChangesDialogOpen(true)
        } else {
          closeComposer()
        }
        return
      }
      setOpen(open)
    },
    [hasUnsavedChanges, closeComposer, setOpen],
  )

  const handleClose = React.useCallback(() => {
    handleOpenChange(false)
  }, [handleOpenChange])

  const addFiles = React.useCallback(
    (files: File[], source: UploadMetadata['source']) => {
      uploader.addFiles(files, { source })
    },
    [uploader],
  )

  const { ideas: ideasLimit } = useOrganizationLimits() ?? {}
  const { ideasCount } = useIdeasCount()
  const isIdeasLimitReached = ideasLimit && ideasCount >= ideasLimit
  const location = useLocation<IdeaComposerLocationState>()
  const isEditAction = location?.state?.action === 'edit'

  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, IDEAS_UPLOADER_ID),
  )
  const completedCount = useAppSelector((state) =>
    selectCompletedCount(state, IDEAS_UPLOADER_ID),
  )

  return (
    <IdeaComposerProvider
      key={composerId}
      draftIdea={currentIdea}
      editor={editor}
      uploader={uploader}
      hasUnsavedChanges={hasUnsavedChanges}
      onOpenChange={handleOpenChange}
      closeComposer={closeComposer}
      setDraftIdea={setDraftIdea}
      isSidepanelOpen={isSidepanelOpen}
      toggleSidepanel={toggleSidepanel}
      setIsSidepanelOpen={setIsSidepanelOpen}
      addFiles={addFiles}
    >
      <Dialog open={open} onOpenChange={handleOpenChange}>
        {isIdeasLimitReached && !isEditAction ? (
          <IdeasLimitReachedModal onClose={closeComposer} />
        ) : (
          <Dialog.Portal>
            <CreateUploadDropzone
              disabled={completedCount + pendingCount >= 10}
              onDrop={(files: File[]): void =>
                addFiles(files, UploadSource.dragAndDrop())
              }
            >
              <Dialog.Content
                id="ideaideas-content-wrapper"
                className={styles.contentWithSidepanel}
                onEscapeKeyDown={handleClose}
                data-sidepanel={isSidepanelOpen ? 'open' : 'closed'}
                portal={false}
              >
                <Flex
                  as="section"
                  direction="column"
                  align="stretch"
                  className={styles.composer}
                >
                  <Header />
                  <Body />
                  <Footer />
                </Flex>
                <Sidepanel>
                  <AIAssistant editor={editor} />
                </Sidepanel>
              </Dialog.Content>
            </CreateUploadDropzone>
          </Dialog.Portal>
        )}
      </Dialog>
      <ConfirmCloseDialog
        open={isDiscardChangesDialogOpen}
        onOpenChange={setIsDiscardChangesDialogOpen}
        onConfirm={(): void => closeComposer()}
      />
    </IdeaComposerProvider>
  )
}

IdeaComposer.displayName = 'IdeaComposer'
