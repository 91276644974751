import React from 'react'

import { getProductPriceCycleText } from '../../../../../common/utils/product'

import {
  UpdatedPlanInfoContainer,
  PlanName,
  ChannelsCount,
  UsersCount,
  CurrentPaymentContainer,
  Row,
  Section,
  CancellationInfo,
} from './UpdatedPlanInfo.style'

interface PaymentPlanInfoProps {
  planName: string
  planCycle: string
  numberOfUsers: string
  channelsCount: number
  newPrice: number
}

const PaymentPlanInfo = (props: PaymentPlanInfoProps): JSX.Element => {
  const { planName, planCycle, numberOfUsers, channelsCount, newPrice } = props

  return (
    <UpdatedPlanInfoContainer>
      <Section>
        Your updated plan:
        <PlanName>{planName}</PlanName>
        <Row>
          <ChannelsCount>
            <span>{channelsCount}</span>
            channels
          </ChannelsCount>
          <UsersCount>
            <span>{numberOfUsers}</span>
          </UsersCount>
        </Row>
      </Section>
      <CurrentPaymentContainer>
        New cost: <span>{getProductPriceCycleText(newPrice, planCycle)}</span>
      </CurrentPaymentContainer>
      <CancellationInfo>
        This will be billed every {planCycle} until canceled.
      </CancellationInfo>
    </UpdatedPlanInfoContainer>
  )
}

export default PaymentPlanInfo
