import React from 'react'
import { useDroppable } from '@dnd-kit/core'

import { Slot } from '@buffer-mono/popcorn'

import type { CalendarItem } from './types'

interface DroppableItemProps extends React.HTMLAttributes<HTMLDivElement> {
  id: CalendarItem['id']
  index: number
  timestamp: CalendarItem['timestamp']
  type: CalendarItem['type']
  disabled?: boolean
  children: React.ReactNode
}

export const DroppableItem = React.memo(
  ({
    id,
    index,
    timestamp,
    type,
    children,
    disabled = false,
    ...props
  }: DroppableItemProps): JSX.Element => {
    const { setNodeRef, isOver, active } = useDroppable({
      id,
      disabled,
      data: {
        index,
        timestamp,
        type,
      },
    })

    const isPostBeenDrag = active?.data.current?.type === 'post' && active?.id

    if (disabled) {
      return <>{children}</>
    }

    return (
      <div ref={setNodeRef} {...props}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error - Is a Slot and the prop is being passed down */}
        <Slot.Root isDraggingOver={isOver && isPostBeenDrag}>
          {children}
        </Slot.Root>
      </div>
    )
  },
)

DroppableItem.displayName = 'DroppableItem'
