import type { Account, Channel } from '../types'

export function calculateNewQuantityWithChannelIncrease(args: {
  numberOfUnlockedChannels: number
  currentChannelSlots: number
  numberOfNewlyConnectedChannels: number
}): number {
  const {
    numberOfUnlockedChannels,
    currentChannelSlots,
    numberOfNewlyConnectedChannels,
  } = args
  const newChannelSlotQuantity =
    numberOfUnlockedChannels + numberOfNewlyConnectedChannels

  // There might be available slots. If the total amount of newly connected channels exceeds the channel slots,
  // then the Subscription quantity will increase by the difference. Otherwise will remain the same and the newly
  // connected channels will occupy an available slot without a charge.
  return newChannelSlotQuantity <= currentChannelSlots
    ? currentChannelSlots
    : newChannelSlotQuantity
}

const engageSupportedServices = ['instagram', 'facebook']

export function getSupportedChannelsByProduct({ channels, product }: any) {
  if (product === 'engage') {
    return channels.filter((channel: any) =>
      engageSupportedServices.includes(channel.service),
    )
  }
  return channels
}

export function getChannelFromList(channelId: any, channelList: any) {
  return channelList.find((channel: any) => channel.id === channelId)
}

export function getChannelServiceNameFromChannel(channel: any) {
  return channel?.service
}

export function getChannelFromAccount(
  account: Account,
  channelId: string,
): Channel {
  const channels = account?.currentOrganization?.channels || []

  return getChannelFromList(channelId, channels)
}

/**
 * Checks if the posting schedule for a given channel is empty.
 * 
 * @param channel - The channel object to check.
 * @returns True if the posting schedule is empty, false otherwise.
 * 
 * A posting schedule is considered empty if:
 * 1. The postingSchedule array doesn't exist or has no elements, or
 * 2. All schedules in the postingSchedule array have no times set.
 */
export function isPostingScheduleEmpty(channel: Channel): boolean {
  if (!channel?.postingSchedule?.length) return true
  return channel.postingSchedule.every(
    (schedule) => schedule.times.length === 0,
  )
}
