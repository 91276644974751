import { graphql } from '~publish/gql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'

export const UPDATE_CHANNEL_POSTING_GOAL_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateChannelPostingGoal($input: UpdateChannelPostingGoalInput!) {
    updateChannelPostingGoal(input: $input) {
      __typename
      ... on UpdateChannelPostingGoalResponse {
        channel {
          id
          postingGoal {
            goal
            sentCount
            scheduledCount
            periodStart
            periodEnd
            status
          }
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useUpdateChannelPostingGoal = () => {
  return useTypedMutation(
    UPDATE_CHANNEL_POSTING_GOAL_MUTATION,
    (data) => data.updateChannelPostingGoal,
    {
      successTypename: 'UpdateChannelPostingGoalResponse',
      refetchQueries: ['GetPostingScheduleInfo'],
    },
  )
}
