import React from 'react'
import styled from 'styled-components'

import type { Video } from '~publish/legacy/composer/composer/entities/factories'
import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'
import { getOrientation } from '../../../Common/utils'

import * as Styles from './styles'

const StyledVideoPlayer = styled(VideoPlayerCommon)`
  ${Styles.Wrapper}
`

interface VideoPlayerProps {
  video: Video
}

const VideoPlayer = ({ video }: VideoPlayerProps): JSX.Element => {
  const { height, width } = video
  const orientation = getOrientation({ height, width })

  return (
    <StyledVideoPlayer
      video={video}
      orientation={orientation}
      showSoundOffIcon
    />
  )
}

export { VideoPlayer }
