import React from 'react'

import {
  BufferTopIcon,
  BufferMiddleIcon,
  BufferBottomIcon,
} from '@buffer-mono/legacy-bufferapp-components'

interface BufferLoadingProps {
  fullscreen?: boolean
  size?: number | object
  dark?: boolean
}

const loadingStyle = ({ dark }: { dark?: boolean }): React.CSSProperties => ({
  width: '100%',
  height: '100%',
  background: dark ? 'rgba(50,59,67,0.8)' : 'white',
  zIndex: 12000,
  textAlign: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
})

const getIconContainerStyle = ({
  size,
  fullscreen,
}: {
  size?: number | object
  fullscreen?: boolean
}): React.CSSProperties => ({
  display: 'inline-block',
  width: `${size}px`,
  height: `${size}px`,
  position: fullscreen ? 'absolute' : 'relative',
  top: fullscreen ? '50%' : 'auto',
  left: fullscreen ? '50%' : 'auto',
  margin: fullscreen ? `-${Number(size) / 2}px 0 0 -${Number(size) / 2}px` : 0,
})

const getIconPartStyle = (delay: string): React.CSSProperties => ({
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  animation: `2s ${delay} fade infinite`,
})

const BufferLoading = ({
  fullscreen = false,
  size = 100,
  dark = false,
}: BufferLoadingProps): JSX.Element => (
  <div style={fullscreen ? loadingStyle({ dark }) : undefined}>
    <div style={getIconContainerStyle({ size, fullscreen })}>
      <div style={getIconPartStyle('0ms')}>
        <BufferTopIcon
          color={dark ? 'white' : 'shuttleGray'}
          size={{ width: `${size}`, height: `${size}` }}
        />
      </div>
      <div style={getIconPartStyle('150ms')}>
        <BufferMiddleIcon
          color={dark ? 'white' : 'shuttleGray'}
          size={{ width: `${size}`, height: `${size}` }}
        />
      </div>
      <div style={getIconPartStyle('300ms')}>
        <BufferBottomIcon
          color={dark ? 'white' : 'shuttleGray'}
          size={{ width: `${size}`, height: `${size}` }}
        />
      </div>
    </div>
  </div>
)

export default BufferLoading
