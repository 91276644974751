import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import { GetAccountConnectedApps } from './useConnectedApps'
import { toast } from '@buffer-mono/popcorn'

export const RevokeAccessConnectedAppMutation = graphql(/* GraphQL */ `
  mutation revokeAccessConnectedApp($input: RevokeAccessConnectedAppInput!) {
    revokeAccessConnectedApp(input: $input) {
      __typename
      ... on EmptySuccess {
        _empty
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

type RevokeAccessConnectedApp = ({
  clientId,
}: {
  clientId: string
}) => Promise<void>

type RevokeAccessConnectedAppResponse = {
  revokeAccessConnectedApp: RevokeAccessConnectedApp
  isLoading: boolean
  hasError: boolean
}

/**
 * Custom hook to update and Accounts start of week preference mutation.
 * @returns {UpdateStartOfWeekPreferenceResponse} The function to trigger updating the start of week preference.
 */
export const useRevokeApp = (): RevokeAccessConnectedAppResponse => {
  const [executeRevokeAccessConnectedApp, { loading, error }] = useMutation(
    RevokeAccessConnectedAppMutation,
  )

  const revokeAccessConnectedApp = useCallback(
    async ({ clientId }: { clientId: string }) => {
      try {
        const { data, errors } = await executeRevokeAccessConnectedApp({
          variables: {
            input: {
              clientId,
            },
          },
          refetchQueries: [GetAccountConnectedApps],
        })

        if (data?.revokeAccessConnectedApp?.__typename === 'UnexpectedError') {
          throw new Error(data.revokeAccessConnectedApp.message)
        }

        // Handle GraphQL errors if any
        if (
          errors?.length ||
          data?.revokeAccessConnectedApp?.__typename !== 'EmptySuccess'
        ) {
          const unexpectedError = new Error(
            errors?.[0]?.message || 'Unexpected API response',
          )
          logError(unexpectedError, { metaData: { data, errors } })
          throw unexpectedError
        }

        toast.success('We’ve revoked the access of the app')
      } catch (error) {
        logError(error as Error, {
          metaData: { variables: { clientId } },
        })
        throw error
      }
    },
    [executeRevokeAccessConnectedApp],
  )

  return {
    revokeAccessConnectedApp,
    isLoading: loading,
    hasError: !!error,
  }
}
