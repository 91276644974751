import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'

import {
  Button,
  CheckIcon,
  CloseIcon,
  EyeIcon,
  EyeOffIcon,
  Flex,
  Form,
  IconButton,
  Input,
  Text,
  toast,
} from '@buffer-mono/popcorn'

import { getLogoutUrl } from '@buffer-mono/app-shell/src/common/utils/urls'

import { graphql } from '~publish/gql'

import style from '../ProfileSettings.module.css'

import ConfirmPasswordModal from '../components/ConfirmPasswordModal'

export const ACCOUNT_UPDATE_PASSWORD = graphql(/* GraphQL */ `
  mutation AccountUpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      ... on EmptySuccess {
        _empty
      }
      ... on InvalidInputError {
        message
      }
      ... on UnauthorizedError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

interface PasswordRule {
  rule: string
  isValid: (password: string) => boolean
}

// prettier-ignore
const PASSWORD_RULES: PasswordRule[] = [
  { rule: 'At least 8 characters long', isValid: (p: string) => p.length >= 8 },
  { rule: 'Contains at least 1 uppercase character', isValid: (p: string) => !!p.match(/[A-Z]/) },
  { rule: 'Contains at least 1 number or symbol', isValid: (p: string) => !!p.match(/[\d\W]/) },
]

export const Password = (): JSX.Element => {
  const [updatePassword, { data, error }] = useMutation(ACCOUNT_UPDATE_PASSWORD)

  const [isMasked, setIsMasked] = useState(true)
  const [newPassword, setNewPassword] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  function handleSubmit(password: string): void {
    updatePassword({
      variables: {
        input: {
          password,
          newPassword,
        },
      },
    })
  }

  // Handle password update
  useEffect(() => {
    if (data?.updatePassword?.__typename === 'EmptySuccess') {
      toast.success(
        'Your password has been updated! You will be redirected to the login page.',
        {
          duration: 5_000,
        },
      )
      window.location.assign(getLogoutUrl(window.location.href))
    } else if (data?.updatePassword?.__typename === 'InvalidInputError') {
      toast.error(data.updatePassword.message)
    } else if (data?.updatePassword?.__typename === 'UnauthorizedError') {
      toast.error(data.updatePassword.message)
    } else if (data?.updatePassword?.__typename === 'UnexpectedError') {
      toast.error('An error occurred while updating your password.')
    } else if (error) {
      toast.error('An error occurred while updating your password.')
    }
  }, [data, error])

  // Handle password validation

  useEffect(() => {
    setIsValidPassword(
      PASSWORD_RULES.every((rule) => rule.isValid(newPassword)),
    )
  }, [newPassword])

  return (
    <Form name="updatePasswordForm" className={style.fullWidth}>
      <Form.Field name="np" className={style.fullWidth}>
        <Form.Label htmlFor="np">Password</Form.Label>
        <Flex gap="xs" align="center" className={style.fullWidth}>
          <Form.Control>
            <Input
              ref={inputRef}
              className={style.flexInput}
              type={isMasked ? 'password' : 'text'}
              data-1p-ignore="true"
              name="np"
              placeholder="New password"
              onChange={(event): void => setNewPassword(event.target.value)}
              aria-invalid={newPassword && !isValidPassword ? true : undefined}
              aria-describedby="password-validation-messages"
              suffix={
                newPassword && (
                  <IconButton
                    label={isMasked ? 'Show password' : 'Hide password'}
                    size="small"
                    variant="tertiary"
                    onClick={(): void => {
                      setIsMasked(!isMasked)
                      inputRef.current?.focus()
                    }}
                  >
                    {isMasked ? (
                      <EyeIcon size="small" />
                    ) : (
                      <EyeOffIcon size="small" />
                    )}
                  </IconButton>
                )
              }
            />
          </Form.Control>
          <ConfirmPasswordModal
            description="To confirm this password change, please enter your previous password."
            onConfirm={(password): void => {
              handleSubmit(password)
            }}
          >
            <Button
              size="medium"
              variant="secondary"
              disabled={!newPassword || !isValidPassword}
              className={style.fixedWidthButton}
            >
              Change Password
            </Button>
          </ConfirmPasswordModal>
        </Flex>
      </Form.Field>
      {newPassword && (
        <div
          className={style.passwordRules}
          id="password-validation-messages"
          aria-live="polite"
        >
          <Flex gap="xs" direction="column" align="start">
            {PASSWORD_RULES.map((rule) => (
              <Flex key={rule.rule} gap="sm" align="center" direction="row">
                {rule.isValid(newPassword) ? (
                  <CheckIcon size="small" color="success" />
                ) : (
                  <CloseIcon size="small" color="critical" />
                )}
                <Text
                  color={rule.isValid(newPassword) ? 'success' : 'critical'}
                >
                  {rule.rule}
                </Text>
              </Flex>
            ))}
          </Flex>
        </div>
      )}
    </Form>
  )
}

export default Password
