import {
  DropdownMenu,
  LockIcon,
  RssIcon,
  UpgradeBadge,
  useDropdownMenu,
} from '@buffer-mono/popcorn'
import React from 'react'
import { FreeFeedsLimitReachedDialog } from '../FreeFeedsLimitReachedDialog/FreeFeedsLimitReachedDialog'
import {
  SubscribeToFeedDialog,
  type SubscribeToFeedDialogProps,
} from './SubscribeToFeedDialog'
import styles from './SubscribeToFeedDialog.module.css'
import { HardLimitReachedDialog } from '../HardLimitReachedDialog/HardLimitReachedDialog'

type SubscribeToFeedDialogDropdownMenuItemProps = Omit<
  SubscribeToFeedDialogProps,
  'onUpgrade' | 'children'
> & {
  limitReached: boolean
  buttonText?: string
  tier: 'free' | 'paid'
  onUpgrade: (args: { cta: CTA; upgradePathName: string }) => void
}
export const SubscribeToFeedDialogDropdownMenuItem = ({
  onUpgrade,
  ...props
}: SubscribeToFeedDialogDropdownMenuItemProps): JSX.Element => {
  const { close } = useDropdownMenu()

  const closeDropdownOnDialogClose = (open: boolean): void => {
    if (!open) {
      close()
    }
    props?.onOpenChange?.(open)
  }

  const handleUpgradeClick = (): void => {
    onUpgrade({
      cta: 'create-feeds-upgradeDialog-upgradeButton-1',
      upgradePathName: 'feeds-subscribeToFeed-upgrade',
    })
    // UpgradeDialog overlay blocks plan selector so we need to close it once upgrade button is clicked
    close()
  }

  return (
    <HardLimitReachedDialog
      key="subscribeToFeedDialog-hardLimitReached"
      enabled={props.limitReached && props.tier === 'paid'}
      type="feeds"
    >
      <FreeFeedsLimitReachedDialog
        key="subscribeToFeedDialog-upgradeDialog"
        enabled={props.limitReached && props.tier === 'free'}
        onUpgrade={handleUpgradeClick}
      >
        <SubscribeToFeedDialog
          key="subscribeToFeedDialog"
          enabled={!props.limitReached}
          onOpenChange={closeDropdownOnDialogClose}
          {...props}
        >
          <DropdownMenu.Item
            onSelect={(e): void => {
              e.preventDefault()
            }}
          >
            <RssIcon /> {props.buttonText || 'Add Feed'}
            {props.limitReached && props.tier === 'paid' ? (
              <LockIcon className={styles.suffixIcon} />
            ) : props.limitReached && props.tier === 'free' ? (
              <UpgradeBadge size="xsmall" className={styles.suffixIcon} />
            ) : null}
          </DropdownMenu.Item>
        </SubscribeToFeedDialog>
      </FreeFeedsLimitReachedDialog>
    </HardLimitReachedDialog>
  )
}
