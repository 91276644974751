import React from 'react'
import { Flex, Skeleton, SkeletonText, Text } from '@buffer-mono/popcorn'

const BetaFeaturesSkeleton = ({
  betaFeaturesCount,
}: {
  betaFeaturesCount: number
}): JSX.Element => {
  return betaFeaturesCount !== 0 ? (
    <Flex gap="xl" align="end" fullWidth style={{ padding: 'var(--space-sm)' }}>
      <Flex gap="sm" align="start" direction="column" justify="between">
        <SkeletonText lines={1} width={100} />
        <SkeletonText lines={1} width={400} />
      </Flex>
      <Skeleton height={30} width={200} />
    </Flex>
  ) : (
    <Flex fullWidth style={{ padding: 'var(--space-sm) 0' }}>
      <Text color="subtle">
        There are currently no Beta features - check back soon, or ask us
        what&apos;s coming up in the Discord community!
      </Text>
    </Flex>
  )
}

export default BetaFeaturesSkeleton
