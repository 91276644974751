import React from 'react'
import { useCalendarContext } from './context'
import type { CalendarItem } from './types'

interface ItemProps extends CalendarItem {
  isDraggingOver?: boolean
}
const RawItem = function Item({
  isDraggingOver,
  ...props
}: ItemProps): JSX.Element | null {
  const { renderItem } = useCalendarContext()
  return renderItem({ ...props, isDraggingOver })
}

export const Item = React.memo(RawItem)
