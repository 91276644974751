/**
 * Component that handles the multiple Save buttons in the composer.
 */
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import Button from '@bufferapp/ui/Button'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'
import dayjs from 'dayjs'
import dayjsTimezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import React, { useState } from 'react'
import styled from 'styled-components'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'
import { AppEnvironments } from '~publish/legacy/constants'
import { useAppSelector } from '~publish/legacy/store'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'

import { useAccount } from '../../../../accountContext'
import { NotificationScopes, SaveButtonTypes } from '../../AppConstants'
import DateTimeSlotPicker from '../DateTimeSlotPicker'
import Dropdown, { DropdownContent, DropdownTrigger } from '../Dropdown'
import NotificationContainer from '../NotificationContainer'
import {
  addedToQueueCopyMap,
  addingToQueueCopyMap,
  getDraftButtons,
  getSaveButton,
  getSaveButtonsCopy,
} from './action-buttons'
import { DraftSaveButtons } from './DraftSaveButtons'
import OmniboxButtons from './OmniboxButtons'
import { StackedSaveButtons } from './StackedSaveButtons'
import UpdateSaverItem from './UpdateSaverItem'

import { Tooltip, type TooltipProps } from '@buffer-mono/popcorn'
import ModalActionCreators from '../../shared-components/modal/actionCreators'
import styles from '../css/UpdateSaver.module.css'
import { RemoveScheduleButton } from './style'
import { getFormattedWhatPreventsSavingMessages } from './utils'
import { useBeforeFirstAction } from './hooks/useBeforeFirstAction'
import { ConfirmationCoachMark } from '~publish/legacy/reminders/components/new-reminders/components/confirmation-coachmark/ConfirmationCoachmark'
import { getThreadedUpdatesUpgradePath } from '~publish/legacy/composer/composer/utils/upgradePaths'
import type {
  AppState,
  AppStoreMetaData,
  ComposerProfile,
  ComposerQueueingType,
  ComposerSaveButton,
  ComposerUserData,
  Timeslot,
  ValidationFeedback,
} from '../../stores/types'
import type { Notification } from '../../values/Notification'
import type { DraftPreview } from '../post-preview/previews/types'

dayjs.extend(utc)
dayjs.extend(dayjsTimezone)

const ActionButtons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const TooltipWrapper = ({ ...props }: TooltipProps): JSX.Element => {
  if (!props.content) {
    return <>{props.children}</>
  }
  return <Tooltip {...props} />
}
interface UpdateSaverProps {
  appState: AppState
  metaData: AppStoreMetaData
  userData: ComposerUserData
  visibleNotifications: Notification[]
  isSlotPickingAvailable: boolean
  saveButtons: ComposerSaveButton[]
  selectedProfiles: ComposerProfile[]
  timezone?: string
  moreThanOneProfileSelected: boolean
  areAllDraftsSaved: boolean
  whatPreventsSavingMessages: ValidationFeedback[]
  whatPreventsSavingDraftMessages: ValidationFeedback[]
  scheduledAt: number | null
  availableSchedulesSlotsForDay: false | undefined | Timeslot[]
  isPinnedToSlot: boolean
  sentPost: boolean
  previewMode: boolean
  draftMode: boolean
  shouldShowThreadsMigrationModal: boolean
  threadsMigrationModalService?: string
  draftPreview?: DraftPreview
}

const UpdateSaver = (props: UpdateSaverProps): JSX.Element | null => {
  const {
    appState,
    userData,
    metaData,
    visibleNotifications,
    timezone,
    moreThanOneProfileSelected,
    areAllDraftsSaved,
    saveButtons,
    isSlotPickingAvailable,
    availableSchedulesSlotsForDay = [],
    isPinnedToSlot,
    selectedProfiles,
    sentPost,
    previewMode,
    draftMode,
    whatPreventsSavingMessages: preventsSavingMessages = [],
    whatPreventsSavingDraftMessages = [],
    shouldShowThreadsMigrationModal,
    threadsMigrationModalService = 'omni',
    draftPreview,
  } = props
  let { scheduledAt } = props

  const [
    isInlineSchedulerDropdownExpanded,
    setIsInlineSchedulerDropdownExpanded,
  ] = useState(false)
  const user = useAccount()

  const commonTrackingProps =
    user?.account?.currentOrganization?.commonTrackingProperties || null

  const { contentGenerationInProgress } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )

  /**
   * DateTimeSlotPicker is displayed within a DropDown component, which takes care
   * of showing/hiding it when necessary. To do that, the DropDown component
   * listens to clicks in the whole document, and when a click happens on a
   * DOM element outside of DropdownContent, it is hidden. That's awesome, but
   * in some situations with DateTimeSlotPicker, users will click on buttons, and
   * the UI will change in such a way that the buttons that were clicked will
   * disappear: when that's the case, the DropDown component will wrongly deduct
   * that the click happened outside of the DropDown, and will proceed to hiding
   * the DateTimeSlotPicker.
   * To prevent this, we stop click event bubbling at the DateTimeSlotPicker level :)
   */
  const onDateTimeSlotPickerClick = (
    e: React.MouseEvent<HTMLDivElement>,
  ): void => e.stopPropagation()

  const onInlineSchedulerDateTimeSlotPickerChange = (
    selectedDateTime: dayjs.Dayjs,
    isPinnedToSlot: boolean,
  ): void => {
    const scheduledAt = selectedDateTime.unix()
    ComposerActionCreators.updateDraftsScheduledAt(scheduledAt, isPinnedToSlot)
  }

  const collapseInlineSchedulerDropdown = (): void => {
    setIsInlineSchedulerDropdownExpanded(false)
  }

  const expandInlineSchedulerDropdown = (): void => {
    setIsInlineSchedulerDropdownExpanded(true)
  }

  const onRemoveSchedule = (): void => {
    ComposerActionCreators.updateDraftsScheduledAt(null, false)
    collapseInlineSchedulerDropdown()
  }

  const {
    isSavingPossible,
    isSavingDraftPossible,
    isDraftsSavePending,
    draftSaveQueueingType,
    isOmniboxEnabled,
  } = appState

  const doSelectedProfilesHaveSlots = selectedProfiles.some(
    (profile): boolean => profile.profileHasPostingSchedule,
  )
  const beforeFirstAction = useBeforeFirstAction({ draftPreview })

  if (saveButtons.length === 0) return null

  const { weekStartsMonday } = userData

  const inlineButtonsWrapperClassName = [
    isDraftsSavePending
      ? styles.savePendingInlineButtonsWrapper
      : styles.inlineButtonsWrapper,
    'schedule-post-button',
  ].join(' ')

  const stackedButtonsWrapperClassName = [
    isDraftsSavePending
      ? styles.savePendingStackedButtonsWrapper
      : styles.stackedButtonsWrapper,
    'schedule-post-button',
  ].join(' ')

  const notificationContainerClassNames = {
    container: styles.notificationContainer,
    notification: styles.notification,
  }

  const saveButtonsCopy = getSaveButtonsCopy({
    moreThanOneProfileSelected,
    draftMode,
  })

  const getActiveSaveButtonCopy = (
    buttonType: ComposerSaveButton,
    queueingType: ComposerQueueingType,
  ): string | null => {
    let finalButtonCopy: string | undefined
    if (isDraftsSavePending)
      finalButtonCopy = addingToQueueCopyMap.get(queueingType)
    else if (areAllDraftsSaved)
      finalButtonCopy = addedToQueueCopyMap.get(queueingType)
    else finalButtonCopy = saveButtonsCopy.get(buttonType)

    return finalButtonCopy ?? null
  }

  // Determine whether we should render the inline scheduler
  // If so, we need to format the scheduledAt date
  if (sentPost) scheduledAt = null
  const shouldDisplayInlineScheduler = scheduledAt !== null
  let scheduledAtMoment: dayjs.Dayjs | undefined
  let humanReadableScheduledAt: string | undefined

  if (shouldDisplayInlineScheduler && scheduledAt) {
    scheduledAtMoment = dayjs.unix(scheduledAt)
    if (timezone) scheduledAtMoment = scheduledAtMoment.tz(timezone)

    const humanReadableFormat = userData.uses24hTime
      ? 'MMM D, H:mm'
      : 'MMM D, h:mm A'
    humanReadableScheduledAt = scheduledAtMoment.format(humanReadableFormat)
  }

  // if the user doesn't have any slots available, then they will
  // only see these two buttons in the composer.
  const shouldLimitButtonOptions =
    saveButtons[0] !== SaveButtonTypes.ADD_TO_DRAFTS &&
    saveButtons[0] !== SaveButtonTypes.SAVE &&
    !doSelectedProfilesHaveSlots &&
    !shouldDisplayInlineScheduler

  const buttons = shouldLimitButtonOptions
    ? [SaveButtonTypes.SHARE_NOW, SaveButtonTypes.SCHEDULE_POST]
    : saveButtons

  const [inlineSaveButtonTypes, stackedSaveButtonTypes] = getSaveButton(buttons)

  const isEditPost = buttons && buttons[0] === SaveButtonTypes.SAVE
  const displayThreadsPaywallButtons =
    shouldShowThreadsMigrationModal && !isEditPost && !draftMode
  const displayInlineSaveButtons =
    !isOmniboxEnabled &&
    inlineSaveButtonTypes.length > 0 &&
    !displayThreadsPaywallButtons
  const displayStackedSaveButtons =
    (!isOmniboxEnabled || previewMode) &&
    stackedSaveButtonTypes.length > 0 &&
    !displayThreadsPaywallButtons

  const [firstStackedButtonType, ...otherStackedButtonsTypes] =
    stackedSaveButtonTypes

  const firstStackedButtonCopy = getActiveSaveButtonCopy(
    firstStackedButtonType,
    draftSaveQueueingType,
  )

  const showRemoveSchedule = draftMode && scheduledAtMoment
  const shouldDisplayTime =
    (isEditPost && !doSelectedProfilesHaveSlots) || draftMode

  const showOmniboxButtons = isOmniboxEnabled && !previewMode
  const showAddToDrafts = !showOmniboxButtons && !draftMode && !isEditPost

  const isDraftSaveDisabled = !isSavingDraftPossible
  const isPostSaveDisabled = !isSavingPossible
  const isSaveDisabled =
    (draftMode ? isDraftSaveDisabled : isPostSaveDisabled) ||
    contentGenerationInProgress

  const hasInitialDate = metaData.emptySlotMode || shouldDisplayInlineScheduler
  const warningMessages = draftMode
    ? whatPreventsSavingDraftMessages
    : preventsSavingMessages
  const whatPreventsSavingMessages = getFormattedWhatPreventsSavingMessages({
    warningMessages,
  })

  const { isOneBufferOrganization } = user?.account?.currentOrganization || {}
  const planId =
    user?.account?.currentOrganization?.billing?.subscription?.plan?.id
  const isDraftPaywallButtonEnabled =
    planId === 'essentials' || planId === 'free'
  const showApprovalPaywall =
    isOneBufferOrganization && isDraftPaywallButtonEnabled
  const threadedUpdatesUpgradePath = getThreadedUpdatesUpgradePath(
    threadsMigrationModalService,
  )

  return (
    <section className={styles.section} data-testid="update-saver-buttons">
      {(shouldDisplayInlineScheduler || shouldDisplayTime) && (
        <div className={styles.postSchedule} data-testid="inline-scheduler">
          <span className={styles.postScheduleText}>
            Schedule date:
            <span className={styles.humanReadableScheduledAt}>
              {' '}
              {shouldDisplayInlineScheduler
                ? humanReadableScheduledAt
                : 'No Time Set'}
            </span>
          </span>
          <div>
            <Dropdown
              isDropdownExpanded={isInlineSchedulerDropdownExpanded}
              onHide={collapseInlineSchedulerDropdown}
              onShow={expandInlineSchedulerDropdown}
              className={styles.inlineDropdownContainer}
            >
              <DropdownTrigger className={styles.tertiaryButton}>
                {shouldDisplayInlineScheduler ? 'Edit' : 'Set Time'}
              </DropdownTrigger>
              <DropdownContent className={styles.rightAlignedDropdownContent}>
                <DateTimeSlotPicker
                  key={
                    String(scheduledAtMoment?.toISOString()) + String(timezone)
                  }
                  onClick={onDateTimeSlotPickerClick}
                  onChange={onInlineSchedulerDateTimeSlotPickerChange}
                  onSubmit={collapseInlineSchedulerDropdown}
                  shouldUse24hTime={userData.uses24hTime}
                  timezone={timezone}
                  weekStartsMonday={weekStartsMonday}
                  initialDateTime={scheduledAtMoment}
                  isSlotPickingAvailable={isSlotPickingAvailable}
                  availableSchedulesSlotsForDay={availableSchedulesSlotsForDay}
                  isPinnedToSlot={isPinnedToSlot}
                  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                  metaData={metaData}
                  submitButtonCopy="Done"
                  doSelectedProfilesHaveSlots={doSelectedProfilesHaveSlots}
                  showDraftScheduleNotice={draftMode}
                />
              </DropdownContent>
            </Dropdown>
            {showRemoveSchedule && (
              <RemoveScheduleButton
                type="button"
                data-testid="remove-schedule-time"
                className={styles.tertiaryButton}
                onClick={onRemoveSchedule}
              >
                Remove
              </RemoveScheduleButton>
            )}
          </div>
        </div>
      )}

      <ActionButtons>
        {showOmniboxButtons && <OmniboxButtons data-testid="omnibox-buttons" />}
        {showAddToDrafts && (
          <TooltipWrapper
            content={isDraftSaveDisabled ? whatPreventsSavingMessages : null}
            side="left"
          >
            <div
              data-testid="draft-save-buttons-section"
              className={inlineButtonsWrapperClassName}
              data-disabled={isDraftSaveDisabled}
            >
              <DraftSaveButtons
                isDisabled={isDraftSaveDisabled}
                timezone={timezone}
                draftSaveItems={getDraftButtons({
                  hasInitialDate,
                  showApprovalPaywall,
                })}
              />
            </div>
          </TooltipWrapper>
        )}

        {displayInlineSaveButtons && (
          <TooltipWrapper
            content={isSaveDisabled ? whatPreventsSavingMessages : null}
            side="left"
          >
            <div
              data-testid="inline-save-buttons"
              className={inlineButtonsWrapperClassName}
              data-disabled={isSaveDisabled}
            >
              {inlineSaveButtonTypes.map((saveButtonType, i) => (
                <UpdateSaverItem
                  key={saveButtonType}
                  type={saveButtonType}
                  draftMode={draftMode}
                  userData={userData}
                  timezone={timezone}
                  weekStartsMonday={weekStartsMonday}
                  isInlineSchedulerDisplayed={shouldDisplayInlineScheduler}
                  isSecondaryItem={i < inlineSaveButtonTypes.length - 1}
                >
                  {draftSaveQueueingType === saveButtonType
                    ? getActiveSaveButtonCopy(
                        saveButtonType,
                        draftSaveQueueingType,
                      )
                    : saveButtonsCopy.get(saveButtonType) ?? null}
                </UpdateSaverItem>
              ))}
            </div>
          </TooltipWrapper>
        )}

        {displayStackedSaveButtons && (
          <Tooltip
            content={!previewMode ? whatPreventsSavingMessages : undefined}
            side="left"
          >
            <div
              data-testid="stacked-save-buttons-section"
              className={stackedButtonsWrapperClassName}
              data-disabled={isSaveDisabled || previewMode}
            >
              <ConfirmationCoachMark selectedProfiles={selectedProfiles}>
                <div>
                  <StackedSaveButtons
                    isSavingPossible={isSavingPossible}
                    isSavingDraftPossible={isSavingDraftPossible}
                    draftMode={draftMode}
                    timezone={timezone}
                    firstStackedButtonLabel={firstStackedButtonCopy ?? ''}
                    firstStackedButtonType={firstStackedButtonType}
                    otherStackedButtonsTypes={otherStackedButtonsTypes}
                    beforeFirstAction={beforeFirstAction}
                    saveButtonsCopy={saveButtonsCopy}
                  />
                </div>
              </ConfirmationCoachMark>
            </div>
          </Tooltip>
        )}

        {displayThreadsPaywallButtons && (
          <Button
            data-testid="threads-free-paywall-button"
            icon={<FlashIcon color="white" />}
            type="primary"
            label="Upgrade to Schedule Thread"
            onClick={(): void => {
              BufferTracker.cTAClicked({
                organizationId: user?.account?.currentOrganization?.id || '',
                cta: `publish-composer-createPost-upgradeToScheduleThread-1`,
                upgradePathName: threadedUpdatesUpgradePath,
                product: 'publish',
                clientName: 'publishWeb',
                ...commonTrackingProps,
              })
              ModalActionCreators.openModal('ThreadsFreeOBPaywall', {
                ctaString: 'composer-upgradeButton-upgradeToScheduleThread-1',
                service: threadsMigrationModalService,
              })
            }}
          />
        )}
      </ActionButtons>

      {metaData.appEnvironment === AppEnvironments.EXTENSION && (
        <NotificationContainer
          visibleNotifications={visibleNotifications}
          scope={NotificationScopes.UPDATE_SAVING_AGGREGATE}
          classNames={notificationContainerClassNames}
        />
      )}
    </section>
  )
}

export default UpdateSaver
