import { ProductNames } from '../../../../../common/constants/products'
export const shouldTrackNavCTA = ({
  isFreeUser,
  activeProductId,
  currentProductId,
}: {
  isFreeUser: boolean
  activeProductId: ProductNames | undefined
  currentProductId: ProductNames | undefined
}): boolean => {
  // Analyze and Engage do not act as CTAs for paid users - don't track
  if (!isFreeUser) {
    return false
  }
  // Don't track if the user is already on the tab
  if (activeProductId === currentProductId) {
    return false
  }
  // Don't track for non-Analyze/Engage tabs
  if (
    currentProductId !== ProductNames.ANALYZE &&
    currentProductId !== ProductNames.ENGAGE
  ) {
    return false
  }

  return true
}
