import { Dialog, Flex, ToggleGroup, VisuallyHidden } from '@buffer-mono/popcorn'
import React, { useState } from 'react'
import {
  SelectFeedCards,
  type SelectFeedCardsProps,
} from '../SelectFeedCards/SelectFeedCards'
import { TrackRender } from '~publish/hooks/tracking/useTrackComponentRendered'
import styles from './ExploreFeedsDialog.module.css'
import { feedSuggestions } from './feedSuggestions'
import { useIsScrolled } from '~publish/hooks/useIsScrolled'
import type { WrappedCreateFeedResponse } from '../../hooks/useCreateFeed'

const categories = [
  'Buffer Favorites',
  'Tech',
  'News',
  'Business',
  'Art & Media',
  'Entertainment',
  'Science',
]

type ExploreFeedsDialogContentProps = Omit<
  SelectFeedCardsProps,
  'newFeeds' | 'onAddFeed'
> & {
  onAddFeed: (args: {
    feedUrl: string
    cta: 'add-from-url' | 'import-opml' | 'explore-feeds'
  }) => Promise<WrappedCreateFeedResponse>
}

const ExploreFeedsDialogContent = ({
  onAddFeed,
  ...props
}: ExploreFeedsDialogContentProps): JSX.Element => {
  const [selectedCategory, setSelectedCategory] = useState('Buffer Favorites')
  const { scrollRef, isScrolled } = useIsScrolled()

  const filteredFeeds = feedSuggestions.filter(
    (feed) => feed.category === selectedCategory,
  )

  const handleToggleGroupChange = (value: string): void => {
    // Ensure a value is always set
    if (value) {
      setSelectedCategory(value)
    }
  }

  const handleAddFeed = React.useCallback(
    async ({
      feedUrl,
    }: {
      feedUrl: string
    }): Promise<WrappedCreateFeedResponse> => {
      return onAddFeed({ feedUrl, cta: 'explore-feeds' })
    },
    [onAddFeed],
  )

  return (
    <Dialog.Content size="xlarge" className={styles.content} portal={false}>
      <Flex
        direction="column"
        align="center"
        justify="start"
        // gap="lg"
        fullHeight
      >
        <Dialog.Header className={styles.header}>
          <Dialog.Title size="large">Explore Feeds</Dialog.Title>
          <TrackRender componentName="ExploreFeedsDialog" />
          <VisuallyHidden>
            <Dialog.Description>
              Explore and add feeds from a curated list
            </Dialog.Description>
          </VisuallyHidden>

          <div className={styles.categoryToggleWrapper}>
            <ToggleGroup
              size="medium"
              orientation="horizontal"
              className={styles.categoryToggleGroup}
              value={selectedCategory}
              onChange={handleToggleGroupChange}
            >
              {categories.map((category) => (
                <ToggleGroup.Item key={category} value={category}>
                  {category}
                </ToggleGroup.Item>
              ))}
            </ToggleGroup>
          </div>
        </Dialog.Header>
        <Dialog.Separator
          data-scroll={isScrolled}
          className={styles.separator}
        />
        <Dialog.Body className={styles.body} ref={scrollRef}>
          <SelectFeedCards
            key={selectedCategory}
            className={styles.selectFeeds}
            {...props}
            newFeeds={filteredFeeds}
            onAddFeed={handleAddFeed}
          />
        </Dialog.Body>
      </Flex>
      <Dialog.CloseButton />
    </Dialog.Content>
  )
}

export type ExploreFeedsDialogProps = {
  onOpenChange?: (open: boolean) => void
  children?: React.ReactNode
} & ExploreFeedsDialogContentProps

export const ExploreFeedsDialog = ({
  onOpenChange,
  children,
  ...props
}: ExploreFeedsDialogProps): JSX.Element => {
  return (
    <Dialog onOpenChange={onOpenChange}>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <ExploreFeedsDialogContent {...props} />
      </Dialog.Portal>
    </Dialog>
  )
}
