import type { IReducerState as ChannelConnectionsState } from '../../exports/Orchestrator/store/channelConnectionsReducer'
import type { ModalsState } from '../../exports/Orchestrator/store/modalsReducer'
import type { ScheduledSubscriptionUpdate } from '../types/graphQL/Billing'
import type { ACCOUNT_ACTIONS_TYPE } from './accountEvents'
import type { ENGAGE_ACTIONS_TYPE } from './engageEvents'
import type { MODAL_ACTIONS_TYPE } from './modalEvents'
import type { ORCHESTRATOR_ACTIONS_TYPE } from './orchestratorEvents'
import type { ORGANIZATION_ACTIONS_TYPE } from './orgEvents'
import type { Service } from '../../exports/Orchestrator/channelConnections/types'

export enum AppshellEventKeys {
  MODAL_EVENT_KEY = 'appshell__modal_event',
  ORCHESTRATOR_EVENT_KEY = 'appshell__modal_event',
  ORGANIZATION_EVENT_KEY = 'appshell__organization_event',
  BILLING_EVENT_KEY = 'appshell__billing_event',
  ACCOUNT_EVENT_KEY = 'appshell__account_event',
  ENGAGE_COMMENT_EVENT_KEY = 'appshell__engage_comment_event',
  OPEN_COMPOSER_EVENT_KEY = 'appshell__open_composer_event',
  OPEN_IDEA_COMPOSER_EVENT_KEY = 'appshell__open_idea_composer_event',
  STREAK_UPDATE_EVENT_KEY = 'appshell__streak_update_event',
  NEW_COMMENT_EVENT_KEY = 'appshell__new_comment_event',
}

export enum OrchestratorActionKeys {
  renderComponent = 'renderComponent',
}

export enum OrganizationActionKeys {
  setCurrentOrganization = 'setCurrentOrganization',
  billingUpdated = 'billingUpdated',
  refetchAccount = 'refetchAccount',
  refetchEngageComments = 'refetchEngageComments',
  openComposer = 'openComposer',
  openIdeaComposer = 'openIdeaComposer',
}

export enum AccountType {
  group = 'group',
  personal = 'personal',
}

export type ModalData = {
  cta?: string
  ctaButton?: string
  ctaView?: string
  ctaLocation?: string
  ctaVersion?: string
  shouldPickModalOnOrganizationState?: boolean
  channelIds?: string[]
  channelId?: string
  source?: string
  // TODO: Use ChangePlanOption type from billing
  selectedPlan?: any
  onlyUpdatedCardDetails?: boolean
  startedTrial?: boolean
  stayedOnSamePlan?: boolean
  isMigrationFromMP?: boolean
  defaultChannelQuantityValue?: number
  upgradePathName?: string
  modalHeader?: string
  paywalledFeature?: string
  featurePaywallProperties?: {
    headline: string
    description?: string
    body: Array<string>
  }
  isDowngradingToFreePlan?: boolean
  subscriptionType?: string
  isOnTrial?: boolean
  scheduledUpdate?: ScheduledSubscriptionUpdate
  currentPlanId?: string
  currentPlanChannelQuantity?: number
  accountType?: AccountType
  channelType?: string
  onRedirect?: (redirectUrl: string) => void
  onCustomCloseAction?: () => void
  onContinue?: () => void
  onDismiss?: () => void
  channelName?: string
  channelAvatar?: string
  channelUrl?: string
  isRefreshingConnection?: boolean
}

export type RenderComponentParams = {
  componentKey: string
  containerId: string
  options?: ModalData
}

export type ConnectChannelParams = {
  selectedService: Service

  isRefreshingConnection?: boolean
  name?: string
  destinationUrl?: string
  cta?: string
  selectedRefreshChannelId?: string
  customChannelIdToConvert?: string
  authMethod?: string
  skipCelebrationModal?: boolean
  // used in e.g. SwitchAccount so that we can proceed with the connection without showing configuration modal again
  skipConfigurationModal?: boolean
  prefillDataAfterConnection?: boolean

  // if passed and user is not and admin - open channel storefront
  role?: string

  // if true - will not check if multiple accounts are on the same network
  skipChannelCheck?: boolean
}

export interface AppshellActions
  extends ORCHESTRATOR_ACTIONS_TYPE,
    ACCOUNT_ACTIONS_TYPE,
    ORGANIZATION_ACTIONS_TYPE,
    ENGAGE_ACTIONS_TYPE,
    MODAL_ACTIONS_TYPE {}

export interface ComponentOrchestrator {
  showComponent: boolean
  componentParams: RenderComponentParams
}

export interface OrchestratorRootState {
  components: ComponentOrchestrator
  channelConnections: ChannelConnectionsState
  modals: ModalsState
}
