import {
  Card,
  ChannelAvatar,
  DropdownMenu,
  EllipsisVerticalIcon,
  IconButton,
  SettingsIcon,
  Text,
  useIsMobileScreenSize,
} from '@buffer-mono/popcorn'
import React from 'react'

import style from './ChannelRow.module.css'
import ChannelStatusSection from './ChannelStatusSection'
import ChannelDropdownItems from './ChannelDropdownItems'
import clsx from 'clsx'
import { isStartPage } from '../util/isStartPage'
import { Status, getChannelStatus } from '../util/getChannelStatus'
import type { ChannelMask } from '../hooks/useChannelsForSettings'
import { useRefreshChannelConnection } from '~publish/hooks/useRefreshChannelConnection'
import { Link } from 'react-router-dom'

/**
 * Returns channel description text based on the channel type
 * @param channel - The channel to get the descriptor for.
 * @returns The descriptor for the channel.
 */
const getChannelDescriptor = (channel: ChannelMask): string => {
  const isChannelStartPage = isStartPage({ channel })
  if (isChannelStartPage) {
    return 'Start Page'
  }

  let channelDescriptor = channel.descriptor
  if (
    channel.metadata?.__typename === 'MastodonMetadata' ||
    channel.metadata?.__typename === 'BlueskyMetadata'
  ) {
    channelDescriptor = `${channelDescriptor} • ${channel.metadata.serverUrl}`
  }
  if (
    channel.metadata?.__typename === 'GoogleBusinessMetadata' ||
    channel.metadata?.__typename === 'FacebookMetadata'
  ) {
    channelDescriptor = `${channelDescriptor} • ${channel.metadata.locationData?.location}`
  }
  return channelDescriptor
}

export interface ChannelRowProps {
  channel: ChannelMask
}

const ChannelRow = ({ channel }: ChannelRowProps): JSX.Element => {
  const refreshChannelConnection = useRefreshChannelConnection()
  const handleRefreshChannel = (cta: string): void => {
    refreshChannelConnection({
      channel,
      cta,
    })
  }

  const { status } = getChannelStatus({
    channel,
  })
  const channelDescriptor = getChannelDescriptor(channel)

  const isMobile = useIsMobileScreenSize()

  return (
    <Card
      padding="small"
      className={clsx(style.base, status === Status.Locked && style.locked)}
    >
      <ChannelAvatar
        src={channel.avatar}
        alt={channel.displayName || channel.name}
        channel={channel.service}
        size="mid"
        className={style.avatar}
      />
      <div className={style.channelRowCenter}>
        <Text
          weight="bold"
          className={clsx(status === Status.Locked && style.locked)}
        >
          {channel.name || channel.displayName}
        </Text>
        <Text
          color={status === Status.Locked ? 'disabled' : 'subtle'}
          className={style.channelDescriptor}
        >
          {channelDescriptor}
        </Text>
      </div>
      <div className={style.statusSection}>
        <ChannelStatusSection
          channel={channel}
          status={status}
          refreshChannelConnection={handleRefreshChannel}
        />
      </div>
      <div className={style.channelRowRight}>
        {status !== Status.Locked && !isMobile && (
          <IconButton
            label={`Settings for ${channel.name || channel.displayName}`}
            size="medium"
            variant="tertiary"
            as={Link}
            to={`/settings/channels/${channel.id}`}
          >
            <SettingsIcon />
          </IconButton>
        )}
        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <IconButton
              label={`More options for ${channel.name || channel.displayName}`}
              size="medium"
              variant="tertiary"
            >
              <EllipsisVerticalIcon />
            </IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="end">
            <ChannelDropdownItems
              channel={channel}
              status={status}
              refreshChannelConnection={handleRefreshChannel}
              showSettingsLink={isMobile}
            />
          </DropdownMenu.Content>
        </DropdownMenu>
      </div>
    </Card>
  )
}

export default ChannelRow
