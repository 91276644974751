import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import type { BetaFeature } from '~publish/gql/graphql'
import { useQuery } from '@apollo/client'

export const GetBetaFeatures = graphql(/* GraphQL */ `
  query getBetaFeatures {
    betaFeatures {
      name
      description
      feedbackUrl
      platforms
    }
  }
`)

type GetBetaFeatures = {
  betaFeatures: BetaFeature[] | null
  isLoading: boolean
  hasError: boolean
}

/**
 * Custom hook to query the beta features
 * @returns {GetBetaFeatures} The accounts beta features
 */
export const useBetaFeatures = (): GetBetaFeatures => {
  const { data, loading, error } = useQuery(GetBetaFeatures)

  if (!data?.betaFeatures && !loading && !error) {
    throw new Error('No beta features found')
  }

  // Handle GraphQL errors if any
  if (error) {
    const unexpectedError = new Error(
      error?.message || 'Unexpected API response',
    )
    logError(unexpectedError, { metaData: { data, error } })
    throw unexpectedError
  }

  return {
    betaFeatures: data?.betaFeatures ?? null,
    isLoading: loading,
    hasError: !!error,
  }
}
