import React from 'react'
import { useParams } from 'react-router-dom'

import { EmptyState, Flex } from '@buffer-mono/popcorn'

import { ScheduleTableCell } from './ScheduleTableCell'
import { ScheduleTableHeader } from './ScheduleTableHeader'
import { usePostingSchedule } from '../../hooks/usePostingSchedule'

import styles from './ScheduleTable.module.css'

const ScheduleTable = (): JSX.Element => {
  const { id: channelId } = useParams<{
    id: string
  }>()
  const {
    schedule,
    isEmpty,
    togglePause,
    removePostingTime,
    updatePostingTime,
    hasEditingPrivilege,
  } = usePostingSchedule(channelId)

  const disabled = !hasEditingPrivilege

  return (
    <Flex direction="column" className={styles.tableWrapper}>
      <Flex className={styles.table} align="stretch">
        {schedule.map(({ day, times, paused }) => {
          const hasTimes = times.length > 0

          const disabledColumn = (paused && !isEmpty) || disabled

          return (
            <Flex
              align="stretch"
              data-testid="posting-schedule-column"
              key={day}
              data-disabled={disabledColumn}
              className={styles.column}
            >
              <Flex
                className={`${styles.column} ${
                  disabledColumn ? styles.disabledColumn : ''
                }`}
                direction="column"
                align="center"
              >
                <ScheduleTableHeader
                  day={day}
                  paused={paused}
                  onTogglePause={(): Promise<void> => togglePause(day)}
                  disabled={disabled}
                  isScheduleEmpty={isEmpty}
                />
                {hasTimes && (
                  <Flex
                    align="center"
                    direction="column"
                    className={styles.columnContent}
                  >
                    {times.map((time) => (
                      <ScheduleTableCell
                        key={`cell-${day}-${time}`}
                        time={{
                          hours: parseInt(time.split(':')[0], 10),
                          minutes: parseInt(time.split(':')[1], 10),
                        }}
                        disabled={disabled}
                        onRemoveTimeClick={(): Promise<void> =>
                          removePostingTime(day, {
                            hours: parseInt(time.split(':')[0], 10),
                            minutes: parseInt(time.split(':')[1], 10),
                          })
                        }
                        onUpdateTime={(newTime): Promise<void> =>
                          updatePostingTime(
                            day,
                            {
                              hours: parseInt(time.split(':')[0], 10),
                              minutes: parseInt(time.split(':')[1], 10),
                            },
                            {
                              hours: newTime.hours,
                              minutes: newTime.minutes,
                            },
                          )
                        }
                      />
                    ))}
                  </Flex>
                )}
              </Flex>
            </Flex>
          )
        })}
      </Flex>
      {isEmpty && (
        <EmptyState
          className={styles.emptyState}
          data-testid="posting-schedule-empty-state"
        >
          <EmptyState.Description>
            You don&apos;t have any posting times set!
            <br />
            Add a new posting time to start publishing posts from your queue.
          </EmptyState.Description>
        </EmptyState>
      )}
    </Flex>
  )
}

export { ScheduleTable }
