import CaretLeftIcon from '@bufferapp/ui/Icon/Icons/CaretLeft'
import CaretRightIcon from '@bufferapp/ui/Icon/Icons/CaretRight'
import React, { useCallback, useMemo } from 'react'
import { UploaderDropzone } from '~publish/legacy/uploads/components/UploaderDropzone'
import {
  FileUploadConfig,
  defaultRestrictions,
  type BufferUpload,
} from '@buffer-mono/uploader'
import { useAppSelector } from '~publish/legacy/store'
import {
  selectPendingCount,
  selectPendingProgress,
} from '~publish/legacy/uploads/state/selectors'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import AppDispatcher from '../../dispatcher'
import { ActionTypes } from '../../state/ActionTypes'
import { escapeParens } from '../../utils/StringUtils'
import * as Styles from './styles'
import usePublishUploader from '~publish/legacy/uploads/hooks/usePublishUploader'

export interface LinkAttachmentThumbnailEditorProps {
  draftId: string
  selectedThumbnail?: {
    url: string
  } | null
  availableThumbnails?: string[] | null
}

/**
 * Component that displays and allows to navigate among a provided list
 * of suggested media
 */
export const LinkAttachmentThumbnailEditor = ({
  draftId,
  selectedThumbnail,
  availableThumbnails,
}: LinkAttachmentThumbnailEditorProps): JSX.Element => {
  const selectPreviousThumbnail = () =>
    ComposerActionCreators.selectPreviousLinkThumbnail(draftId)

  const selectNextThumbnail = () =>
    ComposerActionCreators.selectNextLinkThumbnail(draftId)

  const onUploadFinished = useCallback(
    (upload: BufferUpload) => {
      AppDispatcher.handleViewAction({
        actionType: ActionTypes.UPLOADED_LINK_THUMBNAIL,
        id: draftId,
        url: upload.url,
        width: upload.dimensions?.width,
        height: upload.dimensions?.height,
      })
    },
    [draftId],
  )

  const fileRestrictions = useMemo(() => {
    return {
      ...defaultRestrictions,
      uploadConfig: FileUploadConfig.IMAGE,
    }
  }, [])

  const eventHandlers = useMemo(() => {
    return {
      'upload-finished': onUploadFinished,
    }
  }, [onUploadFinished])

  const uploaderId = 'link-thumbnail-uploader'
  const uploader = usePublishUploader({
    id: uploaderId,
    fileRestrictions,
    eventHandlers,
  })

  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, uploaderId),
  )
  const pendingProgress = useAppSelector((state) =>
    selectPendingProgress(state, uploaderId),
  )
  const isUploading = pendingCount > 0

  const hasThumbnail = selectedThumbnail != null
  const hasMoreThanOneThumbnail =
    availableThumbnails != null && availableThumbnails.length > 1

  return (
    <Styles.Container>
      {hasThumbnail && (
        <Styles.Thumbnail
          style={{
            backgroundImage: `url(${escapeParens(selectedThumbnail.url)})`,
          }}
          role="img"
          aria-label="Link Thumbnail"
        />
      )}

      {/* TODO: Replace with UploadDropzone component - https://linear.app/buffer/issue/CONT-574/extend-uploaddropzone-to-support-button */}
      <UploaderDropzone
        uploader={uploader}
        isUploading={isUploading}
        uploadProgress={pendingProgress}
        activeUploadsCount={pendingCount}
        disabled={isUploading}
        showMessageOnHover
        multiple={false}
      />

      {hasMoreThanOneThumbnail && (
        <Styles.ScrollLeftButton
          onClick={selectPreviousThumbnail}
          aria-label="Scroll suggested media left"
        >
          <CaretLeftIcon />
        </Styles.ScrollLeftButton>
      )}

      {hasMoreThanOneThumbnail && (
        <Styles.ScrollRightButton
          onClick={selectNextThumbnail}
          aria-label="Scroll suggested media right"
        >
          <CaretRightIcon />
        </Styles.ScrollRightButton>
      )}
    </Styles.Container>
  )
}
