import React from 'react'
import { PlusIcon, Sidebar } from '@buffer-mono/popcorn'

import { useIsAdmin } from '~publish/legacy/accountContext'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'

import styles from './ChannelNav.module.css'

export function NewChannelButton(): JSX.Element {
  const isAdmin = useIsAdmin()

  if (!isAdmin) {
    return <></>
  }

  return (
    <Sidebar.ListItem>
      <FeedbackWidget
        id="comments-sidebar-channel-1"
        source="comments"
        description="More channels coming soon to Comment. Let us know which ones we should
          add first."
      >
        <Sidebar.Button
          size="large"
          prefix={
            <span className={styles.icon}>
              <PlusIcon />
            </span>
          }
          className={styles.newChannelButton}
        >
          New Channel
        </Sidebar.Button>
      </FeedbackWidget>
    </Sidebar.ListItem>
  )
}
