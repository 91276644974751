import React, {
  type ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Avatar,
  Button,
  Card,
  ChevronDownIcon,
  CriticalIcon,
  Dialog,
  DropdownMenu,
  Flex,
  Heading,
  HelpIcon,
  ImageIcon,
  ImagePlusIcon,
  Input,
  Label,
  Paragraph,
  Text,
  Tooltip,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import type { OrchestratorRootState } from '../../../../../../../../common/events/types'
import {
  CREATE_CUSTOM_CHANNELS,
  UPDATE_CUSTOM_CHANNEL,
} from '../../../../../../../../common/graphql/channels'
import { trackCustomChannelSetupViewed } from '../../../../../tracking'
import { ChannelAccountType, Service } from '../../../../../types'
import { useUser } from '../../../../../../../../common/context/User'
import { Footer } from '../shared/Footer/Footer'
import {
  avatarLimits,
  getImage,
  setConnectionSuccessQueryParamsForCustomChannel,
  validateDimensions,
} from '../../../../../utils'
import trackChannelConnectionStarted from '../../../../../../../../tracking/trackChannelConnectionStarted'
import styles from './FacebookGroups.module.css'
import footerStyles from '../shared/Footer/Footer.module.css'
import {
  FileUploadConfig,
  UploadSource,
  UploadType,
  useUploader,
} from '@buffer-mono/uploader'
import clsx from 'clsx'
import { resetChannelConnectionsState } from '../../../../../../store/channelConnectionsReducer'

const fileRestrictions = {
  maxNumberOfFiles: 1,
  allowedFileTypes: ['.jpg', '.jpeg', '.png', '.webp'],
  uploadConfig: FileUploadConfig.IMAGE,
}

export function FacebookGroups({
  channelId,
  channelName,
  channelAvatar,
  channelUrl,
  isRefreshingConnection,
  dismissModal,
  modalOnContinue,
}: {
  channelId?: string
  channelName?: string
  channelAvatar?: string
  channelUrl?: string
  isRefreshingConnection?: boolean
  dismissModal: () => void
  modalOnContinue?: () => void
}): React.JSX.Element {
  const dispatch = useDispatch()
  const [name, setName] = useState(channelName ?? '')
  const [nameError, setNameError] = useState('')
  const [url, setUrl] = useState(channelUrl ?? '')
  const [urlError, setUrlError] = useState('')
  const [avatar, setAvatar] = useState(channelAvatar ?? '')
  const [avatarError, setAvatarError] = useState('')
  const [avatarLoading, setAvatarLoading] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const user = useUser()

  const uploader = useUploader({
    id: UploadType.channelAvatar,
    userId:
      user?.products?.find((product) => product.name === 'publish')?.userId ||
      '',
    organizationId: user?.currentOrganization?.id || '',
    fileRestrictions,
  })

  useEffect(() => {
    trackCustomChannelSetupViewed({
      account: user,
      service: Service.facebook,
    })
  }, [user])

  const { cta } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )

  const [upsertCustomChannels, { data, loading }] = useMutation(
    isRefreshingConnection ? UPDATE_CUSTOM_CHANNEL : CREATE_CUSTOM_CHANNELS,
  )

  const handleOnSubmit = useCallback((): void => {
    trackChannelConnectionStarted({
      payload: {
        service: Service.facebook,
        cta,
        channelType: ChannelAccountType.group,
      },
      user,
    })

    // Validation that name and url are required
    if (name.trim() === '' || url.trim() === '') {
      if (name.trim() === '') {
        setNameError('Facebook Group name is required.')
      }

      if (url.trim() === '') {
        setUrlError('Facebook Group url is required.')
      }

      return
    }

    // Max length validation for group name
    if (name.length > 75) {
      setNameError('Facebook Group name must be under 75 characters')
      return
    }

    // Correct Facebook Group URL validation
    if (!url.toLowerCase().includes('facebook.com/groups/')) {
      setUrlError(
        'Facebook Group url should look like this: facebook.com/groups/xxxxxxxxx',
      )
      return
    }

    upsertCustomChannels({
      variables: {
        input: isRefreshingConnection
          ? {
              channelId,
              customChannelMetadata: {
                facebookGroupMetadata: {
                  avatar,
                  name,
                  url,
                },
              },
            }
          : {
              channels: [
                {
                  organizationId: user?.currentOrganization?.id,
                  service: Service.facebook,
                  type: ChannelAccountType.group,
                  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  customChannelMetadata: {
                    facebookGroupMetadata: {
                      avatar,
                      name,
                      url,
                    },
                  },
                },
              ],
            },
      },
    }).then(() => {
      if (isRefreshingConnection) {
        dismissModal()
        dispatch(resetChannelConnectionsState({}))

        modalOnContinue?.()
      }
    })
  }, [
    name,
    url,
    avatar,
    channelId,
    user,
    cta,
    isRefreshingConnection,
    dismissModal,
    dispatch,
    upsertCustomChannels,
    modalOnContinue,
  ])

  const uploadCallback = useCallback(
    async (file: File): Promise<void> => {
      setAvatar('')
      setAvatarError('')

      if (file) {
        const image = await getImage(file)

        if (image.file.size > avatarLimits.MAX_SIZE) {
          setAvatarError('Avatar images must be under 2 MB.')
          return
        }

        if (!validateDimensions(image.width, image.height)) {
          setAvatarError(
            `Avatar must be between ${avatarLimits.MIN_WIDTH}x${avatarLimits.MIN_HEIGHT} pixels and ${avatarLimits.MAX_WIDTH}x${avatarLimits.MAX_HEIGHT} pixels.`,
          )
          return
        }

        setAvatar(image.fileDataUrl)
        setAvatarLoading(true)
        const result = await uploader.upload([image.file], {
          source: UploadSource.filePicker(),
        })

        if (result.successful.length) {
          setAvatar(result.successful[0].uploadURL)
          setAvatarError('')
        } else {
          setAvatarError('Avatar upload failed. Please try again.')
        }
      } else {
        setAvatarError('')
        setAvatar('')
      }

      setAvatarLoading(false)
      /**
       * Reset the input value to allow the same file to be uploaded again in case of any errors
       */
      uploader.uppyInstance.reset()
    },
    [uploader],
  )

  const successfullyCreatedCustomChannelId =
    data?.createCustomChannels?.channels[0]?.id

  if (successfullyCreatedCustomChannelId) {
    // Redirect to '/channels' and trigger channel connection success modal
    const newURLWithSuccessParams =
      setConnectionSuccessQueryParamsForCustomChannel(
        successfullyCreatedCustomChannelId,
      )
    window.location.href = newURLWithSuccessParams.toString()
  }

  return (
    <>
      <Dialog.Body
        className={clsx(styles.body)}
        onDragOver={(event): void => {
          setIsDragging(true)

          event.preventDefault()
        }}
        onDragLeave={(): void => {
          setIsDragging(false)
        }}
        onDrop={(event): void => {
          setIsDragging(false)
          event.preventDefault()

          const item = event.dataTransfer.items[0]
          const file = item.getAsFile()

          if (file && file.type.includes('image')) {
            uploadCallback(file)
          }
        }}
      >
        <div className={styles.titleSubtitle}>
          {isRefreshingConnection ? (
            <Heading size="large">Edit Facebook Group</Heading>
          ) : (
            <>
              <Heading size="large">Add Your Facebook Group to Buffer</Heading>
              <Text>
                Add the Facebook Group details to create Facebook Group channel
                in Buffer.
              </Text>
            </>
          )}
        </div>

        <Flex
          direction="column"
          gap="lg"
          align="stretch"
          className={styles.wrapper}
        >
          <Flex gap="2xs" direction="column" align="stretch">
            <Label>Name</Label>

            <Input
              type="input"
              size="large"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setNameError('')
                setName(e.target.value)
              }}
              id="name"
              placeholder="Facebook Group Name"
              aria-invalid={Boolean(nameError)}
              className={styles.input}
            />
            {nameError && <Text color="critical">{nameError}</Text>}
          </Flex>

          <Flex gap="2xs" direction="column" align="stretch">
            <Label>
              Group URL{' '}
              <Tooltip content="Find your Facebook group URL by logging into Facebook, going to your group, and clicking the '...' icon next to the search button. Select 'Share', then 'Copy Link', and paste the URL here.">
                <HelpIcon color="subtle" />
              </Tooltip>
            </Label>

            <Input
              type="input"
              size="large"
              value={url}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setUrlError('')
                setUrl(e.target.value)
              }}
              id="url"
              name="url"
              placeholder="Looks like this: facebook.com/groups/xxxxxxxxx"
              aria-invalid={Boolean(urlError)}
              className={styles.input}
            />
            {urlError && <Text color="critical">{urlError}</Text>}
          </Flex>

          <Flex gap="2xs" direction="column">
            <Label>Avatar (optional)</Label>
            <Card
              className={clsx(
                avatarError && styles.hasError,
                isDragging && styles.dragging,
              )}
            >
              <Flex gap="xs" align="center">
                <Avatar
                  alt="facebook group avatar"
                  src={avatar}
                  className={avatarLoading ? styles.avatarLoading : undefined}
                />
                <Text color="subtle" className={styles.description}>
                  At least {avatarLimits.MIN_WIDTH}x{avatarLimits.MIN_HEIGHT}{' '}
                  pixels, less than 2 MB
                </Text>
                <VisuallyHidden
                  as="input"
                  type="file"
                  id="avatar-uploader"
                  accept={fileRestrictions.allowedFileTypes
                    .map((fileType) => `image/${fileType.replace('.', '')}`)
                    .join(',')}
                  multiple={false}
                  onChange={async (event): Promise<void> => {
                    setAvatar('')
                    setAvatarError('')

                    if (event.target.files?.length) {
                      uploadCallback(event.target.files[0])
                    }

                    event.target.value = ''
                  }}
                ></VisuallyHidden>
                <Button
                  variant="secondary"
                  className={styles.button}
                  as="label"
                  htmlFor="avatar-uploader"
                  disabled={avatarLoading}
                >
                  <ImageIcon />
                  {avatarLoading ? 'Uploading...' : 'Upload Image'}
                </Button>
              </Flex>
            </Card>
            {avatarError && (
              <Flex gap="2xs" align="center">
                <CriticalIcon size="xsmall" color="critical" />
                <Text size="sm" color="critical">
                  {avatarError}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Dialog.Body>
      <Footer>
        <DropdownMenu className={footerStyles.helpMenu}>
          <DropdownMenu.Trigger>
            <Button
              variant="tertiary"
              size="large"
              className={footerStyles.helpButton}
            >
              Need Help
              <ChevronDownIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="start">
            <DropdownMenu.Item>
              <a
                className={footerStyles.helpLink}
                href="https://support.buffer.com/article/555-using-facebook-with-buffer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Using Facebook with Buffer
              </a>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
        <Button
          size="large"
          disabled={!name || !url || loading || avatarLoading}
          onClick={handleOnSubmit}
        >
          {isRefreshingConnection
            ? loading
              ? 'Saving'
              : 'Save'
            : loading
            ? 'Connecting Channel'
            : 'Continue'}
        </Button>
      </Footer>
      <div className={clsx(styles.overlay, isDragging && styles.enabled)}>
        <div className={styles.icon}>
          <ImagePlusIcon size="large" />
        </div>
        <Paragraph weight="bold" size="md">
          Drop 1 image to add as an avatar
        </Paragraph>
      </div>
    </>
  )
}
