import React from 'react'
import { Button, ChevronDownIcon, DropdownMenu } from '@buffer-mono/popcorn'

import { DEFAULT_STATUS, type FilterStatus } from './useFilters'

type FilterStatusMenuProps = {
  value?: FilterStatus
  onChange?: (value: FilterStatus) => void
}

export const FilterStatusMenu = ({
  value = DEFAULT_STATUS,
  onChange,
}: FilterStatusMenuProps): JSX.Element => {
  const handleStatusChange = (newValue: string): void => {
    if (!newValue || newValue === value) return
    onChange?.(newValue as FilterStatus)
  }

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger asChild>
        <Button size="medium" variant="tertiary">
          {value.charAt(0).toUpperCase() + value.slice(1)}
          <ChevronDownIcon />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content align="end" data-no-focus-lock>
        <DropdownMenu.RadioGroup value={value} onChange={handleStatusChange}>
          <DropdownMenu.RadioItem value="all">All</DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="unreplied">
            Unreplied
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="replied">
            Replied
          </DropdownMenu.RadioItem>
          <DropdownMenu.RadioItem value="dismissed">
            Dismissed
          </DropdownMenu.RadioItem>
        </DropdownMenu.RadioGroup>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
