import React from 'react'
import { TriggerButton } from './BrainfishWidget.style'
import { useBrainfishWidget } from '../../hooks/useBrainfishWidget'
import { HelpIcon, ChevronDownIcon } from '@buffer-mono/popcorn'
import type { Account } from '../../types'

interface BrainfishWidgetProps {
  shouldShowBrainfish: boolean
  shouldMoveToHelpMenu: boolean
  user: Account
}

export const BrainfishWidget: React.FC<BrainfishWidgetProps> = (props) => {
  const { openBrainfish, closeBrainfish, isReady, isOpen } =
    useBrainfishWidget(props)
  const { shouldShowBrainfish, shouldMoveToHelpMenu } = props

  if (!shouldShowBrainfish || !isReady) {
    return null
  }

  if (shouldMoveToHelpMenu) {
    return <></>
  }

  return (
    <TriggerButton
      className="brainfish-trigger-button"
      onClick={isOpen ? closeBrainfish : openBrainfish}
    >
      {isOpen ? (
        <ChevronDownIcon size="large" stroke={1} />
      ) : (
        <HelpIcon size="large" stroke={1} />
      )}
    </TriggerButton>
  )
}
