import React, { createContext, useContext } from 'react'
import type { ApolloError } from '@apollo/client'

import type {
  ChannelType,
  FeatureChannels,
} from '~publish/pages/Comments/types'

// TODO: Replace with actual API query once ready
const MOCK_COMMENTS_CONFIG: { supportedChannelsByFeature: FeatureChannels[] } =
  {
    supportedChannelsByFeature: [
      {
        feature: 'comments',
        supportedChannels: [{ service: 'threads', type: ['profile'] }],
      },
      {
        feature: 'engage',
        supportedChannels: [
          { service: 'facebook', type: ['profile', 'page'] },
          { service: 'instagram', type: ['business'] },
        ],
      },
    ],
  } as const

export type CommentsConfig = {
  isSupportedInComments: (channel: ChannelType) => boolean
  isSupportedInEngage: (channel: ChannelType) => boolean
  isChannelSupported: (channel: ChannelType) => boolean
  hasSupportedCommentChannel: (channels: ChannelType[]) => boolean
}

type ChannelConfig = {
  config: CommentsConfig
  loading: boolean
  error: ApolloError | null
}

const CommentsChannelConfigContext = createContext<ChannelConfig | undefined>(
  undefined,
)

function isChannelSupportedInFeature(
  channel: ChannelType,
  featureConfig: FeatureChannels | undefined,
): boolean {
  if (!featureConfig) return false
  return featureConfig.supportedChannels.some(
    (supportedChannel) =>
      supportedChannel.service === channel.service &&
      supportedChannel.type.includes(channel.type),
  )
}

function CommentsChannelConfigProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  // When API is ready, replace with useQuery
  const featureConfigs = MOCK_COMMENTS_CONFIG.supportedChannelsByFeature
  const commentsConfig = featureConfigs.find((c) => c.feature === 'comments')
  const engageConfig = featureConfigs.find((c) => c.feature === 'engage')

  // Helper functions
  const isSupportedInComments = (channel: ChannelType): boolean =>
    isChannelSupportedInFeature(channel, commentsConfig)
  const isSupportedInEngage = (channel: ChannelType): boolean =>
    isChannelSupportedInFeature(channel, engageConfig)

  const value = {
    config: {
      isSupportedInComments,
      isSupportedInEngage,
      isChannelSupported: (channel: ChannelType): boolean =>
        isSupportedInComments(channel) || isSupportedInEngage(channel),
      hasSupportedCommentChannel: (channels: ChannelType[]): boolean =>
        channels.some(isSupportedInComments),
    },
    loading: false,
    error: null,
  }

  return (
    <CommentsChannelConfigContext.Provider value={value}>
      {children}
    </CommentsChannelConfigContext.Provider>
  )
}

function useCommentsConfig(): ChannelConfig {
  const context = useContext(CommentsChannelConfigContext)

  if (!context) {
    throw new Error('useCommentsConfig must be used within CommentsProvider')
  }

  return context
}

export { useCommentsConfig, CommentsChannelConfigProvider }
