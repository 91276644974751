import React from 'react'
import HashtagGroupCreator from '../HashtagGroupCreator'
import HashtagGroupManager from '../HashtagGroupManager'
import type { HashtagGroup } from '../../types'

const boxStyle: React.CSSProperties = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '28px 0 24px',
  overflow: 'hidden',
  boxSizing: 'border-box',
}

const CREATE_MODE = 'createHashtag'
const MANAGE_MODE = 'manageHashtag'
const EDIT_MODE = 'editHashtag'

export interface HashtagGroupWrapperProps {
  viewMode?: string
  name?: string | null
  text?: string | null
  onHandleInsertHashtagGroupClick?: (name: string, text: string) => void
  onDeleteHashtagGroup?: (group: HashtagGroup) => void
  onEditHashtagGroup?: (group: HashtagGroup) => void
  onSaveHashtagGroup?: () => void
  onUpdateHashtagGroup?: () => void
  onChangeGroupName?: (name: string) => void
  onChangeGroupText?: (text: string) => void
  onCancelHashtagGroup?: () => void
  hashtagGroups: HashtagGroup[]
}

interface HashtagGroupWrapperState {
  viewMode: string
}

class HashtagGroupWrapper extends React.Component<
  HashtagGroupWrapperProps,
  HashtagGroupWrapperState
> {
  constructor(props: HashtagGroupWrapperProps) {
    super(props)

    this.state = {
      viewMode: props.viewMode || CREATE_MODE,
    }

    this.onSwitchMode = this.onSwitchMode.bind(this)
    this.onDeleteHashtagGroupClick = this.onDeleteHashtagGroupClick.bind(this)
    this.onEditHashtagGroupClick = this.onEditHashtagGroupClick.bind(this)
  }

  componentDidMount(): void {
    const { viewMode } = this.props
    this.onSwitchMode(viewMode || CREATE_MODE)
  }

  onSwitchMode(viewMode: string): void {
    this.setState({ viewMode })
    const { onCancelHashtagGroup = () => {} } = this.props
    onCancelHashtagGroup()
  }

  onDeleteHashtagGroupClick(group: HashtagGroup): void {
    const { onDeleteHashtagGroup = () => {} } = this.props
    onDeleteHashtagGroup(group)
  }

  onEditHashtagGroupClick(group: HashtagGroup): void {
    this.onSwitchMode(EDIT_MODE)
    const { onEditHashtagGroup = () => {} } = this.props
    onEditHashtagGroup(group)
  }

  render(): JSX.Element {
    const { viewMode } = this.state
    const {
      hashtagGroups,
      onHandleInsertHashtagGroupClick = () => {},
      name = null,
      text = null,
      onChangeGroupName = () => {},
      onChangeGroupText = () => {},
      onSaveHashtagGroup = () => {},
      onUpdateHashtagGroup = () => {},
    } = this.props

    return (
      <div style={boxStyle} data-testid="hash-tag-manager">
        {viewMode === CREATE_MODE && (
          <HashtagGroupCreator
            name={name}
            text={text}
            isEditing={false}
            onChangeGroupName={onChangeGroupName}
            onChangeGroupText={onChangeGroupText}
            onSaveHashtagGroup={onSaveHashtagGroup}
            onUpdateHashtagGroup={onUpdateHashtagGroup}
            onCancelHashtagGroup={(): void => this.onSwitchMode(MANAGE_MODE)}
          />
        )}

        {viewMode === EDIT_MODE && (
          <HashtagGroupCreator
            name={name}
            text={text}
            isEditing
            onChangeGroupName={onChangeGroupName}
            onChangeGroupText={onChangeGroupText}
            onSaveHashtagGroup={onSaveHashtagGroup}
            onUpdateHashtagGroup={onUpdateHashtagGroup}
            onCancelHashtagGroup={(): void => this.onSwitchMode(MANAGE_MODE)}
          />
        )}

        {viewMode === MANAGE_MODE && (
          <HashtagGroupManager
            hashtagGroups={hashtagGroups}
            onCreateHashtagGroup={(): void => this.onSwitchMode(CREATE_MODE)}
            onInsertHashtagGroupClick={onHandleInsertHashtagGroupClick}
            onEditHashtagGroupClick={this.onEditHashtagGroupClick}
            onDeleteHashtagGroupClick={this.onDeleteHashtagGroupClick}
          />
        )}
      </div>
    )
  }
}

export default HashtagGroupWrapper
