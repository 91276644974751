import React from 'react'

import { Button, PlusIcon, MessageCircleHeartIcon } from '@buffer-mono/popcorn'

import { type FragmentType, getFragmentData } from '~publish/gql'
import { PageLayout } from '~publish/components/PageLayout'
import { PostListOrCalendarViewToggle } from '~publish/components/PostListOrCalendarViewToggle/PostListOrCalendarViewToggle'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { ChannelPageTitle } from '~publish/pages/Channel/ChannelPageTitle'
import { AllChannelsPageTitle } from '~publish/pages/AllChannels'
import {
  FilterByChannel,
  FilterByChannel_Channel,
} from '~publish/components/FilterByChannel'
import {
  FilterByTag,
  type MaskedFilterByTagFragment,
} from '~publish/components/FilterByTag'
import { TimezoneDisplay } from '~publish/components/TimezoneDisplay'
import { AllChannelsTimeZoneSelect } from '~publish/components/AllChannelsTimeZoneSelect'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'
import { GridPreviewButton } from '~publish/pages/Channel/ChannelShopGrid/GridPreviewButton'
import { MoreActions } from '~publish/pages/Channel/MoreActions'

import { TypeFilter } from './TypeFilter/TypeFilter'
import { CalendarControls } from './CalendarControls'

import styles from './CalendarHeader.module.css'

type CalendarHeaderProps = {
  channels: readonly FragmentType<typeof FilterByChannel_Channel>[]
  isSingleChannel: boolean
  onSelectChannels: (channelIds: string[]) => void
  onSelectTags: (tagIds: string[]) => void
  selectedChannelIds?: string[]
  selectedTagIds?: string[]
  tags: MaskedFilterByTagFragment
}

export const CalendarHeader = (props: CalendarHeaderProps): JSX.Element => {
  const {
    channels,
    isSingleChannel,
    onSelectChannels,
    onSelectTags,
    selectedChannelIds = [],
    selectedTagIds,
    tags,
  } = props
  const { createNewPostInComposer, triggerAttributes } = usePostComposer()
  const channel = isSingleChannel
    ? channels
        .map((channel) => getFragmentData(FilterByChannel_Channel, channel))
        .find((channel) => channel.id === selectedChannelIds[0])
    : undefined

  const selectedTags = useSelectedTags()

  return (
    <PageLayout.Header separator={false} className={styles.layout}>
      <PageLayout.HeaderRow>
        {isSingleChannel ? <ChannelPageTitle /> : <AllChannelsPageTitle />}
        <PageLayout.Actions>
          <FeedbackWidget id="all-channels-1" source="publish">
            <Button variant="tertiary" size="large">
              <MessageCircleHeartIcon />
              Share Feedback
            </Button>
          </FeedbackWidget>
          {isSingleChannel ? <GridPreviewButton /> : null}
          <PostListOrCalendarViewToggle />

          <Button
            size="large"
            variant="secondary"
            {...triggerAttributes}
            onClick={(): Promise<void> =>
              createNewPostInComposer({
                cta: 'publish-calendar-header-newPost-1',
                channels:
                  selectedChannelIds && selectedChannelIds.length
                    ? selectedChannelIds
                    : [],
                prefillPostData: {
                  tags: selectedTags,
                },
              })
            }
          >
            <PlusIcon />
            New Post
          </Button>
        </PageLayout.Actions>
      </PageLayout.HeaderRow>
      <PageLayout.HeaderRow>
        <CalendarControls />
        <PageLayout.Actions>
          <TypeFilter />
          {!isSingleChannel && (
            <FilterByChannel
              channels={channels}
              value={selectedChannelIds ?? []}
              onSelect={onSelectChannels}
            />
          )}
          <FilterByTag
            data-tour-id="tags-filter"
            tags={tags}
            value={selectedTagIds ?? []}
            onSelect={onSelectTags}
          />
          {isSingleChannel ? (
            <TimezoneDisplay
              channelId={channel?.id}
              timezone={channel?.timezone}
              disabled={!isSingleChannel}
            />
          ) : (
            <AllChannelsTimeZoneSelect />
          )}
          {isSingleChannel && (
            <MoreActions
              channelId={channel?.id ?? ''}
              data-tour-id="calendar-more-menu-tour-step"
            />
          )}
        </PageLayout.Actions>
      </PageLayout.HeaderRow>
    </PageLayout.Header>
  )
}
