import type { DayOfWeek, DayName } from '~publish/gql/graphql'

export const DAY_OF_WEEK: Record<DayOfWeek, DayName> = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
}

export function convertTo24HourTime(time: {
  hours: number
  minutes: number
  amPm?: 'AM' | 'PM'
}): { hours: number; minutes: number } {
  if (!time.amPm) {
    return { hours: time.hours, minutes: time.minutes }
  }

  const { hours, minutes, amPm } = time
  let convertedHours = hours

  if (amPm === 'PM' && hours < 12) {
    convertedHours = hours + 12
  } else if (amPm === 'AM' && hours >= 12) {
    convertedHours = hours - 12
  }

  return {
    hours: convertedHours,
    minutes,
  }
}
