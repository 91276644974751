import twitter from 'twitter-text'

// @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
const parseTwitterLinks = (text) =>
  twitter
    .extractEntitiesWithIndices(text)
    .map((entity) => {
      if ('url' in entity && entity.url) {
        return {
          displayString: entity.url,
          indices: entity.indices,
          rawString: entity.url,
          url: (entity as any).url,
        }
      }
      if ('hashtag' in entity && entity.hashtag) {
        return {
          displayString: `#${entity.hashtag}`,
          indices: entity.indices,
          rawString: `#${entity.hashtag}`,
          url: `https://x.com/search?q=%23${(entity as any).hashtag}`,
        }
      }
      if ('screenName' in entity && entity.screenName) {
        return {
          displayString: `@${entity.screenName}`,
          indices: entity.indices,
          rawString: `@${entity.screenName}`,
          url: `https://x.com/${(entity as any).screenName}`,
        }
      }
      if ('cashtag' in entity && entity.cashtag) {
        return {
          displayString: `$${entity.cashtag}`,
          indices: entity.indices,
          rawString: `$${entity.cashtag}`,
          url: `https://x.com/search?q=%24${(entity as any).cashtag}`,
        }
      }
      return null
    })
    .filter((entity) => entity !== null)

export { parseTwitterLinks }
