import React from 'react'

import { toast, useLocalStorage } from '@buffer-mono/popcorn'

import { TimeZoneSelect } from '~publish/components/TimeZoneSelect'
import { useTimezone } from '~publish/hooks/useTimezone'

export const ALL_CHANNELS_TIMEZONE_STORAGE_KEY = 'all-channels-timezone'

export function AllChannelsTimeZoneSelect({
  disabled,
}: {
  disabled?: boolean
}): JSX.Element {
  const _timezone = useTimezone()
  const [timezone, setTimezone] = useLocalStorage(
    ALL_CHANNELS_TIMEZONE_STORAGE_KEY,
    _timezone,
  )

  const handleTimeZoneChange = (timezone: string): void => {
    setTimezone(timezone)
    toast.success(
      `Time zone updated! Times in this view will display in ${timezone.replace(
        /_/g,
        ' ',
      )}`,
    )
  }

  return (
    <TimeZoneSelect
      disabled={disabled}
      value={timezone}
      onChange={handleTimeZoneChange}
      variant="tertiary"
    />
  )
}
