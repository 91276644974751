import React from 'react'

import { Button } from '@bufferapp/ui'
import { isIE } from '~publish/legacy/composer/composer/utils/DOMUtils'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import type Draft from '../entities/Draft/Draft'

const openModal = (draft: Draft): void => {
  ModalActionCreators.openModal('ThumbnailEditor', {
    draft,
  })
}

const wrapperStyle: React.CSSProperties = {
  width: '100px',
  textAlign: 'center',
}

const regularVideoButton = {}

const verticalVideoButton = {
  marginTop: '8px',
}

const ThumbnailButton = ({ draft }: { draft: Draft }): JSX.Element => {
  const verticalVideoOrRegularVideoClass =
    draft.isVerticalVideoOnlySupported() || draft.isStoryPost()
      ? verticalVideoButton
      : regularVideoButton

  const wrapperClasses = {
    ...wrapperStyle,
    ...verticalVideoOrRegularVideoClass,
  }

  const isInstagramDirectEnabledForSelectedChannel = () => {
    const selectedInstagramProfile = AppStore.getSelectedProfiles().filter(
      (profile) => profile.service.name === 'instagram',
    )
    const hasOnlyInstagramSelected =
      selectedInstagramProfile && selectedInstagramProfile.length === 1
    if (!selectedInstagramProfile || !hasOnlyInstagramSelected) {
      return true
    }
    return (
      hasOnlyInstagramSelected &&
      selectedInstagramProfile[0] &&
      selectedInstagramProfile[0].instagramDirectEnabled
    )
  }

  const canEditIGThumbnail =
    !draft.isStoryPost() &&
    isInstagramDirectEnabledForSelectedChannel() &&
    draft.instagramFeedback.length < 1

  const shouldDisplayEditThumbnailBtn =
    draft.service.canEditVideoAttachment &&
    draft.hasVideoAttachment() &&
    AppStore.getExpandedComposerId() === draft.id &&
    AppStore.getComposerFeedbackMessages(draft).length < 1 && // don't allow user to edit thumbnail if can't add to queue
    !AppStore.getIsOmniboxEnabled() &&
    !isIE() && // not compatible with IE for first version
    canEditIGThumbnail

  if (!shouldDisplayEditThumbnailBtn) {
    return <></>
  }

  return (
    <div style={wrapperClasses}>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; size: string;... Remove this comment to see the full error message */}
      <Button
        type="secondary"
        label="Edit Thumbnail"
        size="small"
        // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
        onClick={(e) => {
          e.preventDefault()
          openModal(draft)
        }}
      />
    </div>
  )
}

export default ThumbnailButton
