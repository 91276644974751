import React from 'react'

import { useAppSelector } from '~publish/legacy/store'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import { TempImage } from './TempImage'
import { UploaderDropzone } from './UploaderDropzone'
import { selectPendingCount, selectPendingProgress } from '../state/selectors'
import type { Uploader } from '@buffer-mono/uploader'

export const ComposerUploader = ({
  draft,
  uploader,
}: {
  draft: Draft
  uploader: Uploader
}): JSX.Element => {
  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, uploader.getId()),
  )
  const pendingProgress = useAppSelector((state) =>
    selectPendingProgress(state, uploader.getId()),
  )
  const isUploading = pendingCount > 0

  return draft.tempImage ? (
    <TempImage imageUrl={draft.tempImage} />
  ) : (
    // TODO: Replace with UploadDropzone component - https://linear.app/buffer/issue/CONT-574/extend-uploaddropzone-to-support-button
    <UploaderDropzone
      uploader={uploader}
      isUploading={isUploading}
      uploadProgress={pendingProgress}
      activeUploadsCount={pendingCount}
      disabled={isUploading}
      multiple={draft.service.maxAttachableImagesCount(draft) > 1}
      onlyAllowsVerticalVideo={draft.service.onlyAllowsVerticalVideo}
    />
  )
}
