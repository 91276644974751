import React from 'react'
import PostTypeBar from '~publish/legacy/composer/composer/components/channel-bar/PostTypeBar'
import { PostTypeShort } from '~publish/legacy/post/constants'
import type Draft from '../../entities/Draft/Draft'

const YoutubePostTypeBar = ({
  draft,
}: {
  draft: Draft
}): JSX.Element | null => {
  if (!draft.service.isYoutube()) {
    return null
  }

  return (
    <PostTypeBar
      postTypes={[{ value: PostTypeShort, name: 'Short' }]}
      defaultPostType={PostTypeShort}
      draft={draft}
    />
  )
}

export default YoutubePostTypeBar
