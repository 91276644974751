import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import React from 'react'
import { CtaButtonWrapper } from '~publish/components/CtaButtonWrapper'
import { capitalize } from '~publish/helpers/capitalize'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { RegisteredBanner } from '../../../components/RegisteredBanner'
import { useCurrentOrganization } from '~publish/legacy/accountContext/index'
import { Service } from '@buffer-mono/app-shell/src/exports/Orchestrator/channelConnections/types'

const useCanDisplayBanner = (bannerId: string): boolean => {
  const banner = useDismissableBanner(bannerId)
  const selectedChannel = useSelectedChannel()

  return banner.isActive && selectedChannel.service === 'bluesky'
}

export const BlueskyPromotionalBanner = (): JSX.Element | null => {
  const { role } = useCurrentOrganization() ?? {}
  const bannerId = 'blueskyCustomPDSPromo'

  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })
  const selectedChannel = useSelectedChannel()

  const ctaName = `publish-queue-banner-connectBluesky${capitalize(
    selectedChannel?.service ?? '',
  )}-1` as const

  const onBlueskyConnectClick = (): void => {
    if (!role) return
    window.appshell.actions.connectChannel({
      selectedService: Service.bluesky,
      cta: ctaName,
      role,
    })
  }

  const canRenderBanner = useCanDisplayBanner(bannerId)

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <PromotionalBanner onDismiss={banner.dismiss}>
        <PromotionalBanner.Content>
          <PromotionalBanner.Heading>
            Connect to Bluesky using Personal Data Servers!
          </PromotionalBanner.Heading>
          <PromotionalBanner.Description color="subtle">
            You can now connect to Bluesky using handles hosted on your own
            personal data server.
          </PromotionalBanner.Description>
          <PromotionalBanner.Actions>
            <CtaButtonWrapper
              cta={ctaName}
              options={{ upgradePathName: 'accountChannels-upgrade' }}
            >
              <Button variant="secondary" onClick={onBlueskyConnectClick}>
                Connect Another Bluesky Account
              </Button>
            </CtaButtonWrapper>
          </PromotionalBanner.Actions>
        </PromotionalBanner.Content>
        <PromotionalBanner.Image src="https://buffer-publish.s3.us-east-1.amazonaws.com/images/bluesky_pds.png" />
      </PromotionalBanner>
    </RegisteredBanner>
  )
}
