import type { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions'
import { graphql } from '~publish/graphql'
import { isOfType } from '~publish/helpers/typeGuards'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'

const UpdateFeedCollection = graphql(/* GraphQL */ `
  mutation UpdateFeedCollection($input: UpdateFeedCollectionInput!) {
    updateFeedCollection(input: $input) {
      __typename
      ... on FeedCollectionActionSuccess {
        feedCollection {
          __typename
          id
          name
          feeds {
            id
            name
            homepage
            connectionMetadata {
              __typename
              ... on RSSFeedConnectionMetadata {
                url
                lastConnected
              }
              ... on NewsSearchConnectionMetadata {
                query
              }
            }
          }
        }
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useUpdateFeedCollection = () =>
  useTypedMutation(UpdateFeedCollection, (data) => data.updateFeedCollection, {
    successTypename: 'FeedCollectionActionSuccess',
    optimisticResponse: (
      variables,
      { IGNORE },
    ): MutationBaseOptions['optimisticResponse'] => {
      const { feedCollectionId, name } = variables.input
      if (!feedCollectionId || !name) {
        return IGNORE
      }

      return {
        __typename: 'Mutation',
        updateFeedCollection: {
          __typename: 'FeedCollectionActionSuccess',
          feedCollection: {
            __typename: 'FeedCollection',
            id: feedCollectionId,
            name,
          },
        },
      }
    },
    update: (cache, { data }) => {
      const result = data?.updateFeedCollection
      if (isOfType(result, 'FeedCollectionActionSuccess')) {
        const feedCollection = result.feedCollection
        cache.modify({
          id: cache.identify({
            __typename: 'FeedCollection',
            id: feedCollection.id,
          }),
          fields: {
            name: () => feedCollection.name,
            feeds: (_, { toReference }) => {
              return feedCollection.feeds.map((feed) => {
                return toReference({
                  __typename: 'Feed',
                  id: feed.id,
                })
              })
            },
          },
        })
      }
    },
  })
