/* eslint-disable @typescript-eslint/ban-ts-comment */
import MagicWandIcon from '@bufferapp/ui/Icon/Icons/MagicWand'
import React from 'react'

import * as Styled from './AIPlaceholderButton.styles'

/**
 * This buttons is shown only as a placeholder when Editor is empty
 */
const AIPlaceholderButton = ({
  onToggle,
}: {
  onToggle: () => void
}): JSX.Element => {
  return (
    <Styled.PlaceholderButton
      secondary
      onClick={onToggle}
      id="ai-assistant-placeholder-button"
      data-testid="ai-assistant-placeholder-button"
    >
      <MagicWandIcon size="smedium" />
      Use the AI Assistant
    </Styled.PlaceholderButton>
  )
}

export { AIPlaceholderButton }
