import {
  Button,
  Heading,
  Image,
  List,
  UpgradeDialog,
} from '@buffer-mono/popcorn'
import React from 'react'
import styles from './FreeFeedsLimitReachedDialog.module.css'
import { Limits } from '../../constants'
import clsx from 'clsx'
import { TrackRender } from '~publish/hooks/tracking/useTrackComponentRendered'

type FreeFeedsLimitReachedDialogProps = {
  enabled?: boolean
  className?: string
  children: JSX.Element
  onUpgrade?: () => void
  onOpenChange?: (open: boolean) => void
}

export const FreeFeedsLimitReachedDialog = ({
  enabled,
  className,
  children,
  onOpenChange,
  onUpgrade,
}: FreeFeedsLimitReachedDialogProps): JSX.Element => {
  if (!enabled) {
    return children
  }

  return (
    <UpgradeDialog key="feedsUpgradeDialog" onOpenChange={onOpenChange}>
      {/* asChild needs to be false to support multiple conditional dialogs around one trigger */}
      <UpgradeDialog.Trigger
        asChild={false}
        className={clsx(styles.trigger, className)}
      >
        {children}
      </UpgradeDialog.Trigger>
      <UpgradeDialog.Content>
        <UpgradeDialog.TextContent>
          <TrackRender componentName="FreeFeedsLimitReachedDialog" />
          <Heading>Upgrade to add more feeds</Heading>
          You&apos;ve reached your limit of {Limits.feeds.free.total} feeds.
          Upgrade your plan to keep adding feeds and so much more.
          <List>
            <List.Item>Schedule up to 2000 posts at a time.</List.Item>
            <List.Item>Advanced publishing and planning features.</List.Item>
            <List.Item>In-depth social analytics and reporting.</List.Item>
          </List>
          <Button size="large" onClick={onUpgrade}>
            See Upgrade Options
          </Button>
        </UpgradeDialog.TextContent>
        <UpgradeDialog.Image className={styles.imageContainer}>
          <Image
            alt="Content feeds upgrade dialog illustration"
            src="https://buffer-publish.s3.us-east-1.amazonaws.com/images/Upgrade-Content-Feeds/Upgrade-Content-Feeds.svg"
            className={styles.image}
          />
        </UpgradeDialog.Image>
      </UpgradeDialog.Content>
    </UpgradeDialog>
  )
}
