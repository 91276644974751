import React from 'react'
import { Avatar, Flex } from '@buffer-mono/popcorn'

import styles from './AccountMenuAvatar.module.css'

export const getUserAvatar = (avatar?: string): string =>
  avatar ?? 'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

type AccountMenuAvatarProps = {
  avatar?: string
  isImpersonation?: boolean
  size?: 'small' | 'medium'
}

const AccountMenuAvatar = ({
  avatar,
  isImpersonation = false,
  size = 'medium',
}: AccountMenuAvatarProps): JSX.Element => {
  return (
    <Flex gap="xs" align="center" justify="center">
      {isImpersonation && (
        <span
          aria-label="You are impersonating a user"
          className={styles.impersonating}
        >
          Impersonating
        </span>
      )}
      <Avatar src={getUserAvatar(avatar)} alt="User Avatar" size={size} />
    </Flex>
  )
}

export default AccountMenuAvatar
