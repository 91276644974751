import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import type { ConnectedApp } from '~publish/gql/graphql'
import { useQuery } from '@apollo/client'

export const GetAccountConnectedApps = graphql(/* GraphQL */ `
  query getConnectedAppsForAccount {
    account {
      connectedApps {
        clientId
        userId
        name
        description
        website
        createdAt
      }
    }
  }
`)

type GetAccountConnectedApps = {
  connectedApps: ConnectedApp[] | null
  isLoading: boolean
  hasError: boolean
}

/**
 * Custom hook to query the Account connected apps
 * @returns {GetAccountConnectedApps} The function to trigger querying the account connected apps.
 */
export const useConnectedApps = (): GetAccountConnectedApps => {
  const { data, loading, error } = useQuery(GetAccountConnectedApps)

  if (!data?.account?.connectedApps && !loading && !error) {
    throw new Error('No connected apps found for Account')
  }

  // Handle GraphQL errors if any
  if (error) {
    const unexpectedError = new Error(
      error?.message || 'Unexpected API response',
    )
    logError(unexpectedError, { metaData: { data, error } })
    throw unexpectedError
  }

  return {
    connectedApps: data?.account?.connectedApps ?? null,
    isLoading: loading,
    hasError: !!error,
  }
}
