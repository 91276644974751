import {
  serviceRequiresFurtherConfiguration,
  setBufferPageSourceURLCookie,
} from '../../exports/Orchestrator/channelConnections/utils'
import { showComponent } from '../../exports/Orchestrator/store/actions'
import {
  setIsRefreshingChannelConnection,
  setShowChannelStorefront,
  setStartConnection,
  setStartConversion,
} from '../../exports/Orchestrator/store/channelConnectionsReducer'
import type { Service } from '../../exports/Orchestrator/channelConnections/types'
import type { ConnectChannelParams, RenderComponentParams } from './types'

export const ORCHESTRATOR_ACTIONS = {
  renderComponent({
    componentKey,
    containerId,
    options,
  }: RenderComponentParams): void {
    if (window?.appshell?.orchestratorStore) {
      window.appshell.orchestratorStore.dispatch(
        showComponent({ componentKey, containerId, options }),
      )
    }
  },
  connectChannel(args: ConnectChannelParams): void {
    const {
      selectedService,
      isRefreshingConnection,
      name,
      destinationUrl,
      cta,
      selectedRefreshChannelId,
      customChannelIdToConvert,
      authMethod,
      prefillDataAfterConnection = false,
      skipCelebrationModal = false,
      skipConfigurationModal = false,
      skipChannelCheck = false,
      role,
    } = args

    if (role && role !== 'admin') {
      ORCHESTRATOR_ACTIONS.openChannelStorefront({
        cta: cta ?? 'connectChannel-opened-as-non-admin',
        selectedService,
      })

      return
    }

    setBufferPageSourceURLCookie(window.location.href)

    const requiresFurtherConfiguration = serviceRequiresFurtherConfiguration({
      service: selectedService,
      isRefreshingConnection: !!isRefreshingConnection,
    })

    if (window?.appshell?.orchestratorStore) {
      // Converting a custom channel
      if (customChannelIdToConvert) {
        window.appshell.orchestratorStore.dispatch(
          setStartConversion({
            selectedService,
            customChannelIdToConvert,
            cta,
            authMethod,
          }),
        )
        return
      }

      // Normal channel connection
      window.appshell.orchestratorStore.dispatch(
        setStartConnection({
          selectedService,
          destinationUrl,
          cta,
          authMethod,
          serviceRequiresFurtherConfiguration:
            !skipConfigurationModal && requiresFurtherConfiguration,
          prefillDataAfterConnection,
          skipCelebrationModal,
        }),
      )

      if (isRefreshingConnection) {
        window.appshell.orchestratorStore.dispatch(
          setIsRefreshingChannelConnection({
            selectedService,
            selectedRefreshChannelId,
            name,
            authMethod,
            skipChannelCheck,
          }),
        )
      }
    }
  },
  openChannelStorefront({
    cta,
    prefillDataAfterConnection = false,
    skipCelebrationModal = false,
    selectedService,
  }: {
    cta: string
    prefillDataAfterConnection?: boolean
    skipCelebrationModal?: boolean
    selectedService?: Service
  }): void {
    window?.appshell?.orchestratorStore.dispatch(
      setShowChannelStorefront({
        cta,
        prefillDataAfterConnection,
        skipCelebrationModal,
        selectedService,
      }),
    )
  },
}

export type ORCHESTRATOR_ACTIONS_TYPE = typeof ORCHESTRATOR_ACTIONS
