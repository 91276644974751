import React from 'react'
import {
  Button,
  DropdownMenu,
  EllipsisVerticalIcon,
} from '@buffer-mono/popcorn'
import { useDismissAllCommentsByChannelId } from '../hooks/usedismissAllCommentsByChannelId'
import { useOrganizationId } from '~publish/legacy/accountContext'

type BulkActionsMenuProps = {
  channelId: string
}

export const BulkActionsMenu = React.memo(BulkActionsMenuComponent, areEqual)

function BulkActionsMenuComponent({
  channelId,
}: BulkActionsMenuProps): JSX.Element {
  const organizationId = useOrganizationId() ?? ''
  const { dismissAllCommentsByChannelId } = useDismissAllCommentsByChannelId()

  const handleDismissAllComments = async (): Promise<void> => {
    await dismissAllCommentsByChannelId({ organizationId, channelId })
  }

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <Button size="medium" variant="tertiary">
          <EllipsisVerticalIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content data-no-focus-lock>
        <DropdownMenu.Item onClick={handleDismissAllComments}>
          Dismiss all comments
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

function areEqual(
  prevProps: BulkActionsMenuProps,
  nextProps: BulkActionsMenuProps,
): boolean {
  return prevProps.channelId === nextProps.channelId
}
