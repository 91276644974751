import React from 'react'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import styles from '../css/OmniboxButtons.module.css'
import { ArrowRightIcon, Button, Tooltip } from '@buffer-mono/popcorn'
import { useIsIntegrationUploading } from '~publish/legacy/integrations-bar/state/useIsIntegrationUploading'
import { useAppSelector } from '~publish/legacy/store'
import { selectIsUploading } from '~publish/legacy/uploads/state/selectors'

const ConditionalTooltip = ({
  content,
  children,
}: {
  content?: string
  children: JSX.Element
}): JSX.Element => {
  if (content) {
    return <Tooltip content={content}>{children}</Tooltip>
  }
  return <>{children}</>
}

const OmniboxButtons = (): JSX.Element => {
  const isUploading = useAppSelector((state) =>
    selectIsUploading(state, 'composer-uploader-omni'),
  )

  const { isIntegrationUploading } = useIsIntegrationUploading()

  const areUploadsInProgress = isUploading || isIntegrationUploading

  const onCustomizeButtonClick = (): void => {
    ComposerActionCreators.applyOmniUpdate()
  }

  return (
    <ConditionalTooltip
      content={
        areUploadsInProgress ? 'Please wait for uploads to finish' : undefined
      }
    >
      <Button
        variant="secondary"
        size="large"
        className={styles.customizeButton}
        disabled={areUploadsInProgress}
        data-testid="omnibox-buttons"
        onClick={onCustomizeButtonClick}
      >
        Customize for each network
        <ArrowRightIcon />
      </Button>
    </ConditionalTooltip>
  )
}

export default OmniboxButtons
