



import {
  HELLONEXT_URL,
  HELLONEXT_LOGIN_SERVICE_URL,
} from '../../../common/utils/constants'




type NavDropdownItem = {
  id: string
  title: string
  url?: string
  onItemClick?: () => void
  tag?: JSX.Element
  icon?: JSX.Element
  hasDivider?: boolean
  className?: string
  badge?: JSX.Element
  new?: boolean
  shortcuts?: string[]
  subItems?: Array<NavDropdownItem>
}


/* TODO: Refactor to replace onItemClick by a Link as we have done in the Account Menu */
function getHelpDropdownItems(args: {
  hasVerifiedEmail: boolean
  toggleLauncher?: (() => void) | undefined
  launcherIsANewItemInHelpMenu?: boolean
  showBeaconInHelpMenu?: boolean
}): Array<NavDropdownItem> {
  const {
    hasVerifiedEmail,
    launcherIsANewItemInHelpMenu,
    showBeaconInHelpMenu,
  } = args
  const hellonextUrl = hasVerifiedEmail
    ? `${HELLONEXT_LOGIN_SERVICE_URL}&redirect=${HELLONEXT_URL}`
    : HELLONEXT_URL
  const utmParams = 'utm_source=app&utm_medium=appshell&utm_campaign=appshell'

  const items: NavDropdownItem[] = [
    {
      id: 'Help Center',
      title: 'Help Center',
      url: `https://support.buffer.com/?${utmParams}`,
    },
    {
      id: 'Plans & Pricing',
      title: 'Plans & Pricing',
      url: `https://buffer.com/pricing?${utmParams}`,
    },
    {
      id: 'Integrations',
      title: 'Apps & Integrations',
      subItems: getAppsDropdownItems(),
    },
    {
      id: 'Request a Feature',
      title: 'Suggest a Feature',
      url: hellonextUrl,
    },
    {
      id: "What's New",
      title: "What's New",
      url: `https://buffer.com/whats-new?${utmParams}`,
    },
    {
      id: 'Status',
      title: 'Status',
      url: `https://status.buffer.com/?${utmParams}`,
    },
  ]

  if (showBeaconInHelpMenu) {
    items.splice(1, 0, {
      id: 'Get Support',
      title: 'Get Support',
      onItemClick: (): void => {
        if (window.Brainfish) {
          window.Brainfish.Widgets.open()
        } else {
          window.open(
            `https://support.buffer.com/?${utmParams}&openContact=true`,
            '_blank',
          )
        }
      },
    })
  }

  if (args.toggleLauncher) {
    items.push({
      id: 'Quick Navigator',
      title: 'Quick Navigator',
      new: launcherIsANewItemInHelpMenu,
      shortcuts: [getModifierKey(), 'k'],
      onItemClick: (): void => {
        if (args.toggleLauncher) {
          args.toggleLauncher()
        }
      },
    })
  }

  return items
}

function getModifierKey(): string {
  if (navigator.userAgent.indexOf('Mac') > -1) {
    return 'cmd'
  }
  return 'ctrl'
}

/* TODO: Refactor to replace onItemClick by a Link as we have done in the Account Menu */
function getAppsDropdownItems(): Array<NavDropdownItem> {
  return [
    {
      id: 'Buffer-for-iOS',
      title: 'Buffer for iOS',
      url: 'https://apps.apple.com/app/apple-store/id490474324?pt=936146&ct=TopBarAppsDropdown&mt=8',
    },
    {
      id: 'Buffer-for-Android',
      title: 'Buffer for Android',
      url: 'https://play.google.com/store/apps/details?id=org.buffer.android',
    },
    {
      id: 'Buffer-Extensions',
      title: 'Browser Extensions',
      url: 'http://buffer.com/extensions',
    },
    {
      id: 'Zapier-for-Buffer',
      title: 'Zapier Integration',
      url: 'https://zapier.com/apps/buffer/integrations',
    },
  ]
}

export { NavDropdownItem, getHelpDropdownItems, getAppsDropdownItems }
