import { useRef, type DependencyList, useEffect } from 'react'

/**
 * A custom React hook that ensures a callback function is successfully executed only once.
 *
 * @param cb - Callback function to execute once.
 *             Should return a boolean indicating whether it was successfully executed.
 *             If the callback returns `true`, it will be marked as run and won't execute again.
 *             If the callback returns `false`, it will be marked as not run and will execute again.
 * @param deps - Dependency array that determines when the effect should re-evaluate.
 *               Similar to useEffect dependencies, when these values change, the hook will check if it should run the callback.
 *               NOTE: If the callback has returned true, changes in dependencies will not trigger a re-run.
 *
 * @example
 * // Run a function only once when component mounts
 * useOnce(() => {
 *   console.log('This will run only once on mount');
 *   return true;
 * }, []);
 *
 * @example
 * // Run a function once when a specific value changes
 * useOnce(() => {
 *   console.log('This will run once when userId changes');
 *   return true;
 * }, [userId]);
 */
export const useOnce = (cb: () => boolean, deps: DependencyList): void => {
  const hasRun = useRef(false)

  useEffect((): void => {
    if (!hasRun.current) {
      hasRun.current = cb()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
