import React from 'react'
import { useOncePerTimePeriod } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

type ComponentRenderedPayload = Parameters<
  typeof BufferTracker.componentRendered
>[0]

type Props = {
  componentName: string
} & Omit<ComponentRenderedPayload, 'name' | 'path'>
export const TrackRender = ({
  componentName,
  ...props
}: Props): JSX.Element => {
  useTrackComponentRendered(componentName, {
    path: window.location.pathname,
    ...props,
  })
  return <></>
}

/**
 * Tracks when a component is rendered, ensuring the tracking event is only fired once per day for the same component.
 * This helps prevent excessive tracking events for frequently rendered components.
 *
 * @param componentName - Unique identifier for the component being tracked
 * @param props - Tracking payload to be sent with the event
 *
 * @example
 * ```tsx
 * useTrackComponentRendered('MyComponent', {
 *   isDeprecated: true,
 *   page_name: 'home',
 *   // ... other tracking properties
 * })
 * ```
 */
export const useTrackComponentRendered = (
  componentName: string,
  props: ComponentRenderedPayload,
): void => {
  useOncePerTimePeriod(
    `tracking:useTrackComponentRendered:${componentName}`,
    useOncePerTimePeriod.A_DAY,
    () => {
      BufferTracker.componentRendered({
        name: `<${componentName} />`,
        ...props,
      })
    },
  )
}
