import { useCallback, useMemo } from 'react'
import { addPermanentNotification } from '~publish/legacy/notifications-provider/notificationsSlice'
import { useAppDispatch } from '~publish/legacy/store/index'
import { FileUploadConfig, defaultRestrictions } from '@buffer-mono/uploader'
import type { useUploader, UploaderEvents } from '@buffer-mono/uploader'
import { IDEAS_UPLOADER_ID } from '~publish/components/LegacyIdeaComposer/constants'
import usePublishUploader from '~publish/legacy/uploads/hooks/usePublishUploader'

const fileRestrictions = {
  ...defaultRestrictions,
  uploadConfig: FileUploadConfig.IDEAS,
}

export default function useIdeasUploader(): ReturnType<typeof useUploader> {
  const dispatch = useAppDispatch()

  const showError = useCallback(
    (message: string): void => {
      dispatch(addPermanentNotification(`⚠️ ${message}`))
    },
    [dispatch],
  )

  const eventHandlers = useMemo(
    () =>
      ({
        'file-validation-failed': (_, error) => showError(error.message),
        'upload-failed': (_, error) => showError(error.message),
        'file-fetch-failed': () =>
          showError('Failed to retrieve the selected file.'),
      } as Partial<UploaderEvents>),
    [showError],
  )

  return usePublishUploader({
    id: IDEAS_UPLOADER_ID,
    fileRestrictions,
    eventHandlers,
  })
}
