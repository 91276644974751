import React, { useMemo, useState } from 'react'
import clsx from 'clsx'

import { Image, ImageIcon } from '@buffer-mono/popcorn'
import { useScraperData } from '~publish/hooks/resources/useScraperData'

import type { FeedItem } from '../../hooks/useFeedItems'
import styles from './FeedArticleList.module.css'

const ensureHttps = (url?: string): string | undefined => {
  if (!url) return undefined
  try {
    const urlObject = new URL(url)
    urlObject.protocol = 'https:'
    return urlObject.toString()
  } catch {
    // If URL parsing fails, try a simple replacement
    return url.replace(/^http:/, 'https:')
  }
}

type FeedArticleImageThumbnailProps = {
  title: FeedItem['title']
  articleUrl: FeedItem['articleUrl']
  className?: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export const FeedArticleImageThumbnail = (
  props: FeedArticleImageThumbnailProps,
): JSX.Element => {
  const { title, articleUrl, className, ...rest } = props
  const [imageLoadState, setLoadingState] = useState<
    'loading' | 'success' | 'error'
  >('loading')

  const { data } = useScraperData(articleUrl)
  const firstImage = data?.images?.[0]
  const firstImageUrl = ensureHttps(firstImage?.url)

  const loadingState = useMemo(() => {
    if (!firstImageUrl) {
      return 'error'
    }
    return imageLoadState
  }, [firstImageUrl, imageLoadState])

  return (
    <a
      {...rest}
      className={clsx(className, styles.articleThumbnailContainer)}
      aria-label={`View article: ${title}`}
    >
      <div
        className={clsx(
          styles.articleThumbnailPlacholder,
          styles[loadingState],
        )}
        aria-hidden="true"
      >
        <ImageIcon size="large" />
      </div>
      {firstImageUrl && (
        <Image
          className={clsx(styles.articleThumbnail, styles[loadingState])}
          onError={(): void => {
            setLoadingState('error')
          }}
          onLoad={(): void => {
            setLoadingState('success')
          }}
          src={firstImageUrl}
          alt=""
          role="presentation"
        />
      )}
    </a>
  )
}
