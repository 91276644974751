import React from 'react'

import { Dialog } from '@buffer-mono/popcorn'

import { Facebook } from '../../../..//exports/Orchestrator/channelConnections/components/services/Facebook/Facebook'
import type { onRedirectParams } from '../../../../exports/Orchestrator/channelConnections/types'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

import styles from './FacebookSelectAccountType.module.css'

export function FacebookSelectAccountTypeDialog(): JSX.Element {
  const onRedirect = (args: onRedirectParams): void => {
    const { redirectUrl } = args
    window.location.assign(redirectUrl)
  }

  const { modalData: data, dismissModal: closeAction } = useModalManager()

  return (
    <Dialog
      open
      onOpenChange={(): void => {
        // custom close action
        if (data?.onCustomCloseAction) {
          data.onCustomCloseAction()
        }

        // default close action to dismiss modal
        closeAction()
      }}
    >
      <Dialog.Content className={styles.content}>
        <Facebook onRedirect={onRedirect} />
      </Dialog.Content>
    </Dialog>
  )
}

export default FacebookSelectAccountTypeDialog
