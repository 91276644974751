import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  ShopgridIcon,
  CoachMark,
  NewBadge,
  Flex,
} from '@buffer-mono/popcorn'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { BufferTrackerReact } from '@buffer-mono/tracking-plan'
import { useSelectedChannel } from '../ChannelContext'

export function GridPreviewButton(): JSX.Element | null {
  const channel = useSelectedChannel()
  const [step, setStep] = useState<false | 0 | 1>(false)
  const banner = useDismissableBanner('gridPreviewCoachmark', {
    trackDismiss: true,
  })

  if (channel.service === 'instagram' && channel.type !== 'profile') {
    if (banner.isActive) {
      return (
        <CoachMark
          open={step !== false}
          onDismiss={(): void => {
            banner.dismiss()

            setStep(false)
          }}
        >
          <CoachMark.Overlay>
            <Button
              id="grid-preview"
              variant="secondary"
              size="large"
              onClick={(): void => {
                setStep(0)

                BufferTrackerReact.instagramGridPreviewIconClicked({
                  product: 'publish',
                  clientName: 'publishWeb',
                  channelId: channel.id,
                })
              }}
            >
              <ShopgridIcon />
              Grid
              <CoachMark.Spotlight
                style={{
                  '--spotlight-left': '25px',
                  '--spotlight-top': '50%',
                }}
              />
            </Button>
          </CoachMark.Overlay>

          <CoachMark.Content showCloseIcon={false}>
            {step === 0 && (
              <>
                <CoachMark.Title>
                  <Flex gap="2xs" align="center">
                    <NewBadge />
                    Preview your Instagram Grid!
                  </Flex>
                </CoachMark.Title>
                <CoachMark.Description>
                  See your social media posts in a grid layout, just like your
                  audience will on Instagram!
                </CoachMark.Description>
                <CoachMark.Footer>
                  <Button onClick={(): void => setStep(1)}>Next (1/2)</Button>
                </CoachMark.Footer>
              </>
            )}
            {step === 1 && (
              <>
                <CoachMark.Title>Shop Grid Has Moved Here!</CoachMark.Title>
                <CoachMark.Description>
                  Easily add a unique link to each post and reel with Shop Grid,
                  all accessible through one link in your bio!
                </CoachMark.Description>
                <CoachMark.Footer>
                  <CoachMark.Dismiss>
                    <Button as={Link} to={`/channels/${channel.id}/shop-grid`}>
                      Got it
                    </Button>
                  </CoachMark.Dismiss>
                </CoachMark.Footer>
              </>
            )}
          </CoachMark.Content>
        </CoachMark>
      )
    } else {
      return (
        <Button
          id="grid-preview"
          variant="secondary"
          size="large"
          as={Link}
          to={`/channels/${channel.id}/shop-grid`}
          onClick={(): void => {
            BufferTrackerReact.instagramGridPreviewIconClicked({
              product: 'publish',
              clientName: 'publishWeb',
              channelId: channel.id,
            })
          }}
        >
          <ShopgridIcon />
          Grid
        </Button>
      )
    }
  }

  return null
}
