import {
  type MutationHookOptions,
  type MutationResult,
  useMutation,
} from '@apollo/client'
import { useCallback } from 'react'
import { graphql } from '~publish/gql'
import type {
  CreateIdeaMutation,
  CreateIdeaMutationVariables,
} from '~publish/gql/graphql'
import { useOrganizationId } from '~publish/legacy/accountContext'
import type { IdeaContent } from '~publish/pages/Create/types'
import { UNASSIGNED_GROUP_ID } from '../../pages/Create/components/Board/helpers/helpers'
import { QUERY_IDEAS_COUNT } from '../../pages/Create/hooks/useIdeasCount'

export const CREATE_IDEA = graphql(/* GraphQL */ `
  mutation CreateIdea($input: CreateIdeaInput!) {
    createIdea(input: $input) {
      __typename
      ... on Idea {
        id
        content {
          title
          text
          aiAssisted
          media {
            id
            url
            alt
            thumbnailUrl
            type
            size
            source {
              name
              id
            }
          }
          tags {
            id
            name
            color
          }
        }
        organizationId
        groupId
        createdAt
      }
      ... on MutationError {
        message
      }
    }
  }
`)

type CreateIdeaArgs = {
  content: IdeaContent & {
    date?: string
  }
  source?: CTA
  groupId?: string
  placeAfterId?: string
}

type CreateIdeaCompleted = NonNullable<
  MutationHookOptions<
    CreateIdeaMutation,
    CreateIdeaMutationVariables
  >['onCompleted']
>

type CreateIdeaError = NonNullable<
  MutationHookOptions<
    CreateIdeaMutation,
    CreateIdeaMutationVariables
  >['onError']
>

export function useCreateIdea({
  onCompleted,
  onError,
}: {
  onCompleted?: CreateIdeaCompleted
  onError?: CreateIdeaError
} = {}): {
  createIdea: (args: CreateIdeaArgs) => void
  createIdeaData: MutationResult<CreateIdeaMutation>['data']
  createIdeaError: MutationResult<CreateIdeaMutation>['error']
  createIdeaLoading: MutationResult<CreateIdeaMutation>['loading']
} {
  const organizationId = useOrganizationId()

  const [
    executeCreateIdea,
    {
      data: createIdeaData,
      loading: createIdeaLoading,
      error: createIdeaError,
    },
  ] = useMutation(CREATE_IDEA, {
    refetchQueries: [QUERY_IDEAS_COUNT, 'GetIdeasBoard', 'GetIdeasGallery'],
    onCompleted,
    onError,
  })

  const createIdea = useCallback(
    ({ content, groupId, placeAfterId, source }: CreateIdeaArgs) => {
      if (organizationId) {
        executeCreateIdea({
          variables: {
            input: {
              organizationId,
              cta: source ?? 'create-webapp-createIdea-default-1',
              content,
              group: groupId
                ? {
                    groupId: groupId === UNASSIGNED_GROUP_ID ? null : groupId,
                    placeAfterId,
                  }
                : undefined,
            },
          },
        })
      }
    },
    [executeCreateIdea, organizationId],
  )

  return { createIdea, createIdeaData, createIdeaError, createIdeaLoading }
}
