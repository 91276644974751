import React, { useState, type ChangeEvent } from 'react'

import {
  AtSignIcon,
  CriticalIcon,
  EyeIcon,
  EyeOffIcon,
  Flex,
  Heading,
  HelpIcon,
  Input,
  Label,
  Link,
  Notice,
  Text,
  Tooltip,
} from '@buffer-mono/popcorn'

import styles from './Body.module.css'

import type { IBlueskyBody } from '../../types'

export function Body({
  isRefreshingChannelConnection,
  blueskyData,
  errorData,
  setErrorData,
  setBlueskyData,
}: IBlueskyBody): JSX.Element {
  const hasHandleError = errorData?.handleError !== ''
  const hasAppPasswordError = errorData?.appPasswordError !== ''

  const [hidePassword, setHidePassword] = useState(true)

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap="lg"
      className={styles.body}
    >
      <Flex justify="center" fullWidth>
        <Heading size="large">
          {isRefreshingChannelConnection
            ? 'Refresh connection to Bluesky'
            : 'Connect to Bluesky'}
        </Heading>
      </Flex>
      {errorData?.responseError && (
        <Flex>
          <Notice variant="error">{errorData.responseError}</Notice>
        </Flex>
      )}
      <Flex direction="column" gap="3xs" fullWidth>
        <Flex direction="column" gap="sm" fullWidth>
          <Label htmlFor="handle" className={styles.label}>
            Handle
            <Tooltip content="On Bluesky, click on your avatar. Your handle, located below your display name, may look like  this: @username.bsky.social. We also support custom domain handles.">
              <HelpIcon />
            </Tooltip>
          </Label>
          <Input
            prefix={<AtSignIcon />}
            className={styles.input}
            size="large"
            id="handle"
            name="handle"
            placeholder="handle"
            aria-invalid={hasHandleError}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setErrorData({
                ...errorData,
                handleError: '',
                responseError: '',
              })
              setBlueskyData({
                ...blueskyData,
                handle: event.target.value.replace(/^@/, ''),
              })
            }}
          />
        </Flex>
        <Text size="sm" color="subtle">
          For example: yourname.bsky.social
        </Text>
        {hasHandleError && (
          <Flex gap="2xs" fullWidth className={styles.error}>
            <CriticalIcon size="xsmall" color="critical" />
            <Text size="sm" color="critical">
              {errorData?.handleError}
            </Text>
          </Flex>
        )}
      </Flex>

      <Flex direction="column" gap="3xs" fullWidth>
        <Flex direction="column" gap="sm" fullWidth>
          <Label htmlFor="appPassword" className={styles.label}>
            Bluesky App Password
          </Label>
          <Text>
            Use an app password to connect safely with Buffer without giving
            full access to your account. This is not your account password.{' '}
            <Link href="https://bsky.app/settings/app-passwords" external>
              Generate app password in Bluesky
            </Link>
          </Text>
          <Input
            className={styles.input}
            size="large"
            type={hidePassword ? 'password' : 'input'}
            value={blueskyData?.appPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              setErrorData({
                ...errorData,
                appPasswordError: '',
                responseError: '',
              })
              setBlueskyData({
                ...blueskyData,
                appPassword: event.target.value,
              })
            }}
            id="appPassword"
            name="appPassword"
            placeholder="xxxx-xxxx-xxxx-xxxx"
            aria-invalid={hasAppPasswordError}
            suffix={
              hidePassword ? (
                <EyeOffIcon
                  className={styles.eyeIcon}
                  onClick={(): void => setHidePassword(false)}
                />
              ) : (
                <EyeIcon
                  className={styles.eyeIcon}
                  onClick={(): void => setHidePassword(true)}
                />
              )
            }
          />
        </Flex>
        {hasAppPasswordError && (
          <Flex gap="2xs" fullWidth className={styles.error}>
            <CriticalIcon size="xsmall" color="critical" />
            <Text size="sm" color="critical">
              {errorData?.appPasswordError}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
