import React, { useEffect, useMemo } from 'react'

import { SuccessDialog } from '@buffer-mono/popcorn'
import Loader from '@bufferapp/ui/Loader'

import { getPublishComposerURL } from '../../../../common/utils/urls'

import { getUserChannelList } from '../../../../common/utils/user'

import { useUser } from '../../../../common/context/User'

import trackChannelConnectionModalViewed from '../../../../tracking/ChannelConnectionModal/trackChannelConnectionModalViewed'
import { cleanupUrlParams } from '../../utils'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

import NewChannelRefreshSuccessDialog from './components/NewChannelRefreshSuccess'

import styles from './ChannelRefreshSuccess.module.css'
import { getReconnectingChannelCookie } from '../../../../exports/Orchestrator/channelConnections/utils'

function handleCreatePostCTA({
  socialChannelId,
  handleOnDismissModal,
}: {
  socialChannelId: string
  handleOnDismissModal: () => void
}): void {
  const cta = 'channelRefreshSuccessModal-createAPostCta'
  handleOnDismissModal()
  window.location.href = getPublishComposerURL({ socialChannelId, cta })
}

const ChannelRefreshSuccessModal = (): JSX.Element => {
  const user = useUser()

  const { modalData, dismissModal: closeAction } = useModalManager()
  const CTACopy = 'Create a Post'
  const intendedChannelIdForRefresh = useMemo(
    () => getReconnectingChannelCookie(),
    [],
  )

  useEffect(() => {
    trackChannelConnectionModalViewed({
      payload: {
        ctaButton: modalData?.ctaButton || '',
        ctaView: modalData?.ctaView || '',
        ctaLocation: modalData?.ctaLocation || '',
        ctaVersion: modalData?.ctaVersion,
        upgradePathName: modalData?.upgradePathName || '',
      },
      user,
      isReconnecting: true,
    })
    cleanupUrlParams()
  }, [user, modalData])

  const handleOnDismissModal = (): void => {
    closeAction()
  }

  if (
    !user?.currentOrganization?.billing ||
    !user?.currentOrganization?.id ||
    !modalData?.channelIds
  ) {
    return (
      <SuccessDialog open={true} onOpenChange={handleOnDismissModal}>
        <SuccessDialog.Content className={styles.dialog}>
          <Loader />
        </SuccessDialog.Content>
      </SuccessDialog>
    )
  }

  const socialChannelIds = modalData?.channelIds
  const usersChannelList = getUserChannelList(user)

  return (
    <NewChannelRefreshSuccessDialog
      intendedChannelIdForRefresh={intendedChannelIdForRefresh}
      handleOnDismissModal={handleOnDismissModal}
      usersChannelList={usersChannelList || []}
      socialChannelIds={socialChannelIds}
      CTACopy={CTACopy}
      handleCreatePostCTA={handleCreatePostCTA}
    />
  )
}

export default ChannelRefreshSuccessModal
