import { useLocalStorage } from '@buffer-mono/popcorn'
import {
  getMatch,
  newCalendarSingleChannel,
  channel as channelRoute,
} from '~publish/legacy/routes'

const STORE_KEY = 'showTimeSlots'

export function useSlotsEnabled(): [boolean, (enabled: boolean) => void] {
  const singleChannelCalendarMatch = getMatch({
    pathname: window.location.pathname,
    route: newCalendarSingleChannel.route,
  })
  const singleChannelMatch = getMatch({
    pathname: window.location.pathname,
    route: channelRoute.route,
  })
  const isSingleChannel = !!singleChannelCalendarMatch || !!singleChannelMatch
  const [showTimeSlots, setShowTimeSlots] = useLocalStorage(
    STORE_KEY,
    isSingleChannel,
  )

  if (!isSingleChannel) {
    return [
      false,
      (): void => {
        /** do nothing */
      },
    ]
  }

  return [showTimeSlots, setShowTimeSlots]
}
