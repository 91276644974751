import { isBefore, parseISO, startOfToday } from 'date-fns'

import type { PostTab } from '~publish/components/PostTabs'
import type { PostStatus, PostCard_PostFragment } from '~publish/gql/graphql'

// API currently supports array of statuses, and we do mapping here between the tabs and statuses
export const postStatusByTab: Record<PostTab, PostStatus[]> = {
  drafts: ['draft'],
  queue: ['scheduled', 'error'],
  approvals: ['needs_approval'],
  sent: ['sent', 'sending'],
}

export const mapPostStatusToTab = (status: PostStatus): PostTab => {
  for (const [tab, statuses] of Object.entries(postStatusByTab)) {
    if (statuses.includes(status)) {
      return tab as PostTab
    }
  }

  return 'queue'
}

export function isPausedPost(
  post: PostCard_PostFragment,
): post is PostCard_PostFragment & {
  dueAt: string
  status: 'scheduled' | 'error'
} {
  const { status, dueAt } = post
  return (
    (postStatusByTab.queue.includes(status) &&
      !!dueAt &&
      isBefore(parseISO(dueAt), startOfToday())) ||
    (postStatusByTab.queue.includes(status) && dueAt === null)
  )
}

export function isUnscheduledDraft(
  post: PostCard_PostFragment,
): post is PostCard_PostFragment & {
  dueAt: null
  status: 'draft'
} {
  const { status, dueAt } = post
  return postStatusByTab.drafts.includes(status) && !dueAt
}

export function isUnscheduledApproval(
  post: PostCard_PostFragment,
): post is PostCard_PostFragment & {
  dueAt: null
  status: 'needs_approval'
} {
  const { status, dueAt } = post
  return postStatusByTab.approvals.includes(status) && !dueAt
}

export function isScheduledPost(
  post: PostCard_PostFragment,
): post is PostCard_PostFragment & { dueAt: string } {
  return (
    !isUnscheduledDraft(post) &&
    !isUnscheduledApproval(post) &&
    !isPausedPost(post)
  )
}

/**
 * Gets the appropriate display date for a post based on its status
 * For sent posts, use sentAt if available, otherwise fallback to dueAt
 * For other posts, use dueAt
 */
export const getPostDisplayDate = (post: {
  status: PostStatus
  sentAt?: string | null
  dueAt: string
}): string => {
  if ((post.status === 'sent' || post.status === 'sending') && post.sentAt) {
    return post.sentAt
  }
  return post.dueAt
}
