import { Button } from '@bufferapp/ui'
import Text from '@bufferapp/ui/Text'
import * as Dialog from '@radix-ui/react-dialog'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ButtonWrapper,
  CloseDialogButton,
  CloseIcon,
  InnerWrapper,
  ModalWrapper,
  StyledText,
} from './styles'
import styles from '../IdeaComposer.module.css'

const ConfirmCloseModal = ({
  onCancel,
  onConfirmClose,
  container,
}: {
  onCancel: (event: React.MouseEvent) => void
  onConfirmClose: () => void
  container?: HTMLElement | null
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Dialog.Portal container={container}>
      <div className={styles.overlay} />
      <ModalWrapper
        onPointerDownOutside={(e): void => e.preventDefault()}
        aria-describedby={undefined}
      >
        <InnerWrapper>
          <Dialog.Title asChild>
            <Text type="h3">{t('content.modals.confirmClose.title')}</Text>
          </Dialog.Title>
          <Dialog.Description asChild>
            <StyledText type="p">
              {t('content.modals.confirmClose.description')}
            </StyledText>
          </Dialog.Description>
          <ButtonWrapper>
            {/* FIXME: Use Popcorn Button */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: any; onClick: () => v... Remove this comment to see the full error message */}
            <Button
              type="text"
              label={t('content.modals.confirmClose.cancel')}
              onClick={onCancel}
            />
            {/* FIXME: Use Popcorn Button */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: any; onClick: () => v... Remove this comment to see the full error message */}
            <Button
              type="danger"
              label={t('content.modals.confirmClose.confirm')}
              onClick={onConfirmClose}
            />
          </ButtonWrapper>
          <CloseDialogButton asChild>
            <CloseIcon size="large" />
          </CloseDialogButton>
        </InnerWrapper>
      </ModalWrapper>
    </Dialog.Portal>
  )
}

ConfirmCloseModal.propTypes = {}

export default ConfirmCloseModal
