import React from 'react'
import { Calendar, Popover } from '@buffer-mono/popcorn'
import type { Temporal } from '@js-temporal/polyfill'
import styles from './DatePicker.module.css'

export interface DatePickerProps {
  /**
   * The currently selected date
   */
  value?: Temporal.PlainDate
  /**
   * Callback when the selected date changes
   */
  onValueChange?: (value?: Temporal.PlainDate) => void
  /**
   * The timezone to use for date selection
   * Defaults to the user's local timezone
   */
  timezone?: string
  /**
   * The first day of the week in the calendar
   * 0 = Sunday, 1 = Monday
   * Defaults to Sunday (0)
   */
  weekStartsOn?: 0 | 1
  /**
   * The children of the dialog trigger
   */
  children?: React.ReactNode
}

/**
 * DatePicker component that wraps Popcorn's Calendar component
 * for use in the IdeaEditor for scheduling posts
 */
export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onValueChange,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  weekStartsOn = 0,
  children,
}) => {
  return (
    <Popover>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content size="small" align="start" className={styles.content}>
        <Calendar
          value={value}
          onValueChange={onValueChange}
          timezone={timezone}
          weekStartsOn={weekStartsOn}
        />
      </Popover.Content>
    </Popover>
  )
}
