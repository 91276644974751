import React from 'react'
import { Button, Dialog } from '@buffer-mono/popcorn'

export const ConfirmCloseDialog = ({
  open,
  onOpenChange,
  onConfirm,
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirm: () => void
}): JSX.Element => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <Dialog.Content>
        <Dialog.CloseButton />
        <Dialog.Header>
          <Dialog.Title>Discard changes?</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Dialog.Description>
            Any changes you&apos;ve made will be lost. This can&apos;t be
            undone.
          </Dialog.Description>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close>
            <Button variant="secondary">Cancel</Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button variant="critical" onClick={onConfirm}>
              Discard Changes
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
