import React from 'react'

import styles from '../../styles.module.css'

import { colorSwatches } from '../../index'

import { SwatchGroup } from '@buffer-mono/popcorn'

const ColorSwatches = ({
  colorSelected,
  onColorChange,
}: {
  colorSelected: string
  onColorChange: (color: string) => void
}): JSX.Element => (
  <SwatchGroup
    className={styles.colorSwatchesContainer}
    value={colorSelected}
    onChange={(event): void => onColorChange(event.target.value)}
  >
    {Object.entries(colorSwatches).map(([name, color]) => (
      <SwatchGroup.Swatch key={color} color={color} name={name} />
    ))}
  </SwatchGroup>
)

export default ColorSwatches
