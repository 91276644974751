import React from 'react'
import { Text } from '@buffer-mono/popcorn'

import type { Service } from '~publish/gql/graphql'
import { parseThreadsLinks } from '~publish/legacy/utils/channels/threads/helpers'
import { LinkifiedText } from '~publish/legacy/shared-components/LinkifiedText'

import styles from './CommentText.module.css'

export const CommentText = ({
  text,
  serviceType,
}: {
  text: string
  serviceType: Service
}): JSX.Element => {
  if (serviceType === 'threads') {
    return (
      <LinkifiedText
        links={parseThreadsLinks(text)}
        whitespace="pre-wrap"
        newTab
        className={styles.commentText}
      >
        {text}
      </LinkifiedText>
    )
  }

  return <Text>{text}</Text>
}
