import React from 'react'
import { Redirect } from 'react-router-dom'

import { useSplitEnabled } from '@buffer-mono/features'

import { BufferLoading } from '~publish/legacy/shared-components'
import PagesWithSidebar from '~publish/legacy/app-pages/components/PagesWithSidebar'

export const CommentsRouteHandler = ({
  defaultPath,
}: {
  defaultPath: string
}): JSX.Element => {
  const { isEnabled: isCommentsPageEnabled } = useSplitEnabled('geid-comments')
  const [flagStabilized, setFlagStabilized] = React.useState(false)
  const initialRender = React.useRef(true)

  React.useEffect(() => {
    // After the initial render, mark the flag as stabilized
    // This prevents redirects after initial render
    if (initialRender.current) {
      initialRender.current = false

      // Small delay to allow for potential quick updates to the flag
      const timeoutId = setTimeout(() => {
        setFlagStabilized(true)
      }, 800)

      return () => clearTimeout(timeoutId)
    }
  }, [])

  // If we're on the initial load and the flag isn't ready, show the UI anyway
  // This prevents the blank screen while waiting for the flag
  if (!flagStabilized) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <BufferLoading />
      </div>
    )
  }

  // Once the flag is stabilized, we can make the real routing decision
  if (isCommentsPageEnabled) {
    return <PagesWithSidebar />
  }

  return <Redirect to={defaultPath} />
}
