import { CloseIcon, Flex, IconButton } from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'
import { useAnimatedVisibility } from '~publish/legacy/hooks/useAnimatedVisibility'
import { useIdeaComposerState } from '../../IdeaComposerContext'
import styles from './Sidepanel.module.css'

export const Sidepanel = ({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}): JSX.Element | null => {
  const { isSidepanelOpen, toggleSidepanel } = useIdeaComposerState()

  const {
    isVisibleInDOM: SidepanelVisible,
    shouldBeVisible: SidepanelShouldBeVisible,
    onAnimationEnd: SidepanelAnimationEnd,
  } = useAnimatedVisibility({
    visibilityCondition: isSidepanelOpen,
  })

  if (!SidepanelVisible) return null

  return (
    <Flex
      as="section"
      data-state={SidepanelShouldBeVisible ? 'open' : 'closed'}
      className={clsx(styles.sidepanel, className)}
      onAnimationEnd={SidepanelAnimationEnd}
    >
      <IconButton
        label="Close"
        variant="tertiary"
        onClick={toggleSidepanel}
        className={styles.close}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Flex>
  )
}
