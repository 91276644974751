import React from 'react'
import { Button, Dialog } from '@buffer-mono/popcorn'

const ConfirmDeleteTeamMemberModal = ({
  onConfirm,
  close,
  isOpen,
  member,
}: {
  onConfirm: () => void
  close: () => void
  isOpen: boolean
  member?: { name: string; email: string }
}): JSX.Element => {
  function handleSubmit(): void {
    onConfirm()
    close()
  }

  return (
    <Dialog modal open={isOpen}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>Are you sure?</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Dialog.Description>
            You are about to remove{' '}
            <strong>
              {member?.name} ({member?.email})
            </strong>{' '}
            from this organization. This user will no longer have access to any
            assigned channels. Are you sure you want to continue?
          </Dialog.Description>
        </Dialog.Body>
        <Dialog.Footer>
          <Button variant="tertiary" size="large" onClick={close}>
            Cancel
          </Button>

          <Button variant="primary" size="large" onClick={handleSubmit}>
            Yes, Remove
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

export default ConfirmDeleteTeamMemberModal
