import React, { useState } from 'react'
import { useAppSelector } from '~publish/legacy/store'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'
import { PostTypeStory } from '~publish/legacy/post/constants'
import CharacterHashtagCount from './CharacterHashtagCount'
import AddThread from './AddThread'
import { IntegrationsBar } from '../../../integrations-bar/IntegrationsBar'
import { useIntegrationServices } from '../../../integrations-bar/useIntegrationServices'
import { AIAssistantTrigger } from '~publish/legacy/ai/components/Triggers/AIAssistantTrigger'
import type { Draft } from '../entities/Draft'

import {
  IntegrationsWrapper,
  Emoji,
  LeftWrapper,
  RightWrapper,
  VerticalDivider,
  IconWrapper,
  ShortLinkNoticeWrapper,
} from '../../../integrations-bar/styles'
import { HashTagManagerButton } from '../../../integrations-bar/components/HashTagManagerButon'
import { LinkShortenerButton } from '~publish/legacy/integrations-bar/components/LinkShortenerButton'
import { CloseIcon, Notice as PopcornNotice } from '@buffer-mono/popcorn'
import { getCurrentProfile } from '~publish/legacy/composer/composer/components/app/AppStateless'
import { handleUploadError } from '../action-creators/handleUpdloadError'
import { selectPendingUploads } from '../../../uploads/state/selectors'
import type { Uploader } from '@buffer-mono/uploader'
import { useOpenAiAssistant } from '../hooks/useOpenAiAssistant'

/**
 *
 */
export const ComposerIntegrationsBar = ({
  draft,
  emojiContainer = null,
  uploader,
}: {
  draft: Draft
  emojiContainer: React.ReactElement | null
  uploader: Uploader
}): JSX.Element => {
  const context = 'publishComposer'

  const onOpenAiAssistant = useOpenAiAssistant()
  const uploadsInProgress = useAppSelector((state) =>
    selectPendingUploads(state, uploader.getId()),
  )

  const shouldShowUnsplashButton =
    !!(draft?.service?.name !== Service.Youtube) &&
    !draft.isReelsPost() &&
    !draft.isFacebookReelPost()

  const shouldShowCanvaButton =
    !draft.isVerticalVideoOnlySupported() && !draft.isFacebookReelPost()

  // call to the hook that returns services configuration for the context
  const { services, activeService } = useIntegrationServices({
    context,
    uploader,
    updateType: draft.isStoryPost()
      ? PostTypeStory
      : draft.updateType ?? undefined, // only used for uploadCareOptions to make differences in case of being PostTypeStory
    serviceName: draft.isStoryPost() ? 'instagramStory' : draft.service.name,
    onError: handleUploadError,
    enableCanva: shouldShowCanvaButton,
    enableUnsplash: shouldShowUnsplashButton,
  })

  const remainingImages = draft.getTotalImageUploadsRemaining()
  const isOverTheLimit = uploadsInProgress.length >= remainingImages
  const disableBar = !draft.canAttachMoreMedia() || isOverTheLimit

  const selectedProfile = getCurrentProfile()

  const showLinkShortenerButton =
    draft.service.isShorteningLinksAvailable && draft.canType()

  const [shortLinkMessageDismissed, setShortlinkMessageDismissed] = useState(
    !!localStorage.getItem('short_link_message_dismissed'),
  )

  const hideShortLinkMessage = (): void => {
    localStorage.setItem('short_link_message_dismissed', 'true')
    setShortlinkMessageDismissed(true)
  }

  return (
    <>
      {!shortLinkMessageDismissed &&
        showLinkShortenerButton &&
        draft.shouldShowShortLinkMessage() && (
          <ShortLinkNoticeWrapper>
            <PopcornNotice
              variant="warning"
              className={'notice-link-too-short'}
            >
              <PopcornNotice.Text>
                This link is too short for further shortening{' '}
                <IconWrapper>
                  <CloseIcon onClick={hideShortLinkMessage} size="small" />
                </IconWrapper>
              </PopcornNotice.Text>
            </PopcornNotice>
          </ShortLinkNoticeWrapper>
        )}
      <IntegrationsWrapper className="boxed">
        <IntegrationsBar
          context={context}
          services={services}
          activeService={activeService}
          disableBar={disableBar}
        >
          <LeftWrapper>
            {draft.canType() && (
              <>
                <VerticalDivider />
                <Emoji data-tip="Add Emoji">{emojiContainer}</Emoji>
              </>
            )}
            <HashTagManagerButton draft={draft} />
            {showLinkShortenerButton && selectedProfile && (
              <LinkShortenerButton
                draft={draft}
                selectedProfileId={selectedProfile.id}
                domainForLinkShorteningSelectedProfile={
                  selectedProfile.shorteningDomain
                }
              />
            )}
            {draft.canType() && (
              <>
                <VerticalDivider />
                <AIAssistantTrigger onClick={onOpenAiAssistant} />
              </>
            )}
          </LeftWrapper>
          <RightWrapper>
            <CharacterHashtagCount draft={draft} />
            <AddThread draft={draft} />
          </RightWrapper>
        </IntegrationsBar>
      </IntegrationsWrapper>
    </>
  )
}
