import React, { useState } from 'react'

import { Button } from '@buffer-mono/popcorn'

import styles from './index.module.css'
import clsx from 'clsx'
import type { CustomLink } from '~publish/legacy/profile-sidebar/types'

export interface LinkPreviewProps {
  isOver: boolean
  bgColor: string
  textColor: string
  item: CustomLink
  onToggleEditMode: (item: CustomLink, editing: boolean) => void
  onDeleteCustomLink: (customLinkId: string) => void
  hasWriteAccess: boolean
}

const LinkPreview = ({
  isOver,
  bgColor,
  textColor,
  item,
  onToggleEditMode,
  onDeleteCustomLink,
  hasWriteAccess,
}: LinkPreviewProps): JSX.Element => {
  const [isConfirmingDelete, setConfirmingDelete] = useState<boolean>(false)

  return (
    <div
      className={clsx(
        styles.previewWrapper,

        {
          [styles.isTarget]: isOver,
          [styles.hasWriteAccess]: hasWriteAccess,
        },
      )}
    >
      <div className={styles.linkPreviewRow}>
        <div
          className={styles.linkPreviewButton}
          style={{ backgroundColor: bgColor, color: textColor }}
        >
          {item.text}
        </div>
        <div className={styles.linkPreviewRow}>
          <div className={styles.urlWrapper}>{item.url}</div>
        </div>
        {hasWriteAccess && (
          <>
            {onDeleteCustomLink && (
              <>
                <Button
                  variant="tertiary"
                  onClick={(): void => setConfirmingDelete(!isConfirmingDelete)}
                >
                  {isConfirmingDelete ? 'Cancel' : 'Delete'}
                </Button>
                {isConfirmingDelete && (
                  <Button onClick={(): void => onDeleteCustomLink(item.id)}>
                    Delete
                  </Button>
                )}
              </>
            )}
            {!isConfirmingDelete && (
              <Button
                variant="secondary"
                onClick={(): void => onToggleEditMode(item, true)}
              >
                Edit
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default LinkPreview
