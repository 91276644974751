import { Sidebar } from '@buffer-mono/popcorn'
import type { Location } from 'history'
import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { IdeaManagementRouter } from '~publish/components/IdeaManagementRouter'
import {
  createPage,
  feedCollectionPage,
  singleFeedPage,
} from '~publish/legacy/routes'
import { ContentSidebar } from './components/ContentSidebar/ContentSidebar'
import { CollectionList } from './Feed/components/CollectionList/CollectionList'
import { FeedList } from './Feed/components/FeedList/FeedList'
import { IdeasPage } from './IdeasPage'

export function CreatePage(): JSX.Element {
  const location = useLocation<{ returnTo?: Location }>()
  const backgroundLocation = location.state?.returnTo

  return (
    <Sidebar.Provider mobileFallback={true}>
      <ContentSidebar />
      <Switch location={backgroundLocation || location}>
        <Route path={feedCollectionPage.route} key="feed-collection-route">
          <CollectionList />
        </Route>
        <Route path={singleFeedPage.route} key="feed-route">
          <FeedList />
        </Route>
        <Route path={createPage.route} key="create-route">
          <IdeasPage pageTitle="Ideas" />
        </Route>
      </Switch>
      <IdeaManagementRouter />
    </Sidebar.Provider>
  )
}
