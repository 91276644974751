import { useEffect, useMemo, useState } from 'react'
import type { RawTimeZone } from '@vvo/tzdb'

import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

import { useChannels } from '../PageSidebar/useChannels'
import { getTimezonesMap, type TimezoneMap } from './timezone-filter'

/**
 * Custom hook to manage and cache the timezone map state.
 * Fetches and stores the timezone mapping data on component mount.
 *
 * @returns {TimezoneMap} An object mapping timezone names to their corresponding RawTimeZone objects
 *
 * @example
 * const timezones = useTimezoneMap()
 * console.log(timezones['America/New_York']) // { name: 'America/New_York', mainCities: ['New York', ...], ... }
 */
export function useTimezoneMap(): TimezoneMap {
  const [timezones, setTimezones] = useState<TimezoneMap>({})

  useEffect(() => {
    getTimezonesMap().then(setTimezones)
  }, [])

  return timezones
}

/**
 * Custom hook to fetch and provide a list of all available timezones.
 * Uses the @vvo/tzdb package to get comprehensive timezone data.
 *
 * @returns {RawTimeZone[]} An array of all available timezone objects
 *
 * @example
 * const timezones = useTimezones()
 * console.log(timezones[0]) // { name: 'Africa/Abidjan', mainCities: ['Abidjan'], ... }
 */
export function useTimezones(): RawTimeZone[] {
  const [timezones, setTimezones] = useState<RawTimeZone[]>([])
  useEffect((): void => {
    ;(async (): Promise<void> => {
      const { getTimeZones } = await import('@vvo/tzdb')
      setTimezones(getTimeZones())
    })()
  }, [])
  return timezones
}

/**
 * Custom hook to get compatible timezone objects.
 * It finds timezone objects by their name or by checking if the timezone is in another timezone's group.
 *
 * @param {string | undefined} timezone - The timezone identifier to look up
 * @returns {RawTimeZone | undefined} The matching timezone object, or undefined if not found
 *
 * @example
 * const compatibleTimezone = useCompatibleTimezone('America/Los_Angeles')
 * // Or with a timezone from a group:
 * const eastCoastTimezone = useCompatibleTimezone('America/Detroit') // Will find 'America/New_York'
 */
export function useCompatibleTimezone(
  timezone: string | undefined,
): RawTimeZone | undefined {
  const timezones = useTimezones()
  const [rawTimezone, setRawTimezone] = useState<RawTimeZone | undefined>(
    undefined,
  )

  useEffect(() => {
    if (!timezone) return

    const value = timezones.find((timeZone) => {
      return timezone === timeZone.name || timeZone.group.includes(timezone)
    })
    setRawTimezone(value)
  }, [timezone, timezones])

  return rawTimezone
}

/**
 * Custom hook to get timezone suggestions based on user input and channel data.
 * Returns a tuple of two timezone arrays: [suggestedTimezones, otherTimezones]
 *
 * When there's no input:
 * - First array contains the browser's timezone and timezones used in channels
 * - Second array contains all other timezones
 *
 * When there's input:
 * - First array is empty
 * - Second array contains all timezones (filtering is handled elsewhere)
 *
 * @param {string} inputValue - The current search input value
 * @returns {[RawTimeZone[], RawTimeZone[]]} A tuple containing [suggestedTimezones, otherTimezones]
 *
 * @example
 * const [suggestions, others] = useTimezoneSuggestions('')
 * // suggestions: Browser timezone + channel timezones
 * // others: All other timezones
 *
 * const [suggestions, others] = useTimezoneSuggestions('new')
 * // suggestions: [] (empty array)
 * // others: All timezones (will be filtered by the Combobox component)
 */
export function useTimezoneSuggestions(
  inputValue: string,
): [RawTimeZone[], RawTimeZone[]] {
  const { channels } = useChannels()
  const timezones = useTimezones()

  return useMemo(() => {
    if (inputValue) return [[], timezones] // Return empty array for suggestions and all timezones for filtering

    const browserTimezone = timezones.find(
      (timezone) => timezone.name === getCurrentTimeZone(),
    )
    const withoutBrowserTimezone = timezones.filter(
      (timezone) => timezone.name !== getCurrentTimeZone(),
    )

    // Split timezones so the first group are timezones in channels and the second the rest
    const [suggestedTimezones, otherTimezones] = withoutBrowserTimezone.reduce(
      ([suggestions, other], timezone) => {
        if (
          channels?.some(
            (channel) =>
              channel.timezone === timezone.name ||
              timezone.group.includes(channel.timezone),
          )
        ) {
          return [[...suggestions, timezone], other]
        }
        return [suggestions, [...other, timezone]]
      },
      [[] as RawTimeZone[], [] as RawTimeZone[]],
    )

    return [
      [...(browserTimezone ? [browserTimezone] : []), ...suggestedTimezones],
      otherTimezones,
    ]
  }, [channels, inputValue, timezones])
}
