import { useSplitEnabled } from '@buffer-mono/features'
import {
  AllChannelsIcon,
  AppearanceIcon,
  AudienceIcon,
  Badge,
  CloseIcon,
  DollarIcon,
  DropdownMenu,
  FlaskConicalIcon,
  LogOutIcon,
  MessageCircleHeartIcon,
  NewBadge,
  type Theme,
  THEMES,
  UserIcon,
  UserRoundPlusIcon,
  useTheme,
  VisuallyHidden,
  WarningIcon,
  ZapFilledIcon,
} from '@buffer-mono/popcorn'
import React, { useCallback, useMemo } from 'react'

import {
  canHaveTeamMembers,
  hasEditPermission,
  isFreeUser,
  isOneBufferOrganization,
  userCanStartFreeTrial,
} from '../../../../common/utils/user'
import type { Account } from '../../../../common/types'
import UpgradePathsTracking from '../../../../tracking/UpgradePaths'

import {
  handleLogoutClick,
  handlePlansAndPricingClick,
  handleTeamClick,
  handleUpgradeClick,
} from './callbacksAccountMenu'

import {
  getBillingUrl,
  getManageChannelsURL,
  getMigrationHubUrl,
  getNewSettingsUrl,
  getStopImpersonationUrl,
} from '../../../../common/utils/urls'

import { useProductHistory } from '../../../../exports/Navigator/utils/useProductHistory'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'
import type { NavDropdownItem } from '../../utils/dropdown-items'

import { VerifyEmailModal } from './components/VerifyEmailModal'
import { DeveloperDataItem } from './components/DeveloperDataItem'

import styles from './AccountMenu.module.css'
import { OrganizationsSwitcher } from './components/OrganizationsSwitcher'

type AccountMenuProps = {
  user: Account
  onLogout?: () => void
  handleOnClose?: () => void
  children: React.ReactNode
}

export const AccountMenu = ({
  user,
  onLogout,
  children,
  handleOnClose,
}: AccountMenuProps): JSX.Element => {
  const { trackCTAClicked } = UpgradePathsTracking(user)
  const { openModal } = useModalManager()
  const [openMenu, setOpenMenu] = React.useState(false)

  const isFree = isFreeUser(user)
  const canEdit = hasEditPermission(user)
  const canStartTrial = userCanStartFreeTrial(user)
  const isOneBufferOrg = isOneBufferOrganization(user)
  const freeUserCanNotStartATrial = isFree && isOneBufferOrg && !canStartTrial
  const freeUserCanStartATrial = isFree && isOneBufferOrg && canStartTrial
  const shouldShowTeamMemberUpgradePath =
    isOneBufferOrg && !canHaveTeamMembers(user)
  const isBetaEnabled = user?.currentOrganization?.isBetaEnabled
  const isImpersonation = user.isImpersonation

  const { isEnabled: isDeveloperDataEnabled } = useSplitEnabled(
    'show-developer-data',
  )

  const { isEnabled: isNewChannelSettingsPageEnabled } = useSplitEnabled(
    'channel-settings-refresh',
  )

  const showDeveloperData = isDeveloperDataEnabled || isImpersonation

  const { isEnabled: isThemeSwitcherEnabled } = useSplitEnabled(
    'buffer-test-theme-switcher',
  )

  /* CALLBACKS */
  const handleOpenAccountMenu = useCallback((open: boolean) => {
    setOpenMenu(open)
  }, [])

  const { navigate } = useProductHistory()

  /* MENU ITEMS */
  const menuItems: NavDropdownItem[] = useMemo(() => {
    const baseItems = [
      {
        id: 'account',
        title: 'Settings',
        icon: <UserIcon size="small" />,
        hasDivider: true,
        onItemClick: (): void => {
          navigate(getNewSettingsUrl())
        },
        badge: <NewBadge size="small" className={styles.badgeNewSettings} />,
      },
      {
        id: 'channels',
        title: 'Channels',
        icon: <AllChannelsIcon size="small" />,
        onItemClick: (): void => {
          isNewChannelSettingsPageEnabled
            ? navigate(getNewSettingsUrl('channels'))
            : navigate(getManageChannelsURL())
        },
      },
      !isOneBufferOrg && {
        id: 'upgrade',
        title: 'Explore New Features',
        icon: <ZapFilledIcon color="brand" size="small" />,
        className: 'colorBrand',
        hasDivider: true,
        url: getMigrationHubUrl('topNavMenu-migration-cta'),
      },
      freeUserCanNotStartATrial && {
        id: 'upgrade',
        title: 'Upgrade',
        icon: <ZapFilledIcon color="brand" size="small" />,
        className: 'colorBrand',
        hasDivider: true,
        onItemClick: handleUpgradeClick(openModal, trackCTAClicked),
      },
      isFree && {
        id: 'plansandpricing',
        title: 'Plans & Pricing',
        icon: <DollarIcon size="small" />,
        className: 'colorBrand',
        hasDivider: true,
        onItemClick: handlePlansAndPricingClick(openModal, trackCTAClicked),
      },
      !isFree && {
        id: 'billing',
        title: 'Billing',
        icon: <DollarIcon size="small" />,
        url: getBillingUrl(),
      },
      canEdit && {
        id: 'openTeam',
        title: shouldShowTeamMemberUpgradePath ? 'Invite Your Team' : 'Team',
        icon: shouldShowTeamMemberUpgradePath ? (
          <UserRoundPlusIcon color="brand" size="small" />
        ) : (
          <AudienceIcon size="small" />
        ),
        className: shouldShowTeamMemberUpgradePath
          ? 'colorBrand'
          : 'colorNeutral',
        hasDivider: shouldShowTeamMemberUpgradePath,
        onItemClick: handleTeamClick(
          shouldShowTeamMemberUpgradePath,
          openModal,
          trackCTAClicked,
          user,
          navigate,
        ),
      },
      {
        id: 'referral',
        title: 'Refer a Friend',
        icon: <MessageCircleHeartIcon size="small" />,
        hasDivider: !shouldShowTeamMemberUpgradePath,
        onItemClick: (): void => {
          navigate(getNewSettingsUrl('refer-a-friend'))
        },
      },
      {
        id: 'beta',
        title: 'Beta Features',
        icon: <FlaskConicalIcon data-testid="beta-enabled-icon" size="small" />,
        hasDivider: false,
        className: 'colorNeutral',
        onItemClick: (): void => {
          navigate(getNewSettingsUrl('beta'))
        },
        badge: isBetaEnabled ? (
          <Badge className={styles.badgeON} size="small">
            ON
          </Badge>
        ) : null,
      },
      user.isImpersonation
        ? {
            id: 'Stop Impersonation',
            title: 'Stop Impersonation',
            icon: <CloseIcon size="small" />,
            hasDivider: true,
            url: getStopImpersonationUrl(),
          }
        : {
            id: 'logout',
            title: 'Logout',
            icon: <LogOutIcon size="small" />,
            hasDivider: true,
            onItemClick: handleLogoutClick(onLogout),
          },
    ] as NavDropdownItem[]

    return baseItems.filter(Boolean) as NavDropdownItem[]
  }, [
    user,
    onLogout,
    canStartTrial,
    isFree,
    isOneBufferOrg,
    shouldShowTeamMemberUpgradePath,
  ])

  return (
    <DropdownMenu
      modal={false}
      open={openMenu}
      onOpenChange={handleOpenAccountMenu}
      className={styles.accountMenu}
    >
      <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {/* email */}
        {!user.hasVerifiedEmail ? (
          <VerifyEmailModal onCloseMenu={setOpenMenu}>
            <DropdownMenu.Item
              onSelect={(e: Event): void => {
                e.preventDefault()
              }}
            >
              <span className={styles.userEmail}>{user.email}</span>
              <WarningIcon
                data-testid="email-not-verified-icon"
                color="warning"
              />
              <VisuallyHidden>Warning, Email not verified</VisuallyHidden>
            </DropdownMenu.Item>
          </VerifyEmailModal>
        ) : (
          <DropdownMenu.Item disabled>
            <span className={styles.userEmail}>{user.email}</span>
          </DropdownMenu.Item>
        )}

        {/* organizations switcher */}
        <OrganizationsSwitcher />

        {menuItems.map((item) => (
          <React.Fragment key={item.id}>
            {item.hasDivider && (
              <DropdownMenu.Separator key={`separator-${item.id}`} />
            )}
            <DropdownMenu.Item
              key={item.id}
              id={item.id}
              onSelect={item.onItemClick}
              onClick={handleOnClose}
              style={{ minWidth: '180px' }}
              className={
                item.className ? styles[item.className] : styles.colorNeutral
              }
              {...(item.url ? { asChild: true } : {})}
            >
              {/* We are using anchor elements when item is a navigation link
            as they are more accessible */}
              {item.url ? (
                <a href={item.url}>{renderItemContent(item)}</a>
              ) : (
                renderItemContent(item)
              )}
            </DropdownMenu.Item>
          </React.Fragment>
        ))}
        {isThemeSwitcherEnabled && <ThemeSwitcher />}
        {showDeveloperData && <DeveloperDataItem user={user} />}
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

const renderItemContent = (item: NavDropdownItem): JSX.Element => (
  <>
    {item.icon}
    {item.title}
    {item.badge}
  </>
)

export default AccountMenu

export const ThemeSwitcher = (): JSX.Element => {
  const { theme, setTheme } = useTheme()

  const themes = Object.entries(THEMES) as [Theme, string][]

  return (
    <>
      <DropdownMenu.Separator />
      <DropdownMenu.Sub>
        <DropdownMenu.SubTrigger>
          <AppearanceIcon />
          Theme
        </DropdownMenu.SubTrigger>
        <DropdownMenu.SubMenu>
          <DropdownMenu.RadioGroup
            aria-label="Choose theme"
            value={theme}
            onChange={(value: string): void => setTheme(value as Theme)}
          >
            {themes.map(([key, title]) => (
              <DropdownMenu.RadioItem key={key} value={key}>
                {title}
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.SubMenu>
      </DropdownMenu.Sub>
    </>
  )
}
