import {
  AlertDialog,
  Button,
  DropdownMenu,
  TrashIcon,
  useDropdownMenu,
} from '@buffer-mono/popcorn'
import React from 'react'

type DeleteConfirmationDialogProps = {
  mode: 'collection' | 'feed'
  children?: React.ReactNode
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void
  onOpenChange?: (open: boolean) => void
}

export const DeleteConfirmationDialog = (
  props: DeleteConfirmationDialogProps,
): JSX.Element => {
  const { onConfirm, mode, children, onOpenChange } = props

  return (
    <AlertDialog onOpenChange={onOpenChange}>
      {children && <AlertDialog.Trigger>{children}</AlertDialog.Trigger>}
      <AlertDialog.Content>
        <AlertDialog.Title>Are you sure?</AlertDialog.Title>
        <AlertDialog.Description>
          {mode === 'collection'
            ? 'This collection will be permanently deleted.'
            : 'This feed will be permanently deleted.'}
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary">Cancel</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button variant="critical" onClick={onConfirm}>
              Confirm
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

export const DeleteDropdownMenuItem = (
  props: DeleteConfirmationDialogProps,
): JSX.Element => {
  const { close } = useDropdownMenu()

  const closeDropdownOnDialogClose = (open: boolean): void => {
    if (!open) {
      close()
    }
  }
  return (
    <DeleteConfirmationDialog
      {...props}
      onOpenChange={closeDropdownOnDialogClose}
    >
      <DropdownMenu.Item
        variant="critical"
        onSelect={(e): void => {
          e.preventDefault()
        }}
      >
        <TrashIcon />
        Delete
      </DropdownMenu.Item>
    </DeleteConfirmationDialog>
  )
}
