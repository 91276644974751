import { Temporal } from '@js-temporal/polyfill'
import React, { useMemo } from 'react'
import { format as fnsFormat } from 'date-fns'
import clsx from 'clsx'

import { ArrowDownUpIcon, Text, UnstyledButton } from '@buffer-mono/popcorn'

import { type FragmentType, getFragmentData } from '~publish/gql'
import { format } from '~publish/helpers/temporal'
import { CalendarPostCard_Post } from '~publish/pages/Calendar/fragments/CalendarPostCard_Post'

import { CalendarPostPopover } from '../CalendarPostPopover'
import { PostMiniature } from '../PostMiniature'

import styles from './PostItem.module.css'

/**
 * This component renders an individual post item within the calendar.
 * It handles the display of post details and integrates with the PostMiniature.
 *
 * @component
 * @param {Object} props
 * @param {FragmentType<typeof CalendarPostCard_Post>} props.post - The post data to display
 * @param {number} props.timestamp - The timestamp for the post
 * @param {'week' | 'month'} props.viewMode - The current calendar view mode
 * @param {boolean} props.viewOnly - Whether the post is in view-only mode
 * @param {string} props.timezone - The current timezone
 * @param {Function} props.setOpenModal - Function to control modal visibility
 *
 * @returns {JSX.Element | null} The rendered post item or null if no post data
 */
export function PostItem({
  post: maskedPost,
  timestamp,
  viewMode,
  timezone,
  isDraggingOver = false,
}: {
  post: FragmentType<typeof CalendarPostCard_Post>
  timestamp: number
  viewMode: 'week' | 'month'
  timezone: string
  isDraggingOver?: boolean
}): JSX.Element | null {
  const post = getFragmentData(CalendarPostCard_Post, maskedPost)

  const ariaLabel = useMemo(() => {
    if (!post || !post.dueAt) return ''
    const day =
      Temporal.Instant.fromEpochMilliseconds(timestamp).toZonedDateTimeISO(
        timezone,
      )
    const scheduledDay = format(day, 'd MMMM EEEE yyyy')
    const channelService = post.channelService
    const formattedTime = fnsFormat(new Date(post.dueAt), 'HH:mm')
    const error = post.status === 'error' ? ' with an error' : ''
    return `${channelService} post scheduled for ${scheduledDay}, ${formattedTime}${error}`
  }, [post, timestamp, timezone])

  if (!post) {
    return null
  }

  return (
    <CalendarPostPopover post={maskedPost}>
      <UnstyledButton
        aria-label={ariaLabel}
        className={clsx(styles.postTriggerButton, {
          [styles.draggingOver]: isDraggingOver,
        })}
      >
        <PostMiniature
          post={post}
          condensed={viewMode === 'month'}
          className={styles.miniature}
        />
        {isDraggingOver && (
          <div className={styles.draggingOverOverlay}>
            <ArrowDownUpIcon />
            <Text>Swap&nbsp;Posts</Text>
          </div>
        )}
      </UnstyledButton>
    </CalendarPostPopover>
  )
}
