import React from 'react'

import styles from './ChannelsCounterBar.module.css'

function ChannelsCounterBar({
  connected,
  limit,
}: {
  connected: number
  limit: number
}): JSX.Element {
  const isOverflow = connected > limit
  const displayAsBar = limit > 5

  if (displayAsBar) {
    const filledPercentage = isOverflow ? 100 : (connected / limit) * 100
    return (
      // We already show the number of channels elsewhere, so we don't
      // need this to be visible to screen readers
      <div className={styles.barContainer} aria-hidden="true">
        <div
          className={styles.barFill}
          style={{ width: `${filledPercentage}%` }}
        />
      </div>
    )
  }

  return (
    <div className={styles.pillContainer} aria-hidden="true">
      {Array.from({ length: limit }).map((_, index) => (
        <div
          key={index}
          className={
            index < connected || isOverflow ? styles.greenPill : styles.greyPill
          }
        />
      ))}
    </div>
  )
}
export default ChannelsCounterBar
