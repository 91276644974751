import React, { useCallback, useMemo, useState } from 'react'

import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

import { MoveToCustomTimeAlertDialog } from '../MoveToCustomTimeAlertDialog'

export function useMoveToCustomTimeAlertDialog(): [
  (props: {
    fromDate: string
    toDate: string
  }) => Promise<'confirmed' | 'cancelled'>,
  JSX.Element | null,
] {
  const [isOpen, setIsOpen] = useState(false)
  const [dialogProps, setDialogProps] = useState<{
    fromDate: string
    toDate: string
    onConfirm: () => void
    onCancel: () => void
  } | null>(null)

  const { isActive } = useDismissableBanner('move-to-custom-time-alert-dialog')

  const showDialog = useCallback(
    ({
      fromDate,
      toDate,
    }: {
      fromDate: string
      toDate: string
    }): Promise<'confirmed' | 'cancelled'> => {
      if (!isActive) return Promise.resolve('confirmed')
      return new Promise<'confirmed' | 'cancelled'>((resolve) => {
        setDialogProps({
          fromDate,
          toDate,
          onConfirm: () => {
            setIsOpen(false)
            resolve('confirmed')
          },
          onCancel: () => {
            setIsOpen(false)
            resolve('cancelled')
          },
        })
        setIsOpen(true)
      })
    },
    [isActive],
  )

  const dialog = useMemo(() => {
    if (!dialogProps || !isActive) return null
    return (
      <MoveToCustomTimeAlertDialog
        fromDate={dialogProps.fromDate}
        toDate={dialogProps.toDate}
        open={isOpen}
        onOpenChange={setIsOpen}
        onConfirm={dialogProps.onConfirm}
        onCancel={dialogProps.onCancel}
      />
    )
  }, [dialogProps, isOpen, isActive])

  return [showDialog, dialog] as const
}
