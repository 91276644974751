import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { BufferLoading } from '~publish/legacy/shared-components'

import formatPostFromQuery from './formatPostFromQuery'
import { usePostComposer } from '~publish/hooks/usePostComposer'

const StyledLoader = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  height: inherit;
  align-items: center;
`

interface ComposerPageProps {
  initComposer: (params: {
    shouldResetComposerData: boolean
    post: any
  }) => void
  queryParams?: string
  isLoading: boolean
}

const ComposePage = ({
  initComposer,
  queryParams = '',
  isLoading,
}: ComposerPageProps): JSX.Element => {
  const [composerWasOpened, setComposerWasOpened] = useState<boolean>(false)
  const { isOpen } = usePostComposer()

  useEffect(() => {
    if (isLoading) return
    const post = formatPostFromQuery(queryParams)

    initComposer({
      shouldResetComposerData: true,
      post,
    })
  }, [isLoading])

  useEffect(() => {
    if (isOpen) {
      setComposerWasOpened(true)
    }

    if (!isOpen && composerWasOpened) {
      setComposerWasOpened(false)
      window.close()
    }
  }, [isOpen, composerWasOpened])

  return (
    <>
      {isLoading && (
        <StyledLoader>
          <BufferLoading size={64} />
        </StyledLoader>
      )}
    </>
  )
}

export default ComposePage
