import styled from 'styled-components'
import { grayDark, grayDarker, grayLighter } from '@bufferapp/ui/style/colors'

export const HashtagText = styled.div<{
  hover?: boolean
}>`
  display: inline-block;
  height: 21px;
  width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 14px;
  line-height: 21px;
  color: ${(props): string => (props.hover ? grayDarker : grayDark)};
  transition: color 0.3s;
`

export const NameWrapper = styled.span`
  padding-right: 8px;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Card = styled.div<{ hover?: boolean }>`
  padding: 8px 32px;
  display: flex;
  align-items: center;
  transition: background-color 150ms ease-out;
  background-color: ${({ hover }): string =>
    hover ? grayLighter : 'transparent'};
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  align-items: baseline;
`

export const TooltipWrapper = styled.div`
  flex: 1;
  overflow: hidden;

  div[data-reach-tooltip-trigger] {
    display: flex;
    flex-direction: column;
  }
`
