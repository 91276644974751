import React from 'react'
import { Button, Dialog, Flex, Notice } from '@buffer-mono/popcorn'
import useActions from '../hooks/useActions'
import useChannels from '../../../hooks/useChannels'
import usePlanInformation from '../hooks/usePlanInformation'
import useTracking from '../hooks/useTracking'
import { useUser } from '../../../../../common/context/User'

import styles from './ConnectChannelUpgradeActions.module.css'

export const ConnectChannelUpgradeActions = (): JSX.Element => {
  const { cta, upgradePathName } = useTracking()
  const {
    isPaidRevenueCat,
    gatewayPlatformDeviceName,
    gatewayPlatformStoreName,
  } = usePlanInformation()
  const { lockedChannelIds } = useChannels()
  const account = useUser()
  const {
    handleSkipUpgrading,
    handleClick,
    isProcessing,
    actionError,
    actionLabel,
  } = useActions({ cta, upgradePathName })
  const lockedChannelsCopy =
    lockedChannelIds.length > 1 ? 'these channels' : 'this channel'

  const isPayingWithBank =
    account.currentOrganization?.billing?.paymentDetails?.paymentMethod ===
      'bank' || false

  if (isPaidRevenueCat) {
    return (
      <>
        <Notice variant="info">
          Your paid Buffer plan is managed in the {gatewayPlatformStoreName}. To
          make changes to your plan, please visit the Buffer{' '}
          {gatewayPlatformDeviceName} app.
        </Notice>

        <Button
          className="btn_padded"
          id="ack_renevuecat"
          variant="primary"
          onClick={handleSkipUpgrading}
          size="large"
        >
          I understand
        </Button>
      </>
    )
  }

  if (isPayingWithBank) {
    return (
      <>
        <Notice variant="info">
          To make changes to your plan, please contact support at{' '}
          <a href="mailto:hello@buffer.com">hello@buffer.com</a>.
        </Notice>

        <Button
          className="btn_padded"
          id="ack_renevuecat"
          variant="primary"
          onClick={handleSkipUpgrading}
          size="large"
        >
          I understand
        </Button>
      </>
    )
  }

  return (
    <Flex fullWidth direction="column" gap="md">
      <Dialog.Separator />
      {actionError && <Notice variant="error">{actionError.message}</Notice>}
      <Flex gap="sm" fullWidth justify="end" className={styles.footerContent}>
        <Flex gap="sm">
          <Button
            size="large"
            variant="tertiary"
            color="subtle"
            onClick={handleSkipUpgrading}
          >
            Keep {lockedChannelsCopy} locked
          </Button>
          <Button
            variant="primary"
            onClick={handleClick}
            size="large"
            loading={isProcessing}
          >
            {actionLabel}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
