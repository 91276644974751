import React from 'react'
import styles from './index.module.css'

import type { PostCard_PostFragment } from '~publish/gql/graphql'
import GridListPost from './GridListPost'

interface GridListProps {
  gridPosts: PostCard_PostFragment[]
  timezone?: string
  hasWriteAccess?: boolean
  showExtraContent?: boolean
  onSavePostUrl: (post: PostCard_PostFragment, link: string) => void
}

const GridList = ({
  gridPosts,
  timezone = '',
  hasWriteAccess = false,
  showExtraContent = false,
  onSavePostUrl,
}: GridListProps): JSX.Element => {
  return (
    <div className={styles.gridContainer}>
      {gridPosts.map((post) => {
        return (
          <GridListPost
            key={`gridListPost-${post.id}`}
            post={post}
            timezone={timezone}
            showExtraContent={showExtraContent}
            onSavePostUrl={onSavePostUrl}
            hasWriteAccess={hasWriteAccess}
          />
        )
      })}
    </div>
  )
}

export default GridList
