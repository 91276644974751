import * as React from 'react'

const StreakIcon = React.forwardRef(
  (
    {
      size,
      stroke,
      ...props
    }: Omit<React.SVGAttributes<SVGElement>, 'children' | 'stroke'> & {
      size?: number
      stroke?: number
    },
    ref: React.ForwardedRef<SVGSVGElement>,
  ) => {
    const iconName = 'streak'

    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={stroke ?? 2}
        width={size ?? 24}
        height={size ?? 24}
        ref={ref}
        {...props}
      >
        <g id="Icon">
          <path
            id="Vector"
            d="M5.84334 14.5882H18.8204M17.5227 14.5882C17.5227 14.5882 17.8471 17.1824 17.1983 18.6647C16.5494 20.1471 16.0883 21.2878 15.8449 21.5658C15.6016 21.8438 15.2715 22 14.9273 22H9.73646C9.39229 22 9.06221 21.8438 8.81884 21.5658C8.57548 21.2878 7.7899 19.4059 7.46547 18.6647C7.14104 17.9235 7.14105 14.5882 7.14105 14.5882M13.5414 8.64338C13.492 6.84228 13.9048 5.06572 14.7255 3.54779C15.6943 2.2739 17.0937 1.12739 20 1C19.8924 3.92996 19.2465 5.58603 18.1701 6.85993C17.0937 7.87904 15.5867 8.51599 13.5414 8.64338ZM13.5414 8.64338C12.2958 12.4115 12.2514 11.3958 12.2498 13.1481M12.2498 13.1481C12.2497 13.2125 12.2497 13.6 12.2497 13.6L12.2498 13.1481ZM9.40421 5.2C10.5883 6.21912 11.3418 8.00257 11.88 9.91342C9.72714 10.423 8.49613 10.2917 7.09677 9.4C5.80506 8.63566 5.03226 7.3 4 4.675C7.01399 4.03805 8.22014 4.18088 9.40421 5.2Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    )
  },
)

StreakIcon.displayName = 'StreakIcon'

export default StreakIcon
