import React from 'react'
import { Button, SuccessDialog } from '@buffer-mono/popcorn'

import { Link } from 'react-router-dom'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

import announcementIllustration from './announcementIllustration.svg'

export const FEEDS_ANNOUNCEMENT_DIALOG_BANNER_ID =
  'feeds-launch-announcement-dialog'

export const FeedsAnnouncementDialog = (): JSX.Element => {
  const { isActive, dismiss } = useDismissableBanner(
    FEEDS_ANNOUNCEMENT_DIALOG_BANNER_ID,
    {
      trackDismiss: true,
    },
  )

  if (!isActive) {
    return <></>
  }

  return (
    <SuccessDialog
      open={isActive}
      onOpenChange={(open): void => {
        if (!open) {
          dismiss()
        }
      }}
    >
      <SuccessDialog.Content>
        <SuccessDialog.Image alt="" src={announcementIllustration} />
        <SuccessDialog.Title>
          Discover Fresh Content With Feeds
        </SuccessDialog.Title>
        <SuccessDialog.Description>
          Subscribe to content from your favorite blogs, websites, or creators
          and get new content inspiration every day.
        </SuccessDialog.Description>
        <SuccessDialog.Actions>
          <Button
            size="large"
            variant="primary"
            as={Link}
            to="/create"
            onClick={dismiss}
          >
            Get Started
          </Button>
        </SuccessDialog.Actions>
      </SuccessDialog.Content>
    </SuccessDialog>
  )
}
