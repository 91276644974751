/**
 * Timezone Filter Scoring System
 * -----------------------------
 * The scoring system is designed to rank timezone search results based on the
 * NN Group research findings (https://www.nngroup.com/articles/time-zone-selectors/)
 * about how users typically search for timezones, with adaptations for the RawTimeZone
 * interface structure. The system assigns higher scores to matches that align with
 * common user search patterns, prioritizing location names and cities.
 *
 * Base Field Scores (divided by 1000 in final calculation):
 * - Location part of timezone name: 6000 points (Highest priority - e.g., "Dawson_Creek" from "America/Dawson_Creek")
 * - Group location names: 5500 points (Second priority - location parts from group array names)
 * - Cities: 5000 points (Third priority - individual cities are checked separately)
 * - Country: 4500 points (Fourth priority - country name)
 * - Region/Continent: 4100 points (Fifth priority - continent name)
 * - Alternative Name: 3000 points (Sixth priority - timezone alternative names)
 * - Abbreviation: 1000 points (Seventh priority - e.g., "MST")
 * - Long Generic: 500 points (Eighth priority)
 * - Short Generic: 250 points (Lowest priority)
 *
 * Exact Start Bonuses (divided by 1000 in final calculation):
 * - Location part of timezone name: +2500 points
 * - Group location names: +2200 points
 * - Cities: +2000 points
 * - Country: +1800 points
 * - Region/Continent: +1500 points
 * - Alternative Name: +1000 points
 * - Abbreviation: +500 points
 * - Long Generic: +250 points
 * - Short Generic: +100 points
 *
 * Special Cases:
 * - Offset Queries (e.g., GMT+1, UTC-7): Receives 1000 points for exact offset matches
 * - Cities starting with "St.": Checks both "St" and "Saint" variations
 * - Each main city is checked individually rather than as a combined string
 * - Each group name's location part is checked individually
 *
 * Command Score Multipliers (from command-score.ts):
 * - Continuous Match: 1.0 * 0.1 (best case - adjacent matching characters)
 * - Space Word Jump: 0.9 * 0.1 (matching after space)
 * - Non-Space Word Jump: 0.8 * 0.1 (matching after /, _, +, etc.)
 * - Character Jump: 0.17 * 0.1 (any other match)
 * - Transposition: 0.1 * 0.1 (swapped characters)
 *
 * Additional Penalties:
 * - Skipped Characters: 0.999^ per character skipped
 * - Case Mismatch: 0.9999^ per mismatch
 * - Incomplete Match: 0.99 (when search is prefix of result)
 *
 * String Normalization:
 * - Convert to lowercase
 * - Remove diacritical marks
 * - Trim whitespace
 * - Replace underscores with spaces in timezone names
 * - Normalize spaces/hyphens in command scoring
 *
 * Example Scoring Scenarios:
 * 1. Exact location name match "dawson creek" for "America/Dawson_Creek":
 *    Base: 6000/1000 + Exact Start: 2500/1000 + Command Score: 0.1
 *    Total ≈ 8.6
 *
 * 2. Exact city match "london" for a timezone with London in mainCities:
 *    Base: 5000/1000 + Exact Start: 2000/1000 + Command Score: 0.1
 *    Total ≈ 7.1
 *
 * 3. GMT offset search "GMT-7":
 *    Exact offset match = 1000 points
 *
 * 4. Partial country match "cana" for "Canada":
 *    Base: 4500/1000 + Exact Start: 1800/1000 + Command Score: ~0.08
 *    Total ≈ 6.38
 *
 * The final score for a timezone is the highest score among all matching field scores,
 * with higher scores indicating better matches. A score of 0 indicates
 * no match was found. The scoring system ensures that location-specific matches
 * are preferred over more generic matches (like abbreviations), while maintaining
 * the priority order based on user search patterns and timezone structure.
 */
import type { RawTimeZone } from '@vvo/tzdb'

import { memoize } from '~publish/helpers/memoize'
import { commandScore } from './command-score'

export type TimezoneMap = {
  [id: string]: RawTimeZone
}

export async function getOrFetchTimezonesMap(): Promise<TimezoneMap> {
  const { getTimeZones } = await import('@vvo/tzdb')
  const timezonesArray = getTimeZones()

  // Convert the array to a map with timezone names as keys
  const timezoneMap: TimezoneMap = {}
  timezonesArray.forEach((timezone) => {
    timezoneMap[timezone.name] = timezone
  })

  return timezoneMap
}

export const getTimezonesMap = memoize(getOrFetchTimezonesMap)

/**
 * Normalizes strings for consistent comparison by:
 * - Converting to lowercase
 * - Removing diacritical marks
 * - Trimming whitespace
 */
const normalize = (str: string): string => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
}

/**
 * Checks if the search query matches GMT/UTC offset pattern
 * Valid formats: GMT+1, UTC-7, +1, -7, etc.
 */
const isOffsetQuery = (query: string): boolean => {
  return /^(gmt|utc)?[+-]?\d{1,2}(:?\d{2})?$/i.test(query)
}

/**
 * Normalizes offset string for comparison by converting to HHMM format
 * Examples:
 * - "UTC-8" -> "-0800"
 * - "GMT+9:30" -> "+0930"
 * - "-7" -> "-0700"
 * - "+5:45" -> "+0545"
 */

/**
 * Normalizes offset string for comparison by converting to HHMM format
 */
const normalizeOffset = (offset: string): string => {
  // Remove all non-numeric characters except +/-
  const stripped = offset.replace(/[^0-9+-]/g, '')

  // Extract the sign and number
  const match = stripped.match(/([+-])?(\d+)/)
  if (!match) return ''

  const [, sign = '+', num] = match

  // Convert to hours and minutes
  if (num.length <= 2) {
    // Simple hour offset (e.g., "-7" or "+5")
    return `${sign}${num.padStart(2, '0')}00`
  } else if (num.length === 3) {
    // Handle cases like "+545" for 5:45
    return `${sign}0${num}`
  } else if (num.length === 4) {
    // Already in HHMM format
    return `${sign}${num}`
  }

  return ''
}

const SCORE_CONFIG = {
  nameSecondPart: {
    base: 6000, // Highest priority - the second part of the timezone name
    exactStart: 2500,
  },
  groupName: {
    base: 5500, // Second priority - names in the group array
    exactStart: 2200,
  },
  city: {
    base: 5000, // Third priority - main cities
    exactStart: 2000,
  },
  country: {
    base: 4500, // Fourth priority - country name
    exactStart: 1800,
  },
  region: {
    base: 4100, // Fifth priority - continent/region name
    exactStart: 1500,
  },
  longName: {
    base: 3000, // Sixth priority - alternative name
    exactStart: 1000,
  },
  shortName: {
    base: 1000, // Seventh priority - abbreviation
    exactStart: 500,
  },
  longGeneric: {
    base: 500, // Eighth priority
    exactStart: 250,
  },
  shortGeneric: {
    base: 250, // Lowest priority
    exactStart: 100,
  },
}

/**
 * Calculate field score with fuzzy matching
 */
const calculateFieldScore = (
  fieldValue: string,
  normalizedSearch: string,
  baseScore: number,
  exactStartBonus: number,
): number => {
  const cmdScore = commandScore(fieldValue, normalizedSearch, [])

  if (cmdScore === 0) return 0

  // Make base scores dominate over command scores by multiplying cmdScore
  const matchQuality = cmdScore * 0.1 // Reduce impact of command score
  const bonus = fieldValue.startsWith(normalizedSearch) ? exactStartBonus : 0
  return matchQuality + (baseScore + bonus) / 1000
}

/**
 * Enhanced filter function for Command Menu (cmdk) that ranks timezone search results
 * based on NN Group research findings and includes fuzzy matching for typos and
 * character transpositions. Implements the cmdk filter function signature while
 * maintaining the timezone-specific scoring system.
 *
 * The function prioritizes matches in the following order:
 * 1. Location part of timezone name (e.g., "Dawson_Creek" from "America/Dawson_Creek")
 * 2. Location parts from timezone group names
 * 3. Individual cities from the mainCities array
 * 4. Country name
 * 5. Continent/region name
 * 6. Alternative timezone name
 * 7. Timezone abbreviation
 * 8. Generic names
 *
 * Each field is scored independently and the highest score is returned.
 *
 * @param TIMEZONES - Map of timezone objects indexed by timezone name
 * @param value - The value of the command item (timezone ID)
 * @param search - The search query from the command input
 * @returns A number indicating the rank/relevance (0 means no match)
 */
export const filterTimezones = (
  TIMEZONES: TimezoneMap,
  value: string,
  search: string,
): number => {
  const timezone = TIMEZONES[value]
  if (!timezone) return 0

  const normalizedSearch = normalize(search)
  if (!normalizedSearch) return 1

  // Handle offset searches (e.g., GMT+1, UTC-7)
  if (isOffsetQuery(normalizedSearch)) {
    const searchOffset = normalizeOffset(normalizedSearch)
    const tzOffset = normalizeOffset(timezone.rawFormat.split(' ')[0])

    return searchOffset === tzOffset ? 1000 : 0
  }

  let score = 0

  // Extract the second part of the timezone name (e.g., "Dawson_Creek" from "America/Dawson_Creek")
  const nameParts = timezone.name.split('/')
  const nameSecondPart =
    nameParts.length > 1 ? nameParts[1].replace(/_/g, ' ') : ''

  // Name second part scoring (highest priority)
  if (nameSecondPart) {
    const normalizedNameSecondPart = normalize(nameSecondPart)
    const nameSecondPartScore = calculateFieldScore(
      normalizedNameSecondPart,
      normalizedSearch,
      SCORE_CONFIG.nameSecondPart.base,
      SCORE_CONFIG.nameSecondPart.exactStart,
    )
    score = nameSecondPartScore
  }

  // Group names scoring (second priority)
  const groupScores = timezone.group.map((groupName) => {
    const groupNameParts = groupName.split('/')
    const groupSecondPart =
      groupNameParts.length > 1 ? groupNameParts[1].replace(/_/g, ' ') : ''
    if (!groupSecondPart) return 0

    return calculateFieldScore(
      normalize(groupSecondPart),
      normalizedSearch,
      SCORE_CONFIG.groupName.base,
      SCORE_CONFIG.groupName.exactStart,
    )
  })

  const maxGroupScore = Math.max(0, ...groupScores)
  if (maxGroupScore > score) score = maxGroupScore

  // City match scoring - check each main city individually (third priority)
  const cityScores = timezone.mainCities.map((city) => {
    const normalizedCity = normalize(city)

    if (normalizedCity.startsWith('st ')) {
      const stScore = calculateFieldScore(
        normalizedCity,
        normalizedSearch,
        SCORE_CONFIG.city.base,
        SCORE_CONFIG.city.exactStart,
      )
      const saintScore = calculateFieldScore(
        `saint ${normalizedCity.slice(3)}`,
        normalizedSearch,
        SCORE_CONFIG.city.base,
        SCORE_CONFIG.city.exactStart,
      )
      return Math.max(stScore, saintScore)
    } else {
      return calculateFieldScore(
        normalizedCity,
        normalizedSearch,
        SCORE_CONFIG.city.base,
        SCORE_CONFIG.city.exactStart,
      )
    }
  })

  const maxCityScore = Math.max(0, ...cityScores)
  if (maxCityScore > score) score = maxCityScore

  // Country match scoring (fourth priority)
  const countryScore = calculateFieldScore(
    normalize(timezone.countryName),
    normalizedSearch,
    SCORE_CONFIG.country.base,
    SCORE_CONFIG.country.exactStart,
  )
  if (countryScore > score) score = countryScore

  // Region/Continent match scoring (fifth priority)
  const regionScore = calculateFieldScore(
    normalize(timezone.continentName),
    normalizedSearch,
    SCORE_CONFIG.region.base,
    SCORE_CONFIG.region.exactStart,
  )
  if (regionScore > score) score = regionScore

  // Alternative name match scoring (sixth priority)
  const longNameScore = calculateFieldScore(
    normalize(timezone.alternativeName),
    normalizedSearch,
    SCORE_CONFIG.longName.base,
    SCORE_CONFIG.longName.exactStart,
  )
  if (longNameScore > score) score = longNameScore

  // Abbreviation match scoring (seventh priority)
  const shortNameScore = calculateFieldScore(
    normalize(timezone.abbreviation),
    normalizedSearch,
    SCORE_CONFIG.shortName.base,
    SCORE_CONFIG.shortName.exactStart,
  )
  if (shortNameScore > score) score = shortNameScore

  // Long generic match scoring (eighth priority)
  const longGenericScore = calculateFieldScore(
    normalize(timezone.alternativeName),
    normalizedSearch,
    SCORE_CONFIG.longGeneric.base,
    SCORE_CONFIG.longGeneric.exactStart,
  )
  if (longGenericScore > score) score = longGenericScore

  // Short generic match scoring (lowest priority)
  const shortGenericScore = calculateFieldScore(
    normalize(timezone.abbreviation),
    normalizedSearch,
    SCORE_CONFIG.shortGeneric.base,
    SCORE_CONFIG.shortGeneric.exactStart,
  )
  if (shortGenericScore > score) score = shortGenericScore

  return score
}
