import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { GET_ACCOUNT } from '~publish/legacy/accountContext'
import { toast } from '@buffer-mono/popcorn'

export const EnableBetaFeaturesMutation = graphql(/* GraphQL */ `
  mutation addOrganizationToBetaProgram($organizationId: String!) {
    addOrganizationToBetaProgram(organizationId: $organizationId) {
      ... on BasicError {
        userFriendlyMessage
        cause
      }
      ... on MutationBasicResponse {
        success
      }
    }
  }
`)

export const DisableBetaFeaturesMutation = graphql(/* GraphQL */ `
  mutation removeOrganizationFromBetaProgram($organizationId: String!) {
    removeOrganizationFromBetaProgram(organizationId: $organizationId) {
      ... on BasicError {
        userFriendlyMessage
        cause
      }
      ... on MutationBasicResponse {
        success
      }
    }
  }
`)

type UpdateBetaFeaturesEnrolment = ({
  organizationId,
}: {
  organizationId: string
}) => Promise<boolean>

type UpdateBetaFeaturesEnrolmentResponse = {
  enableBetaFeatures: UpdateBetaFeaturesEnrolment
  disableBetaFeatures: UpdateBetaFeaturesEnrolment
  isLoading: boolean
  hasError: boolean
}

/**
 * Custom hook to update the current organization name.
 * @returns {UpdateBetaFeaturesEnrolmentResponse} The function to trigger updating the organization name.
 */
export const useUpdateBetaFeaturesEnrolment =
  (): UpdateBetaFeaturesEnrolmentResponse => {
    const [executeEnableBetaFeatures, { loading, error }] = useMutation(
      EnableBetaFeaturesMutation,
    )
    const [
      executeDisableBetaFeatures,
      { loading: disableLoading, error: disableError },
    ] = useMutation(DisableBetaFeaturesMutation)

    const enableBetaFeatures = useCallback(
      async ({ organizationId }: { organizationId: string }) => {
        try {
          const { data, errors } = await executeEnableBetaFeatures({
            variables: {
              organizationId,
            },
            refetchQueries: [GET_ACCOUNT],
          })

          // Handle GraphQL errors if any
          if (errors?.length) {
            const unexpectedError = new Error(
              errors?.[0]?.message || 'Unexpected API response',
            )
            logError(unexpectedError, { metaData: { data, errors } })
            throw unexpectedError
          }
          toast.success('Organization added to beta program')

          return Boolean(data?.addOrganizationToBetaProgram)
        } catch (error) {
          toast.error(
            'An error occurred while adding your organization to the beta program. Please try again.',
          )
          logError(error as Error, {
            metaData: { variables: { organizationId } },
          })
          throw error
        }
      },
      [executeEnableBetaFeatures],
    )

    const disableBetaFeatures = useCallback(
      async ({ organizationId }: { organizationId: string }) => {
        try {
          const { data, errors } = await executeDisableBetaFeatures({
            variables: {
              organizationId,
            },
            refetchQueries: [GET_ACCOUNT],
          })

          // Handle GraphQL errors if any
          if (errors?.length) {
            const unexpectedError = new Error(
              errors?.[0]?.message || 'Unexpected API response',
            )
            logError(unexpectedError, { metaData: { data, errors } })
            throw unexpectedError
          }
          toast.success('Organization removed from beta program')

          return Boolean(data?.removeOrganizationFromBetaProgram)
        } catch (error) {
          toast.error(
            'An error occurred while removing your organization from the beta program. Please try again.',
          )
          logError(error as Error, {
            metaData: { variables: { organizationId } },
          })
          throw error
        }
      },
      [executeDisableBetaFeatures],
    )

    return {
      enableBetaFeatures,
      disableBetaFeatures,
      isLoading: loading || disableLoading,
      hasError: !!error || !!disableError,
    }
  }
