import React from 'react'
import {
  Button,
  CriticalIcon,
  EmptyState,
  Flex,
  Heading,
  Notice,
  PlusIcon,
  Sidebar,
  Skeleton,
  Text,
} from '@buffer-mono/popcorn'
import partition from 'lodash/partition'
import { useChannelsForSettings } from './hooks/useChannelsForSettings'
import { useAccount, useOrganizationId } from '~publish/legacy/accountContext'
import { isFreeUser, isTrialUser } from '~publish/helpers/user'
import type { Account } from '~publish/legacy/accountContext/types'

import isOnNonAgencyTieredPricing from './util/isOnNonAgencyTieredPricing'
import {
  isPaymentMethodBank,
  isRevenueCatBillingGateway,
} from './util/planUtils'

import SettingsPageLayout from './components/SettingsPageLayout'
import ChannelRow from './components/ChannelRow'
import usePrivilege from './hooks/usePrivilege'

import style from './Settings.module.css'
import ChannelsPricingNotice from './components/ChannelsPricingNotice'
import ChannelsCounterBar from './components/ChannelsCounterBar'

const openTieredPricingModal = (): void => {
  const { MODALS, actions } = window?.appshell || {}
  actions.openModal(MODALS.quantityUpdateTieredPricing, {
    cta: 'channels-title-addRemoveChannels-1',
    upgradePathName: 'accountChannels-upgrade',
  })
}

const openSubscriptionUpdate = (): void => {
  const { MODALS, actions } = window?.appshell || {}
  actions.openModal(MODALS.subscriptionUpdate, {
    cta: 'channels-title-addOrRemoveChannels-1',
    upgradePathName: 'accountChannels-upgrade',
    shouldPickModalOnOrganizationState: true,
  })
}

export function shouldShowAddRemoveChannels(account: Account): boolean {
  // 1. Legacy or trial users should not see the button
  const isOneBufferOrganization =
    account?.currentOrganization?.isOneBufferOrganization
  const isOnTrial = isTrialUser(account)
  if (!isOneBufferOrganization || isOnTrial) {
    return false
  }

  // 2. Free, mobile, or bank payment users should not see the button
  const isFree = isFreeUser(account)
  const isMobileBilling = isRevenueCatBillingGateway(account)
  const isBankPayment = isPaymentMethodBank(account)
  if (isFree || isMobileBilling || isBankPayment) {
    return false
  }

  return true
}

const AddOrRemoveChannelsButton = (): JSX.Element | null => {
  const { account } = useAccount()
  const shouldShow = shouldShowAddRemoveChannels(account)
  const canManageChannels = usePrivilege('canManageChannels')
  const isOnTieredPricing = isOnNonAgencyTieredPricing(account)

  if (!shouldShow) {
    return null
  }

  return (
    <Button
      size="medium"
      variant="secondary"
      disabled={!canManageChannels}
      onClick={
        isOnTieredPricing ? openTieredPricingModal : openSubscriptionUpdate
      }
    >
      Add or Remove Channels
    </Button>
  )
}

const openChannelStoreFront = (): void => {
  const { actions } = window?.appshell || {}
  actions.openChannelStorefront({
    cta: 'account-channels-pageHeader-connectChannel-1',
  })
}

const ConnectChannelButton = ({
  context,
}: {
  context: 'main' | 'emptyState'
}): JSX.Element => {
  const canConnectChannel = usePrivilege('canEdit')
  return (
    <Button
      size="medium"
      variant="primary"
      disabled={!canConnectChannel}
      onClick={openChannelStoreFront}
      data-testid={`connect-channel-button-${context}`}
    >
      Connect Channel
    </Button>
  )
}

const ContactAdminNotice = (): JSX.Element | null => {
  const canManageChannels = usePrivilege('canManageChannels')

  if (canManageChannels) {
    return null
  }

  return (
    <Notice>Contact an Admin in your organization to manage channels.</Notice>
  )
}

export const Channels = ({
  isMobile,
  handleToggleSidebar,
}: {
  isMobile: boolean
  handleToggleSidebar: () => void
}): JSX.Element => {
  const { account } = useAccount()
  const organizationId = useOrganizationId()

  const {
    channels,
    loading: loadingChannels,
    error: errorLoadingChannels,
  } = useChannelsForSettings({
    organizationId,
  })

  const [lockedChannels, connectedChannels] = partition(
    channels,
    (c) => c.isLocked,
  )

  const channelsLimit = account?.currentOrganization?.limits.channels
  const channelsConnected = channels?.length ?? 0
  const channelsConnectedOrMax = Math.min(channelsConnected, channelsLimit ?? 0)

  const lockedChannelsHeader = React.useRef<HTMLHeadingElement>(null)
  const [lockedChannelsVisible, setLockedChannelsVisible] =
    React.useState(false)

  // track if lockedChannelsHeader is in view
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLockedChannelsVisible(true)
          } else {
            setLockedChannelsVisible(false)
          }
        })
      },
      { threshold: 0.85 },
    )
    if (lockedChannelsHeader.current)
      observer.observe(lockedChannelsHeader.current)
    return () => {
      observer.disconnect()
    }
  }, [lockedChannelsHeader])

  // Skeleton loading view
  if (!account || loadingChannels) {
    return (
      <SettingsPageLayout>
        <Flex gap="xl" direction="column">
          <Heading as="h1" size="large">
            Channels
          </Heading>
          <Skeleton>
            <Heading as="h2" size="medium">
              Connected Channels
            </Heading>
          </Skeleton>
          <ul className={style.channelList}>
            {[0, 1, 2].map((index) => (
              <li key={index}>
                <Skeleton width="100%" height="74px" />
              </li>
            ))}
          </ul>
        </Flex>
      </SettingsPageLayout>
    )
  }

  if (errorLoadingChannels) {
    return (
      <SettingsPageLayout>
        <Flex gap="xl" direction="column">
          <Heading as="h1" size="large">
            Channels
          </Heading>
        </Flex>
        <EmptyState variant="critical">
          <EmptyState.Icon>
            <CriticalIcon />
          </EmptyState.Icon>
          <EmptyState.Heading>Failed to load channels</EmptyState.Heading>
          <EmptyState.Description>
            Something went wrong. Please try again later.
          </EmptyState.Description>
        </EmptyState>
      </SettingsPageLayout>
    )
  }

  return (
    <>
      <SettingsPageLayout>
        <section>
          <Flex gap="xl" direction="column">
            <Flex
              gap="sm"
              direction={isMobile ? 'column' : 'row'}
              align={isMobile ? 'start' : 'center'}
              justify="between"
              className={style.fullWidth}
            >
              <Heading as="h1" size="large">
                <Flex align="center">
                  {isMobile && (
                    <Sidebar.Trigger onClick={handleToggleSidebar} />
                  )}
                  Channels
                </Flex>
              </Heading>

              <Flex gap="xs" direction="row" align="center">
                <AddOrRemoveChannelsButton />
                <ConnectChannelButton context="main" />
              </Flex>
            </Flex>

            {/* Banner with current plan and limit */}
            <ChannelsPricingNotice
              numLockedChannels={lockedChannels.length}
              lockedChannelsVisible={lockedChannelsVisible}
            />

            <ContactAdminNotice />

            {channels && channels.length > 0 ? (
              <>
                <Flex gap="sm" direction="column" fullWidth as="section">
                  <Flex
                    direction="row"
                    align="center"
                    justify="between"
                    fullWidth
                  >
                    <Text as="h2" size="lg" weight="medium">
                      {channelsConnectedOrMax}/{channelsLimit} Channels
                      connected
                    </Text>
                    <ChannelsCounterBar
                      connected={channelsConnectedOrMax}
                      limit={channelsLimit}
                    />
                  </Flex>
                  <ul className={style.channelList}>
                    {connectedChannels.map((channel) => (
                      <li key={channel.id}>
                        <ChannelRow channel={channel} />
                      </li>
                    ))}
                  </ul>
                </Flex>
                {lockedChannels.length > 0 && (
                  <Flex gap="sm" direction="column" fullWidth as="section">
                    <Text
                      as="h2"
                      size="lg"
                      weight="medium"
                      id="locked-channels"
                      ref={lockedChannelsHeader}
                    >
                      Locked Channels
                    </Text>
                    <ul className={style.channelList}>
                      {lockedChannels.map((channel) => (
                        <li key={channel.id}>
                          <ChannelRow channel={channel} />
                        </li>
                      ))}
                    </ul>
                  </Flex>
                )}
                {/* Spacer */}
                <div style={{ marginTop: 'var(--space-2xl)' }}></div>
              </>
            ) : (
              <EmptyState size="medium">
                <EmptyState.Icon>
                  <PlusIcon />
                </EmptyState.Icon>
                <EmptyState.Heading>
                  Connect a channel to get started
                </EmptyState.Heading>
                <EmptyState.Description>
                  Once connected, you&apos;ll see your channels listed here.
                </EmptyState.Description>
                <EmptyState.Actions>
                  <ConnectChannelButton context="emptyState" />
                </EmptyState.Actions>
              </EmptyState>
            )}
          </Flex>
        </section>
      </SettingsPageLayout>
    </>
  )
}

export default Channels
