import React from 'react'
import { AudienceIcon, FlagIcon } from '@buffer-mono/popcorn'

import { ChannelAccountType } from '../../../../../types'

export const ACCOUNT_OPTIONS_FOR_CHANNELS = [
  {
    accountType: ChannelAccountType.page,
    header: 'Page',
    icon: <FlagIcon size="medium" color="subtle" />,
    description: 'A public profile that Facebook users “like”',
  },
  {
    accountType: ChannelAccountType.group,
    header: 'Group',
    icon: <AudienceIcon size="medium" color="subtle" />,
    description: (
      <>
        Share to Groups as a member or an admin. <br />
        Direct publishing to Facebook Groups is no longer supported by Meta.
        Buffer facilitates scheduling your posts with mobile-based
        notifications.{' '}
        <a
          href="https://support.buffer.com/article/570-connecting-facebook-groups-and-troubleshooting-connections"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </>
    ),
  },
]
