import { UploadSource } from '@buffer-mono/uploader'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useIdeasUploader from '~publish/hooks/useIdeasUploader'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { selectAllTags } from '~publish/legacy/campaign/slice'
import { createPage } from '~publish/legacy/routes'
import { useAppSelector } from '~publish/legacy/store'
import type { Tag } from '~publish/pages/Create/types'
import { IdeaComposer, type NewIdea } from '../IdeaComposer'
import { useNewIdeaComposerSplit } from '../IdeaComposer/hooks'
import { useOnce } from '~publish/hooks/useOnce'
import { IdeaComposer as LegacyIdeaComposer } from '../LegacyIdeaComposer/IdeaComposer'
/**
 * Component used to open IdeaComposer with preselected data.
 * Renders when corresponding route is matched.
 */
export function IdeaCreationDialog(): JSX.Element | null {
  const [open, setOpen] = React.useState(true)
  const history = useHistory()
  const location = useLocation<{ returnTo?: Location }>()

  const { isEnabled: isNewIdeaComposerEnabled } = useNewIdeaComposerSplit()

  const [text = undefined] = useQueryParam('text')
  const [title = undefined] = useQueryParam('title')
  const [groupId] = useQueryParam('groupId')

  // We select tags for prefilling from both the underlying page's tag filters
  // and any tags passed in as query params
  // We handle these separately because sharing the same parameter name
  // causes the underlying page's tag filters to be overwritten
  const allTags: Tag[] = useAppSelector(selectAllTags)
  // Tags selected in the underlying page's tag filters
  const [tagFilterTagIds = []] = useQueryParam<string[]>('tagIds')
  const [prefillTagIds = []] = useQueryParam<string[]>('tags')

  // Tags provided as query params specifically to be preselected
  const mergedTagIds = [...new Set([...tagFilterTagIds, ...prefillTagIds])]

  // Map tags down to required properties to omit unnecessary data
  const prefillTags = allTags
    .filter((tag) => mergedTagIds.includes(tag.id))
    .map((tag) => ({
      id: tag.id,
      name: tag.name,
      color: tag.color,
    }))

  const initialIdea: NewIdea = {
    groupId,
    content: {
      title,
      text,
      tags: prefillTags,
      services: [],
    },
  }

  // Preload any media passed in query params
  const [media = []] = useQueryParam<string[]>('media')
  const uploader = useIdeasUploader()

  // Only need to run once - media is only provided in
  // initial query param
  useOnce((): boolean => {
    if (media) {
      uploader.addFilesFromUrls(
        media.map((url) => ({
          remoteUrl: url,
          useResizer: true,
        })),
        UploadSource.extension(),
      )
    }
    return !!media
  }, [media, uploader])

  const handleOpenChange = (open: boolean): void => {
    setOpen(open)
    if (!open) {
      // If the dialog was an internal navigation, return to that location
      // Otherwise, default to the create page
      history.push(location.state?.returnTo ?? createPage.route)
    }
  }

  return isNewIdeaComposerEnabled ? (
    <IdeaComposer
      initialIdea={initialIdea}
      open={open}
      onOpenChange={handleOpenChange}
      uploader={uploader}
    />
  ) : (
    <LegacyIdeaComposer
      initialIdea={initialIdea}
      open={open}
      onOpenChange={handleOpenChange}
      uploader={uploader}
    />
  )
}
