import React from 'react'
import { format } from 'date-fns'

import {
  Button,
  IconButton,
  ChevronLeftIcon,
  ChevronRightIcon,
  Flex,
  Text,
  VisuallyHidden,
  ChevronDownIcon,
  Heading,
  Select,
} from '@buffer-mono/popcorn'

import styles from './CalendarControls.module.css'
import { useCalendar } from '../../../components/Calendar/hooks/useCalendar'
import { useTimezone } from '~publish/hooks/useTimezone'

export function CalendarControls(): JSX.Element {
  const timezone = useTimezone()
  const {
    navigateToToday,
    navigateToPrevious,
    navigateToNext,
    toggleViewMode,
    viewMode,
    selectedDate,
    startDate,
    endDate,
  } = useCalendar({ timezone })

  let dateString = format(
    selectedDate.toPlainDateTime().toString(),
    'MMMM yyyy',
  )
  if (startDate.month !== endDate.month && viewMode === 'week') {
    dateString = `${format(
      startDate.toPlainDateTime().toString(),
      'MMM',
    )}-${format(endDate.toPlainDateTime().toString(), 'MMM')} ${format(
      startDate.toPlainDateTime().toString(),
      'yyyy',
    )}`
  }

  return (
    <Flex gap="md" align="center">
      <Flex align="center">
        <IconButton
          label={viewMode === 'week' ? 'Previous Week' : 'Previous Month'}
          variant="tertiary"
          onClick={navigateToPrevious}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          label={viewMode === 'week' ? 'Next Week' : 'Next Month'}
          variant="tertiary"
          onClick={navigateToNext}
        >
          <ChevronRightIcon />
        </IconButton>
        <Heading as="h2" size="small" className={styles.dateHeader}>
          {dateString}
        </Heading>
      </Flex>
      <Button variant="secondary" onClick={navigateToToday}>
        Today
      </Button>
      <Select
        value={viewMode}
        onValueChange={(mode): void => toggleViewMode(mode as 'week' | 'month')}
      >
        <Select.Trigger>
          <Button variant="tertiary">
            <VisuallyHidden>Filter by</VisuallyHidden>
            {viewMode === 'week' ? 'Week' : 'Month'}
            <ChevronDownIcon />
          </Button>
        </Select.Trigger>
        <Select.Content>
          <Select.Item value={'week'}>
            <Text weight="medium">Week</Text>
          </Select.Item>
          <Select.Item value={'month'}>
            <Text weight="medium">Month</Text>
          </Select.Item>
        </Select.Content>
      </Select>
    </Flex>
  )
}
