import React, { type CSSProperties, type KeyboardEventHandler } from 'react'
import { useDraggable, useDroppable } from '@dnd-kit/core'
import clsx from 'clsx'

import { GripVerticalIcon, IconButton, Slot } from '@buffer-mono/popcorn'

import useDraggingCursor from '~publish/hooks/useDraggingCursor'

import type { CalendarItem } from './types'

import styles from './DraggableItem.module.css'

interface DraggableAndDroppableItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  id: CalendarItem['id']
  index: number
  disabled?: boolean
  children: React.ReactNode
  timestamp: CalendarItem['timestamp']
  type: CalendarItem['type']
  onOverlay?: boolean
}

export const DraggableAndDroppableItem = React.memo(
  ({
    id,
    index,
    disabled = false,
    children,
    style,
    className,
    timestamp,
    type,
    onOverlay,
    ...props
  }: DraggableAndDroppableItemProps): JSX.Element => {
    const {
      attributes,
      listeners,
      setNodeRef: setDraggableNodeRef,
      transform,
      isDragging,
    } = useDraggable({
      id,
      disabled,
      data: {
        index,
        timestamp,
        type,
      },
    })
    const {
      setNodeRef: setDroppableNodeRef,
      isOver,
      active,
    } = useDroppable({
      id,
      disabled,
      data: {
        index,
        timestamp,
        type,
      },
    })
    const { onKeyDown, ...mouseListeners } = listeners ?? {}
    useDraggingCursor(isDragging)

    const isPostBeenDrag = active?.data.current?.type === 'post' && active?.id

    if (isDragging && !onOverlay) {
      return <div className={styles.shadowItem}>{children}</div>
    }

    if (disabled) {
      return <>{children}</>
    }

    return (
      <div
        {...mouseListeners}
        ref={(node): void => {
          setDroppableNodeRef(node)
          setDraggableNodeRef(node)
        }}
        className={clsx(
          styles.item,
          {
            [styles.isDragging]: onOverlay,
          },
          className,
        )}
        style={
          {
            '--translate-x': transform?.x ? `${transform.x}px` : undefined,
            '--translate-y': transform?.y ? `${transform.y}px` : undefined,
            ...style,
          } as CSSProperties
        }
        {...props}
      >
        <IconButton
          variant="tertiary"
          label="Drag to reschedule"
          className={styles.dragHandle}
          {...attributes}
          onKeyDown={onKeyDown as KeyboardEventHandler}
          data-dnd
          size="small"
        >
          <GripVerticalIcon />
        </IconButton>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error - Is a Slot and the prop is being passed down */}
        <Slot.Root isDraggingOver={isOver && isPostBeenDrag}>
          {children}
        </Slot.Root>
      </div>
    )
  },
)

DraggableAndDroppableItem.displayName = 'DraggableItem'
