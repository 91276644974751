import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  parseQueryParams,
  serializeQueryParams,
} from '../../hooks/useQueryParam'
import { ideaEditRoute, ideaNewRoute } from '../../legacy/routes'
import { BufferTrackerReact } from '@buffer-mono/tracking-plan'
import { useOrganizationId } from '~publish/legacy/accountContext'
import type { Location } from 'history'

export type UseIdeaComposerQueryData = {
  source: CTA
  title?: string
  text?: string
  media?: string[]
  tags?: string[]
  groupId?: string
  placeAfterId?: string
  aiAssisted?: 'true' | 'false'
  duplicate?: boolean
}

export type IdeaComposerLocationState = {
  returnTo: Location
  action: 'create' | 'edit' | 'duplicate'
}

export type UseIdeaComposerReturn = {
  createIdeaWithComposer: (input: UseIdeaComposerQueryData) => void
  editIdeaWithComposer: (input: { source: CTA; ideaId: string }) => void
}

export type CreateIdeaWithComposer = (input: UseIdeaComposerQueryData) => void

/**
 * Helper hook used to open IdeaCreationDialog and IdeaEditDialog correctly.
 * It redirects to the correct route, manages the query params,
 * and stores the returnTo location for closing the composer.
 *
 * @example
 * const { editIdeaWithComposer } = useIdeaComposer()
 *
 * // Open IdeaCreationDialog with preselected tag
 * editIdeaWithComposer({
 *   source:'create-board-ideaCard-card-1',
 *   ideaId: 'some-idea-id',
 * })
 *
 * const { createIdeaWithComposer } = useIdeaComposer()
 *
 * // Open IdeaCreationDialog with preselected tag
 * createIdeaWithComposer({
 *   source:'create-gallery-header-newButton-1',
 *   tags: ['some-tag-id'],
 * })
 *
 * // Open IdeaCreationDialog with AI generated text
 * createIdeaWithComposer({
 *   source:'create-gallery-ideaGenerator-newIdea-1'
 *   text: 'AI generated text',
 *   aiAssisted: 'true',
 * })
 *
 * // Open the IdeaCreationDialog with group and placeAfterId
 * createIdeaWithComposer({
 *   source:'create-board-group-newIdea-1'
 *
 *   groupId: 'Done',
 *   placeAfterId: 'some-idea-id',
 * })
 */
export const useIdeaComposer = (): UseIdeaComposerReturn => {
  const history = useHistory<IdeaComposerLocationState>()
  const location = useLocation<IdeaComposerLocationState>()
  const organizationId = useOrganizationId()

  const queryParams = React.useMemo(
    () => parseQueryParams(location.search),
    [location.search],
  )

  const createIdeaWithComposer = React.useCallback<CreateIdeaWithComposer>(
    (input) => {
      const { duplicate, ...inputParams } = input
      const search = serializeQueryParams({
        ...queryParams,
        ...inputParams,
      })

      // If the idea composer route is already contained in the location pathname, remove it
      // This ensures the composer pathname is constructed correctly without duplication
      // and ensures a correct returnTo path
      const sanitizedLocationPathname = location.pathname.replace(
        ideaNewRoute.route,
        '',
      )

      history.push({
        ...location,
        pathname: `${sanitizedLocationPathname}${ideaNewRoute.route}`,
        search,
        state: {
          returnTo: { ...location, pathname: sanitizedLocationPathname },
          action: duplicate ? 'duplicate' : 'create',
        },
      })
      BufferTrackerReact.ideaComposerOpened({
        clientName: 'publishWeb',
        organizationId: organizationId ?? '',
        cta: input.source,
      })
    },
    [history, location, queryParams, organizationId],
  )

  const editIdeaWithComposer = React.useCallback(
    ({ ideaId, source }: { source: CTA; ideaId: string }): void => {
      const search = serializeQueryParams({
        ...queryParams,
        source,
      })

      const pathname = ideaEditRoute.getRoute({
        ideaId,
      })

      // If the idea composer route is already contained in the location pathname, remove it
      // This ensures the composer pathname is constructed correctly without duplication
      // and ensures a correct returnTo path
      const sanitizedLocationPathname = location.pathname.replace(pathname, '')
      history.push({
        ...location,
        pathname: `${sanitizedLocationPathname}${pathname}`,
        search,
        state: {
          returnTo: { ...location, pathname: sanitizedLocationPathname },
          action: 'edit',
        },
      })
      BufferTrackerReact.ideaComposerOpened({
        clientName: 'publishWeb',
        organizationId: organizationId ?? '',
        ideaId,
        cta: source,
      })
    },
    [history, location, queryParams, organizationId],
  )

  return {
    createIdeaWithComposer,
    editIdeaWithComposer,
  }
}
