import { type Account, MODALS } from '../../../../common/types'
import { formatCTAString } from '../../../../common/hooks/useSegmentTracking'
import {
  getLogoutUrl,
  getTeamInviteUrl,
  getNewSettingsUrl,
} from '../../../../common/utils/urls'

import { canHaveTeamMembers } from '../../../../common/utils/user'
import type { ModalData } from '../../../../common/events/types'

type openModalCb = ({ key, data }: { key: string; data?: ModalData }) => void
type TrackCTAClickedArguments = {
  ctaButton: string
  upgradePathName: string
  ctaView: string
  ctaLocation: string
}

type TrackCTAClickedArgumentsCb = (args: TrackCTAClickedArguments) => void

export const handleTeamClick =
  (
    shouldShowTeamMemberUpgradePath: boolean,
    openModal: openModalCb,
    trackCTAClicked: TrackCTAClickedArgumentsCb,
    user: Account,
    navigate: (route: string) => void,
  ) =>
  (): void => {
    if (shouldShowTeamMemberUpgradePath) {
      openModal({
        key: MODALS.planSelector,
        data: {
          cta: 'navBar-dropdown-team-1',
          upgradePathName: 'team-upgrade',
        },
      })
      trackCTAClicked({
        ctaButton: 'team',
        upgradePathName: 'team-upgrade',
        ctaView: 'mainNavMenuDropdown',
        ctaLocation: 'mainNavMenuDropdown',
      })
    } else {
      navigate(getNewSettingsUrl('team'))
    }
  }

export const handleInviteClick =
  (
    user: Account,
    openModal: openModalCb,
    trackCTAClicked: TrackCTAClickedArgumentsCb,
  ) =>
  (): void => {
    if (canHaveTeamMembers(user)) {
      window.location.assign(getTeamInviteUrl(user))
    } else {
      openModal({
        key: MODALS.planSelector,
        data: {
          cta: 'navBar-dropdown-inviteYourTeam-1',
          upgradePathName: 'team-upgrade',
        },
      })
      trackCTAClicked({
        ctaButton: formatCTAString('Invite your team'),
        upgradePathName: 'team-upgrade',
        ctaView: 'mainNavMenuDropdown',
        ctaLocation: 'mainNavMenuDropdown',
      })
    }
  }

export const handleUpgradeClick =
  (openModal: openModalCb, trackCTAClicked: TrackCTAClickedArgumentsCb) =>
  (): void => {
    openModal({
      key: MODALS.planSelector,
      data: {
        cta: 'navBar-dropdown-upgradePlan-1',
        upgradePathName: 'mainNavMenu-upgrade',
      },
    })
    trackCTAClicked({
      ctaButton: 'upgrade',
      upgradePathName: 'mainNavMenu-upgrade',
      ctaView: 'mainNavMenuDropdown',
      ctaLocation: 'mainNavMenuDropdown',
    })
  }

export const handleStartTrialClick =
  (openModal: openModalCb, trackCTAClicked: TrackCTAClickedArgumentsCb) =>
  (): void => {
    openModal({
      key: MODALS.startTrial,
      data: {
        ctaView: 'dropdown',
        ctaLocation: 'navBar',
        ctaButton: formatCTAString('Start a free trial'),
        cta: 'navBar-dropdown-startFreeTrial-1',
        upgradePathName: 'mainNavMenu-trial',
      },
    })
    trackCTAClicked({
      ctaButton: formatCTAString('Start a free trial'),
      upgradePathName: 'mainNavMenu-trial',
      ctaView: 'mainNavMenuDropdown',
      ctaLocation: 'mainNavMenuDropdown',
    })
  }

export const handlePlansAndPricingClick =
  (openModal: openModalCb, trackCTAClicked: TrackCTAClickedArgumentsCb) =>
  (): void => {
    openModal({
      key: MODALS.planSelector,
      data: {
        ctaView: 'dropdown',
        ctaLocation: 'navBar',
        ctaButton: formatCTAString('Plans & Pricing'),
        cta: 'navBar-dropdown-plansAndPricing-1',
        upgradePathName: 'mainNavMenu-trial',
      },
    })
    trackCTAClicked({
      ctaButton: formatCTAString('Plans & Pricing'),
      upgradePathName: 'mainNavMenu-trial',
      ctaView: 'mainNavMenuDropdown',
      ctaLocation: 'mainNavMenuDropdown',
    })
  }

export const handleLogoutClick = (onLogout?: () => void) => (): void => {
  if (typeof onLogout === 'function') onLogout()
  window.location.assign(getLogoutUrl(window.location.href))
}
