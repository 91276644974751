import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useUser } from '../../../../../common/context/User'
import useAuthorizationInfo from '../../hooks/useAuthorizationInfo/useAuthorizationInfo'
import PaywallModal from '../PaywallPrompt/PaywallPrompt'

import { checkShouldShowPaywallUpgrade } from '../../utils'
import ChannelConnectionsDialog from '../ChannelConnectionsDialog'
import { redirectToUrl } from '../../../../../common/utils/urls'
import {
  TrackingChannelEvent,
  triggerTrackingEventBasedOnAction,
} from '../../tracking'
import type { OrchestratorRootState } from '../../../../../common/events/types'
import type { onRedirectParams } from '../../types'
import MultipleChannelsWarningModal from '../MultipleChannelsWarningModal/MultipleChannelsWarningModal'
import type { Channel } from '../../../../../common/types'
import { useIsSplitEnabled } from '@buffer-mono/features'

function PreAuthenticationRedirect(): JSX.Element | null {
  const isMultipleChannelWarningModalEnabled = useIsSplitEnabled(
    'multiple-channels-warning-modal',
  )
  const user = useUser()
  const organizationId = user?.currentOrganization?.id

  const dispatch = useDispatch()
  const {
    selectedService,
    startConnection,
    isRefreshingChannelConnection,
    cta,
    selectedRefreshChannelId,
    connectChannelUpgrade,
    authRedirectUrl,
    showChannelStorefront,
    serviceRequiresFurtherConfiguration,
    startConversion,
    customChannelIdToConvert,
    authMethod,
    skipChannelCheck,
  } = useSelector((state: OrchestratorRootState) => state.channelConnections)

  const shouldShowPaywallModal =
    checkShouldShowPaywallUpgrade(
      user,
      selectedService,
      connectChannelUpgrade,
    ) && !isRefreshingChannelConnection

  /**
   * Scenarios which require further action
   * ---------------------
   * Some scenarios will require the user to perform another action
   * Before we can start fetching authentication data to start the channel connection
   * We do this check to prevent the automatic getAuthorizationInfo which is fired on first load
   *
   * Scenarios include:
   * - Free users requiring a Plan upgrade to connect more channels
   * - MP users who are trying to connect over their channel limit
   * - Users who are trying to connect a Service which requires extra setup: see list of channels defined in serviceRequiresFurtherConfiguration()
   */
  const requiresFurtherAction =
    shouldShowPaywallModal || serviceRequiresFurtherConfiguration

  const { url, handleGetAuthorizationInfo } = useAuthorizationInfo()

  const onRedirect = useCallback(
    (args: onRedirectParams) => {
      const { redirectUrl, authMethod } = args

      if (selectedService && organizationId) {
        triggerTrackingEventBasedOnAction({
          account: user,
          event: isRefreshingChannelConnection
            ? TrackingChannelEvent.refresh
            : TrackingChannelEvent.connection,
          service: selectedService,
          cta,
          authMethod,
        })
      }

      // Redirect to the URL after a small delay to ensure that tracking events are sent
      setTimeout(() => {
        redirectToUrl(redirectUrl)
      }, 300)
    },
    [
      dispatch,
      selectedService,
      user,
      cta,
      isRefreshingChannelConnection,
      organizationId,
    ],
  )

  useEffect(() => {
    if (
      (startConnection || startConversion) &&
      selectedService &&
      !requiresFurtherAction &&
      !authRedirectUrl
    ) {
      handleGetAuthorizationInfo({
        service: selectedService,
        authMethod,
        isRefreshingConnection: startConversion
          ? false
          : isRefreshingChannelConnection,
        selectedRefreshChannelId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startConnection,
    selectedService,
    isRefreshingChannelConnection,
    shouldShowPaywallModal,
    authRedirectUrl,
  ])

  useEffect(() => {
    if (startConversion && url && customChannelIdToConvert) {
      // Necessary to include the customChannelIdToConvert in state param for
      // when we get redirected back to Buffer from Instagram, so that we
      // have this channel id available for channel conversion
      const urlWithCustomChannelIdToConvert = `${url}&state=custom_channel_id_to_convert_${customChannelIdToConvert}`
      onRedirect({ redirectUrl: urlWithCustomChannelIdToConvert })
    }
  }, [startConversion, url, customChannelIdToConvert, onRedirect])

  /**
   * Paywall Modal/Prompt
   * ---------------------
   * Scenarios when this is shown:
   * - Free users requiring a Plan upgrade to connect more channels
   * - MP users who are trying to connect over their channel limit
   * - Your lifetimeConnectedChannels is over the limit (currently at 8)
   */
  if (
    (shouldShowPaywallModal && selectedService) ||
    (shouldShowPaywallModal && connectChannelUpgrade)
  ) {
    return <PaywallModal service={selectedService || undefined} />
  }

  if (
    isMultipleChannelWarningModalEnabled &&
    isRefreshingChannelConnection &&
    authRedirectUrl &&
    !skipChannelCheck
  ) {
    const userChannels = user.currentOrganization?.channels || []
    const numberOfChannels =
      userChannels.filter((channel) => {
        if (channel.service === selectedService) {
          if (channel.service === 'instagram') {
            return channel.type === 'business'
          }

          if (channel.service === 'facebook') {
            return channel.type === 'page'
          }

          return true
        }

        return false
      }).length || 0

    if (numberOfChannels > 1) {
      return (
        <MultipleChannelsWarningModal
          onContinue={(): void => {
            onRedirect({
              redirectUrl: authRedirectUrl,
            })
          }}
          channel={
            userChannels.find(
              (channel) => channel.id === selectedRefreshChannelId,
            ) as Channel
          }
        />
      )
    }
  }

  const channelConnectionsDialogActive =
    showChannelStorefront || requiresFurtherAction

  const shouldTriggerRedirectToChannelAuthentication =
    !channelConnectionsDialogActive && startConnection

  if (
    selectedService &&
    shouldTriggerRedirectToChannelAuthentication &&
    authRedirectUrl
  ) {
    onRedirect({
      redirectUrl: authRedirectUrl,
    })
    return null
  }

  return channelConnectionsDialogActive ? (
    <ChannelConnectionsDialog onRedirect={onRedirect} />
  ) : null
}

export default PreAuthenticationRedirect
