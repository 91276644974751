import React from 'react'
import PropTypes from 'prop-types'
import styles from './css/BoardCreator.module.css'
import AppActionCreators from '../action-creators/AppActionCreators'
import { NotificationScopes } from '../AppConstants'
import NotificationContainer from './NotificationContainer'
import Input from './Input'
import NotificationActionCreators from '../action-creators/NotificationActionCreators'
import { visibleNotificationsPropType } from './ComposerPropTypes'

class BoardCreator extends React.Component {
  static propTypes = {
    subprofiles: PropTypes.array,
    profile: PropTypes.object,
    visibleNotifications: visibleNotificationsPropType.isRequired,
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = this.getInitialBoardCreatorState()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onSubmit = (e) => {
    e.preventDefault()
    // @ts-expect-error TS(2339) FIXME: Property 'visibleNotifications' does not exist on ... Remove this comment to see the full error message
    const notifications = this.props.visibleNotifications.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
      (notif) => notif.scope === NotificationScopes.BOARD_CREATION,
    )
    for (const notification of notifications) {
      NotificationActionCreators.removeNotification(notification.id)
    }

    // @ts-expect-error TS(2339) FIXME: Property 'boardName' does not exist on type 'Reado... Remove this comment to see the full error message
    const { boardName } = this.state
    // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
    const profileId = this.props.profile.id

    AppActionCreators.createNewSubprofile(profileId, boardName)

    this.setState(this.getInitialBoardCreatorState())
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onChange = (e) => {
    this.setState({
      boardName: e.target.value,
    })
  }

  getInitialBoardCreatorState = () => ({
    boardName: '',
  })

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { isSubprofileCreationPending } = this.props.profile.appState
    // @ts-expect-error TS(2339) FIXME: Property 'boardName' does not exist on type 'Reado... Remove this comment to see the full error message
    const isBoardNameEmpty = this.state.boardName.length === 0

    const isDisabled = isBoardNameEmpty || isSubprofileCreationPending
    const submitButtonLabel = isSubprofileCreationPending
      ? 'Creating…'
      : 'Create'

    const submitButtonClassName = isDisabled
      ? styles.disabledCreateButton
      : styles.createBoardButton

    const visibileNotificationsForProfile =
      // @ts-expect-error TS(2339) FIXME: Property 'visibleNotifications' does not exist on ... Remove this comment to see the full error message
      this.props.visibleNotifications.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
        (notif) => notif.data.profileId === this.props.profile.id,
      )

    return (
      <div className={styles.createNewBoard}>
        <form onSubmit={this.onSubmit} className={styles.createBoardForm}>
          <Input
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            value={this.state.boardName}
            placeholder="New board name"
            className={styles.newBoardName}
            onChange={this.onChange}
          />
          <Input
            type="submit"
            value={submitButtonLabel}
            className={submitButtonClassName}
            disabled={isDisabled}
          />
        </form>
        <NotificationContainer
          visibleNotifications={visibileNotificationsForProfile}
          scope={NotificationScopes.BOARD_CREATION}
        />
      </div>
    )
  }
}

export default BoardCreator
