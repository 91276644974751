import { useQuery } from '@apollo/client'
import { type FragmentOf, graphql, readFragment } from '~publish/graphql'

export const ChannelSettings_Channel = graphql(/* GraphQL */ `
  fragment ChannelSettings_Channel on Channel @_unmask {
    accessLevel
    allowedActions
    apiVersion
    avatar
    banners
    createdAt
    descriptor
    displayName
    externalLink
    id
    isDisconnected
    isLocked
    name
    organizationId
    service
    type
    metadata {
      ... on InstagramMetadata {
        defaultToReminders
      }
      ... on PinterestMetadata {
        boards {
          serviceId
          name
          url
          description
          avatar
        }
      }
      ... on MastodonMetadata {
        serverUrl
      }
      ... on BlueskyMetadata {
        serverUrl
      }
      ... on GoogleBusinessMetadata {
        locationData {
          location
          mapsLink
          googleAccountId
        }
      }
      ... on FacebookMetadata {
        locationData {
          location
        }
      }
      ... on TwitterMetadata {
        subscriptionType
      }
      ... on LinkedInMetadata {
        shouldShowLinkedinAnalyticsRefreshBanner
      }
    }
  }
`)

export const GetChannels = graphql(
  /* GraphQL */ `
    query getChannels($input: ChannelsInput!) {
      channels(input: $input) {
        id
        ...ChannelSettings_Channel
      }
    }
  `,
  [ChannelSettings_Channel],
)

export type ChannelMask = FragmentOf<typeof ChannelSettings_Channel>

interface UseChannelsReturn {
  channels: Array<ChannelMask> | undefined
  loading: boolean
  error: boolean
}

/**
 * Custom hook to fetch channels for the settings page based on the provided organization ID.
 *
 * @param {string} [params.organizationId] - The Id of the organization to fetch channels for.
 *
 * @property {Array<ChannelMask> | undefined} channels - The fetched channels data or undefined if not loaded.
 */
export const useChannelsForSettings = ({
  organizationId,
}: {
  organizationId?: string
}): UseChannelsReturn => {
  const {
    data: channelsData,
    loading,
    error,
  } = useQuery(GetChannels, {
    variables: {
      input: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error - organizationId is not always defined we skip if it is not defined
        organizationId,
      },
    },
    skip: !organizationId,
  })
  const channels = readFragment(ChannelSettings_Channel, channelsData?.channels)

  return {
    channels,
    loading,
    error: !!error,
  }
}
