import React from 'react'
import {
  Flex,
  Dialog,
  BufferIcon,
  ArrowDownUpIcon,
  CloseIcon,
  ChannelIcon,
  IconButton,
} from '@buffer-mono/popcorn'

import styles from './Header.module.css'

import { BackButton } from '../../../../_shared/components/BackButton/BackButton'

export function Header({
  onBack,
  isRefreshingConnection,
}: {
  onBack?: () => void
  onClose?: () => void
  isRefreshingConnection?: boolean
}): JSX.Element {
  return (
    <Dialog.Header className={styles.wrapper}>
      <div>
        {isRefreshingConnection ? undefined : <BackButton onBack={onBack} />}
      </div>
      <div>
        {!isRefreshingConnection && (
          <Flex gap="sm" align="center">
            <BufferIcon />
            <ArrowDownUpIcon className={styles.arrowsIcon} color="subtle" />
            <ChannelIcon type="facebook" color="branded" />
          </Flex>
        )}
      </div>
      <Dialog.Close>
        <IconButton label="Close" variant="tertiary" size="small">
          <CloseIcon />
        </IconButton>
      </Dialog.Close>
    </Dialog.Header>
  )
}
