import React, { useRef, useState } from 'react'

import Input from '@bufferapp/ui/Input'
import ColorSwatches from '../ColorSwatches'

import styles from '../../styles.module.css'

import { isHexValid, getValidHex } from '../../utils/HexValidations'
import { DEFAULT_COLOR } from '../../index'

const ColorSelectorPopup = ({
  colorSelected,
  isValidHex,
  setIsValidHex,
  onBlur,
  onColorChange,
}: {
  colorSelected: string
  isValidHex: boolean
  setIsValidHex: (isValid: boolean) => void
  onBlur: () => void
  onColorChange: (color: string) => void
}): JSX.Element => {
  const containerEl = useRef<HTMLInputElement>(null)

  const [lastValidColor, setLastValidColor] = useState<string>(
    colorSelected || DEFAULT_COLOR,
  )

  const onColorSelectionChange = (newColor: string): void => {
    onColorChange(newColor)
    setLastValidColor(newColor)
  }

  return (
    <div className={styles.colorPopup}>
      <ColorSwatches
        onColorChange={(color: string): void => {
          onColorSelectionChange(color)
        }}
        colorSelected={colorSelected}
      />
      <div className={styles.colorContainer}>
        <div
          className={styles.colorInputWrapper}
          style={
            {
              '--color': colorSelected,
            } as React.CSSProperties
          }
        >
          <input
            className={styles.colorInput}
            ref={containerEl}
            type="color"
            value={colorSelected}
            id="colorWheel"
            onChange={(event): void => {
              onColorSelectionChange(event.target.value)
            }}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            type="input"
            prefix={{ text: '#', paddingLeft: '18px' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              const color = `#${event.target.value}`
              const isValidColor = isHexValid(color)

              setIsValidHex(isValidColor)
              onColorChange(color)
            }}
            value={colorSelected.replace('#', '')}
            name="colorInput"
            disabled={false}
            hasError={!isValidHex}
            onBlur={(): void => {
              if (!isValidHex) {
                const selectedHex = getValidHex(colorSelected, lastValidColor)

                onColorSelectionChange(selectedHex)
                setIsValidHex(true)
              }
            }}
            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
              if (event.key === 'Enter') {
                event.preventDefault()

                const color = `#${(event.target as HTMLInputElement).value}`
                const isValidColor = isHexValid(color)

                if (isValidColor) {
                  onBlur()
                }
              }
            }}
            maxLength="6"
          />
        </div>
      </div>
    </div>
  )
}

export default ColorSelectorPopup
