import { Tooltip } from '@buffer-mono/popcorn'
import React, { Component } from 'react'
import { Text, Button } from '@bufferapp/ui'
import {
  grayLight,
  redDark,
  redLightest,
  boxShadow,
  blue,
  grayDarker,
} from '@bufferapp/ui/style/colors'
import { fontFamily, fontSize, lineHeight } from '@bufferapp/ui/style/fonts'
import { borderRadius } from '@bufferapp/ui/style/borders'
import countHashtagsInText from '../../utils/HashtagCounter'

import * as Styles from './styles'

const textareaWrapperStyle: React.CSSProperties = {
  position: 'relative',
  flex: 1,
  padding: '16px 16px 52px',
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${grayLight}`,
  borderRadius,
}

const textareaWrapperFocusedStyle: React.CSSProperties = {
  boxShadow: `0px 0px 0px 3px ${boxShadow}`,
  border: `1px solid ${blue}`,
}

const inputStyle: React.CSSProperties = {
  color: grayDarker,
  padding: '13px 16px 12px',
  outline: 'none',
  width: '100%',
  boxSizing: 'border-box',
  border: `1px solid ${grayLight}`,
  margin: '8px 0px',
  borderRadius,
  fontSize,
  lineHeight,
  fontFamily,
}

const inputFocusedStyle = {
  boxShadow: `0px 0px 0px 3px ${boxShadow}`,
  border: `1px solid ${blue}`,
}

const getElementStyle = (
  { state }: { state: any },
  type: string,
): React.CSSProperties => {
  let style: React.CSSProperties = {}
  let focusedStyle: React.CSSProperties = {}
  let focusedState = false
  switch (type) {
    case 'textareaWrapper':
      style = textareaWrapperStyle
      focusedStyle = textareaWrapperFocusedStyle
      focusedState = state.textareaFocused
      break
    case 'input':
      style = inputStyle
      focusedStyle = inputFocusedStyle
      focusedState = state.inputFocused
      break
    default:
      break
  }

  if (focusedState) {
    style = { ...style, ...focusedStyle }
  }

  return style
}

const counterLabelStyle = (hasError: boolean): React.CSSProperties => ({
  position: 'absolute',
  right: '16px',
  bottom: '16px',
  fontSize: '10px',
  lineHeight: '12px',
  textAlign: 'center',
  border: hasError ? `1px solid ${redLightest}` : `1px solid ${grayLight}`,
  color: hasError ? redDark : grayDarker,
  backgroundColor: hasError ? redLightest : 'transparent',
  borderRadius: '4px',
  padding: '2px 4px',
})

interface HashtagGroupCreatorProps {
  name?: string | null
  text?: string | null
  isEditing?: boolean
  onChangeGroupName: (name: string) => void
  onChangeGroupText: (text: string) => void
  onCancelHashtagGroup: () => void
  onSaveHashtagGroup?: () => void
  onUpdateHashtagGroup?: () => void
}

interface HashtagGroupCreatorState {
  textareaFocused: boolean
  inputFocused: boolean
  isSaveButtonDisabled: boolean
}

class HashtagGroupCreator extends Component<
  HashtagGroupCreatorProps,
  HashtagGroupCreatorState
> {
  state: HashtagGroupCreatorState = {
    textareaFocused: false,
    inputFocused: false,
    isSaveButtonDisabled: true,
  }

  constructor(props: HashtagGroupCreatorProps) {
    super(props)

    this.handleTextareaChange = this.handleTextareaChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.disableSaveButton = this.disableSaveButton.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps: HashtagGroupCreatorProps): void {
    if (
      prevProps.name !== this.props.name ||
      prevProps.text !== this.props.text
    ) {
      this.disableSaveButton()
    }
  }

  handleTextareaChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    const { value } = event.target
    this.props.onChangeGroupText(value)
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.props.onChangeGroupName(event.target.value)
  }

  disableSaveButton(): void {
    const { name, text } = this.props
    const numberHashtagsLeft = countHashtagsInText(text || '')
    const isSaveButtonDisabled =
      numberHashtagsLeft < 0 || !text?.trim() || !name?.trim()
    this.setState({ isSaveButtonDisabled })
  }

  handleSubmit(): void {
    const {
      isEditing,
      onUpdateHashtagGroup,
      onSaveHashtagGroup,
      onCancelHashtagGroup,
    } = this.props
    if (isEditing && onUpdateHashtagGroup) {
      onUpdateHashtagGroup()
    } else if (onSaveHashtagGroup) {
      onSaveHashtagGroup()
    }
    onCancelHashtagGroup()
  }

  render(): JSX.Element {
    const { name, text, isEditing, onCancelHashtagGroup } = this.props
    const numberHashtagsLeft = countHashtagsInText(text || '')
    return (
      <>
        <Styles.Title>
          {isEditing ? 'Edit Hashtag Group' : 'Create Hashtag Group'}
        </Styles.Title>
        <Styles.Form autoComplete="off">
          <div>
            <Text htmlFor="hashtagGroupName" type="label">
              Hashtag Group Name
            </Text>
            <input
              style={getElementStyle({ state: this.state }, 'input')}
              placeholder="Your hashtag group name"
              id="hashtagGroupName"
              maxLength={200}
              type="text"
              value={name || ''}
              onChange={this.handleInputChange}
              onFocus={() => this.setState({ inputFocused: true })}
              onBlur={() => this.setState({ inputFocused: false })}
            />
          </div>
          <div>
            <Styles.LabelWrapper>
              <Text htmlFor="hashtagGroupContent" type="label">
                Hashtag Group Content
              </Text>
            </Styles.LabelWrapper>
            <div
              style={getElementStyle({ state: this.state }, 'textareaWrapper')}
            >
              <Styles.TextArea
                placeholder="Your hashtags"
                id="hashtagGroupContent"
                maxLength={2000}
                value={text || ''}
                onChange={this.handleTextareaChange}
                onFocus={() => this.setState({ textareaFocused: true })}
                onBlur={() => this.setState({ textareaFocused: false })}
              />
              <div style={counterLabelStyle(numberHashtagsLeft < 0)}>
                <Tooltip
                  content="Instagram will reject posts containing over 30 hashtags"
                  side="top"
                >
                  <Text type="span"># Remaining: {numberHashtagsLeft}</Text>
                </Tooltip>
              </div>
            </div>
          </div>
        </Styles.Form>
        <Styles.ButtonsWrapper>
          <Styles.CancelButtonWrapper>
            {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
            <Button type="text" label="Cancel" onClick={onCancelHashtagGroup} />
          </Styles.CancelButtonWrapper>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; disabled: boo... Remove this comment to see the full error message */}
          <Button
            type="secondary"
            label="Save Hashtag Group"
            disabled={this.state.isSaveButtonDisabled}
            onClick={this.handleSubmit}
          />
        </Styles.ButtonsWrapper>
      </>
    )
  }
}

export default HashtagGroupCreator
