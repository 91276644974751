import React from 'react'
import { Link } from '@bufferapp/ui'
import { Text } from '@buffer-mono/legacy-bufferapp-components'

export type LinkType = {
  url?: string
  displayString: string
  indices: number[]
  className?: string
}

export type OptionType = {
  newTab: boolean
}

type Element = string | JSX.Element

const linkDataToElement = (
  options: OptionType,
  link: LinkType,
  key: number,
): JSX.Element => (
  <Link
    href={link.url as string}
    key={key}
    newTab={options.newTab}
    className={link.className}
    fontWeight={400}
  >
    {link.displayString}
  </Link>
)

const getLinkifiedText = (
  options: OptionType,
  links: LinkType[],
  text: string,
): Element[] => {
  const elements: Element[] = []
  let currentIndex = 0

  if (!links || links.length === 0) {
    elements.push(text)
    return elements
  }

  links.forEach((link) => {
    const startIndex = link.indices[0]
    const endIndex = link.indices[1]

    if (startIndex < text.length) {
      if (currentIndex !== startIndex) {
        elements.push(text.substring(currentIndex, startIndex))
      }

      elements.push(linkDataToElement(options, link, elements.length))
      currentIndex = endIndex
    }
  })

  if (currentIndex !== text.length) {
    elements.push(text.substring(currentIndex))
  }

  return elements
}

interface LinkifiedTextProps {
  children?: string | null
  color?: React.ComponentProps<typeof Text>['color']
  links?: LinkType[]
  newTab?: boolean
  size?: 'extra-small' | 'large' | 'extra-large' | 'mini' | 'small'
  whitespace?: 'normal' | 'pre' | 'pre-line' | 'pre-wrap' | 'nowrap'
  className?: string
}

const LinkifiedText = ({
  children = '',
  color = 'shuttleGray',
  links = [],
  newTab = true,
  size = 'mini',
  whitespace = 'normal',
  className,
}: LinkifiedTextProps): JSX.Element => {
  const options = { newTab }

  return (
    <Text
      whitespace={whitespace}
      size={size}
      color={color}
      className={className}
    >
      {getLinkifiedText(options, links, children as string)}
    </Text>
  )
}

export default LinkifiedText
