import React, { useContext, useEffect } from 'react'

import {
  Button,
  Flex,
  Image,
  List,
  Paragraph,
  Strong,
  Text,
  UpgradeDialog,
} from '@buffer-mono/popcorn'

import { Error } from '../../../../common/components/Error'
import { UserContext } from '../../../../common/context/User'
import useDismissObject from '../../../../common/hooks/useDismissObject'
import useStartTrial from '../../../../common/hooks/useStartTrial'
import { MODALS } from '../../../../common/types'
import { DATES, setCookie } from '../../../../common/utils/cookies'
import { getSuggestedPlan } from '../../../../common/utils/getSuggestedPlan'
import { prefixWithProduct } from '../../../../common/utils/prefixWithProduct'

import trackStartTrialModalMainCTAClicked from '../../../../tracking/TrialPaths/trackStartTrialModalMainCTAClicked'
import trackStartTrialModalViewed from '../../../../tracking/TrialPaths/trackStartTrialModalViewed'
import type { TrialPathsTrackingPayload } from '../../../../tracking/TrialPaths/TrialPathsTrackingTypes'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

import styles from './StartTrialModalReactive.module.css'
import { useSplitEnabled } from '@buffer-mono/features'

type DetailItem = {
  bold: string
  text: string
}

const trialCheckPoints = [
  {
    bold: 'In-Depth Analytics: ',
    text: 'Track your post performance and gain valuable insights to refine your content strategy.',
  },
  {
    bold: 'Team Collaboration: ',
    text: 'Work together with your team, approve posts, and streamline your workflow.',
  },
  {
    bold: 'Engage with Comments: ',
    text: 'View and reply to all comments (Only available for Facebook and Instagram).',
  },
  {
    bold: 'And more: ',
    text: 'Unlimited scheduling, unlimited tags & ideas, Hashtag Manager and more.',
  },
]

const imageUrl =
  'https://buffer-ui.s3.amazonaws.com/upgrade-paths/start-trial/trial-prompt-modal-after-creating-post.png'

export const StartTrialModalReactive = (): JSX.Element => {
  const user = useContext(UserContext)
  const suggestedPlan = getSuggestedPlan(user)
  const { modalData, openModal, dismissModal: closeAction } = useModalManager()
  const { cta, ctaButton, ctaView, ctaLocation, upgradePathName } =
    modalData || {}

  const { startTrial, trial, error, processing } = useStartTrial({
    user,
    plan: suggestedPlan,
    attribution: {
      cta: prefixWithProduct(cta),
      upgradePathName: upgradePathName || '',
    },
  })

  const trackingPayload: TrialPathsTrackingPayload = {
    ctaButton,
    upgradePathName: upgradePathName || '',
    ctaView,
    ctaLocation,
  }

  // Mechanism to dismiss  from appearing to a customer who
  // has signed up for free and starts a trial
  const { onDismissObject } = useDismissObject({
    organizationId: user.currentOrganization?.id || '',
    dismissedObject: {
      id: 'trial_signup_welcome_modal',
    },
  })

  // We change the copy of the modal based on the split variant_b
  const {
    isEnabled: delayTrialPromptVariantB,
    isReady: delayTrialPromptVariantBIsReady,
  } = useSplitEnabled(
    'geid-remove-generic-trial-prompts-v2',
    {
      email: user.email,
    },
    'variant_b',
  )
  useEffect((): void => {
    if (trial && trial.billingStartTrial.success) {
      onDismissObject()
      openModal({
        key: MODALS.success,
        data: {
          startedTrial: true,
          selectedPlan: suggestedPlan,
        },
      })
    }
  }, [trial])

  useEffect(() => {
    trackStartTrialModalViewed({ payload: trackingPayload, user })
  }, [])

  const handleOnDismissModal = (): void => {
    setCookie({
      key: 'startTrialPrompt',
      value: true,
      expires: DATES.inMonthsFromNow(12),
    })
    closeAction()
  }

  const handleStartTrial = (): void => {
    startTrial()
    trackStartTrialModalMainCTAClicked({ payload: trackingPayload, user })
  }

  return (
    <UpgradeDialog open={true} onOpenChange={handleOnDismissModal}>
      <UpgradeDialog.Content>
        <UpgradeDialog.TextContent>
          <UpgradeDialog.Title>
            {delayTrialPromptVariantB && delayTrialPromptVariantBIsReady
              ? 'Congratulations on starting a posting streak! 🎉'
              : 'Congratulations on your first post! 🎉'}
          </UpgradeDialog.Title>
          <Paragraph>
            Start a free 14-day trial of our paid features to maximize your
            Buffer experience:
          </Paragraph>
          <List>
            {trialCheckPoints.map((detail: DetailItem) => (
              <List.Item key={detail.bold} className={styles.detailItem}>
                <Text as="span">
                  <Strong>{detail.bold}</Strong>
                  {detail.text}
                </Text>
              </List.Item>
            ))}
          </List>
          <Flex gap="xs">
            <Button
              variant="primary"
              size="large"
              disabled={!suggestedPlan || processing}
              onClick={handleStartTrial}
            >
              {processing ? 'Processing ...' : 'Start Your Free 14-day Trial'}
            </Button>
            <Button
              variant="secondary"
              size="large"
              onClick={handleOnDismissModal}
            >
              No Thanks
            </Button>
          </Flex>
          <Text as="p" color="subtle">
            No credit card required
          </Text>
          {error && <Error isInline={false} error={error} />}
        </UpgradeDialog.TextContent>
        <UpgradeDialog.Image>
          <Image alt="Start Trial" src={imageUrl} />
        </UpgradeDialog.Image>
      </UpgradeDialog.Content>
    </UpgradeDialog>
  )
}
