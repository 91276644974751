import React, { useEffect, type Dispatch, type SetStateAction } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  ChevronDownIcon,
  Dialog,
  DropdownMenu,
  Flex,
  Heading,
  Link,
  RadioCard,
  Text,
} from '@buffer-mono/popcorn'
import useAuthorizationInfo from '../../../../../hooks/useAuthorizationInfo/useAuthorizationInfo'
import type { OrchestratorRootState } from '../../../../../../../../common/events/types'
import trackChannelConnectionStarted from '../../../../../../../../tracking/trackChannelConnectionStarted'

import { ACCOUNT_OPTIONS_FOR_CHANNELS } from './constants'
import { HC_UTM_PARAMS } from '../../constants'

import { useUser } from '../../../../../../../../common/context/User'
import { trackProfileTypeSelectionViewed } from '../../../../../tracking'
import { Service, ChannelAccountType } from '../../../../../types'
import { ViewType } from '../../types'
import { Footer } from '../shared/Footer/Footer'
import styles from './SelectAccountType.module.css'
import footerStyles from '../shared/Footer/Footer.module.css'

export function SelectAccountType({
  setSelectedAccountType,
  setCurrentView,
  selectedAccountType,
  onRedirect,
}: {
  setSelectedAccountType: Dispatch<
    SetStateAction<
      ChannelAccountType.page | ChannelAccountType.group | undefined
    >
  >
  selectedAccountType:
    | ChannelAccountType.page
    | ChannelAccountType.group
    | undefined
  onRedirect: (args: { redirectUrl: string }) => void
  setCurrentView: Dispatch<
    SetStateAction<ViewType.accountSelection | ViewType.FacebookGroups>
  >
  onClose: () => void
}): JSX.Element {
  const account = useUser()

  useEffect(() => {
    trackProfileTypeSelectionViewed({
      account,
      service: Service.facebook,
    })
  }, [account])

  const { cta } = useSelector(
    (state: OrchestratorRootState) => state.channelConnections,
  )

  const { handleGetAuthorizationInfo, url: redirectUrl } =
    useAuthorizationInfo()

  useEffect(() => {
    handleGetAuthorizationInfo({
      service: Service.facebook,
      isRefreshingConnection: false,
    })
  }, [handleGetAuthorizationInfo])

  function handleOnSubmit(): void {
    if (selectedAccountType === ChannelAccountType.page) {
      trackChannelConnectionStarted({
        payload: {
          service: Service.facebook,
          cta,
          channelType: ChannelAccountType.page,
        },
        user: account,
      })

      if (redirectUrl) {
        onRedirect({ redirectUrl })
      }
    }

    if (selectedAccountType === ChannelAccountType.group) {
      setCurrentView(ViewType.FacebookGroups)
    }
  }

  return (
    <>
      <Dialog.Body>
        <Flex direction="column" gap="lg" align="center">
          <Flex
            justify="center"
            fullWidth
            direction="column"
            gap="xs"
            align="center"
          >
            <Heading size="large">Select Facebook Account Type</Heading>
            <Text>
              Check out{' '}
              <Link
                href={`https://support.buffer.com/article/567-supported-channels?${HC_UTM_PARAMS}`}
                external
              >
                our guide
              </Link>{' '}
              to supported account types for more details.
            </Text>
          </Flex>

          <Flex className={styles.radioGroup}>
            <RadioCard.Group
              onValueChange={(value): void =>
                setSelectedAccountType(
                  value as ChannelAccountType.page | ChannelAccountType.group,
                )
              }
            >
              {ACCOUNT_OPTIONS_FOR_CHANNELS.map((option) => (
                <RadioCard value={option.accountType} key={option.accountType}>
                  <Flex fullWidth gap="sm">
                    <Flex
                      align="center"
                      justify="center"
                      className={styles.iconContainer}
                    >
                      {option.icon}
                    </Flex>
                    <Flex direction="column" gap="2xs">
                      <Text>
                        <strong>{option.header}</strong>
                      </Text>
                      <Text color="subtle">{option.description}</Text>
                    </Flex>
                  </Flex>
                </RadioCard>
              ))}
            </RadioCard.Group>
          </Flex>
        </Flex>
      </Dialog.Body>
      <Footer>
        <DropdownMenu className={footerStyles.helpMenu}>
          <DropdownMenu.Trigger>
            <Button
              variant="tertiary"
              size="large"
              className={styles.helpButton}
            >
              Need Help
              <ChevronDownIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="start">
            <DropdownMenu.Item>
              <a
                className={footerStyles.helpLink}
                href="https://support.buffer.com/article/555-using-facebook-with-buffer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Using Facebook with Buffer
              </a>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
        <Button
          size="large"
          disabled={!selectedAccountType}
          onClick={handleOnSubmit}
        >
          Continue
        </Button>
      </Footer>
    </>
  )
}
