import type { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions'
import { graphql } from '~publish/gql'
import { useTypedMutation } from '~publish/hooks/useTypedMutation'

const CreateNewFeedCollection = graphql(/* GraphQL */ `
  mutation CreateNewFeedCollection($input: CreateFeedCollectionInput!) {
    createFeedCollection(input: $input) {
      __typename
      ... on FeedCollectionActionSuccess {
        feedCollection {
          id
          name
          organizationId
        }
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useCreateNewFeedCollection = () =>
  useTypedMutation(
    CreateNewFeedCollection,
    (data) => data.createFeedCollection,
    {
      successTypename: 'FeedCollectionActionSuccess',
      // Add optimistic response
      optimisticResponse: (
        variables,
        { IGNORE },
      ): MutationBaseOptions['optimisticResponse'] => {
        if (!variables?.input) return IGNORE

        // Generate a temporary ID that will be replaced with the real one from the server
        const tempId = `temp-id-${Date.now()}`

        return {
          createFeedCollection: {
            __typename: 'FeedCollectionActionSuccess',
            feedCollection: {
              __typename: 'FeedCollection',
              id: tempId,
              name: variables.input.name,
              organizationId: variables.input.organizationId,
            },
          },
        }
      },
      update(cache, { data }) {
        if (
          data?.createFeedCollection?.__typename ===
          'FeedCollectionActionSuccess'
        ) {
          const feedCollection = data.createFeedCollection.feedCollection

          // Modify the feedCollections field directly
          cache.modify({
            fields: {
              feedCollections(existing = { edges: [] }, { toReference }) {
                const newEdge = {
                  __typename: 'FeedCollectionEdge',
                  node: toReference({
                    __typename: 'FeedCollection',
                    id: feedCollection.id,
                    name: feedCollection.name,
                  }),
                }
                return {
                  ...existing,
                  edges: [newEdge, ...existing.edges],
                }
              },
            },
          })
        }
      },
    },
  )
