import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Flex, Form, Input, toast } from '@buffer-mono/popcorn'

import { useAccount } from '~publish/legacy/accountContext'
import { graphql } from '~publish/gql'

import style from '../ProfileSettings.module.css'

export const UPDATE_ACCOUNT_NAME = graphql(/* GraphQL */ `
  mutation UpdateAccountName($input: UpdateAccountNameInput!) {
    updateAccountName(input: $input) {
      __typename
      ... on UpdateAccountNameSuccess {
        _empty
      }
      ... on UnexpectedError {
        message
      }
      ... on InvalidInputError {
        message
      }
    }
  }
`)

export const Name = (): JSX.Element => {
  const [updateAccountName] = useMutation(UPDATE_ACCOUNT_NAME)
  const { account, refetchAccount } = useAccount()
  const currentAccountName = account?.name
  const [updatedAccountName, setUpdatedAccountName] =
    useState(currentAccountName)
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (updatedAccountName !== currentAccountName) {
      setDirty(true)
    } else {
      setDirty(false)
    }
  }, [updatedAccountName, currentAccountName])

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()
    const result = await updateAccountName({
      variables: {
        input: {
          name: updatedAccountName ?? '',
        },
      },
    })
    if (
      result.data?.updateAccountName?.__typename !== 'UpdateAccountNameSuccess'
    ) {
      toast.error('An error occurred while updating your name.')
      setUpdatedAccountName(currentAccountName)
    } else {
      if (refetchAccount) {
        refetchAccount()
      }
      toast.success('Your name has been updated!')
    }
  }

  return (
    <Form
      name="updateAccountNameForm"
      className={style.fullWidth}
      onSubmit={handleSubmit}
    >
      <Flex gap="xs" align="end" className={style.fullWidth}>
        <Form.Field name="name" className={style.fullWidth}>
          <Form.Label htmlFor="name">Name</Form.Label>
          <Form.Control>
            <Input
              id="name"
              className={style.flexInput}
              defaultValue={currentAccountName ?? ''}
              data-1p-ignore="true"
              onChange={(event): void =>
                setUpdatedAccountName(event.target.value)
              }
            />
          </Form.Control>
        </Form.Field>
        <Button
          size="medium"
          type="submit"
          variant="secondary"
          disabled={!dirty}
          className={style.fixedWidthButton}
        >
          Save Changes
        </Button>
      </Flex>
    </Form>
  )
}

export default Name
