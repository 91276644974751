import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import React from 'react'
import { capitalize } from '~publish/helpers/capitalize'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import {
  useCurrentOrganization,
  useHasChannelConnected,
} from '~publish/legacy/accountContext'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { CtaButtonWrapper } from '../../../components/CtaButtonWrapper'
import { RegisteredBanner } from '../../../components/RegisteredBanner'
import threadsBannerSvg from './threads-banner.svg'
import { Service } from '@buffer-mono/app-shell/src/exports/Orchestrator/channelConnections/types'

const useCanDisplayBanner = (bannerId: string): boolean => {
  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })
  const hasThreadsChannel = useHasChannelConnected('threads')
  const selectedChannel = useSelectedChannel()
  const supportedChannels = ['twitter', 'instagram', 'facebook', 'mastodon']
  const isCurrentChannelSupported =
    !!selectedChannel && supportedChannels.includes(selectedChannel.service)

  return banner.isActive && !hasThreadsChannel && isCurrentChannelSupported
}

export const MetaThreadsPromotionalBanner = (): JSX.Element | null => {
  const { role } = useCurrentOrganization() ?? {}
  const bannerId = 'threadsByMetaPromo'
  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })
  const selectedChannel = useSelectedChannel()

  const connectThreadsCta = `publish-queue-banner-connectThreads${capitalize(
    selectedChannel?.service ?? '',
  )}-1` as const

  const onThreadsConnectClick = (): void => {
    if (!role) return
    window.appshell.actions.connectChannel({
      selectedService: Service.threads,
      cta: connectThreadsCta,
      role,
    })
  }

  const onBlogLinkClick = (): void => {
    window.open(`https://buffer.com/resources/how-to-use-threads/`, '_blank')
  }

  const canRenderBanner = useCanDisplayBanner(bannerId)

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <PromotionalBanner onDismiss={banner.dismiss}>
        <PromotionalBanner.Content>
          <PromotionalBanner.Heading>
            Threads is now available in Buffer
          </PromotionalBanner.Heading>
          <PromotionalBanner.Description>
            Learn more about the fastest growing social network and how to make
            it work for you.
          </PromotionalBanner.Description>
          <PromotionalBanner.Actions>
            <CtaButtonWrapper
              cta={connectThreadsCta}
              options={{ upgradePathName: 'accountChannels-upgrade' }}
            >
              <Button variant="primary" onClick={onThreadsConnectClick}>
                Connect Threads
              </Button>
            </CtaButtonWrapper>
            <CtaButtonWrapper cta="publish-queue-banner-readBeginnersGuideThreads-1">
              <Button variant="secondary" onClick={onBlogLinkClick}>
                Read our Beginner&apos;s Guide
              </Button>
            </CtaButtonWrapper>
          </PromotionalBanner.Actions>
        </PromotionalBanner.Content>
        <PromotionalBanner.Image src={threadsBannerSvg} />
      </PromotionalBanner>
    </RegisteredBanner>
  )
}
