import { useEffect, useRef, useState } from 'react'

/**
 * A custom React hook that detects whether an element has been scrolled.
 *
 * @returns {Object} An object containing:
 *   - scrollRef: A React ref object to be attached to the scrollable element.
 *   - isScrolled: A boolean indicating whether the element has been scrolled.
 */
export const useIsScrolled = (): {
  scrollRef: React.RefObject<HTMLElement>
  isScrolled: boolean
} => {
  const scrollRef = useRef<HTMLElement | null>(null)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = (): void => {
      if (scrollRef.current) {
        const scrollPosition = scrollRef.current.scrollTop
        setIsScrolled(scrollPosition > 0)
      }
    }

    const targetElement = scrollRef.current
    if (targetElement instanceof HTMLElement) {
      targetElement.addEventListener('scroll', handleScroll)
    }

    return (): void => {
      if (targetElement instanceof HTMLElement) {
        targetElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return { scrollRef, isScrolled }
}
