import {
  Card,
  Flex,
  IconButton,
  LockIcon,
  PlusIcon,
  Text,
  Link,
  TrashIcon,
  UpgradeBadge,
  toast,
  Heading,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { FeedItemIcon } from '../Feed/FeedIcon'
import { FreeFeedsLimitReachedDialog } from '../FreeFeedsLimitReachedDialog/FreeFeedsLimitReachedDialog'
import { HardLimitReachedDialog } from '../HardLimitReachedDialog/HardLimitReachedDialog'
import styles from './SelectFeedCards.module.css'
import type { WrappedCreateFeedResponse } from '../../hooks/useCreateFeed'

type NewFeedOption = {
  id?: string
  title: string
  url: string
  thumbnailUrl?: string
  link?: string
  loading?: boolean
  exists: boolean
}

type ExistingFeedOption = {
  id: string
  title: string
  url: string
  thumbnailUrl?: string
  link?: string
  loading?: boolean
  exists: true
}

type FeedOption = NewFeedOption | ExistingFeedOption

const isExistingFeed = (feedItem: FeedOption): feedItem is ExistingFeedOption =>
  feedItem.exists

const FeedCard = ({
  feedItem,
  tier,
  limitReached,
  onAdd,
  onRemove,
  onUpgrade,
}: {
  feedItem: FeedOption
  tier: 'free' | 'paid'
  limitReached: boolean
  onAdd: (args: { feedItem: NewFeedOption }) => void
  onRemove: (args: { feedItem: ExistingFeedOption }) => void
  onUpgrade?: () => void
}): JSX.Element => {
  const thumbnailUrl = useMemo(() => {
    if (feedItem.thumbnailUrl) {
      return `https://images.buffer.com/proxy/100x100/${feedItem.thumbnailUrl}`
    }
    return undefined
  }, [feedItem.thumbnailUrl])

  return (
    <div className={styles.cardContainer}>
      <Card
        className={clsx(styles.feedCard)}
        data-exists={feedItem.exists}
        key={feedItem.url}
      >
        <FeedItemIcon
          feedName={feedItem.title}
          url={feedItem.url}
          thumbnailUrl={thumbnailUrl}
          className={styles.feedIcon}
          size={40}
        />
        <Flex
          direction="column"
          justify="start"
          align="start"
          // gap="xs"
          className={styles.feedTitleContainer}
        >
          <Text size="md" className={styles.feedTitle} weight="bold" truncate>
            {feedItem.link ? (
              <Link
                href={feedItem.link}
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
              >
                {feedItem.title}
              </Link>
            ) : (
              feedItem.title
            )}
          </Text>

          <Text size="sm" color="subtle" className={styles.feedUrl} truncate>
            {feedItem.url}
          </Text>
        </Flex>
        <Flex>
          <HardLimitReachedDialog
            type="feeds"
            enabled={!feedItem.exists && limitReached && tier === 'paid'}
          >
            <FreeFeedsLimitReachedDialog
              onUpgrade={onUpgrade}
              enabled={!feedItem.exists && limitReached && tier === 'free'}
            >
              {isExistingFeed(feedItem) ? (
                <IconButton
                  variant="critical-subtle"
                  label="Remove feed"
                  disabled={feedItem.loading}
                  loading={feedItem.loading}
                  className={styles.removeFeedButton}
                  onClick={(): void => onRemove({ feedItem })}
                >
                  <TrashIcon />
                </IconButton>
              ) : (
                <IconButton
                  variant="tertiary"
                  label={
                    !limitReached
                      ? 'Add feed'
                      : tier === 'free'
                      ? 'Upgrade'
                      : 'Limit reached'
                  }
                  disabled={feedItem.loading}
                  loading={feedItem.loading}
                  onClick={(): void => {
                    if (!limitReached) {
                      onAdd({ feedItem })
                    }
                  }}
                >
                  {limitReached && tier === 'free' ? (
                    <UpgradeBadge size="xsmall" />
                  ) : limitReached && tier === 'paid' ? (
                    <LockIcon />
                  ) : (
                    <PlusIcon />
                  )}
                </IconButton>
              )}
            </FreeFeedsLimitReachedDialog>
          </HardLimitReachedDialog>
        </Flex>
      </Card>
    </div>
  )
}

export type SelectFeedCardsProps = {
  newFeeds: {
    title: string
    url: string
    thumbnailUrl?: string
  }[]
  existingFeeds: Array<{ url: string; id: string }>
  tier: 'free' | 'paid'
  limitReached: boolean
  className?: string
  onAddFeed: (args: { feedUrl: string }) => Promise<WrappedCreateFeedResponse>
  onRemoveFeed: (feedId: string) => Promise<void>
  onUpgrade?: () => void
}

export const SelectFeedCards = React.forwardRef<
  HTMLElement,
  SelectFeedCardsProps
>(
  (
    {
      newFeeds,
      existingFeeds,
      tier,
      limitReached,
      onAddFeed,
      onRemoveFeed,
      onUpgrade,
      className,
    },
    ref,
  ): JSX.Element => {
    const [feeds, setFeeds] = React.useState<FeedOption[]>(() => {
      return newFeeds.map((feed) => ({
        ...feed,
        loading: false,
        exists: false,
      }))
    })

    const feedOptions = React.useMemo(() => {
      return feeds.map((feed) => {
        const existingFeed = existingFeeds.find((f) => f.url === feed.url)
        if (existingFeed) {
          return {
            ...feed,
            id: existingFeed.id,
            loading: false,
            exists: true,
          }
        }
        return feed
      })
    }, [feeds, existingFeeds])

    const setIsLoading = (feedUrl: string, loading: boolean): void => {
      setFeeds((feeds) => {
        return feeds.map((feed) => {
          if (feed.url === feedUrl) {
            return {
              ...feed,
              loading,
            }
          }
          return feed
        })
      })
    }

    const handleAddFeed = async ({
      feedItem,
    }: {
      feedItem: NewFeedOption
    }): Promise<void> => {
      if (limitReached) return
      // Set loading state before triggering callback
      setIsLoading(feedItem.url, true)

      try {
        const { feedId, error } = await onAddFeed({
          feedUrl: feedItem.url,
        })
        if (error) {
          setIsLoading(feedItem.url, false)
          toast.error(
            <>
              <Heading size="xsmall">{error.title}</Heading>
              <Text>{error.description}</Text>
            </>,
          )
          return
        }
        // If successful, update feed
        setFeeds((feeds) => {
          return feeds.map((feed) => {
            if (feed.url === feedItem.url) {
              return {
                ...feed,
                id: feedId,
                loading: false,
                exists: true,
              }
            }
            return feed
          })
        })
      } catch (e) {
        console.error(e)
        setIsLoading(feedItem.url, false)
        toast.error('An error occurred while adding the feed')
      }
    }

    const handleRemoveFeed = async ({
      feedItem,
    }: {
      feedItem: ExistingFeedOption
    }): Promise<void> => {
      setIsLoading(feedItem.url, true)
      try {
        await onRemoveFeed(feedItem.id)
        // If successful, remove feed
        setFeeds((feeds) => {
          return feeds.map((feed) => {
            if (feed.url === feedItem.url) {
              return {
                ...feed,
                loading: false,
                exists: false,
              }
            }
            return feed
          })
        })
      } catch (e) {
        console.error(e)
        setIsLoading(feedItem.url, false)
      }
    }

    return (
      <section
        aria-label="Select feeds"
        className={clsx(styles.feedList, className)}
        ref={ref}
      >
        {feedOptions.map((item) => {
          return (
            <FeedCard
              key={item.url}
              feedItem={item}
              tier={tier}
              limitReached={limitReached}
              onAdd={handleAddFeed}
              onRemove={handleRemoveFeed}
              onUpgrade={onUpgrade}
            />
          )
        })}
      </section>
    )
  },
)

SelectFeedCards.displayName = 'SelectFeedCards'
