import { type LazyQueryExecFunction, useLazyQuery } from '@apollo/client'

import { PusherEvent, usePusherEvent } from '~publish/services/pusher'
import { graphql } from '~publish/gql'
import type {
  GetPostForRealTimeUpdateQuery,
  GetPostListForRealTimeUpdateQuery,
  GetPostListForRealTimeUpdateQueryVariables,
  GetPostListQuery,
  GetPostListQueryVariables,
  PostsFiltersInput,
  RealTimeUpdates_PostFragment,
} from '~publish/gql/graphql'
import { wait } from '~publish/helpers/wait'
import { client } from '~publish/legacy/apollo-client'
import { isPostSatisfyingFilters } from '~publish/helpers/posts/isPostSatisfyingFilters'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { debugLog } from '~publish/helpers/debugLog'
import { useChannels } from '~publish/components/PublishSidebar/useChannels'

import { GetPostList, type SortPreset } from './usePaginatedPostList'

const RealTimeUpdates_Post = graphql(/* GraphQL */ `
  fragment RealTimeUpdates_Post on Post {
    id
    status
    dueAt
    tags {
      id
    }
    channel {
      id
    }
  }
`)

export const GetPostForRealTimeUpdate = graphql(/* GraphQL */ `
  query GetPostForRealTimeUpdate($postId: PostId!) {
    post(input: { id: $postId }) {
      id
      dueAt
      ...PostCard_Post
      ...RealTimeUpdates_Post
    }
  }
`)

export const GetPostListForRealTimeUpdate = graphql(/* GraphQL */ `
  query GetPostListForRealTimeUpdate(
    $first: Int!
    $after: String
    $organizationId: OrganizationId!
    $channelIds: [ChannelId!]
    $tagIds: [TagId!]
    $status: [PostStatus!]
    $sort: [PostSortInput!]
  ) {
    posts(
      input: {
        organizationId: $organizationId
        filter: { channelIds: $channelIds, tagIds: $tagIds, status: $status }
        sort: $sort
      }
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          dueAt
        }
      }
    }
  }
`)

/**
 * Pusher returns RpcUpdate data, but we only need the id
 * so we can use this id to refetch the post in the cache
 */
type PusherEventData = {
  update_id: string
  draft_id: string
  profile_id: string
}

const postEvents = [
  PusherEvent.POST_UPDATED,
  PusherEvent.POST_TAG_ADDED,
  PusherEvent.POST_TAG_REMOVED,
  PusherEvent.POST_NOTE_ADDED,
  PusherEvent.POST_NOTE_DELETED,
  PusherEvent.POST_NOTE_UPDATED,
  PusherEvent.DRAFT_UPDATED,
  PusherEvent.POST_SENT,
  PusherEvent.POST_FAILED,
  PusherEvent.DRAFT_MOVED,
  PusherEvent.DRAFT_APPROVED,
  PusherEvent.POST_CREATED,
]

/**
 * Evicts post from cache if it exists
 * @param id - The ID of the post to evict
 */
const evictPost = (id: string): void => {
  const normalizedId = client.cache.identify({
    id,
    __typename: 'Post',
  })

  // it is important to check that post exists in cache
  // if we try to evict non-existing post, queries that depend on it will reload
  const existingPost = client.cache.readFragment({
    id: normalizedId,
    fragment: graphql(/* GraphQL */ `
      fragment Post_evicting on Post {
        id
      }
    `),
  })

  if (!existingPost) {
    return
  }

  client.cache.evict({ id: normalizedId })
}

/**
 * Highlights post as being removed
 * @param id - The ID of the post to highlight
 */
const highlightPostAsBeingRemoved = async (id: string): Promise<void> => {
  const normalizedId = client.cache.identify({
    id,
    __typename: 'Post',
  })

  if (!normalizedId) {
    return
  }

  client.cache.writeFragment({
    id: normalizedId,
    fragment: graphql(/* GraphQL */ `
      fragment Post_isProcessing on Post {
        isProcessing @client
      }
    `),
    data: {
      isProcessing: true,
    },
  })

  // next tick, to make sure apollo cache is updated and there is time
  // to trigger react update before refetching
  await wait(0)
}

/**
 * Removes highlight from post
 * @param id - The ID of the post to remove highlight from
 */
const removePostHighlight = (id: string): void => {
  const normalizedId = client.cache.identify({
    id,
    __typename: 'Post',
  })

  if (!normalizedId) {
    return
  }

  client.cache.writeFragment({
    id: normalizedId,
    fragment: graphql(/* GraphQL */ `
      fragment Post_isProcessing on Post {
        isProcessing @client
      }
    `),
    data: {
      isProcessing: false,
    },
  })
}

type UseRealTimePusherUpdatesOptions = {
  filters: PostsFiltersInput
  refetch: (options?: { mode?: 'remove' | 'insert' }) => Promise<void>
  isPostVisible: (id: string) => boolean
  variables?: GetPostListQueryVariables
  sort: SortPreset
}

/**
 * Hook that handles real-time updates to posts via Pusher events
 * Manages post visibility, ordering, and cache updates based on various events
 */
export const useRealTimePusherUpdates = ({
  filters,
  isPostVisible,
  refetch,
  variables,
  sort,
}: UseRealTimePusherUpdatesOptions): void => {
  const organizationId = useOrganizationId()
  const { channels } = useChannels()
  const [getPost] = useLazyQuery(GetPostForRealTimeUpdate, {
    fetchPolicy: 'network-only',
  })
  const [getPostList] = useLazyQuery(GetPostListForRealTimeUpdate)

  /**
   * Updates a post's data and manages its visibility in the list
   * @param id - The ID of the post to update
   */
  const updatePost = async (id: string): Promise<void> => {
    const existingPost = client.cache.readFragment({
      id: client.cache.identify({
        __typename: 'Post',
        id,
      }),
      fragment: RealTimeUpdates_Post,
    })
    const wasPostVisibleBeforeUpdate = isPostVisible(id)

    // fetching one post to get its data after update
    const { data } = await getPost({
      variables: {
        postId: id,
      },
    })

    // compare to updated and refetch if status or due date has changed
    const updatedPost = data?.post
    if (!updatedPost) {
      return
    }

    const { satisfies: shouldUpdatedPostBeVisible } = isPostSatisfyingFilters(
      updatedPost as RealTimeUpdates_PostFragment,
      filters,
    )

    // case 1: post was visible but should not be visible anymore after update (due to filters)
    // -> highlight post as being removed, refetch the list and remove highlight
    if (wasPostVisibleBeforeUpdate && !shouldUpdatedPostBeVisible) {
      await highlightPostAsBeingRemoved(id)
      await removePostFromCache(id)
      removePostHighlight(id)

      return
    }

    // case 2: post was not visible before, but should be visible now
    // -> refetch the list, because order is not clear
    if (!wasPostVisibleBeforeUpdate && shouldUpdatedPostBeVisible) {
      insertNewPostInCache(updatedPost, variables, sort)

      return
    }

    // case 3: post was visble but its due date was changed which impacts its order in the list
    // -> refetch the list, because order is likely to change
    if (
      wasPostVisibleBeforeUpdate &&
      updatedPost &&
      existingPost &&
      existingPost?.dueAt !== updatedPost?.dueAt
    ) {
      updateCachedPost(id, data)
      updatePostListOrder(variables, sort)

      return
    }

    // case 4: all other cases, likely just post content has changed
    // -> update post data in apollo cache
    updateCachedPost(id, data)
  }

  /**
   * Checks if a channel exists on the fetched channels list
   * @param profileId - The ID of the profile
   * @returns True if the channel exists, false otherwise
   */
  // TODO: this is a temporary solution, because pusher events are sent to all
  // users of the organization regardless of which channels they have access to
  // we should update pusher events to be more specific to the user
  const isChannelAllowed = (profileId?: string): boolean => {
    return (
      profileId !== undefined &&
      channels.find((c) => c.id === profileId) !== undefined
    )
  }

  usePusherEvent(
    PusherEvent.POST_DELETED,
    async (data: PusherEventData): Promise<void> => {
      debugLog('💽 POST_DELETED', data)
      const id = data.update_id

      // highlight post as being removed, wait a bit and then remove it from cache
      await removePostFromCache(id)
    },
  )

  usePusherEvent(
    PusherEvent.POSTS_REORDERED,
    async (data?: {
      profile_id?: string
      update_ids?: Array<string>
    }): Promise<void> => {
      debugLog('💽 POSTS_REORDERED', data)
      const hasChanges = Number(data?.update_ids?.length) > 0
      const isProfileIncluded =
        // no filters means all channles are included
        filters?.channelIds?.length === 0 ||
        filters?.channelIds?.includes(data?.profile_id ?? '')
      if (
        !hasChanges ||
        !isProfileIncluded ||
        !isChannelAllowed(data?.profile_id)
      ) {
        return
      }

      if (data?.update_ids?.length === 1) {
        updatePost(data.update_ids[0])
      } else {
        if (!organizationId) return
        fetchPostListOrder(getPostList, organizationId, variables)
      }
    },
  )

  usePusherEvent(
    PusherEvent.QUEUE_CHANGED,
    async (data?: { profile_id?: string }): Promise<void> => {
      debugLog('💽 QUEUE_CHANGED', data)

      const isProfileIncluded =
        // no filters means all channles are included
        filters?.channelIds?.length === 0 ||
        filters?.channelIds?.includes(data?.profile_id ?? '')
      if (!isProfileIncluded) {
        return
      }

      refetch()
    },
  )

  usePusherEvent(postEvents, (data: PusherEventData): void => {
    debugLog('💽 POST_EVENTS', data)

    if (!isChannelAllowed(data?.profile_id)) {
      return
    }
    updatePost(data.update_id ?? data.draft_id)
  })
}

interface SortablePost {
  node: {
    dueAt?: string | null
    createdAt?: string | null
  }
}

// Create a sorting function for posts based on the sort parameter
function getSortFunction(
  sort: 'upcomingFirst' | 'mostRecentlyPostedFirst',
): (a: SortablePost, b: SortablePost) => number {
  return sort === 'upcomingFirst'
    ? (a: SortablePost, b: SortablePost): number =>
        (a.node.dueAt ?? '').localeCompare(b.node.dueAt ?? '') ||
        (b.node.createdAt ?? '').localeCompare(a.node.createdAt ?? '')
    : (a: SortablePost, b: SortablePost): number =>
        (b.node.dueAt ?? '').localeCompare(a.node.dueAt ?? '') ||
        (b.node.createdAt ?? '').localeCompare(a.node.createdAt ?? '')
}

/**
 * Removes a post from the Apollo cache with visual feedback
 * @param id - The ID of the post to remove
 */
async function removePostFromCache(id: string): Promise<void> {
  await highlightPostAsBeingRemoved(id)
  await wait(500)
  evictPost(id)
}

/**
 * Inserts a new post into the Apollo cache while maintaining sort order
 * @param updatedPost - The post to insert
 * @param variables - Variables for the post list query
 * @param sort - Sorting function for the post list
 */
function insertNewPostInCache(
  updatedPost: GetPostForRealTimeUpdateQuery['post'],
  variables?: GetPostListQueryVariables,
  sort: SortPreset = 'upcomingFirst',
): void {
  const existingData = client.cache.readQuery<GetPostListQuery>({
    query: GetPostList,
    variables,
  })

  if (!existingData?.posts.edges) {
    // If there are no edges, the query was never made, so we don't need to update the cache
    return
  }
  const newPost = {
    __typename: 'PostsEdge' as const,
    node: {
      __typename: 'Post' as const,
      ...updatedPost,
    },
  }

  client.cache.writeQuery({
    query: GetPostList,
    variables,
    data: {
      ...existingData,
      posts: {
        ...existingData.posts,
        edges: [...existingData.posts.edges, newPost].sort(
          getSortFunction(sort),
        ),
      },
    },
  })
}

/**
 * Updates a post's data in the Apollo cache
 * @param id - The ID of the post to update
 * @param data - The new post data
 */
function updateCachedPost(
  id: string,
  data: GetPostForRealTimeUpdateQuery | undefined,
): void {
  client.cache.writeQuery({
    query: GetPostForRealTimeUpdate,
    variables: {
      postId: id,
    },
    data,
  })
}

/**
 * Updates the order of posts in the list based on their due dates
 * @param variables - Variables for the post list query
 */
function updatePostListOrder(
  variables?: GetPostListQueryVariables,
  sort: SortPreset = 'upcomingFirst',
): void {
  const existingData = client.cache.readQuery<GetPostListQuery>({
    query: GetPostList,
    variables,
  })

  if (existingData?.posts.edges) {
    client.cache.writeQuery({
      query: GetPostList,
      variables,
      data: {
        ...existingData,
        posts: {
          ...existingData.posts,
          edges: [...existingData.posts.edges].sort(getSortFunction(sort)),
        },
      },
    })
  }
}

/**
 * Fetches updated post list order from the server with only the id and dueAt
 * for each post and updates the cache
 * @param getPostList - Function to fetch the post list
 * @param organizationId - The ID of the organization
 * @param variables - Variables for the post list query
 */
async function fetchPostListOrder(
  getPostList: LazyQueryExecFunction<
    GetPostListForRealTimeUpdateQuery,
    GetPostListForRealTimeUpdateQueryVariables
  >,
  organizationId: string,
  variables?: GetPostListQueryVariables,
  sort: SortPreset = 'upcomingFirst',
): Promise<void> {
  const existingData = client.cache.readQuery<GetPostListQuery>({
    query: GetPostList,
    variables,
  })

  // Refetch dueAt for all posts in the cache
  const { data: updatedData } = await getPostList({
    variables: {
      ...variables,
      first: (existingData?.posts.edges?.length ?? 0) + 1,
      organizationId,
    },
    fetchPolicy: 'network-only',
  })

  if (!existingData?.posts.edges) {
    // If there are no edges, the query was never made, so we don't need to update the cache
    return
  }

  const updatedDueAtMap =
    updatedData?.posts.edges?.reduce<Record<string, string | null | undefined>>(
      (acc, edge) => {
        acc[edge.node.id] = edge.node.dueAt
        return acc
      },
      {},
    ) ?? {}

  const newEdges = existingData.posts.edges
    .map((edge) => {
      if (updatedDueAtMap[edge.node.id]) {
        return {
          ...edge,
          node: {
            ...edge.node,
            dueAt: updatedDueAtMap[edge.node.id],
          },
        }
      }
      return edge
    })
    .sort(getSortFunction(sort))

  client.cache.modify({
    id: client.cache.identify({
      id: existingData.posts.edges[0].node.id,
      __typename: 'Post',
    }),
    fields: {
      edges: () => newEdges,
    },
  })
}
