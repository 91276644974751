import { useProductHistory } from '../../exports/Navigator/utils/useProductHistory'

/**
 * Use this component to navigate to a different app. If history is available, it will use the history API to navigate.
 * Otherwise, it will use window.location.assign.
 *
 * @example
 * ```tsx
 * const navigateCrossApp = useCrossAppNavigate()
 * navigateCrossApp('https://publish.buffer.com')
 * ```
 */
export function useCrossAppNavigate(): (href: string) => void {
  const { history } = useProductHistory()

  const navigateCrossApp = (href: string): void => {
    const url = new URL(href)
    const origin = url.origin
    const pathname = url.pathname

    if (history && window.location.origin === origin) {
      try {
        return history?.push(pathname)
      } catch (error) {
        // Do nothing, will fallback to window.location.assign
      }
    }
    window.location.assign(href)
  }

  return navigateCrossApp
}
