import { ChannelIcon, Text } from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'
import type { Service } from '~publish/gql/graphql'
import { capitalize } from '~publish/helpers/capitalize'
import styles from './ChannelServiceTag.module.css'

export const ChannelServiceTag = ({
  service,
}: {
  service: Service
}): JSX.Element => {
  return (
    <Text
      key={service}
      className={clsx(styles.channelTag)}
      data-channel={service}
    >
      <ChannelIcon type={service as Service} color="inverted" size={14} />
      {capitalize(service)}
    </Text>
  )
}
