import * as React from 'react'

export const StreakBackgroundAtRisk = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={212}
    height={67}
    fill="none"
    viewBox="0 0 212 67"
    aria-hidden="true"
    {...props}
  >
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <rect
          width={212}
          height={212}
          x={212}
          y={-64}
          fill="#FFD889"
          rx={5}
          transform="rotate(90 212 -64)"
        />
        <rect
          width={347.766}
          height={179.258}
          x={233.881}
          y={-52.382}
          fill="#FFEFD0"
          rx={5}
          transform="rotate(135 233.881 -52.382)"
        />
        <rect
          width={347.766}
          height={54.327}
          x={145.541}
          y={-140.722}
          fill="#FFE8B9"
          rx={5}
          transform="rotate(135 145.541 -140.722)"
        />
        <circle
          cx={188.997}
          cy={24.282}
          r={75.226}
          fill="#FFE8B9"
          transform="rotate(90 188.997 24.282)"
        />
        <circle
          cx={188.996}
          cy={24.282}
          r={65.9}
          fill="#FFD889"
          transform="rotate(90 188.996 24.282)"
        />
        <circle
          cx={28.599}
          cy={90.182}
          r={78.956}
          fill="#FFF7E9"
          transform="rotate(90 28.599 90.182)"
        />
        <circle
          cx={28.598}
          cy={90.182}
          r={70.874}
          fill="#FFE8B9"
          transform="rotate(90 28.598 90.182)"
        />
        <rect
          width={189.354}
          height={97.603}
          x={92.634}
          y={-106.269}
          fill="#FFD889"
          rx={5}
          transform="rotate(135 92.634 -106.269)"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <rect width={212} height={67} fill="#fff" rx={8} />
      </clipPath>
      <clipPath id="b">
        <rect
          width={212}
          height={212}
          x={212}
          y={-64}
          fill="#fff"
          rx={5}
          transform="rotate(90 212 -64)"
        />
      </clipPath>
    </defs>
  </svg>
)
