import { useState, useEffect } from 'react'

/**
 * Hook that returns a boolean value that becomes true after a specified delay.
 * Useful for delaying UI updates, animations, or any action that needs to occur
 * after a certain amount of time has passed.
 *
 * @param {number} duration - The delay in milliseconds before the returned value becomes true
 * @returns {boolean} A boolean that is initially false and becomes true after the specified duration
 *
 * @example
 * // Show a component after 2 seconds
 * const isVisible = useDelay(2000);
 *
 * return (
 *   <div>
 *     {isVisible && <MyComponent />}
 *   </div>
 * );
 */
export const useDelay = (duration: number) => {
  const [delayedValue, setDelayedValue] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayedValue(true)
    }, duration)

    return () => {
      clearTimeout(timeout)
    }
  }, [duration])

  return delayedValue
}
