import React from 'react'

import Input from '../shared/Input'
import FooterField from '../shared/FooterField'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'

const VideoTitle = ({
  draftId,
  title,
}: {
  draftId: string
  title?: string
}): JSX.Element => {
  const saveTitle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    ComposerActionCreators.updateDraftVideoTitle(draftId, value)
  }

  return (
    <FooterField
      label="Video Title"
      ariaLabel="videoTitle"
      fieldContent={
        <Input
          aria-labelledby="videoTitle"
          type="text"
          onChange={saveTitle}
          name="videoTitle"
          placeholder="Video Title"
          value={title}
        />
      }
    />
  )
}

export default VideoTitle
