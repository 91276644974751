import React from 'react'
import { Button, CoachMark, NewBadge, Flex } from '@buffer-mono/popcorn'
import {
  type DismissableBanner,
  useDismissableBanner,
} from '~publish/hooks/useDismissableBanner'
import { useSelectedChannel } from './ChannelContext'

import styles from './PostingGoalCoachMark.module.css'

export const usePostingGoalCoachMark = (): DismissableBanner => {
  return useDismissableBanner('posting-goal-setup-coach-mark', {
    trackDismiss: true,
  })
}

/**
 * Coach mark component that highlights the "Set a posting goal" feature
 * for users who haven't set a posting goal yet.
 */
export const PostingGoalCoachMark = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const channel = useSelectedChannel()
  const coachMarkBanner = usePostingGoalCoachMark()

  // Only show the coach mark if the channel doesn't have a posting goal
  // and the coachMarkBanner hasn't been dismissed
  const shouldShowCoachMark = coachMarkBanner.isActive && !channel.postingGoal

  const handleSetPostingGoal = (): void => {
    // Open the posting goal configuration modal
    const { actions } = window.appshell ?? {}
    actions.openModal('postingGoalConfiguration', {
      channelId: channel.id,
    })
  }

  if (!shouldShowCoachMark) {
    return <>{children}</>
  }

  return (
    <CoachMark onDismiss={coachMarkBanner.dismiss}>
      <CoachMark.Overlay>
        <CoachMark.Spotlight
          size="small"
          style={{
            '--spotlight-top': '50%',
            '--spotlight-left': '0.22em',
          }}
        />
        {children}
      </CoachMark.Overlay>
      <CoachMark.Content
        side="right"
        align="start"
        showCloseIcon={false}
        style={
          {
            '--coach-mark-max-width': '340px',
            '--coach-mark-gap': 'var(--space-xs)',
          } as React.CSSProperties
        }
      >
        <Flex gap="xs" align="center">
          <NewBadge />
          <CoachMark.Title>Stay Consistent with a Posting Goal</CoachMark.Title>
        </Flex>
        <CoachMark.Description>
          We&apos;ll recommend goals based on Buffer data and best practices, to
          help you achieve your goal!
        </CoachMark.Description>
        <CoachMark.Footer className={styles.footer}>
          <CoachMark.Dismiss>
            <Button variant="tertiary">No, Thanks</Button>
          </CoachMark.Dismiss>
          <CoachMark.Dismiss>
            <Button onClick={handleSetPostingGoal}>Set Posting Goal</Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
