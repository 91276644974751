import React, { useEffect } from 'react'
import Pusher, { type Channel as PusherChannel } from 'pusher-js'
import { useCallbackRef } from '@buffer-mono/popcorn'

import { useOrganizationId } from '~publish/legacy/accountContext'
import { debugLog } from '~publish/helpers/debugLog'

// TODO: Move this to env var
const PUSHER_APP_KEY = '646fd82bc5706097c621'

/**
 * Initializes a new Pusher instance, it can be used to subscribe to channels
 * and listen to events.
 */
export const pusher = new Pusher(PUSHER_APP_KEY, {
  userAuthentication: {
    endpoint: '/pusher/auth',
    transport: 'ajax',
  },
  cluster: 'mt1',
})

/**
 * Enum of supported Pusher events
 */
export enum PusherEvent {
  POST_CREATED = 'added_update',
  POST_UPDATED = 'updated_update',
  POST_DELETED = 'deleted_update',
  POST_SENT = 'sent_update',
  POST_FAILED = 'failed_update',
  POSTS_REORDERED = 'reordered_updates',
  POST_TAG_ADDED = 'added_tagging',
  POST_TAG_REMOVED = 'deleted_tagging',
  DRAFT_MOVED = 'collaboration_draft_moved',
  DRAFT_UPDATED = 'collaboration_draft_updated',
  DRAFT_APPROVED = 'collaboration_draft_approved',
  POST_NOTE_ADDED = 'added_post_note',
  POST_NOTE_DELETED = 'deleted_post_note',
  POST_NOTE_UPDATED = 'updated_post_note',
  QUEUE_CHANGED = 'queue_changed',
  COMMENT_INGESTED = 'comment_ingested',
}

/**
 * Channel used to subscribe to organization updates
 */
export const subscribeToOrganizationChannel = (
  organizationId: string,
): PusherChannel => {
  return pusher.subscribe(`private-updates-org-${organizationId}`)
}

/**
 * Channel used to subscribe to user updates
 */
export const subscribeToChannelEventsChannel = (
  channelId: string,
): PusherChannel => {
  return pusher.subscribe(`private-updates-${channelId}`)
}

type PusherContextValue = {
  channel: PusherChannel | null
}

const PusherContext = React.createContext<PusherContextValue>({
  channel: null,
})

/**
 * Context provider that subscribes to the organization-level events channel
 */
export const PusherContextProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const organizationId = useOrganizationId()
  const [channel, setChannel] = React.useState<PusherChannel | null>(null)

  useEffect(() => {
    if (!organizationId) {
      return
    }

    const channelSubscription = subscribeToOrganizationChannel(organizationId)
    setChannel(channelSubscription)

    // clean up
    return (): void => {
      channelSubscription?.unsubscribe?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  useEffect(() => {
    if (!channel) {
      return
    }

    const cb = (event: string, data: unknown): void => {
      debugLog(`📣 [${event}]`, data)
    }

    channel.bind_global(cb)

    return (): void => {
      channel.unbind_global(cb)
    }
  }, [channel])

  return (
    <PusherContext.Provider value={{ channel }}>
      {children}
    </PusherContext.Provider>
  )
}

/**
 * Hook that listens to a Pusher event and calls the provided callback
 *
 * @example
 * ```tsx
 * usePusherEvent(PusherEvent.POST_CREATED, onCreatePost)
 * usePusherEvent([PusherEvent.POST_UPDATED, PusherEvent.POST_DELETED], onUpdatePost)
 * ```
 */
export const usePusherEvent = (
  event: PusherEvent | PusherEvent[],
  callback: (data: any) => void | Promise<void>,
): void => {
  const { channel } = React.useContext(PusherContext)
  const onEventEmitted = useCallbackRef(callback)

  useEffect(() => {
    if (!channel) {
      return
    }

    const events = Array.isArray(event) ? event : [event]

    events.forEach((e): void => {
      channel.bind(e, onEventEmitted)
    })

    return (): void => {
      events.forEach((e): void => {
        channel?.unbind?.(e, onEventEmitted)
      })
    }
  }, [onEventEmitted, channel, event])
}
