import {
  AllChannelsIcon,
  BoardIcon,
  Button,
  CalendarFilledIcon,
  ChevronDownIcon,
  Collapsible,
  Flex,
  GalleryIcon,
  IconButton,
  LogOutIcon,
  Tag,
  TagIcon,
  Text,
} from '@buffer-mono/popcorn'
import { UploadSource } from '@buffer-mono/uploader'
import { Temporal } from '@js-temporal/polyfill'
import clsx from 'clsx'
import { formatDate } from 'date-fns'
import React from 'react'
import { TagsCombobox } from '~publish/components/TagsCombobox'
import { channelServices } from '~publish/helpers/channels'
import { useTags } from '~publish/hooks/useTags'
import { useIntegrationServices } from '~publish/legacy/integrations-bar'
import { UNASSIGNED_GROUP_ID } from '~publish/pages/Create/components/Board/helpers/helpers'
import { useIdeaComposerState } from '../../IdeaComposerContext'
import {
  ActiveIntegrationButton,
  AssetManager,
  IntegrationsDropdown,
} from '../AssetManager/AssetManager'
import { ChannelSelector } from '../ChannelSelector'
import { DatePicker } from '../DatePicker'
import { GroupSelector } from '../GroupSelector'
import styles from './PropertyFields.module.css'
import { useTimezone } from '~publish/hooks/useTimezone'

export const PropertyFields = (): JSX.Element => {
  const timeZone = useTimezone()
  const {
    addFiles,
    uploader,
    setGroupId,
    setSelectedTags,
    setChannelServices,
    setDate,
  } = useIdeaComposerState()

  const { services: integrationServices, activeService } =
    useIntegrationServices({
      uploader,
      context: 'ideasEditor',
    })

  const [isCollapsed, setIsCollapsed] = React.useState(false)
  const { draftIdea } = useIdeaComposerState()
  const { tags: allTags } = useTags()

  const tags = draftIdea?.content?.tags ?? []

  const handleTagChange = (tagIds: string[]): void => {
    setSelectedTags(
      tagIds.map((id) => {
        const tag = allTags.find((t) => t.id === id)
        return { id, name: tag?.name ?? '', color: tag?.color ?? '' }
      }),
    )
  }

  const handleDateChange = (date: Temporal.PlainDate | undefined): void => {
    setDate(date ? new Date(date.toString()).toISOString() : undefined)
  }

  const uploadInputId = React.useId()
  const uploadInputRef = React.useRef<HTMLInputElement>(null)

  const handleUploadClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.stopPropagation()
    uploadInputRef.current?.click()
  }

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const files: File[] = Array.from(event.target.files ?? [])
    if (!files.length) {
      return
    }
    addFiles(files, UploadSource.filePicker())
  }

  return (
    <Collapsible open={!isCollapsed} onOpenChange={setIsCollapsed}>
      {isCollapsed && (
        <Collapsible.Trigger>
          <IconButton variant="tertiary" label="Expand">
            <ChevronDownIcon />
          </IconButton>
        </Collapsible.Trigger>
      )}
      <Collapsible.Content>
        <Flex direction="column" gap="xs" className={styles.customFields}>
          <Flex
            key="group"
            direction="row"
            align="center"
            className={styles.fieldRow}
          >
            <Text color="subtle" className={styles.fieldLabel}>
              <BoardIcon />
              Group
            </Text>
            <GroupSelector
              className={styles.fieldValue}
              selectedGroupId={draftIdea?.groupId ?? UNASSIGNED_GROUP_ID}
              onGroupSelect={setGroupId}
            />
          </Flex>
          <Flex
            key="date"
            direction="row"
            align="center"
            className={styles.fieldRow}
          >
            <Text color="subtle" className={styles.fieldLabel}>
              <CalendarFilledIcon />
              Date
            </Text>
            <DatePicker
              value={
                draftIdea?.content?.date
                  ? Temporal.Instant.from(draftIdea.content.date)
                      .toZonedDateTimeISO(timeZone)
                      .toPlainDate()
                  : undefined
              }
              onValueChange={handleDateChange}
            >
              <Button variant="secondary" className={styles.fieldValue}>
                {draftIdea?.content?.date ? (
                  <Text weight="medium">
                    {formatDate(draftIdea?.content?.date, 'MMM d, yyyy')}
                  </Text>
                ) : (
                  <Text color="subtle">Empty</Text>
                )}
              </Button>
            </DatePicker>
          </Flex>

          <Flex
            key="tags"
            direction="row"
            align="center"
            className={styles.fieldRow}
          >
            <Text color="subtle" className={styles.fieldLabel}>
              <TagIcon />
              Tags
            </Text>
            <TagsCombobox
              selectedTags={tags.map((tag) => tag.id) ?? []}
              onChange={handleTagChange}
              align="start"
            >
              <Button variant="secondary" className={styles.fieldValue}>
                {tags.length > 0 ? (
                  <Flex gap="xs" wrap="wrap" className={styles.selectedTags}>
                    {tags.map((tag) => (
                      <Tag key={tag.id} color={tag.color}>
                        {tag.name}
                      </Tag>
                    ))}
                  </Flex>
                ) : (
                  <Text color="subtle">Empty</Text>
                )}
              </Button>
            </TagsCombobox>
          </Flex>
          <Flex
            key="channels"
            direction="row"
            align="center"
            className={styles.fieldRow}
          >
            <Text color="subtle" className={styles.fieldLabel}>
              <AllChannelsIcon />
              Channels
            </Text>
            <ChannelSelector
              services={channelServices}
              selectedServices={draftIdea?.content?.services ?? []}
              onChange={setChannelServices}
              className={styles.fieldValue}
            />
          </Flex>
          <Flex
            key="Assets"
            direction="row"
            align="center"
            className={clsx(styles.fieldRow)}
          >
            <Text color="subtle" className={styles.fieldLabel}>
              <GalleryIcon />
              Assets
            </Text>
            <Flex
              align="center"
              className={clsx(styles.fieldValue, styles.assetField)}
            >
              <AssetManager uploader={uploader} />
              <ActiveIntegrationButton services={integrationServices}>
                <IconButton
                  label="Upload"
                  variant="tertiary"
                  size="small"
                  onClick={handleUploadClick}
                >
                  <LogOutIcon
                    className={styles.sideways}
                    data-icon={undefined}
                  />
                </IconButton>
              </ActiveIntegrationButton>
              <IntegrationsDropdown
                // open={isIntegrationsDropdownOpen}
                // onOpenChange={setIsIntegrationsDropdownOpen}
                services={integrationServices}
                activeService={activeService}
                onFilesAdded={addFiles}
              >
                <Flex justify="between" align="center" gap="xs">
                  <IconButton
                    label="Integrations"
                    variant="tertiary"
                    size="small"
                  >
                    <ChevronDownIcon />
                  </IconButton>
                </Flex>
              </IntegrationsDropdown>
              <input
                key={uploadInputId}
                hidden
                multiple
                type="file"
                ref={uploadInputRef}
                onClick={(event): void => event.stopPropagation()}
                onChange={handleFileChange}
              />
            </Flex>
          </Flex>
        </Flex>
      </Collapsible.Content>
    </Collapsible>
  )
}
