import React, { forwardRef, type InputHTMLAttributes } from 'react'
import styles from './css/Input.module.css'

const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ className = '', onChange = (): void => {}, ...restProps }, ref) => {
  return (
    <input
      ref={ref}
      className={`${styles.input} ${className}`}
      onChange={onChange}
      {...restProps}
    />
  )
})

Input.displayName = 'Input'

export default Input
