import React from 'react'

import { Dialog, Flex } from '@buffer-mono/popcorn'

import styles from './Footer.module.css'

export function Footer({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <Flex direction="column" align="baseline" className={styles.wrapper}>
      <Dialog.Separator />
      <Dialog.Footer className={styles.footer}>{children}</Dialog.Footer>
    </Flex>
  )
}
