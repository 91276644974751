import React from 'react'
import { AudienceIcon, DropdownMenu, Flex, Text } from '@buffer-mono/popcorn'

import useOrgSwitcher from '../../../../../common/hooks/useOrgSwitcher'

import styles from './OrganizationsSwitcher.module.css'
import { useUser } from '../../../../../common/context/User'
import { QUERY_ACCOUNT } from '../../../../../common/graphql/account'
import { pluralizeWithCount } from '../../../../../common/utils/copy'
import type { Organization } from '../../../../../common/types'

const OrganizationListItem = ({
  org,
  onSelect,
}: {
  org: Organization
  onSelect: (organizationId: string) => void
}): JSX.Element => {
  const user = useUser()
  const userEmail = user?.email

  return (
    <DropdownMenu.RadioItem
      key={org.id}
      value={org.id || ''}
      onSelect={(): void => {
        if (org.id) onSelect(org.id)
      }}
    >
      <Flex gap="2xs" direction="column">
        <Flex align="center" gap="xs">
          {org.name}{' '}
          {userEmail !== org?.ownerEmail && <AudienceIcon size="xsmall" />}
        </Flex>
        <Text as="small" color="subtle" size="sm" lineHeight="tight">
          {pluralizeWithCount(
            org?.channels?.length || 0,
            'channel',
            'channels',
          )}
          ,{' '}
          {pluralizeWithCount(
            org.members?.totalCount || 0,
            'member',
            'members',
          )}
        </Text>
      </Flex>
    </DropdownMenu.RadioItem>
  )
}

export function OrganizationsSwitcher(): JSX.Element | null {
  const user = useUser()
  const currentOrganization = user?.currentOrganization
  const organizations = user?.organizations

  const switchOrganization = useOrgSwitcher()

  const selectOrganization = (organizationId: string): void => {
    switchOrganization(organizationId, {
      refetchQueries: [QUERY_ACCOUNT],
      onCompleted: () => {
        if (window.location.pathname.startsWith('/settings')) {
          window.location.reload()
          return
        }
        window.location.href = '/'
      },
    })
  }

  if (!organizations || organizations.length <= 1) {
    return null
  }

  const shouldShowFlatList = organizations?.length <= 2

  if (shouldShowFlatList) {
    return (
      <DropdownMenu.Group>
        <DropdownMenu.Separator />
        <DropdownMenu.RadioGroup
          aria-label="Choose organization"
          value={currentOrganization?.id}
        >
          {organizations.map((org) => (
            <OrganizationListItem
              key={org.id}
              org={org}
              onSelect={selectOrganization}
            />
          ))}
        </DropdownMenu.RadioGroup>
      </DropdownMenu.Group>
    )
  }

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>
        {currentOrganization?.name}
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubMenu className={styles.menu}>
        <DropdownMenu.Label className={styles.subMenuLabel}>
          Organizations
        </DropdownMenu.Label>
        <DropdownMenu.Separator />

        <DropdownMenu.RadioGroup
          aria-label="Choose organization"
          value={currentOrganization?.id}
          className={styles.menuList}
        >
          {organizations.map((org) => (
            <OrganizationListItem
              key={org.id}
              org={org}
              onSelect={selectOrganization}
            />
          ))}
        </DropdownMenu.RadioGroup>
      </DropdownMenu.SubMenu>
    </DropdownMenu.Sub>
  )
}
