import styled from 'styled-components'
import { grayDark, gray, white } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  color: ${grayDark};
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconWrapper = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  background-color: ${({ color }): string => color || gray};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: ${white};
    color: ${white};
    width: 8px;
  }
`
