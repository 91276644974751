import React, { useCallback } from 'react'

import {
  Button,
  CloseIcon,
  DropdownMenu,
  Flex,
  IconButton,
  ScrollArea,
  Text,
} from '@buffer-mono/popcorn'

import { useTimeFormatPreference } from '~publish/hooks/useTimeFormatPreference'

import { convertTo24HourTime } from '../../../utils'

import styles from './ScheduleTableCell.module.css'

const twelveHours = Array.from({ length: 12 }, (_, i) =>
  (i + 1).toString().padStart(2, '0'),
)
const twentyFourHours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, '0'),
)
const minutes = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, '0'),
)

type ScheduleTableCellProps = {
  time: {
    hours: number
    minutes: number
  }
  disabled?: boolean
  onRemoveTimeClick: () => void
  onUpdateTime: (newTime: { hours: number; minutes: number }) => void
}

const ScheduleTableCell = ({
  time,
  disabled = false,
  onRemoveTimeClick,
  onUpdateTime,
}: ScheduleTableCellProps): JSX.Element => {
  const is24Hours = useTimeFormatPreference() === '24'
  const displayHours = is24Hours ? twentyFourHours : twelveHours

  const handleUpdateTime = useCallback(
    (newTime: { hours: number; minutes: number; amPm?: 'AM' | 'PM' }): void => {
      onUpdateTime(
        convertTo24HourTime({
          hours: newTime.hours,
          minutes: newTime.minutes,
          amPm: is24Hours ? undefined : newTime.amPm,
        }),
      )
    },
    [onUpdateTime, is24Hours],
  )

  // NOTE: We're deliberately checking that the hour DOES NOT match 24 here
  // as 24 equates to 00:00, so is not larger than 12, so should not be PM.
  // This is to fix a bug where it was possible to save 24:00 as a time.
  const amPm = time.hours >= 12 && time.hours !== 24 ? 'PM' : 'AM'
  const hour = is24Hours ? time.hours : Number(time.hours) % 12 || 12

  if (disabled)
    return (
      <Flex justify="center" align="center" className={styles.cell}>
        <Flex align="center" justify="center">
          <Text className={styles.disabledLabel}>
            {hour.toString().padStart(2, '0')}:
            {time.minutes.toString().padStart(2, '0')} {is24Hours ? '' : amPm}
          </Text>
        </Flex>
      </Flex>
    )

  return (
    <Flex justify="center" align="center" className={styles.cell}>
      <Flex align="center" justify="center">
        <DropdownMenu>
          <DropdownMenu.Trigger>
            <Button size="small" variant="tertiary" className={styles.label}>
              {hour.toString().padStart(2, '0')}
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className={styles.dropdown}>
            <ScrollArea>
              {displayHours.map((hour) => (
                <DropdownMenu.Item
                  onClick={async (): Promise<void> => {
                    handleUpdateTime({
                      hours: Number(hour),
                      minutes: time.minutes,
                      amPm,
                    })
                  }}
                  key={hour}
                >
                  {hour}
                </DropdownMenu.Item>
              ))}
            </ScrollArea>
          </DropdownMenu.Content>
        </DropdownMenu>
        :
        <DropdownMenu>
          <DropdownMenu.Trigger>
            <Button size="small" variant="tertiary" className={styles.label}>
              {time.minutes.toString().padStart(2, '0')}
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className={styles.dropdown}>
            <ScrollArea>
              {minutes.map((minute) => (
                <DropdownMenu.Item
                  onClick={async (): Promise<void> => {
                    handleUpdateTime({
                      hours: time.hours,
                      minutes: Number(minute),
                      amPm,
                    })
                  }}
                  key={minute}
                >
                  {minute}
                </DropdownMenu.Item>
              ))}
            </ScrollArea>
          </DropdownMenu.Content>
        </DropdownMenu>
      </Flex>
      {!is24Hours && (
        <DropdownMenu>
          <DropdownMenu.Trigger>
            <Button size="small" variant="tertiary" className={styles.label}>
              {amPm}
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className={styles.dropdown}>
            {['AM' as const, 'PM' as const].map((amPm) => (
              <DropdownMenu.Item
                onClick={async (): Promise<void> => {
                  handleUpdateTime({
                    hours: time.hours,
                    minutes: time.minutes,
                    amPm,
                  })
                }}
                key={amPm}
              >
                {amPm}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu>
      )}
      <IconButton
        size="small"
        label="Remove time"
        variant="tertiary"
        onClick={async (): Promise<void> => {
          onRemoveTimeClick()
        }}
        className={styles.removeButton}
        data-testid="schedule-table-cell-remove-button"
      >
        <CloseIcon />
      </IconButton>
    </Flex>
  )
}

export { ScheduleTableCell }
