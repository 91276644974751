import { Tooltip } from '@buffer-mono/popcorn'
import React, { Component } from 'react'
import { Text, Button } from '@bufferapp/ui'
import Select from '@bufferapp/ui/Select'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'
import TrashIcon from '@bufferapp/ui/Icon/Icons/Trash'
import PencilIcon from '@bufferapp/ui/Icon/Icons/Pencil'
import { Divider } from '@buffer-mono/legacy-bufferapp-components'
import * as styles from './styles'
import type { HashtagGroup } from '../../types'

interface HashtagGroupItemProps {
  onInsertHashtagGroupClick: () => void
  onEditHashtagGroupClick: (group: HashtagGroup) => void
  onDeleteHashtagGroupClick: (group: HashtagGroup) => void
  numberOfHashtags: number
  group: HashtagGroup
}

class HashtagGroupItem extends Component<HashtagGroupItemProps> {
  state = {
    hover: false,
  }

  render(): React.ReactNode {
    const {
      numberOfHashtags,
      group,
      onInsertHashtagGroupClick,
      onEditHashtagGroupClick,
      onDeleteHashtagGroupClick,
    } = this.props
    const { hover } = this.state

    return (
      <>
        <styles.Card
          onMouseEnter={(): void => this.setState({ hover: true })}
          onMouseLeave={(): void => this.setState({ hover: false })}
          hover={hover}
        >
          <styles.TooltipWrapper>
            <Tooltip content={group.text} side="left">
              <div>
                <styles.Header>
                  <styles.NameWrapper>
                    <Text type="label">{group.name}</Text>
                  </styles.NameWrapper>
                  <Text color="gray" type="label" hover={hover}>
                    {numberOfHashtags}
                  </Text>
                </styles.Header>
                <styles.HashtagText hover={hover}>
                  {group.text}{' '}
                </styles.HashtagText>
              </div>
            </Tooltip>
          </styles.TooltipWrapper>
          {hover && (
            <styles.ButtonWrapper>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ label: any; type: string; onClick: () => v... Remove this comment to see the full error message */}
              <Button
                type="primary"
                size="small"
                label="Insert"
                onClick={onInsertHashtagGroupClick}
              />
              <Select
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                onSelectClick={(selectedItem): void =>
                  selectedItem.onItemClick()
                }
                label="Click me"
                icon={<MoreIcon />}
                type="primary"
                items={[
                  {
                    id: 'editHashtagGroup',
                    title: 'Edit Group',
                    icon: <PencilIcon color="gray" />,
                    onItemClick: () => onEditHashtagGroupClick(group),
                  },
                  {
                    id: 'deleteHashtagGroup',
                    title: 'Delete Group',
                    icon: <TrashIcon color="gray" />,
                    onItemClick: () => onDeleteHashtagGroupClick(group),
                  },
                ]}
                hasIconOnly
                xPosition="right"
                yPosition="bottom"
                hideSearch
              />
            </styles.ButtonWrapper>
          )}
        </styles.Card>
        <Divider marginTop="0" marginBottom="0" />
      </>
    )
  }
}

export default HashtagGroupItem
