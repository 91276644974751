import { gql, useApolloClient } from '@apollo/client'
import { useCallback, useEffect } from 'react'

const TTL_IN_MS = 5 * 60 * 1000

export enum UploadType {
  postAsset = 'postAsset',
  channelAvatar = 'channelAvatar',
  accountAvatar = 'accountAvatar',
}

export const isValidUploadType = (val: string): val is UploadType => {
  return val in UploadType
}

export type UserS3UploadSignatureResponse = {
  url: string
  key: string
  bucket: string
}

export type KnownBuckets =
  | 'buffer-media-uploads'
  | 'buffer-channel-avatars-bucket'
  | 'buffer-account-avatars-bucket'

export const GET_S3_SIGNATURE = gql`
  query s3PreSignedURL($input: S3PreSignedURLInput!) {
    s3PreSignedURL(input: $input) {
      url
      key
      bucket
    }
  }
`

export type S3PreSignedURLInput = {
  organizationId: string
  uploadType: UploadType
  fileName: string
  mimeType: string
}

export function useS3Signature(): ({
  organizationId,
  fileName,
  mimeType,
  uploadType,
}: S3PreSignedURLInput) => Promise<UserS3UploadSignatureResponse> {
  const client = useApolloClient()

  useEffect(() => {
    const interval = setInterval(() => {
      client.cache.evict({
        fieldName: 's3PreSignedURL',
      })
    }, TTL_IN_MS)

    return () => {
      client.cache.evict({
        fieldName: 's3PreSignedURL',
      })
      clearInterval(interval)
    }
  }, [client.cache])

  return useCallback(
    ({
      organizationId,
      fileName,
      mimeType,
      uploadType,
    }: S3PreSignedURLInput): Promise<UserS3UploadSignatureResponse> =>
      client
        .query({
          query: GET_S3_SIGNATURE,
          variables: {
            input: {
              organizationId,
              fileName,
              mimeType,
              uploadType,
            },
          },
        })
        .then((result) => {
          return result.data.s3PreSignedURL
        }),
    [client],
  )
}
