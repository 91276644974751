export const getFileNameFromPath = (path = ''): string => {
  if (!path) return ''

  // Check if it looks like a URL with a protocol
  const hasProtocol = /^[a-z]+:\/\//i.test(path)

  if (hasProtocol) {
    try {
      // If it has a protocol, parse it as a URL
      const url = new URL(path)

      // Get the pathname (without query parameters)
      const pathParts = url.pathname.split('/')
      const fileName = pathParts[pathParts.length - 1]

      // Decode URI components to handle special characters
      return decodeURIComponent(fileName)
    } catch (error) {
      // Fall back to the simpler method
    }
  }

  // If filename without any path, just return it
  if (!path.includes('/') && !path.includes('\\')) {
    return path
  }

  // Otherwise it's a path, so extract the filename part
  // Handle Windows-style paths
  if (path.includes('\\')) {
    const parts = path.split('\\')
    return parts[parts.length - 1]
  }

  // Handle Unix-style paths
  const parts = path.split('/')
  return parts[parts.length - 1]
}
