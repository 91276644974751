// This code is inspired by: https://codepen.io/Gthibaud/pen/ENzXbp
import React, { useEffect, useRef } from 'react'

/**
 * Confetti colors matching the design system
 */
const CONFETTI_COLORS = [
  '#2AFF28', // Green
  '#EFC905', // Yellow
  '#2AB6FF', // Blue
  '#EF0583', // Pink
  '#5E68C2', // Purple
  '#EAECFF', // Light Blue
  '#FF3363', // Red
  '#FB0', // Orange
]

/**
 * Properties of each confetti particle
 */
interface Particle {
  x: number
  y: number
  vx: number
  vy: number
  gravity: number
  friction: number
  size: number
  color: string
  rotation: number
  rotationSpeed: number
  shape: 'rect' | 'circle'
  wobble: number
  wobbleSpeed: number
  wobbleAngle: number
  initialX: number
  burstVelocity?: number
  opacity: number
}

/**
 * Props for the ConfettiAnimation component
 */
interface ConfettiAnimationProps {
  /** Optional CSS class name */
  className?: string
  /** Width of the canvas in pixels */
  width?: number
  /** Height of the canvas in pixels */
  height?: number
  /** Duration of the animation in milliseconds */
  duration?: number
  /** Delay before animation starts in milliseconds */
  startDelay?: number
}

/**
 * Renders a canvas-based confetti animation
 * Creates particles that fall from the top of the canvas with physics simulation
 * and fade out when reaching the bottom
 */
export const ConfettiAnimation: React.FC<ConfettiAnimationProps> = ({
  className,
  width = 735,
  height = 150,
  duration = 5000,
  startDelay = 300,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const particles = useRef<Particle[]>([])
  const animationFrameId = useRef<number>(0)
  const isFirstRender = useRef<boolean>(true)
  const fadeRange = 50 // Range in pixels where particles start to fade out
  const animationActive = useRef<boolean>(false)
  const timeoutId = useRef<number | null>(null)
  const startTimeoutId = useRef<number | null>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Set canvas dimensions
    canvas.width = width
    canvas.height = height

    // Initially, set animation as inactive until delay passes
    animationActive.current = false

    // Create confetti particles
    const particleCount = 20
    particles.current = []

    // Schedule animation start after delay
    startTimeoutId.current = window.setTimeout(() => {
      // Activate animation
      animationActive.current = true

      // Initialize particles
      initializeParticles()

      // Schedule animation end
      timeoutId.current = window.setTimeout(() => {
        animationActive.current = false
      }, duration)
    }, startDelay)

    /**
     * Creates initial set of particles, with special burst particles on first render
     */
    const initializeParticles = (): void => {
      for (let i = 0; i < particleCount; i++) {
        // Determine if this is a burst particle
        const isBurstParticle = isFirstRender.current && i < particleCount / 2

        const x = isBurstParticle
          ? width / 2 + (Math.random() * 40 - 20)
          : Math.random() * width

        const y = isBurstParticle ? height / 2 : -Math.random() * 100

        const particle: Particle = {
          x,
          y,
          initialX: x,
          vx: Math.random() * 4 - 2,
          vy: (Math.random() * 2 + 0.5) * 0.7,
          gravity: 0.05,
          friction: 0.98 + Math.random() * 0.01,
          size: Math.round(Math.random() * 10) + 5,
          color:
            CONFETTI_COLORS[Math.floor(Math.random() * CONFETTI_COLORS.length)],
          rotation: Math.random() * 360,
          rotationSpeed: (Math.random() * 2 - 1) * 0.5,
          shape: Math.random() > 0.5 ? 'rect' : 'circle',
          wobble: Math.random() * 0.05 - 0.025,
          wobbleSpeed: 0.05 + Math.random() * 0.05,
          wobbleAngle: 0,
          opacity: 1,
        }

        // Add burst properties if it's a burst particle
        if (isBurstParticle) {
          const angle = Math.random() * Math.PI * 2
          const burstVelocity = 3 + Math.random() * 5
          particle.vx = Math.cos(angle) * burstVelocity
          particle.vy = Math.sin(angle) * burstVelocity - 2 // Add upward bias
          particle.burstVelocity = burstVelocity
        }

        particles.current.push(particle)
      }

      // Mark that we've done the initial render
      isFirstRender.current = false
    }

    /**
     * Resets a particle to start from the top again or moves it off-screen
     * if the animation is no longer active
     */
    const resetParticle = (particle: Particle): void => {
      // Only reset if animation is still active
      if (!animationActive.current) {
        // If animation is no longer active, move particle off-screen
        particle.y = height + 100
        particle.opacity = 0
        return
      }

      particle.x = Math.random() * width
      particle.y = -Math.random() * 50 - 10
      particle.vx = Math.random() * 4 - 2
      particle.vy = Math.random() * 1
      particle.opacity = 1 // Reset opacity
      // Remove burst velocity once it's reset
      if (particle.burstVelocity) {
        delete particle.burstVelocity
      }
    }

    /**
     * Animation loop that updates and renders all particles
     */
    const animate = (): void => {
      ctx.clearRect(0, 0, width, height)

      // If animation hasn't started yet, just request next frame
      if (particles.current.length === 0 && !animationActive.current) {
        animationFrameId.current = requestAnimationFrame(animate)
        return
      }

      // Check if any particles are visible
      const hasVisibleParticles = particles.current.some(
        (p) => p.opacity > 0 && p.y <= height + 50,
      )

      if (!animationActive.current && !hasVisibleParticles) {
        // Stop animation when all particles are gone
        cancelAnimationFrame(animationFrameId.current)
        return
      }

      particles.current.forEach((particle) => {
        // Only process if particle is visible
        if (particle.opacity <= 0) return

        // Apply physics: gravity, wobble, friction
        particle.vy += particle.gravity
        particle.vx += particle.wobble * Math.cos(particle.wobbleAngle)
        particle.wobbleAngle += particle.wobbleSpeed

        particle.vx *= particle.friction
        particle.vy *= particle.friction

        particle.x += particle.vx
        particle.y += particle.vy
        particle.rotation += particle.rotationSpeed

        // Calculate fade out effect when approaching bottom
        const distanceFromBottom = height - particle.y
        if (distanceFromBottom < fadeRange) {
          particle.opacity = distanceFromBottom / fadeRange
        }

        // Reset if out of bounds
        if (
          particle.y > height ||
          particle.x < -20 ||
          particle.x > width + 20
        ) {
          resetParticle(particle)
        }

        // Draw the particle
        ctx.save()
        ctx.translate(particle.x, particle.y)
        ctx.rotate((particle.rotation * Math.PI) / 180)

        // Apply wobble effect to scale
        const wobbleEffect = 0.5 + Math.sin(particle.vy * 20) * 0.5
        ctx.scale(1, wobbleEffect)

        // Apply opacity
        ctx.globalAlpha = particle.opacity

        ctx.fillStyle = particle.color

        if (particle.shape === 'rect') {
          const halfSize = particle.size / 2
          ctx.fillRect(-halfSize, -halfSize, particle.size, particle.size)
        } else {
          ctx.beginPath()
          ctx.arc(0, 0, particle.size / 2, 0, Math.PI * 2)
          ctx.fill()
        }

        ctx.restore()
      })

      animationFrameId.current = requestAnimationFrame(animate)
    }

    // Start the animation loop
    animate()

    return () => {
      // Clean up timeouts and animation frames
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
      if (startTimeoutId.current) {
        clearTimeout(startTimeoutId.current)
      }
      cancelAnimationFrame(animationFrameId.current)
    }
  }, [width, height, duration, startDelay])

  return <canvas ref={canvasRef} className={className} />
}
