import React, { useCallback, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { Sidebar, useIsMobileScreenSize } from '@buffer-mono/popcorn'

import SettingsSidebar from './SettingsSidebar'
import ProfileSettings from './ProfileSettings'
import Preferences from './Preferences'
import EmailNotifications from './EmailNotifications'
import AppsExtrasPage from './AppsExtrasPage'
import Team from './Team'
import GeneralSettings from './General'
import ReferAFriend from './ReferAFriend'
import BetaFeatures from './BetaFeatures'

import {
  appsAndExtrasRoute,
  settingsNotificationsRoute,
  settingsPreferencesRoute,
  betaFeaturesRoute,
  generalSettingsRoute,
  referAFriendRoute,
  settingsTeamRoute,
  settingsChannelsRoute,
  settingsChannelsSettingsRoute,
  settingsBillingRoute,
} from '~publish/legacy/routes'
import Channels from './Channels'
import { ChannelSettings } from '../Channel/ChannelSettings'
import { useSplitEnabled } from '@buffer-mono/features'
import {
  injectMetaViewportTag,
  removeMetaViewportTag,
} from '@buffer-mono/app-shell/src/exports/Navigator/utils/metaViewport'
import Billing from './Billing'

const Settings = (): JSX.Element | null => {
  const [forceOpen, setForceOpen] = useState(true)
  const open = forceOpen

  const isSmallScreen = useIsMobileScreenSize()

  const { isEnabled: isResponsiveNavEnabled } = useSplitEnabled(
    'responsive-main-nav',
  )
  useEffect(() => {
    if (isResponsiveNavEnabled) {
      injectMetaViewportTag()
    }
    // remove when component unmounts
    // (the rest of app is not ready for responsive UI yet)
    return () => {
      removeMetaViewportTag()
    }
  }, [isResponsiveNavEnabled])

  const handleToggleSidebar = useCallback((): void => {
    setForceOpen(!open)
  }, [setForceOpen, open])

  useEffect(() => {
    if (isSmallScreen) {
      setForceOpen(false)
    }
  }, [isSmallScreen, setForceOpen])

  return (
    <Sidebar.Provider
      open={open}
      onOpenChange={setForceOpen}
      mobileFallback={true}
      data-temporary-hack-disable-root-scroll
    >
      <SettingsSidebar
        isOpen={open}
        isMobile={isSmallScreen}
        onToggleSidebar={handleToggleSidebar}
      />
      <Route path="/settings" exact>
        <ProfileSettings
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={settingsPreferencesRoute.route} exact>
        <Preferences
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={settingsNotificationsRoute.route} exact>
        <EmailNotifications
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={appsAndExtrasRoute.route} exact>
        <AppsExtrasPage
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={generalSettingsRoute.route} exact>
        <GeneralSettings
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={settingsChannelsRoute.route} exact>
        <Channels
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={settingsBillingRoute.route} exact>
        <Billing
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={settingsChannelsSettingsRoute.route}>
        <ChannelSettings isForNewSettings={true} />
      </Route>
      <Route path={referAFriendRoute.route} exact>
        <ReferAFriend
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={betaFeaturesRoute.route} exact>
        <BetaFeatures
          isMobile={isSmallScreen}
          handleToggleSidebar={handleToggleSidebar}
        />
      </Route>
      <Route path={settingsTeamRoute.route} exact>
        <Team />
      </Route>
    </Sidebar.Provider>
  )
}

export default Settings
