import React from 'react'
import { GridList, EmptyState } from '~publish/legacy/shared-components'
import {
  ExternalLinkIcon,
  toast,
  Tooltip,
  Button,
  CopyIcon,
  Separator,
  Text,
  Heading,
  Skeleton,
} from '@buffer-mono/popcorn'

import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'

import ProfilesDisconnectedBanner from '~publish/legacy/profiles-disconnected-banner'
import {
  getChannelProperties,
  openPreviewPage,
  urlHasProtocol,
} from '../../util'
import CustomLinks from '../CustomLinks'
import styles from './index.module.css'
import {
  BufferTrackerReact as BufferTracker,
  BufferTrackerReact,
} from '@buffer-mono/tracking-plan'
import type {
  CustomLink,
  CustomLinksDetails,
  Profile,
} from '~publish/legacy/profile-sidebar/types'
import { Views } from '~publish/pages/Channel/ChannelShopGrid/ChannelShopGrid'
import { usePaginatedPostList } from '~publish/pages/AllChannels/PostList/usePaginatedPostList'
import { useAppDispatch } from '~publish/legacy/store/index'
import { getFragmentData } from '~publish/gql/fragment-masking'
import { PostCard_Post } from '~publish/components/PostCard/PostCard'
import { actionTypes } from '../../reducer'

const ErrorBoundary = getErrorBoundary(true)

export interface GridPostsProps {
  profile: Profile
  maxCustomLinks?: number
  selectedView: Views
  customLinksDetails: CustomLinksDetails
}

const onCopyToClipboard = ({
  publicGridUrl,
  profile,
}: {
  publicGridUrl: string
  profile?: Partial<Profile>
}): void => {
  navigator.clipboard.writeText(publicGridUrl)

  BufferTracker.shopGridPageLinkCopied({
    ...getChannelProperties(profile),
    shopGridUrl: publicGridUrl || '',
  })

  toast.success('Copied!')
}

const modifyItem = (item: CustomLink | null): CustomLink | null => {
  return item
    ? {
        ...item,
        url: urlHasProtocol(item.url) ? item.url : `https://${item.url}`,
      }
    : item
}

const onPreviewClick = (publicGridUrl: string): void => {
  openPreviewPage(publicGridUrl)
}

const GridPreview = ({
  profile,
  maxCustomLinks = 3,
  selectedView,
  customLinksDetails = {
    customLinks: [],
    buttonColor: null,
    buttonContrastColor: null,
  },
}: GridPostsProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const isDisconnectedProfile = profile?.isDisconnected
  const publicGridUrl = `https://shopgr.id/${profile?.serviceUsername}`
  const previewGridUrl = `https://buffer.com/p/${profile?.serviceUsername}`
  const hasWriteAccess = !!profile?.permissions?.includes?.('buffer_write')

  const channelId = profile?.id || ''

  const { data, loading, refetch } = usePaginatedPostList({
    channelIds: [channelId],
    status: ['scheduled', 'sent'],
    sortPreset: 'mostRecentlyPostedFirst',
    perPage: 100,
  })

  const flattenedPostEdges = !data?.posts.edges?.length
    ? []
    : data.posts.edges.map((edge) => edge.node)
  const posts = getFragmentData(PostCard_Post, flattenedPostEdges)

  const gridPosts = posts.filter(
    (post) =>
      ['reel', 'post'].includes(post.metadata?.type || '') &&
      post.notificationStatus == null,
  )

  if (loading) {
    return (
      <div className={styles.loadingGridContainer}>
        {new Array(Math.ceil(window.innerHeight / 410) * 3)
          .fill(null)
          .map((_, index) => (
            <Skeleton key={index} height={410} width={308} />
          ))}
      </div>
    )
  }

  return (
    <ErrorBoundary>
      {isDisconnectedProfile ? (
        <ProfilesDisconnectedBanner />
      ) : (
        <div>
          <div className={styles.header}>
            {selectedView === Views.ShopGrid && (
              <>
                <Heading size="medium" className={styles.title}>
                  Shop Grid
                </Heading>

                <Heading size="small">Public Page URL</Heading>

                <div className={styles.buttonWrapper}>
                  <div className={styles.linkField}>
                    <Tooltip arrow content="Copy Page Link">
                      <button
                        className={styles.copyLinkButton}
                        type="button"
                        onClick={(): void => {
                          onCopyToClipboard({
                            publicGridUrl,
                            profile,
                          })
                        }}
                      >
                        {publicGridUrl}
                        <CopyIcon size="small" />
                      </button>
                    </Tooltip>
                  </div>
                  <Button
                    variant="secondary"
                    onClick={(): void => {
                      onPreviewClick(previewGridUrl)

                      BufferTrackerReact.shopGridPagePreviewed(
                        getChannelProperties(profile),
                      )
                    }}
                  >
                    <ExternalLinkIcon />
                    Preview Page
                  </Button>
                </div>
              </>
            )}
          </div>
          {selectedView === Views.ShopGrid && (
            <>
              <Separator />
              <CustomLinks
                customLinksDetails={customLinksDetails}
                onUpdateCustomLinksColor={(colors): void => {
                  dispatch({
                    type: actionTypes.UPDATE_CUSTOM_LINKS,
                    profileId: profile.id,
                    customLinks: false,
                    customLinkColor: colors.customLinkColor,
                    customLinkContrastColor: colors.customLinkContrastColor,
                  })
                }}
                onDeleteCustomLink={(customLinkId): void => {
                  dispatch({
                    type: actionTypes.DELETE_CUSTOM_LINK,
                    profileId: profile.id,
                    customLinkId,
                  })
                }}
                maxCustomLinks={maxCustomLinks}
                onToggleEditMode={(item, editing): void => {
                  dispatch({
                    type: actionTypes.TOGGLE_CUSTOM_LINK_EDIT_MODE,
                    profileId: profile.id,
                    item,
                    editing,
                  })
                }}
                onSwapCustomLinks={(
                  customLinkSource,
                  customLinkTarget,
                ): void => {
                  dispatch({
                    type: actionTypes.SWAP_CUSTOM_LINKS,
                    profileId: profile.id,
                    customLinkSource,
                    customLinkTarget,
                  })
                }}
                onSaveNewLinkClick={(item): void => {
                  dispatch({
                    type: actionTypes.ADD_NEW_CUSTOM_LINK,
                    profileId: profile.id,
                    item: modifyItem(item),
                  })
                }}
                onUpdateSingleCustomLink={(item): void => {
                  dispatch({
                    type: actionTypes.UPDATE_SINGLE_CUSTOM_LINK,
                    profileId: profile.id,
                    linkId: item.id,
                    item: modifyItem(item),
                  })
                }}
                hasWriteAccess={hasWriteAccess}
              />
              <Separator />
              <div className={styles.titleWrapper}>
                <Heading size="small">Post Links</Heading>
                <Text as="p" color="subtle">
                  Add links to your posts. Only posts with links will appear on
                  your public Shop Grid page.
                </Text>
              </div>
            </>
          )}
          {gridPosts.length < 1 && (
            <EmptyState
              title="No posts published yet!"
              subtitle="Once you've posted to your instagram account, your posts will show up here."
              heroImg="https://s3.amazonaws.com/buffer-publish/images/empty-sent2x.png"
              heroImgSize={{ width: '270px', height: '150px' }}
            />
          )}
          {gridPosts.length >= 1 && (
            <GridList
              gridPosts={gridPosts}
              hasWriteAccess={hasWriteAccess}
              timezone={profile?.timezone}
              showExtraContent={selectedView === Views.ShopGrid}
              onSavePostUrl={(post, link): void => {
                dispatch({
                  type: actionTypes.SAVE_POST_URL,
                  updateId: post.id,
                  post,
                  profileId: profile.id,
                  link,
                  callback: () => {
                    // temporary fix for the post list not updating
                    refetch()
                  },
                })
              }}
            />
          )}
        </div>
      )}
    </ErrorBoundary>
  )
}

export default GridPreview
