import * as React from 'react'

export const StreakBackgroundHit = (
  props: React.SVGProps<SVGSVGElement>,
): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={212}
    height={67}
    fill="none"
    viewBox="0 0 212 67"
    aria-hidden="true"
    {...props}
  >
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <g clipPath="url(#c)">
          <rect
            width={212}
            height={212}
            x={212}
            y={-64}
            fill="#D1BDFF"
            rx={5}
            transform="rotate(90 212 -64)"
          />
          <rect
            width={347.766}
            height={179.258}
            x={233.881}
            y={-52.382}
            fill="#EDE4FF"
            rx={5}
            transform="rotate(135 233.881 -52.382)"
          />
          <rect
            width={347.766}
            height={54.327}
            x={145.541}
            y={-140.722}
            fill="#E4D7FF"
            rx={5}
            transform="rotate(135 145.541 -140.722)"
          />
          <circle
            cx={188.997}
            cy={24.282}
            r={75.226}
            fill="#E4D7FF"
            transform="rotate(90 188.997 24.282)"
          />
          <circle
            cx={188.997}
            cy={24.282}
            r={65.9}
            fill="#D1BDFF"
            transform="rotate(90 188.997 24.282)"
          />
          <circle
            cx={28.598}
            cy={90.182}
            r={78.956}
            fill="#F6F2FF"
            transform="rotate(90 28.598 90.182)"
          />
          <circle
            cx={28.598}
            cy={90.182}
            r={70.874}
            fill="#E4D7FF"
            transform="rotate(90 28.598 90.182)"
          />
          <rect
            width={189.354}
            height={97.603}
            x={92.633}
            y={-106.269}
            fill="#D1BDFF"
            rx={5}
            transform="rotate(135 92.633 -106.269)"
          />
        </g>
      </g>
      <path
        fill="#B0EC9C"
        fillOpacity={0.75}
        d="m5.85 17.443-1.89-6.845 3.714-.837 1.888 6.845z"
      />
      <path
        fill="#213130"
        fillOpacity={0.85}
        d="m18.22 12.545-1.237-2.633 2.1-.806 1.236 2.633z"
      />
      <path
        fill="#B0EC9C"
        fillOpacity={0.75}
        d="m114.861 48.897.978 2.72-2.17.637-.977-2.72z"
      />
      <path
        fill="#D1BDFF"
        fillOpacity={0.35}
        d="m1.21 25.826 5.627-5.852 2.304 1.811-5.626 5.852z"
      />
      <path
        fill="#B0EC9C"
        d="M71.565-7.72 68.367.222l-2.87-.945 3.198-7.943z"
      />
      <path
        fill="#B0EC9C"
        fillOpacity={0.35}
        d="m57.649 20.232.167-2.156 3.047.192-.167 2.157z"
      />
      <path
        fill="#213130"
        fillOpacity={0.85}
        d="m29.816 62.178 3.852 4.458-1.797 1.269-3.852-4.458z"
      />
      <path
        fill="#213130"
        fillOpacity={0.9}
        d="m49.145 48.897.905-1.213 3.814 2.326-.905 1.212z"
      />
      <path
        fill="#B0EC9C"
        fillOpacity={0.35}
        d="m21.513 33.144-.396.982-1.138.182-.743-.8.395-.983 1.139-.181.743.8z"
      />
      <path
        fill="#D1BDFF"
        fillOpacity={0.6}
        d="m1.529 17.134 1.07-.756 1.127.687-.374 1.18-1.358.043-.465-1.154z"
      />
      <path
        fill="#D1BDFF"
        fillOpacity={0.65}
        d="m160.115 55.198-2.157.633-1.333-1.658 1.333-1.659 2.157.634v2.05z"
      />
      <path
        fill="#EDE4FF"
        fillOpacity={0.65}
        d="m83.654-.752-1.857 1.8L79.33.008l.332-2.443 2.672-.47 1.32 2.153z"
      />
      <path
        fill="#EDE4FF"
        fillOpacity={0.6}
        d="m65.63 56.117-1.555.456-.506-1.405 1.555-.457.505 1.406z"
      />
      <path
        fill="#B0EC9C"
        fillOpacity={0.9}
        d="m28.458 54.497.928-1.114 1.531.17.603 1.284-.928 1.113-1.531-.17-.603-1.283z"
      />
      <path
        fill="#D1BDFF"
        fillOpacity={0.35}
        d="m27.996 37.995-2.363 2.136-.864-2.918 3.227.782z"
      />
      <path
        fill="#213130"
        fillOpacity={0.45}
        d="m3.164 40.707-.463 2.379-2.047-1.553 2.51-.826z"
      />
      <path
        fill="#213130"
        fillOpacity={0.9}
        d="m207.202 15.646-.26 1.018-1.106.305-.845-.712.26-1.018 1.105-.306.846.713z"
      />
      <path
        fill="#D1BDFF"
        fillOpacity={0.35}
        d="m40.659 34.343 2.968-1.25 1.383 2.682-2.967 1.252-1.384-2.684z"
      />
      <path
        fill="#B0EC9C"
        fillOpacity={0.9}
        d="m188.03 54.592-.75-2.366 2.257-1.376 2.145 1.515-.931 2.313-2.721-.086z"
      />
      <path
        fill="#213130"
        d="m156.187 4.595-.041 2.093-2.025 1.015-1.985-1.078.041-2.094 2.025-1.015 1.985 1.079z"
      />
      <path
        fill="#D1BDFF"
        fillOpacity={0.6}
        d="m122.191 50.15 1.233 1.426-1.983.252.75-1.678z"
      />
      <path
        fill="#213130"
        d="m70.077 1.255-2.918 1.344L65.672-.04l2.918-1.344 1.487 2.638z"
      />
      <path
        fill="#D1BDFF"
        fillOpacity={0.6}
        d="m90.627 42.01-1.24-1.335.66-1.639 1.9-.303 1.24 1.336-.66 1.64-1.9.302z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={212} height={67} fill="#fff" rx={8} />
      </clipPath>
      <clipPath id="b">
        <rect width={212} height={67} fill="#fff" rx={8} />
      </clipPath>
      <clipPath id="c">
        <rect
          width={212}
          height={212}
          x={212}
          y={-64}
          fill="#fff"
          rx={5}
          transform="rotate(90 212 -64)"
        />
      </clipPath>
    </defs>
  </svg>
)
