import React from 'react'

import type { CommentStatus, SortDirection } from '~publish/gql/graphql'

import { DEFAULT_STATUS, type DateRange } from './useFilters'
import { FilterStatusMenu } from './FilterStatusMenu'
import { FilterAndSortMenu } from './FilterAndSortMenu'
import { BulkActionsMenu } from './BulkActionsMenu'

export type CommentsFiltersProps = {
  status?: CommentStatus | 'all'
  sortDirection?: SortDirection
  publishedAt?: DateRange
  setStatus: (status: CommentStatus | 'all') => void
  setSortDirection: (direction: SortDirection) => void
  setPublishedAt: (publishedAt: DateRange) => void
  clearFilters: () => void
  channelId: string
}

export const CommentsFilters = ({
  status = DEFAULT_STATUS,
  sortDirection = 'desc',
  publishedAt = 'alltime',
  setStatus,
  setSortDirection,
  setPublishedAt,
  clearFilters,
  channelId,
}: CommentsFiltersProps): JSX.Element => {
  return (
    <>
      <FilterStatusMenu value={status} onChange={setStatus} />
      <FilterAndSortMenu
        sortOrder={sortDirection ?? 'desc'}
        onSortOrderChange={setSortDirection}
        publishedAt={publishedAt}
        onPublishedAtChange={setPublishedAt}
        onResetFilters={(): void => clearFilters()}
      />
      <BulkActionsMenu channelId={channelId} />
    </>
  )
}
