import { useEffect, useRef, useState } from 'react'
import { useTheme } from '@buffer-mono/popcorn'
import { env } from '../../env'
import { useUser } from '../context/User'

type WidgetOptions = {
  show?: 'changelog' | 'feature_requests' | 'all'
  enableIndicator?: boolean
  showOnlySubmission?: boolean
}

type Widget = {
  open: () => void
  close: () => void
  hasNewChangelog: boolean
}

export const useFeatureOsWidget = (
  options: WidgetOptions = {},
): Widget | null => {
  const widgetRef = useRef<Widget | null>(null)
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false)
  const [isWidgetInitialized, setIsWidgetInitialized] = useState(false)
  const [hasNewChangelog, setHasNewChangelog] = useState(false)
  const { theme } = useTheme()
  const account = useUser()

  useEffect(() => {
    const checkWidgetLoaded = (): void => {
      if (window.HellonextWidget) {
        setIsWidgetLoaded(true)
      } else {
        setTimeout(checkWidgetLoaded, 100)
      }
    }

    checkWidgetLoaded()
  }, [])

  useEffect(() => {
    if (!isWidgetLoaded || !account.id || isWidgetInitialized) {
      // FeatureOS script is not loaded yet...
      return
    }

    try {
      // FeatureOS script is defined in /html-scripts/featureOS.ts
      // it does not support typescript, so we need to cast it to any
      // all the options are defined here based on the widget documentation
      // https://developers.featureos.app/docs/widgets
      const widget = new window.HellonextWidget({
        type: 'modal',
        theme: theme === 'dark' ? 'dark' : 'light',
        openFrom: 'center',
        ssoToken: account.hellonextSsoToken,
        token: env.VITE_FEATUREOS_TOKEN,
        modules:
          !options.show || options.show === 'all'
            ? ['changelog', 'feature_requests']
            : [options.show],
        enableIndicator: options.enableIndicator ?? true,
        noDefaultTrigger: true,
        postFilters: {
          bucket_id: [13248, 13444],
          status: ['under_review', 'planned', 'in_progress', 'completed'],
        },
        changelogFilters: {
          per_page: 20,
        },
        showOnlySubmission: options.showOnlySubmission ?? false,
        onNewChangelogIndicator: (): void => {
          setHasNewChangelog(true)
        },
      })
      widget.init()
      setIsWidgetInitialized(true)

      widgetRef.current = widget
    } catch (error) {
      console.error('Failed to create FeatureOS widget', error)
    }
  }, [account.id, isWidgetLoaded, isWidgetInitialized])

  if (!widgetRef.current) {
    return null
  }

  return {
    open: (): void => {
      widgetRef.current?.open?.()
    },
    close: (): void => {
      widgetRef.current?.close?.()
    },
    hasNewChangelog,
  }
}
