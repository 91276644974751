import {
  Lightbox,
  ClockIcon,
  Badge,
  Tooltip,
  IgReelIcon,
  CarouselIcon,
  Input,
  toast,
} from '@buffer-mono/popcorn'
import dayjs from 'dayjs'
import timezonePlugin from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { getResizedImageUrlWithWidth } from '~publish/legacy/utils/bufferImages'
import type { PostCard_PostFragment } from '~publish/gql/graphql'
import { PostMediaAsset_Asset } from '~publish/components/PostCard/PostMedia/PostMediaAsset'
import { getFragmentData } from '~publish/gql/fragment-masking'
import { isValidURL, urlHasProtocol } from '~publish/legacy/grid/util/index'

dayjs.extend(utc)
dayjs.extend(timezonePlugin)

function onLinkSave(
  post: PostCard_PostFragment,
  newValue: string,
  onSavePostUrl: (post: PostCard_PostFragment, link: string) => void,
  onValidationError: () => void,
): void {
  if (post.metadata?.__typename !== 'InstagramPostMetadata') {
    return
  }

  const link = post.metadata?.link || ''

  if (link !== newValue) {
    if (newValue === '') {
      onSavePostUrl(post, '')
    } else {
      if (isValidURL(newValue)) {
        onSavePostUrl(
          post,
          urlHasProtocol(newValue) ? newValue : `https://${newValue}`,
        )
      } else {
        onValidationError()
        toast.error('The URL format is invalid!')
      }
    }
  }
}

function ExtraContent({
  post,
  hasWriteAccess,
  onSavePostUrl,
  onValidationError,
  onReset,
  hasError,
}: {
  post: PostCard_PostFragment
  hasWriteAccess: boolean
  onSavePostUrl: (post: PostCard_PostFragment, link: string) => void
  onReset: () => void
  onValidationError: () => void
  hasError?: boolean
}): JSX.Element | null {
  if (post.metadata?.__typename !== 'InstagramPostMetadata') {
    return null
  }

  const link = post.metadata?.link || ''

  return (
    <div className={styles.inputWrapper}>
      <Input
        aria-invalid={hasError}
        disabled={!hasWriteAccess}
        onFocus={onReset}
        onBlur={(event): void => {
          onLinkSave(post, event.target.value, onSavePostUrl, onValidationError)
        }}
        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
          if (event.key === 'Enter') {
            onLinkSave(
              post,
              event.currentTarget.value,
              onSavePostUrl,
              onValidationError,
            )
          } else {
            onReset()
          }
        }}
        name="postUrl"
        placeholder="Website or Product URL"
        defaultValue={link || ''}
      />
    </div>
  )
}

const getTooltipText = (
  post: PostCard_PostFragment,
  timezone?: string,
  isScheduled?: boolean,
): string => {
  const timestamp = isScheduled ? post.dueAt : post.sentAt

  let slotMoment = dayjs(timestamp)

  if (timezone) {
    slotMoment = slotMoment.tz(timezone)
  }

  const humanReadableTime = slotMoment.format('MMM Do YYYY [at] h:mm a')
  const sentText = isScheduled ? 'will be' : 'was'

  return `This post ${sentText} sent ${humanReadableTime}`
}

interface GridListPostProps {
  post: PostCard_PostFragment
  timezone?: string
  showExtraContent?: boolean
  hasWriteAccess: boolean
  onSavePostUrl: (post: PostCard_PostFragment, link: string) => void
}

const GridListPost = ({
  post,
  timezone = '',
  showExtraContent,
  hasWriteAccess,
  onSavePostUrl,
}: GridListPostProps): JSX.Element => {
  const [hasError, setHasError] = useState(false)
  const { triggerAttributes, editPostInComposer } = usePostComposer()
  const isScheduled = post.status === 'scheduled'
  const ImageItem = isScheduled ? 'button' : Lightbox.Trigger
  const firstAsset = getFragmentData(PostMediaAsset_Asset, post.assets)[0]
  const image =
    firstAsset.__typename === 'VideoAsset'
      ? firstAsset.thumbnail
      : firstAsset.source

  useEffect(() => {
    if (!showExtraContent) {
      setHasError(false)
    }
  }, [showExtraContent])

  return (
    <div className={styles.itemStyle}>
      <Lightbox slides={[{ src: `${image}` }]}>
        <Tooltip content={getTooltipText(post, timezone, isScheduled)}>
          <ImageItem
            {...triggerAttributes}
            className={styles.imgWrapper}
            style={
              {
                '--image': `url(${getResizedImageUrlWithWidth(image, 820)})`,
              } as React.CSSProperties
            }
            onClick={
              isScheduled
                ? (): void => {
                    editPostInComposer({
                      cta: 'publish-gridList-gridItem-editPost-1',
                      postId: post.id,
                    })
                  }
                : undefined
            }
          >
            {isScheduled && (
              <div className={styles.scheduledIcon}>
                <Badge variant="neutral">
                  <ClockIcon size="medium" />
                  Scheduled
                </Badge>
              </div>
            )}
            {post.assets.length > 1 && (
              <div className={styles.typeIcon}>
                <CarouselIcon size="medium" />
              </div>
            )}
            {post.metadata?.type === 'reel' && (
              <div className={styles.typeIcon}>
                <IgReelIcon size="medium" />
              </div>
            )}
          </ImageItem>
        </Tooltip>
      </Lightbox>
      {hasError && <div className={styles.error} />}
      {showExtraContent && (
        <ExtraContent
          post={post}
          hasWriteAccess={hasWriteAccess}
          onSavePostUrl={onSavePostUrl}
          onValidationError={(): void => {
            setHasError(true)
          }}
          onReset={(): void => {
            setHasError(false)
          }}
          hasError={hasError}
        />
      )}
    </div>
  )
}

export default GridListPost
