import React, { useImperativeHandle, useRef } from 'react'

import {
  DragSource,
  DropTarget,
  DragPreviewImage,
  type ConnectDragSource,
  type ConnectDropTarget,
  type ConnectDragPreview,
  type DropTargetMonitor,
} from 'react-dnd'
import SwapIcon from '@bufferapp/ui/Icon/Icons/Swap'

import LinkPreview, { type LinkPreviewProps } from '../LinkPreview'

import styles from './index.module.css'
import type { CustomLink } from '~publish/legacy/profile-sidebar/types'

const customLinkSource = {
  beginDrag(props: {
    item: { _id: string }
    onSwapCustomLinks: (params: {
      customLinkSource: { _id: string }
      customLinkTarget: { _id: string }
    }) => void
  }): {
    id: string
    item: { _id: string }
    onSwapCustomLinks: (params: {
      customLinkSource: { _id: string }
      customLinkTarget: { _id: string }
    }) => void
  } {
    return {
      id: props.item._id,
      item: props.item,
      onSwapCustomLinks: props.onSwapCustomLinks,
    }
  },
}

const customLinkTarget = {
  drop(props: LinkPreviewProps, monitor: DropTargetMonitor): void {
    const { onSwapCustomLinks } = monitor.getItem()
    const linkSource = monitor.getItem().item
    const linkTarget = props.item

    onSwapCustomLinks(linkSource, linkTarget)
  },
}

interface LinkDragWrapperProps extends LinkPreviewProps {
  connectDragSource: ConnectDragSource
  connectDropTarget: ConnectDropTarget
  connectDragPreview: ConnectDragPreview
  onSwapCustomLinks: (from: CustomLink, to: CustomLink) => void
  isDragging: boolean
}

const LinkDragWrapper = React.forwardRef(
  (
    {
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      isDragging,
      ...customLinkProps
    }: LinkDragWrapperProps,
    ref,
  ): JSX.Element => {
    const elementRef = useRef<HTMLDivElement>(null)
    connectDragSource(elementRef)
    connectDropTarget(elementRef)
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))
    const { hasWriteAccess, isOver } = customLinkProps

    return (
      <>
        <DragPreviewImage
          src="https://buffer-publish.s3.amazonaws.com/images/drag-link-placeholder.png"
          connect={connectDragPreview}
        />
        <div
          className={styles.dragWrapper}
          ref={elementRef}
          draggable
          style={{
            transition: 'all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
            outline: 'none',
          }}
          onDragStart={(event): void => {
            if (!hasWriteAccess) {
              event.preventDefault()
              event.stopPropagation()
            }
          }}
        >
          {!isDragging && isOver && (
            <div className={styles.swapWrapper}>
              <div className={styles.swapIcon}>
                <SwapIcon size="medium" color="#FFFFFF" />
                <div className={styles.swapText}>Swap Links</div>
              </div>
            </div>
          )}
          <LinkPreview {...customLinkProps} />
        </div>
      </>
    )
  },
)

LinkDragWrapper.displayName = 'LinkDragWrapper'

export default DropTarget(
  'customLink',
  customLinkTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }),
)(
  DragSource('customLink', customLinkSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }))(LinkDragWrapper),
)
