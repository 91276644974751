import React from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { Paragraph, Skeleton, Text } from '@buffer-mono/popcorn'

import style from './OtpQrCode.module.css'

const OtpQrCode = ({
  issuer = 'Buffer',
  account,
  secret,
}: {
  issuer?: string
  account: string
  secret: string
}): JSX.Element => {
  const otpauth = `otpauth://totp/${issuer}:${account}?secret=${secret}&issuer=${issuer}`
  const ready = issuer && account && secret

  return (
    <>
      <div className={style.qrCodeWrapper}>
        {ready && (
          <QRCodeSVG
            value={otpauth}
            size={200}
            level="L"
            bgColor="#ffffff"
            fgColor="#000000"
          />
        )}
        {!ready && <Skeleton width={200} height={200} />}
      </div>
      {ready && (
        <ul className={style.details}>
          <li>
            <Text weight="medium">Issuer:</Text> <Text>Buffer</Text>
          </li>
          <li>
            <Text weight="medium">Account:</Text> <Text>{account}</Text>
          </li>
          <li>
            <Text weight="medium">Secret Key:</Text> <Text>{secret}</Text>
          </li>
        </ul>
      )}
      {!ready && <Paragraph className={style.details}>Loading...</Paragraph>}
    </>
  )
}

export default OtpQrCode
