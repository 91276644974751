import { useQuery } from '@apollo/client'
import { graphql } from '~publish/graphql'
import { useOrganizationId } from '~publish/legacy/accountContext'
import type {
  CommentsScoreResponse,
  CommentsScoreInput,
} from '~publish/gql/graphql'

export const commentsScoreQuery = graphql(/* GraphQL */ `
  query CommentsScoreQuery($input: CommentsScoreInput!) {
    commentsScore(input: $input) {
      currentAchievementLevel
      currentWeekScore {
        totalValue
        responseRate {
          value
          percentage
        }
        responseSpeed {
          value
          percentage
        }
        consistency {
          value
          percentage
        }
      }
      previousWeekScore {
        totalValue
        responseRate {
          value
          percentage
        }
        responseSpeed {
          value
          percentage
        }
        consistency {
          value
          percentage
        }
      }
    }
  }
`)

type CommentsScoreData = {
  commentsScore: CommentsScoreResponse
}
/**
 * Fetches the comments score for the current and previous week for a given channel
 *
 * @param channelId - The channelId to fetch comments score for
 * @returns CommentsScoreResponse - the comments score broken down by current and previous week, as well as in the indidivual metrics
 */
export function useCommentsScore(args: {
  channelId: string
}): CommentsScoreResponse | null {
  const organizationId = useOrganizationId()

  const { data, loading } = useQuery<
    CommentsScoreData,
    { input: CommentsScoreInput }
  >(commentsScoreQuery, {
    variables: {
      input: {
        organizationId: organizationId || '',
        channelId: args.channelId,
      },
    },
  })

  if (!organizationId || loading || !data) return null

  return data.commentsScore
}
