import { Button, Combobox, Text } from '@buffer-mono/popcorn'
import React from 'react'
import type { Service } from '~publish/gql/graphql'
import clsx from 'clsx'
import styles from './ChannelSelector.module.css'
import { ChannelServiceTag } from '~publish/components/ChannelServiceTag'

export type ChannelSelectorProps = {
  services: Service[]
  selectedServices?: Service[]
  onChange: (value: Service[]) => void
  className?: string
}

export const ChannelSelector = (props: ChannelSelectorProps): JSX.Element => {
  const { services, selectedServices = [], onChange, className } = props

  const handleSelect = (value: string[]): void => {
    onChange(value as Service[])
  }

  return (
    <Combobox value={selectedServices} onChange={handleSelect}>
      <Combobox.Trigger>
        <Button
          variant="secondary"
          className={clsx(styles.channels, className)}
        >
          {selectedServices.length > 0 ? (
            selectedServices.map((service) => (
              <ChannelServiceTag key={service} service={service} />
            ))
          ) : (
            <Text color="subtle">Empty</Text>
          )}
        </Button>
      </Combobox.Trigger>
      <Combobox.Content align="start">
        <Combobox.Input />
        <Combobox.List>
          {services.map((service) => (
            <Combobox.Item key={service} value={service}>
              <Text
                key={service}
                className={clsx(styles.channelIcon)}
                data-channel={service}
              >
                <ChannelServiceTag key={service} service={service} />
              </Text>
            </Combobox.Item>
          ))}
        </Combobox.List>
      </Combobox.Content>
    </Combobox>
  )
}
