import React from 'react'
import { Flex, Skeleton, Card } from '@buffer-mono/popcorn'

export const Invoices = ({ loading }: { loading: boolean }): JSX.Element => {
  return (
    <>
      <Flex gap="xs" direction="row" align="center">
        Invoices & Receipts
      </Flex>
      <Skeleton show={loading}>
        <Card>Invoices & Receipts content here</Card>
      </Skeleton>
    </>
  )
}

export default Invoices
