import React from 'react'

import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'

import { Container } from './AgencyPlanSection.style'

const AgencyPlanSection = (props: {
  ctaAction: () => void
  ctaLabel: string
}): JSX.Element => {
  const { ctaAction, ctaLabel } = props

  return (
    <Container id="agency_plan_section">
      <Text htmlFor="agencyPlan" type="help">
        Need more than 10 channels?{''}{' '}
        <Button
          id="try_agency_plan"
          type="text"
          onClick={(): void => ctaAction()}
          aria-label={ctaLabel}
          label={ctaLabel}
        />
      </Text>
    </Container>
  )
}

export default AgencyPlanSection
