import React from 'react'
import styled from 'styled-components'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import uuid from 'uuid/v4'
import { Text } from '@bufferapp/ui'
import { blue } from '@bufferapp/ui/style/colors'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

const StyledText = styled(Text)`
  flex: 1;
  margin: 0 0 0 8px;
  align-self: flex-start;
`

const ItemContainer = styled.li`
  display: flex;
  margin: 18px 0;
  align-items: baseline;
`

const CheckListContainer = styled.ul`
  margin: 0;
  padding: 0;
`

const StyledCheckmark = styled.span`
  display: flex;
  align-items: center;
  height: 20px;
`

const CheckList = ({ items }: { items: string[] }): JSX.Element => (
  <CheckListContainer>
    {items.map((item) => (
      <ItemContainer key={uuid()}>
        <StyledCheckmark>
          <CheckmarkIcon size="medium" color={blue} />
        </StyledCheckmark>
        <StyledText type="p">{item}</StyledText>
      </ItemContainer>
    ))}
  </CheckListContainer>
)

export default CheckList
