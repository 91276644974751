import React, { useCallback, useEffect, useState } from 'react'
import { Cross } from '@bufferapp/ui/Icon'
import { StyledWrapper, CloseButton } from './styles'

interface ComposerSidepanelProps {
  isVisible?: boolean
  customStyles?: Record<string, string>
  showCloseButton?: boolean
  onClose?: (event: React.MouseEvent) => void
  children: React.ReactNode
  closeButtonPosition?: {
    top?: string
    right?: string
  }
  animated?: boolean
  state?: string
  onAnimationEnd?: () => void
  side?: string
}

const ComposerSidepanel = ({
  isVisible = false,
  showCloseButton = false,
  closeButtonPosition,
  customStyles = {},
  onClose = (): void => {},
  children,
  animated = false,
  state = '',
  onAnimationEnd = (): void => {},
  side = '',
}: ComposerSidepanelProps): JSX.Element => {
  const [animation, setAnimation] = useState<string>('')

  const onAnimationEndCallback = useCallback((): void => {
    onAnimationEnd()
  }, [onAnimationEnd])

  useEffect(() => {
    if (animated && state) {
      setAnimation(state)
    }

    const timeoutId = setTimeout(onAnimationEndCallback, 300)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [state])

  return (
    <StyledWrapper
      data-testid="composer-sidepanel"
      className={`${side} ${animation}`}
      $isVisible={isVisible}
      $customStyles={customStyles}
    >
      {showCloseButton && (
        <CloseButton position={closeButtonPosition}>
          <Cross size="medium" onClick={onClose} data-testid="close-button" />
        </CloseButton>
      )}
      {children}
    </StyledWrapper>
  )
}

export default ComposerSidepanel
