import React from 'react'
import { ArrowUpIcon, Button, SimpleSpinner } from '@buffer-mono/popcorn'

import { useCommentsQuery } from '../hooks/useCommentsQuery'
import { useNewCommentsAvailableButton } from '../hooks/useNewCommentsAvailableButton'
import type { BaseCommentsFilters } from '../CommentsFilters/useFilters'
import styles from './CommentsChannel.module.css'

export const NewCommentsAvailableButton = ({
  channelId,
  filters,
}: {
  channelId: string
  filters?: BaseCommentsFilters
}): JSX.Element => {
  const [isFetchingNewComments, setIsFetchingNewComments] =
    React.useState(false)
  const { showNewCommentsButton, hideNewCommentsButton } =
    useNewCommentsAvailableButton()
  const { fetchNewComments } = useCommentsQuery({
    channelIds: channelId ? [channelId] : undefined,
    filters,
    skip: true,
  })

  const handleFetchNewComments = async (): Promise<void> => {
    setIsFetchingNewComments(true)
    try {
      await fetchNewComments()
    } finally {
      setIsFetchingNewComments(false)
      hideNewCommentsButton()
    }
  }

  const isValidFilter =
    filters?.status === 'all' || filters?.status === 'unreplied'
  if (!isValidFilter || (!isFetchingNewComments && !showNewCommentsButton)) {
    return <></>
  }

  return (
    <div className={styles.buttonContainer}>
      {isFetchingNewComments ? (
        <SimpleSpinner size="medium" />
      ) : (
        <Button
          as="button"
          size="medium"
          variant="primary"
          onClick={handleFetchNewComments}
          className={styles.newCommentsButton}
        >
          <ArrowUpIcon /> New comment available
        </Button>
      )}
    </div>
  )
}
