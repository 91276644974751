import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  ChannelIcon,
  EmptyState,
  Flex,
  Heading,
  MessageCircleHeartIcon,
  Tabs,
  ThreadsIcon,
} from '@buffer-mono/popcorn'

import { commentsChannel } from '~publish/legacy/routes'
import { PageLayout } from '~publish/components/PageLayout'
import { useCommentsChannels } from '~publish/components/CommentsSidebar/useCommentsChannels'
import { useCommentsConfig } from '~publish/pages/Comments/CommentsChannelConfigContext'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'

import { useConnectChannel } from '~publish/hooks/useConnectChannel'
import { useCta } from '~publish/hooks/useCta'

import styles from './CommentsChannel/CommentsChannel.module.css'

export const CommentsPage = (): JSX.Element => {
  const { channels, loading } = useCommentsChannels()
  const { config } = useCommentsConfig()

  const connectChannel = useConnectChannel({
    cta: 'publish-comments-emptyState-connectThreads-1',
  })

  const { clicked: ctaClicked } = useCta(
    'publish-comments-emptyState-connectThreads-1',
    {
      upgradePathName: 'addChannel-upgrade',
    },
  )

  const handleConnectThreads = (): void => {
    ctaClicked()
    connectChannel(Service.Threads)
  }

  if (loading) {
    return <></>
  }

  type CommentTab = 'overview' | 'comments'
  const availableTabs: CommentTab[] = ['overview', 'comments']
  const DEFAULT_TAB: CommentTab = 'overview'

  if (!config.hasSupportedCommentChannel(channels)) {
    return (
      <PageLayout>
        <Tabs defaultValue={DEFAULT_TAB} value={DEFAULT_TAB}>
          <PageLayout.Header>
            <PageLayout.HeaderRow>
              <ConnectThreadsChannelTitle />
              <PageLayout.Actions>
                <FeedbackWidget id="comments-threads" source="comments">
                  <Button variant="tertiary" size="large">
                    <MessageCircleHeartIcon />
                    Share Feedback
                  </Button>
                </FeedbackWidget>
              </PageLayout.Actions>
            </PageLayout.HeaderRow>
            <PageLayout.HeaderRow>
              <Tabs.TabList>
                {availableTabs.map((tab) => (
                  <Tabs.Tab key={tab} value={tab}>
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </Tabs.Tab>
                ))}
              </Tabs.TabList>
            </PageLayout.HeaderRow>
          </PageLayout.Header>
          <PageLayout.Container>
            <EmptyState size="xlarge">
              <EmptyState.Icon>
                <ThreadsIcon size="large" />
              </EmptyState.Icon>
              <EmptyState.Heading>
                Connect your Threads channel to reply to comments.
              </EmptyState.Heading>
              <EmptyState.Description>
                Comments from Threads will appear here.
              </EmptyState.Description>
              <EmptyState.Actions>
                <Button size="large" onClick={handleConnectThreads}>
                  Connect Threads
                </Button>
              </EmptyState.Actions>
            </EmptyState>
          </PageLayout.Container>
        </Tabs>
      </PageLayout>
    )
  }

  /* This page redirects to the first channel in the sidebar for now */
  /* We'll remove this redirect once we add support for Comments page */
  const firstChannelId = channels?.[0]?.id

  if (!firstChannelId) {
    return <></>
  }

  return (
    <Redirect
      to={commentsChannel.buildPathname({ channelId: firstChannelId })}
    />
  )
}

const ConnectThreadsChannelTitle = (): JSX.Element => {
  return (
    <Flex direction="row" align="center" gap="xs">
      <ChannelIcon
        type={Service.Threads}
        color="branded"
        className={styles.threadsIcon}
      />
      <PageLayout.Title>
        <Heading className={styles.threadsHeading}>Connect Threads</Heading>
      </PageLayout.Title>
    </Flex>
  )
}
