import {
  BarChartIcon,
  Button,
  ClockIcon,
  Collapsible,
  Flex,
  IconButton,
  Text,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import { formatDistanceToNowStrict } from 'date-fns'
import React from 'react'
import {
  usePostData,
  useTrackActionClicked,
} from '~publish/components/PostCard/PostCardContext'
import { SplitProtected } from '~publish/components/SplitProtected'
import type { PostMetric as Metric } from '~publish/gql/graphql'
import styles from './PostMetrics.module.css'
import { PostMetric } from './PostMetric'

export const PostMetricsWrapper = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}): JSX.Element | null => {
  const { schedulingType, status, metrics } = usePostData()

  // Notifications don't have metrics
  if (schedulingType === 'notification' || status !== 'sent' || !metrics) {
    return null
  }

  return (
    <section
      aria-label="Analytics"
      className={clsx(styles.metricsWrapper, className)}
    >
      {children}
    </section>
  )
}

type MoreAnalyticsButtonProps = {
  analyzeUrl: string
}

export const MoreAnalyticsButton = ({
  analyzeUrl,
}: MoreAnalyticsButtonProps): JSX.Element => {
  const trackPostAction = useTrackActionClicked()

  return (
    <Button
      variant="secondary"
      as="a"
      href={analyzeUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(): void => trackPostAction('seeMoreAnalytics-2')}
      className={styles.analyzeCtaCollapsible}
    >
      <BarChartIcon />
      More Analytics...
    </Button>
  )
}

export const MoreAnalyticsIconButton = ({
  analyzeUrl,
}: MoreAnalyticsButtonProps): JSX.Element => {
  const trackPostAction = useTrackActionClicked()

  return (
    <IconButton
      label="See More Analytics"
      tooltip="See more analytics in Analyze"
      size="medium"
      as="a"
      href={analyzeUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(): void => trackPostAction('seeMoreAnalytics-2')}
      className="analyzeCta"
    >
      <BarChartIcon />
    </IconButton>
  )
}

export const PostMetricsGrid = ({
  metrics,
  engageUrl,
}: {
  metrics: Metric[]
  engageUrl: string | null
}): JSX.Element => (
  <section className={styles.metrics}>
    {metrics?.map((metric) => (
      <PostMetric
        key={metric.name}
        type={metric.type}
        name={metric.displayName}
        value={metric.nullableValue}
        unit={metric.unit}
        engageUrl={engageUrl}
      />
    ))}
  </section>
)

export const CollapsiblePostMetrics = ({
  visibleMetrics,
  collapsedMetrics,
  engageUrl,
  analyzeUrl,
}: {
  visibleMetrics: Metric[]
  collapsedMetrics: Metric[]
  engageUrl: string | null
  analyzeUrl: string | null
}): JSX.Element => {
  return (
    <Collapsible className={styles.metricsCollapse}>
      <Collapsible.Trigger className={styles.metricsTrigger}>
        <Flex
          justify="start"
          gap="2xl"
          className={styles.metricsCollapsibleTrigger}
        >
          {visibleMetrics.map((metric) => (
            <PostMetric
              key={metric.name}
              type={metric.type}
              name={metric.displayName}
              value={metric.nullableValue}
              unit={metric.unit}
              engageUrl={engageUrl}
            />
          ))}
        </Flex>
      </Collapsible.Trigger>
      <Collapsible.Content>
        <Flex direction="column" gap="md" className={styles.collapsedMetrics}>
          <Flex justify="start" gap="2xl">
            {collapsedMetrics.map((metric) => (
              <PostMetric
                key={metric.name}
                type={metric.type}
                name={metric.displayName}
                value={metric.nullableValue}
                unit={metric.unit}
                engageUrl={engageUrl}
              />
            ))}
          </Flex>
          <div className={styles.analyzeCtaWrapper}>
            {analyzeUrl && <MoreAnalyticsButton analyzeUrl={analyzeUrl} />}
          </div>
        </Flex>
      </Collapsible.Content>
    </Collapsible>
  )
}

export const MetricsRefresh = ({
  className,
}: {
  className?: string
}): JSX.Element | null => {
  const { metrics, metricsUpdatedAt, status } = usePostData()

  if (status !== 'sent' || !metrics) {
    console.log('debug: returning null for MetricsRefresh')
    return null
  }

  const hasNoData = metrics.every(
    (metric) => typeof metric.nullableValue !== 'number',
  )

  return (
    <SplitProtected name="CT-show-metric-refresh">
      {metricsUpdatedAt ? (
        <div className={clsx(styles.lastUpdated, className)}>
          <ClockIcon size="xsmall" color="subtle" />
          <Text size="sm" color="subtle">
            Refreshed{' '}
            {formatDistanceToNowStrict(metricsUpdatedAt, { addSuffix: true })}
          </Text>
        </div>
      ) : null}
      {!metricsUpdatedAt && hasNoData ? (
        <div className={clsx(styles.lastUpdated, className)}>
          <ClockIcon size="xsmall" color="subtle" />
          <Text size="sm" color="subtle">
            Metrics not available yet
          </Text>
        </div>
      ) : null}
    </SplitProtected>
  )
}
