import { useQuery } from '@apollo/client'
import React, { useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'
import {
  AllChannelsIcon,
  ArrowRightIcon,
  Button,
  EmptyState,
  Flex,
  LockIcon,
  MessageCircleHeartIcon,
  PlusIcon,
  Skeleton,
  SkeletonText,
} from '@buffer-mono/popcorn'

import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { FilterByTag } from '~publish/components/FilterByTag'
import { NewPostComposerTrigger } from '~publish/components/NewPostComposerTrigger'
import { PostListOrCalendarViewToggle } from '~publish/components/PostListOrCalendarViewToggle/PostListOrCalendarViewToggle'
import { graphql } from '~publish/gql'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { TimezoneProvider } from '~publish/hooks/useTimezone'
import { useCurrentOrganization } from '~publish/legacy/accountContext'
import { useFullStory } from '~publish/legacy/thirdParty/hooks/useFullStory'
import type { PostStatus } from '~publish/gql/graphql'
import { PageLayout } from '~publish/components/PageLayout'
import {
  PostEmptyStateByTab,
  type PostTab,
  PostTabs,
} from '~publish/components/PostTabs'
import { mapPostStatusToTab } from '~publish/helpers/post'
import { PostManagementRouter } from '~publish/components/PostManagementRouter'
import { RegisteredBannersProvider } from '~publish/components/RegisteredBanner'
import { TiktokImagesPromotionalBanner } from '~publish/pages/Channel/PromotionalBanners/TiktokImagesPromotionalBanner'
import { TimezoneDisplay } from '~publish/components/TimezoneDisplay'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'
import { ChannelError } from '~publish/components/ChannelError'
import { getAccountChannelsURL } from '~publish/legacy/utils/formatters/getURL'

// TODO: fetching post counts and list should be extracted to common folders
import { PostList, PostListSkeleton } from '../AllChannels/PostList'
import { usePostCounts } from '../AllChannels/PostList/usePostCounts'
import { QueueLimitNotice } from './QueueLimitNotice'
import { QueueList } from './QueueList'
import { QueuePausedNotice } from './QueuePausedNotice'
import { ChannelProvider, useSelectedChannel } from './ChannelContext'
import { BlueskyPromotionalBanner } from './PromotionalBanners/BlueskyPromotionalBanner'
import { MetaThreadsPromotionalBanner } from './PromotionalBanners/MetaThreadsPromotionalBanner'
import { DisconnectedChannelNotice } from './DisconnectedChannelNotice'
import { RefreshLinkedInForAnalyticsPromotionalBanner } from './PromotionalBanners/RefreshLinkedInForAnalyticsPromotionalBanner'
import { SetupInstagramRemindersPromotionalBanner } from './PromotionalBanners/SetupInstagramRemindersPromotionalBanner'
import { LinkedInVideoPromotionalBanner } from './PromotionalBanners/LinkedInVideoPromotionalBanner'
import { InstagramProfessionalAccountBanner } from './InstagramProfessionalAccount'
import { TiktokUSBanBanner } from './TiktokUSBanBanner'
import { XRefreshBanner } from './XRefreshBanner'
import { ChannelPageTitle } from './ChannelPageTitle'
import { GridPreviewButton } from './ChannelShopGrid/GridPreviewButton'
import { MoreActions } from './MoreActions'

import styles from './ChannelPage.module.css'
import { useAppDispatch } from '~publish/legacy/store'

// TODO: replace serverUrl with metadata.serverUrl, at the moment doesn't seem to work
/*
metadata {
    __typename
    ... on MastodonMetadata {
      serverUrl
    }
  }
*/
export const ChannelPage_Channel = graphql(/* GraphQL */ `
  fragment ChannelPage_Channel on Channel {
    ...QueueList_Channel
    accessLevel
    id
    createdAt
    avatar
    isQueuePaused
    isDisconnected
    isLocked
    name
    metadata {
      ... on LinkedInMetadata {
        __typename
        shouldShowLinkedinAnalyticsRefreshBanner
      }
    }
    locationData {
      location
    }
    hasActiveMemberDevice
    serverUrl
    service
    timezone
    type
    apiVersion
    postingSchedule {
      day
      times
      paused
    }
    postingGoal {
      goal
      sentCount
      scheduledCount
      periodStart
      periodEnd
      status
    }
  }
`)
export const GetChannelInfo = graphql(/* GraphQL */ `
  query GetChannelInfo(
    $channelId: ChannelId!
    $organizationId: OrganizationId!
  ) {
    channel(input: { id: $channelId }) {
      ...QueueList_Channel
      ...ChannelPage_Channel
      id
      avatar
      isQueuePaused
      isLocked
      name
      locationData {
        location
      }
      serverUrl
      service
      timezone
      type
    }

    tags(input: { organizationId: $organizationId }) {
      id
      ...FilterByTag_Tag
    }
  }
`)

const availableTabs: PostTab[] = ['queue', 'drafts', 'approvals', 'sent']

export const ChannelPage = (): JSX.Element => {
  const id = useParams<{ id: string }>().id
  const [tab = 'queue', setTab] = useQueryParam<PostTab>('tab')
  const [tagsQueryParam = [], setTagFilter] = useQueryParam<string[]>('tags')
  const dispatch = useAppDispatch()
  const { initFullStory } = useFullStory()

  const organization = useCurrentOrganization()
  const organizationId = organization?.id
  const channelsCount = organization?.channels.length

  const { data, error } = useQuery(GetChannelInfo, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error - organizationId is not always defined we skip if it is not defined
      organizationId,
      channelId: id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !organizationId,
  })
  const { counts, loading: countsLoading } = usePostCounts({
    organizationId,
    channelIds: [id],
    tagIds: tagsQueryParam,
  })

  const tags = sanitizeNullableArray(data?.tags)
  const channel = data?.channel

  useEffect(
    function checkTabQueryParam() {
      if (!availableTabs.includes(tab)) {
        setTab(mapPostStatusToTab(tab as PostStatus))
      }
    },
    [setTab, tab],
  )

  // tracking
  useEffect(() => {
    initFullStory()
  }, [initFullStory])

  const selectedTags = useSelectedTags()

  // HOTFIX: this event was causing aa lot of traffic and us hitting Mixpanel limits
  // TODO: Create different tracking for filters and tab switches
  // See: https://linear.app/buffer/issue/CONT-158
  // useEffect(() => {
  //   BufferTracker.singleChannelOpened({
  //     tab,
  //     organizationId,
  //     accountId: accountId || '',
  //     channelSelected: id,
  //     tagsSelected: tagsQueryParam.length === 0 ? undefined : tagsQueryParam,
  //   })
  // }, [organizationId, id, tagsQueryParam, tab, accountId])

  // FIXME: this is a workaround for missing images in the sent posts tab for
  // posts that we fetched from instagram and have expiring image URLs
  // Triggering gridPosts will execute fetchRecentMedia in buffer-web
  // @see: https://buffer.atlassian.net/browse/CT-993
  const fetchedGridPosts = useRef<Set<string>>(new Set())
  useEffect(() => {
    if (channel?.service === 'instagram' && !fetchedGridPosts.current.has(id)) {
      dispatch(
        dataFetchActions.fetch({
          name: 'gridPosts',
          args: {
            profileId: id,
          },
        }),
      )
      fetchedGridPosts.current.add(id)
    }
  }, [dispatch, id, fetchedGridPosts, channel?.service])

  if (error) {
    return <ChannelError error={error} />
  }

  if (!channel) {
    return (
      <PageLayout legacyNotResponsive>
        <PageLayout.Header>
          <PageLayout.HeaderRow>
            <Flex direction="row" align="center" gap="md">
              <Skeleton rounded width={48} height={48} />
              <Skeleton rounded width={100} height={20} />
            </Flex>
            <PageLayout.Actions>
              <Skeleton width={90} height={40} />
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
          <PageLayout.HeaderRow>
            <Flex direction="row" align="center" gap="md">
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
            </Flex>
            <PageLayout.Actions>
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
              <SkeletonText lines={1} width={80} />
            </PageLayout.Actions>
          </PageLayout.HeaderRow>
        </PageLayout.Header>
        <PageLayout.Container size="narrow">
          <PostListSkeleton />
        </PageLayout.Container>
      </PageLayout>
    )
  }

  if (channel.isLocked) {
    return <ChannelLockedError />
  }

  const tabs: { id: PostTab; count: number | null | undefined }[] = [
    { id: 'queue', count: counts?.queue },
    { id: 'drafts', count: counts?.drafts },
    { id: 'approvals', count: counts?.approvals },
    { id: 'sent', count: counts?.sent },
  ]

  const tabCountLimit = counts?.limit

  const filterApplied = tagsQueryParam.length > 0

  return (
    <>
      <PostManagementRouter />
      <ChannelProvider channel={channel}>
        <TimezoneProvider timeZone={channel.timezone} key={channel.id}>
          <RegisteredBannersProvider
            key={`registered-banner-provider-${channel.name}`}
          >
            <PostTabs asChild value={tab} onChange={setTab}>
              <PageLayout>
                <PageLayout.NotificationsContainer>
                  <ChannelPageNotifications />
                </PageLayout.NotificationsContainer>
                <PageLayout.Header>
                  <PageLayout.HeaderRow>
                    <ChannelPageTitle />
                    <PageLayout.Actions>
                      <FeedbackWidget id="single-channel-1" source="publish">
                        <Button variant="tertiary" size="large">
                          <MessageCircleHeartIcon />
                          Share Feedback
                        </Button>
                      </FeedbackWidget>
                      <GridPreviewButton />
                      <PostListOrCalendarViewToggle />
                      <NewPostComposerTrigger
                        cta="publish-singleChannel-header-newPost-1"
                        channels={[id]}
                        prefillPostData={{
                          tags: selectedTags,
                        }}
                      >
                        <Button
                          data-testid="queue-header-create-post"
                          size="large"
                          variant="secondary"
                        >
                          <PlusIcon /> New Post
                        </Button>
                      </NewPostComposerTrigger>
                    </PageLayout.Actions>
                  </PageLayout.HeaderRow>
                  <PageLayout.HeaderRow>
                    <PostTabs.TabList>
                      {tabs.map((tab) => (
                        <PostTabs.Tab
                          key={tab.id}
                          value={tab.id}
                          count={tab.count}
                          countLimit={tabCountLimit}
                          countLoading={countsLoading}
                        />
                      ))}
                    </PostTabs.TabList>
                    <PageLayout.Actions>
                      <FilterByTag
                        data-tour-id="tags-filter"
                        tags={tags}
                        value={tagsQueryParam}
                        onSelect={setTagFilter}
                      />

                      <TimezoneDisplay
                        channelId={id}
                        timezone={channel.timezone}
                      />
                    </PageLayout.Actions>
                    <MoreActions channelId={id} />
                  </PageLayout.HeaderRow>
                </PageLayout.Header>

                <PageLayout.Container size="narrow" as="main">
                  <PostTabs.Panel value="queue" asChild>
                    <QueueList
                      key={`${id}-queue`}
                      channel={channel}
                      tagIds={
                        tagsQueryParam.length ? tagsQueryParam : undefined
                      }
                    />
                  </PostTabs.Panel>

                  {(['drafts', 'approvals', 'sent'] as const).map((tab) => (
                    <PostTabs.Panel key={`${id}-${tab}`} value={tab} asChild>
                      <PostList
                        key={`${id}-${tab}`}
                        status={tab}
                        channelIds={[id]}
                        tagIds={
                          tagsQueryParam.length ? tagsQueryParam : undefined
                        }
                        emptyState={
                          !filterApplied && (
                            <PostEmptyStateByTab tab={tab}>
                              <NewPostComposerTrigger
                                cta="publish-singleChannel-emptyState-newPost-1"
                                channels={[id]}
                              >
                                <Button size="large">
                                  <PlusIcon /> New Post
                                </Button>
                              </NewPostComposerTrigger>
                              {channelsCount === 1 && (
                                <Button
                                  as="a"
                                  href={getAccountChannelsURL()}
                                  variant="secondary"
                                  size="large"
                                  className={styles.connectMoreButton}
                                >
                                  <AllChannelsIcon /> Connect more channels
                                </Button>
                              )}
                            </PostEmptyStateByTab>
                          )
                        }
                      />
                    </PostTabs.Panel>
                  ))}
                </PageLayout.Container>
              </PageLayout>
            </PostTabs>
          </RegisteredBannersProvider>
        </TimezoneProvider>
      </ChannelProvider>
    </>
  )
}

export function ChannelPageNotifications(): JSX.Element {
  const selectedChannel = useSelectedChannel()

  return (
    <>
      {/* Notices */}
      <QueueLimitNotice />
      {selectedChannel?.isQueuePaused && <QueuePausedNotice />}
      <DisconnectedChannelNotice />

      {/* Banners - First rendered has highest priority */}
      <XRefreshBanner />
      <TiktokUSBanBanner />
      <InstagramProfessionalAccountBanner />
      <TiktokImagesPromotionalBanner />
      <RefreshLinkedInForAnalyticsPromotionalBanner />
      <LinkedInVideoPromotionalBanner />
      <BlueskyPromotionalBanner />
      <MetaThreadsPromotionalBanner />
      <SetupInstagramRemindersPromotionalBanner />
    </>
  )
}

const ChannelLockedError = (): JSX.Element => {
  return (
    <EmptyState size="large" variant="warning">
      <EmptyState.Icon>
        <LockIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>This channel is locked</EmptyState.Heading>
      <EmptyState.Description>
        Channel you are trying to access is locked. Likely it is because your
        account was downgraded.
      </EmptyState.Description>
      <EmptyState.Actions>
        <Button as={Link} to="/all-channels" size="large">
          <ArrowRightIcon />
          Go back to All Channels
        </Button>
      </EmptyState.Actions>
    </EmptyState>
  )
}
