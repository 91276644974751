import { DEFAULT_COLOR } from '../index'

export const getColorContrast = (hex: string): string => {
  const hexColor = hex.replace('#', '')
  const r = parseInt(hexColor.slice(0, 2), 16)
  const g = parseInt(hexColor.slice(2, 4), 16)
  const b = parseInt(hexColor.slice(4, 6), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  return yiq >= 160 ? '#000000' : '#ffffff'
}

export const isHexValid = (hex: string): boolean => /^#[0-9A-F]{6}$/i.test(hex)

const MAX_HEX_LENGTH = 6
export const repeatInputHex = (
  selectedHex: string,
  lastValidColor: string,
): string => {
  const repeatBy = MAX_HEX_LENGTH / selectedHex.length
  const repeatedHex = `#${selectedHex.repeat(repeatBy)}`

  return isHexValid(repeatedHex) ? repeatedHex : lastValidColor
}

export const getValidHex = (
  hexColor: string,
  lastValidColor: string,
): string => {
  const color = hexColor.replace('#', '')
  const validColor = lastValidColor || DEFAULT_COLOR

  if (!isHexValid(hexColor)) {
    const shouldRepeat = color.length > 0 && color.length <= 3

    return shouldRepeat ? repeatInputHex(color, validColor) : validColor
  }

  return hexColor
}

export const getColors = (
  selectedHex: string,
): {
  color: string
  contrastColor: string
} => {
  const color = (
    selectedHex.charAt(0) !== '#' ? `#${selectedHex}` : selectedHex
  ).toUpperCase()
  const contrastColor = getColorContrast(color)

  return {
    color,
    contrastColor,
  }
}
