import React from 'react'
import clsx from 'clsx'

import { PostCardActions } from '../PostCard/PostCardActions'
import { usePostData } from '../PostCard/PostCardContext'
import { PostedFrom } from './PostedFrom'
import { PostCardAuthor } from './PostCardAuthor'
import styles from './PostCardFooter.module.css'
import { Flex, Skeleton } from '@buffer-mono/popcorn'

const PostCardFooterRoot = ({
  condensed = false,
}: {
  condensed?: boolean
}): JSX.Element | null => {
  const { via } = usePostData()
  const isApiPost = via === 'api' || via === 'buffer'
  return (
    <footer
      className={clsx(styles.footer, condensed && styles.footerCondensed)}
    >
      {!isApiPost && !condensed && <PostedFrom />}
      {isApiPost && !condensed && <PostCardAuthor />}
      <PostCardActions />
    </footer>
  )
}

PostCardFooterRoot.displayName = 'PostCardFooter'

const PostCardFooterSkeleton = ({
  isSent = false,
}: {
  isSent?: boolean
}): JSX.Element => {
  if (isSent) {
    return (
      <footer className={styles.footer}>
        <Flex justify="end" gap="2xs" className={styles.skeleton}>
          <Skeleton height={32} width={100} />
          <Skeleton height={32} width={32} />
        </Flex>
      </footer>
    )
  }
  return (
    <footer className={styles.footer}>
      <Flex justify="end" gap="2xs" className={styles.skeleton}>
        <Skeleton height={32} width={120} />
        <Skeleton height={32} width={32} />
        <Skeleton height={32} width={32} />
      </Flex>
    </footer>
  )
}

PostCardFooterSkeleton.displayName = 'PostCardFooter.Skeleton'

export const PostCardFooter = Object.assign(PostCardFooterRoot, {
  Skeleton: PostCardFooterSkeleton,
})
