import React, { useContext, useEffect, useState } from 'react'

import { trackButtonClick, trackStepViewed } from '../utils'
import { useMutation } from '@apollo/client'
import { SEND_TEST_NOTIFICATION } from '../../../../../../common/graphql/account'
import {
  Button,
  Card,
  CheckIcon,
  ChevronDownIcon,
  Dialog,
  DropdownMenu,
  Flex,
  Heading,
  LoadingIcon,
  Text,
  WarningIcon,
} from '@buffer-mono/popcorn'
import { useTranslation, i18n } from '../../../../../../common/i18n'

import { AndroidIcon } from '../../icons/AndroidIcon'
import { AppleIcon } from '../../icons/AppleIcon'
import styles from './RemindersWizard.module.css'
import { getSendProcessLabel } from '../../utils'
import { UserContext } from '../../../../../../common/context/User'

const RemindersWizard = ({
  closeAction,
  source,
  onContinue,
  onDismiss,
}: {
  closeAction: () => void
  source?: string
  onContinue?: () => void
  onDismiss?: () => void
}): JSX.Element => {
  const { t } = useTranslation('translation', { i18n })

  const user = useContext(UserContext)
  const orgId = user?.currentOrganization?.id ?? null

  const [testRun, setTestRun] = useState(0)
  const [tryAgainButtonEnabled, setTryAgainButtonEnabled] = useState(false)

  const [
    sendTestNotification,
    { loading, data: sendTestNotificationData, called },
  ] = useMutation(SEND_TEST_NOTIFICATION, {
    onCompleted: () => {
      setTestRun((prev) => Math.min(prev + 1, 2))
    },
  })
  const code = sendTestNotificationData?.sendTestNotification?.code

  useEffect(() => {
    trackStepViewed({
      stepNumber: 1,
      organizationId: user?.currentOrganization?.id || '',
      placement: 'appShell',
      clientName: 'publishWeb',
    })
  }, [user?.currentOrganization?.id])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (!loading) {
      timeoutId = setTimeout(() => {
        setTryAgainButtonEnabled(true)
      }, 5000)
    }

    return () => clearTimeout(timeoutId)
  }, [loading])

  const errorMessageKey = code
    ? code === 1001
      ? 'remindersSetupWizard.noDeviceFoundWarningMessage'
      : 'remindersSetupWizard.warningMessage'
    : testRun >= 2 && !loading
    ? 'remindersSetupWizard.warningMessage'
    : undefined

  return (
    <Dialog
      open
      onOpenChange={(open): void => {
        if (!open) {
          trackButtonClick('skip', orgId, source)
          closeAction()
          onDismiss?.()
        }
      }}
    >
      <Dialog.Content className={styles.content}>
        <div>
          <div className={styles.topBar}>
            <Dialog.CloseButton />
          </div>

          <Dialog.Body className={styles.body}>
            <Dialog.Header className={styles.header}>
              <Heading size="large">
                {t('remindersSetupWizard.headline')}
              </Heading>
              <Dialog.Description>
                {t('remindersSetupWizard.description')}
              </Dialog.Description>
            </Dialog.Header>

            <Flex
              gap="md"
              direction="column"
              align="stretch"
              className={styles.cards}
            >
              <Card>
                <Flex gap="xs">
                  <div className={styles.number}>1</div>
                  <div>
                    <Text as="h3" weight="bold" size="md">
                      {t('remindersSetupWizard.downloadBufferApp')}
                    </Text>
                    <Text as="p" size="md">
                      {t('remindersSetupWizard.downloadBufferAppDescription')}
                    </Text>
                    <div className={styles.buttonsArea}>
                      <Button
                        as="a"
                        href="https://play.google.com/store/apps/details?id=org.buffer.android"
                        variant="secondary"
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={(): void => {
                          trackButtonClick('get-android-app', orgId, source)
                        }}
                        aria-label="Get Buffer for Android"
                      >
                        <AndroidIcon />
                        {t('remindersSetupWizard.ctaAndroid')}
                      </Button>
                      <Button
                        as="a"
                        href="https://apps.apple.com/app/apple-store/id490474324"
                        variant="secondary"
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={(): void => {
                          trackButtonClick('get-ios-app', orgId, source)
                        }}
                        aria-label="Get Buffer for iOS"
                      >
                        <AppleIcon />
                        {t('remindersSetupWizard.ctaiOS')}
                      </Button>
                    </div>
                  </div>

                  <img
                    className={styles.qrImage}
                    data-testid="reminders-wizard-qr-image"
                    src="https://buffer-publish.s3.amazonaws.com/images/qr-download.png"
                    alt="QR to download the Buffer app"
                  />
                </Flex>
              </Card>
              <Card>
                <Flex gap="xs">
                  <div className={styles.number}>2</div>
                  <Flex direction="column">
                    <Text as="h3" weight="bold" size="md">
                      {t('remindersSetupWizard.enableNotifications')}
                    </Text>
                    <ul className={styles.list}>
                      <li>
                        <Text>
                          {t('remindersSetupWizard.enableNotificationsItem1')}
                        </Text>
                      </li>
                      <li>
                        <Text>
                          {t('remindersSetupWizard.enableNotificationsItem2')}
                        </Text>
                      </li>
                      <li>
                        <Text>
                          {t('remindersSetupWizard.enableNotificationsItem3')}
                        </Text>
                      </li>
                    </ul>
                  </Flex>
                </Flex>
              </Card>
            </Flex>

            {errorMessageKey && (
              <div className={styles.errorMessage}>
                <WarningIcon size="medium" className={styles.errorIcon} />
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(errorMessageKey),
                    }}
                  />
                </Text>
              </div>
            )}
          </Dialog.Body>
        </div>
        <Flex gap="md" direction="column">
          <Dialog.Separator />
          <Dialog.Footer className={styles.footer}>
            <DropdownMenu
              align="start"
              className={styles.helpMenu}
              trigger={
                <Button
                  variant="tertiary"
                  size="large"
                  className={styles.helpButton}
                >
                  {t('remindersSetupWizard.help')}
                  <ChevronDownIcon />
                </Button>
              }
            >
              <DropdownMenu.Item asChild>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  className={styles.helpLink}
                  href="https://support.buffer.com/article/658-using-notification-publishing?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
                >
                  {t('remindersSetupWizard.helpItem1')}
                </a>
              </DropdownMenu.Item>
            </DropdownMenu>
            {testRun > 0 ? (
              <>
                <Button
                  variant="tertiary"
                  size="large"
                  disabled={!tryAgainButtonEnabled}
                  onClick={(): void => {
                    setTryAgainButtonEnabled(false)
                    trackButtonClick('try-again', orgId, source)

                    sendTestNotification()
                  }}
                >
                  {loading ? (
                    <LoadingIcon />
                  ) : (
                    !tryAgainButtonEnabled && <CheckIcon />
                  )}
                  {getSendProcessLabel(
                    'remindersSetupWizard.buttonLabelTryAgain',
                    loading,
                    !tryAgainButtonEnabled,
                    t,
                  )}
                </Button>

                <Button
                  variant="primary"
                  size="large"
                  onClick={(): void => {
                    trackButtonClick('notification-received', orgId, source)
                    onContinue?.()

                    closeAction()
                  }}
                >
                  <CheckIcon />
                  {t('remindersSetupWizard.buttonLabelNotificationReceived')}
                </Button>
              </>
            ) : (
              <Button
                variant="primary"
                size="large"
                onClick={(): void => {
                  trackButtonClick('try-notifications', orgId, source)
                  sendTestNotification()
                }}
              >
                {loading ? <LoadingIcon /> : called && <CheckIcon />}
                {getSendProcessLabel(
                  'remindersSetupWizard.buttonLabel',
                  loading,
                  called,
                  t,
                )}
              </Button>
            )}
          </Dialog.Footer>
        </Flex>
      </Dialog.Content>
    </Dialog>
  )
}

export default RemindersWizard
