import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import {
  createPage,
  ideaDetailsRoute,
  ideaEditRoute,
  ideaNewRoute,
} from '~publish/legacy/routes'

import { IdeaCreationDialog } from './IdeaCreationDialog'
import { IdeaDetailsDialog } from './IdeaDetailsDialog'
import { IdeaEditDialog } from './IdeaEditDialog'

/**
 * Router component used to manage IdeaCreationDialog, IdeaEditDialog, and IdeaDetailsDialog.
 * Accepts a parent route that is prefixed to the dialog routes.
 * This allows selectively rendering the dialogs where necessary
 * without needing them to be completely global.
 */
export function IdeaManagementRouter(): JSX.Element {
  const location = useLocation<{ returnTo?: Location }>()
  const rootPath = location.state?.returnTo?.pathname || createPage.route

  return (
    <Switch>
      <Route
        path={`${rootPath}${ideaNewRoute.route}`}
        key="idea-creation-dialog"
      >
        <IdeaCreationDialog />
      </Route>
      <Route path={`${rootPath}${ideaEditRoute.route}`} key="idea-edit-dialog">
        <IdeaEditDialog />
      </Route>
      <Route
        path={`${rootPath}${ideaDetailsRoute.route}`}
        key="idea-details-dialog"
      >
        <IdeaDetailsDialog />
      </Route>
    </Switch>
  )
}
