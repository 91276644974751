import React from 'react'
import AppActionCreators from '../../action-creators/AppActionCreators'
import { SaveButtonTypes, ButtonsQueuingTypesMap } from '../../AppConstants'
import Dropdown, { DropdownTrigger, DropdownContent } from '../Dropdown'
import DateTimeSlotPicker from '../DateTimeSlotPicker'
import Button from '../shared/Button'
import styles from '../css/UpdateSaver.module.css'
import type {
  ComposerInlineSaveButton,
  ComposerUserData,
} from '../../stores/types'

type UpdateSaverItemProps = {
  type: ComposerInlineSaveButton
  children: React.ReactNode
  userData: ComposerUserData
  isInlineSchedulerDisplayed: boolean
  disabled?: boolean
  timezone?: string
  isMenuItem?: boolean
  isSecondaryItem?: boolean
  draftMode: boolean
  weekStartsMonday: boolean
}

const UpdateSaverItem = ({
  type,
  children,
  userData,
  disabled,
  timezone,
  isMenuItem,
  isSecondaryItem,
  weekStartsMonday,
  isInlineSchedulerDisplayed,
  draftMode,
}: UpdateSaverItemProps): JSX.Element => {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const queuingType = ButtonsQueuingTypesMap.get(type)

    AppActionCreators.saveDrafts(queuingType, {
      shouldSkipEmptyTextAlert: false,
      hasCampaignsToTagsFeature: true,
    })

    e.stopPropagation()
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'timestamp' implicitly has an 'any' type... Remove this comment to see the full error message
  const onDateTimeSlotPickerSubmit = (timestamp) => {
    const queuingType = ButtonsQueuingTypesMap.get(type)

    AppActionCreators.saveDrafts(queuingType, {
      customScheduleTime: timestamp,
      shouldSkipEmptyTextAlert: false,
      hasCampaignsToTagsFeature: true,
    })
  }

  const getClassName = (): string => {
    if (isMenuItem) {
      return styles.dropdownMenuItem
    }

    if (isSecondaryItem) {
      return styles.secondaryButton
    }

    return styles.button
  }

  const schedulishSaveButtonTypes = [
    SaveButtonTypes.SCHEDULE_POST,
    SaveButtonTypes.SCHEDULE_DRAFT,
  ]

  const internalButtonType =
    // @ts-expect-error TS(2345) FIXME: Argument of type 'ComposerInlineSaveButton' is not assig... Remove this comment to see the full error message
    schedulishSaveButtonTypes.includes(type) && !isInlineSchedulerDisplayed
      ? 'SCHEDULE_POST_DROPDOWN'
      : 'BUTTON'
  const isScheduleDraftAction =
    // @ts-expect-error TS(2367) FIXME: This comparison appears to be unintentional because the ty... Remove this comment to see the full error message
    type === SaveButtonTypes.SCHEDULE_DRAFT ||
    (type === SaveButtonTypes.SAVE && draftMode)

  switch (internalButtonType) {
    case 'SCHEDULE_POST_DROPDOWN':
      return (
        <Dropdown className={styles.dropdownContainerAsMenuItem}>
          <DropdownTrigger className={styles.dropdownTriggerAsMenuItem}>
            {children}
          </DropdownTrigger>
          <DropdownContent className={styles.dropdownContentAsMenuItem}>
            <DateTimeSlotPicker
              onSubmit={onDateTimeSlotPickerSubmit}
              shouldUse24hTime={userData.uses24hTime}
              timezone={timezone}
              weekStartsMonday={weekStartsMonday}
              submitButtonCopy={
                isScheduleDraftAction ? 'Add Draft' : 'Schedule'
              }
              showDraftScheduleNotice={isScheduleDraftAction}
            />
          </DropdownContent>
        </Dropdown>
      )

    default:
      return (
        <Button
          type="button"
          className={getClassName()}
          disabled={disabled}
          onClick={onButtonClick}
        >
          {children}
        </Button>
      )
  }
}

export default UpdateSaverItem
