import { Temporal } from '@js-temporal/polyfill'
import { format as dateFnsFormat, type FormatOptions } from 'date-fns'

// Check functions
export function isToday(day: Temporal.ZonedDateTime): boolean {
  return day.toPlainDate().equals(getZonedNow(day.timeZoneId).toPlainDate())
}

export function isTomorrow(day: Temporal.ZonedDateTime): boolean {
  return isSameDay(day, getZonedNow(day.timeZoneId).add({ days: 1 }))
}

export function isYesterday(day: Temporal.ZonedDateTime): boolean {
  return isSameDay(day, getZonedNow(day.timeZoneId).subtract({ days: 1 }))
}

export function isPast(day: Temporal.ZonedDateTime): boolean {
  return isBefore(day, getZonedNow(day.timeZoneId))
}

export function isFuture(day: Temporal.ZonedDateTime): boolean {
  return isAfter(day, getZonedNow(day.timeZoneId))
}

export function isBefore(
  day: Temporal.ZonedDateTime,
  otherDay: Temporal.ZonedDateTime,
): boolean {
  return Temporal.ZonedDateTime.compare(day, otherDay) < 0
}

export function isAfter(
  day: Temporal.ZonedDateTime,
  otherDay: Temporal.ZonedDateTime,
): boolean {
  return Temporal.ZonedDateTime.compare(day, otherDay) > 0
}

export function isSameDay(
  day: Temporal.ZonedDateTime,
  otherDay: Temporal.ZonedDateTime,
): boolean {
  return day.toPlainDate().equals(otherDay.toPlainDate())
}

export function isSameHour(
  day: Temporal.ZonedDateTime,
  otherDay: Temporal.ZonedDateTime,
): boolean {
  return (
    day.toPlainDate().equals(otherDay.toPlainDate()) &&
    day.hour === otherDay.hour
  )
}

// Creation functions
export function getZonedNow(timezone: string): Temporal.ZonedDateTime {
  return Temporal.ZonedDateTime.from(Temporal.Now.zonedDateTimeISO(timezone))
}

export function isDSTObserved(
  date: Temporal.ZonedDateTime,
  hour: number,
): boolean {
  return date.hour !== hour
}

export function startOfHour(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  const newDate = date.startOfDay().add({ hours: date.hour })
  if (isDSTObserved(newDate, date.hour)) {
    const delta = date.hour - newDate.hour
    return newDate.add({ hours: delta })
  }
  return newDate
}

export function endOfHour(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  return startOfHour(date).add({ hours: 1 }).subtract({ nanoseconds: 1 })
}

export function startOfDay(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  return date.startOfDay()
}

export function endOfDay(date: Temporal.ZonedDateTime): Temporal.ZonedDateTime {
  return date.startOfDay().add({ days: 1 }).subtract({ nanoseconds: 1 })
}

export function startOfWeek(
  date: Temporal.ZonedDateTime,
  { weekStartsOn = 0 }: { weekStartsOn?: 0 | 1 } = {},
): Temporal.ZonedDateTime {
  const dayOfWeek = (date.dayOfWeek - weekStartsOn + 7) % 7

  return startOfDay(date).subtract({ days: dayOfWeek })
}

export function endOfWeek(
  date: Temporal.ZonedDateTime,
  { weekStartsOn = 0 }: { weekStartsOn?: 0 | 1 } = {},
): Temporal.ZonedDateTime {
  const weekStartDate = startOfWeek(date, { weekStartsOn })
  return endOfDay(weekStartDate).add({ days: 6 })
}

export function startOfMonth(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  return startOfDay(date).subtract({ days: date.day - 1 })
}

export function endOfMonth(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  return endOfDay(date).add({ days: date.daysInMonth - date.day })
}

// Format functions
export function format(
  date: Temporal.ZonedDateTime,
  formatStr: string,
  options?: FormatOptions,
): string {
  return dateFnsFormat(date.toPlainDateTime().toString(), formatStr, options)
}

/**
 * Calculates the start and end dates of a date range based on the current date, view mode, and week start day.
 *
 * @param {Temporal.ZonedDateTime} currentDate - The current date to center the range around.
 * @param {ViewMode} viewMode - The current view mode ('week' or 'month').
 * @param {0 | 1} weekStart - The day to start the week on (0 for Sunday, 1 for Monday).
 * @returns {{start: Temporal.ZonedDateTime, end: Temporal.ZonedDateTime}} An object containing the start and end dates of the range.
 */
export const getDateRange = (
  currentDate: Temporal.ZonedDateTime,
  viewMode: 'week' | 'month',
  weekStart: 0 | 1,
): { start: Temporal.ZonedDateTime; end: Temporal.ZonedDateTime } => {
  let start: Temporal.ZonedDateTime
  let end: Temporal.ZonedDateTime

  if (viewMode === 'week') {
    start = startOfWeek(currentDate, { weekStartsOn: weekStart })
    end = endOfWeek(currentDate, { weekStartsOn: weekStart })
  } else {
    // month view
    const monthStart = startOfMonth(currentDate)
    const monthEnd = endOfMonth(currentDate)
    // Get the start of the week containing the first day of the month
    start = startOfWeek(monthStart, { weekStartsOn: weekStart })
    // Get the end of the week containing the last day of the month
    end = endOfWeek(monthEnd, { weekStartsOn: weekStart })
  }

  return { start, end }
}
