import { Temporal } from '@js-temporal/polyfill'
import type {
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragCancelEvent,
} from '@dnd-kit/core'

import {
  format,
  endOfWeek,
  getZonedNow,
  isBefore,
  startOfWeek,
  getDateRange,
} from '~publish/helpers/temporal'

import type { CalendarBlockData, CalendarInfo, CalendarItem } from './types'

const FIVE_MINUTES_MS = 300000

const calendarInfoCache = new Map<string, CalendarInfo>()

/**
 * Efficiently calculates and caches calendar data (hours or days) based on input parameters.
 * This centralized calculation approach significantly improves performance by:
 * 1. Computing all date blocks at once rather than in child components
 * 2. Using a cache to avoid redundant calculations
 * 3. Organizing data in 2D arrays for more efficient rendering patterns
 *
 * Performance: <3ms for months and <7ms for weeks under standard conditions
 *
 * @param {Temporal.ZonedDateTime} selectedDate - The selected date to center the calendar around.
 * @param {'week' | 'month'} viewMode - The current view mode of the calendar.
 * @param {0 | 1} weekStartsOn - The day to start the week on (0 for Sunday, 1 for Monday).
 * @param {Object} options - Configuration options.
 * @param {boolean} [options.enableCache=true] - Whether to use caching for performance optimization.
 * @returns {CalendarInfo} An object containing pre-structured arrays of hours and days data.
 */
export const calculateCalendarData = (
  selectedDate: Temporal.ZonedDateTime,
  viewMode: 'week' | 'month',
  weekStartsOn: 0 | 1 = 0,
  { enableCache = true }: { enableCache?: boolean } = {},
): CalendarInfo => {
  if (!viewMode || !selectedDate) return { hours: [], days: [] }

  // Create a unique cache key based on the day, view mode, and week start setting
  // We use startOfDay to ensure time differences don't create unnecessary cache entries
  const key = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}-${selectedDate.timeZoneId}-${viewMode}-${weekStartsOn}`

  // If we have cached data for this configuration, return it immediately
  // This is a major performance optimization that prevents recalculating the same data
  if (enableCache && calendarInfoCache.has(key)) {
    return calendarInfoCache.get(key) as CalendarInfo
  }

  // Calculate different data structures based on view mode
  let hours: CalendarBlockData[][] = []
  let days: CalendarBlockData[][] = []

  if (viewMode === 'week') {
    hours = calculateCalendarHours(selectedDate, weekStartsOn)
  } else {
    days = calculateCalendarDays(selectedDate, weekStartsOn)
  }
  const result = {
    hours,
    days,
  }
  if (enableCache) {
    calendarInfoCache.set(key, result)
  }

  return result
}

/**
 * Efficiently calculates hour blocks for a week view of the calendar.
 * Groups hours by hour of day (0-23) for easier rendering in a grid layout.
 * Handles DST transitions (both skipped and repeated hours) correctly.
 *
 * The resulting 2D array structure optimizes rendering by grouping the same hour
 * across different days, reducing additional data transformations in components.
 *
 * @param {Temporal.ZonedDateTime} selectedDate - The selected date to center the week around.
 * @param {0 | 1} weekStartsOn - The day to start the week on (0 for Sunday, 1 for Monday).
 * @returns {CalendarBlockData[][]} A 2D array of hour blocks grouped by hour of day.
 */
function calculateCalendarHours(
  currentDate: Temporal.ZonedDateTime,
  weekStartsOn: 0 | 1,
): CalendarBlockData[][] {
  // Get the start and end of the week containing the selected date
  const startZdt = startOfWeek(currentDate, { weekStartsOn })
  const endZdt = endOfWeek(currentDate, { weekStartsOn })
  const timeZone = currentDate.timeZoneId

  const startEpoch = startZdt.toInstant().epochMilliseconds
  const endEpoch = endZdt.toInstant().epochMilliseconds

  // Get current time in the specified timezone for determining creation timestamps
  const nowTimestamp = getZonedNow(timeZone)
    .round('minute')
    .toInstant().epochMilliseconds

  // Create a 2D array to store hours grouped by hour of day (0-23)
  // This structure makes it easier to render hours in a grid layout
  // Each inner array will contain the same hour across different days
  // This 2D structure eliminates the need for additional transformations in the Week component
  const hoursByHourOfDay: CalendarBlockData[][] = Array.from(
    { length: 24 },
    () => [],
  )

  // Step in 1-hour increments from startEpoch to endEpoch (but watch for DST skip / repeat)
  let currentMs = startEpoch
  let prevZdt =
    Temporal.Instant.fromEpochMilliseconds(currentMs).toZonedDateTimeISO(
      timeZone,
    )

  while (currentMs < endEpoch) {
    // Move forward one hour numerically
    let nextMs = currentMs + 60 * 60 * 1000

    let nextZdt =
      Temporal.Instant.fromEpochMilliseconds(nextMs).toZonedDateTimeISO(
        timeZone,
      )

    const isSameDay = nextZdt.day === prevZdt.day
    const hourDiff = nextZdt.hour - prevZdt.hour

    // Handle DST transitions:
    // 1. When a timezone skips an hour (e.g., spring forward), hourDiff will be > 1
    // 2. When a timezone repeats an hour (e.g., fall back), hourDiff will be 0
    // NOTE: this will not work with Lord Howe Island (Australia) because it
    // has a DST change of 30 minutes

    // If the hour is skipped in the timezone, we add a placeholder hour block for it
    // This ensures the UI still shows all 24 hours even during DST transitions
    if (hourDiff > 1 && isSameDay) {
      hoursByHourOfDay[prevZdt.hour + 1].push({
        // Nothing should be scheduled in this hour so we set the start and
        // end to the same timestamp
        startTimestamp: nextMs - 1,
        endTimestamp: nextMs - 1,
        creationTimestamp: nextMs - 1 + FIVE_MINUTES_MS,
        start: prevZdt,
        isDSTSkippedHour: true,
      })
    }

    // If local hour didn't change, we hit a DST repetition, so we add one more hour
    // This handles "fall back" where an hour is repeated
    if (hourDiff === 0 && isSameDay) {
      nextMs += 60 * 60 * 1000
      nextZdt =
        Temporal.Instant.fromEpochMilliseconds(nextMs).toZonedDateTimeISO(
          timeZone,
        )
    }

    const startTimestamp = currentMs
    const endTimestamp = nextMs - 1

    // Calculate creation timestamp for new events:
    // - If the hour is in the future, use the start of the hour
    // - If the hour is in the past or current, use current time + 5 minutes
    const creationTimestamp =
      startTimestamp > nowTimestamp
        ? startTimestamp
        : nowTimestamp + FIVE_MINUTES_MS

    // Add the hour block to the appropriate hour group
    // This organizes hours by their hour-of-day value (0-23)
    hoursByHourOfDay[prevZdt.hour].push({
      startTimestamp,
      endTimestamp,
      creationTimestamp,
      start: prevZdt,
    })

    // Advance to the next hour
    currentMs = nextMs
    if (currentMs < endEpoch) {
      prevZdt = nextZdt
    }
  }

  return hoursByHourOfDay
}

/**
 * Efficiently calculates day blocks for a month view of the calendar.
 * Groups days by week for optimized grid rendering, minimizing Temporal object creation.
 *
 * The resulting 2D array structure (days grouped by week) eliminates the need for
 * additional data transformations in the Month component, improving rendering performance.
 *
 * @param {Temporal.ZonedDateTime} selectedDate - The selected date to center the month around.
 * @param {0 | 1} weekStartsOn - The day to start the week on (0 for Sunday, 1 for Monday).
 * @returns {CalendarBlockData[][]} A 2D array of day blocks grouped by week.
 */
function calculateCalendarDays(
  selectedDate: Temporal.ZonedDateTime,
  weekStartsOn: 0 | 1,
): CalendarBlockData[][] {
  const { start, end } = getDateRange(selectedDate, 'month', weekStartsOn)
  const timeZone = selectedDate.timeZoneId

  // Calculate number of weeks in the month view
  // This complex calculation handles edge cases:
  // 1. When the month spans across year boundaries
  // 2. Adjusts for different week start days (Sunday or Monday)
  //
  // We use modulo 52 to handle year boundary cases: when crossing years,
  // (end.weekOfYear - start.weekOfYear) will be negative.
  // Adding 52 ensures the result is positive, then modulo 52 gives us the correct number of weeks.
  const numberOfWeeks =
    ((end.weekOfYear - start.weekOfYear + 52) % 52) + weekStartsOn

  let dayZdt = start.startOfDay()
  const endZdt = end.add({ days: 1 }).startOfDay()

  // Get current time in the specified timezone for determining creation timestamps
  const nowTimestamp = getZonedNow(timeZone)
    .round('minute')
    .toInstant().epochMilliseconds

  // Create a 2D array to store days grouped by week
  // This structure makes it easier to render days in a grid layout
  // Each inner array represents a week containing up to 7 days
  const daysByWeek: CalendarBlockData[][] = Array.from(
    { length: numberOfWeeks },
    () => [],
  )
  let weekIndex = 0

  // Process each day from start to end date
  while (isBefore(dayZdt, endZdt)) {
    const nextDayZdt = dayZdt.add({ days: 1 })

    const startTimestamp = dayZdt.toInstant().epochMilliseconds
    const endTimestamp = nextDayZdt.toInstant().epochMilliseconds - 1

    // Use noon as the default creation time for new events
    // This places new events in the middle of the day by default
    const noonMs = startTimestamp + 12 * 60 * 60 * 1000

    // Calculate creation timestamp for new events:
    // - If the day is in the future, use noon of that day
    // - If the day is in the past or current, use current time + 5 minutes
    const creationTimestamp =
      noonMs > nowTimestamp ? noonMs : nowTimestamp + FIVE_MINUTES_MS

    const day: CalendarBlockData = {
      startTimestamp,
      endTimestamp,
      creationTimestamp,
      start: dayZdt,
    }

    // Push into the current week array
    daysByWeek[weekIndex].push(day)

    // If we have 7 days in this row, advance to the next week
    if (daysByWeek[weekIndex].length === 7) {
      weekIndex++
      if (weekIndex >= numberOfWeeks) break
    }

    dayZdt = nextDayZdt
  }

  return daysByWeek
}

/**
 * Generates announcement functions for drag and drop operations in a the calendar.
 *
 * @param {Object} options - The options for generating announcements.
 * @param {string} options.timezone - The timezone to use for formatting dates and times.
 * @param {boolean} options.is24HourFormat - Whether to use 24-hour time format.
 * @param {'week' | 'month'} options.viewMode - The current view mode of the calendar.
 * @param {CalendarItem[]} [options.items=[]] - An array of calendar items.
 *
 * @returns {Object} An object containing announcement functions for different drag and drop events.
 * @property {function} onDragStart - Announces when an item starts being dragged.
 * @property {function} onDragOver - Announces when a dragged item is over a droppable area.
 * @property {function} onDragEnd - Announces when a drag operation ends.
 * @property {function} onDragCancel - Announces when a drag operation is cancelled.
 */
export function getDndKitAnnouncements({
  timezone,
  is24HourFormat,
  viewMode,
  items = [],
}: {
  timezone: string
  is24HourFormat: boolean
  viewMode: 'week' | 'month'
  items: CalendarItem[] | undefined
}): {
  onDragStart: (event: DragStartEvent) => string
  onDragOver: (event: DragOverEvent) => string
  onDragEnd: (event: DragEndEvent) => string
  onDragCancel: (event: DragCancelEvent) => string
} {
  const dateTimeFormatter = (timestamp: number): string => {
    const zonedDate =
      Temporal.Instant.fromEpochMilliseconds(timestamp).toZonedDateTimeISO(
        timezone,
      )
    return format(
      zonedDate,
      `eee, MMMM d 'at' ${is24HourFormat ? 'HH:mm' : 'h:mm a'}`,
    )
  }
  const dateFormatter = (timestamp: number): string => {
    const zonedDate =
      Temporal.Instant.fromEpochMilliseconds(timestamp).toZonedDateTimeISO(
        timezone,
      )
    return format(zonedDate, 'eee, MMMM d')
  }

  return {
    onDragStart({ active }: DragStartEvent): string {
      const item = items.find((item) => item.id === active.id.toString())
      if (!item) return ``
      return `Picked up item scheduled for ${dateTimeFormatter(
        item.timestamp,
      )}.`
    },
    onDragOver({ active, over }: DragOverEvent): string {
      const item = items.find((item) => item.id === active.id.toString())
      if (!item || !over?.id) return ``

      if (over) {
        return `Item scheduled for ${dateTimeFormatter(
          item.timestamp,
        )} is over  ${
          viewMode === 'month'
            ? dateFormatter(over.data.current?.timestamp)
            : dateTimeFormatter(over.data.current?.timestamp)
        }.`
      }
      return `Item is no longer over a droppable area.`
    },
    onDragEnd({ active, over }: DragEndEvent): string {
      const item = items.find((item) => item.id === active.id.toString())
      if (!item) return ``
      if (over?.data.current?.timestamp === active.data.current?.timestamp)
        return `Item scheduled for ${dateTimeFormatter(
          item.timestamp,
        )} was moved to its original position.`

      if (over) {
        return `Item scheduled for ${dateTimeFormatter(
          item.timestamp,
        )} was rescheduled to  ${
          viewMode === 'month'
            ? dateFormatter(over.data.current?.timestamp)
            : dateTimeFormatter(over.data.current?.timestamp)
        }.`
      }
      return `Item was dropped back in its original position.`
    },
    onDragCancel({ active }: DragCancelEvent): string {
      const item = items.find((item) => item.id === active.id.toString())
      if (!item) return ``

      return `Dragging was cancelled. Item scheduled for ${dateTimeFormatter(
        item.timestamp,
      )} was returned to its original position.`
    },
  }
}
