import React, { type MutableRefObject } from 'react'
import {
  Avatar,
  Flex,
  Heading,
  Input,
  Notice,
  RadioCard,
} from '@buffer-mono/popcorn'

import { getMastodonServers } from '../../utils'

import styles from './Body.module.css'
import { MASTODON_SERVER_GENERIC } from '../../constants'

type BodyProps = {
  setSelectedServer: (value: string) => void
  inputRef: MutableRefObject<HTMLInputElement> | MutableRefObject<null>
  hasError: boolean
  selectedServer: string | null
  setCustomServer: (value: string) => void
}

export function Body({
  setSelectedServer,
  inputRef,
  hasError,
  selectedServer,
  setCustomServer,
}: BodyProps): JSX.Element {
  const availableServers = getMastodonServers()

  return (
    <Flex fullWidth direction="column" className={styles.container}>
      <Flex justify="center" fullWidth>
        <Heading size="large" className={styles.header}>
          Choose your Mastodon server
        </Heading>
      </Flex>
      <Flex
        justify="center"
        align="center"
        fullWidth
        className={styles.cardsContainer}
      >
        <RadioCard.Group
          onValueChange={(value): void => {
            setSelectedServer(value)
          }}
          className={styles.group}
        >
          {availableServers.map((server) => {
            const showCustomServerInput =
              server.url === MASTODON_SERVER_GENERIC &&
              selectedServer === MASTODON_SERVER_GENERIC

            return (
              <RadioCard value={server.url} key={server.url}>
                <Flex align="center" gap="sm">
                  <Avatar
                    src={server.image_url}
                    alt={`server image for ${server.text}`}
                  />
                  {showCustomServerInput ? (
                    <Input
                      onChange={(e): void =>
                        setCustomServer(e.target.value.toLowerCase())
                      }
                      name="other"
                      ref={inputRef}
                      placeholder="Other"
                      id="custom-server-input"
                      data-testid="custom-server-input"
                    />
                  ) : (
                    <span>
                      <strong>{server.text}</strong>
                    </span>
                  )}
                </Flex>
              </RadioCard>
            )
          })}
          {hasError && (
            <Notice variant="error">
              Mastodon server doesn&apos;t exist or is unreachable
            </Notice>
          )}
        </RadioCard.Group>
      </Flex>
    </Flex>
  )
}
