import { useMutation } from '@apollo/client'
import { toast } from '@buffer-mono/popcorn'
import { graphql } from '~publish/graphql'
import { useOrganizationId } from '~publish/legacy/accountContext'

export const CREATE_CUSTOMER_PORTAL_SESSION = graphql(/* GraphQL */ `
  mutation createCustomerPortalSession(
    $organizationId: String!
    $returnURL: String!
  ) {
    billingCreateCustomerPortalSession(
      organizationId: $organizationId
      returnURL: $returnURL
    ) {
      ... on BillingCreateCustomerPortalSessionResponse {
        customerPortalSessionUrl
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`)

const DEFAULT_ERROR_MESSAGE =
  'Whoops! Looks like there was a problem processing your request. Please try again. If you continue experiencing this problem, please contact us.'

export enum PortalOptions {
  CUSTOMER_UPDATE = 'customer/update',
  MAIN_PAGE = '',
}

/**
 * Custom hook to get the Stripe customer portal session
 * @returns {Object} - The customer portal session URL
 */
export const useGetPortalSession = ({
  portalOption = PortalOptions.CUSTOMER_UPDATE,
}: {
  portalOption: PortalOptions
}): {
  getCustomerPortalSession: (returnURL: string) => void
  loading: boolean
} => {
  const organizationId = useOrganizationId()

  const [createPortalSession, { loading }] = useMutation(
    CREATE_CUSTOMER_PORTAL_SESSION,
    {
      onCompleted(data) {
        const response = data.billingCreateCustomerPortalSession
        if ('userFriendlyMessage' in response) {
          toast.error(DEFAULT_ERROR_MESSAGE)
        } else {
          window.open(
            `${response.customerPortalSessionUrl}/${portalOption}`,
            '_self',
          )
        }
      },
      onError() {
        toast.error(DEFAULT_ERROR_MESSAGE)
      },
    },
  )

  const getCustomerPortalSession = (returnURL: string): void => {
    if (!organizationId) {
      toast.error(DEFAULT_ERROR_MESSAGE)
      return
    }
    createPortalSession({
      variables: {
        organizationId,
        returnURL,
      },
    })
  }

  return {
    getCustomerPortalSession,
    loading,
  }
}
