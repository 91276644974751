import React from 'react'
import { useQuery } from '@apollo/client'

import { graphql } from '~publish/gql'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { Button, Select } from '@buffer-mono/popcorn'

type IdeaGroupSelectorProps = {
  selectedGroupId: string
  onGroupSelect: (groupId: string | undefined) => void
  className?: string
}

export const getIdeaGroupsQuery = graphql(/* GraphQL */ `
  query GetIdeaGroups($organizationId: ID!) {
    ideaGroups(input: { organizationId: $organizationId }) {
      id
      name
      isLocked
    }
  }
`)

const UNASSIGNED_ITEM_ID = 'unassigned'

// This is a temporary component to select idea group
export const GroupSelector = ({
  selectedGroupId,
  onGroupSelect,
  className,
}: IdeaGroupSelectorProps): JSX.Element | null => {
  const organizationId: string = useAppSelector(selectCurrentOrganizationId)
  // TODO: this loading should happen in parent component, this is a temporary solution
  const { data, loading } = useQuery(getIdeaGroupsQuery, {
    variables: { organizationId },
  })

  if (loading || !data) {
    return null
  }

  const handleGroupSelect = (groupId: string): void => {
    onGroupSelect?.(groupId)
  }

  const selectedGroup = data.ideaGroups.find(
    (group) => group.id === selectedGroupId,
  )

  return (
    <Select
      value={selectedGroupId ?? UNASSIGNED_ITEM_ID}
      onValueChange={handleGroupSelect}
    >
      <Select.Trigger className={className} size="medium">
        <Button variant="secondary" size="medium">
          {selectedGroup ? selectedGroup.name : 'Unassigned'}
        </Button>
      </Select.Trigger>
      <Select.Content>
        <Select.Item value={UNASSIGNED_ITEM_ID}>Unassigned</Select.Item>
        {data.ideaGroups.map((group) => (
          <Select.Item key={group.id} value={group.id}>
            {group.name}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  )
}
