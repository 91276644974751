import { graphql } from '~publish/gql'

import { useTypedMutation } from '../../hooks/useTypedMutation'

export const PublishPostNow = graphql(/* GraphQL */ `
  mutation PublishPostNow($id: PostId!) {
    publishPostNow(input: { id: $id }) {
      __typename
      ... on MutationError {
        message
      }
      ... on PostActionSuccess {
        post {
          id
          status
          dueAt
          externalLink
        }
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const usePublishPostNow = () =>
  useTypedMutation(PublishPostNow, (data) => data.publishPostNow, {
    refetchQueries: ['GetPostList'],
    successTypename: 'PostActionSuccess',
  })
