import Bugsnag from '@bugsnag/browser'
import * as traits from '../hooks/utils/segmentTraitGetters'
import type { Account } from '../types'
import { env } from '../../env'

function identifySegment(user: Account): void {
  if (window.analytics) {
    if (user.id && !user.isImpersonation && user.currentOrganization) {
      const currentOrganization = user.currentOrganization
      window.analytics.identify(user.id, {
        createdAt: user.createdAt,
        currentAnalyzePlan: traits.currentAnalyzePlan({ currentOrganization }),
        currentBufferPlan: traits.currentBufferPlan(user),
        currentPublishPlan: traits.currentPublishPlan({ currentOrganization }),
        email: user.email,
        isMultiProductCustomer: traits.isMultiProductCustomer({
          currentOrganization,
        }),
        isOneBufferEnabled: user.currentOrganization.isOneBufferOrganization,
        isPayingAnalyzeUser: traits.isPayingAnalyzeOrganization(user),
        isPayingBufferUser: traits.isPayingBufferOrganization({
          currentOrganization,
        }),
        isPayingPublishUser: traits.isPayingPublishOrganization(user),
        isFreePlan: traits.isFreePlan(user),
        currentOrganizationId: user.currentOrganization.id,
        organizationUserRole: traits.organizationUserRole({
          currentOrganization,
        }),

        isOnBufferTrial: traits.isOnBufferTrial({ currentOrganization }),
        currentBufferTrialPlan: traits.currentBufferTrialPlan(user),

        isOnPublishTrial: traits.isOnPublishTrial({ currentOrganization }),
        currentPublishTrialPlan: traits.currentPublishTrialPlan({
          currentOrganization,
        }),

        isOnAnalyzeTrial: traits.isOnAnalyzeTrial({ currentOrganization }),
        currentAnalyzeTrialPlan: traits.currentAnalyzeTrialPlan({
          currentOrganization,
        }),

        billingCycle: traits.billingCycle({ currentOrganization }),
        trialBillingCycle: traits.trialBillingCycle({ currentOrganization }),
        paidSubscriptionAutoRenewEnabled:
          traits.paidSubscriptionAutoRenewEnabled({ currentOrganization }),
        isBetaEnabled: user.currentOrganization.isBetaEnabled,
        isAlphaEnabled: user.currentOrganization.isAlphaEnabled,
        appCommitHash: env.VITE_COMMIT_HASH,
      })
    }
  }
}

function identifyClarity(user: Account): void {
  if (window.clarity && user && user.currentOrganization && user.id) {
    const currentOrganization = user.currentOrganization
    const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
    const recentSignup = new Date(user.createdAt ?? Date.now()) > thirtyDaysAgo
    window.clarity('identify', user.id)
    window.clarity('set', 'recent_signup', recentSignup.toString())
    window.clarity(
      'set',
      'billing_plan',
      currentOrganization?.billing?.subscription?.plan.id,
    )
    window.clarity('set', 'billing_state', traits.getCurrentBillingState(user))
    window.clarity(
      'set',
      'channel_count',
      (currentOrganization.channels?.length ?? 0).toString(),
    )
    window.clarity(
      'set',
      'has_team_members',
      ((currentOrganization.members?.totalCount ?? 0) > 1).toString(),
    )
    window.clarity(
      'set',
      'in_beta_program',
      (currentOrganization?.isBetaEnabled ?? false).toString(),
    )
  }
}

export function identifyUser(user: Account): void {
  identifySegment(user)
  identifyClarity(user)
}

export function groupUser(user: Account): void {
  if (window.analytics) {
    if (
      user.id &&
      !user.isImpersonation &&
      user.currentOrganization &&
      user.currentOrganization.id
    ) {
      const currentOrganization = user.currentOrganization
      window.analytics.group(user.currentOrganization.id, {
        currentAnalyzePlan: traits.currentAnalyzePlan({ currentOrganization }),
        currentBufferPlan: traits.currentBufferPlan(user),
        currentPublishPlan: traits.currentPublishPlan({ currentOrganization }),
        isOneBufferEnabled: user.currentOrganization.isOneBufferOrganization,
        isPayingAnalyzeOrganization: traits.isPayingAnalyzeOrganization(user),
        isPayingBufferOrganization: traits.isPayingBufferOrganization({
          currentOrganization,
        }),
        isPayingPublishOrganization: traits.isPayingPublishOrganization(user),
        name: user.currentOrganization.name,
        organizationId: user.currentOrganization.id,

        isOnBufferTrial: traits.isOnBufferTrial({ currentOrganization }),
        currentBufferTrialPlan: traits.currentBufferTrialPlan(user),

        isOnPublishTrial: traits.isOnPublishTrial({ currentOrganization }),
        currentPublishTrialPlan: traits.currentPublishTrialPlan({
          currentOrganization,
        }),

        isOnAnalyzeTrial: traits.isOnAnalyzeTrial({ currentOrganization }),
        currentAnalyzeTrialPlan: traits.currentAnalyzeTrialPlan({
          currentOrganization,
        }),

        isBetaEnabled: user.currentOrganization.isBetaEnabled,
        isAlphaEnabled: user.currentOrganization.isAlphaEnabled,
      })
    }
  }
}

// prettier-ignore
function injectSegmentTracker(user: Account): void {
  if (!window.analytics) {
    if (window.SEGMENT_KEY) {
      Bugsnag.notify('AppShell is injecting Segment snippet', (event) => {
        event.addMetadata('user', user)
      })
      // This is the Segment snippet https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2-copy-the-segment-snippet
      /* eslint-disable */
      // @ts-expect-error TS(1345) FIXME: An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
      !(function () {
        var analytics = (window.analytics = window.analytics || [])
        // @ts-expect-error TS(2339) FIXME: Property 'initialize' does not exist on type 'neve... Remove this comment to see the full error message
        if (!analytics.initialize) {
          // @ts-expect-error TS(2339) FIXME:
          if (analytics.invoked) {
            window.console &&
              console.error &&
              console.error('Segment snippet included twice.')
          } else {
            // @ts-expect-error TS(2339) FIXME: Property 'invoked' does not exist on type 'never'.
            analytics.invoked = !0
            // @ts-expect-error TS(2339) FIXME: Property 'methods' does not exist on type 'never'.
            analytics.methods = [
              'trackSubmit',
              'trackClick',
              'trackLink',
              'trackForm',
              'pageview',
              'identify',
              'reset',
              'group',
              'track',
              'ready',
              'alias',
              'debug',
              'page',
              'once',
              'off',
              'on',
              'addSourceMiddleware',
              'addIntegrationMiddleware',
              'setAnonymousId',
              'addDestinationMiddleware',
            ]
            // @ts-expect-error TS(2339) FIXME: Property 'factory' does not exist on type 'never'.
            analytics.factory = function (t: any) {
              return function () {
                var e = Array.prototype.slice.call(arguments)
                e.unshift(t)
                // @ts-expect-error TS(2339) FIXME: Property 'push' does not exist on type 'never'.
                analytics.push(e)
                return analytics
              }
            }
            // @ts-expect-error TS(2339) FIXME: Property 'methods' does not exist on type 'never'.
            for (var t = 0; t < analytics.methods.length; t++) {
              // @ts-expect-error TS(2339) FIXME: Property 'methods' does not exist on type 'never'.
              var e = analytics.methods[t]
              // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
              analytics[e] = analytics.factory(e)
            }
            // @ts-expect-error TS(2339) FIXME: Property 'load' does not exist on type 'never'.
            analytics.load = function (t: any, e: any) {
              var n = document.createElement('script')
              n.type = 'text/javascript'
              n.async = !0
              n.src =
                'https://cdn.segment.com/analytics.js/v1/' +
                t +
                '/analytics.min.js'
              var a = document.getElementsByTagName('script')[0]
              // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
              a.parentNode.insertBefore(n, a)
              // @ts-expect-error TS(2339) FIXME: Property '_loadOptions' does not exist on type 'ne... Remove this comment to see the full error message
              analytics._loadOptions = e
            }
            // @ts-expect-error TS(2339) FIXME: Property 'SNIPPET_VERSION' does not exist on type ... Remove this comment to see the full error message
            analytics.SNIPPET_VERSION = '4.1.0'
            // @ts-expect-error TS(2339) FIXME: Property 'load' does not exist on type 'never'.
            analytics.load(window.SEGMENT_KEY)
            // @ts-expect-error TS(2339) FIXME: Property 'page' does not exist on type 'never'.
            analytics.page()
            /* eslint-enable */
          }
        }
      })()
    } else {
      window.console.warn('Please provide a SEGMENT_KEY, to start tracking')
    }
  }
}

function injectMicrosoftClarity(): void {
  if (!window.clarity) {
    if (env.NODE_ENV === 'production') {
      ;(function (c: any, l, a: any, r, i): void {
        c[a] =
          c[a] ||
          function (): void {
            // eslint-disable-next-line prefer-rest-params
            ;(c[a].q = c[a].q || []).push(arguments)
          }
        const t: any = l.createElement(r)
        t.async = 1
        t.src = 'https://www.clarity.ms/tag/' + i
        const y: any = l.getElementsByTagName(r)[0]
        y.parentNode.insertBefore(t, y)
      })(window, document, 'clarity', 'script', 'n59lssc1z7')
    }
  }
}

function trackUser(user: Account): void {
  injectSegmentTracker(user)
  injectMicrosoftClarity()
  identifyUser(user)
  groupUser(user)
}

export default trackUser
