import React from 'react'
import {
  Button,
  Checkbox,
  ChevronDownIcon,
  Dialog,
  DropdownMenu,
  Flex,
  Label,
} from '@buffer-mono/popcorn'

import styles from './Footer.module.css'

type FooterProps = {
  loading: boolean
  setFollowBuffer: (value: boolean) => void
  followBuffer: boolean
  onConfirmServer: () => void
  isValidServer: boolean
}

export function Footer({
  loading,
  setFollowBuffer,
  followBuffer,
  onConfirmServer,
  isValidServer,
}: FooterProps): JSX.Element {
  return (
    <Dialog.Footer className={styles.footer}>
      <Flex
        align="center"
        justify="between"
        className={styles.actionsContainer}
      >
        <DropdownMenu className={styles.helpMenu}>
          <DropdownMenu.Trigger>
            <Button
              variant="tertiary"
              size="large"
              className={styles.helpButton}
            >
              Need Help
              <ChevronDownIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="start">
            <DropdownMenu.Item>
              <a
                className={styles.helpLink}
                href="https://support.buffer.com/article/563-using-mastodon-with-buffer"
                target="_blank"
                rel="noopener noreferrer"
              >
                Using Mastodon with Buffer
              </a>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
        <Flex align="center" gap="sm">
          <Label htmlFor="followBuffer">
            <Checkbox
              id="followBuffer"
              name="mastodonFollowBuffer"
              defaultChecked
              checked={followBuffer}
              onChange={(): void => {
                setFollowBuffer(!followBuffer)
              }}
            >
              <strong>Follow us on Mastodon</strong>
            </Checkbox>
          </Label>

          <Button
            size="large"
            disabled={loading || !isValidServer}
            id="go-to-service"
            onClick={onConfirmServer}
          >
            {loading ? 'Redirecting...' : 'Continue'}
          </Button>
        </Flex>
      </Flex>
    </Dialog.Footer>
  )
}
